import { handleUpdateDealShowsResponse, iUpdateShowPayload, updateDealShows } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES, TICKETING_CURRENCIES } from 'deal-form/data/constants';
import {
  VARIABLE_EXPENSES_BOR_OPTIONS,
  VARIABLE_EXPENSES_CALC_OPTIONS,
  VARIABLE_EXPENSES_CAPACITY_TYPES,
  VARIABLE_EXPENSES_EMPTY_ITEM,
  VARIABLE_EXPENSES_TYPES,
} from 'deal-form/data/organisms';
import { Input } from 'deal-form/form-controls/Input';
import { iCurrency, iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismVariableExpense, iOrganismVariableExpenses } from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { useWindowSize, windowSizes } from 'support/windowResize';
import { BoxOfficeExpense, PerHeadExpense } from 'types/Show';

import { Currency } from '../../form-controls/Currency';
import { Dropdown } from '../../form-controls/Dropdown';
import { DropdownWithStaticSearch } from '../../form-controls/DropdownWithStaticSearch';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';
import {
  convertCurrencyAndUpdateContractById,
  formatTotals,
  formatCurrencyWithSymbol,
} from '../../helpers/dealHelpers';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { getMasterShow } from '../../helpers/showHelpers';
import { useArtistCurrency } from '../../helpers/useArtistCurrency';
import cypressTags from 'support/cypressTags';

const { VARIABLE_EXPENSES } = cypressTags.DEAL_FORM;

export const COLUMN_LABELS = [
  { value: 'Type', isRequired: true },
  { value: 'Currency', isRequired: true },
  { value: 'Amount', isRequired: false },
  { value: 'Cap', isRequired: true },
  { value: 'Expense Calculation', isRequired: true },
  { value: 'Ticket %', isRequired: true },
  { value: 'GBOR/NBOR', isRequired: true },
  { value: 'Per Head', isRequired: true },
  { value: 'Per Head Capacity', isRequired: true },
];
export const COLUMN_LABELS_TABLET = [
  { value: 'Type', isRequired: true },
  { value: 'Currency', isRequired: true },
  { value: 'Amount', isRequired: false },
  { value: 'Cap', isRequired: true },
  { value: 'Expense Calculation', isRequired: true },
  { value: 'Ticket %', isRequired: true },
  { value: 'GBOR/NBOR', isRequired: true },
  { value: 'Per Head', isRequired: true },
  { value: 'Per Head Capacity', isRequired: true },
  { value: 'Note' },
];

export const GRID_LAYOUT = 'grid grid-cols-4 xl:grid-cols-4 gap-2';

interface HideField {
  index: number;
  value: string;
  ids: string[];
}
const VariableExpensesForm: React.FC<iFormProps> = ({ calculations, deal, setDeal, isOpen, locked, contract }) => {
  const [showId, setShowId] = useState<string>('');
  const [variableExpensesFormData, setVariableExpensesFormData] = useState<iOrganismVariableExpenses | null>(null);
  const [convertedVariableExpensesTotal, setConvertedVariableExpensesTotal] = useState<iCurrency>({
    value: 0,
    currency: 'USD',
  });
  const [fieldsToHide, setFieldsToHide] = useState<HideField[]>([]);
  const [canSubmit, setCanSubmit] = useState(true);

  const [customTierOptions, setCustomTierOptions] = useState<iOptions[]>([]);

  const { width } = useWindowSize();

  const artistCurrency = useArtistCurrency(deal);

  const handleAddCustomOption = (newOption: string) => {
    setCustomTierOptions([...customTierOptions, { id: newOption, label: newOption, data: {} }]);
  };

  const createHideObject = (value: string, index: number) => {
    const newObject = {
      index: index,
      value: value,
      ids:
        value === 'boxOffice'
          ? [`expenses.${index}.perHead`, `expenses.${index}.perHeadCapacity`]
          : [`expenses.${index}.ticketPercentage`, `expenses.${index}.gBor_nBor`],
    };

    return newObject;
  };

  useEffect(() => {
    const show = getMasterShow(deal.shows);
    setShowId(show._id);
    const expenses = show.expenses;
    const variableExpensesTotal = formatTotals(calculations?.deals[deal._id]?.estimatedVariableExpensesTotal);

    if (expenses && Array.isArray(expenses.perHead) && Array.isArray(expenses.boxOffice)) {
      const perHeads: iOrganismVariableExpense[] = expenses.perHead.map((expense) => {
        const currency = expense.currency || deal.currency;
        return {
          type: expense.type,
          currency: currency,
          guarantee: {
            value: expense.guarantee || 0,
            currency: currency,
          },
          cap: {
            value: expense.cap || 0,
            currency: currency,
          },
          calculation: 'perHead',
          perHead: {
            value: expense.amount || 0,
            currency: currency,
          },
          perHeadCapacity: expense.capacity || '',
          notes: expense.note,
          ticketPercentage: 0,
          gBor_nBor: '',
        };
      });
      const boxOffices: iOrganismVariableExpense[] = expenses.boxOffice.map((expense) => {
        const currency = expense.currency || deal.currency;
        return {
          type: expense.type,
          currency: currency,
          guarantee: {
            value: expense.guarantee || 0,
            currency: currency,
          },
          cap: {
            value: expense.cap || 0,
            currency: currency,
          },
          calculation: 'boxOffice',
          ticketPercentage: expense.percent || 0,
          gBor_nBor: expense.potentialType || 'GBOR',
          notes: expense.note,
          perHead: {
            value: 0,
            currency: currency,
          },
          perHeadCapacity: '',
        };
      });
      const combined = [...perHeads, ...boxOffices];

      const newFields: HideField[] = [];
      combined.forEach((expenseItem, index) => {
        const newObject = createHideObject(expenseItem.calculation, index);
        newFields.push(newObject);
      });
      setFieldsToHide(newFields);
      setVariableExpensesFormData({
        expenses: combined,
        variableExpensesTotal: variableExpensesTotal,
      });
    } else {
      setConvertedVariableExpensesTotal(variableExpensesTotal);
      setVariableExpensesFormData({
        expenses: [emptyItemWithCurrency],
        variableExpensesTotal: variableExpensesTotal,
      });
    }
  }, [deal, calculations]);

  useEffect(() => {
    if (variableExpensesFormData && variableExpensesFormData.expenses.length > 0) {
      const toCurrency = variableExpensesFormData.expenses[0]?.currency;
      convertCurrencyAndUpdateContractById(
        variableExpensesFormData.variableExpensesTotal.value,
        variableExpensesFormData.variableExpensesTotal.currency,
        toCurrency,
        contract?.exchangeRates,
        contract?._id
      ).then((convertedValue) => {
        setConvertedVariableExpensesTotal({ value: convertedValue, currency: toCurrency });
      });
    }
  }, [variableExpensesFormData]);

  const emptyItemWithCurrency = useMemo(
    () => ({ ...VARIABLE_EXPENSES_EMPTY_ITEM, currency: artistCurrency?.id || 'USD' }),
    [artistCurrency]
  );

  const onSubmit = async (data: iOrganismVariableExpenses) => {
    const perHeadPayload: PerHeadExpense[] = [];
    const boxOfficePayload: BoxOfficeExpense[] = [];

    data.expenses.map((expense: iOrganismVariableExpense) => {
      if (expense.calculation === 'perHead') {
        perHeadPayload.push({
          type: expense.type,
          guarantee: Number(expense.guarantee.value),
          currency: expense.currency || deal.currency,
          cap: Number(expense.cap.value),
          amount: expense.perHead?.value || 0,
          capacity: expense.perHeadCapacity || '',
          note: expense.notes,
        });
      } else if (expense.calculation === 'boxOffice') {
        boxOfficePayload.push({
          type: expense.type,
          guarantee: Number(expense.guarantee.value),
          currency: expense.currency || deal.currency,
          cap: Number(expense.cap.value),
          percent: expense.ticketPercentage || 0,
          potentialType: expense.gBor_nBor || '',
          note: expense.notes,
        });
      }
    });

    const updateData: iUpdateShowPayload[] = [];
    deal.shows.forEach((s) => {
      if (s._id === showId) {
        const oldExpenses = s.expenses;
        const updatedShow = {
          showId: showId,
          updateBody: {
            expenses: {
              fixed: [...oldExpenses.fixed],
              perHead: perHeadPayload,
              boxOffice: boxOfficePayload,
            },
          },
        };
        updateData.push(updatedShow);
      }
    });

    try {
      setCanSubmit(false);

      const response = await updateDealShows({
        dealId: deal._id.toString(),
        updates: updateData,
      });

      if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });

      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR UPDATING VARIABLE EXPENSES', e);
      setCanSubmit(true);
    }
  };

  /**
   * This function handles hiding fields conditionaly per row
   *
   * @param chosen the chosen Calculated Expense option
   * @param index the row index to update
   */
  const onCalcChange = (chosen: iOptions | null, setValueMethod?: UseFormSetValue<FieldValues>, index?: number) => {
    if (setValueMethod) {
      // console.log('throws warning if unused');
    }
    if (chosen && index !== undefined) {
      const newObject = createHideObject(chosen.id, index);

      if (fieldsToHide[index]) {
        if (fieldsToHide[index].value !== chosen.id) {
          const newFields = [...fieldsToHide];
          newFields[index] = newObject;
          setFieldsToHide(newFields);
        }
      } else {
        const newFields = [...fieldsToHide, newObject];
        setFieldsToHide(newFields);
      }
    }
  };

  /**
   * override the built in append functionality
   *
   * @param index row to be removed
   */
  const handleRemoveOverride = async (
    index: number,
    reset: UseFormReset<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>
  ) => {
    const vals: iOrganismVariableExpenses = getValues?.() as iOrganismVariableExpenses;

    if (vals?.expenses?.length) {
      const isNew = vals.expenses?.[index]?.isNew || false;
      vals.expenses.splice(index, 1);

      if (isNew) {
        reset(vals);
      } else {
        onSubmit(vals);
      }
    } else {
      reset();
    }
  };

  const multipleCurrencyValidation = (value: string, expenses: iOrganismVariableExpense[]) => {
    // throw error if there are more than 1 currency
    if (expenses?.length) {
      // list set of unique type of currency for each row of expenses
      const uniqueCurrencies: { [key: string]: boolean } = {};
      expenses.forEach((expense: iOrganismVariableExpense) => {
        uniqueCurrencies[expense.currency || 'unknown'] = true;
      });
      const uniqueCurrencyValues = Object.keys(uniqueCurrencies);
      if (uniqueCurrencyValues.length > 1 || uniqueCurrencyValues[0] !== value) {
        return "You can't have more than one currency in variable expenses";
      } else {
        return true;
      }
    }
    return true;
  };

  return (
    variableExpensesFormData && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className={`form-row full-width ${canSubmit ? '' : 'opacity-50'}`}
        defaultValues={variableExpensesFormData}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.VARIABLE_EXPENSES}
      >
        <FieldArray
          gridClassName={GRID_LAYOUT}
          groupName="expenses"
          columns={width >= windowSizes.XL ? COLUMN_LABELS : COLUMN_LABELS_TABLET}
          addButtonLabel="Add More Expenses"
          emptyRow={emptyItemWithCurrency}
          columnLabelsType="repeats"
          hideFields={fieldsToHide}
          appendCallback={(index) => {
            const newObject = createHideObject('perHead', index);
            const newFields = [...fieldsToHide, newObject];
            setFieldsToHide(newFields);
          }}
          overrideRemove={handleRemoveOverride}
          disabled={!isOpen || locked || !canSubmit}
        >
          <DropdownWithStaticSearch
            placeholder="Type"
            id="type"
            staticOptions={VARIABLE_EXPENSES_TYPES}
            maxLength={32}
            addCustomOption={handleAddCustomOption}
            customOptions={customTierOptions}
            customOptionLabel="Create Custom Type"
            disabled={!isOpen || locked}
            rules={{ required: 'A ‘Type’ selection is required' }}
            isRequired
            dataCy={VARIABLE_EXPENSES.TYPE_DROPDOWN}
          />

          <Dropdown
            placeholder="Currency"
            id="currency"
            options={TICKETING_CURRENCIES}
            rules={{
              required: 'A ‘Currency’ selection is required',
              validate: (value, data) => multipleCurrencyValidation(value, data?.expenses || []),
            }}
            disabled={contract?.status === 'Contract Issued'}
            dataCy={VARIABLE_EXPENSES.CURRENCY}
          />
          <Currency
            id="guarantee"
            overrideCurrencyParam="expenses.index.currency"
            disabled={!isOpen || locked}
            rules={{
              min: { value: 0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
            }}
            dataCy={VARIABLE_EXPENSES.AMOUNT_INPUT}
          />
          <Currency
            rulesCallback={() => ({
              min: { value: 0, message: ERROR_MESSAGES.CAP_MINIMUM_VALUE },
            })}
            id="cap"
            overrideCurrencyParam="expenses.index.currency"
            disabled={!isOpen || locked}
            dataCy={VARIABLE_EXPENSES.CAP_INPUT}
          />
          <Dropdown
            placeholder="Expenses Calculation"
            id="calculation"
            options={VARIABLE_EXPENSES_CALC_OPTIONS}
            disabled={!isOpen || locked}
            handleChange={onCalcChange}
            rules={{ required: 'An ‘Expenses Calculation’ selection is required' }}
            dataCy={VARIABLE_EXPENSES.EXPENSE_CALCULATION}
          />
          <Input
            fieldType="percent"
            id="ticketPercentage"
            step="1"
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            disabled={!isOpen || locked}
            dataCy={VARIABLE_EXPENSES.TICKET_PERCENTAGE}
          />
          <Dropdown
            placeholder="Type"
            id="gBor_nBor"
            options={VARIABLE_EXPENSES_BOR_OPTIONS}
            disabled={!isOpen || locked}
            rules={{ required: "Please enter a 'GBOR/NBOR’ option" }}
            dataCy={VARIABLE_EXPENSES.GBOR_NBOR}
          />
          <Currency
            id="perHead"
            overrideCurrencyParam="expenses.index.currency"
            disabled={!isOpen || locked}
            rules={{
              min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
            }}
            dataCy={VARIABLE_EXPENSES.PER_HEAD_INPUT}
          />
          <Dropdown
            placeholder="Per Head Capacity"
            id="perHeadCapacity"
            options={VARIABLE_EXPENSES_CAPACITY_TYPES}
            disabled={!isOpen || locked}
            rules={{ required: "Please enter a 'Per Head Capacity’ option" }}
            dataCy={VARIABLE_EXPENSES.PER_HEAD_CAPACITY}
          />

          <TextArea
            placeholder="Type a note."
            rules={{
              maxLength: {
                value: 1024,
                message: getMaxCharMessage('your notes', 1024),
              },
            }}
            id="notes"
            rows={width >= windowSizes.XL ? 2 : 1}
            containerClassName="xl:px-0 xl:col-span-full"
            disabled={!isOpen || locked}
            dataCy={VARIABLE_EXPENSES.NOTE}
          />
        </FieldArray>

        <div className={`${GRID_LAYOUT} pr-10 items-start justify-start`}>
          <div className="pr-4 ">
            <div className="inline-block pr-4 form-label">Total</div>
            <div data-cy={VARIABLE_EXPENSES.TOTAL} className="inline-block text-sm">
              {convertedVariableExpensesTotal.value
                ? formatCurrencyWithSymbol(
                    convertedVariableExpensesTotal.value,
                    convertedVariableExpensesTotal.currency
                  )
                : '--'}
            </div>
          </div>
          {variableExpensesFormData?.variableExpensesTotal?.currency !== convertedVariableExpensesTotal.currency && (
            <div className="pr-4 ">
              <div className="inline-block pr-4 form-label">Total(Converted)</div>
              <div className="inline-block text-sm">
                {formatCurrencyWithSymbol(
                  variableExpensesFormData?.variableExpensesTotal?.value || 0,
                  variableExpensesFormData?.variableExpensesTotal?.currency || 'USD'
                )}
              </div>
            </div>
          )}
        </div>
      </Form>
    )
  );
};

export default VariableExpensesForm;
