import { CheckboxProps } from '@united-talent-agency/onyx-components/Forms/Checkbox/Checkbox';
import ScrollFAB from 'components/ScrollFAB';
import Table from 'components/Table';
import { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import TimelineAgenda from '.';
import CellContentWithMore from './Body/CellContentWithMore';
import { TimelineAgendaData } from './types';

export interface TimelineAgendaFullProps {
  data: TimelineAgendaData;
  onChange: (data: TimelineAgendaData) => void;
}

const Full: FC<TimelineAgendaFullProps> = ({ data, onChange }) => {
  // Refs
  const tableRef = useRef<HTMLTableElement>(null);

  // States
  const [pageOnTop, setPageOnTop] = useState<boolean>(true);
  const [calendarOnTop, setCalendarOnTop] = useState<boolean>(true);

  useLayoutEffect(() => {
    const handleScroll = (event: Event) => {
      const { scrollTop } = event.target as HTMLTableElement;

      const reachedTop = scrollTop === 0;
      if (calendarOnTop !== reachedTop) {
        setCalendarOnTop(reachedTop);
      }
    };

    tableRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      tableRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [calendarOnTop]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const stickyContainer = document?.querySelector('.sticky-container');
      const scrollTop = stickyContainer?.scrollTop;

      const reachedTop = scrollTop === 0;
      if (pageOnTop !== reachedTop) {
        setPageOnTop(reachedTop);
      }
    };

    const stickyContainer = document?.querySelector('.sticky-container');

    stickyContainer?.addEventListener('scroll', handleScroll);

    return () => {
      stickyContainer?.removeEventListener('scroll', handleScroll);
    };
  }, [pageOnTop]);

  const onClickScrollFAB = useCallback(() => {
    const scrollOptions = {
      behavior: 'smooth',
      left: 0,
      top: 0,
      // eslint-disable-next-line no-undef
    } as ScrollToOptions;

    tableRef.current?.scrollTo(scrollOptions);

    if (tableRef.current?.scrollTop === 0) {
      document?.querySelector('.sticky-container')?.scroll(scrollOptions);
    }
  }, []);

  const allSelected = useMemo(() => data?.every((row) => row?.selected), [data]);

  const handleOnSelectAll = useCallback<Required<CheckboxProps>['onChange']>(
    (e) => {
      onChange(
        data?.map((row) => ({
          ...row,
          selected: e.target.checked,
        }))
      );
    },
    [onChange]
  );

  return (
    <TimelineAgenda.Root ref={tableRef}>
      <Table.Header.THead>
        <Table.Header.Row.Root className="[&>*:nth-child(n+7)]:print:hidden">
          <TimelineAgenda.Header.Header
            checked={allSelected}
            onChange={handleOnSelectAll}
            className="max-w-[212px] [&>*]:max-w-[212px]"
          />
          <Table.Header.Row.Content data={data} />
        </Table.Header.Row.Root>
      </Table.Header.THead>
      <Table.Body.Root className="tbody table-row-group">
        {data?.map((row, index) => (
          <Table.Body.Row.Root
            printable={row?.selected}
            key={row?.id}
            className="[&>*:nth-child(n+7)]:print:hidden [&>*:nth-child(n+6)]:print:!border-r-0 [&>*:nth-child(n+7)]:print:!border-x-0 [&:last-child>*]:print:border-b-0 print:even:bg-[#f5f5f5]"
          >
            <TimelineAgenda.Header.BodyCell
              row={row}
              index={index}
              data={data}
              setData={onChange}
              className="w-0 max-w-[212px] [&>*]:max-w-[212px]"
            />
            <Table.Body.Row.Content
              row={row}
              index={index}
              renderCellContent={CellContentWithMore}
              className="border-x-0 [&:nth-child(n+3)]:border-x-2 print:!border-x print:border-y-0"
            />
          </Table.Body.Row.Root>
        ))}
      </Table.Body.Root>
      {(!pageOnTop || !calendarOnTop) && false && (
        <ScrollFAB className="hidden md:block print:!hidden z-10" onClick={onClickScrollFAB} />
      )}
    </TimelineAgenda.Root>
  );
};

export default Full;
