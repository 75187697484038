import { updateDeal } from 'api';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import { TextField } from 'deal-form/form-controls/TextField';
import { iFormProps } from 'deal-form/interfaces/general';
import { iCurrencyField, iOrganismCalculatedValues } from 'deal-form/interfaces/organisms';
import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { Currency } from '../../form-controls/Currency';
import { Form } from '../../form-controls/Form';
import { getTerms } from 'deal-form/helpers/dealTermsFormatting';
import cypressTags from 'support/cypressTags';

const { CALCULATED_VALUES_SECTION } = cypressTags.DEAL_FORM;
export const GRID_LAYOUT = 'grid grid-cols-2 xl:grid-cols-[160px_1fr_70px_1fr] gap-x-2 gap-y-5 items-center';

interface iCalculatedData extends iOrganismCalculatedValues {
  splitPoint: iCurrencyField;
  breakEven: iCurrencyField;
  walkoutPotential: iCurrencyField;
  gross: iCurrencyField;
  net: iCurrencyField;
  amountDisplayed: iCurrencyField;
}
const CalculatedValuesForm: React.FC<iFormProps> = ({ deal, setDeal, calculations, isOpen, locked }) => {
  const [calculatedData, setCalculatedData] = useState<iCalculatedData | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [terms, setTerms] = useState<any>({ currency: { label: '', value: '' } });
  const onSubmit = async (data: iOrganismCalculatedValues) => {
    try {
      setCanSubmit(false);
      const updatedDeal = await updateDeal(deal._id, {
        ...deal,
        overrideSplitPoint: Number(data.splitPointOverride.value),
        overrideBreakEven: Number(data.breakEvenOverride.value),
        overrideWalkoutPotential: Number(data.walkoutOverride.value),
        overrideNetPotential: Number(data.netOverride.value),
      });
      if (setDeal && updatedDeal) {
        setDeal(updatedDeal);
      }
      setTimeout(() => setCanSubmit(true), 500);
    } catch (error) {
      console.log('Error updating Calculated Values', error);
      setCanSubmit(true);
    }
  };

  useEffect(() => {
    if (deal) {
      setTerms(getTerms(deal));
    }
    if (calculations && calculations.deals) {
      const dealCalculations = calculations.deals[deal._id];
      const data: iCalculatedData = {
        splitPoint: {
          value: (dealCalculations && dealCalculations.splitPoint.amount) || 0,
          currency: (dealCalculations && dealCalculations.splitPoint.currency) || terms.currency.value,
        },
        splitPointOverride: {
          value: deal.overrideSplitPoint || 0,
          currency: terms.currency.value,
        },
        breakEven: {
          value: (dealCalculations && dealCalculations.calculatedBreakEven.amount) || 0,
          currency: (dealCalculations && dealCalculations.calculatedBreakEven.currency) || terms.currency.value,
        },
        breakEvenOverride: {
          value: deal.overrideBreakEven || 0,
          currency: terms.currency.value,
        },
        walkoutPotential: {
          value: (dealCalculations && dealCalculations.calculatedWalkoutPotential.amount) || 0,
          currency: (dealCalculations && dealCalculations.calculatedWalkoutPotential.currency) || terms.currency.value,
        },
        walkoutOverride: {
          value: deal.overrideWalkoutPotential || 0,
          currency: terms.currency.value,
        },
        gross: {
          value: (dealCalculations && dealCalculations.estimatedGrossPotential.amount) || 0,
          currency: (dealCalculations && dealCalculations.estimatedGrossPotential.currency) || terms.currency.value,
        },
        net: {
          value: (dealCalculations && dealCalculations.estimatedNetPotential.amount) || 0,
          currency: (dealCalculations && dealCalculations.estimatedNetPotential.currency) || terms.currency.value,
        },
        netOverride: {
          value: deal.overrideNetPotential || 0,
          currency: terms.currency.value,
        },
        amountDisplayed: {
          value:
            deal.overrideWalkoutPotential ||
            (dealCalculations && dealCalculations.calculatedWalkoutPotential.amount) ||
            0,
          currency: terms.currency.value,
        },
      };
      setCalculatedData(data);
    }
  }, [deal, calculations]);

  const handleWalkoutChange = (
    value: iCurrencyField,
    index: number | undefined,
    setValueMethod?: UseFormSetValue<FieldValues>
  ) => {
    if (index) {
      // console.log('Not needed but will throw error if not in order');
    }
    if (calculatedData) {
      const display = value.value > 0 ? value : calculatedData.walkoutPotential;
      if (setValueMethod) {
        setValueMethod('amountDisplayed', display);
      }
    }
  };

  return (
    calculatedData && (
      <Form
        canSubmit={canSubmit}
        disabled={!isOpen || locked}
        onSubmit={onSubmit}
        defaultValues={calculatedData}
        formName={FORM_NAMES.CALCULATED_VALUES}
      >
        <div className="flex gap-x-10">
          <div className={GRID_LAYOUT}>
            <label className="form-label">Split Point</label>
            <TextField id="splitPoint" isCurrency text="--" className="leading-10" />
            <label className="form-label">Override</label>
            <Currency
              rules={{
                min: { value: 0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
              }}
              id="splitPointOverride"
              overrideCurrency={terms.currency.value}
              disabled={!isOpen || locked}
            />

            <label className="form-label col-start-1">Break Even</label>
            <TextField
              dataCy={CALCULATED_VALUES_SECTION.BREAK_EVEN_DISPLAY}
              id="breakEven"
              isCurrency
              text="--"
              className="leading-10"
            />
            <label className="form-label">Override</label>
            <Currency
              rules={{
                min: { value: 0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
              }}
              id="breakEvenOverride"
              overrideCurrency={terms.currency.value}
              disabled={!isOpen || locked}
              dataCy={CALCULATED_VALUES_SECTION.BREAK_EVEN_OVERRIDE_INPUT}
            />

            <label className="form-label">Walkout Potential</label>
            <TextField
              dataCy={CALCULATED_VALUES_SECTION.WALKOUT_POTENTIAL_DISPLAY}
              id="walkoutPotential"
              isCurrency
              text="--"
              className="leading-10"
            />
            <label className="form-label">Override</label>
            <Currency
              rules={{
                min: { value: 0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
              }}
              id="walkoutOverride"
              handleChange={handleWalkoutChange}
              overrideCurrency={terms.currency.value}
              disabled={!isOpen || locked}
              dataCy={CALCULATED_VALUES_SECTION.WALKOUT_POTENTIAL_INPUT}
            />
          </div>
          <div className={`${GRID_LAYOUT} grid-rows-5 xl:grid-rows-3`}>
            <label className="form-label">Gross</label>
            <TextField id="gross" isCurrency text="--" className="leading-10" />

            <label className="form-label col-start-1">Net</label>
            <TextField id="net" isCurrency text="--" className="leading-10" />
            <label className="form-label">Override</label>

            <Currency
              rules={{
                min: { value: 0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
              }}
              id="netOverride"
              overrideCurrency={terms.currency.value}
              disabled={!isOpen || locked}
            />

            <label className="form-label">Amount Displayed</label>
            <TextField
              dataCy={CALCULATED_VALUES_SECTION.AMAOUNT_DISPLAYED}
              id="amountDisplayed"
              isCurrency
              text="--"
              className="leading-10"
            />
          </div>
        </div>
      </Form>
    )
  );
};

export default CalculatedValuesForm;
