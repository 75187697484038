import { ReactComponent as Logo } from 'assets/logo.svg';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import MobileNav from 'features/MobileNav';
import Nav from 'features/nav-bar';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Desk } from 'types/RootState';
import SignOutIcon from 'assets/icons/SignOutIcon';
import { DropdownBasic, IdString } from 'components/dropdown-basic/DropdownBasic';
import { User } from 'types';

interface HeaderProps {
  banner?: string;
  currentDeskId: string;
  desks: Desk[];
  onChangeDesk: (val: string) => void;
  user: User | null;
}

export const Header = ({ banner, desks, user, currentDeskId, onChangeDesk }: HeaderProps): ReactElement => {
  const [currentDesk, setCurrentDesk] = useState<IdString | undefined>(undefined);
  const [options, setOptions] = useState<IdString[] | undefined>(undefined);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [showDeskDropdown, setShowDesksDropdown] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentDeskId && desks && desks.length) {
      setCurrentDesk(getDefaultDeskItem(currentDeskId));

      let deskOptions =
        desks &&
        desks.map((desk) => {
          return {
            id: desk._id,
            label: desk.name,
          };
        });

      setOptions([
        ...deskOptions,
        {
          id: 'logout',
          label: 'Log out',
        },
      ]);
    }
  }, [currentDeskId, desks]);

  const getDefaultDeskItem = (deskId: string) => {
    const foundDesk = desks.find((d) => d._id === deskId);
    return foundDesk ? { id: foundDesk._id, label: foundDesk.name || '' } : undefined;
  };
  return (
    <header className="w-full h-20 flex justify-center items-center print:hidden">
      {banner && (
        <div className="fixed z-50 px-4 md:px-10 bg-sushi text-center font-extrabold leading-6 text-black ml-auto top-0">
          {banner}
        </div>
      )}
      <div className="w-full content-wrapper h-20 justify-between items-center fixed flex ">
        <div className="w-[173px] h-12 text-white ">
          <a href="/" className="flex items-center">
            <Logo style={{ color: 'white', width: 45, height: 48 }} />
            <div className="ml-4 text-xs">
              <span className="font-bold">ONYX</span>
              <span className="px-2">|</span>
              <span className="font-thin">TOURING</span>
            </div>
          </a>
        </div>

        <MobileNav isOpen={isHamburgerOpen} setIsOpen={setIsHamburgerOpen}>
          <div className="ml-0 z-[21] ">
            <Nav
              className="flex flex-col items-start gap-y-8 mt-10 mb-8 md:mb-10 "
              setIsOpen={setIsHamburgerOpen}
              mobileView={true}
              user={user}
            />
          </div>
          <div className="w-full ">
            <div className="w-full ">
              <div
                className={`w-full text-start md:text-center text-lg md:text-nav leading-4 no-underline text-black font-medium flex`}
              >
                <button
                  className="flex pl-8 mb-6"
                  onClick={() => {
                    setShowDesksDropdown(!showDeskDropdown);
                  }}
                >
                  Desk
                </button>
                <div className="w-full flex justify-end pr-8">
                  <ChevronDownIcon
                    color="black"
                    width={17}
                    strokeWidth={'3'}
                    className={`mb-6 ` + `transition-all duration-200 ${showDeskDropdown ? 'rotate-180' : ''}`}
                    onClick={() => setShowDesksDropdown(!showDeskDropdown)}
                  />
                </div>
              </div>
              {showDeskDropdown &&
                (options || [])
                  .filter((item) => item.id !== 'logout')
                  .map((item, index: number) => (
                    <div
                      key={index}
                      className={` w-full pr-8 ${currentDesk?.label === item.label ? `bg-greyQuill` : ``}`}
                    >
                      <div className="py-5 w-full pl-5">
                        <div
                          className="w-full flex justify-between md:text-center text-md md:text-nav leading-4 no-underline text-black font-normal"
                          onClick={() => onChangeDesk(item.id as string)}
                        >
                          <span
                            className={`pl-8 leading-normal inline-block ${
                              currentDesk?.label === item.label ? `font-bold` : ``
                            }`}
                          >
                            {item.label}
                          </span>
                          {currentDesk?.label === item.label && (
                            <span className="leading-normal inline-block">
                              <CheckIcon
                                color="black"
                                strokeWidth={'3'}
                                width={17}
                                className="rotate-[15deg] stroke-3"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                      {options && index < options.length - 2 && (
                        <div className="w-full bg-greyQuill h-px mt-2 ml-4"></div>
                      )}
                    </div>
                  ))}
            </div>
            <div className="w-full bg-greyQuill h-px mt-2"></div>{' '}
          </div>
          <div
            className={`w-full text-start md:text-center text-lg md:text-nav leading-4 no-underline text-black font-medium mt-6`}
          >
            <a href={'/logout'}>
              <span className="flex pl-8">
                <SignOutIcon width={30} className={'mr-3'} />
                Sign out
              </span>
            </a>
            <div className="w-full bg-greyQuill h-px mt-8"></div>
          </div>
        </MobileNav>

        <div className="hidden md:flex items-center ">
          <div className="ml-auto z-[21]">
            <Nav className="flex items-center gap-x-8" mobileView={false} user={user} />
          </div>

          <div className="w-auto h-auto z-20 ml-8">
            <DropdownBasic
              isAutoExpand
              isCloseOnMouseLeave
              label={currentDesk?.label || 'No desks'}
              items={options}
              onUpdate={(chosen) => {
                if (chosen.id === 'logout') {
                  navigate('/logout');
                } else {
                  onChangeDesk(chosen.id as string);
                }
              }}
              propsStyles={`text-white bg-black bg-opacity-20`}
              listStyles={`bg-white text-black shadow-dropdown border-none py-2 overflow-auto min-w-full`}
            />
          </div>
        </div>
      </div>
    </header>
  );
};
