import { FC } from 'react';
import BlankCell from '../BlankCell';

export type DefaultRendererProps = {
  value?: number;
};

const DefaultRenderer: FC<DefaultRendererProps> = ({ value }) => <>{value || <BlankCell />}</>;

export default DefaultRenderer;
