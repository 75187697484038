import { PlusCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import { getClientData } from 'api/clients-routing';
import DocumentExportIcon from 'assets/icons/DocumentExportIcon';
import RejectDealModal from 'containers/deals/RejectDealModal';
import { NewDealModal } from 'containers/deals/NewDealModal';
import { iOptions } from 'deal-form/interfaces/general';
import { SetState, iModalState } from 'features/routing/context';
import { doCsvExportFromData, formatRoutingDataForExport } from 'grid-pages/utils/export-helpers';
import moment from 'moment';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { User } from 'types';
import { getArtistThumbUrl, isValidUrl } from 'utils/cloudinary';
import { getDateFormatByBrowser, slugify } from 'utils/helpers';

import Button from 'components/Button';
import ArtistBannerBg from 'components/artist-banner-bg';

import { MonthDatePicker } from '../../../components/month-date-picker/monthDatePicker';
import { Pill } from '../../../components/pill/Pill';
import { RoutingContext, RoutingContextProvider } from '../../../features/routing';
import RoutingTable from '../../../pages/RoutingTable';
import CypressTags from '../../../support/cypressTags';
import { EntityDesignateType, EntityType } from '../../../types/data-schemas';
import { RouteStop } from '../../../types/routes';
import { getEntityProfileUrl } from '../../../urls';
import { EditColumnsMenu } from '../EditColumnsMenu';
import cypressTags from '../../../support/cypressTags';

export interface Violation {
  _id?: string;
  stop?: string | RouteStop;
  violatesUp?: boolean;
  violatesDown?: boolean;
}

interface ClientRoutingProps {
  artistName: string;
  artistId: string;
  artistImgUrl?: string;
  user: User;
}

const THUMBNAIL_WIDTH_HEIGHT = 160;

export const ClientRouting = ({ artistName, artistId, artistImgUrl, user }: ClientRoutingProps): ReactElement => {
  const {
    fetchTableRows,
    setArtistId,
    selectedDate,
    setUser,
    showNewDealModal,
    setShowNewDealModal,
    showRejectDealModal,
    setShowRejectDealModal,
  } = useContext(RoutingContext);

  const [artistImage, setArtistImage] = useState('');

  useEffect(() => {
    setUser(user);
  }, [user]);

  useEffect(() => {
    setArtistId(artistId);
  }, [artistId]);

  useEffect(() => {
    void fetchTableRows(artistId);
  }, [selectedDate]);

  useEffect(() => {
    async function fetchArtistImage() {
      const imageUrl = await getArtistThumbUrl(artistImgUrl || '', THUMBNAIL_WIDTH_HEIGHT, THUMBNAIL_WIDTH_HEIGHT); // Assuming the image URL is in the response as text
      setArtistImage(imageUrl);
    }
    fetchArtistImage();
  }, []);

  return (
    <>
      <div className="bg-black pt-20 md:pt-[113px] z-10 relative h-[270px] md:h-[300px]">
        <div className="w-screen">
          {artistImage && <ArtistBannerBg imageUrl={artistImage} />}
          <div className="relative content-wrapper header">
            <ArtistBanner
              user={user}
              artistId={artistId}
              artistName={artistName}
              artistImgUrl={artistImgUrl}
              handleShowNewDeal={setShowNewDealModal}
            />
            <div className="mt-6">
              <DateSelector />
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrapper">
        <div className="w-full content-wrapper my-10">
          <RoutingTable user={user} />
        </div>
      </div>

      <RejectDealModal
        setShowRejectDealModal={setShowRejectDealModal}
        dealId={showRejectDealModal.dealId || ''}
        showModal={showRejectDealModal.isOpen}
      />

      <NewDealModal
        client={artistId}
        title="ADD NEW DEAL"
        showClose
        showDate={showNewDealModal.date}
        setShowNewDealModal={setShowNewDealModal}
        showModal={showNewDealModal.isOpen}
        fetchTableRows={fetchTableRows}
        dataCyModalTitle={cypressTags.ROUTING.ADD_NEW_DEAL_MODAL.ADD_NEW_DEAL_TITLE}
      />
    </>
  );
};

interface ClientRoutingPageProps {
  user: User;
}

export const ClientRoutingPage = ({ user }: ClientRoutingPageProps): ReactElement => {
  const [artistData, setArtistdata] = useState<{
    artistId: string;
    artistName: string;
    artistImgUrl?: string;
  } | null>(null);

  const { artistId, artistName } = useParams<{
    artistId: string;
    artistName: string;
  }>();

  useEffect(() => {
    if (artistId && artistName && !artistData) {
      const getProfilePic = async () => {
        const data = await getClientData(artistId);
        const artistImgUrl = (data && isValidUrl(data?.profile_pic || '') && data?.profile_pic) || undefined;
        setArtistdata({ artistId: artistId, artistName: artistName, artistImgUrl: artistImgUrl });
      };
      getProfilePic();
    }
  }, [artistId, artistName]);

  if (artistData) {
    return (
      <div className="flex flex-col overflow-hidden">
        <RoutingContextProvider>
          <ClientRouting
            {...{
              ...artistData,
              user,
            }}
          />
        </RoutingContextProvider>
        {/* 
      - REMOVED THE MAP
       <RoutingMapContextProvider>
        <ClientRouteMap artistId={artistId} />
      </RoutingMapContextProvider> 
      */}
      </div>
    );
  } else {
    return <div />;
  }
};

interface ArtistBannerProps {
  user: User;
  artistId: string;
  artistName: string;
  artistImgUrl?: string;
  handleShowNewDeal: SetState<iModalState>;
}

const ArtistBanner = ({ artistId, artistName, artistImgUrl, handleShowNewDeal }: ArtistBannerProps) => {
  const href = getEntityProfileUrl(EntityDesignateType.Person, artistId, EntityType.Client);
  const { numberViolations } = useContext(RoutingContext);
  const [artistImage, setArtistImage] = useState('');

  useEffect(() => {
    async function fetchArtistImage() {
      const imageUrl = await getArtistThumbUrl(artistImgUrl || '', THUMBNAIL_WIDTH_HEIGHT, THUMBNAIL_WIDTH_HEIGHT); // Assuming the image URL is in the response as text
      setArtistImage(imageUrl);
    }
    fetchArtistImage();
  }, []);

  return (
    <div className="flex items-center gap-y-4 md:items-end flex-col sm:flex-row lg:items-center md:justify-between text-white">
      <div className="flex">
        <div className="aspect-square w-20 h-20 mr-6 overflow-hidden flex items-center justify-center">
          {artistImage ? (
            <img src={artistImage} alt={artistName} />
          ) : (
            <UserIcon className="w-full h-full bg-white text-black mix-blend-screen" />
          )}
        </div>
        <div className="flex flex-col justify-between">
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white no-underline cursor-pointer text-xl md:text-3xl font-extended uppercase"
            data-cy={cypressTags.ROUTING.CLIENT_TITLE_LINK}
          >
            {artistName}
          </a>
          <div>Routing</div>
        </div>

        {numberViolations > 0 && (
          <div data-cy={CypressTags.ROUTING.VIOLATION_BANNER_COUNT}>
            <Pill
              type="warning"
              style={{ marginLeft: 33 }}
              pillText={`WARNING: ${numberViolations} Radius Clause Violation${numberViolations > 1 ? 's' : ''}`}
            />
          </div>
        )}
      </div>

      <div className="flex items-center justify-between gap-3">
        <Button
          onClick={() => {
            handleShowNewDeal({ isOpen: true });
          }}
          variant="primary"
        >
          <span className="hidden lg:block" data-cy={cypressTags.ROUTING.CREATE_DEAL_BUTTON}>
            Create Deal
          </span>
          <span className="block lg:hidden">
            <PlusCircleIcon title="Create Deal" height={24} />
          </span>
        </Button>

        <EditColumnsMenu />

        <ExportToCSVDropdown artistName={artistName} />
      </div>
    </div>
  );
};

const ExportToCSVDropdown = ({ artistName }: { artistName: string }) => {
  const { gridApi, columnApi, tableRows } = useContext(RoutingContext);
  const [printerOptionsOpen, setPrinterOptionsOpen] = useState(false);

  const onExportWithFinancials = async () => {
    if (gridApi && columnApi) {
      const filteredRows = tableRows?.filter((row) => row.deal !== undefined);
      const visibleCols = columnApi
        .getAllDisplayedColumns()
        .filter((col) => !['timeline', 'actions'].includes(col.getColId()));
      const formattedData: string[][] = formatRoutingDataForExport(filteredRows, visibleCols);

      //do export
      doCsvExportFromData(formattedData, `routing-${slugify(artistName)}`);
    }

    setPrinterOptionsOpen(false);
  };

  const onExportWithoutFinancials = async () => {
    if (gridApi && columnApi) {
      gridApi.exportDataAsCsv({
        fileName: `routing-${slugify(artistName)}-${moment().format(getDateFormatByBrowser())}.csv`,
        columnKeys: columnApi
          .getAllDisplayedColumns()
          .map((c) => c.getColId())
          .filter((c) => !['walkoutPotential', 'softMerch', 'hardMerch', 'guarantee', 'sellableCapacity'].includes(c)) // Filters out financial data
          .filter((c) => !['timeline', 'actions'].includes(c)),
        processCellCallback: (params) => {
          if (params.column.getColId() === 'date') {
            return moment.utc(params.value).format(getDateFormatByBrowser());
          }
          return params.value;
        },
      });
    }

    setPrinterOptionsOpen(false);
  };

  const handleSelect = ({ id }: iOptions) => {
    if (id === 'financialData') {
      onExportWithFinancials();
    } else {
      // alert('generate deal memo');
      onExportWithoutFinancials();
    }
  };

  const items = [
    { id: 'financialData', label: 'WITH Financial Data' },
    { id: 'noFinancialData', label: 'WITHOUT Financial Data' },
  ];

  return (
    <>
      <div
        className={`cursor-pointer w-full rounded  select-none relative  ${printerOptionsOpen ? 'active z-50' : ''}
          after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3  text-lg`}
        onClick={() => {
          setPrinterOptionsOpen(!printerOptionsOpen);
        }}
      >
        <Button rightIcon={<DocumentExportIcon />} onClick={() => {}}>
          <span className="hidden lg:block">Export to CSV</span>
        </Button>

        <ul
          className={`border whitespace-nowrap list-none m-0 p-0 absolute top-[105%] right-0 xl:right-auto xl:left-0 transition-opacity duration-300 max-h-[300px] bg-white shadow-dropdown z-10 ${
            printerOptionsOpen ? 'opacity-1 pointer-events-auto z-10' : 'opacity-0 pointer-events-none'
          }`}
        >
          {items &&
            items.map((item) => (
              <li
                key={item.id}
                className="px-4 py-2 text-sm text-black font-medium flex flex-row items-center hover:underline hover:opcity-50"
                onClick={() => handleSelect(item)}
              >
                <DocumentExportIcon width={16} className="hover:cursor-pointer mr-2" /> {item.label}
              </li>
            ))}
        </ul>
      </div>
      {printerOptionsOpen && (
        <div
          // Make this layer focusable to support closing on focus
          tabIndex={0}
          role="button"
          className="fixed top-0 left-0 opacity-0 w-[100vw] h-[100vh] z-0"
          onFocus={() => {
            setPrinterOptionsOpen(false);
          }}
          onClick={() => {
            setPrinterOptionsOpen(false);
          }}
        ></div>
      )}
    </>
  );
};

const DateSelector = () => {
  const { selectedDate, setSelectedDate } = useContext(RoutingContext);
  const [month, setMonth] = useState(moment().format('MMMM'));
  const [year, setYear] = useState(moment().year().toString());
  const [date, setDate] = useState(moment().format('MM/DD/YYYY'));

  const createYearsList = () => {
    const yearsList = [];
    const dateStart = moment().subtract(2, 'y');
    const dateEnd = moment().add(5, 'y');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      yearsList.push(dateStart.format('YYYY'));
      dateStart.add(1, 'year');
    }
    return yearsList;
  };

  useEffect(() => {
    // Only set the selectedDate if the local date time for this component is different.
    // Not checking for this will cause needless endpoint calls to GET routing
    // data for this "new" date even though the date is the same but the exact
    // time during that day is different.
    if (selectedDate.getTime() !== new Date(date).getTime()) {
      setSelectedDate(new Date(date));
    }
  }, [date]);

  const handleMonthChanged = (changedMonth: string) => {
    setMonth(changedMonth);
    const changedMonthNumber = moment().month(changedMonth).format('MM');
    setDate(moment(`${year}-${changedMonthNumber}-01`).format('MM/DD/YYYY'));
  };

  const handleYearChanged = (changedYear: string) => {
    setMonth(month);
    setYear(changedYear);
    const updatedMonth = moment(selectedDate).format('MM');
    const updatedDate = moment(selectedDate).format('DD');
    setDate(moment(`${changedYear}-${updatedMonth}-${updatedDate}`).format('MM/DD/YYYY'));
  };

  const handleDateChanged = (changedDate: string) => {
    setMonth(moment(changedDate).format('MMMM'));
    setYear(moment(changedDate).format('YYYY'));
    setDate(moment(changedDate).format('MM/DD/YYYY'));
  };

  return (
    <MonthDatePicker
      month={month}
      date={date}
      year={year}
      years={createYearsList()}
      onChangeMonth={(m: string) => handleMonthChanged(m)}
      onChangeYear={(y: string) => handleYearChanged(y)}
      onChangeDate={(d: string) => handleDateChanged(d)}
    />
  );
};
