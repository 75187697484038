import { updateDeal } from 'api';
import { FORM_NAMES } from 'deal-form/data/constants';
import { Currency } from 'deal-form/form-controls/Currency';
import { TextField } from 'deal-form/form-controls/TextField';
import { iFormProps } from 'deal-form/interfaces/general';
import { iCurrencyField, iOrganismSettlementTotals } from 'deal-form/interfaces/organisms';
import React, { useEffect, useState } from 'react';
import { Form } from '../../form-controls/Form';
import { getMasterShow } from '../../helpers/showHelpers';
import { Input } from 'deal-form/form-controls/Input';
import submitToNetsuite from 'utils/submitToNetsuite';
import { useArtistCurrency } from 'deal-form/helpers/useArtistCurrency';
import { getTicketCurrency } from 'deal-form/helpers/dealHelpers';
import cypressTags from 'support/cypressTags';

const { SETTLEMENTS_SECTION } = cypressTags.DEAL_FORM;
const { TOTALS } = SETTLEMENTS_SECTION;
interface iTotalsData extends iOrganismSettlementTotals {
  grossBoxOfficeRevenue: iCurrencyField;
  netBoxOfficeRevenue: iCurrencyField;
  overrideNetPotential: iCurrencyField;
  overrideWalkoutPotential: iCurrencyField;
  guarantee: iCurrencyField;
  dealDisplay: string;
  totalPayout: iCurrencyField;
  agencyCommission: iCurrencyField;
  paymentsRequested: iCurrencyField;
  paymentsReceived?: iCurrencyField;
  buyoutsRequested: iCurrencyField;
  buyoutsReceived?: iCurrencyField;
  clientReductions?: iCurrencyField;
  agencyReductions?: iCurrencyField;
  totalTicketsSold: number;
  compTickets: number;
  totalCompTickets: number;
}

const SettlementTotalsForm: React.FC<iFormProps> = ({ deal, contract, calculations, setDeal, isOpen, locked }) => {
  const [totalsData, setTotalsData] = useState<iTotalsData | null>(null);
  const [canSubmitSettlement, setCanSubmitSettlement] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [ticketCurrency, setTicketCurrency] = useState(getTicketCurrency(deal));
  const artistCurrency = useArtistCurrency(deal);

  const GRID_LAYOUT = 'grid grid-cols-[240px_1fr] gap-5 items-center py-5 border-b border-greyQuill';

  useEffect(() => {
    let ticket = getTicketCurrency(deal);
    setTicketCurrency(ticketCurrency);

    if (deal && calculations && calculations.deals && contract) {
      const dealCalculations = calculations.deals[deal._id];
      const dealTerms = getMasterShow(deal.shows).performers[0].terms;
      const getTermType = Object.keys(dealTerms).filter((key) => key !== '_id')[0];
      const dealTermsDisplay = dealTerms[getTermType].contractDisplay;
      if (
        deal.shows.filter(({ date }) => {
          return new Date(date) > new Date();
        }).length === 0 &&
        contract.netsuiteStatus === 'Set'
      ) {
        setCanSubmitSettlement(true);
      }

      const dataObject: iTotalsData = {
        totalTicketsSold: deal.totalTicketsSold || 0,
        compTickets: deal.compTickets || 0,
        totalCompTickets: deal.totalCompTickets || 0,
        grossBoxOfficeRevenue: {
          value: deal.grossBoxOfficeRevenue || 0,
          currency: ticket,
        },
        netBoxOfficeRevenue: {
          value: deal.netBoxOfficeRevenue || 0,
          currency: ticket,
        },
        overrideNetPotential: {
          value: deal.overrideNetPotential || 0,
          currency: artistCurrency?.id || '',
        },
        overrideWalkoutPotential: {
          value: deal.overrideWalkoutPotential || 0,
          currency: artistCurrency?.id || '',
        },
        guarantee: {
          value: deal.guarantee || 0,
          currency: artistCurrency?.id || '',
        },
        dealDisplay: dealTermsDisplay || '',
        overage: {
          value: deal.overage || 0,
          currency: artistCurrency?.id || '',
        },
        overageNotes: deal.overageNotes || '',
        clientReductions: {
          value: deal.totalClientReductions || 0,
          currency: deal.currency,
        },
        agencyReductions: {
          value: deal.totalAgencyReductions || 0,
          currency: deal.currency || 0,
        },
        totalPayout: {
          value: (dealCalculations && dealCalculations.totalPayout.amount) || 0,
          currency: (dealCalculations && dealCalculations.totalPayout.currency) || deal.currency,
        },
        agencyCommission: {
          value: (dealCalculations && dealCalculations.agencyCommission.amount) || 0,
          currency: (dealCalculations && dealCalculations.agencyCommission.currency) || deal.currency,
        },
        paymentsRequested: {
          value: (dealCalculations && dealCalculations.expectedPaymentsToClientTotal.amount) || 0,
          currency: (dealCalculations && dealCalculations.expectedPaymentsToClientTotal.currency) || deal.currency,
        },
        paymentsReceived: {
          value: dealCalculations && dealCalculations.actualPaymentsToClientTotal.amount,
          currency: (dealCalculations && dealCalculations.actualPaymentsToClientTotal.currency) || deal.currency,
        },
        buyoutsRequested: {
          value: (dealCalculations && dealCalculations.expectedBuyoutsTotal.amount) || 0,
          currency: (dealCalculations && dealCalculations.expectedBuyoutsTotal.currency) || deal.currency,
        },
        buyoutsReceived: {
          value: dealCalculations && dealCalculations.actualBuyoutsTotal.amount,
          currency: (dealCalculations && dealCalculations.actualBuyoutsTotal.currency) || deal.currency,
        },
        taxWithholding: {
          value: (dealCalculations && dealCalculations.taxWithholding.amount) || 0,
          currency: (dealCalculations && dealCalculations.taxWithholding.currency) || deal.currency,
        },
        taxWithholdingPercentage:
          (dealCalculations &&
            Math.round(
              (dealCalculations.taxWithholding.amount /
                (deal.overrideGrossBoxOfficeReceipts || dealCalculations.grossBoxOfficeRevenue.amount)) *
                100
            )) ||
          0,
      };
      setTotalsData(dataObject);
    }
  }, [deal, calculations, contract]);

  const onSubmit = async (values: iOrganismSettlementTotals) => {
    try {
      setCanSubmit(false);

      const updatedDeal = await updateDeal(deal._id, {
        ...deal,
        totalTicketsSold: values.totalTicketsSold,
        totalCompTickets: values.totalCompTickets,
        grossBoxOfficeRevenue: values.grossBoxOfficeRevenue.value,
        netBoxOfficeRevenue: values.netBoxOfficeRevenue.value,
        guarantee: deal?.guarantee,
        overage: values.overage.value,
        overageNotes: values.overageNotes,
        taxWitholding: values.taxWithholding.value,
      });

      if (updatedDeal && setDeal) setDeal(updatedDeal);

      if (canSubmitSettlement) {
        submitToNetsuite(contract, deal, true, setDeal);
      }
      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('there was an error saving');
      setCanSubmit(true);
    }
  };

  return (
    <>
      {totalsData && (
        <div>
          <Form
            canSubmit={canSubmit}
            onSubmit={onSubmit}
            defaultValues={totalsData}
            disabled={!isOpen || locked}
            formName={FORM_NAMES.SETTLEMENT_TOTALS}
          >
            <div className={`${GRID_LAYOUT} grid-cols-[240px_220px_auto_1fr]`}>
              <label htmlFor="totalTicketsSold" className="form-label">
                Total Ticket Sold
              </label>

              <Input
                label={false}
                fieldType="number"
                id="totalTicketsSold"
                disabled={!isOpen || locked}
                containerClassName="max-w-[512px]"
                dataCy={TOTALS.TOTAL_TICKETS_SOLD}
              />
            </div>
            <div className={`${GRID_LAYOUT} grid-cols-[240px_220px_auto_1fr]`}>
              <label htmlFor="totalCompTickets" className="form-label">
                Total Comps
              </label>

              <Input
                label={false}
                fieldType="number"
                id="totalCompTickets"
                disabled={!isOpen || locked}
                containerClassName="max-w-[512px]"
                dataCy={TOTALS.TOTAL_COMPS}
              />
            </div>

            <div className={`${GRID_LAYOUT} grid-cols-[240px_220px_auto_1fr]`}>
              <label htmlFor="grossBoxOfficeRevenue" className="form-label">
                Gross Box Office Revenue
              </label>
              <Currency
                id="grossBoxOfficeRevenue"
                overrideCurrency={String(ticketCurrency) || ''}
                containerClassName="max-w-[240px]"
                disabled={!isOpen || locked}
                rules={{
                  min: { value: 0, message: 'Please select a dollar amount with two decimal places' },
                }}
                dataCy={TOTALS.GBOR}
              />
            </div>

            <div className={`${GRID_LAYOUT} grid-cols-[240px_220px_auto_1fr]`}>
              <label htmlFor="netBoxOfficeRevenue" className="form-label">
                Net Box Office Revenue
              </label>
              <Currency
                id="netBoxOfficeRevenue"
                overrideCurrency={String(ticketCurrency) || ''}
                containerClassName="max-w-[240px]"
                disabled={!isOpen || locked}
                rules={{
                  min: { value: 0, message: 'Please select a dollar amount with two decimal places' },
                }}
                dataCy={TOTALS.NBOR}
              />
            </div>

            <div className={GRID_LAYOUT}>
              <label className="form-label">Total Guarantee</label>
              <TextField text="--" id="guarantee" isCurrency className="leading-10" dataCy={TOTALS.TOTAL_GUARANTEE} />
            </div>

            <div className={GRID_LAYOUT}>
              <label className="form-label">Deal</label>
              <TextField id="dealDisplay" text="--" className="leading-10">
                {totalsData.dealDisplay}
              </TextField>
            </div>

            <div className={`${GRID_LAYOUT} grid-cols-[240px_220px_auto_1fr]`}>
              <label htmlFor="overage" className="form-label">
                Overage
              </label>
              <Currency id="overage" overrideCurrency={artistCurrency?.id || ''} containerClassName="max-w-[512px]" />

              <label htmlFor="overageNotes" className="form-label">
                Notes
              </label>
              <Input
                label={false}
                fieldType="text"
                rules={{}}
                id="overageNotes"
                disabled={!isOpen || locked}
                containerClassName="max-w-[512px]"
              />
            </div>

            <div className={GRID_LAYOUT}>
              <label className="form-label">Total Payout</label>
              <TextField text="--" id="totalPayout" isCurrency className="leading-10" dataCy={TOTALS.TOTAL_PAYOUTS} />
            </div>

            <div className={GRID_LAYOUT}>
              <label className="form-label">Payments Requested</label>
              <TextField text="--" id="paymentsRequested" isCurrency className="leading-10" />
            </div>

            <div className={GRID_LAYOUT}>
              <label className="form-label">Payments Received</label>
              <TextField
                text="--"
                id="paymentsReceived"
                isCurrency
                className="leading-10"
                dataCy={TOTALS.PAYMENTS_RECIEVED}
              />
            </div>

            <div className={GRID_LAYOUT}>
              <label className="form-label">Buyouts Requested</label>
              <TextField text="--" id="buyoutsRequested" isCurrency className="leading-10" />
            </div>

            <div className={GRID_LAYOUT}>
              <label className="form-label">Buyouts Received</label>
              <TextField text="--" id="buyoutsReceived" isCurrency className="leading-10" />
            </div>

            <div className={`${GRID_LAYOUT} grid-cols-[240px_512px_70px_100px] mb-10`}>
              <label htmlFor="taxWithholding" className="form-label">
                Tax Withholding
              </label>

              <Currency
                id="taxWithholding"
                overrideCurrency={artistCurrency?.id || ''}
                disabled={!isOpen || locked}
                rules={{
                  min: { value: 0, message: 'Please select a dollar amount with two decimal places' },
                }}
              />

              <label htmlFor="taxWithholding" className="form-label">
                Percentage
              </label>
              <Input
                label={false}
                fieldType="percent"
                readOnly
                id="taxWithholdingPercentage"
                disabled={!isOpen || locked}
              />
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default SettlementTotalsForm;
