import { updateDeal } from 'api';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  PAYMENTS_TO_CLIENT_TYPE,
  PAYMENT_TO_CLIENT_EMPTY_FORM,
  PAYMENT_TO_CLIENT_EMPTY_ITEM,
} from 'deal-form/data/organisms';
import { Currency } from 'deal-form/form-controls/Currency';
import { iFormProps } from 'deal-form/interfaces/general';
import { iOrganismClientPayments } from 'deal-form/interfaces/organisms';
import React, { useMemo, useState } from 'react';
import { Payment } from 'types/deal';

import { FieldValues, UseFormGetValues, UseFormReset } from 'react-hook-form';
import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import submitToNetsuite from 'utils/submitToNetsuite';

export const COLUMN_LABELS = [
  { value: 'Amount', isRequired: true },
  { value: 'Type', isRequired: true },
  { value: 'Description' },
];
export const GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_3fr] gap-2 ';

const PaymentsToClientForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked, contract }) => {
  const [canSubmit, setCanSubmit] = useState(true);

  const onSubmit = async (data: iOrganismClientPayments) => {
    try {
      setCanSubmit(false);
      const updatedPayments: Payment[] = [];

      if (data.payments.length > 0) {
        data.payments.forEach(({ amount, clientPaymentType, memo }) => {
          const payment: Payment = {
            amount: amount.value,
            currency: deal.currency.toUpperCase(),
            type: clientPaymentType,
            memo,
          };
          updatedPayments.push(payment);
        });
      }

      const updatedDeal = await updateDeal(deal._id, {
        ...deal,
        paymentsToClient: updatedPayments,
      });

      if (updatedDeal && setDeal) setDeal(updatedDeal);

      const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';

      if (isDealSubmittedToNetsuite) {
        submitToNetsuite(contract, deal, false);
      }

      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR updated payments', e);
      setCanSubmit(true);
    }
  };

  const defaultValues = useMemo(
    () =>
      deal.paymentsToClient.length === 0
        ? PAYMENT_TO_CLIENT_EMPTY_FORM
        : {
            payments: deal.paymentsToClient.map((payment) => {
              return {
                amount: {
                  currency: deal.currency.toLowerCase(),
                  value: payment.amount,
                },
                clientPaymentType: payment.type,
                memo: payment.memo,
              };
            }),
          },
    [deal]
  );

  /**
   * override the built in append functionality
   *
   * @param index row to be removed
   */
  const handleRemoveOverride = async (
    index: number,
    reset: UseFormReset<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>
  ) => {
    const vals: iOrganismClientPayments = getValues?.() as iOrganismClientPayments;

    if (vals?.payments?.length) {
      const isNew = vals.payments?.[index]?.isNew || false;
      vals.payments.splice(index, 1);

      if (isNew) {
        reset(vals);
      } else {
        onSubmit(vals);
      }
    } else {
      reset();
    }
  };

  return (
    <Form
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      className={`form-row full-width ${canSubmit ? '' : 'opacity-50'}`}
      defaultValues={defaultValues}
      disabled={!isOpen || locked}
      formName={FORM_NAMES.PAYMENTS_TO_CLIENT}
    >
      <FieldArray
        gridClassName={GRID_LAYOUT}
        groupName="payments"
        columns={COLUMN_LABELS}
        addButtonLabel="Add Payment"
        emptyRow={PAYMENT_TO_CLIENT_EMPTY_ITEM}
        disabled={!isOpen || locked || !canSubmit}
        overrideRemove={handleRemoveOverride}
      >
        <Currency
          id="amount"
          overrideCurrency={deal.currency}
          disabled={!isOpen || locked}
          rules={{
            required: ERROR_MESSAGES.REQUIRED_FIELDS,
            min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
          }}
        />
        <Dropdown
          placeholder="Payment Type"
          id="clientPaymentType"
          options={PAYMENTS_TO_CLIENT_TYPE}
          disabled={!isOpen || locked}
          rules={{ required: "Please select a valid 'Type' option" }}
        />
        <TextArea
          placeholder="Type your description here."
          rules={{
            maxLength: {
              value: 1024,
              message: getMaxCharMessage('the description', 1024),
            },
          }}
          id="memo"
          rows={1}
          disabled={!isOpen || locked}
        />
      </FieldArray>
    </Form>
  );
};

export default PaymentsToClientForm;
