import algoliasearch from 'algoliasearch/lite';
import { defaultAvailsSearchResult } from 'containers/availability/constants';
import { AvailsFilters, AvailsSearchResult, CustomShowsHitType, DateRange } from 'context/avails-filters/hook';

export const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID || ' ',
  process.env.REACT_APP_ALGOLIA_API_KEY || ' '
);

export const searchClient = async (searchIndex: any, searchQuery: string, searchParams: any) => {
  if (!searchIndex) return;
  const result = await searchIndex.search(searchQuery, searchParams);
  return result;
};

export const availsClientSearch = async (searchIndex: string, query: string, hitsPerPage = 50) => {
  try {
    const filters = `(vocations:'Musician' OR vocations:'Comedy (Live)') AND type:'client'`;
    const index = algoliaClient.initIndex(searchIndex);
    return await index.search(query, { filters, hitsPerPage, restrictSearchableAttributes: ['name'] });
  } catch (error) {
    return null;
  }
};

/**
 *
 * @description creates a filter string to send to algolia based on the filter passed as param
 * @param filters
 */
const createAvailsFilter = (filters: AvailsFilters): string => {
  const startDate = Math.floor(filters.dateRange.start.getTime());

  // Add 23 hours, 59 minutes, 59 seconds, and 999 milliseconds to the end date
  const endDate = Math.floor(filters.dateRange.end.getTime());

  const dateRangeFilter = `show.date_timestamp >= ${startDate} AND show.date_timestamp <= ${endDate}`;
  const confirmedFilter = filters.includeConfirmedShows ? 'show.status:"Confirmed"' : '';
  const genresFilter = filters.genres.length
    ? `(${filters.genres.map((genre) => `client.genres:"${genre}"`).join(' OR ')})`
    : '';
  const showTypeFilter = filters.showType && filters.showType !== 'any' ? `show.type:"${filters.showType}"` : '';
  const clientsFilter = filters.clients.length
    ? `(${filters.clients.map((client) => `client._id:"${client}"`).join(' OR ')})`
    : '';
  const guaranteeFilter = `guarantee.value >= ${filters.guarantee.min} AND guarantee.value <= ${filters.guarantee.max}`;

  // Combine all filter conditions
  const allFilters = [dateRangeFilter, confirmedFilter, genresFilter, showTypeFilter, clientsFilter, guaranteeFilter]
    .filter(Boolean)
    .join(' AND ');

  return allFilters;
};

export const applyAvailsSearch = async (
  searchIndex: string,
  filter: AvailsFilters,
  hitsPerPage = 10
): Promise<AvailsSearchResult> => {
  try {
    const filters = createAvailsFilter(filter);
    const index = algoliaClient.initIndex(searchIndex);

    const searchParams: any = {
      hitsPerPage,
      filters,
    };

    if (filter.location && Object.keys(filter.location).length > 0) {
      let { longitude, latitude } = filter.location;
      const { locationRadius } = filter;

      latitude = parseFloat(latitude).toFixed(2);
      longitude = parseFloat(longitude).toFixed(2);

      // Convert locationRadius from miles to meters
      const locationRadiusInMeters = locationRadius * 1609.34;

      searchParams.aroundLatLng = `${latitude}, ${longitude}`;
      searchParams.aroundRadius = parseInt(locationRadiusInMeters.toString(), 10);
    }

    const res = await index.search('', searchParams);

    return {
      hits: res.hits as CustomShowsHitType[],
      nbHits: res.nbHits,
      nbPages: res.nbPages,
      page: res.page,
    };
  } catch (error) {
    return defaultAvailsSearchResult;
  }
};

export const searchClientUnavailableDates = async (searchIndex: string, clients: string[], dateRange: DateRange) => {
  try {
    const clientFilter = clients.length ? `(${clients.map((client) => `clientId:"${client}"`).join(' OR ')})` : '';
    const dateRangeFilter = `date_timestamp >= ${dateRange.start.getTime()} AND date_timestamp <= ${
      dateRange.end.getTime() + 86399999
    }`;
    const clientAvailableFilter = `clientAvailable:false`;

    const filters = [clientFilter, dateRangeFilter, clientAvailableFilter].filter(Boolean).join(' AND ');

    const index = algoliaClient.initIndex(searchIndex);

    const results = await index.search('', {
      filters,
      hitsPerPage: 10000,
    });

    return results.hits;
  } catch (error) {
    console.log('error', error);
    return [];
  }
};
