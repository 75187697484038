import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { showUserError, updateContract, updateDeal, updateDealShows } from 'api';
import { useEditDealContext } from 'context/editDealContext';
import { CURRENCIES, ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import { DEAL_TERMS_EMPTY_FORM, DEAL_TERMS_TYPE_OPTIONS, DEAL_TERMS_VERSUS_TYPES } from 'deal-form/data/organisms';
import ButtonWithContext from 'deal-form/form-controls/ButtonWithContext';
import { Currency } from 'deal-form/form-controls/Currency';
import { TextField } from 'deal-form/form-controls/TextField';
import {
  FormValues,
  createTermsPayload,
  generateContractDisplay,
  getTerms,
} from 'deal-form/helpers/dealTermsFormatting';
import { iCurrencyOption, iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismDealTerms } from 'deal-form/interfaces/organisms';
import { MasterDealWarning } from 'deal-form/ui/MasterDealWarning';
import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { Deal, Performer, Terms } from 'types';
import { tCurrency } from 'types/deal';
import getWireTransferAccount from '../../../utils/wireAccountLogic';
import { Dropdown } from '../../form-controls/Dropdown';
import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { Label } from '../../form-controls/Label';
import { TextArea } from '../../form-controls/TextArea';
import { getMasterShow } from '../../helpers/showHelpers';
import { FieldArray } from 'deal-form/form-controls/FieldArray';
import { organizeExchangeRates, transformObject } from 'deal-form/helpers/flattenCurrencies';
import { CONTRACT_WIRE_ACCOUNTS } from 'deal-form/data/organisms';
import submitToNetsuite from 'utils/submitToNetsuite';
import cypressTags from 'support/cypressTags';
export const GRID_LAYOUT = 'grid grid-cols-[1fr_2fr] gap-x-24';
const { TERMS_SECTION } = cypressTags.DEAL_FORM;
const TermsOfTheDealForm: React.FC<iFormProps> = ({
  deal,
  setDeal,
  contract,
  setContract,
  isOpen,
  locked,
  contractLocked,
}) => {
  const { setDefaultCurrency } = useEditDealContext();
  const [dealType, setDealType] = useState<iOptions>(DEAL_TERMS_TYPE_OPTIONS[0]);
  const [dealTermsData, setDealTermsData] = useState<iOrganismDealTerms | null>(null);
  const [artistCurrency, setArtistCurrency] = useState<string>(deal.currency);
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  const [crossed, setCrossed] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [guaranteeMin, setGuaranteMin] = useState(1);

  const { isOrganismDirty } = useEditDealContext();
  const shouldValidate = isOrganismDirty(FORM_NAMES.TERMS_OF_THE_DEAL);

  const getWireTransfer = (contractInfo: any, data: any) => {
    const wireTransferResult = getWireTransferAccount(
      contractInfo.financeInfo.location,
      data.currency,
      contractInfo.financeInfo.subsidiary,
      contractInfo.financeInfo.department,
      contractInfo.financeInfo.lineOfBusiness
    );
    return wireTransferResult;
  };

  useEffect(() => {
    let exchangeRates: any = [];
    if (contract) {
      exchangeRates = transformObject(contract?.exchangeRates || {});
    }
    const terms = getTerms(deal);
    let masterTerms;
    if (contract && contract.masterDeal && contract.crossed !== 'Neither') {
      masterTerms = getTerms(contract.masterDeal);
      setMasterDeal(contract.masterDeal);
      setCrossed(contract.crossed === 'Crossed');
    } else {
      setMasterDeal(null);
    }
    if (terms) {
      const currency = masterTerms ? masterTerms.currency : terms.currency;
      const chosenDealType = masterTerms ? (crossed ? masterTerms.dealType : terms.dealType) : terms.dealType;
      const contractDisplay = terms.contractDisplay;
      const termData: iOrganismDealTerms = {
        currency: currency.value || 'USD',
        dealType: {
          id: chosenDealType.value,
          label: chosenDealType.label,
        },
        contractDisplay: contractDisplay,
        percentage: terms.percent,
        promoterProfit: terms.buyerProfit,
        guarantee: {
          value: deal.guarantee,
          currency: terms.currency.value || 'USD',
        },
        afterAmount: {
          value: terms.afterAmount,
          currency: terms.currency.value || 'USD',
        },
        cappedAt: {
          value: terms.cap,
          currency: terms.currency.value || 'USD',
        },
        bonus: {
          value: terms.bonus,
          currency: terms.currency.value || 'USD',
        },
        versusType: terms.versusType || 'Gross',
        exchangeRates: exchangeRates,
      };
      setArtistCurrency(termData.currency);
      setDealTermsData(termData);
      setDealType(termData.dealType);
      // setGeneratedDisplay(contractDisplay);
    } else {
      setDealTermsData(DEAL_TERMS_EMPTY_FORM);
    }
  }, [deal, contract]);

  const doUpdate = async (terms: Terms, dealToUpdate: Deal, currency: string, isMasterDealUpdate?: boolean) => {
    const masterShow = getMasterShow(dealToUpdate.shows);

    // only apply the terms to the first performer
    const performers: Performer[] = masterShow.performers.map((performer, index) => {
      if (index === 0) {
        return { ...performer, terms };
      }
      return performer;
    });
    setCanSubmit(false);

    // TODO: update all shows, this is currently just updating the master show.

    // await updateDealShows({
    //   dealId: dealToUpdate._id.toString(),
    //   updates: [
    //     {
    //       showId: masterShow._id,
    //       updateBody: {
    //         performers,
    //       },
    //     },
    //   ],
    // })
    await updateDealShows({
      dealId: dealToUpdate._id.toString(),
      updates: deal.shows.map((show) => ({ showId: show._id, updateBody: { performers } })),
    })
      .then(async (resp) => {
        if (resp.ok) {
          const updatedDeal = await updateDeal(dealToUpdate._id, {
            ...(resp.body as Deal),
            currency: currency as tCurrency,
          });

          if (isMasterDealUpdate && setContract && contract) {
            setContract({ ...contract, masterDeal: updatedDeal });
          } else if (setDeal) {
            setDeal(updatedDeal);
          }

          if (!isMasterDealUpdate && setDeal) {
            setDeal(resp.body as Deal);
          }

          setTimeout(() => setCanSubmit(true), 500);
        } else {
          showUserError(resp);
          setCanSubmit(true);
        }
      })
      .catch((e) => {
        console.log('there was an error processing your request', e);
        setCanSubmit(true);
      });
  };

  const onSubmit = async (data: iOrganismDealTerms) => {
    const wireTransferName = getWireTransfer(contract, data);
    const wireTransferId = CONTRACT_WIRE_ACCOUNTS.find((account) => account.label === wireTransferName)?.id;

    if (data.currency) {
      //Set the default currency for the deal
      const defaultCurrency = CURRENCIES.find((currency: iCurrencyOption) => currency.id === data.currency);
      if (defaultCurrency) setDefaultCurrency(defaultCurrency);
    }

    if (masterDeal) {
      const ogDealTerms = getTerms(deal);
      const masterData = {
        currency: data.currency,
        dealType: data.dealType,
        contractDisplay: data.contractDisplay,
        guarantee: {
          value: data.guarantee?.value || 0,
          currency: data.guarantee?.currency || 'USD',
        },
        travelAccommodations: ogDealTerms.travelAccommodations,
      };
      const dealData: iOrganismDealTerms = {
        currency: ogDealTerms.currency.value || 'USD',
        dealType: data.dealType,
        contractDisplay: data.contractDisplay || ogDealTerms.contractDisplay,
        percentage: data.percentage || 0,
        promoterProfit: data.percentage || 0,
        afterAmount: {
          value: data.afterAmount?.value || 0,
          currency: data.afterAmount?.currency || 'USD',
        },
        cappedAt: {
          value: data.cappedAt?.value || 0,
          currency: data.cappedAt?.currency || 'USD',
        },
        bonus: {
          value: data.bonus?.value || 0,
          currency: data.bonus?.currency || 'USD',
        },
        guarantee: {
          value: data.guarantee?.value || 0,
          currency: data.guarantee?.currency || 'USD',
        },
        versusType: data.versusType || '',
        travelAccommodations: ogDealTerms.travelAccommodations,
      };

      const masterTerms = createTermsPayload(masterData, ogDealTerms.travelAccommodations);
      const dealTerms = createTermsPayload(dealData, ogDealTerms.travelAccommodations);

      await doUpdate(dealTerms, deal, data.currency, false);
      await doUpdate(masterTerms, masterDeal, data.currency, true);
    } else {
      const ogDealTerms = getTerms(deal);
      const terms = createTermsPayload(data, ogDealTerms.travelAccommodations);
      await doUpdate(terms, deal, data.currency);
    }

    if (contract && data.exchangeRates) {
      const exchangeRates = organizeExchangeRates(data.exchangeRates);
      await updateContract(contract._id, { ...contract, exchangeRates: exchangeRates }).then((resp) => {
        if (resp && setContract) {
          setContract({ ...resp });
        }
      });
    }

    if (wireTransferId && contract) {
      const updateContractWireID = await updateContract(contract._id, {
        ...contract,
        utaWireTransfer: wireTransferId.toString(),
      });
      if (updateContractWireID && setContract) {
        setContract(updateContractWireID);
      }
    }
    const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';

    if (isDealSubmittedToNetsuite) {
      submitToNetsuite(contract, deal, false);
    }
  };

  const parseGeneratorData = (data: FieldValues) => {
    //parse our data to work with exisitng generator
    const parsedData: FormValues = {
      currency: {
        label: data.currency,
        value: data.currency,
      },
      dealType: { value: dealType.id, label: dealType.label },
      percent: data.percentage || 0,
      guarantee: data.guarantee ? data.guarantee.value : 0,
      bonus: data.bonus ? data.bonus.value : 0,
      afterAmount: data.afterAmount ? data.afterAmount.value : 0,
      cap: data.cappedAt ? data.cappedAt.value : 0,
      contractDisplay: data.contractDisplay || '',
      buyerProfit: data.promoterProfit || 0,
      versusType: data.versusType || '',
    };
    return parsedData;
  };

  const onDealTypeChange = (option: iOptions | null) => {
    if (option) {
      setDealType(option);
    }
  };
  const onDealCurrencyChange = (option: iOptions | null) => {
    if (option) {
      setArtistCurrency(option.id);
    }
  };

  const hasGuaranteeField =
    dealType.id === 'flat' || dealType.id === 'plusBackend' || dealType.id === 'fixedSplit' || dealType.id === 'versus';
  const hasPercentField = dealType.id !== 'flat';
  // const hasCappedAtField = dealType.id !== 'flat';
  const hasAfterAmountField = dealType.id === 'fixedSplit' || dealType.id === 'capped' || dealType.id === 'doorDeal';
  const hasPromoterProfitField = dealType.id === 'plusBackend';

  const columnClasses = 'col-span-2 grid grid-cols-[100px_1fr] lg:grid-cols-[200px_1fr] gap-4 items-center';

  const generateDisplay = (values: FieldValues) => {
    if (values) {
      //parse our data to work with exisitng generator
      const chosenCurrency = CURRENCIES.find((currency: iCurrencyOption) => currency.id === artistCurrency);
      const parseddata = parseGeneratorData(values);

      return generateContractDisplay(parseddata, chosenCurrency);
    }
  };

  useEffect(() => {
    if (dealTermsData?.dealType.id === 'flat') {
      setGuaranteMin(0.01);
    }
  }, [dealTermsData]);

  const EMPTY_CURRENCY_FORM = [
    { exchangeRateFrom: { currency: 'USD', value: 1 }, exchangeRateTo: { currency: 'USD', value: 1 } },
  ];

  return (
    dealTermsData && (
      <div>
        {masterDeal && (
          <MasterDealWarning
            crossed={crossed}
            fields={[]}
            text={'Updating any of the fields below will update the individual deal and not the contract.'}
          />
        )}
        <Form
          canSubmit={canSubmit}
          onSubmit={onSubmit}
          className="fixed-first-column-with-labels items-start"
          defaultValues={dealTermsData}
          disabled={!isOpen || locked}
          formName={FORM_NAMES.TERMS_OF_THE_DEAL}
        >
          <div className={columnClasses}>
            <Label isRequired>Currency</Label>
            <Dropdown
              label={false}
              placeholder="Select Currency"
              id="currency"
              options={CURRENCIES}
              handleChange={onDealCurrencyChange}
              disabled={!isOpen || locked || contractLocked}
              rules={{
                required: ERROR_MESSAGES.REQUIRED_FIELDS,
                validate: (currency) => {
                  let useMasterDeal = masterDeal && masterDeal?.currency;
                  if (!useMasterDeal) return true;
                  return (
                    currency === masterDeal?.currency ||
                    'The currency in the Currency field cannot be changed when a deal is linked. Users must unlink the deal to change the currency if needed'
                  );
                },
              }}
              dataCy={TERMS_SECTION.CURRENCY_DROPDOWN}
            />

            <Label>Currency Conversion(s)</Label>
            <div className="flex flex-col">
              <div className="grid grid-cols-[1fr_1fr]">
                <Label className="text-left self-start !font-bold">From</Label>
                <Label className="text-left self-start !font-bold">To</Label>
              </div>
              <div className="flex">
                <FieldArray
                  gridClassName={GRID_LAYOUT}
                  groupName="exchangeRates"
                  emptyRow={EMPTY_CURRENCY_FORM}
                  addButtonLabel="Add Currency Conversion"
                  disabled={!isOpen || locked}
                >
                  <Currency id="exchangeRateFrom" displayOnlyInput={true} allCurrencies />
                  <Currency id="exchangeRateTo" allCurrencies />
                </FieldArray>
              </div>
            </div>

            <Label isRequired className="form-label col-start-1 ">
              Deal Type
            </Label>
            <Dropdown
              handleChange={onDealTypeChange}
              label={false}
              placeholder="Select Deal Type"
              id="dealType.id"
              disabled={!isOpen || locked}
              options={DEAL_TERMS_TYPE_OPTIONS}
              rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
              dataCy={TERMS_SECTION.DEAL_TYPE_DROPDOWN}
            />
          </div>
          <div className={`${columnClasses} lg:grid-cols-[160px_1fr]`}>
            {hasGuaranteeField ? (
              <>
                <Label isRequired>Guarantee</Label>
                <TextField
                  className="flex items-center"
                  id="guarantee"
                  text="--"
                  isCurrency
                  disabled={!isOpen || locked}
                  rules={{
                    min: { value: guaranteeMin, message: ERROR_MESSAGES.REQUIRED_FIELDS },
                  }}
                  dataCy={TERMS_SECTION.GUARANTEE}
                />
              </>
            ) : null}

            {hasPercentField ? (
              <>
                <Label isRequired>Percentage</Label>
                <Input
                  fieldType="percent"
                  containerClassName={`pr-9`}
                  label={false}
                  placeholder="Percentage"
                  step="0.1"
                  rules={{
                    required: ERROR_MESSAGES.REQUIRED_FIELDS,
                    min: { value: 0.1, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
                    max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
                  }}
                  id="percentage"
                  disabled={!isOpen || locked}
                  dataCy={TERMS_SECTION.PERCENTAGE_INPUT}
                />
              </>
            ) : null}

            {hasPromoterProfitField ? (
              <>
                <Label isRequired>Promoter Profit %</Label>
                <Input
                  fieldType="percent"
                  containerClassName={`pr-9`}
                  label={false}
                  step="0.1"
                  rules={{
                    required: ERROR_MESSAGES.REQUIRED_FIELDS,
                    min: { value: 0, message: ERROR_MESSAGES.PERCENTAGE_ZERO },
                    max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ZERO },
                  }}
                  placeholder="Promoter Profit %"
                  id="promoterProfit"
                  disabled={!isOpen || locked}
                  dataCy={TERMS_SECTION.PROMOTER_PROFIT_INPUT}
                />
              </>
            ) : null}

            {dealType.id === 'versus' ? (
              <>
                <Label isRequired>Type (Net or Gross)</Label>
                <div className={`pr-9`}>
                  <Dropdown
                    disabled={!isOpen || locked}
                    placeholder="Type (Net or Gross)"
                    id="versusType"
                    options={DEAL_TERMS_VERSUS_TYPES}
                  />
                </div>
              </>
            ) : null}

            {hasAfterAmountField ? (
              <>
                <Label isRequired>After Amount</Label>
                <Currency
                  id="afterAmount"
                  overrideCurrency={artistCurrency}
                  disabled={!isOpen || locked}
                  rules={{
                    min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
                  }}
                />
              </>
            ) : null}

            <Label>Bonus</Label>
            <Currency
              id="bonus"
              overrideCurrency={artistCurrency}
              disabled={!isOpen || locked}
              rules={{
                min: { value: 0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
              }}
              dataCy={TERMS_SECTION.BONUS_INPUT}
            />
          </div>

          <div>
            <Label isRequired className="form-label col-start-1 self-start">
              Contract Display
            </Label>

            {shouldValidate ? (
              <p className="text-sm my-2">Some fields have changed and values displayed may be outdated.</p>
            ) : null}
          </div>

          <TextArea
            rules={{
              required: 'You must have all required field entered and select the ‘Generate Display’ button to save',
            }}
            label={false}
            id="contractDisplay"
            placeholder="Show on Contract"
            containerClassName="col-span-3"
            rows={4}
            disabled={!isOpen || locked}
            dataCy={TERMS_SECTION.TEXTAREA}
          />

          <div className="col-start-3 col-span-2 flex justify-end mt-2">
            <ButtonWithContext
              leftIcon={<ArrowPathIcon width={24} height={24} />}
              variant="primary"
              onClick={generateDisplay}
              shouldValidate
              updateFormName="contractDisplay"
              disabled={!isOpen || locked}
              shouldDirty
              dataCy={TERMS_SECTION.GENERATE_TEXT_BUTTON}
            >
              GENERATE DISPLAY
            </ButtonWithContext>
          </div>
        </Form>
      </div>
    )
  );
};

export default TermsOfTheDealForm;
