export default {
  COMMON: {
    SEARCH_BAR: 'search-bar',
  },
  ROSTER: {
    CLIENT_CARD_LIST_ITEM: 'client-card-list-item',
    CLIENT_CARD_CLIENT_NAME: 'client-card-client-name',
    CLIENT_CARD_CLIENT_IMAGE_FROM_LINK: 'client-card-client-image-from-link',
    CLIENT_CARD_CLIENT_IMAGE_DEFAULT: 'client-card-client-image-default',
    NEXT_PAGE_BUTTON: 'next-page-button',
    PREVIOUS_PAGE_BUTTON: 'previous-page-button',
    ALL_CLIENTS_BUTTON: 'all-clients-button',
    MY_CLIENTS_BUTTON: 'my-clients-button',
    SHARE_BUTTON: 'share-button',
    BACKSTAGE_EMAIL_INPUT: 'backstage-email-input',
    BACKSTAGE_COMPLETE_EMAIL_NEXT_BTN: 'backstage-email-next-button',
    BACKSTAGE_SHARE_ARTIST_BTN: 'backstage-share-artist-button',
    BACKSTAGE_SHARE_TITLE: 'backstage-share-title',
  },
  ROUTING: {
    ACTIONS: 'routing-actions',
    CLIENT_TITLE_LINK: 'client-title-link',
    CREATE_DEAL_BUTTON: 'create-deal-button',
    GALLERY_CARD: 'client-card',
    TABLE: 'routing-table',
    MONTH_BREAK: 'month-break',
    TABLE_DATE: 'table-date',
    DATE_RANGE_PICKER: 'date-range-picker',
    MAP_DATE_RANGE_PICKER: 'map-date-range-picker',
    MONTH_PICKER: 'month-picker',
    SELECTED_MONTH: 'selected-month',
    UNSELECTED_MONTH: 'unselected-month',
    YEAR_PICKER: 'year-picker',
    COLUMN_HEADER: 'column_header',
    EDIT_COLUMNS_MENU_TOGGLE: 'edit-columns-menu-toggle',
    EDIT_COLUMNS_MENU_TOGGLE_DESKTOP: 'edit-columns-menu-toggle-desktop',
    EDIT_COLUMNS_MENU: 'edit-columns-menu',
    VIOLATES_UP: 'violates-up',
    VIOLATES_DOWN: 'violates-down',
    VIOLATION: 'violation',
    VIOLATION_BANNER_COUNT: 'violation-banner-count',
    GUARANTEE_COLUMN: 'guarantee',
    WALKOUT_POTENTIAL_COLUMN: 'walkout-potential',
    BUYER_COMPANY_COLUMN: 'buyer-company',
    DEAL_TYPE_COLUMEN: 'deal-type',
    EDIT_COLUMNS: {
      CITY: 'city-col-menu',
      STATE: 'state-col-menu',
      COUNTRY: 'country-col-menu',
      RADIUS_CLAUSE: 'radiusclause-col-menu',
      DAYS_PRIOR: 'daysprior-col-menu',
      DAYS_AFTER: 'daysafter-col-menu',
      BUYER_COMPANY: 'buyercompany-col-menu',
      BUYER_NAME: 'buyername-col-menu',
      DEAL_STATUS: 'dealstatus-col-menu',
      HOLD_NUMBER: 'hold#-col-menu',
      GUARANTEE: 'guarantee-col-menu',
      SELLABLE_CAPACITY: 'sellablecapacity-col-menu',
      DEAL_TYPE: 'dealtype-col-menu',
      PHONE: 'phone-col-menu',
      WALKOUT_POTENTIAL: 'walkoutpotential-col-menu',
      TICKETS: 'tix-col-menu',
      AGES: 'ages-col-menu',
      SOFT_MERCH: 'softmerch-col-menu',
      HARD_MERCH: 'hardmerch-col-menu',
      NOTES: 'notes-col-menu',
    },
    ADD_DEAL_BUTTON: 'add-deal-button',
    EDIT_DEAL_BUTTON: 'edit-deal-button',
    TOGGLE_CLIENT_AVAILABILITY: 'toggle-client-availability',
    ADD_NEW_DEAL_MODAL: {
      ADD_NEW_DEAL_TITLE: 'add-new-deal-title',
      FIRST_SHOW_DATE: 'first-show-date',
      VENUE_INPUT: 'venue-input',
      BUYER_COMPANY_INPUT: 'buyer-company-input',
      BUYER_NAME_INPUT: 'buyer-name-input',
      SHOW_STATUS_INPUT: 'show-status-input',
      SAVE_AND_EDIT: 'save-and-edit',
      SAVE_AND_CLOSE: 'save-and-close',
      NOTE_INPUT: 'note-input',
    },
  },
  DROPDOWN_OPTION: 'dropdown-option',
  DEAL_FORM: {
    NOTE_EDIT_MODAL: 'deal-form-note-edit-modal',
    NOTE_DISPLAY: 'deal-form-note-display',
    SUBMIT_BUTTON: 'deal-form-submit-button',
    // should be moved to header
    CONTRACT_STATUS_INDIFCATOR: 'contract-status-indicator',
    SUBMIT_TO_NETSUITE_ERROR: 'SUBMIT-TO-NETSUITE-ERROR',
    SPLIT_DEAL_BUTTON: 'split-deal-button',
    SPLIT_DEAL_ERROR: 'SPLIT-DEAL-ERROR',
    BUYER_SECTION: {
      TITLE: 'buyer-section-title',
      COMPANY_DROPDOWN: 'buyer-company-dropdown',
      COMPANY_DROPDOWN_OPTIONS: 'buyer-company-dropdown-options',
      PERSON_DROPDOWN: 'buyer-person-dropdown',
      PERSON_DROPDOWN_OPTIONS: 'buyer-person-dropdown-options',
      COMPANY_ADDRESS: 'buyer-company-address',
      PERSON_CONTACT_INFO: 'buyer-person-info',
      CONTRACT_SIGNATORY: 'buyer-contract-signatory',
      CONTACT_INFO: 'buyer-contract-info',
    },
    CLIENT_SECTION: {
      TITLE: 'client-section-title',
      COMMISSION_INPUT: 'client-commission-input',
      CLIENT_NAME: 'client-name',
      REPONSIBLE_AGENT: 'client-responsible-agent',
      PAPERING_AGENT: 'client-papering-agent',
    },
    LINKED_DEALS_SECTION: {
      TITLE: 'linked-deals-section-title',
      LINK_CROSS_TYPE: 'linked-deals-section-link-cross-type-statement',
      LINKED_DEALS_ROW: 'linked-deals-section-linked-deals-row',
      LINKED_DEALS_GUARANTEE: 'G',
      LINKED_DEALS_CONTRACT_GUARANTEE: 'linked-deals-section-contract-guarantee',
    },
    LINK_DEAL_MODAL: {
      LINK_TYPE: 'link-deal-modal-link-type',
      START_DATE: 'link-deal-modal-start-date',
      END_DATE: 'link-deal-modal-end-date',
      SELECT_DEAL: 'link-deal-modal-select-deal',
      SEARCH_BUTTON: 'link-deal-modal-search-button',
      LINKABLE_DEAL: 'link-deal-modal-linkable-deals',
      LINK_BUTTON: 'link-deal-modal-link-button',
      MODAL_TITLE: 'link-deal-modal-title',
      LINKABLE_DEAL_GUARANTEE: 'link-deal-modal-linkable-deal-guarantee',
    },
    SHOWS_SECTION: {
      TITLE: 'shows-section-title',
      TYPE_DROPDOWN: 'shows-section-type-dropdown',
      TYPE_DROPDOWN_OPTION: 'shows-section-type-dropdown-option',
      NOTES_EDIT: 'shows-section-notes-edit',
      NOTES_DISPLAY: 'shows-section-notes-display',
      DATE_INPUT: 'shows-section-dates-input',
      TARGET_DATE: 'shows-section-target-date',
      STATUS_DROPDOWN: 'shows-section-status-dropdown',
    },
    SHOW_DETAILS: {
      TITLE: 'show-details-title',
      SHOW_GUARANTEE: 'show-details-show-guarantee',
      SHOW_DATE: 'show-details-show-date',
      SHOW_STATUS: 'show-details-show-status',
      SHOW_NOTES: 'show-details-show-notes',
      SHOW_TIME: 'show-details-show-time',
    },
    EXPENSES_SECTION: {
      TITLE: 'expenses-title',
      TYPE_DROPDOWN: 'expenses-type-dropdown',
      TYPE_DROPDOWN_OPTION: 'expenses-type-dropdown-option',
      AMOUNT_INPUT: 'expenses-amount-input',
      NOTE_INPUT: 'expenses-note-display',
      REMOVE_EXPENSE: 'expenses-remove',
      ADD_EXPENSE: 'expenses-add',
      ADD_EXPENSE_DISABLED: 'expenses-add-disabled',
      TOTAL: 'expenses-total',
      GROSS: 'expenses-gross',
    },
    VARIABLE_EXPENSES: {
      TITLE: 'variable-expenses-title',
      TYPE_DROPDOWN: 'variable-expenses-type-dropdown',
      CURRENCY: 'variable-expenses-currency',
      AMOUNT_INPUT: 'variable-expenses-amount',
      CAP_INPUT: 'variable-expenses-cap',
      PER_HEAD_INPUT: 'variable-expenses-per-head',
      EXPENSE_CALCULATION: 'variable-expenses-expense-calculation',
      TICKET_PERCENTAGE: 'variable-expenses-ticket-percentage',
      NOTE: 'variable-expenses-note',
      GBOR_NBOR: 'variable-expenses-gbor-nbor',
      PER_HEAD_CAPACITY: 'variable-expenses-per-head-capacity',
      TOTAL: 'variable-expenses-total',
    },
    REDUCTIONS_SECTION: {
      TITLE: 'reductions-title',
      ROW: 'reductions-row',
      AMOUNT_INPUT: 'reductions-amount-input',
      TYPE_DROPDOWN: 'reductions-type-dropdown',
      DESCRIPTION_TEXT: 'reductions-description-text',
      REMOVE_REDUCTION: 'reductions-remove-reduction',
      ADD_REDUCTION: 'reductions-add-reduction',
    },
    TICKET_FEES: {
      TITLE: 'ticket-fees-title',
      ADD_FEE: 'ticket-fees-add',
      ADD_FEE_DISABLED: 'ticket-fees-add-disabled',
      TIER_DROPDOWN: 'ticket-fees-tier-dropdown',
      TIER_DROPDOWN_OPTION: 'ticket-fees-tier-dropdown-option',
      TYPE_DROPDOWN: 'ticket-fees-type-dropdown',
      TYPE_DROPDOWN_OPTION: 'ticket-fees-type-dropdown-option',
      AMOUNT: 'ticket-fees-amount',
      NOTE: 'ticket-fees-note',
      GROSS: 'ticket-fees-row-gross',
      GROSS_DISABLED: 'ticket-fees-row-gross-disabled',
      FEE_ROW: 'fee-row',
      REMOVE_FEE: 'remove-fee-row',
      TOTAL_GROSS: 'ticket-fees-total-gross',
    },
    TICKET_SECTION: {
      TITLE: 'ticket-section-title',
      DATE_DROPDOWN: 'ticket-section-date-dropdown',
      TIER_DROPDOWN: 'ticket-section-tier-dropdown',
      AVAILABLE_INPUT: 'ticket-section-available-input',
      DAY_OF_INPUT: 'ticket-section-day-of-input',
      ADVANCE_INPUT: 'ticket-section-advance-input',
      COMP_INPUT: 'ticket-section-comp-input',
      NOTE_INPUT: 'ticket-section-note-input',
      TICKET_CAPACITY_INPUT: 'ticket-section-ticket-capacity-input',
      TICKET_SELLABLE_CAPACITY: 'ticket-section-ticket-sellable-capacity',
      TICKET_GROSS: 'ticket-section-ticket-gross',
      TOTAL_COMPS: 'ticket-section-total-comps',
      TOTAL_AVAILABLE: 'ticket-section-total-available',
      TOTAL_SELLABLE: 'ticket-section-total-sellable',
      TOTAL_GROSS: 'ticket-section-total-gross',
      TOTAL_CAPACITY: 'ticket-section-total-capacity',
      ADD_TICKET: 'ticket-section-add',
      REMOVE_TICKET: 'ticket-section-remove',
      ADD_TICKET_DISABLED: 'ticket-section-add-disabled',
      SHOW_DROPDOWN: 'ticket-section-show-dropdown',
    },
    TICKET_CALCULATIONS: {
      TITLE: 'ticket-calculations-title',
      SHOW: 'ticket-calculations-show',
      GROSS_POTENTIAL: 'ticket-calculations-gross-potential',
      TOTAL_FEES: 'ticket-calculations-total-fees',
      TOTAL_TAXES: 'ticket-calculations-total-taxes',
      TOTAL_NET: 'ticket-calculations-total-net',
      NET_POTENTIAL: 'ticket-calculations-net-potential',
    },
    SHOW_LOCATION: {
      TITLE: 'shows-section-title',
      VENUE_DROPDOWN: 'show-venue-dropdown',
      VENUE_DROPDOWN_OPTIONS: 'show-venue-dropdown-options',
      AGES_DROPDOWN: 'show-ages-dropdown',
      TITLE_INPUT: 'show-title-input',
      INTERNAL_USE_CHECKBOX: 'show-internal-use-checkbox',
      VENUE_ADDRESS: 'show-venue-address',
      TERRITORY_DROPDOWN: 'show-territory-dropdown',
    },
    TAXES_SECTION: {
      TITLE: 'taxes-section-title',
      TIER_DROPDOWN: 'taxes-section-tier-dropdown',
      TIER_DROPDOWN_OPTION: 'taxes-section-tier-dropdown-option',
      PERCENT_INPUT: 'taxes-section-percent-input',
      CALCULATION_DROPDOWN: 'taxes-section-calc-dropdown',
      CALCULATION_DROPDOWN_OPTION: 'taxes-section-calc-dropdown-option',
      MATH_TYPE_DROPDOWN: 'taxes-section-mathType-dropdown',
      MATH_TYPE_DROPDOWN_OPTION: 'taxes-section-mathType-dropdown-dropdown',
      NOTE_INPUT: 'taxes-section-note-input',
      GROSS: 'taxes-section-single-gross',
      REMOVE_TAX: 'taxes-remove',
      TOTAL_GROSS: 'taxes-section-total-gross',
      ADD_TAX: 'taxes-section-add',
      ROW: 'taxes-section-row',
    },
    TRAVEL_ACCOMMODATIONS_SECTION: {
      TITLE: 'travel-accommodations-section-title',
      TRAVEL_ACCOMMODATIONS: 'travel-accommodations',
    },
    TERMS_SECTION: {
      TITLE: 'terms-section-title',
      CURRENCY_DROPDOWN: 'terms-section-currency-dropdown',
      CURRENCY_DROPDOWN_OPTION: 'terms-section-currency-dropdown-option',
      DEAL_TYPE_DROPDOWN: 'terms-section-dealType-dropdown',
      DEAL_TYPE_DROPDOWN_OPTION: 'terms-section-dealType-dropdown',
      VERSUS_TYPE_DROPDOWN: 'terms-section-versusType-dropdown',
      VERSUS_TYPE_DROPDOWN_OPTION: 'terms-section-versusType-dropdown-option',
      PERCENTAGE_INPUT: 'terms-section-percentage-input',
      AFTER_AMOUNT_INPUT: 'terms-section-afterAmount-input',
      GUARANTEE: 'terms-section-guarantee',
      BUYER_PROFIT_INPUT: 'terms-section-buyerProfit-input',
      CAPPED_INPUT: 'terms-section-capped-input',
      BONUS_INPUT: 'terms-section-bonus-input',
      GENERATE_TEXT_BUTTON: 'terms-section-generate-button',
      TEXTAREA: 'terms-section-textArea',
      PROMOTER_PROFIT_INPUT: 'terms-section-promoterProfit-input',
    },
    PAYMENTS_SECTION: {
      TITLE: 'payment-section-title',
      DATE_DROPDOWN: 'payment-section-date-dropdown',
      AMOUNT_INPUT: 'payment-section-amount-input',
      PAYMENT_TO_DROPDOWN: 'payment-section-paymentTo-dropdown',
      PAYMENT_TO_DROPDOWN_OPTION: 'payment-section-paymentTo-dropdown-option',
      PAYMENT_TYPE_DROPDOWN: 'payment-section-paymentType-dropdown',
      PAYMENT_TYPE_DROPDOWN_OPTION: 'payment-section-paymentType-dropdown-option',
      DESCRIPTION_INPUT: 'payment-section-description-input',
      TOTAL_AMOUNT: 'payment-section-total-amount',
      REMOVE_PAYMENT: 'payment-remove',
      ADD_PAYMENT: 'payment-add',
      PAYMENT_ROW: 'payment-row',
      STATUS_DROPDOWN: 'payment-section-status-dropdown',
      STATUS_DROPDOWN_OPTION: 'payment-section-status-dropdown-option',
    },
    HEADER: {
      CONTAINER: 'deal-form-header',
      INFORMATION_BADGE: 'deal-form-header-information-badge',
      SUBMIT_BUTTON: 'deal-form-header-submit-button',
      LINK_DEAL_BUTTON: 'deal-form-header-link-deal-button',
    },
    CALCULATIONS_SECTION: {
      TITLE: 'calculations-section-date-title',
      DATE_INPUT: 'calculations-section-date-input',
      GROSS_INPUT: 'calculations-section-gross-input',
      FEES_INPUT: 'calculations-section-fees-input',
      TAXES_INPUT: 'calculations-section-taxes-input',
      NET_INPUT: 'calculations-section-net-input',
      TOTAL_GROSS: 'calculations-section-total-gross',
      TOTAL_FEES: 'calculations-section-total-fees',
      TOTAL_TAXES: 'calculations-section-total-taxes',
      TOTAL_NET: 'calculations-section-total-net',
    },
    CALCULATED_VALUES_SECTION: {
      TITLE: 'calculations-values-section-title',
      SPLIT_POINT_DISPLAY: 'calculations-values-section-split-point-display',
      BREAK_EVEN_DISPLAY: 'calculations-values-section-break-even-display',
      WALKOUT_POTENTIAL_DISPLAY: 'calculations-values-section-walkout-potential-display',
      BREAK_EVEN_OVERRIDE_INPUT: 'calculations-values-section-break-even-input',
      WALKOUT_POTENTIAL_INPUT: 'calculations-values-section-walkout-potential-input',
      AMAOUNT_DISPLAYED: 'calculations-values-section-amount-displayed',
    },
    SETTLEMENTS_SECTION: {
      TITLE: 'settlements-section-title',
      FINALS: {
        TITLE: 'settlements-section-finals',
        TICKET_TIER: {
          ROW: 'settlements-section-finals-ticket-tier-row',
          NAME: 'settlements-section-finals-ticket-tier-name',
          PRICE: 'settlements-section-finals-ticket-tier-price',
          SOLD: 'settlements-section-finals-ticket-tier-sold',
          VALUE: 'settlements-section-finals-ticket-tier-value',
          SELLABLE: 'settlements-section-finals-ticket-tier-sellable',
          PCT_TIER_SOLD: 'settlements-section-finals-ticket-tier-percent-tier-sold',
          PCT_TOTAL_SOLD: 'settlements-section-finals-ticket-tier-percent-total-sold',
        },
        TOTAL: {
          ROW: 'settlements-section-finals-total-row',
          SOLD: 'settlements-section-finals-total-sold',
          VALUE: {
            GROSS_BOX_OFFICE_OVERRIDE_RECEIPTS: 'settlements-section-finals-total-gbor-value',
            NET_BOX_OFFICE_OVERRIDE_RECEIPTS: 'settlements-section-finals-total-nbor-value',
          },
          SELLABLE: 'settlements-section-finals-total-sellable',
          PCT_TIER_SOLD: 'settlements-section-finals-total-percent-tier-sold',
          PCT_TOTAL_SOLD: 'settlements-section-finals-total-percent-total-sold',
        },
      },
      TOTALS: {
        TITLE: 'settlements-section-totals',
        GUARANTEE: 'settlements-section-guarantee',
        OVERAGE: 'settlements-section-overage',
        CLIENT_REDUCTION: 'settlements-section-client-reduction',
        AGENCY_REDUCTION: 'settlements-section-agency-reduction',
        TOTAL_PAYOUTS: 'settlements-section-total-payouts',
        AGENCY_COMMISSION_TOTALS: 'settlements-section-agency-commision-totals',
        GROSS_BOX_OFFICE_OVERRIDE_RECEIPTS: 'settlements-section-finals-total-gbor-value',
        NET_BOX_OFFICE_OVERRIDE_RECEIPTS: 'settlements-section-finals-total-nbor-value',
        TOTAL_TICKETS_SOLD: 'settlements-section-total-tickets-sold',
        TOTAL_COMPS: 'settlements-section-total-comps',
        GBOR: 'settlements-section-gbor',
        NBOR: 'settlements-section-nbor',
        TOTAL_GUARANTEE: 'settlements-section-total-guarantee',
        PAYMENTS_RECIEVED: 'settlements-section-payments-recieved',
      },
      PAYMENTS_TO_CLIENTS: 'settlements-section-payments-to-clients',
      PAYMENTS_ON_CLIENTS_BEHALF: 'settlements-section-payments-on-clients-behalf',
      STATUS: 'settlements-section-status',
      BASE_REMOVE: 'settlements-section-remove',
    },
    ADDITIONAL_CLAUSES_SECTION: {
      TITLE: 'additional-clauses-section-title',
      CLAUSE_TEXT: 'additional-clauses-section-clause-text',
      CLAUSE_DROPDOWN: 'additional-clauses-section-clause-dropdown',
      CLAUSE_DROPDOWN_OPTION: 'additional-clauses-section-clause-dropdown-option',
      ADD_CLAUSE: 'additional-clauses-section-add-clause',
      ADD_CLAUSE_DISABLED: 'additional-clauses-section-add-clause-disabled',
      REMOVE_CLAUSE: 'additional-clauses-section-remove-clause',
    },
    PROVISIONS_SECTION: {
      TITLE: 'provisions-section-title',
      OPTIONS: 'provisions-section-options-dropdown',
      NOTE: 'provisions-section-note',
    },
    FINANCIAL_SECTION: {
      TITLE: 'financial-section-title',
      TERRITORY_DROPDOWN: 'financial-section-territory-dropdown',
      TERRITORY_DROPDOWN_OPTION: 'financial-section-territory-dropdown-option',
      SUBSIDIARY_DROPDOWN: 'financial-section-subsidiary-dropdown',
      SUBSIDIARY_DROPDOWN_OPTION: 'financial-section-subsidiary-dropdown-option',
      LOB_DROPDOWN: 'financial-section-lob-dropdown',
      LOB_DROPDOWN_OPTION: 'financial-section-lob-dropdown-option',
      LOCATION_DROPDOWN: 'financial-section-location-dropdown',
      LOCATION_DROPDOWN_OPTION: 'financial-section-location-dropdown-option',
      DEPARTMENT_DROPDOWN: 'financial-section-department-dropdown',
      DEPARTMENT_DROPDOWN_OPTION: 'financial-section-department-dropdown-option',
    },
    AGENT_SECTION: {
      TITLE: 'agent-section-title',
      PAPERING_AGENT_DROPDOWN: 'agent-section-papering-agent-dropdown',
      PAPERING_DROPDOWN_OPTIONS: 'agent-section-papering-dropdown-options',
    },
    BILLING_INFO: {
      TITLE: 'billing-info-title',
      PERCENT_INPUT: 'billing-percent',
      TYPE: 'billing-type',
      NOTES: 'billing-notes',
      GENERATE_DISPLAY: 'billing-generate-display',
      CLEAR_DISPLAY: 'billing-clear-display',
    },
    CONTRACT_INFO: {
      TITLE: 'contract-info-title',
      TEMPLATE: 'contract-info-template',
      STATUS: 'contract-info-status',
      DUE_DATE: 'contract-info-due-date',
      CONTRACT_INFO_FORM: 'contract-info-form',
      WIRE_TRANSFER_DROPDOWN: 'contract-info-wire-transfer-dropdown',
    },
    SHOW_CONTACTS: {
      TITLE: 'show-contacts-title',
      TYPE_DROPDOWN: 'show-contacts-type-dropdown',
      PERSON_DROPDOWN: 'show-contacts-person-dropdown',
      REMOVE_CONTACT: 'show-contacts-remove-contact-button',
      PERSON_DROPDOWN_OPTIONS: 'show-contacts-person-dropdown-options',
      ADD_CONTACT: 'show-contacts-add-contact',
      ADD_CONTACT_DISABLED: 'show-contacts-add-contact-disabled',
      CONTACT_ROW: 'show-contacts-contact-row',
    },
    CALCULATED_VALUES: {
      TITLE: 'calculated-values-title',
    },
    MERCHANDISE: {
      TITLE: 'merchandise-title',
      ADD_MERCHANDISE: 'add-merchandise',
      TYPE_DROPDOWN: 'merchandise-type',
      MERCHENDISE_PERCENT_INPUT: 'merchendaise-section-percent-input',
      SELLER_DROPDOWN: 'merchandise-seller',
      NOTE: 'merchendise-note',
      REMOVE_MERCHANDISE_ROW: 'remove-merchandise-row',
      MERCHANDISE_ROW: 'merchandise-row',
    },
    PAYMENT_SCHEDULE: {
      TITLE: 'payment-schedules-title',
      SHOW: 'payment-schedules-show',
      DATE_EXPECTED: 'payment-schedules-date-expected',
      AMOUNT: 'payment-schedules-amount',
      PAYMENT_TO: 'payment-schedules-payment-to',
      NOTES: 'payment-schedules-notes',
      AMOUNT_EXPECTED: 'payment-schedules-amount-expected',
      CONTRACT_SPLIT_TYPE: 'payment-schedule-split-type',
    },
    BUYOUTS: {
      TITLE: 'buyouts-title',
      ADD_BUYOUT: 'buyout-add',
      TOTAL_AMOUNT: 'buyouts-total-amount',
      AMOUNT_INPUT: 'buyouts-amount-input',
      PAYMENT_TYPE_DROPDOWN: 'buyouts-paymentType-dropdown',
      PAYMENT_TO_DROPDOWN: 'buyouts-paymentTo-dropdown',
      DATE_INPUT: 'buyouts-date-input',
      DESCRIPTION_INPUT: 'buyouts-note-input',
      REMOVE_BUYOUT: 'buyout-remove',
      BUYOUT_ROW: 'buyout-row',
      SHOW_DROPDOWN: 'buyout-show',
      TYPE_DROPDOWN: 'buyout-type',
      AMOUNT_DUE: 'buyout-amount-due',
    },
    CLIENT_NAME: 'deal-form-client-name',
    DOWNLOAD_CONTRACT_PDF: 'download-contract-pdf',
    ACCOUNTING_SECTION: {
      TITLE: 'accounting-title',
      LOCATION_DROPDOWN: 'accounting-location-dropdown',
      SUBSIDIARY_DROPDOWN: 'accounting-subsidiary-dropdown',
      LINE_OF_BUSINESS_DROPDOWN: 'accounting-line-of-business-dropdown',
      DEPARTMENT_DROPDOWN: 'accounting-department-dropdown',
    },
    SPLIT_DEAL_SECTION: {
      TITLE: 'split-deal-title',
      AMOUNT_INPUT: 'split-deal-section-amount-input',
      SPLIT_PERCENT: 'split-deal-section-split-percent',
      CONTRACT_SPLIT_TYPE: 'split-deal-section-split-type',
      CLIENT_BUSINESS_NAME: 'split-deal-section-client-business-name',
      CONTRACT_SIGNED_BLOCK: 'split-deal-section-contract-signed-block',
      DEAL_DISPLAY: 'split-deal-section-deal-display',
      BALANCE_REMITTANCE: 'split-deal-section-balance-remitance',
    },
  },
  DEAL_SEARCH: {
    TABLE: 'deal-search-table',
    PAGINATION: 'deal-search-pagination',
    SEARCH: {
      ARTIST_DROPDOWN: 'deal-search-artist-dropdown',
      ARTIST_DROPDOWN_OPTION: 'deal-search-artist-dropdown-option',
      SEARCH_BUTTON: 'deal-search-search-button',
    },
  },
  LOGIN_CALLBACK: 'login-callback',
  TOURING_NOTIFICATION: {
    ERROR: 'error-notification',
    WARNING: 'warning-notification',
    SUCCESS: 'success-notification',
  },
};
