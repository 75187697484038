import { getHeaders } from './index';

// eslint-disable-next-line no-undef
const serverUrl = process.env.REACT_APP_API_SERVER;

export const createClientRouteCSV = async (
  id: string,
  printingDateRange: Record<string, string>,
  includeFinancials?: boolean
): Promise<Response> => {
  const url = new URL(`/touring/printing/route-csv/${id}`, serverUrl);

  if (includeFinancials) {
    url.searchParams.set('financials', includeFinancials.toString());
  }
  if (printingDateRange?.startDateString) {
    url.searchParams.set('startDate', printingDateRange.startDateString);
  }
  if (printingDateRange?.endDateString) {
    url.searchParams.set('endDate', printingDateRange.endDateString);
  }

  return fetch(url, {
    method: 'POST',
    headers: getHeaders('text/csv'),
  });
};

export const generatePdf = (contractId: string, redirect: boolean = true): Promise<Response> => {
  const url = new URL(`/touring/contract/generatePDF/${contractId}`, serverUrl);
  url.searchParams.set('redirect', redirect.toString());

  return fetch(url, {
    method: 'GET',
    headers: getHeaders('application/pdf'),
  });
};

export const generateMemoPdf = (contractId: string, redirect: boolean = true): Promise<Response> => {
  const url = new URL(`/touring/contract/generateMemoPDF/${contractId}`, serverUrl);
  url.searchParams.set('redirect', redirect.toString());

  return fetch(url, {
    method: 'GET',
    headers: getHeaders('application/pdf'),
  });
};

export const getFilenameFromHeaders = (headers: Headers, fallback?: string) =>
  headers
    .get('Content-Disposition')
    ?.split(';')
    ?.find((item) => item.includes('filename='))
    ?.replace(/filename=|"/g, '')
    .trim() ||
  fallback ||
  '';
