import { CURRENCIES } from 'deal-form/data/constants';
import { getTerms } from 'deal-form/helpers/dealTermsFormatting';
import { iCurrencyOption } from 'deal-form/interfaces/general';
import { useEffect, useState } from 'react';
import { Deal } from 'types';

export const useArtistCurrency = (deal: Deal) => {
  const [defaultArtistCurrency, setDefaultArtistCurrency] = useState<iCurrencyOption | undefined>(undefined);

  useEffect(() => {
    const terms = getTerms(deal);
    const artistCurrency = CURRENCIES.find((currency: iCurrencyOption) => currency.id === terms.currency.label);

    setDefaultArtistCurrency(artistCurrency);
  }, [deal]);

  return defaultArtistCurrency;
};
