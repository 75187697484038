import { ReactElement } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface PillProps {
  pillText?: string;
  onClear?: () => void;
}

export const Pill = ({ pillText, onClear }: PillProps): ReactElement => {
  return (
    <div
      className={`${onClear ? 'cursor-pointer hover:opacity-50' : ''}`}
      onClick={() => {
        if (onClear) onClear();
      }}
    >
      <div className={`bg-whiteCararra font-medium text-[10px] px-3 py-1`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center mt-[2px] mr-[10px]">{pillText}</div>
          {onClear && <XMarkIcon width={14} />}
        </div>
      </div>
    </div>
  );
};
