import { GridReadyEvent, IServerSideDatasource, IServerSideSelectionState } from 'ag-grid-community';
import { AgGridReact as AgGrid } from 'ag-grid-react';
import { algoliaClient, searchClient } from 'api/search-client';
import ContactRenderer from 'contacts/components/ContactRenderer';
import { NameRenderer } from 'grid-pages/components';
import { KeyValuePair } from 'grid-pages/interfaces';
import GridTable from 'grid-pages/organisms/GridTable';
import { searchPageDefaultCols } from 'grid-pages/utils/configs';
import { getSelectedDataFromRows } from 'grid-pages/utils/export-helpers';
import { doCsvExportFromData } from 'grid-pages/utils/export-helpers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { contactUrlForId } from 'urls';

interface iContactRow {
  id: string | number;
  objectID: string | number;
  _highlightResult?: object;
}

interface ContactGridProps {
  gridApiRef: React.RefObject<AgGrid> | null;
  isExporting?: boolean;
  setIsExporting?: (val: boolean) => void;
}

const ContactsTable = ({ gridApiRef, isExporting, setIsExporting }: ContactGridProps) => {
  const [currentGridData, setCurrentGridData] = useState<iContactRow[] | []>([]);

  const index = process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX || '';

  const defaultColDef = useMemo(() => searchPageDefaultCols, []);

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.objectID;
    };
  }, []);

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { startRow = 0, endRow = 50, filterModel, sortModel } = params.request;
      const sortOrder = sortModel.length ? sortModel[0].sort : undefined;

      let facets = [];
      if (filterModel['type']) {
        facets = filterModel['type'].values.map((v: string) => `type:${v}`);
      }

      let searchQuery = '';
      if (filterModel['contactName']) {
        searchQuery = filterModel['contactName'].filter;
      }

      const searchParams = {
        offset: startRow,
        length: endRow - startRow,
        facetFilters: [facets],
      };

      const indexToUse = sortOrder ? `${index}_${sortOrder}` : index;

      try {
        const searchIndex = algoliaClient.initIndex(indexToUse);
        const results = await searchClient(searchIndex, searchQuery, searchParams);
        const { hits, nbHits, nbSortedHits } = results;

        if (hits) {
          const parsedHits = hits.map((hit: any) => {
            return {
              ...hit,
              contactName: {
                name: hit.name,
                _id: hit.objectID,
                url: contactUrlForId(hit.objectID),
              },
              id: hit.objectID, //need id for csv export
            };
          });
          setCurrentGridData(parsedHits);
          params.success({
            rowData: parsedHits,
            rowCount: sortOrder ? nbSortedHits : nbHits,
          });
        }
      } catch (error) {
        params.fail();
      }
    },
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    params.api.sizeColumnsToFit();
  }, []);

  const getRowHeight = (params: any) => {
    const height = params.data?.contacts?.length > 2 ? params.data?.contacts?.length * 20 : 40;
    return height;
  };

  const gridOptions: any = {
    localeText: {
      noRowsToShow: 'No data to display',
    },
    pagination: true,
    paginationPageSize: 50, //how many rows to display per page, default 100
    cacheBlockSize: 100, //how many rows are fetched at one time, default 100
    rowModelType: 'serverSide',
    getRowHeight: getRowHeight,
  };
  const [columnDefs] = useState([
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: false,
      maxWidth: 44,
      headerClass: 'select-all',
      cellClass: 'select-check-box',
      cellStyle: {
        padding: '8px 10px',
      },
    },
    {
      field: 'contactName',
      headerName: 'Name',
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
      },
      filterValueGetter: (params: any) => {
        const colId = params.column.colId;
        return params.data[colId]?.name;
      },
      valueGetter: (params: any) => {
        const colId = params.column.colId;
        return params.data[colId]?.name;
      },
      valueFormatter: (params: any) => {
        const colId = params.column.colId;
        return params.data[colId];
      },
      cellRenderer: NameRenderer,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      field: 'type',
      headerName: 'Contact Type',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Client', 'Industry Contact', 'Shared', 'Employee', 'Outlook'],
      },
    },
    {
      field: 'contacts',
      headerName: 'Contact Info',
      enableValue: true,
      cellRenderer: ContactRenderer,
    },
  ]);

  useEffect(() => {
    if (gridApiRef && isExporting) {
      const selectedRows = gridApiRef.current?.api?.getServerSideSelectionState() as IServerSideSelectionState;
      if (currentGridData && selectedRows) {
        const selectedData = getSelectedDataFromRows(selectedRows, currentGridData);
        if (selectedData) {
          const parsedSelected: KeyValuePair[] = selectedData.map((item) => {
            const contacts = item.contacts?.map((c: any) => `${c.contactType}: ${c.contact}\r\n`);
            return {
              name: item.name,
              type: item.type,
              contacts: contacts ? contacts.join('') : '',
            };
          });
          const titleKeys: string[] = columnDefs.filter((_, i) => i !== 0).map((d) => d.headerName || '');
          const refinedData: string[][] = [titleKeys];
          parsedSelected.forEach((row) => {
            const rowValues: string[] = Object.values(row);
            refinedData.push(rowValues);
          });
          doCsvExportFromData(refinedData, 'contacts');
        }
      }

      if (setIsExporting) setIsExporting(false);
    }
  }, [isExporting]);

  return (
    <GridTable
      ref={gridApiRef}
      pageId="contacts"
      rowSelection="multiple"
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      defaultColDef={{ ...defaultColDef, sortable: false }}
      getRowId={getRowId}
      onGridReady={onGridReady}
    />
  );
};

export default ContactsTable;
