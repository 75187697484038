import { apiServerUrl, defaultUrl } from '../urls';

export const USER_ID = '5cda086534eaf10012cb4646';
export const DESK_ID = '5b1989fc460a69000f4c0b00';

const UID_FIELD = 'uta.uid';
const DESKS_FIELD = 'uta.desks';
const CURRENT_DESK_FIELD = 'uta.currentDesk';
const CURRENT_DESK_ID_FIELD = 'currentDeskId';
const AUTH_FIELD = 'uta.auth';

// eslint-disable-next-line no-undef
const IS_LOCAL = process.env.REACT_APP_NAME === 'touring.local';

// TODO - TOUR-514 - this should die when the  login flow is updated.
//  it was decided to leverage this not-so-graceful way of tricking the login-flow
//  because updates to authentication are in the works where this will no longer be necessary
export const MOCK_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVjZGEwODY1MzRlYWYxMDAxMmNiNDY0NiIsImRlc2tzIjpbeyJfaWQiOiI1YjE5ODlmYzQ2MGE2OTAwMGY0YzBiMDAiLCJuYW1lIjoiYWRkcmVzc0Jvb2suY29udGFjdHMuY29udGFjdF8xIn1dLCJwZXJtaXNzaW9ucyI6eyJwcm9qZWN0cyI6eyJwcmludGluZyI6WyJ3YXRlcm1hcmsiLCJyZXBBZ3JlZW1lbnRzIiwiaW5kaWVOb3RlcyIsInNhbGVzRmluYW5jaW5nIiwiaW5kaWVTdGF0dXMiLCJncm91cEJ5SW5kaWVTdGF0dXMiXSwiZGV0YWlscyI6WyJyZXBBZ3JlZW1lbnRzIiwiaW5kaWVTdGF0dXMiLCJpbmRpZU5vdGVzIiwic2FsZXNGaW5hbmNpbmciXSwicHJvamVjdFNlYXJjaCI6WyJyZXBBZ3JlZW1lbnRzIiwic2FsZXNGaW5hbmNpbmciLCJpbmRpZVN0YXR1cyJdfSwic3VibWlzc2lvbnMiOnsiYWNjZXNzIjpbIndlYiJdfSwicGhvbmVzaGVldCI6eyJhY2Nlc3MiOlsid2ViIl19fSwiaWF0IjoxNzEwNDE5ODY3LCJleHAiOjIwMjU3Nzk4Njd9.aC19OhJa_wYf9hEMbb_Ot3HBQMgSLLgSUurckuwvETk';

/**
 * Checks to see if the environment is running locally and then provisions the
 * browser's localStorage with the required data to authenticate through a locally running server
 */
export const login = async () => {
  if (IS_LOCAL) {
    localStorage.setItem(UID_FIELD, USER_ID);
    localStorage.setItem(AUTH_FIELD, MOCK_TOKEN);
    localStorage.setItem(DESKS_FIELD, '[]');
    localStorage.setItem(CURRENT_DESK_FIELD, DESK_ID);
    localStorage.setItem(CURRENT_DESK_ID_FIELD, DESK_ID);
  }
};

// Azure AD endpoint for UTA Tenant.
//  - This endpoint allows Azure to kick off the authentication process
const endpoint = 'https://login.microsoftonline.com/4cb9240e-2025-488f-97e7-7eb69335458a/oauth2/v2.0/authorize';

const AZURE_AUTH_PROPS: Record<string, string> = {
  redirect_uri: `${apiServerUrl}/auth/azure/callback`, // the API route that is hit w/ a POST after Azure does what it does.
  response_type: 'code id_token', // what we want from Azure
  response_mode: 'form_post', // how we want Azure to response (aka a POST)
  client_id: '29d00b38-2243-4edb-8540-770475e20eab', // client id of UTA Authentication registered service on Azure.
  state: `${defaultUrl}/login-callback?relay=%2F`, // the application route that is hit after everything is done.
  nonce: 'c8c298ed-b678-40e8-816e-36780c96f724', // unique uuid4()

  scope: [
    'profile',
    'email',
    'offline_access',
    'User.Read',
    'Contacts.ReadWrite',
    'User.ReadWrite',
    'openid',
    'Calendars.ReadWrite.Shared',
    'Calendars.ReadWrite',
    'Contacts.ReadWrite.Shared',
    'Files.ReadWrite',
    'Mail.Send.Shared',
    'Mail.Send',
    'Mail.ReadWrite',
    'Mail.ReadWrite.Shared',
    'People.Read',
  ].join(' '),
};

/**
 * Encodes all parameters within the above AZURE_AUTH_PROPS such that
 * they can be included in a valid URI address that the client can redirect to.
 */
const encodeLoginParams = () => {
  const params: string[] = [];
  Object.keys(AZURE_AUTH_PROPS).forEach((key) => {
    params.push(`${key}=${encodeURIComponent(AZURE_AUTH_PROPS[key])}`);
  });
  return params;
};

/**
 * Authenticates the Client Session so that it can make authorized API transaction requests
 *
 * - IS_LOCAL flag is for local development purposes. If running locally the authentication
 *   flow is skipped and a long-lived token is provided into a callback route where its
 *   added into the client's localStorage
 */
export const authenticate = () => {
  if (IS_LOCAL) {
    window.location.href = `${defaultUrl}/login-callback#jwt=${MOCK_TOKEN}`;
  } else {
    window.location.href = `${endpoint}?${encodeLoginParams().join('&')}`;
  }
};
