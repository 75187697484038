import { STATUS_TRANSITIONS, STATUS_TYPES } from 'features/deal-form';
import _ from 'lodash';
import { createMachine } from 'xstate';

const isPending = (ctx, event) => event.status === STATUS_TYPES.PENDING;
const isConfirmed = (ctx, event) => event.status === STATUS_TYPES.CONFIRMED;
const isRejected = (ctx, event) => event.status === STATUS_TYPES.REJECTED;
const isAgentReview = (ctx, event) => event.status === STATUS_TYPES.AGENT_REVIEW;
const isReady = (ctx, event) => event.status === STATUS_TYPES.READY_TO_ISSUE;
const isIssued = (ctx, event) => event.status === STATUS_TYPES.CONTRACT_ISSUED;
const isMarkup = (ctx, event) => event.status === STATUS_TYPES.MARKUP_REVIEW;
const isSigned = (ctx, event) => event.status === STATUS_TYPES.BUYER_SIGNED;
const isToClient = (ctx, event) => event.status === STATUS_TYPES.TO_CLIENT;
const isExecuted = (ctx, event) => event.status === STATUS_TYPES.FULLY_EXECUTED;
const isCancelled = (ctx, event) => event.status === STATUS_TYPES.CANCELLED;
const isCancelledWithPayment = (ctx, event) => event.status === STATUS_TYPES.CANCELLED_WITH_PAYMENT;
const isSettled = (ctx, event) => event.status === STATUS_TYPES.SETTLED;

const targets = {
  pending: { target: 'pending', cond: isPending },
  confirmed: { target: 'confirmed', cond: isConfirmed },
  rejected: { target: 'rejected', cond: isRejected },
  agentReview: { target: 'agentReview', cond: isAgentReview },
  readyToIssue: { target: 'readyToIssue', cond: isReady },
  contractIssued: { target: 'contractIssued', cond: isIssued },
  markupReview: { target: 'markupReview', cond: isMarkup },
  buyerSigned: { target: 'buyerSigned', cond: isSigned },
  toClient: { target: 'toClient', cond: isToClient },
  fullyExecuted: { target: 'fullyExecuted', cond: isExecuted },
  cancelled: { target: 'cancelled', cond: isCancelled },
  cancelledWithPayment: { target: 'cancelledWithPayment', cond: isCancelledWithPayment },
  settled: { target: 'settled', cond: isSettled },
};

const getTransitions = (type, state) => {
  if (type == 'options') {
    return STATUS_TRANSITIONS[state]
      .filter((option) => option !== 'CONTRACT_ISSUED')
      .map((option) => STATUS_TYPES[option]);
  } else if (type == 'targets') {
    return STATUS_TRANSITIONS[state].map((option) => targets[_.camelCase(option)]);
  }
};

export const contractMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'contract',
    initial: 'idle',
    context: {
      availableStatuses: [],
      lockedFields: [],
      dealIsLocked: false,
    },
    states: {
      idle: {
        on: {
          INIT: Object.values(targets),
        },
      },
      rejected: {
        entry: ['setRejectedFields'],
      },
      pending: {
        entry: ['setPendingFields'],
        on: {
          UPDATE: getTransitions('targets', 'pending'),
        },
      },
      confirmed: {
        entry: ['setConfirmedFields'],
        on: {
          UPDATE: getTransitions('targets', 'confirmed'),
        },
      },
      agentReview: {
        entry: 'setAgentReviewFields',
        on: {
          UPDATE: getTransitions('targets', 'agentReview'),
        },
      },
      readyToIssue: {
        entry: ['setReadyToIssueFields'],
        on: {
          UPDATE: getTransitions('targets', 'readyToIssue'),
        },
      },
      contractIssued: {
        entry: ['setContractIssuedFields'],
        on: {
          UPDATE: getTransitions('targets', 'contractIssued'),
        },
      },
      markupReview: {
        entry: ['setMarkupReviewFields'],
        on: {
          UPDATE: getTransitions('targets', 'markupReview'),
        },
      },
      buyerSigned: {
        entry: ['setBuyerSignedFields'],
        on: {
          UPDATE: getTransitions('targets', 'buyerSigned'),
        },
      },
      toClient: {
        entry: ['setToClientFields'],
        on: {
          UPDATE: getTransitions('targets', 'toClient'),
        },
      },
      fullyExecuted: {
        entry: ['setFullyExecutedFields'],
        on: {
          UPDATE: getTransitions('targets', 'fullyExecuted'),
        },
      },
      cancelled: {
        entry: ['setCancelledFields'],
      },
      cancelledWithPayment: {
        entry: ['setCancelledWithPaymentFields'],
        on: {
          UPDATE: getTransitions('targets', 'cancelledWithPayment'),
        },
      },
      settled: {
        entry: ['setSettledFields'],
      },
    },
  },
  {
    actions: {
      setRejectedFields: (ctx) => {
        ctx.dealIsLocked = false;
        ctx.lockedFields = ['*'];
        ctx.availableStatuses = getTransitions('options', 'rejected');
      },
      setConfirmedFields: (ctx) => {
        ctx.dealIsLocked = false;
        ctx.lockedFields = ['deal.buyer.person'];
        ctx.availableStatuses = getTransitions('options', 'confirmed');
      },
      setPendingFields: (ctx) => {
        ctx.dealIsLocked = false;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'pending');
      },
      setAgentReviewFields: (ctx) => {
        ctx.dealIsLocked = false;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'agentReview');
      },
      setReadyToIssueFields: (ctx) => {
        ctx.dealIsLocked = false;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'readyToIssue');
      },
      setContractIssuedFields: (ctx) => {
        ctx.dealIsLocked = false;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'contractIssued');
      },
      setMarkupReviewFields: (ctx) => {
        ctx.dealIsLocked = false;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'markupReview');
      },
      setBuyerSignedFields: (ctx) => {
        ctx.dealIsLocked = true;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'buyerSigned');
      },
      setToClientFields: (ctx) => {
        ctx.dealIsLocked = true;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'toClient');
      },
      setFullyExecutedFields: (ctx) => {
        ctx.dealIsLocked = true;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'fullyExecuted');
      },
      setCancelledFields: (ctx) => {
        ctx.dealIsLocked = true;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'cancelled');
      },
      setCancelledWithPaymentFields: (ctx) => {
        ctx.dealIsLocked = true;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'cancelledWithPayment');
      },
      setSettledFields: (ctx) => {
        ctx.dealIsLocked = false;
        ctx.lockedFields = [];
        ctx.availableStatuses = getTransitions('options', 'settled');
      },
      notify: (ctx, event) => {
        console.info('STATUS UPDATED TO -', event.status);
      },
    },
  }
);
