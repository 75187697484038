import { FormSelect, SelectOptionT } from '@united-talent-agency/onyx-components';
import { useFilters } from 'context/avails-filters/context';
import useIsMobile from 'hooks/isMobile';
import { Close } from '@united-talent-agency/onyx-components/Icons';
import { GENRE_OPTIONS } from '../constants';

const GenresFilter = () => {
  const { selectedGenres, genresFocusedView, setSelectedGenres, setGenresFocusedView } = useFilters();
  const isMobile = useIsMobile();

  return (
    <div className="relative flex items-center justify-center">
      <FormSelect
        placeholder="Select Genre"
        label="Genre(s)"
        // isClearable
        isMulti
        isSearchable
        options={GENRE_OPTIONS}
        menuShouldBlockScroll
        value={selectedGenres}
        menuIsOpen={isMobile ? genresFocusedView : undefined}
        onFocus={() => isMobile && setGenresFocusedView(true)}
        onChange={(value) => setSelectedGenres([...value])}
        noOptionsMessage="No Genre"
        loadOptions={async (inputValue: string) =>
          new Promise<SelectOptionT[]>((resolve) => {
            resolve(
              GENRE_OPTIONS.reduce<SelectOptionT[]>((result, i) => {
                if (i.label.toLocaleLowerCase().includes(inputValue.toLowerCase())) {
                  return result.concat(i);
                }
                return result;
              }, [])
            );
          })
        }
        className="w-full"
      />
      <div
        className={`absolute cursor-pointer top-[12px] right-[50px] p-1 bg-white ${
          selectedGenres.length === 0 ? 'hidden' : 'block z-20'
        }`}
      >
        <Close height={16} width={16} onClick={() => setSelectedGenres([])} />
      </div>
    </div>
  );
};

export default GenresFilter;
