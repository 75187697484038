import { StickyHeaderPage } from 'features/layouts';
import { PageHeader } from 'grid-pages/components';
import { CustomReports } from 'grid-pages/pages/CustomReports';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import { useCallback, useState } from 'react';

const CustomReportsPage = () => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();

  // States
  const [isExporting, setIsExporting] = useState<boolean>(false);
  // TODO: move to context
  const [title, setTitle] = useState('Custom Reports');

  const handleOnExport = () => {
    setIsExporting(true);
  };

  const renderFixedContainer = useCallback(() => <PageHeader onExport={handleOnExport} title={title} />, []);

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="main-wrapper">
        <div
          className="grid-height content-wrapper md:pt-10"
          onMouseOver={lockOverscrollNavigation}
          onMouseLeave={unlockOverscrollNavigation}
        >
          <CustomReports isExporting={isExporting} setIsExporting={setIsExporting} setTitle={setTitle} />
        </div>
      </div>
    </StickyHeaderPage>
  );
};

export default CustomReportsPage;
