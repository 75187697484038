import React, { ReactElement } from 'react';

import { OVERVIEW_ORGANISM_SECTIONS } from 'deal-form/data/constants';

export default function FlyoutMenuMobile({
  overviewRef,
  onLinkClick,
}: {
  setOverviewMenuOpen?: (data: boolean) => void;
  overviewRef: any;
  onLinkClick: (hash: string, event: React.MouseEvent<HTMLAnchorElement>) => void;
}): ReactElement {
  const mobileSortedSections = OVERVIEW_ORGANISM_SECTIONS.sort((a, b) => a.mobileSortOrder - b.mobileSortOrder);
  return (
    <div className="relative mb-2 text-left pl-2 pr-1" ref={overviewRef}>
      {mobileSortedSections.map((section) => (
        <a
          key={section.name}
          href={section.href}
          className="w-full  font-semibold text-gray-900 cursor-pointer text-sm"
          onClick={(event) => onLinkClick(section.href, event)}
        >
          <div className={`hover:bg-greyPearl`}>
            <div>
              {section.name}
              <p className=" text-grey text-xs mt-1">{section.description}</p>
            </div>
          </div>
          <div className="w-full  h-px bg-greyQuill my-3"></div>
        </a>
      ))}
    </div>
  );
}
