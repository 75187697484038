import { XMarkIcon } from '@heroicons/react/20/solid';
import { ReactNode, ReactElement, useRef } from 'react';
import { useClickAway } from 'react-use';

export default function mobileSubMenuModal({
  children,
  setModalOpen,
}: {
  children?: ReactNode;
  setModalOpen: (data: boolean) => void;
}): ReactElement {
  const dealFormModalRef = useRef(null);
  useClickAway(dealFormModalRef, () => {
    setModalOpen(false);
  });

  return (
    <div className="absolute md:hidden block z-10">
      <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center">
          <div className="relative overflow-hidden bg-white p-4 shadow-xl w-full" ref={dealFormModalRef}>
            <div>
              <div className="w-full mr-0 flex justify-end align-right rounded-full">
                <div className="w-full flex justify-center">
                  <div className="w-24 ml-8 h-1 bg-greyQuill mt-2"></div>
                </div>
                <div onClick={() => setModalOpen(false)}>
                  <XMarkIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
              </div>

              <div className="mt-4 text-center sm:mt-6">
                <div className="mt-2">{children}</div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
