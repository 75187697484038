import { Modal } from 'features/common/modal/Modal';
import { RemoveButton } from 'deal-form/ui/RemoveButton';
import Button from 'components/Button';

interface ModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  mobileView: boolean;
  deleteDeal: (dealId: string) => void;
  dealId: string;
}

/**
 * Linked Deals Modal
 *
 * Shows a popup with a list of deals to link to current deal
 */
export const RemoveDealModal = ({ showModal, setShowModal, mobileView, deleteDeal, dealId }: ModalProps) => {
  return (
    <div>
      {mobileView ? (
        <button
          className="text-black block md:hidden"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {' '}
          Link Deal
        </button>
      ) : (
        <RemoveButton
          className="flex w-10 items-center justify-center self-start"
          onClick={() => {
            setShowModal(true);
          }}
        />
      )}

      {showModal && (
        <Modal
          title="Confirm"
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={false}
          className="mx-60 max-w-full"
        >
          <p className="text-center">Are you sure you want to remove this show from its linked deal ?</p>
          <div className="mt-6 flex justify-between">
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
                deleteDeal(dealId);
              }}
            >
              Conirm
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
