import { FC } from 'react';
import FilterContainer from 'containers/availability/FilterContainer';
import { PageHeader } from 'grid-pages/components';
import AvailsTabs from 'containers/availability/AvailsTabs';
import moment from 'moment';
import logoBlackAndWhite from 'assets/images/logo-black-and-white.png';
import { User } from 'types';
import { Desk } from 'types/RootState';
import { StickyHeaderPage } from 'features/layouts/stickyHeaderPage';

type AvailabilityPageProps = {
  currentDeskId: string;
  desks: Desk[];
  user: User;
};

export const Availability: FC<AvailabilityPageProps> = ({ currentDeskId, desks, user }) => {
  const currentDesk = desks.find(({ _id }) => _id === currentDeskId);

  const renderFixedContainer = () => <PageHeader title="Availability" isAvails />;

  return (
    <StickyHeaderPage
      renderFixedContainer={renderFixedContainer}
      className="bg-[#f5f5f5] print:bg-transparent [&::-webkit-scrollbar]:print:hidden print:[-ms-overflow-style:none] print:[scrollbar-width:none]  [&>div]:last:print:!pt-0"
    >
      <div className="break-inside-avoid">
        <div className="hidden print:flex fixed top-0 right-0 bottom-0 left-0 items-center justify-center opacity-10 z-10">
          <div className="flex items-center justify-center -rotate-45 scale-[0.7071] h-[min(100vh,_100vw)] w-[min(100vh,_100vw)] origin-center">
            <span className="text-9xl text-center text-[#6B6A69] font-bold cursor-none select-none">
              {currentDesk?.name}
            </span>
          </div>
        </div>
        <div className="hidden print:flex flex-col justify-between">
          <img src={logoBlackAndWhite} width={44 * 1.3} height={48 * 1.3} alt="UTA Logo" />
        </div>
        <div className="hidden print:flex flex-1 flex-row justify-between items-start pt-4">
          <h1 className="text-[42px] leading-[44px] font-bold">CALENDAR</h1>
          <div className="flex flex-col gap-1 text-xs font-medium text-right">
            <span>{user?.email}</span>
            <span>{moment().format('L LT')}</span>
          </div>
        </div>
        <FilterContainer currentDeskId={currentDeskId} />
      </div>
      <AvailsTabs />
    </StickyHeaderPage>
  );
};
