import { TableCellData } from 'components/Table/types';
import { FC, PropsWithChildren, useMemo } from 'react';
import Cell from '.';
import { BodyCellRootProps } from './Root';

export type BodyCellFullProps<T = unknown> = PropsWithChildren<
  {
    column: Partial<TableCellData<T>>;
    columnIndex?: number;
    rowIndex?: number;
  } & BodyCellRootProps
>;

const CellFull: FC<BodyCellFullProps> = ({ columnIndex, rowIndex, children, column: { unavailable }, ...props }) => {
  const key = useMemo(
    () => (rowIndex !== undefined && columnIndex !== undefined ? `column-${columnIndex}-row-${rowIndex}` : undefined),
    [rowIndex, columnIndex]
  );

  return (
    <Cell.Root key={key} unavailable={unavailable} {...props}>
      {children}
    </Cell.Root>
  );
};

export default CellFull;
