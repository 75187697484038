import { LinkIcon } from '@heroicons/react/24/solid';
import { getLinkableDeals } from 'api/deals';
import { linkDeals } from 'api/deals';
import { formatCurrencyWithSymbol, formatDate, parseDealTypeName } from 'deal-form/helpers/dealHelpers';
import { getMasterShow } from 'deal-form/helpers/showHelpers';
import { iCurrency } from 'deal-form/interfaces/general';
import { PlainCheckbox } from 'deal-form/ui/PlainCheckbox';
import { Modal } from 'features/common/modal/Modal';
import { useEffect, useMemo, useState } from 'react';
import { Contract, Deal } from 'types';

import Button from 'components/Button';
import cypressTags from 'support/cypressTags';

const { HEADER, LINK_DEAL_MODAL } = cypressTags.DEAL_FORM;
interface ModalProps {
  dealId: string;
  dealType: string;
  contract: Contract;
  setContract?: (c: Contract) => void;
  mobileView: boolean;
}

interface iModalDeal {
  id: string;
  date: string;
  venue: string;
  dealType: string;
  guarantee: iCurrency;
  deal: Deal;
}

/**
 * Linked Deals Modal
 *
 * Shows a popup with a list of deals to link to current deal
 */
export const LinkedDealsModal = ({ dealId, dealType, contract, setContract, mobileView }: ModalProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [singleSelectedIds, setSingleSelectedIds] = useState(new Set());
  const [originalDealIds, setOriginalDealIds] = useState(new Set());
  const [dealsData, setDeals] = useState<iModalDeal[] | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [crossed, setCrossed] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const filteredDealsByType = useMemo(() => {
    if (!crossed) {
      return dealsData;
    }

    // Crossed deals required the Deal Type to be the same
    return dealsData?.filter((deal) => deal.dealType === dealType);
  }, [dealsData, crossed]);

  const itemStyles = 'py-2';

  const updateLinkedDeals = async () => {
    if (dealsData) {
      const combinedDealIds = originalDealIds ? Array.from(originalDealIds) : [];
      const allFilteredDealIds = dealsData.map((deal) => deal.id);
      const dealIds = selectAll ? allFilteredDealIds : Array.from(singleSelectedIds);
      dealIds.forEach((id) => {
        if (!combinedDealIds.includes(id)) {
          combinedDealIds.push(id);
        }
      });

      const updateBody = {
        dealIDs: [...combinedDealIds, dealId],
        linkType: crossed ? 'Crossed' : 'Non-Crossed',
      };

      try {
        const updatedContract = await linkDeals(contract._id, updateBody);
        if (updatedContract) {
          setContract && setContract(updatedContract);
          setShowModal(false);
          setSelectAll(false);
          setStartDate('');
          setEndDate('');
        }
      } catch (error) {
        //TODO display error somehow?
        console.log('Error linking deals', error);
      }
    }
  };

  const getDeals = async () => {
    setIsSearching(true);

    const selectedSet = new Set();
    contract.deals.forEach((d) => {
      if (d._id !== dealId) {
        selectedSet.add(d._id);
      }
    });
    setOriginalDealIds(selectedSet);
    setSingleSelectedIds(selectedSet);

    const deals = await getLinkableDeals(dealId, startDate, endDate);
    if (deals && deals.length) {
      setCrossed(contract.crossed ? contract.crossed === 'Crossed' : true);
      let data: iModalDeal[] = deals.map((deal: Deal) => {
        const masterShow = getMasterShow(deal.shows);

        return {
          id: deal._id,
          date: masterShow?.date ? formatDate(masterShow.date) : '',
          venue: masterShow?.venue?.name || '',
          dealType: deal.termTypes ? deal.termTypes[0] : '',
          guarantee: {
            value: deal.guarantee || 0,
            currency: deal.currency || 'USD',
          },
          deal: deal,
        };
      });

      data = data.filter((d) => d.id !== dealId);
      setDeals(data);
    } else {
      setDeals(null);
    }
    setIsSearching(false);
  };

  useEffect(() => {
    getDeals();
  }, [contract]);

  const handleCheckboxChange = (id: string) => {
    if (isSearching) {
      return;
    }

    const selectedExcept = new Set(singleSelectedIds);
    if (selectedExcept.has(id)) {
      selectedExcept.delete(id);
      originalDealIds.delete(id);
      setSingleSelectedIds(selectedExcept);
      setOriginalDealIds(originalDealIds);
    } else {
      selectedExcept.add(id);
      setSingleSelectedIds(selectedExcept);
    }
  };

  const handleChangeCrossed = (isCrossed: boolean) => {
    // TODO: Look into only deselecting deals with different deal
    // types when switching from a non-crossed to a crossed link type
    setSelectAll(false);
    setSingleSelectedIds(new Set());

    setCrossed(isCrossed);
  };

  return (
    <div>
      {mobileView ? (
        <button
          className="text-black block md:hidden"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {' '}
          Link Deal
        </button>
      ) : (
        <Button
          variant="primary"
          leftIcon={<LinkIcon width={16} height={16} />}
          onClick={() => {
            setShowModal(true);
          }}
          dataCy={HEADER.LINK_DEAL_BUTTON}
        >
          Link Deal
        </Button>
      )}

      {showModal && (
        <Modal
          title="Link Deals"
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={false}
          className="lg:min-w-fit max-w-full overflow-x-auto"
          dataCyModalTitle={LINK_DEAL_MODAL.MODAL_TITLE}
        >
          <div>
            <form>
              <fieldset
                disabled={isSearching}
                className="flex flex-row items-center gap-x-2 pt-[20px] pb-[16px] text-sm"
                data-cy={LINK_DEAL_MODAL.LINK_TYPE}
              >
                <legend className="contents text-greyCod">Link Type:</legend>
                <input
                  type="radio"
                  id="nonCrossed"
                  name="linkType"
                  checked={!crossed}
                  className="ml-4 scale-125"
                  onChange={() => handleChangeCrossed(false)}
                />
                <label htmlFor="nonCrossed">Non-Crossed</label>
                <input
                  type="radio"
                  id="crossed"
                  name="linkType"
                  checked={crossed}
                  className="ml-4 scale-125"
                  onChange={() => handleChangeCrossed(true)}
                />
                <label htmlFor="crossed">Crossed</label>
              </fieldset>
            </form>
          </div>
          <div>
            <form className="mb-6 flex items-end gap-x-4">
              <div>
                <label htmlFor="startDate" className="standard-label">
                  Start Date
                </label>
                <input
                  className="peer h-10 w-full appearance-none border border-solid border-black px-2 py-1 text-sm"
                  type="date"
                  placeholder="start date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  disabled={isSearching}
                  data-cy={LINK_DEAL_MODAL.START_DATE}
                />
              </div>
              <div>
                <label htmlFor="startDate" className="standard-label">
                  End Date
                </label>
                <input
                  className="peer h-10 w-full appearance-none border border-solid border-black px-2 py-1 text-sm"
                  type="date"
                  placeholder="end date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  disabled={isSearching}
                  data-cy={LINK_DEAL_MODAL.END_DATE}
                />
              </div>

              <div className="ml-4">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    getDeals();
                  }}
                  variant="secondary"
                  disabled={isSearching}
                  dataCy={LINK_DEAL_MODAL.SEARCH_BUTTON}
                >
                  Search
                </Button>
              </div>
            </form>
            <div className="max-h-[45vh] overflow-y-scroll border-b border-greyCloudy border-opacity-50 md:min-w-[617px]">
              <div className="grid grid-cols-[32px_1fr_2fr_1fr_1fr] border-b border-black text-sm font-medium">
                <div className="flex items-center">
                  <PlainCheckbox
                    id={dealId}
                    checked={selectAll}
                    indeterminate={selectAll && singleSelectedIds?.size}
                    handleChange={() => {
                      if (selectAll && singleSelectedIds?.size) {
                        setSelectAll(false);
                        setSingleSelectedIds(new Set());
                      } else {
                        setSelectAll(!selectAll);
                        setSingleSelectedIds(new Set());
                      }
                    }}
                    disabled={isSearching}
                  />
                </div>
                <div className={`text-bold ${itemStyles}`}>Dates</div>
                <div className={`text-bold ${itemStyles}`}>Venue</div>
                <div className={`text-bold ${itemStyles}`}>Deal Type</div>
                <div className={`text-bold ${itemStyles}`}>Guarantee</div>
              </div>

              {filteredDealsByType?.length ? (
                filteredDealsByType.map((deal) => (
                  <div
                    key={deal.id}
                    className={`grid gap-2 grid-cols-[32px_1fr_2fr_1fr_1fr] text-sm ${
                      isSearching ? 'cursor-default' : 'cursor-pointer'
                    }`}
                    data-cy={`${LINK_DEAL_MODAL.LINKABLE_DEAL}-${deal.id}`}
                  >
                    <div className="flex items-center">
                      <PlainCheckbox
                        id={deal.id}
                        checked={selectAll ? !singleSelectedIds.has(deal.id) : singleSelectedIds.has(deal.id)}
                        handleChange={(id) => handleCheckboxChange(id)}
                        disabled={isSearching}
                        data-cy={`${LINK_DEAL_MODAL.SELECT_DEAL}-${deal.id}`}
                      />
                    </div>
                    <div onClick={() => handleCheckboxChange(deal.id)} className={itemStyles}>
                      {deal.date}
                    </div>
                    <div onClick={() => handleCheckboxChange(deal.id)} className={itemStyles}>
                      {deal.venue}
                    </div>
                    <div onClick={() => handleCheckboxChange(deal.id)} className={itemStyles}>
                      {parseDealTypeName(deal.dealType)}
                    </div>
                    <div
                      data-cy={`${LINK_DEAL_MODAL.LINKABLE_DEAL_GUARANTEE}-${deal.id}`}
                      onClick={() => handleCheckboxChange(deal.id)}
                      className={itemStyles}
                    >
                      {formatCurrencyWithSymbol(deal.guarantee.value, deal.guarantee.currency)}
                    </div>
                  </div>
                ))
              ) : (
                <p className="py-10 text-sm">There are no results matching your search criteria.</p>
              )}
            </div>
            <div className="mt-6 flex justify-between">
              <Button
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>

              <Button
                leftIcon={<LinkIcon width={16} height={16} />}
                onClick={(e) => {
                  e.preventDefault();
                  updateLinkedDeals();
                }}
                disabled={dealsData === null || isSearching}
                variant="secondary"
                dataCy={LINK_DEAL_MODAL.LINK_BUTTON}
              >
                Link
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
