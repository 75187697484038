import { SelectOptionT } from '@united-talent-agency/onyx-components';
import { CalendarViewData } from './TimelineAgendaView';
import { CustomShowsHitType, DateRange, Position } from 'context/avails-filters/hook';
import { AvailsShowStatus, AvailsShowType } from 'types/Show';
import { ClauseViolationDataType, TimelineAgendaData } from 'components/TimelineAgenda/types';
import { ShowsAgendaData } from 'components/ShowsAgenda/types';
import { searchClientUnavailableDates } from 'api/search-client';

export const STATUSES_CONFIG = [
  {
    status: 'Work Visa',
    statusLabel: 'Work Visa',
    statusBg: '#E95344',
    statusColor: '#ffffff',
  },
  {
    status: 'Blocked',
    statusLabel: 'Blocked',
    statusBg: '#E95344',
    statusColor: '#ffffff',
  },
  {
    status: '1st Hold',
    statusLabel: '1st Hold',
    statusBg: '#E87D00',
    statusColor: '#ffffff',
  },
  {
    status: '2nd Hold',
    statusLabel: '2nd Hold',
    statusBg: '#E87D00',
    statusColor: '#ffffff',
  },
  {
    status: '3rd Hold',
    statusLabel: '3rd Hold',
    statusBg: '#E87D00',
    statusColor: '#ffffff',
  },
  {
    status: '4th Hold',
    statusLabel: '4th Hold',
    statusBg: '#E87D00',
    statusColor: '#ffffff',
  },
  {
    status: '5th Hold',
    statusLabel: '5th Hold',
    statusBg: '#E87D00',
    statusColor: '#ffffff',
  },
  {
    status: '6th Hold',
    statusLabel: '6th Hold',
    statusBg: '#E87D00',
    statusColor: '#ffffff',
  },
  {
    status: 'Confirmed',
    statusLabel: 'Confirmed',
    statusBg: '#29A1DB',
    statusColor: '#ffffff',
  },
  {
    status: 'Routed',
    statusLabel: 'Routing',
    statusBg: '#90C04C',
    statusColor: '#ffffff',
  },
  {
    status: 'Pending',
    statusLabel: 'Pending',
    statusBg: '#3748AC',
    statusColor: '#ffffff',
  },
];

export const createRangeValues = (price: number): SelectOptionT[] => {
  let arr: SelectOptionT[] = [];
  let value = 0;

  while (value < price) {
    value = value < 100000 ? (value += 10000) : (value += 100000);
    let formattedValue = value.toLocaleString('en-US');
    arr.push({
      value,
      label: '$' + formattedValue,
    });
  }
  arr.unshift({ value: 0, label: '$0' });

  return arr;
};

export const formatResultsToAgendaViewData = (
  results: CustomShowsHitType[],
  dateRange: DateRange
): CalendarViewData[] => {
  const clients: { [id: string]: CalendarViewData } = {};

  // Create a new Date object for the start and end dates from the date range.
  const startDate = new Date(dateRange.start);
  const endDate = new Date(dateRange.end);

  results.forEach((result) => {
    const clientId = result.client._id;
    const clientName = result.client.name;
    const showDate = new Date(result.show.date);
    const showInfo = {
      title: result.show.title,
      venue: { address: { address: result.venue.address.address ?? '' } },
      status: result.show.status,
    };

    // Check if the show date is within the given date range
    if (showDate >= startDate && showDate <= endDate) {
      if (!clients[clientId]) {
        clients[clientId] = {
          client: { id: clientId, name: clientName },
          dates: [],
        };
      }

      const existingDates = clients[clientId].dates.map((d) => d.date.toDateString());
      if (!existingDates.includes(showDate.toDateString())) {
        clients[clientId].dates.push({ date: showDate, shows: [showInfo] });
      } else {
        const existingDate = clients[clientId].dates.find((d) => d.date.toDateString() === showDate.toDateString());
        existingDate?.shows?.push(showInfo);
      }
    }
  });

  // Add missing dates within the date range for each client and sort the dates
  for (const clientId in clients) {
    let current = new Date(startDate);
    while (current <= endDate) {
      const dateString = current.toDateString();
      if (!clients[clientId].dates.some((d) => d.date.toDateString() === dateString)) {
        clients[clientId].dates.push({ date: new Date(current) });
      }
      current.setDate(current.getDate() + 1);
    }

    // Sort again to include newly added dates
    clients[clientId].dates.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  return Object.values(clients);
};

export const getStatus = (status_: AvailsShowStatus) => {
  switch (status_) {
    case 'Work Visa':
      return {
        statusColor: '#E95344',
        statusBg: '#fff',
        statusLabel: 'Work Visa',
        statusText: 'WV',
      };
    case 'Confirmed':
      return {
        statusColor: '#fff',
        statusBg: '#29A1DB',
        statusLabel: 'Confirmed',
        statusText: 'C',
      };
    case 'Pending':
      return {
        statusColor: '#fff',
        statusBg: '#3748AC',
        statusLabel: 'Pending',
        statusText: 'P',
      };
    case '1st Hold':
      return {
        statusColor: '#fff',
        statusBg: '#E87D00',
        statusLabel: '1st Hold',
        statusText: '1H',
      };
    case 'Routing':
      return {
        statusColor: '#fff',
        statusBg: '#90C04C',
        statusLabel: 'Routing',
        statusText: 'R',
      };
    case 'Blocked':
      return {
        statusColor: '#fff',
        statusBg: '#E95344',
        statusLabel: 'Blocked',
        statusText: 'B',
      };
    default:
      return {
        statusColor: '#fff',
        statusBg: '#000',
        statusLabel: 'Unknown',
        statusText: 'U',
      };
  }
};

const toRad = (value: number) => (value * Math.PI) / 180;

// should return a number in miles
const getProximity = (geolocation: { lat: number; lng: number }, location_: Position) => {
  if (!geolocation || !location_) return '';

  const lat1 = geolocation.lat;
  const lon1 = geolocation.lng;
  const lat2 = location_.latitude;
  const lon2 = location_.longitude;

  const R = 6371; // km
  const dLat = toRad(Number(lat2) - lat1);
  const dLon = toRad(Number(lon2) - lon1);
  const lat1Rad = toRad(lat1);
  const lat2Rad = toRad(Number(lat2));

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  // convert km to miles
  return isNaN(parseInt((d * 0.621371).toString())) ? '' : parseInt((d * 0.621371).toString()).toString();
};

const getRadiusClauseViolation = (show: AvailsShowType): ClauseViolationDataType | null => {
  const { ruleViolations }: any = show.show;

  if (ruleViolations?.length) {
    const distance = getProximity(show._geoloc, {
      latitude: ruleViolations[0]?.showDetails?.venueGeoLocation?.coordinates[0]?.toString(),
      longitude: ruleViolations[0]?.showDetails?.venueGeoLocation?.coordinates[1]?.toString(),
    });

    const days = Math.floor(
      Math.abs(
        (show?.show?.date_timestamp - new Date(ruleViolations[0]?.showDetails?.date)?.getTime()) / (1000 * 3600 * 24)
      )
    );

    return {
      distance: parseInt(distance),
      days,
    };
  } else {
    return null;
  }
};

export const transformSearchDataToTimelineAgendaData = async (
  data: CustomShowsHitType[],
  dateRange: DateRange,
  loc_?: Position | null,
  selectedClients: SelectOptionT[] = []
): Promise<TimelineAgendaData> => {
  // GROUP BY CLIENT
  const groupedData = data.reduce(
    (acc, show) => {
      const client = show.client?._id;
      if (!acc[client]) {
        acc[client] = [];
      }
      acc[client].push(show);
      return acc;
    },
    {} as Record<string, CustomShowsHitType[]>
  );

  const getDatesFromGroupedData = (group: CustomShowsHitType[], loc?: Position | null) => {
    const { start, end } = dateRange;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dates: any[] = [];

    group.map(async (show) => {
      const showDate = new Date(show.show.date);

      if (showDate >= startDate && showDate <= endDate) {
        const existingDate = dates.find((dateObj) => new Date(dateObj.date).toDateString() === showDate.toDateString());

        const proximity = getProximity(show?._geoloc, loc ?? ({} as Position));
        let clauseViolations: ClauseViolationDataType | null = null;

        if (show?.show?.ruleViolations?.length) {
          clauseViolations = getRadiusClauseViolation(show);
        }

        const newData = {
          label: show.venue.name,
          address: `${show.venue?.address?.address} ${proximity.length !== 0 ? ' | ' + proximity + ' mi' : ''}`,
          status: show.show?.status,
          clauseViolationData: clauseViolations,
          ...STATUSES_CONFIG?.find((statusConfig: any) => statusConfig.status === show?.show.status),
        };

        if (existingDate) {
          existingDate.data.push(newData);
        } else {
          dates.push({
            date: new Date(show.show.date).toDateString(),
            data: [newData],
          });
        }
      }
    });

    // Fill missing dates within the range with empty objects
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const currentDateStr = currentDate.toDateString();

      if (!dates.some((dateObj) => new Date(dateObj.date).toDateString() === currentDateStr)) {
        dates.push({
          date: currentDateStr,
          data: [],
        });
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    // sort the dates array by date
    dates.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return dates;
  };

  const timelineAgendaData = Object.values(groupedData).map((group) => {
    return {
      id: group[0].client?._id,
      timelineLabel: group[0].client?.name,
      selected: false,
      data: getDatesFromGroupedData(group, loc_),
    };
  });

  // sort the timelineAgendaData array by client name -> timelineLabel

  timelineAgendaData.sort((a, b) => {
    const nameA = a.timelineLabel.toUpperCase();
    const nameB = b.timelineLabel.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });

  const draftShows = await searchClientUnavailableDates(
    process.env.REACT_APP_ALGOLIA_DRAFT_SHOWS_INDEX ?? '',
    timelineAgendaData.map((client) => client.id),
    dateRange
  );

  const groupedClientsWithUnavailableDates = draftShows.reduce(
    (acc, show: any) => {
      const client = show.clientId;
      if (!acc[client]) {
        acc[client] = [];
      }
      acc[client].push(new Date(show.date).toDateString());
      return acc;
    },
    {} as Record<string, any[]>
  );

  const timelineAgendaDataWithUnavailableDates: TimelineAgendaData = timelineAgendaData.map((client) => {
    const clientUnavailableDates = groupedClientsWithUnavailableDates[client.id];
    const data_ = client.data.map((dateObj) => {
      const unavailable = clientUnavailableDates?.includes(dateObj.date) ?? false;
      return {
        ...dateObj,
        unavailable,
      };
    });

    return {
      ...client,
      data: data_,
    };
  });

  if (selectedClients.length) {
    selectedClients.forEach((selectedClient) => {
      if (selectedClient?.value && !timelineAgendaDataWithUnavailableDates.some((d) => d.id === selectedClient.value)) {
        timelineAgendaDataWithUnavailableDates.push({
          id: selectedClient.value.toString(),
          timelineLabel: selectedClient.label?.name,
          selected: false,
          data: getDatesFromGroupedData([]),
        });
      }
    });
  }

  return timelineAgendaDataWithUnavailableDates;
};

export const bindProximityToShowsViewData = (loc: Position | null, results: CustomShowsHitType[]): ShowsAgendaData => {
  const resultsSortedByDates = results.sort(
    (a, b) => new Date(a.show.date).getTime() - new Date(b.show.date).getTime()
  );

  const groupedData = resultsSortedByDates.reduce(
    (acc, show) => {
      const client = show.client?.name;
      if (!acc[client]) {
        acc[client] = [];
      }
      acc[client].push(show);
      return acc;
    },
    {} as Record<string, CustomShowsHitType[]>
  );

  const sortedGroupedData = Object.keys(groupedData)
    .sort()
    .reduce(
      (acc, key) => {
        acc[key] = groupedData[key];
        return acc;
      },
      {} as Record<string, CustomShowsHitType[]>
    );

  const showsAgendaData: ShowsAgendaData = [];

  Object.keys(sortedGroupedData).forEach((key) => {
    const group = sortedGroupedData[key];

    const data: ShowsAgendaData = group.map((show) => {
      return {
        id: show.client?._id,
        timelineLabel: '',
        selected: false,
        data: [
          {
            date: new Date(show.show.date),
            data: [
              {
                ...show,
                proximity: show?._geoloc ? getProximity(show?._geoloc, loc ?? ({} as Position)) : '',
              },
            ],
          },
        ],
      };
    });

    showsAgendaData.push(...data);
  });

  return showsAgendaData;
};
