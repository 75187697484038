import { CheckIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, InputHTMLAttributes } from 'react';
import { FieldValues, RegisterOptions, UseFormGetValues, useFormContext } from 'react-hook-form';

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string | boolean;
  className?: string;
  containerClassName?: string;
  rules?: RegisterOptions;
  index?: number;
  checkColor?: string;
  onHandleChange?: (checked: boolean, id: string, getValues: any) => void;
  getValues?: UseFormGetValues<FieldValues>;
}

export const Checkbox = ({
  id,
  className,
  containerClassName,
  label,
  rules,
  checkColor,
  onHandleChange,
  ...props
}: ICheckboxProps) => {
  const { register, setValue, getValues, watch } = useFormContext();
  const checked = watch(id);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;

    setValue(id, val, { shouldDirty: true });

    if (onHandleChange) onHandleChange(val, id, getValues);
  };

  return (
    <div className={`relative w-full h-10 px-2 ${containerClassName}`}>
      <label className="relative cursor-pointer flex items-center h-full w-fit">
        <input
          {...register(id, rules)}
          className={`appearance-none w-4 h-4 ${
            checkColor === 'white' ? 'bg-black' : 'bg-transparent'
          } rounded-sm self-center cursor-pointer disabled:cursor-default ${
            props.disabled ? 'border border-greyCloudy' : 'border border-greyCod'
          } ${checked ? (props.disabled ? 'bg-greyCloudy' : 'bg-greyCod') : ''} ${className}`}
          {...props}
          id={id}
          type="checkbox"
          aria-describedby={`${id}_error_help`}
          onChange={handleChange}
          data-testid="checkbox-testid"
        />
        {checked && (
          <div className="w-4 h-full absolute left-0 top-0 flex items-center">
            <CheckIcon className="" width={16} color={checkColor} />
          </div>
        )}
        {label !== false && (
          <span className={`ml-3 ${props.disabled ? 'text-greyCloudy' : 'text-greyCod'}`}>{label}</span>
        )}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  className: '',
  containerClassName: '',
  label: false,
  rule: {},
  checkColor: 'white',
};
