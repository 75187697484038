import { Contract, Deal } from 'types';

export { Header } from './header/Header';

export interface SectionProps {
  deal: Deal;
  setDeal: (d: Deal) => void;
  dataCy: string;
  contract?: Contract;
  locked?: boolean;
  label: string[];
}

export interface FakeProps {
  dataCy: string;
  label: string[];
}

export interface FullDealProps extends SectionProps {
  deal: Deal;
  setDeal: (d: Deal) => void;
  contract: Contract;
  setContract: (c: Contract) => void;
}

export interface ContractSectionProps {
  contract: Contract;
  setContract: (c: Contract) => void;
  dataCy: string;
  locked: boolean;
  label: string[];
}

export const STATUS_TYPES = {
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  CONFIRMED: 'Confirmed',
  AGENT_REVIEW: 'Agent Review',
  READY_TO_ISSUE: 'Ready to Issue',
  CONTRACT_ISSUED: 'Contract Issued',
  MARKUP_REVIEW: 'Markup Review',
  BUYER_SIGNED: 'Buyer Signed',
  TO_CLIENT: 'To Client',
  FULLY_EXECUTED: 'Fully Executed',
  CANCELLED: 'Cancelled',
  CANCELLED_WITH_PAYMENT: 'Cancelled with Payment',
  SETTLED: 'Settled',
};

/**
 * maps allowable transitions between the current status to next status
 */
export const STATUS_TRANSITIONS = {
  pending: ['REJECTED', 'CONFIRMED', 'AGENT_REVIEW', 'READY_TO_ISSUE'],
  rejected: [],
  confirmed: ['PENDING', 'AGENT_REVIEW', 'READY_TO_ISSUE'],
  agentReview: ['PENDING', 'CONFIRMED', 'READY_TO_ISSUE'],
  readyToIssue: ['PENDING', 'AGENT_REVIEW', 'CONTRACT_ISSUED'],
  contractIssued: ['BUYER_SIGNED', 'TO_CLIENT'],
  markupReview: ['CONTRACT_ISSUED'],
  buyerSigned: ['TO_CLIENT', 'MARKUP_REVIEW', 'FULLY_EXECUTED'],
  toClient: ['MARKUP_REVIEW', 'FULLY_EXECUTED'],
  fullyExecuted: ['CANCELLED', 'CANCELLED_WITH_PAYMENT', 'SETTLED'],
  cancelled: [],
  cancelledWithPayment: ['SETTLED'],
  settled: [],
};
