import moment from 'moment';
import { getDateFormatByBrowser } from 'utils/helpers';

export const formatDateString = (date: string): string => {
  const mDate = moment.utc(date);
  return mDate.format(getDateFormatByBrowser());
};

export const getFormattedDate = (date: string) => {
  const mDate = moment.utc(date);
  return mDate.format(getDateFormatByBrowser());
};
