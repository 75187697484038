export const dateFilterComparator = (filterDate: Date, cellValue: string) => {
  const cellDate = new Date(cellValue);
  //convert to midnight to match filterDate time
  cellDate.setHours(0, 0, 0, 0);
  return cellDate.getTime() - filterDate.getTime();
};

export const sortKeyComparator = (valueA: any, valueB: any, key: string) => {
  const aValue = valueA ? valueA[key] : '';
  const bValue = valueB ? valueB[key] : '';

  if (aValue == bValue) return 0;
  return aValue > bValue ? 1 : -1;
};
