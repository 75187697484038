import { FC } from 'react';
import { STATUS_TYPES } from '../../features/deal-form';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import cypressTags from 'support/cypressTags';

export type ContractStatusIndicatorProps = {
  contractStatus: string;
};

export const ContractStatusIndicator: FC<ContractStatusIndicatorProps> = ({ contractStatus }) => {
  let color = 'border-white text-white';

  if ([STATUS_TYPES.REJECTED, STATUS_TYPES.CANCELLED, STATUS_TYPES.CANCELLED_WITH_PAYMENT].includes(contractStatus)) {
    color = 'border-merlot text-white';
  }

  if (
    [STATUS_TYPES.FULLY_EXECUTED, STATUS_TYPES.SETTLED, STATUS_TYPES.CANCELLED_WITH_PAYMENT].includes(contractStatus)
  ) {
    color = 'border-sushi text-sushi';
  }

  return (
    <div
      className={twMerge(
        clsx('border whitespace-nowrap py-[6px] px-4 rounded font-medium font-sans uppercase text-xs', color)
      )}
      data-cy={cypressTags.DEAL_FORM.CONTRACT_STATUS_INDIFCATOR}
    >
      {contractStatus.toUpperCase()}
    </div>
  );
};
