import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import clsx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { ClassValue } from 'clsx';

export type BodyCellRootProps = PropsWithChildren<
  {
    unavailable?: boolean;
    className?: ClassValue;
  } & Omit<HTMLAttributes<HTMLDivElement>, 'className'>
>;

const CellRoot: FC<BodyCellRootProps> = ({ children, unavailable, className, ...props }) => (
  <td
    className={twMerge(
      clsx(
        'group/table-td bg-white h-[135px] max-h-full max-w-full md:h-[105px] md:min-w-[218px] print:bg-transparent print:min-w-[180px] print:md:min-w-[180px] border-2 border-solid border-[#f5f5f5] print:border-[#6B6A69]',
        { 'calendar-unavailable-pattern': unavailable },
        className
      )
    )}
    {...props}
  >
    {children}
  </td>
);

export default CellRoot;
