import { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CheckboxProps } from '@united-talent-agency/onyx-components/Forms/Checkbox/Checkbox';

import ShowsAgenda from '.';

import ScrollFAB from 'components/ScrollFAB';
import Table from 'components/Table';

import { ShowTableDataType, ShowsAgendaData } from './types';
import { SHOWS_AGENDA_COLUMNS } from 'containers/availability/constants';
import CellContent, { getRadiusClause, getResponsibleAgentName } from './Body/CellContent';
import { ShowsAgendaHeaderContent } from 'components/Table/Header/Row/ShowsHeaderContent';
import { useFilters } from 'context/avails-filters/context';
import { SortBy, SortableColums } from 'context/avails-filters/hook';
import { TableRowData } from 'components/Table/types';

export interface ShowsAgendaFullProps {
  data: ShowsAgendaData;
  onChange: (data: ShowsAgendaData) => void;
}

const Full: FC<ShowsAgendaFullProps> = ({ data, onChange }) => {
  // Refs
  const tableRef = useRef<HTMLTableElement>(null);

  // Contexts
  const { setSortedBy, sortedBy } = useFilters();

  // States
  const [pageOnTop, setPageOnTop] = useState<boolean>(true);
  const [calendarOnTop, setCalendarOnTop] = useState<boolean>(true);

  useLayoutEffect(() => {
    const handleScroll = (event: Event) => {
      const { scrollTop } = event.target as HTMLTableElement;

      const reachedTop = scrollTop === 0;
      if (calendarOnTop !== reachedTop) {
        setCalendarOnTop(reachedTop);
      }
    };

    tableRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      tableRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [calendarOnTop]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const stickyContainer = document?.querySelector('.sticky-container');
      const scrollTop = stickyContainer?.scrollTop;

      const reachedTop = scrollTop === 0;
      if (pageOnTop !== reachedTop) {
        setPageOnTop(reachedTop);
      }
    };

    const stickyContainer = document?.querySelector('.sticky-container');

    stickyContainer?.addEventListener('scroll', handleScroll);

    return () => {
      stickyContainer?.removeEventListener('scroll', handleScroll);
    };
  }, [pageOnTop]);

  const onClickScrollFAB = useCallback(() => {
    const scrollOptions = {
      behavior: 'smooth',
      left: 0,
      top: 0,
      // eslint-disable-next-line no-undef
    } as ScrollToOptions;

    tableRef.current?.scrollTo(scrollOptions);

    if (tableRef.current?.scrollTop === 0) {
      document?.querySelector('.sticky-container')?.scroll(scrollOptions);
    }
  }, []);

  const allSelected = useMemo(() => data?.every((row) => row?.selected), [data]);

  const handleOnSelectAll = useCallback<Required<CheckboxProps>['onChange']>(
    (e) => {
      onChange(
        data?.map((row) => ({
          ...row,
          selected: e.target.checked,
        }))
      );
    },
    [onChange]
  );

  const getSortingValue = (all: TableRowData<ShowTableDataType>, sortBy: SortBy) => {
    const data_ = all?.data[0] && all?.data[0]?.data ? all?.data[0]?.data[0] : null;
    if (!data_) {
      return '';
    }

    const { show, venue, client, deal, proximity, agents } = data_;

    switch (sortBy.column) {
      case 'Client':
        return client?.name;
      case 'Dates':
        return show?.date?.toString();
      case 'Deal Memo #':
        return deal?.number?.toString();
      case 'Location':
        return venue?.address?.address;
      case 'Notes':
        return show?.notes;
      case 'Proximity (Mi)':
        return proximity?.toString();
      case 'Radius':
        return getRadiusClause(show?.date, show?.rule);
      case 'Responsible Agent':
        return getResponsibleAgentName(agents);
      case 'Status':
        return show?.status === 'Cancelled' ? 'Unknown' : show?.status;
      case 'Venue':
        return venue?.name;
      default:
        return show?.date?.toString();
    }
  };

  const handleSortData = useCallback(
    (col: SortableColums) => {
      const sortBy: SortBy = {
        column: col,
        order: sortedBy?.column === col && sortedBy?.order === 'asc' ? 'desc' : 'asc',
      };

      setSortedBy(sortBy);

      // Sort data
      const sortedData = [...data];

      sortedData.sort((a, b) => {
        const valueA = getSortingValue(a, sortBy) ?? '';
        const valueB = getSortingValue(b, sortBy) ?? '';

        if (sortBy.order === 'asc') {
          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        } else {
          if (valueA > valueB) return -1;
          if (valueA < valueB) return 1;
          return 0;
        }
      });

      // Call the onChange callback to update the sorted data
      onChange(sortedData);
    },
    [setSortedBy, sortedBy]
  );

  return (
    <ShowsAgenda.Root ref={tableRef}>
      <Table.Header.THead>
        <Table.Header.Row.Root className="[&>*:nth-child(n+7)]:print:hidden">
          <ShowsAgenda.Header.Header checked={allSelected} onChange={handleOnSelectAll} />
          <ShowsAgendaHeaderContent handleSort={(col: SortableColums) => handleSortData(col)} />
        </Table.Header.Row.Root>
      </Table.Header.THead>
      <Table.Body.Root className="tbody table-row-group">
        {data?.map((row, index) => (
          <Table.Body.Row.Root
            printable={row?.selected}
            key={row?.id}
            className="[&>*:nth-child(n+7)]:print:hidden [&>*:nth-child(n+6)]:print:!border-r-0 [&>*:nth-child(n+7)]:print:!border-x-0 [&:last-child>*]:print:border-b-0 print:even:bg-[#f5f5f5]"
          >
            <ShowsAgenda.Header.BodyCell row={row} index={index} data={data} setData={onChange} />
            {SHOWS_AGENDA_COLUMNS.map((rw, idx) => (
              <td key={rw} className="border-[#f5f5f5] border-b-2">
                <CellContent data={row} columnIndex={idx} />
              </td>
            ))}
          </Table.Body.Row.Root>
        ))}
      </Table.Body.Root>
      {(!pageOnTop || !calendarOnTop) && (
        <ScrollFAB className="hidden md:block print:!hidden z-10" onClick={onClickScrollFAB} />
      )}
    </ShowsAgenda.Root>
  );
};

export default Full;
