import { Deal, Show } from '../types';
import { RequestResponse, patch, post, showUserError } from './index';

export interface iUpdateShowPayload {
  showId: string;
  updateBody: Partial<Show>;
}

export interface UpdateShowsRequest {
  dealId: string;
  updates: iUpdateShowPayload[];
}

export const addDealShow = async (dealId: string) => {
  return await post(`/touring/deals/${dealId}/addShow`, {});
};

export const deleteDealShow = async (dealId: string, showId: string) => {
  return await post(`/touring/deals/${dealId}/deleteShow/${showId}`, {});
};

export const updateDealShows = async (params: UpdateShowsRequest): Promise<RequestResponse> => {
  const { dealId, updates } = params;
  return await patch(`/touring/deals/${dealId}/shows`, updates);
};

/**
 * Handles the updateDealShows endpoint request.
 * @param {RequestResponse} resp: the endpoint response object.
 * @param {Object} props - component properties that can set the state of a deal.
 * @param [callback]
 */
export const handleUpdateDealShowsResponse = (
  resp: RequestResponse,
  props: {
    deal: Deal;
    setDeal: (d: Deal) => void;
  },
  callback: () => void = () => {}
) => {
  if (resp.status === 200) {
    callback();
    props.setDeal({ ...props.deal, ...(resp.body as Deal) });
  } else {
    showUserError(resp.body.message);
  }
};

/**
 * Handles the addDealShows endpoint request.
 * @param {RequestResponse} resp: the endpoint response object.
 * @param {Object} props - component properties that can set the state of a deal.
 * @param [callback]
 */
export const handleAddDealShowsResponse = (
  resp: RequestResponse,
  props: {
    deal: Deal;
    setDeal: (d: Deal) => void;
  },
  callback: () => void = () => {}
) => {
  if (resp.status === 200) {
    callback();
    props.setDeal({ ...props.deal, shows: [...props.deal.shows, resp.body as Show] });
  } else {
    showUserError(resp.body.message);
  }
};
