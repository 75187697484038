import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

/**
 * Merchandise renderer.
 *
 * @param {ICellRendererParams} props
 */
export const MerchRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const { data: row, column } = props.data;
  let value = props.value;
  const fieldName = column?.getColId();

  if (!value && fieldName) value = row[fieldName];
  const formattedValue = Number(value).toFixed(2) + '%';
  return (
    <div className="center-content" data-row-id={props.data.id}>
      {value != null ? formattedValue : <></>}
    </div>
  );
};
MerchRenderer.displayName = 'MerchRenderer';
