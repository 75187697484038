import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

export interface IdString {
  id: string | number;
  label: string;
}
interface DropdownProps {
  label: string;
  items?: IdString[];
  propsStyles?: string;
  listStyles?: string;
  isAutoExpand?: boolean;
  isCloseOnMouseLeave?: boolean;
  isDown?: boolean;
  onUpdate: (chosen: IdString) => void;
}

export const DropdownBasic = ({
  label,
  items,
  propsStyles,
  listStyles,
  isAutoExpand = false,
  isCloseOnMouseLeave = false,
  isDown = true,
  onUpdate,
}: DropdownProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [chosen, setChosen] = useState<IdString | null>(null);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (chosen) {
      onUpdate(chosen);
    }
  }, [chosen]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const _onClick = () => {
    toggleOpen();
  };

  const handleMouseLeave = () => {
    if (isCloseOnMouseLeave) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={`cursor-pointer w-full border border-solid border-greyTapa px-4 py-2 select-none relative text-sm ${
        isOpen ? 'active' : ''
      }
      after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3 ${propsStyles ? propsStyles : 'bg-white'}`}
      onClick={_onClick}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex flex-row items-center justify-between">
        {chosen ? chosen.label : label}{' '}
        <ChevronDownIcon width={15} className={`transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </div>
      <ul
        ref={ref}
        className={`border border-solid border-greyTapa list-none m-0 p-0 absolute ${
          isDown ? 'top-full' : 'bottom-full'
        } border-t-0 ${
          isAutoExpand ? 'left-auto' : '-left-[1px]'
        } -right-[1px] transition-opacity duration-300 max-h-[300px] bg-inherit shadow-header ${
          isOpen ? 'opacity-1 pointer-events-auto z-10' : 'opacity-0 pointer-events-none'
        } ${listStyles ? listStyles : ''} `}
      >
        {items &&
          items.map((item) => (
            <li
              key={item.id}
              className={`px-4 py-2 hover:bg-greyQuill ${isAutoExpand ? 'whitespace-nowrap' : ''}`}
              onClick={() => setChosen(item)}
            >
              {item.label}
            </li>
          ))}
      </ul>
    </div>
  );
};
