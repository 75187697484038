import { updateDealShows } from 'api';
import { handleUpdateDealShowsResponse, iUpdateShowPayload } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  FIXED_EXPENSES_EMPTY_EXPENSE_ITEM,
  FIXED_EXPENSES_EMPTY_FORM,
  FIXED_EXPENSES_LIMIT,
  FIXED_EXPENSES_TYPES,
} from 'deal-form/data/organisms';
import { DropdownWithStaticSearch } from 'deal-form/form-controls/DropdownWithStaticSearch';
import Totals from 'deal-form/form-controls/Totals';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismFixedExpense, iOrganismFixedExpenses } from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { FixedExpense } from 'types/Show';

import { FieldValues, UseFormGetValues, UseFormReset } from 'react-hook-form';
import { Currency } from '../../form-controls/Currency';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';
import { formatTotals, formatCurrencyWithSymbol } from '../../helpers/dealHelpers';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { getMasterShow } from '../../helpers/showHelpers';
import cypressTags from 'support/cypressTags';

const { EXPENSES_SECTION } = cypressTags.DEAL_FORM;
export const COLUMN_LABELS = [
  { value: 'Type', isRequired: true },
  { value: 'Amount', isRequired: true },
  { value: 'Notes' },
];
export const GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_2fr] gap-2';

const FixedExpensesForm: React.FC<iFormProps> = ({ calculations, deal, setDeal, isOpen, locked, contract }) => {
  const [showId, setShowId] = useState<string>('');
  const [fixedExpensesFormData, setFixedExpensesFormData] = useState<iOrganismFixedExpenses | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [sumPerCurrency, setSumPerCurrency] = useState<{ value: number; currency: string }[]>([]);

  const [customTierOptions, setCustomTierOptions] = useState<iOptions[]>([]);

  useEffect(() => {
    const show = getMasterShow(deal.shows);

    setShowId(show._id);
    const fixedExpenses = show.expenses?.fixed;

    const fixedExpensesTotal = calculations?.deals[deal._id]?.fixedExpensesTotal;

    if (Array.isArray(fixedExpenses)) {
      const expensesData = fixedExpenses.map(({ amount, currency, type, note }) => {
        return {
          amount: {
            value: amount,
            currency: currency || deal.currency,
          },
          type: type,
          notes: note,
        };
      });
      setFixedExpensesFormData({
        expenses: expensesData,
        fixedExpensesTotal: formatTotals(fixedExpensesTotal),
      });
    } else {
      // TODO: Is is still needed?
      setFixedExpensesFormData(FIXED_EXPENSES_EMPTY_FORM);
    }
    // calculate the sum of all fixed expenses for each currency variation
    const fixedExpensesEntries = show.expenses?.fixed || [];
    const fixedExpensesSums = fixedExpensesEntries.reduce(
      (acc, expense) => {
        if (!expense.currency) return acc;
        const currency = expense.currency;
        const amount = Number(expense.amount);
        const total = acc[currency] || 0;
        acc[currency] = total + amount;
        return acc;
      },
      {} as Record<string, number>
    );
    const fixedExpensesSumsFormatted = Object.entries(fixedExpensesSums).map(([currency, amount]) => ({
      value: amount,
      currency,
    }));
    setSumPerCurrency(fixedExpensesSumsFormatted);
  }, [deal, calculations]);

  const onSubmit = async (data: iOrganismFixedExpenses) => {
    const expensesPayload: FixedExpense[] = data.expenses.map((expense: iOrganismFixedExpense) => {
      return {
        amount: Number(expense.amount.value),
        currency: expense.amount.currency,
        type: expense.type,
        note: expense.notes,
      };
    });

    if (expensesPayload) {
      const updateData: iUpdateShowPayload[] = [];
      deal.shows.forEach((s) => {
        if (s._id === showId) {
          const oldExpenses = s.expenses;
          const updatedShow = {
            showId: showId,
            updateBody: {
              expenses: {
                ...oldExpenses,
                fixed: expensesPayload,
              },
            },
          };
          updateData.push(updatedShow);
        }
      });

      try {
        setCanSubmit(false);
        const response = await updateDealShows({
          dealId: deal._id.toString(),
          updates: updateData,
        });

        if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });
        setTimeout(() => setCanSubmit(true), 500);
      } catch (e) {
        console.log('ERROR UPDATING FIXED EXPENSES', e);
        setCanSubmit(true);
      }
    }
  };

  const handleAddCustomOption = (newOption: string) => {
    setCustomTierOptions([...customTierOptions, { id: newOption, label: newOption, data: {} }]);
  };

  const updatedEmptyItem = useMemo(
    () => ({
      ...FIXED_EXPENSES_EMPTY_EXPENSE_ITEM,
      isNew: true,
      amount: {
        value: 0,
        currency: deal.currency || '',
      },
    }),
    [deal]
  );

  /**
   * override the built in append functionality
   *
   * @param index row to be removed
   */
  const handleRemoveOverride = async (
    index: number,
    reset: UseFormReset<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>
  ) => {
    const vals: iOrganismFixedExpenses = getValues?.() as iOrganismFixedExpenses;

    if (vals?.expenses?.length) {
      const isNew = vals.expenses?.[index]?.isNew || false;
      vals.expenses.splice(index, 1);

      if (isNew) {
        reset(vals);
      } else {
        onSubmit(vals);
      }
    } else {
      reset();
    }
  };

  return (
    fixedExpensesFormData && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className={`form-row full-width ${canSubmit ? '' : 'opacity-50'}`}
        defaultValues={fixedExpensesFormData}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.FIXED_EXPENSES}
      >
        <FieldArray
          gridClassName={GRID_LAYOUT}
          groupName="expenses"
          columns={COLUMN_LABELS}
          addButtonLabel="Add More Expenses"
          emptyRow={updatedEmptyItem}
          limit={FIXED_EXPENSES_LIMIT}
          disabled={!isOpen || locked || !canSubmit}
          overrideRemove={handleRemoveOverride}
        >
          <DropdownWithStaticSearch
            id="type"
            staticOptions={FIXED_EXPENSES_TYPES}
            placeholder="Type"
            disabled={!isOpen || locked}
            isRequired
            maxLength={32}
            addCustomOption={handleAddCustomOption}
            customOptions={customTierOptions}
            customOptionLabel="Create Custom Type"
            rules={{
              required: "Please select a valid 'Expense Type'",
            }}
            dataCy={EXPENSES_SECTION.TYPE_DROPDOWN}
          />
          <Currency
            id="amount"
            disabled={!isOpen || locked}
            allCurrencies={true}
            rules={{
              required: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL,
            }}
            overrideCurrency={contract?.status === 'Contract Issued' ? deal.currency : undefined}
            dataCy={EXPENSES_SECTION.AMOUNT_INPUT}
          />
          <TextArea
            placeholder="Type your notes here."
            rules={{
              maxLength: {
                value: 1024,
                message: getMaxCharMessage('your notes', 1024),
              },
            }}
            id="notes"
            rows={1}
            disabled={!isOpen || locked}
            dataCy={EXPENSES_SECTION.NOTE_INPUT}
          />
        </FieldArray>

        <div className={`grid grid-cols-[5fr_2fr_13fr] gap-2 pr-10`}>
          <label className="form-label ">Total</label>
          <div className={`relative w-full flex flex-row `}>
            <div className={``}>
              <div className="w-full text-greyCloudy text-xs -mb-1">Gross</div>
              {sumPerCurrency.map((sum, i) => (
                <div data-cy={EXPENSES_SECTION.GROSS} key={'sum-entry-' + i} className="text-sm">
                  {formatCurrencyWithSymbol(sum.value, sum.currency)}
                </div>
              ))}
            </div>
          </div>
          {sumPerCurrency.length === 1 &&
          (sumPerCurrency[0].currency || deal.currency) &&
          sumPerCurrency[0].currency === deal.currency ? (
            <div></div>
          ) : (
            <Totals
              id="fixedExpensesTotal"
              total="value"
              identifier="Gross (Converted)"
              label={false}
              className={'pl-0'}
              dataCy={EXPENSES_SECTION.TOTAL}
            />
          )}
        </div>
      </Form>
    )
  );
};

export default FixedExpensesForm;
