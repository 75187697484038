import * as React from 'react';
import { useEffect, useState } from 'react';
import { IdName } from 'types';

interface SearchableDropdownListProps {
  options: IdName[];
  defaultOption?: string;
  onUpdate: (value: IdName | null) => void;
  onChange?: (value: string) => void;
  clearOnChosen?: boolean;
  placeholder?: string;
}

export const DropdownWithSearch: React.FunctionComponent<SearchableDropdownListProps> = ({
  options,
  defaultOption,
  onUpdate,
  onChange,
  clearOnChosen,
  placeholder,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [chosenValue, setChosenValue] = useState<IdName | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<IdName[]>(options);

  useEffect(() => {
    onUpdate(chosenValue);
    if (clearOnChosen) {
      setChosenValue(null);
    }
  }, [chosenValue]);

  useEffect(() => {
    if (onChange) onChange(searchTerm);
  }, [searchTerm]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTerm !== '') setChosenValue(null);

    setSearchTerm(event.target.value);

    const fOptions = options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredOptions(fOptions);
  };

  return (
    <div className="relative">
      <input
        className="border border-solid border-greyTapa px-4 py-2 rounded-sm text-sm w-full"
        type="text"
        value={
          searchTerm !== '' ? searchTerm : chosenValue !== null ? chosenValue.name : defaultOption ? defaultOption : ''
        }
        onChange={handleSearch}
        placeholder={placeholder || ''}
        autoComplete="off"
      />
      {filteredOptions.length > 0 && (
        <ul
          className={`border border-solid border-greyTapa list-none m-0 p-0 absolute top-full border-t-0 -left-[1px] -right-[1px] transition-opacity duration-300 overflow-scroll overflow-x-hidden bg-white shadow-header max-h-[300px] ${
            searchTerm !== '' ? 'opacity-1 pointer-events-auto z-10' : 'opacity-0 pointer-events-none'
          }`}
        >
          {filteredOptions.map((option) => (
            <li
              key={option.id}
              className="px-4 py-2 border-t border-solid border-greyCloudy cursor-pointer"
              value={option.name}
              onClick={() => {
                setChosenValue(option);
                setSearchTerm('');
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
