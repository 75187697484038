import { ReactElement } from 'react';

export const Logout = (): ReactElement => {
  // clear the saved desk and access token
  localStorage.removeItem('accessToken');
  localStorage.removeItem('deskId');
  localStorage.removeItem('mrkrui');

  window.location.href = `https://login.microsoftonline.com/4cb9240e-2025-488f-97e7-7eb69335458a/oauth2/v2.0/logout?redirect_uri=${encodeURI(
    window.location.href
  )}`;
  return <div />;
};
