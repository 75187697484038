import Content from '../../../TimelineAgenda/Body/CellContent';
import Full from './Full';
import Root from './Root';

const Cell = {
  Root,
  Full,
  Content,
};

export default Cell;
