import clsx from 'clsx';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type BodyRowRootProps = PropsWithChildren<
  {
    index?: number;
    printable?: boolean;
  } & HTMLAttributes<HTMLDivElement>
>;

const RowRoot: React.FC<BodyRowRootProps> = ({ index, printable, children, className, ...props }) => (
  <tr
    key={index === undefined ? undefined : `row-${index}`}
    className={twMerge(clsx('body-row bg-white', { 'print:hidden': !printable }, className))}
    {...props}
  >
    {children}
  </tr>
);

export default RowRoot;
