import React from 'react';

const ContactRenderer = ({ getValue }: any): React.ReactElement => {
  let value = getValue();

  return (
    <div className="">
      {value &&
        value.map((item: { _id: string; contact: string; contactType: string }, index: number) => (
          <p key={index}>
            {item.contactType && item.contactType + `:`} {item.contact}
          </p>
        ))}
    </div>
  );
};

export default ContactRenderer;
