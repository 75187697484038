import { errors } from '@united-talent-agency/components';

export const Login = ({ isAuthenticating = true }: { isAuthenticating: boolean }) => {
  return (
    <div className={'pt-40 w-full text-center items-center bg-black text-white h-[100vh]'}>
      {isAuthenticating ? (
        <div>
          <p>Validating your credentials...</p>
          <p>Please wait</p>
        </div>
      ) : (
        <div>{errors.Forbidden()}</div>
      )}
    </div>
  );
};
