import Divider from '@united-talent-agency/onyx-components/Divider';
import { Close } from '@united-talent-agency/onyx-components/Icons';
import { FC } from 'react';

type MobileDrawerHeaderProps = {
  onClose?: () => void;
};

const MobileDrawerHeader: FC<MobileDrawerHeaderProps> = ({ onClose }) => (
  <div className="flex justify-center my-4" onClick={onClose}>
    <Divider className="border-2 w-[40%] rounded" />
    <div className="absolute top-2 right-4">
      <Close />
    </div>
  </div>
);

export default MobileDrawerHeader;
