/**
 * Style of a normal cell.
 */
export const staticCellStyle = {
  padding: '8px 20px',
  margin: '0',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  overflow: 'visible',
  wordBreak: 'normal',
};

/**
 * Style of a normal cell.
 */
export const radiusCellStyle = {
  padding: '0 5px',
  margin: '0',
  display: 'block',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'visible',
  whiteSpace: 'nowrap ',
  lineHeight: '40px',
};

/**
 * Style of a cell with notes
 */
export const notesCellStyle = {
  paddingTop: '12px',
  margin: '0',
  display: 'block',
  textAlign: 'center',
  maxWidth: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap ',
};

/**
 * Style of a cell under pinned column.
 */
export const pinnedCellStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'visible',
  textOverflow: 'initial',
};
