import { AgGridReact } from 'ag-grid-react';
import { Position } from 'context/avails-filters/hook';
import { StickyHeaderPage } from 'features/layouts';
import { PageHeader } from 'grid-pages/components';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import { FC, useCallback, useRef, useState } from 'react';
import VenueTable from 'venue/organisms/TableClientside';
import VenueSearch from 'venue/organisms/VenueSearch';

export type RadiusUnit = 'km' | 'mi';

export interface ISearchParams {
  name: string;
  cities: string[];
  venueTypes?: string[];
  minCapacity?: string;
  maxCapacity?: string;
  radius?: number;
  radiusUnit?: RadiusUnit;
  locations?: Array<Position>;
}

const DEFAULT_PARAMS = {
  name: '',
  cities: [],
  states: [],
  countries: [],
  venueTypes: [],
};

export const Venue: FC = () => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();

  // States
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<ISearchParams>(DEFAULT_PARAMS);

  const gridApiRef = useRef<AgGridReact>(null);

  const handleOnExport = () => {
    setIsExporting(true);
  };

  const renderFixedContainer = useCallback(() => <PageHeader onExport={handleOnExport} title="Venue" />, []);

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="main-wrapper">
        <div
          className="grid-height content-wrapper md:pt-10"
          onMouseOver={lockOverscrollNavigation}
          onMouseLeave={unlockOverscrollNavigation}
        >
          <VenueSearch setSearchParams={setSearchParams} />
          <VenueTable
            gridApiRef={gridApiRef}
            searchParams={searchParams}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
          />
        </div>
      </div>
    </StickyHeaderPage>
  );
};
