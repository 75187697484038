import { updateDeal } from 'api';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  PAYMENT_SCHEDULE_EMPTY_EXPECTED_PAYMENT_ITEM,
  PAYMENT_SCHEDULE_EMPTY_FORM,
  PAYMENT_SCHEDULE_EMPTY_RECEIVED_PAYMENT_ITEM,
  PAYMENT_SCHEDULE_NOTES,
  PAYMENT_SCHEDULE_PAYMENT_TO,
  PAYMENT_SCHEDULE_EMPTY_EXPECTED_PAYMENT_ITEM_SPLIT_DEAL,
} from 'deal-form/data/organisms';
import { DropdownWithStaticSearch } from 'deal-form/form-controls/DropdownWithStaticSearch';
import { formatCurrency, formatDate } from 'deal-form/helpers/dealHelpers';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismPaymentSchedule, iOrganismPaymentScheduleItem } from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { useWindowSize, windowSizes } from 'support/windowResize';
import { Deposit } from 'types';
import { getDateFormatByBrowser } from 'utils/helpers';

import { DateInput } from 'deal-form/form-controls/DateInput';
import moment from 'moment';
import { Currency } from '../../form-controls/Currency';
import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Label } from '../../form-controls/Label';
import { TextArea } from '../../form-controls/TextArea';
import { formatTotals } from '../../helpers/dealHelpers';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { useArtistCurrency } from '../../helpers/useArtistCurrency';
import cypressTags from 'support/cypressTags';

const { PAYMENT_SCHEDULE } = cypressTags.DEAL_FORM;
export const COLUMN_LABELS_EXPECTED = [
  { value: 'Show', isRequired: true },
  { value: 'Date Expected', isRequired: true },
  { value: 'Amount', isRequired: true },
  { value: 'Payment To', isRequired: true },
  { value: 'Payment Notes' },
];
export const COLUMN_LABELS_EXPECTED_SPLIT_DEAL = [
  { value: 'Show', isRequired: true },
  { value: 'Date Expected', isRequired: true },
  { value: 'Amount', isRequired: true },
  { value: 'Payment To', isRequired: true },
  { value: 'Contract Split', isRequired: true },
  { value: 'Payment Notes' },
];
export const COLUMN_LABELS_RECEIVED = [
  { value: 'Show', isRequired: true },
  { value: 'Date Received' },
  { value: 'Amount', isRequired: true },
  { value: 'Payment To', isRequired: true },
  { value: 'Payment Notes' },
];
export const COLUMN_LABELS_EXPECTED_TABLET = [
  { value: 'Date Expected', isRequired: true },
  { value: 'Amount', isRequired: true },
  { value: 'Payment To', isRequired: true },
];
export const COLUMN_LABELS_RECEIVED_TABLET = [
  { value: 'Date Received' },
  { value: 'Amount', isRequired: true },
  { value: 'Payment To', isRequired: true },
];
export const GRID_LAYOUT = 'w-full grid grid-cols-[1fr_1fr_2fr_1fr] lg:grid-cols-[1fr_1fr_2fr_1fr_2fr] gap-2';
export const GRID_LAYOUT_SPLIT_DEAL =
  'w-full grid grid-cols-[1fr_1fr_2fr_2fr_1fr] lg:grid-cols-[1fr_1fr_2fr_1fr_1fr_2fr] gap-2';
export let contractSplitTypes = [
  {
    id: 'Client',
    label: 'Client (Artist)',
  },
  {
    id: 'Production',
    label: 'Production',
  },
  {
    id: 'Management',
    label: 'Management',
  },
];
const PaymentScheduleForm: React.FC<iFormProps> = ({ calculations, deal, setDeal, isOpen, locked, isSplitDeal }) => {
  const [paymentsData, setPaymentsData] = useState<iOrganismPaymentSchedule>(PAYMENT_SCHEDULE_EMPTY_FORM);
  const artistCurrency = useArtistCurrency(deal);
  const [totals, setTotals] = useState({
    expectedTotal: { value: 0, currency: artistCurrency?.id || 'USD' },
    receivedTotal: { value: 0, currency: artistCurrency?.id || 'USD' },
  });
  const [canSubmit, setCanSubmit] = useState(true);
  const [customTierOptions, setCustomTierOptions] = useState<iOptions[]>([]);
  const [showDates, setShowDates] = useState<iOptions[]>([]);

  const { width } = useWindowSize();

  useEffect(() => {
    const { deposits, paymentScheduleNotes } = deal;
    const dealCalculations = calculations?.deals[deal._id];
    const expectedTotalPayments = formatTotals(dealCalculations?.expectedTotalPayments);
    const receivedTotalPayments = formatTotals(dealCalculations?.receivedTotalPayments);

    let expectedPayments: iOrganismPaymentScheduleItem[] = [];
    let receivedPayments: iOrganismPaymentScheduleItem[] = [];

    const masterShow = deal.shows.find((show) => show.masterShowFlag);

    if (deposits && deposits.length > 0) {
      const customTiers: iOptions[] = [];
      const paymentSchedule: iOrganismPaymentScheduleItem[] = deposits.map((deposit) => {
        const foundNote = PAYMENT_SCHEDULE_NOTES.find(({ id }) => id === deposit.description);
        const foundTier = customTiers.find(({ id }) => id === deposit.description);
        const transformedPayee = {
          Agency: 'UTA',
          Agent: 'UTA',
          UTA: 'UTA',
          Client: 'Artist',
          Artist: 'Artist',
          '3rd Party': '3rd Party',
        };
        // check to see if the tier is custom, if it is then it needs to be added to the list of available tiers
        if (foundNote === undefined && deposit.description.trim() !== '' && foundTier === undefined) {
          customTiers.push({ id: deposit.description, label: deposit.description });
        }

        let tempExpectedObject: iOrganismPaymentScheduleItem = {
          showId: deposit.showId ? deposit.showId : `${masterShow?._id || ''}`,
          type: deposit.type || 'Balance',
          dateExpected: deposit.status === 'Due' ? moment(deposit.due).utc().toDate() : undefined,
          amount: {
            value: deposit.amount || 0,
            currency: artistCurrency?.id || 'USD',
          },
          paymentTo: transformedPayee[deposit.payee] || '',
          dateReceived: deposit.status === 'Paid' ? moment(deposit.dateReceived).utc().toDate() : undefined,
          notes: deposit.description || '',
        };

        if (isSplitDeal) {
          tempExpectedObject = { ...tempExpectedObject, splitType: deposit.contractSplitType };
        }

        return tempExpectedObject;
      });

      setCustomTierOptions(customTiers);

      paymentSchedule.forEach((deposit) => {
        if (deposit.type === 'Balance') {
          expectedPayments.push(deposit);
        } else if (deposit.type === 'Deposit') {
          receivedPayments.push(deposit);
        }
      });

      setTotals({
        expectedTotal: expectedTotalPayments,
        receivedTotal: receivedTotalPayments,
      });
    } else {
      if (artistCurrency) {
        setTotals({
          expectedTotal: { ...expectedTotalPayments, currency: artistCurrency.id },
          receivedTotal: { ...receivedTotalPayments, currency: artistCurrency.id },
        });
      }
    }

    setPaymentsData({
      paymentsReceived: receivedPayments,
      paymentsExpected: expectedPayments,
      paymentScheduleNotes: paymentScheduleNotes || '',
    });

    const dateShows = deal.shows
      .sort(function (a, b) {
        if (new Date(a.date) === new Date(b.date)) {
          a.earlyLate === 'Early' ? 1 : -1;
        }

        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .map((show) => {
        return {
          id: show._id,
          label: `${formatDate(show.date, false)} - ${show.earlyLate === 'Neither' ? 'Only Show' : show.earlyLate}`,
          disabled: show.status === 'Settled',
        };
      });
    // setApplyToAll(applyAll);
    setShowDates(dateShows);
  }, [deal, calculations, isSplitDeal]);

  const emptyItems = useMemo(() => {
    const defaultAmount = {
      value: 0,
      currency: artistCurrency?.id || 'USD',
    };
    let newEmptyRow = isSplitDeal
      ? PAYMENT_SCHEDULE_EMPTY_EXPECTED_PAYMENT_ITEM_SPLIT_DEAL
      : PAYMENT_SCHEDULE_EMPTY_EXPECTED_PAYMENT_ITEM;

    return {
      paymentsExpected: [
        {
          ...newEmptyRow,
          amount: defaultAmount,
        },
      ],
      paymentsReceived: [
        {
          ...PAYMENT_SCHEDULE_EMPTY_RECEIVED_PAYMENT_ITEM,
          amount: defaultAmount,
        },
      ],
    };
  }, [artistCurrency]);

  const handleAddCustomOption = (newOption: string) => {
    setCustomTierOptions([...customTierOptions, { id: newOption, label: newOption, data: {} }]);
  };

  const onSubmit = async (data: iOrganismPaymentSchedule) => {
    const existingDeposits = deal.deposits || [];
    const depositsPayload: Deposit[] = existingDeposits.filter((deposit) => deposit.type == 'Deposit');
    data.paymentsExpected.forEach((payment) => {
      if (payment.amount.value && payment.dateExpected) {
        const paymentDeposit: Deposit = {
          showId: payment.showId,
          amount: payment.amount.value,
          due: moment(payment.dateExpected).startOf('day').utc().toDate(),
          payee: (payment.paymentTo as 'Agent') || 'Client',
          type: 'Balance',
          description: payment.notes || '',
          currency: payment.amount.currency,
          contractSplitType: payment.splitType?.toString(),
        };

        depositsPayload.push(paymentDeposit);
      }
    });

    try {
      setCanSubmit(false);

      const updatedDeal = await updateDeal(deal._id, {
        ...deal,
        deposits: depositsPayload,
        paymentScheduleNotes: data.paymentScheduleNotes,
      });

      if (setDeal) {
        setDeal(updatedDeal);
      }
      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR UPDATING PAYMENT SCHEDULE', e);
      setCanSubmit(true);
    }
  };
  useEffect(() => {
    let filteredDeals: any = [];
    if (deal.dealSplits && deal.dealSplits.length > 0) {
      filteredDeals = contractSplitTypes.filter((option: any) => {
        return deal?.dealSplits?.some((split: any) => {
          return option.id.toLocaleLowerCase() === split.contractSplitType.toLowerCase();
        });
      });
    }
    contractSplitTypes = filteredDeals;
  });

  return (
    paymentsData && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className="form-row full-width"
        defaultValues={paymentsData}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.PAYMENT_SCHEDULE}
      >
        {isSplitDeal ? (
          <FieldArray
            gridClassName={GRID_LAYOUT_SPLIT_DEAL}
            groupName="paymentsExpected"
            columns={width < windowSizes.LG ? COLUMN_LABELS_EXPECTED_TABLET : COLUMN_LABELS_EXPECTED_SPLIT_DEAL}
            addButtonLabel="Add Expected Payment"
            emptyRow={emptyItems.paymentsExpected[0]}
            disabled={!isOpen || locked}
            lockRules={(field) => {
              const rule = {
                isLocked: false,
                fields: [] as string[],
              };

              const fieldShowId = field.showId;

              const matchingShow = deal.shows.find((show) => show._id === fieldShowId);

              // if a show status is Settled it should not be updated any more
              if (matchingShow && matchingShow.status === 'Settled') {
                rule.isLocked = true;
                rule.fields = ['showId', 'dateExpected', 'amount', 'paymentTo', 'notes'];
              }

              return rule;
            }}
          >
            <Dropdown
              placeholder="Show"
              id="showId"
              options={showDates}
              disabled={!isOpen || locked}
              rulesCallback={() => ({
                required: ERROR_MESSAGES.REQUIRED_FIELDS,
              })}
              handleChange={(_chosen, _setValue, _index, trigger) => {
                if (trigger) {
                  trigger();
                }
              }}
              hideClearButton
              dataCy={PAYMENT_SCHEDULE.SHOW}
            />
            <DateInput
              placeholder="Date Expected"
              id="dateExpected"
              disabled={!isOpen || locked}
              rules={{
                required: ERROR_MESSAGES.VALID_DATE,
              }}
              dataCy={PAYMENT_SCHEDULE.DATE_EXPECTED}
            />
            <Currency
              id="amount"
              containerClassName="min-w-[200px]"
              dataCy={PAYMENT_SCHEDULE.AMOUNT}
              disabled={!isOpen || locked}
              dealCurrency={artistCurrency}
              overrideCurrency={artistCurrency?.id || 'USD'}
              rules={{
                required: ERROR_MESSAGES.REQUIRED_FIELDS,
                min: { value: 0, message: ERROR_MESSAGES.REQUIRED_FIELDS },
              }}
            />

            <Dropdown
              placeholder="Payment To"
              id="paymentTo"
              options={PAYMENT_SCHEDULE_PAYMENT_TO}
              disabled={!isOpen || locked}
              rules={{
                required: "Please select a valid 'Payment to' option",
              }}
              dataCy={PAYMENT_SCHEDULE.PAYMENT_TO}
            />
            <Dropdown
              placeholder="Contract Split Type"
              id="splitType"
              containerClassName="!flex-col !items-start"
              options={contractSplitTypes}
              rules={{ required: ERROR_MESSAGES.CONTRACT_SPLIT_TYPE }}
              dataCy={PAYMENT_SCHEDULE.CONTRACT_SPLIT_TYPE}
            />

            <DropdownWithStaticSearch
              id="notes"
              staticOptions={PAYMENT_SCHEDULE_NOTES}
              placeholder="Your notes here."
              disabled={!isOpen || locked}
              maxLength={180}
              addCustomOption={handleAddCustomOption}
              customOptions={customTierOptions}
              customOptionLabel="Create Custom"
              dataCy={PAYMENT_SCHEDULE.NOTES}
            />
          </FieldArray>
        ) : (
          <FieldArray
            gridClassName={GRID_LAYOUT}
            groupName="paymentsExpected"
            columns={width < windowSizes.LG ? COLUMN_LABELS_EXPECTED_TABLET : COLUMN_LABELS_EXPECTED}
            addButtonLabel="Add Expected Payment"
            emptyRow={emptyItems.paymentsExpected[0]}
            disabled={!isOpen || locked}
            lockRules={(field) => {
              const rule = {
                isLocked: false,
                fields: [] as string[],
              };

              const fieldShowId = field.showId;

              const matchingShow = deal.shows.find((show) => show._id === fieldShowId);

              // if a show status is Settled it should not be updated any more
              if (matchingShow && matchingShow.status === 'Settled') {
                rule.isLocked = true;
                rule.fields = ['showId', 'dateExpected', 'amount', 'paymentTo', 'notes'];
              }

              return rule;
            }}
          >
            <Dropdown
              placeholder="Show"
              id="showId"
              options={showDates}
              disabled={!isOpen || locked}
              rulesCallback={() => ({
                required: ERROR_MESSAGES.REQUIRED_FIELDS,
              })}
              handleChange={(_chosen, _setValue, _index, trigger) => {
                if (trigger) {
                  trigger();
                }
              }}
              hideClearButton
              dataCy={PAYMENT_SCHEDULE.SHOW}
            />
            <DateInput
              placeholder="Date Expected"
              id="dateExpected"
              disabled={!isOpen || locked}
              rules={{
                required: ERROR_MESSAGES.VALID_DATE,
              }}
              dataCy={PAYMENT_SCHEDULE.DATE_EXPECTED}
            />
            <Currency
              containerClassName="min-w-[200px]"
              id="amount"
              dealCurrency={artistCurrency}
              disabled={!isOpen || locked}
              rules={{
                required: ERROR_MESSAGES.REQUIRED_FIELDS,
                min: { value: 0, message: ERROR_MESSAGES.REQUIRED_FIELDS },
              }}
              overrideCurrency={artistCurrency?.id || 'USD'}
              dataCy={PAYMENT_SCHEDULE.AMOUNT}
            />

            <Dropdown
              placeholder="Payment To"
              id="paymentTo"
              options={PAYMENT_SCHEDULE_PAYMENT_TO}
              disabled={!isOpen || locked}
              rules={{
                required: "Please select a valid 'Payment to' option",
              }}
              dataCy={PAYMENT_SCHEDULE.PAYMENT_TO}
            />

            <DropdownWithStaticSearch
              id="notes"
              staticOptions={PAYMENT_SCHEDULE_NOTES}
              placeholder="Your notes here."
              disabled={!isOpen || locked}
              maxLength={180}
              addCustomOption={handleAddCustomOption}
              customOptions={customTierOptions}
              customOptionLabel="Create Custom"
              dataCy={PAYMENT_SCHEDULE.NOTES}
            />
          </FieldArray>
        )}

        <div className={GRID_LAYOUT}>
          <label className="form-label pr-2">Amount Expected</label>
          <div className="text-sm text-[#FF0303] self-center">
            {/* {artistCurrency?.symbol + formatCurrency(totals.expectedTotal.value) + ' ' + artistCurrency?.id} */}
            {formatCurrency(totals.expectedTotal.value) + ' ' + artistCurrency?.id}
          </div>
        </div>

        <hr />

        <div className={GRID_LAYOUT}>
          <label className="form-label pr-2">Show</label>
          <label className="form-label pr-2">Date Received</label>
          <label className="form-label pr-2">Amount</label>
          <label className="form-label pr-2">Payment To</label>
          <label className="form-label pr-2">Notes</label>

          {paymentsData.paymentsReceived.length > 0 ? (
            paymentsData.paymentsReceived.map((psi) => {
              return <PaymentRowContainer psi={psi} artistCurrency={artistCurrency} showDates={showDates} />;
            })
          ) : (
            <p key={Math.random()}>No Deposits Received.</p>
          )}
        </div>

        {/* 
        <FieldArray
          gridClassName={GRID_LAYOUT}
          groupName="paymentsReceived"
          columns={width < windowSizes.LG ? COLUMN_LABELS_RECEIVED_TABLET : COLUMN_LABELS_RECEIVED}
          addButtonLabel="Add Received Payment"
          emptyRow={emptyItems.paymentsReceived[0]}
          disabled={!isOpen || locked}
        >
          <Dropdown
            placeholder="Show"
            id="showId"
            options={showDates}
            disabled
            rulesCallback={() => ({
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            })}
            handleChange={(_chosen, _setValue, _index, trigger) => {
              if (trigger) {
                trigger();
              }
            }}
            hideClearButton
          />
          <DateInput
            placeholder="Date Received"
            id="dateReceived"
            disabled
            rules={{
              required: ERROR_MESSAGES.VALID_DATE,
            }}
          />
          <Currency
            containerClassName="min-w-[200px]"
            id="amount"
            dealCurrency={artistCurrency}
            disabled
            rules={{
              required: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL,
              min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
            }}
            overrideCurrency={artistCurrency?.id || 'USD'}
          />
          <Dropdown
            placeholder="Payment To"
            id="paymentTo"
            options={PAYMENT_SCHEDULE_PAYMENT_TO}
            disabled
            rules={{
              required: "Please select a valid 'Payment for' option",
            }}
          />
          <TextArea
            placeholder="Type your notes here."
            rules={{
              maxLength: {
                value: 1024,
                message: getMaxCharMessage('your notes', 1024),
              },
            }}
            id="notes"
            rows={1}
            containerClassName="col-span-full lg:col-span-1"
            disabled
          />
        </FieldArray> */}

        <div className={GRID_LAYOUT}>
          <label className="form-label pr-2">Amount Received</label>
          <div className="text-sm px-2 text-[#159500] self-center">
            {/* {artistCurrency?.symbol + formatCurrency(totals.receivedTotal.value) + ' ' + artistCurrency?.id} */}
            {formatCurrency(totals.receivedTotal.value) + ' ' + artistCurrency?.id}
          </div>
        </div>

        <div className="grid grid-cols-[100px_1fr_120px_1fr_120px_1fr] gap-5 pr-10 border-t border-greyCloudy pt-5 mt-5">
          <Label className=" self-start col-span-1 row-start-2" htmlFor="paymentScheduleNotes">
            Payment Schedule Notes
          </Label>
          <TextArea
            containerClassName="col-span-5 row-start-2"
            disabled={!isOpen || locked}
            id="paymentScheduleNotes"
            label={false}
            placeholder="Type notes here."
            rows={4}
            rules={{
              maxLength: {
                value: 2000,
                message: getMaxCharMessage('your notes', 2000),
              },
            }}
          />
        </div>
      </Form>
    )
  );
};

export default PaymentScheduleForm;

let PaymentRowContainer = ({ showDates, psi, artistCurrency }: any) => {
  return (
    <>
      <div className="flex flex-row justify-between">
        <p>{showDates && showDates.find((showDate: any) => showDate.id === psi.showId)?.label}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p>{moment.utc(psi.dateReceived).format(getDateFormatByBrowser())}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p>
          {artistCurrency?.symbol} {formatCurrency(psi.amount.value)} {artistCurrency?.id}
        </p>
      </div>
      <div className="flex flex-row justify-between">
        <p>{psi.paymentTo}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p>{psi.notes}</p>
      </div>
    </>
  );
};
