import { CheckIcon } from '@heroicons/react/24/outline';
import { ReactNode, useEffect, useState } from 'react';

interface iProps {
  labels: {
    value: string;
    isRequired?: boolean;
    checkboxId?: string;
    checked?: boolean;
  }[];
  containerClassName: string;
  columnClassName?: string;
  hasChildFields?: boolean;
  updateChecked?: (id: string, checked: boolean) => void;
}

export const ColumnLabels = ({
  labels,
  containerClassName,
  columnClassName,
  hasChildFields,
  updateChecked,
}: iProps) => (
  <div className={`my-[2px] ${containerClassName}`} data-testid="column-labels-container">
    {labels.map((label) => (
      <ColumnLabel
        key={label.value}
        label={label.value}
        className={columnClassName}
        checkboxId={label.checkboxId}
        checked={label.checked}
        // Only display required asterisk if there're fields displaying
        isRequired={label.isRequired && hasChildFields}
        handleChange={(checked) => {
          updateChecked && label.checkboxId && updateChecked(label.checkboxId, checked);
        }}
      />
    ))}
  </div>
);

ColumnLabels.defaultProps = {
  columnClassName: '',
  hasChildFields: false,
};

const ColumnLabel = ({
  label,
  className,
  isRequired,
  checkboxId,
  checked,
  handleChange,
}: {
  label: string | ReactNode;
  className?: string;
  isRequired?: boolean;
  checkboxId?: string;
  checked?: boolean;
  handleChange?: (checked: boolean) => void;
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (checked !== undefined) setIsChecked(checked);
  }, [checked]);

  return (
    <div className={`font-medium text-sm text-greyCod ${className}`}>
      <div className="flex self-start" data-testid="column-label-testid">
        {checkboxId ? (
          <label className="relative cursor-pointer flex items-center h-full w-fit">
            <input
              className={`appearance-none w-4 h-4 bg-transparent rounded-sm border border-greyCod self-center cursor-pointer disabled:cursor-default ${
                isChecked ? 'bg-greyCod' : ''
              } ${className}`}
              type="checkbox"
              onChange={() => {
                handleChange && handleChange(!isChecked);
                setIsChecked(!isChecked);
              }}
              checked={isChecked}
              data-testid="column-checkbox"
            />
            {isChecked && (
              <div className="w-4 h-full absolute left-0 top-0 flex items-center ">
                <CheckIcon className="" width={16} color="black" />
              </div>
            )}
            <span className="ml-3">{label}</span>
          </label>
        ) : (
          <span>{label}</span>
        )}
        {isRequired ? <span className="text-cinnabar">*</span> : null}
      </div>
    </div>
  );
};
