import { Column, GridApi, IServerSideSelectionState } from 'ag-grid-community';

import {
  CellSerializer,
  serializeCity,
  serializeCountry,
  serializeCurrency,
  serializeDate,
  serializeDaysAfter,
  serializeDaysPrior,
  serializeDealType,
  serializeField,
  serializeName,
  serializePhone,
  serializeRadiusClause,
  serializeState,
  serializeVenue,
} from 'containers/routing/RoutingTableSerializer';
import { formatCurrencyWithSymbol, formatDate } from 'deal-form/helpers/dealHelpers';
import { iAgent, iCurrencyNumber } from 'grid-pages/interfaces';
import { Dictionary } from 'types/dictionary';

import { formatDateString } from './format';

export const getSelectedDataFromRows = (selectedRows: IServerSideSelectionState, currentGridData: any[]) => {
  let selectedData = [];
  if (selectedRows?.selectAll || (!selectedRows?.selectAll && selectedRows.toggledNodes.length === 0)) {
    selectedData = currentGridData.filter((row) => !selectedRows?.toggledNodes?.includes(row.id));
  } else {
    selectedData = currentGridData.filter((row) => selectedRows?.toggledNodes?.includes(row.id));
  }

  return selectedData;
};

const toSnakeCase = (str: string) => str.replace(/(\W)|([A-Z])/g, '_$2').toLowerCase();

export const doCsvExportFromData = (data: string[][], pageName: string = '') => {
  //escaping values to avoid issues with values containing commas, i.e. "Majestic Inc, LLC"
  //eslint-disable-next-line
  const escapedData = data.map((r) => r.map((v) => `\"${v}\"`));
  let csvContent = '';
  escapedData.forEach((row: string[]) => {
    csvContent += row.join(',') + '\n';
  });
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', objUrl);

  const fileName = `${toSnakeCase(pageName)}_${formatDate()}`;
  link.setAttribute('download', fileName);

  document?.querySelector('body')?.append(link);
  link.click();
  document.body.removeChild(link);
};

export const doCsvExportFromGrid = (
  gridApi: GridApi,
  pageName: string = '',
  columns: string[],
  onlySelected: boolean
) => {
  gridApi.exportDataAsCsv({
    onlySelectedAllPages: onlySelected,
    columnKeys: columns,
    shouldRowBeSkipped: (params) => !params.node.displayed,
    processCellCallback: (params) => {
      if (!params.value) return params.value;
      const colDef = params.column.getColDef();
      const exportedValue = formatValueFromColType(params.value, colDef.type);
      if (colDef.type && colDef.type.includes('currencyColumn')) {
        return `${exportedValue}, ${params.value.currency}`;
      } else {
        return exportedValue;
      }
    },
    fileName: `${toSnakeCase(pageName)}_${formatDate()}`,
  });
};

export const formatCurrencyValue = (value: iCurrencyNumber): string => {
  return value && (value.amount || value.amount === 0) ? formatCurrencyWithSymbol(value.amount, value.currency) : '--';
};

export const formatAddress = (address: any): string => {
  return address ? `${address.address}\r\n${address.city}, ${address.state}\r\n${address.country}` : '';
};

const formatAgentsColumn = (agents: iAgent[]) => agents.map((agent: any) => agent.name).join(', ') || '--';

const formatDateRangeColumn = (dates: string[]) => dates.map((date) => formatDateString(date)).join(' - ') || '--';

const formatBooleanColumn = (value: boolean) => (value === true ? 'yes' : 'no');

export const formatValueFromColType = (value: any, colType: string | string[] | undefined): string => {
  let exportedValue = value;
  if (colType) {
    const isCurrency = colType.includes('currencyColumn');
    const isDate = colType.includes('dateColumn');
    const isAgents = colType.includes('agentsColumn');
    const isDateRange = colType.includes('dateRangeColumn');
    const isBoolean = colType.includes('booleanColumn');

    if (isCurrency && value) {
      exportedValue = Number(value.amount).toFixed(2);
    } else if (isDate && value) {
      exportedValue = formatDateString(value);
    } else if (isAgents && value) {
      exportedValue = formatAgentsColumn(value);
    } else if (isDateRange && value) {
      exportedValue = formatDateRangeColumn(value);
    } else if (isBoolean) {
      exportedValue = formatBooleanColumn(value);
    }
  }
  return exportedValue;
};

export const formatDataForExport = (data: any, columns: any[], rowsToskip: string[]): string[][] => {
  const colHeaders = columns.map((c: Column) => {
    const colType = c.getColDef().type;
    if (colType && colType.includes('currencyColumn')) {
      return [c.getColDef().headerName || '', 'Currency'];
    } else {
      return c.getColDef().headerName || '';
    }
  });
  const formattedData: string[][] = [colHeaders.flat()];

  data.forEach((row: any) => {
    const rowData: string[] = [];
    if (!rowsToskip.includes(row._id)) {
      columns.forEach((col: Column) => {
        const def: any = col.getUserProvidedColDef();
        const pathComponents = def['path'].split('.');
        const colType = col.getColDef().type;
        const match = row[pathComponents[0]] !== undefined ? row[pathComponents[0]] : null;

        let value;
        if (match !== null) {
          if (pathComponents.length === 1) {
            value = match;
          } else {
            for (let i = 1; i < pathComponents.length; i++) {
              value = match[pathComponents[i]];
            }
          }
        }

        const formattedVal = value !== undefined ? formatValueFromColType(value, colType) : '--';
        rowData.push(formattedVal);
        if (colType && colType.includes('currencyColumn')) {
          rowData.push(value?.currency || '--');
        }
      });
    }
    formattedData.push(rowData);
  });

  return formattedData;
};

export const formatRoutingDataForExport = (data: any, columns: any[]): string[][] => {
  const colHeaders = columns.map((c: Column) => {
    const colType = c.getColDef().type;
    if (colType && colType.includes('currencyColumn')) {
      return [c.getColDef().headerName || '', 'Currency'];
    } else {
      return c.getColDef().headerName || '';
    }
  });
  const formattedData: string[][] = [colHeaders.flat()];

  data.forEach((row: any) => {
    const rowData: string[] = [];

    columns.forEach((col: Column) => {
      const def: any = col.getUserProvidedColDef();
      const colValue = row[def.field];
      const colType = def.type;

      //Reusing exisiting serializers
      const serializers: Dictionary<CellSerializer> = {
        date: serializeDate,
        venue: serializeVenue,
        city: serializeCity,
        state: serializeState,
        country: serializeCountry,
        'rule.radius': serializeRadiusClause,
        'rule.timeBefore': serializeDaysPrior,
        'rule.timeAfter': serializeDaysAfter,
        hold: serializeField,
        holdNumber: serializeField,
        guarantee: serializeCurrency,
        walkoutPotential: serializeCurrency,
        notes: serializeField,
        sellableCapacity: serializeField,
        hardMerch: serializeField,
        softMerch: serializeField,
        tix: serializeField,
        ages: serializeField,
        status: serializeField,
        dealType: serializeDealType,
        buyerCompany: serializeName,
        buyerPerson: serializeName,
        phone: serializePhone,
      };
      const serializer: CellSerializer = serializers[col.getColId() ?? col.getColDef().field];
      const serialized = serializer ? serializer(colValue, row) : JSON.stringify(colValue);
      rowData.push(serialized);
      if (colType && colType.includes('currencyColumn')) {
        rowData.push(row.dealCurrency || '');
      }
    });
    formattedData.push(rowData);
  });

  return formattedData;
};
