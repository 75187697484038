import { Contract } from 'types';
import { iCalculations } from 'types/deal';

import { get, patch } from './index';

/**
 * Method used to update contract
 * @param id - ObjectId of deal to update
 * @param contractUpdateRequest - data to update contract with
 */
export const updateContract = async (id: string, contractUpdateRequest: Contract) => {
  const response = await patch(`/touring/contracts/${id}`, contractUpdateRequest);
  return response.body;
};

/**
 * Method used to get contract by id
 * @param id - objectId of contract collection
 * @returns Promise<Contract> - Contract object
 */
export const getContractById = async (id: string): Promise<Contract> => {
  const response = await get(`/touring/contracts/${id}`);
  return response.body;
};
/**
 * Method used to get contract by deal id
 * @param id - objectId of deal
 * @returns Promise<Contract> - Contract object
 */
export const getContractByDealId = async (id: string): Promise<Contract> => {
  const response = await get(`/touring/contracts/all/${id}`);
  return response.body;
};
/**
 * Method used to get calculations by deal id
 * @param id - objectId of deal
 * @returns Promise<Contract> - Contract object
 */
export const getCalculationsByDealId = async (id: string): Promise<iCalculations> => {
  const response = await get(`/touring/contracts/all/${id}/calculations`);
  return response.body;
};
