import { ICellRendererParams } from 'ag-grid-community';
import { ReactElement } from 'react';

import { TableRow } from '../../../../types';

/**
 * Sellable Capacity renderer.
 *
 * @param {ICellRendererParams} props
 */
export const SellableCapacityRenderer = (props: ICellRendererParams): ReactElement => {
  const row: TableRow = props.data;

  let value = props.value ? props.value : row?.sellableCapacity;
  if (typeof value !== 'number') {
    value = Number(value);
  }

  value = Math.floor(value).toLocaleString();
  return (
    <div className="center-content" data-row-id={row.id}>
      {value !== 'NaN' ? ` ${value}` : ''}{' '}
    </div>
  );
};
SellableCapacityRenderer.displayName = 'SellableCapacityRenderer';
