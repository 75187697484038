import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { updateContract, updateDealShows } from 'api';
import { removeLinkedDeal, updateDeal } from 'api/deals';
import { FORM_NAMES } from 'deal-form/data/constants';
import { TextArea } from 'deal-form/form-controls/TextArea';
import { TextField } from 'deal-form/form-controls/TextField';
import { formatCurrencyWithSymbol } from 'deal-form/helpers/dealHelpers';
import { createTermsPayload, getTerms } from 'deal-form/helpers/dealTermsFormatting';
import { iFormProps } from 'deal-form/interfaces/general';
import { iOrganismDealTerms, iOrganismLinkedDeal, iOrganismLinkedDeals } from 'deal-form/interfaces/organisms';
import { ColumnLabels } from 'deal-form/ui/ColumnLabels';
// import { RemoveButton } from 'deal-form/ui/RemoveButton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Deal, Performer } from 'types';

import { getDateFormatByBrowser } from 'utils/helpers';
import { Form } from '../../form-controls/Form';
import { getMasterShow } from '../../helpers/showHelpers';
import { RemoveDealModal } from 'features/deal-form/linked-deals/RemoveDealsModal';
import cypressTags from 'support/cypressTags';
const { LINKED_DEALS_SECTION } = cypressTags.DEAL_FORM;

const COLUMN_LABELS = [
  { value: '' },
  { value: 'Date' },
  { value: 'Venue' },
  { value: 'Deal Type' },
  { value: 'Guarantee' },
];
const GRID_LAYOUT = 'grid grid-cols-[40px_1fr_1fr_1fr_1fr_40px] items-center';

const LinkedDealsForm: React.FC<iFormProps> = ({ deal, contract, setContract }) => {
  const [linkedDealsData, setLinkedDealsData] = useState<iOrganismLinkedDeal[]>([]);
  const [contractData, setContractData] = useState<iOrganismLinkedDeals | null>(null);
  const [crossed, setCrossed] = useState<boolean>(false);
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [showRemoveDealModal, setShowRemoveDealModal] = useState(false);

  useEffect(() => {
    if (contract && deal) {
      if (contract.deals.length > 1) {
        const isCrossed = contract?.crossed === 'Crossed';
        setCrossed(isCrossed);

        const master = contract.masterDeal;

        const rawLinkedDeals = contract.deals.filter((d) => d._id !== deal._id);
        const linkedDeals = rawLinkedDeals.map((d: Deal) => {
          const masterShow = getMasterShow(d.shows);

          const date = new Date(masterShow.date);
          const mDate = moment.utc(date);
          // const formattedDate = mDate.format('dddd DD/MM/YYYY');
          const formattedDate = mDate.format(getDateFormatByBrowser());
          return {
            id: d._id,
            date: date,
            formattedDate: formattedDate,
            venue: masterShow?.venue?.name || 'Venue Name',
            dealType: d.termTypes ? d.termTypes[0] : 'Flat',
            guarantee: {
              value: d.guarantee,
              currency: d.currency,
            },
          };
        });
        linkedDeals.sort((a, b) => a.date.getTime() - b.date.getTime());
        setLinkedDealsData(linkedDeals);

        if (master && contract.crossed !== 'Neither') {
          const calculatedValues = contract.calculations;
          const parsedData = {
            guaranteeTotal: {
              value: calculatedValues.guaranteeTotal?.amount || 0,
              currency: calculatedValues.guaranteeTotal?.currency || 'USD',
            },
            guaranteeTotalOverride: {
              value: 0,
              currency: master.currency || 'USD',
            },
            estimatedGrossPotential: {
              value: calculatedValues.estimatedGrossPotential?.amount || 0,
              currency: calculatedValues.estimatedGrossPotential?.currency || 'USD',
            },
            estimatedNetPotential: {
              value: calculatedValues.estimatedNetPotential?.amount || 0,
              currency: calculatedValues.estimatedNetPotential?.currency || 'USD',
            },
            calculatedWalkoutPotential: {
              value: calculatedValues.calculatedWalkoutPotential?.amount || 0,
              currency: calculatedValues.calculatedWalkoutPotential?.currency || 'USD',
            },
            walkoutPotentialOverride: {
              value: master.overrideWalkoutPotential || 0,
              currency: master.currency || 'USD',
            },
            overage: {
              value: master?.overage || 0,
              currency: master.currency || 'USD',
            },
            calculatedAmountToSplit: {
              value: calculatedValues.calculatedAmountToSplit?.amount || 0,
              currency: calculatedValues.calculatedAmountToSplit?.currency || 'USD',
            },
            amountToSplitOverride: {
              value: master.overrideAmountToSplit || 0,
              currency: master.currency || 'USD',
            },
            tourDealTerms: contract.tourDealTerms || '',
          };
          setContractData(parsedData);
          setMasterDeal(master);
        }
      }
    }
  }, [deal, contract]);

  const onSubmit = async (data: iOrganismLinkedDeals) => {
    if (contract && masterDeal) {
      const ogTerms = getTerms(masterDeal);
      const masterShow = getMasterShow(masterDeal.shows);

      const termsData: iOrganismDealTerms = {
        currency: ogTerms.currency.value || 'USD',
        contractDisplay: ogTerms.contractDisplay || '',
        dealType: {
          id: ogTerms.dealType.value,
          label: ogTerms.dealType.value,
        },
        guarantee: {
          value: Number(data.guaranteeTotalOverride?.value) || 0,
          currency: ogTerms.currency.value || 'USD',
        },
      };

      const terms = createTermsPayload(termsData);
      const performers: Performer[] = masterShow.performers.map((performer, index) => {
        if (index === 0) {
          return { ...performer, terms };
        }
        return performer;
      });

      setCanSubmit(false);

      const updatedShowDeal = await updateDealShows({
        dealId: masterDeal._id.toString(),
        updates: [
          {
            showId: masterShow._id,
            updateBody: {
              performers,
            },
          },
        ],
      });
      const newDealPayload = {
        ...updatedShowDeal.body,
        overrideWalkoutPotential: data.walkoutPotentialOverride?.value || 0,
        overrideAmountToSplit: data.amountToSplitOverride?.value || 0,
        overage: data.overage?.value || 0,
      };
      try {
        const updatedDeal = await updateDeal(masterDeal._id, { ...newDealPayload });
        const updatedContract =
          data.tourDealTerms !== contract.tourDealTerms
            ? await updateContract(contract._id, {
                ...contract,
                tourDealTerms: data.tourDealTerms,
              })
            : contract;

        if (updatedDeal && setContract) setContract({ ...updatedContract, masterDeal: updatedDeal });
        setTimeout(() => {
          setCanSubmit(true);
        }, 500);
      } catch (error) {
        console.log('ERROR updating contract with linked deal tour terms', error);
        setCanSubmit(true);
      }
    }
  };

  const onDelete = async (dealId: string) => {
    if (contract) {
      try {
        const updatedContract = await removeLinkedDeal(contract._id, dealId);
        if (updatedContract) {
          setContract && setContract(updatedContract);
        }
      } catch (e) {
        console.log('ERROR removing linked deal', e);
      }
    }
  };

  return (
    <div>
      <div data-cy={LINKED_DEALS_SECTION.LINK_CROSS_TYPE} className="mb-6">
        {crossed ? (
          <p className="my-2">
            This event is part of a <b>crossed offer</b> along with the following shows:
          </p>
        ) : (
          <p className="my-2">
            This event is part of a <b>non-crossed offer</b> along with the following shows:
          </p>
        )}
      </div>
      {linkedDealsData && (
        <div className="mb-6">
          <ColumnLabels labels={COLUMN_LABELS} containerClassName={`${GRID_LAYOUT}`} columnClassName="px-2" />
          {linkedDealsData.map((d, i) => (
            <div key={d.id + i} className={`${GRID_LAYOUT} border-b border-black py-2`}>
              <a href={`/deal/${d.id}`} target="_blank" referrerPolicy="no-referrer">
                <ArrowTopRightOnSquareIcon width={20} className="text-viridian" />
              </a>

              <p className="px-2 text-sm">{d.formattedDate}</p>

              <p className="px-2 text-sm">{d.venue}</p>

              <p className="px-2 text-sm">{d.dealType}</p>

              <p className="px-2 text-sm">{formatCurrencyWithSymbol(d.guarantee.value, d.guarantee.currency)}</p>

              {/* <RemoveButton
                className="flex w-10 items-center justify-center self-start"
                onClick={() => {
                  onDelete(d.id);
                }}
              /> */}
              <RemoveDealModal
                setShowModal={setShowRemoveDealModal}
                showModal={showRemoveDealModal}
                mobileView={false}
                deleteDeal={onDelete}
                dealId={d.id}
              />
            </div>
          ))}
        </div>
      )}

      {masterDeal && (
        <Form canSubmit={canSubmit} onSubmit={onSubmit} defaultValues={contractData} formName={FORM_NAMES.LINKED_DEALS}>
          <div className="grid grid-cols-[200px_400px] gap-4">
            <label htmlFor="guaranteeTotal" className="form-label">
              Contract Guarantee
            </label>
            <TextField
              dataCy={LINKED_DEALS_SECTION.LINKED_DEALS_CONTRACT_GUARANTEE}
              id="guaranteeTotal"
              text="--"
              isCurrency
            />
            {/* {crossed && (
              <>
                <label htmlFor="guaranteeTotalOverride" className="form-label">
                  Contract Guarantee Override
                </label>
                <Currency id="guaranteeTotalOverride" overrideCurrency={masterDeal.currency || 'USD'} />
              </>
            )} */}
            <label htmlFor="estimatedGrossPotential" className="form-label">
              Contract Gross Potential
            </label>
            <TextField id="estimatedGrossPotential" text="--" isCurrency />
            <label htmlFor="estimatedNetPotential" className="form-label">
              Contract Net Potential
            </label>
            <TextField id="estimatedNetPotential" text="--" isCurrency />
            <label htmlFor="calculatedWalkoutPotential" className="form-label">
              Walkout Potential
            </label>
            <TextField id="calculatedWalkoutPotential" text="--" isCurrency />

            <>
              <label htmlFor="tourDealTerms" className="form-label">
                Tour Deal Terms
              </label>
              <TextArea id="tourDealTerms" rows={4} />
            </>

            {/* {crossed && (
              <>
                <label htmlFor="walkoutPotentialOverride" className="form-label">
                  Walkout Potential Override
                </label>
                <Currency id="walkoutPotentialOverride" overrideCurrency={masterDeal.currency || 'USD'} />
                <label htmlFor="calculatedAmountToSplit" className="form-label">
                  Amount to Split
                </label>
                <TextField id="calculatedAmountToSplit" text="--" isCurrency />
                <label htmlFor="amountToSplitOverride" className="form-label col-start-1">
                  Amount to Split Override
                </label>
                <Currency id="amountToSplitOverride" overrideCurrency={masterDeal.currency || 'USD'} />

                <label htmlFor="overage" className="form-label">
                  Overage
                </label>
                <Currency
                  id="overage"
                  overrideCurrency={masterDeal?.currency || deal?.currency || 'USD'}
                  containerClassName="max-w-[512px]"
                />
              </>
            )} */}
          </div>
        </Form>
      )}
    </div>
  );
};

export default LinkedDealsForm;
