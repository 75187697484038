import { formatDateString } from 'grid-pages/utils/format';

import BlankCell from '../BlankCell';
import { FC } from 'react';

export type DateRendererProps = {
  value?: string;
};

const DateRenderer: FC<DateRendererProps> = ({ value }) => {
  if (!value) {
    return <BlankCell />;
  }

  const dateString = formatDateString(value || '');

  return <>{dateString !== '' ? dateString : value}</>;
};

export default DateRenderer;
