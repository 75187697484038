// import 'jspdf/dist/polyfills.es.js';
// UTA Packages
// import { Branding } from '@united-talent-agency/components';
import { LicenseManager } from 'ag-grid-enterprise';
import { LoginCallback } from 'features/common';
import { Header } from 'features/header';
import {
  Accounting,
  Availability,
  Contacts,
  Contracts,
  DealForm,
  AccessDenied,
  Promoters,
  Tracking,
  Venue,
} from 'pages';
import DealsTestAlgolia from 'pages/algolia-client';
import DealsTestAlgoliaServer from 'pages/algolia-server';
import CustomReportsPage from 'pages/custom-reports';
import Deals from 'pages/deals';
import { Logout } from 'pages/logout';
import DealsTestMongoClient from 'pages/mongo-client';
import DealsTestMongo from 'pages/mongo-server';
import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { User } from 'types';

import { get, showUserError } from './api';
import { ClientRoutingPage } from './containers/routing/ClientRouting';
import { RoutingContainer } from './containers/routing/RoutingContainer';
import { Login } from './features/common/login/Login';
// import NavBar from './features/nav-bar';
import { authenticate } from './support/auth';
import { setDataDog } from './support/dataDog';
import { Desk } from './types/RootState';
import { apiServerUrl } from './urls';
import { hasAccessToAccountingQueue, isUserFromAllowedDepartments } from 'utils/helpers';
import { useFilters } from 'context/avails-filters/context';
import { useMarker } from 'context/useMarker';

// eslint-disable-next-line no-undef
const SHOW_BANNER = process.env.REACT_APP_SHOW_BANNER;

// eslint-disable-next-line no-undef
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID || '');

const Root: React.FC = () => {
  const [user, setUser] = useState<null | User>(null);
  const [desks, setDesks] = useState<Desk[]>([]);
  const [token, setToken] = useState(localStorage.getItem('accessToken'));
  const [currentDeskId, setCurrentDeskId] = useState<string | null>(null);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const { load: loadMarker, unload: unloadMarker } = useMarker();

  const routeLocation = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'local' || process.env.REACT_APP_ENVIRONMENT === 'test') return;

    const { pathname } = routeLocation;

    if (pathname.includes('customReports')) {
      unloadMarker();
    } else {
      loadMarker();
    }
  }, [routeLocation]);

  useEffect(() => {
    const isFullWidth = routeLocation.pathname.includes('/reports/');

    const defaultWidth = '1920px';
    const fullWidth = '100%';
    const widthValue = isFullWidth ? fullWidth : defaultWidth;
    const widthVar = '--content-wrapper-width';

    document.documentElement.style.setProperty(widthVar, widthValue);
  }, [routeLocation]);

  // capture the desk in local storage
  const [storedDeskId, setStoredDeskId] = useLocalStorage('deskId', '');
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const navigate = useNavigate();
  const { setCollapsed, clearAll } = useFilters();

  const props = {
    apiServerUrl,
    user,
    currentDeskId: currentDeskId || '',
  };

  const handleChangeDeskId = (val: string) => {
    const temp = currentDeskId?.toLowerCase();
    setCurrentDeskId(val);
    setStoredDeskId(val);
    if (temp !== val) {
      // reset avails filters state
      setCollapsed(false);
      clearAll();
      navigate('/');
    }
  };

  // Fetch a token from UTA Azure via UTA Authentication registered App-Service.
  useEffect(() => {
    if (!token) {
      authenticate();
    }
  }, [token]);

  // Once a token has been fetched, call the API with the token to get the
  // authenticated user & desks.
  useEffect(() => {
    if (token && !loggedIn) {
      get('/touring/login/')
        .then((res) => {
          const { user: _user, desks: _desks } = res.body;
          setLoggedIn(true);
          setUser(_user);
          setDesks(_desks);

          if (storedDeskId && _user.deskIds.includes(storedDeskId)) {
            setCurrentDeskId(storedDeskId);
          } else {
            setCurrentDeskId(_user.deskIds[0]);
          }
          setIsAuthenticating(false);
          setDataDog(_user);
        })
        .catch((err) => {
          setIsAuthenticating(false);
          showUserError(err);
        });
    }
  }, [token, loggedIn]);
  return (
    <div className="font-sans h-full">
      <Helmet>
        <title>Touring</title>
      </Helmet>

      <div className="absolute w-full z-40">
        {/* <Branding
          text="Touring"
          currentDeskId={currentDeskId || ''}
          desks={desks}
          showBanner={SHOW_BANNER}
          showDeskSelector
          onChangeDesk={handleChangeDeskId}
        /> */}
        <Header
          banner={SHOW_BANNER}
          currentDeskId={currentDeskId || ''}
          user={user}
          desks={desks}
          onChangeDesk={handleChangeDeskId}
        />
      </div>
      {/* <NavBar /> */}
      <>
        {loggedIn && currentDeskId ? (
          <Routes>
            <Route path="/" element={<Navigate to="/routing" />} />
            {isUserFromAllowedDepartments(user) ||
            process.env.REACT_APP_ENVIRONMENT === 'test' ||
            process.env.REACT_APP_ENVIRONMENT === 'local' ? (
              <>
                {/* <Route path="/" element={<RoutingContainer {...props} />} /> */}
                <Route
                  path="/availability"
                  element={<Availability currentDeskId={currentDeskId || ''} desks={desks} user={user as User} />}
                />
                <Route path="/routing" element={<RoutingContainer {...props} />} />
                <Route path="/routing/:artistId/:artistName/" element={<ClientRoutingPage user={user as User} />} />
                <Route path="/deal/:dealId" element={<DealForm />} />
                <Route path="/reports" element={<Navigate to="/reports/deals" replace />} />
                <Route path="/reports/deals" element={<Deals />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/reports/tracking" element={<Tracking />} />
                <Route
                  path="/reports/accountingQueue"
                  element={hasAccessToAccountingQueue(user) ? <Accounting /> : <AccessDenied />}
                />
                <Route path="/reports/contracts" element={<Contracts />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/contacts/venues" element={<Venue />} />
                <Route path="/contacts/promoters" element={<Promoters />} />
                <Route path="/tests/algolia-client" element={<DealsTestAlgolia />} />
                <Route path="/tests/algolia-server" element={<DealsTestAlgoliaServer />} />
                <Route path="/tests/mongo-server" element={<DealsTestMongo />} />
                <Route path="/tests/mongo-client" element={<DealsTestMongoClient />} />
                <Route path="/reports/customReports" element={<CustomReportsPage />} />
                <Route path="/reports/customReports/:reportId" element={<CustomReportsPage />} />
              </>
            ) : (
              <>
                <Route path="/routing" element={<AccessDenied />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : (
          // PUBLIC ROUTES
          <>
            <div className="pt-30" />
            <Routes>
              <Route path="/login-callback" element={<LoginCallback {...props} setToken={setToken} />} />
              <Route path={'*'} element={<Login isAuthenticating={isAuthenticating} />} />
            </Routes>
          </>
        )}
      </>
    </div>
  );
};

const App: React.FC = () => (
  <Router>
    <HelmetProvider>
      <Root />
    </HelmetProvider>
  </Router>
);

export default App;
