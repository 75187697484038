import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { iOptions } from 'deal-form/interfaces/general';
import { BaseSyntheticEvent, KeyboardEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import {
  FieldValues,
  FormState,
  RegisterOptions,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext,
} from 'react-hook-form';
import { useClickAway } from 'react-use';
import { alphabetize } from 'utils/helpers';

import { findNestedErrorById } from '../../helpers/formHelpers';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

interface iDropdownProps {
  label?: string | boolean;
  options?: iOptions[];
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  id: string;
  index?: number;
  handleChange?: (
    chosen: iOptions | null,
    setValueMethod?: UseFormSetValue<FieldValues>,
    index?: number,
    trigger?: UseFormTrigger<FieldValues>
  ) => void;
  rules?: RegisterOptions;
  rulesCallback?: (formState: FormState<FieldValues>, index?: number) => RegisterOptions<FieldValues, string>;
  hideClearButton?: boolean;
  dataCy?: string;
}

export const Dropdown = ({
  id,
  options,
  label,
  handleChange,
  placeholder,
  className,
  containerClassName,
  // disabled = false!,
  index,
  rules,
  rulesCallback,
  hideClearButton = false,
  dataCy,
}: iDropdownProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [chosen, setChosen] = useState<iOptions | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const disabled = false;

  const {
    register,
    setValue,
    getValues,
    control,
    trigger,
    clearErrors,
    formState,
    formState: { errors },
  } = useFormContext();

  // TODO: refactor to combine rulesCallback and rules
  const { ref, name } = register(id, { ...(rulesCallback ? { ...rulesCallback(formState, index) } : rules) });

  const openRef = useRef(null);
  useClickAway(openRef, () => {
    setIsOpen(false);
  });

  const hasErrors = errors && !!findNestedErrorById(id, errors);

  // Clear errors when component unmounts
  useEffect(() => () => clearErrors(id), []);

  const isRequired = Object.keys(rules || {}).includes('required');

  useEffect(() => {
    if (chosen) {
      const currentValue = getValues(id);
      //Only do update if chosen value has changed
      if (chosen.id !== currentValue) {
        // TODO: We may want to refactor this to use react-hook-form's Controller
        // In the meantime, we set `dirty` programmatically to true to reflect the value has changed
        setValue(id, chosen.id, { shouldDirty: true });
        trigger(id);

        if (handleChange) handleChange(chosen, setValue, index, trigger);
        const element: HTMLSelectElement = document.getElementById(`${id}-${name}`) as HTMLSelectElement;

        if (element) {
          if (getValues(id) !== element.value) {
            element.value = chosen.id;
          }
        }
      }
    }
  }, [chosen, handleChange, id, setValue]);

  useEffect(() => {
    if (control._defaultValues && getValues(id)) {
      try {
        const value = getValues(id);

        if (value !== undefined) {
          const idValue = value.value || value;
          const optionFromId = options?.filter((option) => option.id === idValue);

          if (optionFromId) {
            if (optionFromId.length > 0) {
              setChosen(optionFromId[0]);
            } else {
              setChosen({ id: value, label: value });
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [control._defaultValues, options]);

  useEffect(() => {
    if (isOpen === false) {
      setSearchValue('');
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    if (!disabled) toggleOpen();
  };

  const handleArrowKeyDown = (direction = 'down') => {
    if (!options) {
      return;
    }

    if (!isOpen) {
      setIsOpen(true);
      return;
    }

    // const currentIndex = options.sort(alphabetize).findIndex((option) => option.id === chosen?.id);

    const currentIndex = chosen
      ? options.sort(alphabetize).findIndex((option) => option.id === chosen?.id)
      : options.length;

    const prevIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
    const newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;

    setChosen(options[direction === 'up' ? prevIndex : newIndex]);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
    const key = e.key;

    if (key === 'Enter') {
      e.preventDefault();
      setIsOpen(false);
    }

    if (key === 'ArrowUp') {
      e.preventDefault();
      handleArrowKeyDown('up');
    }

    if (key === 'ArrowDown') {
      e.preventDefault();
      handleArrowKeyDown('down');
    }

    if (key === 'Escape') {
      setIsOpen(false);
    }
  };

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const key = e.key;

    if (key === 'Enter') {
      e.preventDefault();
      setIsOpen(false);
    }

    if (key === 'ArrowUp') {
      e.preventDefault();
      handleArrowKeyDown('up');
    }

    if (key === 'ArrowDown') {
      e.preventDefault();
      handleArrowKeyDown('down');
    }

    if (key === 'Escape' || key === 'Tab') {
      setIsOpen(false);
    }
  };

  const handleClear = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setChosen(null);
    setValue(id, '', { shouldDirty: true });

    if (isOpen) {
      setIsOpen(false);
    }
  };

  if (getValues(id) === undefined) return <div />;

  return (
    <div className={twMerge(clsx('form-field-wrapper', containerClassName))} data-cy={dataCy}>
      <select id={`${id}-${name}`} name={name} value={getValues(id).id} className="hidden">
        {options &&
          options.map((option) => (
            <option key={`${id}-${option.id}`} value={option.id}>
              {option.label}
            </option>
          ))}
      </select>
      {label !== false && (
        <label className={`standard-label ${(containerClassName || '').includes('flex-col') ? '!self-start' : ''}`}>
          {label}
          {isRequired ? <span className="text-cinnabar">*</span> : null}
        </label>
      )}
      <div
        ref={ref} // assign ref prop
        className={`h-10  w-full border border-solid ${disabled ? 'opacity-50' : 'cursor-pointer'} ${
          hasErrors ? 'border-cinnabar' : 'border-black'
        } select-none relative text-sm ${isOpen ? 'active' : ''}
      after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3 ${className}`}
      >
        {isOpen ? (
          <input
            type="text"
            className="flex flex-row items-center justify-between max-w-full w-full h-full px-3 py-2 z-[150]"
            onChange={(event) => {
              return setSearchValue(event.target.value);
            }}
            onKeyDown={handleSearchKeyDown}
            tabIndex={0}
            autoFocus
            value={searchValue}
            data-cy={dataCy}
          />
        ) : (
          <button
            className="flex flex-row items-center justify-between max-w-full w-full h-full px-3 py-2 "
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            data-testid="dropdown-toggle-testid"
          >
            <span
              className={`text-ellipsis max-w-[90%] overflow-hidden table-cell whitespace-nowrap ${
                chosen ? '' : 'opacity-50'
              }`}
            >
              {chosen ? chosen.label : placeholder}
            </span>{' '}
            <div className="flex flex-row gap-2">
              <ChevronDownIcon width={15} className={`transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`} />
            </div>
          </button>
        )}
        <button
          className={`${
            hideClearButton || isRequired || chosen === null ? 'hidden' : ''
          } top-0 absolute right-[40px] h-full`}
          onClick={handleClear}
          data-id="clear"
          disabled={disabled}
          data-testid="clear-btn-testid"
        >
          <XMarkIcon width={20} />
        </button>

        <ul
          ref={openRef}
          className={`border border-solid border-black list-none m-0 p-0 absolute top-full border-t-0 -left-[1px] -right-[1px] transition-opacity duration-300 max-h-[300px] overflow-auto overflow-x-hidden bg-white shadow-header z-10 ${
            isOpen ? 'opacity-1 pointer-events-auto z-10' : 'opacity-0 pointer-events-none'
          }`}
          role="listbox"
        >
          {options && options.filter((o) => o.label.toLowerCase().includes(searchValue.toLowerCase())).length > 0 ? (
            options
              .filter((o) => o.label.toLowerCase().includes(searchValue.toLowerCase()))
              .sort(alphabetize)
              .map((option, i) => (
                <li
                  key={option.id}
                  className={`px-3 py-2 border-t border-solid border-greyCloudy hover:underline ${
                    option.disabled ? 'hidden' : ''
                  } text-sm whitespace-pre-wrap ${option.id === chosen?.id ? 'bg-greyQuill underline' : ''}`}
                  onClick={() => {
                    setChosen(option);
                    toggleOpen();
                  }}
                  role="option"
                  tabIndex={i + 1}
                >
                  {option.label}
                </li>
              ))
          ) : (
            <li
              className="px-3 py-2 border-t border-solid border-greyCloudy hover:underline text-sm whitespace-pre-wrap"
              onClick={() => {
                setChosen(null);
                toggleOpen();
              }}
              role="option"
            >
              no results
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  label: false,
  placeholder: '',
  className: '',
  containerClassName: '',
  disabled: false,
  rules: {},
  hideClearButton: false,
};
