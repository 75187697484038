import { Modal } from 'features/common/modal/Modal';
import { useForm } from 'react-hook-form';
import Button from '../../../components/Button';
import { FC } from 'react';

interface NoteValues {
  interimNotes: string;
}

export interface AddNoteModalProps {
  testId?: string;
  show: boolean;
  values: NoteValues; // initial value
  isLoading?: boolean;
  setShow: (show: boolean) => void;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
}

const AddNoteModal: FC<AddNoteModalProps> = ({
  testId,
  show = false,
  values,
  isLoading,
  setShow,
  onSubmit,
  onCancel,
}) => {
  const { register, handleSubmit } = useForm<NoteValues>();

  return (
    <Modal
      setShowModal={() => setShow(!show)}
      showModal={show}
      showClose
      title="Notes"
      className="lg:min-w-fit max-w-full overflow-x-auto"
    >
      <div data-testid={testId} className="flex flex-col items-center min-w-[325px] my-4">
        <form className="flex flex-col items-end gap-5 w-full" onSubmit={handleSubmit(onSubmit)}>
          <textarea
            {...register('interimNotes')}
            className="peer w-full appearance-none border border-solid border-black px-2 py-1 text-sm"
            placeholder="Type notes here..."
            id="interimNotes"
            rows={3}
            defaultValue={values.interimNotes}
          />
          <div className="flex justify-end w-full">
            <Button
              onClick={() => {
                if (onCancel) onCancel();
                setTimeout(() => {
                  setShow(false);
                }, 300);
              }}
              className="mr-8"
            >
              Cancel
            </Button>
            <Button
              // closing the modal on the parent component after async call
              className="bg-black"
              variant="secondary"
              type="submit"
              disabled={isLoading}
            >
              Edit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddNoteModal;
