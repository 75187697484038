import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, FC } from 'react';
import cypressTags from 'support/cypressTags';

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
}

const SearchBar: FC<SearchBarProps> = ({ value, onChange, onClear }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  return (
    <div className="relative border border-white w-full h-10 text-white flex items-center px-4 ">
      <input
        className="w-full h-full bg-transparent text-white outline-none text-sm font-extended"
        type="text"
        placeholder="Search Artist"
        value={value}
        onChange={handleChange}
        data-cy={cypressTags.COMMON.SEARCH_BAR}
      />
      {value && value !== '' ? (
        <button className="text-white" onClick={onClear} aria-label="clear-btn">
          <XMarkIcon width={20} />
        </button>
      ) : (
        <MagnifyingGlassIcon width={20} />
      )}
    </div>
  );
};

export default SearchBar;
