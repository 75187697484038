import { updateDeal } from 'api';
import { SEARCH_TYPE } from 'deal-form/constants';
import { FORM_NAMES } from 'deal-form/data/constants';
import { BUYER_EMPTY_FORM, BUYER_EMPTY_FORM_ITEM } from 'deal-form/data/organisms';
import { DropdownWithSearch } from 'deal-form/form-controls/DropdownWithSearch';
import { FieldArray } from 'deal-form/form-controls/FieldArray';
import RepeaterLabel from 'deal-form/form-controls/RepeaterLabel';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iBuyer, iOrganismBuyer } from 'deal-form/interfaces/organisms';
import { MasterDealWarning } from 'deal-form/ui/MasterDealWarning';
import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Buyer, Company, Deal, Person } from 'types';

import { Form } from '../../form-controls/Form';
import { Label } from '../../form-controls/Label';
import { TextField } from '../../form-controls/TextField';
import submitToNetsuite from 'utils/submitToNetsuite';
import cypressTags from 'support/cypressTags';

const BuyerForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, contract, setContract, locked }) => {
  const [buyerFormData, setBuyerFormData] = useState<iOrganismBuyer | null>(null);
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    let dealToUse = deal;
    if (contract && contract.masterDeal && contract.crossed !== 'Neither') {
      dealToUse = contract.masterDeal;
      setMasterDeal(contract.masterDeal);
    } else {
      setMasterDeal(null);
    }
    const { buyer, additionalBuyers } = dealToUse;

    //Primary buyer
    const primaryBuyer: iBuyer[] = mapBuyerDataToFormData(Array.isArray(buyer) ? buyer : [buyer], true);

    //Additional buyersxs
    const additional: iBuyer[] = additionalBuyers?.length ? mapBuyerDataToFormData(additionalBuyers, false) : [];

    if (buyer === null || buyer === undefined) setBuyerFormData(BUYER_EMPTY_FORM);
    else setBuyerFormData({ buyers: [...primaryBuyer, ...additional] });
  }, [deal, contract]);

  const mapBuyerDataToFormData = (buyers: Buyer[], isPrimary: boolean) => {
    const translated = buyers.map(({ company, person, signatory }) => {
      return {
        buyerCompany: { name: company?.name, data: company },
        buyerName: { name: person?.name, data: person },
        contacts: person?.contacts,
        address: company?.addresses,
        signatory: { name: signatory?.name || '', data: signatory || null },
        isPrimary: isPrimary,
      };
    });

    return translated;
  };

  const parseAdditionalForSubmit = (buyers?: iBuyer[]) => {
    if (!buyers) return [];
    const validBuyers = buyers.filter((b) => b?.buyerCompany.data && b?.buyerName.data);
    const parsed = validBuyers.map((buyer) => {
      return {
        company: buyer.buyerCompany.data as Company,
        person: buyer.buyerName.data as Person,
        signatory: buyer.signatory && buyer.signatory.data,
        useSignerBlock: false, //required field but we don't have a way for the user to add it
        signerBlock: '', //required field but we don't have a way for the user to add it
      };
    });
    return parsed;
  };

  const onSubmit = async (data: iOrganismBuyer) => {
    const dealToUse = masterDeal || deal;
    const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';
    const primaryBuyer = data.buyers.find((b) => b.isPrimary);
    const additionalBuyers = data.buyers.filter((b) => !b.isPrimary);

    try {
      if (primaryBuyer?.buyerCompany.data && primaryBuyer?.buyerName.data) {
        setCanSubmit(false);
        const updatedDeal = await updateDeal(dealToUse._id, {
          ...dealToUse,
          buyer: {
            ...dealToUse.buyer,
            company: primaryBuyer?.buyerCompany.data,
            person: primaryBuyer?.buyerName.data,
            signatory: primaryBuyer?.signatory && primaryBuyer?.signatory.data,
          },
          additionalBuyers: parseAdditionalForSubmit(additionalBuyers),
        });

        if (masterDeal && contract && setContract) {
          setContract({ ...contract, masterDeal: updatedDeal as Deal });
        } else if (updatedDeal && setDeal) setDeal(updatedDeal);

        if (!isDealSubmittedToNetsuite && contract?.status === 'Confirmed') {
          submitToNetsuite(contract, deal, false, undefined, setContract);
        }
        setTimeout(() => setCanSubmit(true), 500);
      }
    } catch (e) {
      console.log('ERROR updated buyer', e);
      setCanSubmit(true);
    }
  };

  /**
   * This function fires to update the data associated with the buyer company
   * primarily to update the company address
   *
   * @param chosen the data about the company being selected
   */
  const handleCompanyChange = (
    chosen: iOptions | null,
    setValueMethod: UseFormSetValue<FieldValues>,
    index?: number
  ) => {
    if (chosen?.data) {
      setValueMethod(`buyers.${index}.buyerCompany.name`, chosen?.data?.name);
      setValueMethod(`buyers.${index}.buyerCompany.data`, chosen?.data);
      setValueMethod(`buyers.${index}.address`, chosen?.data?.addresses);
    }
  };

  /**
   * This function fires to update the data associated with the buyer name
   * primarily to update the contact info
   *
   * @param chosen the data about the person being selected
   */
  const handleBuyerNameChange = (
    chosen: iOptions | null,
    setValueMethod: UseFormSetValue<FieldValues>,
    index?: number
  ) => {
    if (chosen?.data) {
      setValueMethod(`buyers.${index}.buyerName.name`, chosen?.data?.name);
      setValueMethod(`buyers.${index}.buyerName.data`, chosen?.data);

      // only get the first item of each contact type
      const contactList = chosen?.data?.contacts?.filter((value: any, i: number, s: any) => {
        return s.findIndex((v: any) => v.contactType === value.contactType) === i;
      });

      setValueMethod(`buyers.${index}.contacts`, contactList);
    }
  };

  /**
   * This function fires to update the data associated with the buyer name
   * primarily to update the contact info
   *
   * @param chosen the data about the person being selected
   */
  const handleSignatoryChange = (
    chosen: iOptions | null,
    setValueMethod: UseFormSetValue<FieldValues>,
    index?: number
  ) => {
    setValueMethod(`buyers.${index}.signatory.name`, chosen?.data?.name || '');
    setValueMethod(`buyers.${index}.signatory.data`, chosen?.data || null);
  };

  return (
    buyerFormData && (
      <>
        {masterDeal && contract && (
          <MasterDealWarning crossed={contract.crossed === 'Crossed'} fields={['Buyer Company', 'Buyer Name']} />
        )}
        <Form
          canSubmit={canSubmit}
          onSubmit={onSubmit}
          className=""
          defaultValues={buyerFormData}
          disabled={!isOpen || locked}
          formName={FORM_NAMES.BUYER}
        >
          <FieldArray
            gridClassName="fixed-first-column-with-labels w-full items-center"
            groupName="buyers"
            addButtonLabel="Add Additional Buyers"
            emptyRow={BUYER_EMPTY_FORM_ITEM}
            disabled={!isOpen || locked}
            lockRules={(field) => {
              const rule = {
                isLocked: false,
                fields: [],
              };

              if (field.isPrimary) {
                rule.isLocked = true;
              }

              return rule;
            }}
          >
            <Label isRequired>Buyer Company</Label>
            <DropdownWithSearch
              label={false}
              id="buyerCompany.name"
              searchType={SEARCH_TYPE.COMPANY}
              placeholder="Buyer Company"
              handleChange={handleCompanyChange}
              disabled={!isOpen || locked}
              isRequired
              rules={{
                required: 'Please select a valid buyer company',
              }}
              dataCy={cypressTags.DEAL_FORM.BUYER_SECTION.COMPANY_DROPDOWN}
            />

            <Label isRequired>Buyer Name</Label>
            <DropdownWithSearch
              label={false}
              id="buyerName.name"
              secondaryId="contacts"
              searchType={SEARCH_TYPE.CONTACT}
              placeholder="Buyer Name"
              handleChange={handleBuyerNameChange}
              disabled={!isOpen || locked}
              isRequired
              rules={{
                required: 'Please select a valid buyer name',
              }}
              dataCy={cypressTags.DEAL_FORM.BUYER_SECTION.PERSON_DROPDOWN}
            />

            <RepeaterLabel
              label="Company Address"
              className="form-label self-start"
              containerClassName="self-start"
              isRequired
            />
            <TextField
              label={false}
              id="address"
              className="pt-[2px]"
              text=""
              containerClassName="self-start"
              dataCy={cypressTags.DEAL_FORM.BUYER_SECTION.COMPANY_ADDRESS}
            />

            <RepeaterLabel label="Contact Info" className="form-label self-start" containerClassName="self-start" />
            <TextField
              label={false}
              id="contacts"
              className="pt-[2px]"
              text=""
              containerClassName="self-start"
              dataCy={cypressTags.DEAL_FORM.BUYER_SECTION.CONTACT_INFO}
            />

            <Label isRequired>Contract Signatory</Label>
            <DropdownWithSearch
              label={false}
              id="signatory.name"
              searchType={SEARCH_TYPE.CONTACT}
              placeholder="Contract Signatory"
              handleChange={handleSignatoryChange}
              disabled={!isOpen || locked}
              rules={{
                required: 'Please select a valid Contract Signatory',
              }}
              dataCy={cypressTags.DEAL_FORM.BUYER_SECTION.CONTRACT_SIGNATORY}
            />
          </FieldArray>
        </Form>
      </>
    )
  );
};

export default BuyerForm;
