import { FC } from 'react';
import Body from '.';
import { TableData } from '../types';
import { BodyCellFullProps } from './Cell/Full';

export interface BodyContentProps {
  data: TableData;
  renderCellContent?: FC<BodyCellFullProps<any>>;
}

const Content: FC<BodyContentProps> = ({ data, renderCellContent }) => (
  <>
    {data?.map((row, index) => (
      <Body.Row.Full key={index} row={row} index={index} renderCellContent={renderCellContent} />
    ))}
  </>
);

export default Content;
