import { datadogRum } from '@datadog/browser-rum';
import { User } from 'types';

const HOST_MAP: Record<string, string> = {
  'touring.unitedtalent.com': 'prod',
  'touring.staging.unitedtalent.com': 'staging',
  'touring.dev.unitedtalent.com': 'dev',
  localhost: 'local',
};

let DATA_DOG_RUM = false;

/**
 * Triggers Real User Monitoring (RUM) within UTA's data-dog account
 * when the running .env is within Staging or Prod.
 * @param _user
 */
export const setDataDog = (_user: User) => {
  if (!DATA_DOG_RUM) {
    datadogRum.setUser({
      id: _user.azure_id,
      name: `${_user.last_name}, ${_user.first_name}`,
      email: _user.email,
    });
    datadogRum.startSessionReplayRecording();
    DATA_DOG_RUM = true;
  }
};

/**
 * Initializes data dog for the appropriate host (environment)
 *
 * @param { string } host:
 * @param { string[] } allowableEnvs: a list of allowable environments to track.
 */
export const initializeDataDog = (host: string, allowableEnvs: string[]) => {
  const env = HOST_MAP[host];
  if (allowableEnvs.includes(env)) {
    datadogRum.init({
      env,
      version: '0.3.1',
      service: 'touring-united-talent',
      site: 'us3.datadoghq.com',
      applicationId: '84bf9d42-e75c-4630-b767-cc4052587b33',
      clientToken: 'pub89c5ac56b4bd93e869420958a372ee45',
      allowedTracingOrigins: ['https://api.unitedtalent.com', /https:\/\/.*\.unitedtalent\.com/],
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });
  }
};
