import { Company } from 'types';

import { ExternalRelationship, UTARelationship } from '../types/data-schemas';
import { get } from './index';

export const findCompanies = async (name: string): Promise<Company[]> => {
  if (!name) {
    return [];
  }
  const response = await get(`/touring/companies/${name}`);
  return response.body;
};

export const getGroupToUTAEmployee = async (id: string): Promise<UTARelationship[]> => {
  const response = await get(`/v2/internal-teams/group-to-uta-employee?group=${id}`);
  return response.body;
};

export const getCompanyDetails = async (id: string): Promise<{ data: Company }> => {
  const response = await get(`/groups/${id}`);
  return response.body;
};

export const getCompanyToPersonRelationships = async (id: string): Promise<ExternalRelationship[]> => {
  const response = await get(`/v2/external-teams/group-to-person?group=${id}`);
  return response.body;
};

export const getCompanyToCompanyRelationships = async (id: string): Promise<ExternalRelationship[]> => {
  const response = await get(`/v2/external-teams/group-to-group?group=${id}`);
  return response.body;
};
