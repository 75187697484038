import Body from './Body';
import Full from './Full';
import Header from './Header';
import Root from './Root';

const ShowsAgenda = {
  Root,
  Full,
  Header,
  Body,
};

export default ShowsAgenda;
