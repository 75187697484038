import { ReactElement, useEffect, useState } from 'react';
import { FormSelect, Switch, Tooltip, SelectOptionT } from '@united-talent-agency/onyx-components';

import CustomRenderOption from './CustomRenderOption';
import { availsClientSearch } from 'api/search-client';
import { Person } from 'types';
import { getClientsRoutingInfo as getClients } from 'api/clients-routing';

import { useFilters } from 'context/avails-filters/context';

interface CustomHitType extends Person {
  _id: string;
  readonly objectID: string;
  readonly _highlightResult?: {} | undefined;
  readonly _snippetResult?: {} | undefined;
  readonly _rankingInfo?: any;
  readonly _distinctSeqID?: number | undefined;
}

interface ClientsFilterContainerProps {
  currentDeskId: string;
}

const ClientsFilter = (props: ClientsFilterContainerProps): ReactElement => {
  const { setSelectedClients, selectedClients, showAllMyClients, myClients, setMyClients } = useFilters();
  const { currentDeskId = '' } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const loadMyclients = async () => {
    try {
      setLoading(true);
      const results = await getClients({ desk: currentDeskId, showAll: false, limit: 0 });
      if (results && Array.isArray(results?.data)) {
        setMyClients(results.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleGetMyClients = async (showMyClients = false) => {
    if (showMyClients) {
      // an object for quick lookup and elimination of duplicates
      const uniqueClients: Record<string, SelectOptionT> = {};

      // Populate the object with existing clients
      selectedClients.forEach((client) => {
        uniqueClients[client.label._id] = client;
      });

      // Populate the object with new clients
      myClients.forEach((client: any) => {
        // Assuming client._id is the unique identifier
        const clientKey = client._id;
        if (!uniqueClients[clientKey]) {
          uniqueClients[clientKey] = {
            label: client,
            value: client._id,
          };
        }
      });

      // Convert back to array
      const newValues = Object.values(uniqueClients);

      setSelectedClients(newValues);
    } else {
      const myClientIds = new Set(myClients.map((client: any) => client._id));

      const filteredSelectedClients = selectedClients.filter((client) => !myClientIds.has(client.label._id));

      setSelectedClients(filteredSelectedClients);
    }
  };

  useEffect(() => {
    loadMyclients();
  }, [currentDeskId]);

  const peopleIndex = process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX ?? '';
  return (
    <div className="client-filter">
      <FormSelect
        placeholder="Select User"
        label="Client Name(s)"
        menuIsOpen
        dropdownIndicator={<></>}
        menuPortalTarget={document?.getElementsByClassName('client-filter')[0] as HTMLElement}
        menuPosition="fixed"
        menuShouldBlockScroll
        isLoading={loading}
        isMulti
        isSearchable
        value={selectedClients}
        onChange={(value) => setSelectedClients([...value])}
        renderValue={(data) => (
          <Tooltip placement="bottom-start">
            <Tooltip.Trigger>
              <p className="bg-pearl-grey text-[10px] leading-5 px-2">{data.label.name}</p>
            </Tooltip.Trigger>
          </Tooltip>
        )}
        renderOption={(data) => <CustomRenderOption label={data.label} />}
        noOptionsMessage="No User"
        loadOptions={async (inputValue: string) => {
          return new Promise<SelectOptionT[]>((resolve, reject) => {
            availsClientSearch(peopleIndex, inputValue, 100)
              .then((results) => {
                if (!results) return reject();
                const options: SelectOptionT[] = (results.hits as CustomHitType[]).map((i: CustomHitType) => ({
                  label: i,
                  value: i._id,
                }));
                resolve(options);
              })
              .catch((error) => reject(error));
          });
        }}
        className="w-full"
      />
      <div className="toggle-container mt-6 flex items-center gap-3">
        <Switch
          checked={Boolean(myClients.length) && showAllMyClients}
          disabled={loading}
          onChange={(state) => handleGetMyClients(state)}
          size="lg"
        />
        <span className="text-sm font-normal">Show My Clients</span>
      </div>
    </div>
  );
};

export default ClientsFilter;
