import { ColumnApi, ColumnState, GridApi } from 'ag-grid-community';
import { saveRoutingTableState } from 'api/clients-routing';
import haversineDistance from 'haversine-distance';
import moment from 'moment/moment';
import { Context, Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useState } from 'react';
import { TableRow, User, ViolationsMap } from 'types';
import { IFrontendViolation } from 'types/TableRow';
import { dateToUTC, toMiles } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';

import { get } from '../../../api';

export interface iModalState {
  isOpen: boolean;
  date?: Date;
  dealId?: string;
}

export type SetState<T> = Dispatch<SetStateAction<T>>;
export type RoutingContextDef = {
  // States
  user: User | null;
  setUser: SetState<User | null>;
  tableRows: TableRow[] | null;
  setTableRows: SetState<TableRow[] | null>;
  violations: ViolationsMap;
  setViolations: SetState<ViolationsMap>;
  numberViolations: number;
  setNumberViolations: SetState<number>;
  selectedDate: Date;
  setSelectedDate: SetState<Date>;
  selectedIndex: number;
  setSelectedIndex: SetState<number>;
  artistId: string;
  setArtistId: SetState<string>;
  gridApi: GridApi | null;
  setGridApi: SetState<GridApi | null>;
  columnApi: ColumnApi | null;
  setColumnApi: SetState<ColumnApi | null>;
  updateInProgress: boolean;
  setUpdateInProgress: SetState<boolean>;
  showNewDealModal: iModalState;
  setShowNewDealModal: SetState<iModalState>;
  showRejectDealModal: iModalState;
  setShowRejectDealModal: SetState<iModalState>;
  // API
  fetchTableRows: (artistId: string, daysPrior?: number, daysAfter?: number) => Promise<void>;
  saveColumnState: (columnState: ColumnState[]) => Promise<void>;
};
export const contextDef: RoutingContextDef = {
  // States
  user: null as User | null,
  setUser: (() => {}) as SetState<User | null>,
  tableRows: null,
  setTableRows: (() => {}) as SetState<TableRow[] | null>,
  violations: {} as ViolationsMap,
  setViolations: (() => {}) as SetState<ViolationsMap>,
  numberViolations: 0,
  setNumberViolations: (() => {}) as SetState<number>,
  selectedDate: new Date(),
  setSelectedDate: () => Date,
  selectedIndex: 0,
  setSelectedIndex: () => {},
  artistId: '',
  setArtistId: (() => {}) as SetState<string>,
  gridApi: null as GridApi | null,
  setGridApi: (() => {}) as SetState<GridApi | null>,
  columnApi: null as ColumnApi | null,
  setColumnApi: (() => {}) as SetState<ColumnApi | null>,
  updateInProgress: false,
  setUpdateInProgress: () => {},
  showNewDealModal: { isOpen: false },
  setShowNewDealModal: () => {},
  showRejectDealModal: { isOpen: false },
  setShowRejectDealModal: () => {},
  // API
  fetchTableRows: (async () => {}) as (artistId: string, daysPrior?: number, daysAfter?: number) => Promise<void>,
  saveColumnState: (async () => {}) as (columnState: ColumnState[]) => Promise<void>,
};

/*
 * Converts meters to miles for TableRow.radius viewing.
 * @param row
 * @returns An object of type TableRow
 */
export const convertDistance = (row: TableRow): TableRow => {
  if ((row?.radius as number) >= 0) {
    row.radius = row.radius as number;
  }
  return row;
};

/**
 * Converts timeBefore & timeAfter from milliseconds into days
 *
 * @param row
 * @returns An object of type TableRow
 */
export const convertTime = (row: TableRow): TableRow => {
  if ((row.timeBefore as number) >= 0) {
    const v = row.timeBefore;
    row.timeBefore = v as number;
  }
  if ((row?.timeAfter as number) >= 0) {
    const v = row.timeAfter;
    row.timeAfter = v as number;
  }
  return row;
};

/**
 * Converts Guarantee and Walkout Potential into amount and currency type
 *
 * @param row
 * @returns An object of type TableRow
 */
export const convertCurrencies = (row: TableRow): TableRow => {
  const { guarantee, walkoutPotential, dealCurrency } = row;
  if ((guarantee || guarantee == 0) && (typeof guarantee == 'number' || typeof guarantee == 'string')) {
    row.guarantee = {
      amount: guarantee as number,
      currency: dealCurrency || 'USD',
    };
  }

  if ((walkoutPotential || walkoutPotential === 0) && (typeof guarantee == 'number' || typeof guarantee == 'string')) {
    row.walkoutPotential = {
      amount: walkoutPotential as number,
      currency: dealCurrency || 'USD',
    };
  }
  return row;
};

/**
 * Converts raw data of time & distance into readable form
 * Adds uuid for row id
 * @param show
 * @returns An object of type TableRow
 */
export const convertResponse = (show: TableRow): TableRow => {
  convertTime(show);
  convertDistance(show);
  convertCurrencies(show);
  return { ...show, id: uuidv4() };
};

const normalizeRadiusClauses = (rows: TableRow[]) => {
  // if rows exist, go through each one
  rows &&
    rows.forEach((row) => {
      if (row.radius && row.radius !== 0 && row.dealShows && row.dealShows > 1) {
        const showsOfDeal = rows.filter((r) => r.deal === row.deal);
        showsOfDeal.forEach((show) => {
          show.radius = row.radius;
          show.timeBefore = row.timeBefore;
          show.timeAfter = row.timeAfter;
        });
      }
    });
};

/**
 * run through all the rows and determine if there are any
 * Radius Clause violations.  If there are, attach them to the
 * object and return the modified array
 *
 * @param {TableRows[]} rows the rows from the data, received from the API
 */
const captureViolations = (rows: TableRow[]) => {
  let numberViolations = 0;

  rows &&
    rows.forEach((row) => {
      // checking to see if any OTHER shows violate the current one
      if (row.radius && row.radius > 0) {
        const { radius, radiusUnit, timeAfter, timeBefore, date } = row;

        const violatorsFound: IFrontendViolation[] = [];

        const dealDate = dateToUTC(new Date(date))!;

        const clauseStart = dateToUTC(
          new Date(new Date(dealDate.getTime()).setDate(dealDate.getDate() - (timeBefore || 0)))
        );

        const clauseFinish = dateToUTC(new Date(new Date(dealDate).setDate(dealDate.getDate() + (timeAfter || 0))));

        // capture any items within the date range
        const possibleViolators = rows.filter(
          (r) =>
            r.deal &&
            r.deal !== row.deal &&
            r.status !== 'Cancelled' &&
            r.status !== 'Rejected' &&
            r.clientAvailable !== false &&
            dateToUTC(new Date(r.date))! > clauseStart! &&
            dateToUTC(new Date(r.date))! < clauseFinish!
        );

        // if there are items in the date range
        if (possibleViolators.length > 0) {
          // capture the geo location of the venue
          const pointA = {
            lon: row?.venue?.geoLocation?.coordinates[0] || 0,
            lat: row?.venue?.geoLocation?.coordinates[1] || 0,
          };

          // check to see if any of the violators are within the distance
          possibleViolators.forEach((pv) => {
            const pointB = {
              lon: pv.venue?.geoLocation.coordinates[0] || 0,
              lat: pv.venue?.geoLocation.coordinates[1] || 0,
            };

            // if all the values are valid
            if (pointA.lon && pointA.lat && pointB.lon && pointB.lat) {
              // get the distance
              const distanceFromVenue = Math.round(toMiles(haversineDistance(pointA, pointB)));

              if ((pointA.lon === -71 && pointA.lat === 25) || (pointB.lon === -71 && pointB.lat === 25)) {
                if (pv._id && row._id) {
                  violatorsFound.push({ id: pv._id, distance: -1 });
                  if (pv.violations === undefined) pv.violations = [];
                  pv.violations.push({ id: row._id, distance: -1 });
                  numberViolations += 1;
                }
              } else {
                // if the distance violates the rule, add the appropriate values
                const radiusInMiles = radiusUnit === 'miles' ? radius : toMiles(radius * 1000);
                if (distanceFromVenue <= radiusInMiles && pv._id && row._id) {
                  violatorsFound.push({ id: pv._id, distance: distanceFromVenue });
                  if (pv.violations === undefined) pv.violations = [];
                  pv.violations.push({ id: row._id, distance: distanceFromVenue });
                  numberViolations += 1;
                }
              }
            }
          });
        }
        row.violators = violatorsFound;
      }
    });

  return numberViolations;
};

export const createRoutingContextProvider = (ctx: Context<RoutingContextDef>): FC<PropsWithChildren> => {
  return ({ children }) => {
    const [tableRows, setTableRows] = useState<TableRow[] | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(moment().format('MM/DD/YYYY')));
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [violations, setViolations] = useState<ViolationsMap>({});
    const [numberViolations, setNumberViolations] = useState<number>(0);
    const [artistId, setArtistId] = useState('');
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
    const [showNewDealModal, setShowNewDealModal] = useState<{ date?: Date; isOpen: boolean }>({ isOpen: false });
    const [showRejectDealModal, setShowRejectDealModal] = useState<{ dealId?: string; isOpen: boolean }>({
      isOpen: false,
    });

    /**
     * Fetches all Shows/ Draft Shows for a given client
     *
     * fetch returns a complete calendar section including all show information as well as empty
     * dates information all formatted as TableRows.
     *
     * @param clientId - artist id whose show schedule is being fetched.
     * @param daysPrior
     * @param daysAfter
     */
    const fetchTableRows = async (clientId: string, daysPrior?: number, daysAfter?: number): Promise<void> => {
      let url = `/touring/routingTable/${clientId}/${selectedDate.getTime()}`;

      if (daysPrior && daysAfter) {
        url += `/${daysPrior}/${daysAfter}`;
      }

      await get(url).then((response) => {
        if (response.ok) {
          const { tableRows: newRows, violations: newViolations, selectedIndex: newIndex } = response.body;
          const rows: TableRow[] = [];
          normalizeRadiusClauses(newRows);
          const numViolations = captureViolations(newRows);
          setNumberViolations(numViolations);

          newRows.forEach((tableRow: TableRow) => rows.push(convertResponse(tableRow)));

          setTableRows(rows);
          setSelectedIndex(newIndex);

          setViolations(newViolations);
        } else {
          setTableRows(null);
          setViolations({});
          setSelectedIndex(0);
        }
      });
    };

    /**
     * Save column state onto user preference.
     */
    const saveColumnState = async (state: ColumnState[]): Promise<void> => {
      if (user && user._id) {
        await saveRoutingTableState(
          state.map(({ colId, hide }) => ({ colId, hide })),
          user._id
        );
      }
    };

    return (
      <ctx.Provider
        value={{
          user,
          setUser,
          tableRows,
          setTableRows,
          selectedDate,
          setSelectedDate,
          selectedIndex,
          setSelectedIndex,
          artistId,
          setArtistId,
          gridApi,
          setGridApi,
          columnApi,
          setColumnApi,
          violations,
          setViolations,
          numberViolations,
          setNumberViolations,
          // API
          fetchTableRows,
          saveColumnState,
          updateInProgress,
          setUpdateInProgress,
          showNewDealModal,
          setShowNewDealModal,
          showRejectDealModal,
          setShowRejectDealModal,
        }}
      >
        {children}
      </ctx.Provider>
    );
  };
};

export const RoutingContext = createContext(contextDef);
export const RoutingMapContext = createContext(contextDef);
export const RoutingContextProvider = createRoutingContextProvider(RoutingContext);
export const RoutingMapContextProvider = createRoutingContextProvider(RoutingMapContext);
