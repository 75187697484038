import { FC, PropsWithChildren, ReactNode } from 'react';
import { createPortal } from 'react-dom';

export interface iModalProps {
  title?: string;
  showModal: boolean;
  children?: ReactNode;
  className?: string;
  showClose?: boolean;
  contentStyle?: string;
  setShowModal?: (show: boolean) => void;
  dataCyModalTitle?: string;
}

export const Modal: FC<PropsWithChildren<iModalProps>> = ({
  title,
  showModal,
  children,
  className = '',
  showClose = false,
  contentStyle = '',
  setShowModal,
  dataCyModalTitle,
}) => {
  const rootElement =
    document.getElementById('root') ||
    document.querySelector('[data-cy-root]') ||
    document.getElementById('onyx-portal-root');

  return (
    rootElement &&
    createPortal(
      <>
        {showModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className={`relative w-auto my-6 mx-auto max-w-[500px] ${className}`}>
                {/*content*/}
                <div
                  className={`relative flex flex-col w-full bg-whiteWildSand outline-none focus:outline-none pt-[52px] pb-5 px-8 ${contentStyle}`}
                >
                  {/*header*/}
                  {title || showClose ? (
                    <div className="pb-4 flex items-start justify-center">
                      {title && (
                        <h4 className="font-extended text-2xl" data-cy={dataCyModalTitle}>
                          {title}
                        </h4>
                      )}
                      {showClose && (
                        <button
                          className="ml-auto text-black opacity-50 -mt-1 float-right text-3xl leading-none font-semibold outline-none hover:opacity-100 focus:outline-none"
                          onClick={() => {
                            if (setShowModal) setShowModal(false);
                          }}
                        >
                          <span className="text-black h-4 w-8 text-2xl">×</span>
                        </button>
                      )}
                    </div>
                  ) : null}
                  <div className="relative pb-4 flex-auto text-sm text-greyMineShaft">{children || null}</div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
      </>,
      rootElement
    )
  );
};
