import cx from 'classnames';
import { Avatar } from '@united-talent-agency/onyx-components';
import { isValidUrl } from 'utils/cloudinary';

export interface CustomRenderOptionProps {
  label: { name: string; type: string; profile_pic: string };
}

const CustomRenderOption = (props: CustomRenderOptionProps) => {
  const { label } = props;
  return (
    <div className="flex items-center gap-2 py-2.5 cursor-pointer">
      <Avatar
        src={label.profile_pic}
        size="sm"
        label={label.name
          ?.split(' ')
          .map((name: string) => name.charAt(0).toUpperCase())
          .join('')}
        className={cx('bg-[#BA9A1C]')}
        image={isValidUrl(label.profile_pic) ? true : false}
        bgColor={'#BA9A1C'}
      />
      <div>
        <p className="text-[14px] font-semibold leading-[18px]">{label.name}</p>
        <div className="flex gap-1">
          <span className={cx('w-3 leading-3 bg-[#BA9A1C] text-white text-[8px] text-center font-medium')}>{'C'}</span>
          <span className=" text-gray-500 text-[10px] leading-3">{label.type}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomRenderOption;
