import { useEffect, useMemo } from 'react';
import {
  FieldValues,
  FormState,
  RegisterOptions,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext,
} from 'react-hook-form';

import { DatePicker } from 'antd';
import { getDateFormatByBrowser } from 'utils/helpers';
import { findNestedErrorById } from '../../helpers/formHelpers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

dayjs.extend(utc);

export type THandleInputChange = (
  value?: string | Date | null,
  index?: number,
  setValueMethod?: UseFormSetValue<FieldValues>,
  getValues?: UseFormGetValues<FieldValues>,
  trigger?: UseFormTrigger<FieldValues>
) => void;

interface IInputProps {
  id: string;
  label?: string | boolean;
  className?: string;
  rules?: RegisterOptions;
  containerClassName?: string;
  /** Sets time to zero and defines the timezone as UTC, to simplify manipulations. */
  ignoreTime?: boolean;
  index?: number;
  placeholder?: string;
  disableIf?: {
    field: string;
    value: string | number | boolean;
  };
  disabled?: boolean;
  handleChange?: THandleInputChange;
  rulesCallback?: (formState: FormState<FieldValues>, index?: number) => RegisterOptions<FieldValues, string>;
  dataCy?: string;
}

export const DateInput = ({
  id,
  className = '',
  label = false,
  rules = {},
  ignoreTime,
  containerClassName = '',
  placeholder,
  index,
  disabled = false,
  disableIf,
  dataCy,
  handleChange,
  rulesCallback,
}: IInputProps) => {
  const {
    setValue,
    register,
    getValues,
    formState,
    resetField,
    formState: { errors },
    watch,
    trigger,
    clearErrors,
  } = useFormContext();

  const hasErrors = errors && !!findNestedErrorById(id, errors);
  const isRequired = rules && Object.keys(rules).includes('required');
  const { ref, name } = register(id, { ...(rulesCallback ? { ...rulesCallback(formState, index) } : rules) });

  // Clear errors when component unmounts
  useEffect(() => () => clearErrors(id), []);

  const dateValue: Date = useMemo(() => watch(id), [watch, id]);

  const disable =
    disabled || (disableIf && getValues(disableIf.field.replace('{index}', `${index}`)) === disableIf.value);

  const onDateChange = (_date: dayjs.Dayjs | null) => {
    let newDate = _date?.toDate();
    if (ignoreTime) {
      newDate = _date?.utc(true).startOf('day')?.toDate();
    }

    setValue(id, newDate, { shouldDirty: true });
    trigger(id);
    handleChange && handleChange(newDate, index, setValue, getValues, trigger);
  };

  const dateFormat = getDateFormatByBrowser();

  const defaultValue = useMemo(() => {
    if (dateValue) {
      if (ignoreTime) {
        return dayjs(dateValue).utc().startOf('day');
      } else {
        return dayjs(dateValue);
      }
    }
  }, [dateValue]);

  /**
   * If we are ignoring the time we need to ensure that the default value is ignoring it too.
   */
  useEffect(() => {
    if (!dateValue) {
      return;
    }

    const zeroTime = !!new Date(dateValue).toISOString().match('T00:00:00.000Z');
    if (!zeroTime && ignoreTime) {
      const newDefaultValue = dayjs(dateValue).utc(false).startOf('day')?.toDate();
      // we need to change the default to avoid sending the default with time.
      resetField(id, { defaultValue: newDefaultValue });
    }
  }, [dateValue, ignoreTime, id, resetField]);

  return (
    <div className={twMerge(clsx('form-field-wrapper', containerClassName))}>
      {label !== false && (
        <label
          className={`standard-label ${
            containerClassName && containerClassName.includes('flex-col') ? '!self-start' : ''
          }`}
        >
          {label} {isRequired ? <span className="text-cinnabar">*</span> : null}
        </label>
      )}
      <div className="relative w-full ">
        <DatePicker
          ref={ref}
          name={name}
          picker="date"
          className={twMerge(
            clsx(
              `h-10 border border-solid rounded-none border-black px-3 disabled:opacity-50 py-1 text-sm appearance-none peer w-full`,
              className,
              {
                'border-cinnabar': hasErrors,
              }
            )
          )}
          format={dateFormat}
          onChange={onDateChange}
          id={id}
          disabled={disable}
          placeholder={placeholder || ' '}
          aria-describedby={`${id}_error_help`}
          defaultValue={defaultValue}
          data-cy={dataCy}
        />
      </div>
    </div>
  );
};

DateInput.defaultProps = {
  className: '',
  label: false,
  rules: {},
  containerClassName: '',
  disabled: false,
};
