import { Helmet } from 'react-helmet-async';

import Button from '../../../components/Button';
import DocumentExport from '../../ui/icons/DocumentExportIcon';
import { useFilters } from 'context/avails-filters/context';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export interface PageHeaderProps {
  title: string;
  isAvails?: boolean;
  onExport?: () => void;
}

const PageHeader = ({ onExport = () => {}, title, isAvails = false }: PageHeaderProps) => {
  const { headerCollapsed, genresFocusedView, showTypesFocusedView } = useFilters();

  const containerDynamicClasses = useMemo(() => {
    if (isAvails) {
      if (genresFocusedView || showTypesFocusedView) {
        return 'lg:h-[160px] h-[160px]';
      }

      if (headerCollapsed) {
        return 'lg:h-[180px] h-[160px]';
      }

      return 'lg:h-[248px] h-[200px]';
    }

    return 'lg:h-[200px]';
  }, [isAvails, headerCollapsed, genresFocusedView, showTypesFocusedView]);

  const contentDynamicClasses = useMemo(() => {
    if (isAvails) {
      if (headerCollapsed) {
        return 'bg-availsBlur';
      }

      return 'bg-availsBlur';
    }

    return 'bg-cinematicBlur';
  }, [isAvails, headerCollapsed]);

  return (
    <div className={twMerge(containerDynamicClasses, 'w-full bg-black bg-center transition-all print:hidden')}>
      <Helmet>
        <title>Touring | {title}</title>
      </Helmet>
      <div
        className={twMerge(
          'w-full bg-center flex flex-col justify-end transition-all',
          containerDynamicClasses,
          contentDynamicClasses
        )}
      >
        <div
          className={`w-full header content-wrapper flex items-center justify-between flex-row ${
            isAvails ? 'mb-5 lg:mb-9' : 'mb-9'
          }`}
        >
          <header>
            <p className="text-2xl font-extended text-white uppercase self-end">{title}</p>
          </header>
          {!isAvails && (
            <Button rightIcon={<DocumentExport />} onClick={onExport}>
              <span className="hidden md:block">Export to CSV</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
