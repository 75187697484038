import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@kaizen/draft-tooltip';
import { useWindowWidth } from '@react-hook/window-size';
import { updateDeal, updateDealShows } from 'api';
import { handleUpdateDealShowsResponse, iUpdateShowPayload } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES, TICKETING_CURRENCIES } from 'deal-form/data/constants';
import {
  TICKET_FEES_EMPTY_TIER_ITEM,
  TICKET_FEES_FEETYPE_OPTIONS,
  TICKET_FEES_FEE_TYPE_OPTIONS,
  TICKET_SCALING_EMPTY_TIER_ITEM,
  TICKET_SCALING_TICKET_TIERS,
  TICKET_TAXES_CALCULATION,
  TICKET_TAXES_EMPTY_TIER_ITEM,
  TICKET_TAXES_MATH_TYPE,
} from 'deal-form/data/organisms';
import { Calculation } from 'deal-form/form-controls/Calculation';
import { Checkbox } from 'deal-form/form-controls/Checkbox';
import { formatDate, getShowFromTierId, getTicketCurrency } from 'deal-form/helpers/dealHelpers';
import { createTicketScalingCombinedPayload } from 'deal-form/helpers/submissionFormatting';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import {
  iCurrencyField,
  iOrganismTicket,
  iOrganismTicketFeesItem,
  iOrganismTicketScalingTier,
  iOrganismTicketTaxesItem,
} from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { Deal } from 'types';
import { tCurrency } from 'types/deal';
import { isWholeNumber } from 'utils/helpers';

import { Currency } from '../../form-controls/Currency';
import { Dropdown } from '../../form-controls/Dropdown';
import { DropdownWithStaticSearch } from '../../form-controls/DropdownWithStaticSearch';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { Label } from '../../form-controls/Label';
import { TextArea } from '../../form-controls/TextArea';
import Totals from '../../form-controls/Totals';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { TextField } from 'deal-form/form-controls/TextField';
import { EmptyOrganismMessage } from 'deal-form/ui/EmptyOrganismMessage';
import { useArtistCurrency } from 'deal-form/helpers/useArtistCurrency';
import cypressTags from 'support/cypressTags';
const { TICKET_SECTION } = cypressTags.DEAL_FORM;

export const TICKET_LIMIT = 50;

export const COLUMN_LABELS_SCALING = [
  { value: 'Show', isRequired: true },
  { value: 'Tier', isRequired: true },
  { value: 'Advance Price', isRequired: true },
  { value: 'Day of Price', isRequired: false },
  { value: 'Capacity', isRequired: true },
  { value: 'Comps', isRequired: true },
  { value: 'Sellable Capacity' },
  { value: 'Gross' },
];

export const COLUMN_LABELS_FEES = [
  { value: 'Fee Type', isRequired: true },
  { value: 'Fee Amount', isRequired: false },
  { value: 'Fee Percentage', isRequired: false },
  { value: 'Calculation', isRequired: true },
  { value: 'Gross' },
  { value: 'Description' },
];

export const COLUMN_LABELS_TAXES = [
  { value: 'Fee', isRequired: true },
  { value: 'Calculation', isRequired: true },
  { value: 'Math Type', isRequired: true },
  { value: 'Description' },
];

export const GRID_LAYOUT_SCALING = 'w-full grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr_0.5fr_80px_1fr] gap-2';
export const GRID_LAYOUT_SCALING_MOBILE = 'w-full';

export const GRID_LAYOUT_FEES = 'w-full grid grid-cols-[1.5fr_1.5fr_1fr_1fr_150px_2fr] gap-2 border-none';
export const GRID_LAYOUT_FEES_MOBILE = 'w-full';

export const GRID_LAYOUT_TAXES = 'w-full grid grid-cols-[1fr_1fr_1fr_2fr] gap-2 ';
export const GRID_LAYOUT_TAXES_MOBILE = 'w-full';

const formatEarlyLate = (earlyLate: string) => (earlyLate === 'Neither' ? 'Only Show' : earlyLate);

const formatTicketFeeId = (id: string, earlyLate: string, tier: string) =>
  `${id}|${earlyLate === 'Neither' ? 'Only Show' : earlyLate}|${tier}`;

const TicketScalingCombinedForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked, contract }) => {
  const [availableTierOptions, setAvailableTierOptions] = useState<iOptions[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string>(deal.currency);
  const [showDates, setShowDates] = useState<iOptions[]>([]);
  const [defaultValues, setDefaultValues] = useState<iOrganismTicket | null>(null);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [applyToAll, setApplyToAll] = useState<boolean>(false);
  const [isShowSettled, setIsShowSettled] = useState<boolean>(false);
  const [customScalingTierOptions, setCustomScalingTierOptions] = useState<iOptions[]>([]);
  const artistCurrency = useArtistCurrency(deal);
  const [currency, setCurrency] = useState<string>(getTicketCurrency(deal));
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    let ticketCurrency = getTicketCurrency(deal);
    deal.shows.forEach((show) => {
      if (show.ticketTiers.length > 0) {
        ticketCurrency = show.ticketTiers[0].currency;
      }
    });

    setCurrency(ticketCurrency);
  }, [deal]);

  const getTicketTiers = (applyAll: boolean) => {
    const scalingTiers: iOrganismTicketScalingTier[] = [];
    let taxesTiers: iOrganismTicketTaxesItem[] = [];
    let dealCurrency = deal.currency;
    //show only master show if tiers are applied to all shows and no shows are settled

    const customScalingTiers: iOptions[] = [];

    deal.shows.forEach((show) => {
      show.ticketTiers.forEach((tier) => {
        if (
          (deal.allShowsFlag === true && !isShowSettled && show.masterShowFlag) ||
          deal.allShowsFlag !== true ||
          isShowSettled
        ) {
          if (tier.currency !== dealCurrency) {
            dealCurrency = tier.currency as tCurrency;
          }

          // check to see if the tier is custom, if it is then it needs to be added to the list of available tiers
          if (
            TICKET_SCALING_TICKET_TIERS.find((t) => t.id === tier.tier) === undefined &&
            customScalingTiers.find((customScalingTier) => customScalingTier.id === tier.tier) === undefined
          ) {
            customScalingTiers.push({ id: tier.tier, label: tier.tier });
          }

          const scalingTier: iOrganismTicketScalingTier = {
            showId: show._id,
            tier: tier.tier,
            advance: {
              value: tier.advance,
              currency: tier.currency,
            },
            dayOf: {
              value: tier.dayOfShow,
              currency: tier.currency,
            },
            available: tier.available,
            comps: tier.comp,
            note: tier.note,
            sellable: 0,
            gross: 0,
            fees: [],
          };

          if ((applyAll && show.masterShowFlag === true) || !applyAll) {
            tier.fees.forEach(({ amount, description, type, percentFlag, percent }) => {
              const sellable = tier.available - tier.comp;
              const item: iOrganismTicketFeesItem = {
                calculation: type,
                description,
                feeType: percentFlag === true ? 'percentage' : 'flat',
                percent: percent || 0,
                fee: {
                  value: amount,
                  currency: dealCurrency,
                },
                tier: formatTicketFeeId(show._id, show.earlyLate, tier.tier),
                gross: {
                  value:
                    type === 'Built-In'
                      ? percentFlag && percent
                        ? sellable * ((+percent / 100) * tier.advance)
                        : +sellable * +amount
                      : -1, // this is the issue
                  currency: dealCurrency,
                },
              };
              if (scalingTier.fees) scalingTier.fees.push(item);
            });
          }
          scalingTiers.push(scalingTier);
        }

        if (((applyAll && show.masterShowFlag === true) || !applyAll) && !taxesTiers.length) {
          taxesTiers = tier.taxes.map(({ percent, note, type, beforeFee }) => {
            let calculatedGross = 0;

            deal.shows.forEach((showInner) => {
              showInner.ticketTiers.forEach((ticketInner) => {
                const tierGross = (ticketInner.available - ticketInner.comp) * ticketInner.advance;
                const sellable = ticketInner.available - ticketInner.comp;

                if (beforeFee) {
                  if (type === 'Multiplier') {
                    calculatedGross += tierGross * (percent / 100);
                  } else {
                    calculatedGross += tierGross - tierGross / (1 + percent / 100);
                  }
                } else {
                  let feeTotal = ticketInner.fees.reduce(
                    (acc, fee) => (fee.type === 'Built-In' ? Number(fee.amount) + acc : acc),
                    0
                  );
                  feeTotal *= sellable;
                  if (type === 'Multiplier') {
                    calculatedGross += (tierGross - feeTotal) * (percent / 100);
                  } else {
                    calculatedGross += tierGross - feeTotal - (tierGross - feeTotal) / (1 + percent / 100);
                  }
                }
              });
            });

            const tax: iOrganismTicketTaxesItem = {
              tier: '',
              calculation: beforeFee ? 'true' : 'false',
              description: note,
              mathType: type || '',
              fee: percent || 0,
              gross: {
                value: calculatedGross, //type === 'Built-In' ? +tierGross + +amount : +tierGross,
                currency: dealCurrency,
              },
            };

            return tax;
          });
        }
      });
    });

    setCustomScalingTierOptions(customScalingTiers);
    setSelectedCurrency(dealCurrency);
    setApplyToAll(deal.allShowsFlag === true);

    return {
      currency: dealCurrency,
      taxesTiers,
      scalingTiers,
      ticketScalingNotes: deal.ticketScalingNotes || '',
      allShowsFlag: deal.allShowsFlag === true,
    };
  };

  useEffect(() => {
    // const applyAllScaling = (deal?.allShowsFlag && deal.shows.length > 1) || false;
    const applyAll = deal?.allShowsFlag || false;
    const isShowSettledVal = deal.shows.some((show) => show.status === 'Settled');

    setIsShowSettled(isShowSettledVal);
    setApplyToAll(applyAll && !isShowSettledVal);
    setDefaultValues(getTicketTiers(applyAll));

    const dateShows = deal.shows
      .sort(function (a, b) {
        if (new Date(a.date) === new Date(b.date)) {
          a.earlyLate === 'Early' ? 1 : -1;
        }

        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .map((show) => {
        return {
          id: show._id,
          label: `${formatDate(show.date, false)} - ${show.earlyLate === 'Neither' ? 'Only Show' : show.earlyLate}`,
          disabled: show.status === 'Settled',
        };
      });

    const dealsToShowTaxes =
      applyAll && !isShowSettledVal ? deal.shows.filter((s) => s.masterShowFlag === true) : deal.shows;

    const options: iOptions[] = [];

    dealsToShowTaxes.forEach((show) => {
      show.ticketTiers.forEach((tier) => {
        if ((applyAll && !isShowSettledVal && show.masterShowFlag === true) || !applyAll || isShowSettledVal) {
          const option: iOptions = {
            id: formatTicketFeeId(show._id, show.earlyLate, tier.tier),
            label: `${applyAll ? 'All Shows' : formatDate(show.date, false)} ${
              applyAll ? '–' : `(${formatEarlyLate(show.earlyLate)})`
            }\n${tier.tier}`,
            disabled: show.status === 'Settled',
          };
          options.push(option);
        }
      });
    });

    setShowDates(dateShows);
    setAvailableTierOptions(options);
  }, [deal]);

  const handleAddCustomOption = (newOption: string) => {
    setCustomScalingTierOptions([...customScalingTierOptions, { id: newOption, label: newOption, data: {} }]);
  };

  const onSubmit = async (data: iOrganismTicket) => {
    // TODO: would there be a benefit in getting just the show or tier that was impacted by the change?
    // TODO: should we perform any additional data validation here?
    // ticket types for specific shows are meant to be unique (cant have two General Admission tiers on the same date)
    let updateData: iUpdateShowPayload[] = [];

    // If there're still tiers in the data
    // Add/update/remove tiers
    if (data.scalingTiers && data.scalingTiers.length) {
      const lastIndex = data.scalingTiers.length - 1;

      if (applyToAll && !isShowSettled) {
        updateData = deal.shows.map((s) =>
          createTicketScalingCombinedPayload(s, data.currency, data.scalingTiers, data.taxesTiers)
        );
      } else if (data.scalingTiers[lastIndex].showId && data.scalingTiers[lastIndex].tier) {
        // get the show id
        deal.shows.forEach((show) => {
          // get the show date
          const showId = show._id;
          // get the tiers associated with that show
          const scalingTiers = data.scalingTiers.filter((tierData) => tierData.showId === showId);
          // add the show to the query
          const payload = createTicketScalingCombinedPayload(show, data.currency, scalingTiers, data.taxesTiers);
          updateData.push(payload);
        });
      }
      // If there're no tiers in the data
      // Remove all ticketTiers from all shows
    } else {
      updateData = deal.shows.map((s) => createTicketScalingCombinedPayload(s, data.currency, [], []));
    }

    if (!updateData.length) return;

    try {
      setCanSubmit(false);
      const updatedShowsResponse = await updateDealShows({ dealId: deal._id.toString(), updates: updateData });

      const updatedDeal = await updateDeal(deal._id.toString(), {
        ...(updatedShowsResponse.body as Deal),
        ticketScalingNotes: data.ticketScalingNotes || '',
        allShowsFlag: applyToAll && !isShowSettled,
      });

      if (setDeal) {
        handleUpdateDealShowsResponse(updatedShowsResponse, { deal: updatedDeal, setDeal });
      }

      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.error(e);
      setCanSubmit(true);
    }
  };

  const handleChangeCurrency = (chosen: iOptions | null) => {
    if (chosen) {
      setSelectedCurrency(chosen.id.toUpperCase());
    }
  };

  const emptyTierItemWithCurrency = useMemo(() => {
    const grossWithCurrency = {
      ...TICKET_TAXES_EMPTY_TIER_ITEM.gross,
      currency: getTicketCurrency(deal),
    };
    return { ...TICKET_TAXES_EMPTY_TIER_ITEM, gross: grossWithCurrency };
  }, [deal]);

  const handleFeeChange = (
    val: iCurrencyField,
    index?: number,
    setValue?: UseFormSetValue<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>,
    _trigger?: UseFormTrigger<FieldValues>,
    parentindex?: number
  ) => {
    if (setValue) {
      const calc = getValues ? getValues(`scalingTiers.${parentindex}.fees.${index}.calculation`) : '';
      const tier = getValues ? getValues(`scalingTiers.${parentindex}.fees.${index}.tier`) : '';
      const showDetails = getShowFromTierId(tier);

      const showFromId = deal.shows.find((s) => s._id === showDetails.showId);
      const tierFromShow = showFromId?.ticketTiers.find((ticketTier) => ticketTier.tier === showDetails.tierLabel);

      if (tierFromShow) {
        // const tierGross = (tierFromShow?.available - tierFromShow?.comp) * tierFromShow.advance;
        const sellable = tierFromShow?.available - tierFromShow?.comp;
        if (calc === 'Built-In') {
          setValue(`scalingTiers.${parentindex}.fees.${index}.gross.value`, sellable * +val.value);
          setValue(`scalingTiers.${parentindex}.fees.${index}.gross.currency`, tierFromShow.currency);
        } else if (calc === 'On-Top') {
          setValue(`scalingTiers.${parentindex}.fees.${index}.gross.value`, -1);
          setValue(`scalingTiers.${parentindex}.fees.${index}.gross.currency`, tierFromShow.currency);
        }
      }
    }
  };

  const handleFeePercentChange = (
    val: string,
    index?: number,
    setValue?: UseFormSetValue<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>,
    _trigger?: UseFormTrigger<FieldValues>,
    parentindex?: number
  ) => {
    if (setValue) {
      const calc = getValues ? getValues(`scalingTiers.${parentindex}.fees.${index}.calculation`) : '';
      const tier = getValues ? getValues(`scalingTiers.${parentindex}.fees.${index}.tier`) : '';
      const showDetails = getShowFromTierId(tier);

      const showFromId = deal.shows.find((s) => s._id === showDetails.showId);
      const tierFromShow = showFromId?.ticketTiers.find((ticketTier) => ticketTier.tier === showDetails.tierLabel);

      if (tierFromShow) {
        // const tierGross = (tierFromShow?.available - tierFromShow?.comp) * tierFromShow.advance;
        const sellable = tierFromShow?.available - tierFromShow?.comp;

        if (calc === 'Built-In') {
          setValue(
            `scalingTiers.${parentindex}.fees.${index}.gross.value`,
            sellable * ((+val / 100) * tierFromShow.advance)
          );
          setValue(`scalingTiers.${parentindex}.fees.${index}.gross.currency`, tierFromShow.currency);
        } else if (calc === 'On-Top') {
          setValue(`scalingTiers.${parentindex}.fees.${index}.gross.value`, -1);
          setValue(`scalingTiers.${parentindex}.fees.${index}.gross.currency`, tierFromShow.currency);
        }
      }
    }
  };

  return (
    defaultValues && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className="form-row full-width"
        defaultValues={defaultValues}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.TICKET_SCALING}
        // isSaveButton={!!availableTierOptions.length} this line comes from TicketTaxesForm
      >
        {/* <div> Use this default currency: {defaultCurrency.label}</div> */}
        <div className=" flex">
          <Dropdown
            label="Ticketing Currency"
            placeholder="Currency"
            id="currency"
            options={TICKETING_CURRENCIES}
            disabled={!isOpen || locked || contract?.status === 'Contract Issued'}
            handleChange={handleChangeCurrency}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            containerClassName="md:max-w-xs"
          />
          {deal.shows.length > 1 && (
            <div className="ml-10 flex">
              <Checkbox
                containerClassName="pr-2"
                id="allShowsFlag"
                checkColor="black"
                label="Apply tiers to all shows"
                disabled={isShowSettled || !isOpen || locked}
                onHandleChange={(checked) => setApplyToAll(checked)}
              ></Checkbox>
              <Tooltip
                position="right"
                text="Applying tiers to all shows also applies Ticket Taxes and Ticket Fees to all shows. Apply to all shows is not available for deals with settled shows."
              >
                <InformationCircleIcon className="w-5 h-5 text-greyCloudy" />
              </Tooltip>
            </div>
          )}
        </div>
        <FieldArray
          gridClassName={onlyWidth > 1200 ? GRID_LAYOUT_SCALING : GRID_LAYOUT_SCALING_MOBILE}
          groupName="scalingTiers"
          columns={COLUMN_LABELS_SCALING}
          addButtonLabel="Add Tier"
          emptyRow={TICKET_SCALING_EMPTY_TIER_ITEM}
          disabled={!isOpen || locked}
          columnLabelsType="repeats"
          lockRules={(field) => {
            const rule = {
              isLocked: false,
              fields: [] as string[],
            };

            // if a show status is Settled it should not be updated any more
            if (deal.shows.find((s) => s._id === field.showId)?.status === 'Settled') {
              rule.isLocked = true;
              rule.fields = ['showId', 'tier', 'advance', 'dayOf', 'available', 'comps', 'note', 'nestedFieldArray'];
            }

            return rule;
          }}
        >
          {applyToAll && !isShowSettled ? (
            <p className="text-sm px-4 leading-10">All Shows</p>
          ) : (
            <Dropdown
              placeholder="Show"
              id="showId"
              options={showDates}
              disabled={!isOpen || locked}
              handleChange={(_chosen, _setValue, _index, trigger) => {
                if (trigger) {
                  trigger();
                }
              }}
              // TODO: This won't be needed when we merge the Dropdown's `rules` and `rulesCallback`
              hideClearButton
              dataCy={TICKET_SECTION.SHOW_DROPDOWN}
            />
          )}
          <DropdownWithStaticSearch
            placeholder="Tier"
            disabled={!isOpen || locked}
            id="tier"
            isRequired={true}
            label={false}
            maxLength={32}
            addCustomOption={handleAddCustomOption}
            customOptions={customScalingTierOptions}
            staticOptions={TICKET_SCALING_TICKET_TIERS}
            handleChange={(_chosen, _setValue, _index, trigger) => {
              if (trigger) {
                trigger();
              }
            }}
            customOptionLabel="Create Custom Tier"
            dataCy={TICKET_SECTION.TIER_DROPDOWN}
          />

          <Currency
            placeholder="Advance"
            id="advance"
            overrideCurrency={selectedCurrency}
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL,
              min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
            }}
            dataCy={TICKET_SECTION.ADVANCE_INPUT}
          />
          <Currency
            placeholder="Day of"
            id="dayOf"
            overrideCurrency={selectedCurrency}
            disabled={!isOpen || locked}
            rules={{
              min: { value: 0.0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
            }}
            dataCy={TICKET_SECTION.DAY_OF_INPUT}
          />
          <Input
            placeholder="Capacity"
            fieldType="number"
            id="available"
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.WHOLE_NUMBER,
              min: { value: 1, message: ERROR_MESSAGES.WHOLE_NUMBER },
              validate: (value) => isWholeNumber(value) || ERROR_MESSAGES.WHOLE_NUMBER,
            }}
            dataCy={TICKET_SECTION.TICKET_CAPACITY_INPUT}
          />
          <Input
            placeholder="Comps"
            fieldType="number"
            id="comps"
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.WHOLE_NUMBER,
              min: { value: 0, message: ERROR_MESSAGES.WHOLE_NUMBER },
              validate: (value) => isWholeNumber(value) || ERROR_MESSAGES.WHOLE_NUMBER,
            }}
            dataCy={TICKET_SECTION.COMP_INPUT}
          />
          <Calculation
            id="sellable"
            containerClassName={onlyWidth > 1200 ? '' : 'horizontal-calcultation'}
            equation={[{ type: 'subtract', fields: ['scalingTiers.index.available', 'scalingTiers.index.comps'] }]}
            dataCy={TICKET_SECTION.TICKET_SELLABLE_CAPACITY}
          />
          <Calculation
            id="gross"
            containerClassName={onlyWidth > 1200 ? '' : 'horizontal-calcultation'}
            equation={[
              { type: 'multiply', fields: ['scalingTiers.index.sellable', 'scalingTiers.index.advance.value'] },
            ]}
            currency={selectedCurrency}
            dataCy={TICKET_SECTION.TICKET_GROSS}
          />
          <FieldArray
            id="nestedFieldArray"
            gridClassName={onlyWidth > 1200 ? GRID_LAYOUT_FEES : GRID_LAYOUT_FEES_MOBILE}
            className="col-span-full"
            groupName="scalingTiers.{index}.fees"
            isNested
            columns={availableTierOptions.length > 0 ? COLUMN_LABELS_FEES : undefined}
            addButtonLabel="Add Fee"
            emptyRow={TICKET_FEES_EMPTY_TIER_ITEM}
            showDivider={false}
            disabled={!isOpen || locked}
            disableIf={{ field: 'scalingTiers.{index}.isNew', value: true }}
            disableMessage="Please save the Ticket Scaling tier to edit Ticket Fees."
            columnLabelsType="repeats"
            lockRules={(field) => {
              const rule = {
                isLocked: false,
                fields: [] as string[],
              };

              // if a show status is Settled it should not be updated any more
              if (isShowSettled) {
                rule.isLocked = true;
                rule.fields = ['fee', 'calculation', 'description', 'feeType'];
              }

              if (field.isMasterShow) {
                rule.isLocked = true;
              }

              return rule;
            }}
          >
            <Dropdown
              placeholder="Fee Type"
              id="feeType"
              options={TICKET_FEES_FEETYPE_OPTIONS}
              disabled={!isOpen || locked}
              rules={{
                required: ERROR_MESSAGES.REQUIRED_FIELDS,
              }}
            />

            <Currency
              placeholder="Fee"
              id="fee"
              rules={{
                min: { value: 0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
              }}
              overrideCurrency={getTicketCurrency(deal)}
              handleChange={handleFeeChange}
              disabled={!isOpen || locked}
              disableIf={{ field: 'scalingTiers.{parentindex}.fees.{index}.feeType', value: 'percentage' }}
            />

            <Input
              label={false}
              fieldType="percent"
              rules={{
                min: { value: 0, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
                max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
              }}
              id="percent"
              handleChange={handleFeePercentChange}
              step="0.1"
              disabled={!isOpen || locked}
              disableIf={{ field: 'scalingTiers.{parentindex}.fees.{index}.feeType', value: 'flat' }}
            />

            <Dropdown
              placeholder="Calculation"
              id="calculation"
              options={TICKET_FEES_FEE_TYPE_OPTIONS}
              disabled={!isOpen || locked}
              rules={{
                required: 'Please select a valid ‘Calculation’ type',
              }}
            />

            <TextField text="--" id="gross" isCurrency className="leading-10" />

            <TextArea
              placeholder="Type description here."
              rules={{
                maxLength: {
                  value: 1024,
                  message: getMaxCharMessage('the description', 1024),
                },
              }}
              id="description"
              rows={1}
              disabled={!isOpen || locked}
            />
          </FieldArray>
          <TextArea
            placeholder="Type description here."
            rules={{
              maxLength: {
                value: 1024,
                message: getMaxCharMessage('the description', 1024),
              },
            }}
            id="note"
            label="Description"
            containerClassName="col-span-full text-description"
            rows={2}
            disabled={!isOpen || locked}
          />
        </FieldArray>

        <div className={`${onlyWidth > 1200 ? GRID_LAYOUT_SCALING : GRID_LAYOUT_SCALING_MOBILE} pr-10`}>
          <label className="form-label col-start-4 self-end">Total</label>
          <Totals
            id="scalingTiers"
            total="available"
            type="number"
            identifier="Capacity"
            label={false}
            multiplier={applyToAll ? deal.shows.length : undefined}
            dataCy={TICKET_SECTION.TOTAL_CAPACITY}
          />
          <Totals
            id="scalingTiers"
            total="comps"
            type="number"
            identifier="Comps"
            label={false}
            multiplier={applyToAll ? deal.shows.length : undefined}
            dataCy={TICKET_SECTION.TOTAL_COMPS}
          />
          <Totals
            id="scalingTiers"
            total="sellable"
            type="number"
            identifier="Sellable Capacity"
            label={false}
            multiplier={applyToAll ? deal.shows.length : undefined}
            dataCy={TICKET_SECTION.TOTAL_SELLABLE}
          />
          <Totals
            id="scalingTiers"
            total="gross"
            identifier="Gross"
            label={false}
            currencyOverride={selectedCurrency}
            multiplier={applyToAll ? deal.shows.length : undefined}
            convertFrom={currency}
            convertTo={artistCurrency?.id || currency}
            exchangeRates={contract?.exchangeRates}
            contractId={contract?._id}
            dataCy={TICKET_SECTION.TOTAL_GROSS}
          />
        </div>

        <div className="w-full pb-[15px] pt-[20px] border-b margin-b mb-[30px] border-t border-greyCloudy">
          <Label className=" self-start col-span-1 row-start-2" htmlFor="ticketScalingNotes">
            Ticketing Notes
          </Label>
          <TextArea
            containerClassName="col-span-5 row-start-2"
            disabled={!isOpen || locked}
            id="ticketScalingNotes"
            label={false}
            placeholder="Type notes here."
            rows={4}
            rules={{
              maxLength: {
                value: 2000,
                message: getMaxCharMessage('your notes', 2000),
              },
            }}
          />
        </div>

        <div>{FORM_NAMES.TICKET_TAXES_COMBINED}</div>

        {availableTierOptions.length > 0 ? (
          <>
            <FieldArray
              gridClassName={onlyWidth > 1200 ? GRID_LAYOUT_TAXES : GRID_LAYOUT_TAXES_MOBILE}
              groupName="taxesTiers"
              columns={availableTierOptions.length > 0 ? COLUMN_LABELS_TAXES : undefined}
              addButtonLabel="Add Taxes"
              showDivider={false}
              emptyRow={emptyTierItemWithCurrency}
              disabled={!isOpen || locked}
              columnLabelsType="repeats"
              lockRules={() => {
                const rule = {
                  isLocked: false,
                  fields: [] as string[],
                };

                // if a show status is Settled it should not be updated any more
                if (isShowSettled) {
                  rule.isLocked = true;
                  rule.fields = ['percent', 'calculation', 'mathType', 'description', 'fee'];
                }

                return rule;
              }}
            >
              <Input
                placeholder="Fee"
                fieldType="percent"
                id="fee"
                step="0.01"
                rules={{
                  required: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL,
                }}
                disabled={!isOpen || locked}
              />

              <Dropdown
                placeholder="Calculation"
                id="calculation"
                options={TICKET_TAXES_CALCULATION}
                disabled={!isOpen || locked}
                rules={{ required: 'A ‘Calculation’ selection is required' }}
              />

              <Dropdown
                placeholder="Math Type"
                id="mathType"
                options={TICKET_TAXES_MATH_TYPE}
                disabled={!isOpen || locked}
                rules={{ required: 'A ‘Math Type’ selection is required' }}
              />

              <TextArea
                placeholder="Type description here."
                rules={{
                  maxLength: {
                    value: 1024,
                    message: getMaxCharMessage('the description', 1024),
                  },
                }}
                id="description"
                rows={1}
                disabled={!isOpen || locked}
              />
            </FieldArray>
            <div className={`${onlyWidth > 1200 ? GRID_LAYOUT_TAXES : GRID_LAYOUT_TAXES_MOBILE} pr-10`}>
              <label className="form-label col-start-3 self-end">Total</label>
              <Totals
                id="taxesTiers"
                total="gross"
                identifier="Tax Amount"
                label={false}
                multiplier={applyToAll ? deal.shows.length : undefined}
                convertFrom={currency}
                convertTo={artistCurrency?.id || currency}
                exchangeRates={contract?.exchangeRates}
                contractId={contract?._id}
              />
            </div>
          </>
        ) : (
          <EmptyOrganismMessage>Please save a Ticket Scaling tier to edit Ticket Taxes.</EmptyOrganismMessage>
        )}
      </Form>
    )
  );
};

export default TicketScalingCombinedForm;
