import { updateDeal } from 'api';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  BUYOUTS_EMPTY_FORM,
  BUYOUTS_EMPTY_PAYMENT_ITEM,
  BUYOUTS_PAYMENT_TO,
  BUYOUTS_PAYMENT_TYPE,
} from 'deal-form/data/organisms';
import { formatDate } from 'deal-form/helpers/dealHelpers';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismBuyouts } from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { Buyout } from 'types';
import { getDateFormatByBrowser } from 'utils/helpers';

import { DateInput } from 'deal-form/form-controls/DateInput';
import moment from 'moment';
import { Currency } from '../../form-controls/Currency';
import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';
import Totals from '../../form-controls/Totals';
import { formatTotals } from '../../helpers/dealHelpers';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { useArtistCurrency } from '../../helpers/useArtistCurrency';
import submitToNetsuite from 'utils/submitToNetsuite';
import cypressTags from 'support/cypressTags';

const { BUYOUTS } = cypressTags.DEAL_FORM;
export const COLUMN_LABELS = [
  { value: 'Show', isRequired: true },
  { value: 'Type', isRequired: true },
  { value: 'Amount Due', isRequired: true },
  { value: 'Payment To', isRequired: true },
  { value: 'Due Date', isRequired: true },
  { value: 'Description' },
];
export const GRID_LAYOUT = 'w-full grid grid-cols-[1fr_1fr_1fr_1fr_1fr_3fr] gap-2';

const BuyoutsForm: React.FC<iFormProps> = ({ calculations, deal, setDeal, isOpen, locked, contract }) => {
  const artistCurrency = useArtistCurrency(deal);

  // States
  const [defaultValues, setDefaultValues] = useState<iOrganismBuyouts | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [showDates, setShowDates] = useState<iOptions[]>([]);

  const onSubmit = async (data: iOrganismBuyouts) => {
    const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';
    // type, amount, received, payee, note, currency
    try {
      const updatedBuyouts: Buyout[] = [];

      if (data.buyouts.length > 0) {
        data.buyouts.forEach(({ amountDue, dateReceived, description, paymentTo, type, showId }) => {
          if (type && type !== '' && amountDue.value && paymentTo && paymentTo !== '') {
            const buyoutObject: Buyout = {
              amount: amountDue.value,
              currency: (amountDue?.currency as unknown as Buyout['currency']) || artistCurrency?.id || 'USD',
              note: description,
              payee: paymentTo as unknown as Buyout['payee'],
              type,
              received: moment(dateReceived).utc().toDate(),
              showId,
            };

            if (buyoutObject.amount && buyoutObject.currency && buyoutObject.payee && buyoutObject.type)
              updatedBuyouts.push(buyoutObject);
          } else {
            throw new Error();
          }
        });
      }

      setCanSubmit(false);
      const updatedDeal = await updateDeal(deal._id, {
        ...deal,
        buyouts: updatedBuyouts,
      });

      if (updatedDeal && setDeal) {
        setDeal(updatedDeal);
      }

      if (isDealSubmittedToNetsuite) {
        submitToNetsuite(contract, deal, false);
      }

      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR updated buyouts', e);
      setCanSubmit(true);
    }
  };

  const emptyBuyoutsPaymentItem = useMemo(
    () => ({
      ...BUYOUTS_EMPTY_PAYMENT_ITEM,
      amountDue: {
        value: 0,
        currency: artistCurrency?.id || 'USD',
      },
    }),
    [artistCurrency]
  );

  useEffect(() => {
    const expectedBuyoutsTotal = formatTotals(calculations?.deals[deal._id]?.expectedBuyoutsTotal);
    const masterShow = deal.shows.find((show) => show.masterShowFlag);

    if (deal.buyouts.length > 0) {
      setDefaultValues({
        buyouts: deal.buyouts.map(({ showId, amount, type, received, payee, note, currency }) => {
          const transformedPayee = {
            Agency: 'UTA',
            Agent: 'UTA',
            Client: 'Artist',
          };
          return {
            showId: showId || masterShow?._id,
            amountDue: {
              value: amount,
              currency: currency || artistCurrency?.id || 'USD',
            },
            type,
            paymentTo: transformedPayee[payee] || payee || '',
            dateReceived: moment(received).utc().toDate(),
            description: note,
          };
        }),
        totalBuyouts: expectedBuyoutsTotal,
      });
    } else {
      setDefaultValues(BUYOUTS_EMPTY_FORM);
    }

    const dateShows = deal.shows
      .sort(function (a, b) {
        if (new Date(a.date) === new Date(b.date)) {
          a.earlyLate === 'Early' ? 1 : -1;
        }

        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .map((show) => {
        return {
          id: show._id,
          label: `${formatDate(show.date, false)} - ${show.earlyLate === 'Neither' ? 'Only Show' : show.earlyLate}`,
          disabled: show.status === 'Settled',
        };
      });
    // setApplyToAll(applyAll);

    setShowDates(dateShows);
  }, [contract, deal, calculations]);

  return (
    defaultValues && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className="form-row full-width"
        defaultValues={defaultValues}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.BUYOUTS}
      >
        <FieldArray
          gridClassName={GRID_LAYOUT}
          groupName="buyouts"
          columns={COLUMN_LABELS}
          addButtonLabel="Add Buyout"
          emptyRow={emptyBuyoutsPaymentItem}
          disabled={!isOpen || locked}
          lockRules={(field) => {
            const rule = {
              isLocked: false,
              fields: [] as string[],
            };

            const fieldShowId = field.showId;

            const show = deal.shows.find((s) => s._id === fieldShowId);

            // // // if a show status is Settled it should not be updated any more
            if (show && show?.status === 'Settled') {
              rule.isLocked = true;
              rule.fields = ['type', 'showId', 'amountDue', 'paymentTo', 'description', 'dateReceived'];
            }

            if (field.isMasterShow) {
              rule.isLocked = true;
            }

            return rule;
          }}
        >
          <Dropdown
            placeholder="Show"
            id="showId"
            options={showDates}
            disabled={!isOpen || locked}
            rulesCallback={() => ({
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            })}
            handleChange={(_chosen, _setValue, _index, trigger) => {
              if (trigger) {
                trigger();
              }
            }}
            hideClearButton
            dataCy={BUYOUTS.SHOW_DROPDOWN}
          />
          <Dropdown
            placeholder="Type"
            id="type"
            options={BUYOUTS_PAYMENT_TYPE}
            disabled={!isOpen || locked}
            rules={{ required: "Please select a valid 'Type' option" }}
            dataCy={BUYOUTS.TYPE_DROPDOWN}
          />
          <Currency
            rules={{
              min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
            }}
            id="amountDue"
            dealCurrency={artistCurrency}
            disabled={!isOpen || locked}
            dataCy={BUYOUTS.AMOUNT_DUE}
          />
          <Dropdown
            placeholder="Payment to"
            id="paymentTo"
            options={BUYOUTS_PAYMENT_TO}
            disabled={!isOpen || locked}
            rules={{ required: 'Please select a valid ‘Payment to’ option' }}
            dataCy={BUYOUTS.PAYMENT_TO_DROPDOWN}
          />
          <DateInput
            id="dateReceived"
            ignoreTime
            placeholder="Due Date"
            disabled={!isOpen || locked}
            rules={{ required: `Please select a valid date (${getDateFormatByBrowser()})` }}
            dataCy={BUYOUTS.DATE_INPUT}
          />
          <TextArea
            placeholder="Type description here."
            rules={{
              maxLength: {
                value: 1024,
                message: getMaxCharMessage('the description', 1024),
              },
            }}
            id="description"
            rows={1}
            disabled={!isOpen || locked}
            dataCy={BUYOUTS.DESCRIPTION_INPUT}
          />
        </FieldArray>
        <div className={GRID_LAYOUT}>
          <label className="form-label self-end">Totals</label>
          <Totals
            id="totalBuyouts"
            total="value"
            type="currency"
            identifier="Amount Due"
            label={false}
            dataCy={BUYOUTS.TOTAL_AMOUNT}
          />
        </div>
      </Form>
    )
  );
};

export default BuyoutsForm;
