export const transformObject = (inputObj: any) => {
  const result = [];
  for (const fromCurrency in inputObj) {
    const toCurrencies = inputObj[fromCurrency];
    for (const toCurrency in toCurrencies) {
      const exchangeRateFrom = { currency: fromCurrency, value: 1 };
      const exchangeRateTo = {
        currency: toCurrency,
        value: toCurrencies[toCurrency],
      };
      result.push({ exchangeRateFrom, exchangeRateTo });
    }
  }
  return result;
};

export const organizeExchangeRates = (arrayOfRates: any) => {
  const organizedRates: any = {};

  // Loop through the array of exchange rate objects
  arrayOfRates.forEach((exchangeRate: any) => {
    const { currency: fromCurrency } = exchangeRate.exchangeRateFrom;
    const { currency: toCurrency, value: toValue } = exchangeRate.exchangeRateTo;
    // Create an object for the fromCurrency if it doesn't exist
    // ts=ignore
    if (!organizedRates[fromCurrency]) {
      organizedRates[fromCurrency] = {};
    }

    // Assign the exchange rate to the corresponding currency pair
    organizedRates[fromCurrency][toCurrency] = parseFloat(toValue);
  });

  return organizedRates;
};
