import { MobileSideDrawer, Tooltip } from '@united-talent-agency/onyx-components';
import { Error as ErrorIcon } from '@united-talent-agency/onyx-components/Icons';
import clsx from 'clsx';
import MobileDrawerHeader from 'components/MobileDrawerHeader';
import { BodyCellFullProps } from 'components/Table/Body/Cell/Full';
import { FC, useCallback, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import MoreButton from './MoreButton';
import { LocationType } from '../types';
import CellContent from './CellContent';

type CellContentWithMoreProps = BodyCellFullProps<LocationType>;

const CellContentWithMore: FC<CellContentWithMoreProps> = ({ column }) => {
  const [actionsOpened, setActionsOpened] = useState<boolean>(false);

  const dataLength = useMemo(() => (Array.isArray(column?.data) ? column?.data?.length : 0), [column?.data]);
  const hasMore = useMemo(() => dataLength > 1, [dataLength]);

  const handleToggleActions = useCallback(() => {
    setActionsOpened(!actionsOpened);
  }, [actionsOpened]);

  const handleOnClickMoreButton = useCallback(() => {
    setActionsOpened(true);
  }, []);

  return (
    <div className="flex flex-1 flex-col gap-1 w-0 min-w-full max-w-full">
      <CellContent {...column} data={column?.data?.[0]} />
      {hasMore && (
        <div className="flex items-center justify-center w-full print:hidden">
          <div className="hidden md:flex">
            <Tooltip placement="right">
              <Tooltip.Trigger>
                <MoreButton dataLength={dataLength} />
              </Tooltip.Trigger>
              <Tooltip.Content className="[&&]:p-0 shadow-[3px_2px_27px_0_rgba(0,0,0,0.07),_0_2px_4px_0_rgba(0,0,0,0.25)] [&&>svg]:[paint-order:stroke]">
                <div className="max-h-[405px] md:max-h-[525px] divide-y-2 divide-[#F5F5F5] overflow-auto">
                  {column?.data?.map(
                    (data, index) =>
                      index !== 0 && (
                        <div
                          key={index}
                          className="group/table-td flex flex-row flex-1 items-center h-[135px] min-w-[150px] max-h-full max-w-full md:h-[105px] md:min-w-[218px] print:bg-transparent print:min-w-[180px] print:md:min-w-[180px]"
                        >
                          <CellContent data={data} />
                        </div>
                      )
                  )}
                </div>
              </Tooltip.Content>
            </Tooltip>
          </div>
          <div className="flex md:hidden">
            <MoreButton dataLength={dataLength} onClick={handleOnClickMoreButton} />
            <MobileSideDrawer
              isOpen={actionsOpened}
              variant="full"
              className="!max-h-[60%] !h-auto"
              header={() => <MobileDrawerHeader onClose={handleToggleActions} />}
              onClose={handleToggleActions}
            >
              <div className="divide-y divide-[#F5F5F5]">
                {column?.data?.map(
                  (data, index) =>
                    index !== 0 && (
                      <div
                        key={data?.label}
                        className={twMerge(
                          clsx(
                            'flex flex-col gap-2 py-8',
                            // Cell Root
                            'min-h-0 h-auto',
                            '[&>div]:items-stretch',
                            // Cell Content - Content
                            '[&_.cell-container]:px-0 [&_.cell-container]:items-stretch [&_.cell-container]:flex-row [&_.cell-container]:gap-8 [&_.cell-container]:text-left [&_.cell-container]:justify-between',
                            // Cell Content - Content - children
                            '[&_.cell-container>div:first-child]:flex-[3] [&_.cell-container>div:nth-child(2)]:flex-[2] [&_.cell-container>div:nth-child(2)]:justify-start',
                            {
                              'pb-4': !!data?.clauseViolationData,
                            }
                          )
                        )}
                      >
                        <CellContent disableRadiusClauseTooltip data={data} />
                        {!!data?.clauseViolationData && (
                          <div className="flex flex-row self-start items-center bg-[rgba(233,83,68,0.10)] px-1.5 py-1 gap-1 mx-5">
                            <ErrorIcon className="w-4 h-4 fill-[#E95344]" />
                            <span className="text-xs font-medium">
                              {`This venue is within ${data?.clauseViolationData?.distance} mi and ${data?.clauseViolationData?.days} days of a clause
                  violation`}
                            </span>
                          </div>
                        )}
                      </div>
                    )
                )}
              </div>
            </MobileSideDrawer>
          </div>
        </div>
      )}
    </div>
  );
};

export default CellContentWithMore;
