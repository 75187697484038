import { Modal } from 'features/common/modal/Modal';
import { FC, ReactElement, useState } from 'react';

import Button from '../../../components/Button';

export interface ConfirmModalProps {
  testId?: string;
  title: string;
  body?: string;
  icon?: ReactElement;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ConfirmModal: FC<ConfirmModalProps> = ({ testId, title, body, onConfirm, onCancel, icon }) => {
  const [showModal, setShowModal] = useState<boolean>(true);

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} showClose={false}>
      <div data-testid={testId} className="flex flex-col items-center">
        {icon && <div className="m-2">{icon}</div>}
        <h3 className="text-2xl text-center mb-4">{title}</h3>
        {body && <p className="text-sm text-center mb-6 max-w-[325px]">{body}</p>}
        <div>
          <div className="flex justify-center">
            <Button
              onClick={() => {
                if (onCancel) onCancel();
                setTimeout(() => {
                  setShowModal(false);
                }, 300);
              }}
              className="mr-8"
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                onConfirm();
                setTimeout(() => {
                  setShowModal(false);
                }, 300);
              }}
              variant="secondary"
            >
              CONFIRM
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
