import { iOptions, iContractSignerBlockOptions } from 'deal-form/interfaces/general';
import moment from 'moment/moment';
import { User } from 'types';
import { ContractSignerBlock } from 'types/company';

const METERS_PER_MILE = 1609.34;
const MS_PER_DAY = 8.64e7;

/**
 * Converts Meters to Miles
 *
 * @param v number - in meters
 * @returns number - in miles
 */
export const toMiles = (v: number): number => Math.round((Number(v) * 10) / METERS_PER_MILE) / 10;

/**
 * Converts Miles to Meters
 *
 * @param v number - in miles
 * @returns number - in meters
 */
export const toMeters = (v: number): number => Math.round(Number(v) * METERS_PER_MILE);

/**
 * Converts Milliseconds to Days
 *
 * @param v number - in milliseconds
 * @returns number - in days
 */
export const toDays = (v: number): number => Math.round(Number(v) / MS_PER_DAY);

/**
 * Converts Days to Milliseconds
 *
 * @param v number - in days
 * @returns number - in milliseconds
 */
export const toMs = (v: number): number => Math.round(Number(v) * MS_PER_DAY);

/**
 * Format number to US en standard
 * will accept a string or a number and return the formatted value 1000000 -> 1,000,000
 *
 * @param x number or string - input value
 * @returns string - number formatted with commas
 */
export const numberWithCommas = (x: number | string): string => x.toLocaleString('en-US');

/**
 * Convert date from local timezone to midnight UTC to avoid conflicts
 *
 * @param dateString string to convert to UTC
 * @returns Date - UTC Date
 */
export const dateInputToUTC = (dateString: string) => {
  const date = new Date(`${dateString} 00:00 GMT`);
  return date;
};

const DEFAULT_TO_LOCALE_DATE_STRING: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};

/**
 * Transform date to a formated date string
 * @param {Date | string | number} date
 * @params An object that contains one or more properties that specify comparison options.
 */
export const toLocaleDateString = (
  date: Date | string | number,
  options: Intl.DateTimeFormatOptions = DEFAULT_TO_LOCALE_DATE_STRING
): string => {
  let currentDate = date;
  if (!(currentDate instanceof Date)) {
    currentDate = new Date(date);
  }

  return currentDate.toLocaleDateString(navigator.language, options);
};

export const contractDueDateToUTC = (dateInput: string) => {
  const dArray = dateInput.split('-');
  // produce date string based on browser settings
  if (navigator.language === 'en-US') {
    // if the user is in the US, use MM-DD-YYYY as the date format
    const year = dArray[2];
    const month = dArray[0];
    const day = dArray[1];
    const dateString = `${year}-${month}-${day}`;
    const date = new Date(`${dateString} 00:00 GMT`);
    return date;
  } else {
    const year = dArray[2];
    const month = dArray[1];
    const day = dArray[0];
    const dateString = `${year}-${month}-${day}`;
    const date = new Date(`${dateString} 00:00 GMT`);
    return date;
  }
};

/**
 * Convert date from local timezone to midnight UTC based on browser language to avoid conflicts
 *
 * @param dateInput string to convert to UTC
 * @returns Date - UTC Date
 */
export const setDateToMidnight = (dateInput: Date | string) => {
  if (typeof dateInput === 'string') {
    const date = new Date(`${dateInput} 00:00 GMT`);
    return date;
  } else {
    return dateToUTC(dateInput);
  }
};

export const dateToUTC = (date: Date) => {
  if (!date) {
    return;
  }

  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const getDateFormatByBrowser = (separator: string = '-') => {
  // A fake date and with each part different to be able to identify later
  const fakeDate = new Date();
  fakeDate.setDate(22);
  fakeDate.setMonth(10); // index
  fakeDate.setFullYear(1999);

  const format = new Intl.DateTimeFormat(navigator.language)
    .format(fakeDate)
    .replace(fakeDate.getDate().toString(), 'DD')
    // also consider the month as an index
    .replace((fakeDate.getMonth() + 1).toString(), 'MM')
    .replace(fakeDate.getFullYear().toString(), 'YYYY');

  return format.replace(/\W/g, separator);
};

export const getDateFormatByBrowserRouting = () => {
  const defaultFormat = getDateFormatByBrowser();
  if (defaultFormat === 'MM-DD-YYYY') {
    return 'ddd MM/DD';
  }

  return 'ddd DD/MM';
};

export const getDateObjectFromBrowserString = (dateString: string) => {
  const dArray = dateString.split('-');

  if (navigator.language === 'en-US') {
    const month = dArray[0];
    const day = dArray[1];
    const year = dArray[2];
    return new Date(`${year}-${month}-${day}`);
  }

  const month = dArray[1];
  const day = dArray[0];
  const year = dArray[2];
  return new Date(`${year}-${month}-${day}`);
};

/**
 * check to see if an object is empty
 *
 * @param {object} obj - object to check
 * @returns
 */
export const isObjectEmpty = (obj: object) => Object.keys(obj).length === 0;

/**
 * Check if date is after today
 *
 * @param {string} dateString - input date
 * @returns true if date is after today
 */
export const isDateAfterToday = (dateString: string) => {
  const today = moment();
  const momentDate = moment.utc(dateString);
  const isAfterToday = momentDate.isAfter(today);

  return isAfterToday;
};

/**
 * Check if number has decimals
 *
 * @param {number} number - number to check
 * @returns true if number doesn't have decimals
 */
export const isWholeNumber = (number: number) => number % 1 === 0;

/**
 * Generate URL-friendly slug of a string.
 *
 * @param {string} str
 * @returns {string}
 */
export const slugify = (str: string) =>
  str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export const alphabetize = (a: iOptions, b: iOptions) => {
  if (a?.label?.includes('Create Custom')) return -1;
  if (b?.label?.includes('Create Custom')) return 1;
  return a?.label?.localeCompare(b?.label);
};

export const isValidDate = (dateObject: Date) => {
  return new Date(dateObject).toString() !== 'Invalid Date';
};

export const formatPercentNumber = (number: string | number): number => {
  let formattedNumber = typeof number === 'string' ? parseFloat(number) : number;
  let decimalPlaces = (`${number}`.split('.')[1] || '').length;

  if (decimalPlaces > 2) {
    formattedNumber = parseFloat(formattedNumber.toFixed(2));
  }

  return formattedNumber;
};

export const isUserFromDepartments = (user: User | null, departments: string[]) =>
  user?.departments?.some((userDepartment) => departments.includes(userDepartment)) || false;

export const hasAccessToAccountingQueue = (user: User | null) => {
  if (!user) return false;
  return process.env.REACT_APP_ENVIRONMENT === 'production' ? isUserFromFinanceAndAccountingDepartment(user) : true;
};
export const isUserFromFinanceAndAccountingDepartment = (user: User | null) =>
  isUserFromDepartments(user, ['Finance & Accounting']);

export const isUserFromAllowedDepartments = (user: User | null) =>
  isUserFromDepartments(user, [
    'Finance & Accounting',
    'Music',
    'Comedy Touring',
    'Comedy and Touring',
    'Information Technology',
    'IQ',
  ]);

export const selectContractSignerOptionsBasedOnTerritoryInfo = (
  contractSignerBlockOptions: ContractSignerBlock[],
  territory?: string
) => {
  if (!territory) return [];
  let territoryBasedOptions: iContractSignerBlockOptions[] = [];
  if (contractSignerBlockOptions && territory) {
    contractSignerBlockOptions.forEach((signerOption: ContractSignerBlock) => {
      if (
        signerOption.territory.toLowerCase() == 'all territories' ||
        signerOption.territory.toLowerCase() == territory.toLowerCase()
      ) {
        territoryBasedOptions.push({
          id: signerOption?.label,
          label: signerOption?.label,
          contractSignerBlockId: signerOption?._id,
          text: signerOption?.text,
        });
      }
    });
  }
  return territoryBasedOptions;
};
