import React from 'react';

interface TextInputProps {
  defaultValue?: number;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const NumberInput: React.FunctionComponent<TextInputProps> = ({ defaultValue, placeholder, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //TODO formatting checks
    onChange(event.target.value.toString());
  };

  return (
    <input
      className="border border-solid border-greyTapa px-4 py-2 rounded text-sm w-full"
      type="number"
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={handleChange}
      autoComplete="off"
    />
  );
};
