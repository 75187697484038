import { Dispatch, SetStateAction, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';

import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {
  ColDef,
  ColumnPinnedType,
  DisplayedColumnsChangedEvent,
  FilterChangedEvent,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideSelectionState,
} from 'ag-grid-community';

// API
import { getContractByDealId, RequestResponse, showUserError } from 'api';
import { getDealById, removeDeal, updateDeal } from 'api/deals';
import { generatePdf } from 'api/doc-generation';
import { getDealGrids } from 'api/grid-pages';

// Components
import {
  AddNoteModal,
  BooleanFilter,
  ConfirmModal,
  ActionsRenderer,
  BooleanRenderer,
  CurrencyRenderer,
  DateRangeRenderer,
  DateRenderer,
  DealRenderer,
  DefaultRenderer,
  MultiNameRenderer,
  NameRenderer,
  NumberRenderer,
  PercentRenderer,
  AgDateInput,
} from 'grid-pages/components';
import * as cellComponents from 'features/routing/routing-table';

// Grid Pages - Others
import '../grid-styles/tableStyles.scss';
import { Deal } from 'types';
import { pinnedCellStyle, staticCellStyle } from 'grid-pages/grid-styles';
import { ActionsProps, FilterModel } from 'grid-pages/interfaces';
import { doCsvExportFromData, formatDataForExport } from 'grid-pages/utils/export-helpers';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

export const checkboxColumn: ColDef<unknown> = {
  maxWidth: 44,
  pinned: true,
  field: 'check',
  sortable: false,
  suppressMovable: false,
  checkboxSelection: true,
  headerClass: 'select-all',
  headerCheckboxSelection: true,
  cellClass: 'select-check-box',
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  headerCheckboxSelectionCurrentPageOnly: true,
  cellStyle: {
    padding: '8px 10px',
  },
};

const getDealIdMessage = (dealId?: string) => (dealId ? `Deal ID ${dealId}` : 'this deal');

const CSV_EXCLUDED_COLUMNS = ['check', 'accountingActions', 'contractingActions', 'trackingActions'];

interface ModalProps {
  id: string;
  title: string;
  body?: string;
  clientName?: string;
  dealId: number | string;
  modalAction: (data: Omit<ModalProps, 'modalAction'>) => Promise<void>;
}

export interface GridTableProps extends Partial<AgGridReactProps> {
  pageId: string;
  endDate?: Date | null;
  showSideBar?: boolean;
  isExporting?: boolean;
  showFilters?: boolean;
  showColumns?: boolean;
  startDate?: Date | null;
  customFilterModel?: any;
  dealGridsParams?: string;
  hideSidebarColumns?: boolean;
  onColumnsChange?: (cols: any) => void;
  setIsExporting?: (val: boolean) => void;
  setEndDate?: Dispatch<SetStateAction<Date | null>>;
  setStartDate?: Dispatch<SetStateAction<Date | null>>;
  setCustomFilterModel?: (filter: FilterModel) => void;
}

interface InterimNotesModalData {
  id: string;
  interimNotes?: string;
  dealId: number | string;
}

const GridTable = forwardRef<AgGridReact, GridTableProps>(
  (
    {
      pageId,
      endDate,
      startDate,
      showSideBar,
      isExporting,
      showColumns,
      showFilters,
      dealGridsParams,
      customFilterModel,
      hideSidebarColumns = false,
      setEndDate,
      setStartDate,
      setIsExporting,
      onColumnsChange,
      setCustomFilterModel,
      ...rest
    },
    ref
  ) => {
    // Refs
    const gridApiRef = useRef<AgGridReact>(null);
    const urlParams = useRef(new URLSearchParams()).current;

    // States
    const [modalData, setModalData] = useState<ModalProps | null>(null);
    const [currentRowsLength, setCurrentRowsLength] = useState<number>(0);
    const [interimNotesModalData, setInterimNotesModalData] = useState<InterimNotesModalData>();
    const [isInterimNotesModalOpen, setIsInterimNotesModalOpen] = useState<boolean>(false);
    const [isSavingInterimNotesModalOpen, setIsSavingInterimNotesModalOpen] = useState<boolean>(false);

    if (ref) {
      if (typeof ref === 'function') {
        ref(gridApiRef.current);
      } else if (typeof ref === 'object') {
        ref.current = gridApiRef.current;
      }
    }

    useEffect(() => {
      if (currentRowsLength === 0) {
        gridApiRef?.current?.api?.showNoRowsOverlay();
      } else {
        gridApiRef?.current?.api?.hideOverlay();
      }
    }, [currentRowsLength, gridApiRef]);

    useEffect(() => {
      showFilters ? gridApiRef?.current?.api?.openToolPanel('filters') : gridApiRef?.current?.api?.closeToolPanel();
    }, [showFilters]);

    useEffect(() => {
      showColumns ? gridApiRef?.current?.api?.openToolPanel('columns') : gridApiRef?.current?.api?.closeToolPanel();
    }, [showColumns]);

    useEffect(() => {
      gridApiRef?.current?.api?.setFilterModel(customFilterModel);
    }, [customFilterModel]);

    const dataSource: IServerSideDatasource = useMemo(
      () => ({
        async getRows(params) {
          //get the sort and filter info from the grid params
          const { sortModel, endRow = 3, filterModel, startRow = 0 } = params.request;

          urlParams.set('skip', `${startRow}`);
          urlParams.set('limit', `${endRow - startRow}`);

          //get sorting
          if (sortModel.length) {
            const { sort, colId } = sortModel[0];
            urlParams.set('sort', colId);
            urlParams.set('order', sort);
          }

          //TODO update to handle date filter response
          Object.entries(filterModel)?.forEach(([key, value]) => {
            const { type, filter, dateTo, dateFrom, operator, filterType, conditions } = value as Record<string, any>;

            const filterInstance = params.api.getFilterInstance(key);
            // @ts-ignore
            const colId = filterInstance?.providedFilterParams?.column?.userProvidedColDef?.colId || key;

            if (operator) {
              const conditionsToFilter = [];
              for (const [condition] of conditions.entries()) {
                conditionsToFilter.push(`${condition.type}=${condition.filter}`);
              }

              urlParams.set(`${colId}.${filterType}.${operator}`, conditionsToFilter.join(','));
            } else {
              switch (filterType) {
                case 'boolean':
                  urlParams.set(`${colId}.${filterType}.equals`, filter);
                  break;
                case 'date':
                  urlParams.set(`${colId}.from`, moment(dateFrom).utc().startOf('day').toISOString());
                  urlParams.set(`${colId}.to`, moment(dateTo).utc().startOf('day').toISOString());
                  break;
                default:
                  urlParams.set(`${colId}.${filterType}.${type}`, filter);
                  break;
              }
            }
          });

          //dates
          if (startDate) {
            urlParams.set('start', moment(startDate).utc().startOf('day').toISOString());
          }
          if (endDate) {
            urlParams.set('end', moment(endDate).utc().startOf('day').toISOString());
          }

          // update date range
          if (setStartDate && setEndDate) {
            const match = [urlParams.get('startDate.from'), urlParams.get('startDate.to')];
            if (match[0] && match[1] && !moment(match[0]).isSame(startDate) && !moment(match[1]).isSame(endDate)) {
              setStartDate(moment(match[0]).utc().startOf('day').toDate());
              setEndDate(moment(match[1]).utc().startOf('day').toDate());
            }
          }

          const dealGridsParamsObj = new URLSearchParams(dealGridsParams);
          Array.from(dealGridsParamsObj.entries()).forEach(([key, value]) => {
            urlParams.set(key, value);
          });

          const data = await getDealGrids(urlParams.toString());

          if (data.rows) {
            // save rows length for fetching csv export data
            setCurrentRowsLength(data.rowCount);

            // call the success callback
            params.success({
              rowData: data.rows,
              rowCount: data.rowCount,
            });
          } else {
            // inform the grid request failed
            params.fail();
          }
        },
      }),
      [dealGridsParams, startDate, endDate, setEndDate, setStartDate, urlParams]
    );

    useEffect(() => {
      gridApiRef.current?.api?.refreshServerSide();
      gridApiRef.current?.api?.setServerSideDatasource(dataSource);
    }, [dataSource]);

    useEffect(() => {
      //export csv
      if (gridApiRef && gridApiRef.current && isExporting) {
        //TODO add loading spinner
        //only parse visible columns, and exclude the "check" selection column
        const visibleCols = gridApiRef.current.columnApi
          .getAllDisplayedColumns()
          .filter((col) => !CSV_EXCLUDED_COLUMNS.includes(col.getColId()));

        //is the header checkbox to select all checked?
        const selected = gridApiRef.current.api.getServerSideSelectionState() as IServerSideSelectionState;
        if (selected.selectAll || (!selected.selectAll && selected.toggledNodes.length === 0)) {
          // then get the data from the backend to create the csv data
          const getDeals = async () => {
            urlParams.set('skip', '0');
            urlParams.set('limit', `${currentRowsLength}`);
            const data = await getDealGrids(urlParams.toString());

            //format data into arrays of arrays of strings
            const formattedData: string[][] = formatDataForExport(data.rows, visibleCols, selected.toggledNodes);

            //do export
            doCsvExportFromData(formattedData, pageId);
            if (setIsExporting) {
              setIsExporting(false);
            }
          };
          getDeals();
        } else {
          // else use the grid data for selected rows to export
          const selectedData = gridApiRef.current?.api.getSelectedRows();
          const formattedData: string[][] = formatDataForExport(selectedData, visibleCols, []);

          //do export
          doCsvExportFromData(formattedData, pageId);
          if (setIsExporting) {
            setIsExporting(false);
          }
        }
      }
    }, [currentRowsLength, isExporting, pageId, setIsExporting, urlParams]);

    const onGridReady = useCallback(
      (params: GridReadyEvent) => {
        // Grid is ready, so fetch the data
        params.api.setServerSideDatasource(dataSource);
      },
      [dataSource]
    );

    const getRowId = useMemo(
      //Used for identifying selected rows
      () => (params: any) => {
        return params.data._id;
      },
      []
    );

    const defaultColDef = {
      minWidth: 75,
      resizable: true,
      cellStyle: staticCellStyle,
      sortable: true,
      unSortIcon: true,
      autoHeight: true,
      //don't show header filters if we have the sidebar
      floatingFilter: !showSideBar,
    };

    const defaultTextFilterProps = {
      filter: 'agTextColumnFilter',
      filterParams: {
        trimInput: true,
        maxNumConditions: 1,
        filterOptions: ['contains', 'notContains'],
      },
    };

    const defaultNumberFilterProps = {
      filter: 'agNumberColumnFilter',
      filterParams: {
        maxNumConditions: 1,
        filterOptions: ['equals', 'greaterThan', 'lessThan'],
      },
    };

    const defaultDateFilterProps = {
      filter: 'agDateColumnFilter',
      filterParams: {
        maxNumConditions: 1,
        filterOptions: ['inRange'],
      },
    };

    const doMarkAsPaid = async (data: Omit<ModalProps, 'modalAction'>) => {
      const deal = await getDealById(data.id);
      if (deal) {
        await updateDeal(data.id, { ...deal, paid: true })
          .then((resp) => {
            if (resp) gridApiRef?.current?.api?.refreshServerSide();
          })
          .catch((err) => {
            showUserError(err);
          });
      }
    };

    const openDealForm = (params: ActionsProps) => {
      window.open(`/deal/${params.value}/#payment-schedule`, '_blank');
    };

    const handleMarkAsPaid = (params: ActionsProps) => {
      setModalData({
        id: params.data._id,
        modalAction: doMarkAsPaid,
        dealId: params.data.dealId,
        clientName: params.data.client?.name,
        title: `Please confirm that ${getDealIdMessage(params?.data?.dealId)}, ${params.data.client?.name} is paid.`,
      });
    };

    const doRemove = async (data: Omit<ModalProps, 'modalAction'>) => {
      await removeDeal(data.id)
        .then((resp) => {
          if (resp.ok) {
            gridApiRef?.current?.api?.refreshServerSide();
          } else {
            showUserError(resp);
          }
        })
        .catch((err) => {
          showUserError(err);
        });
    };

    const handleRemove = (params: ActionsProps) => {
      setModalData({
        id: params.data._id,
        modalAction: doRemove,
        dealId: params.data.dealId,
        title: `You’re deleting ${getDealIdMessage(params?.data?.dealId)}`,
        body: `Once a deal is deleted, it’s permanent.
        You will not be able to see it’s details again.`,
      });
    };

    const handleUpdateInterimNotes = async (interimNotes: string) => {
      if (!interimNotesModalData) return;

      setIsSavingInterimNotesModalOpen(true);

      const deal: Deal = await getDealById(interimNotesModalData.id);

      if (deal) {
        try {
          const response = await updateDeal(interimNotesModalData.id, { ...deal, interimNotes });
          setInterimNotesModalData(undefined);
          if (response) {
            const rowNode = gridApiRef?.current?.api?.getRowNode(interimNotesModalData.id);
            rowNode?.setData({ ...rowNode.data, interimNotes });
          }
        } catch (err: any) {
          showUserError(err);
        }
      }

      setIsSavingInterimNotesModalOpen(false);
      setIsInterimNotesModalOpen(false);
    };

    const handleOpenInterimNotesModal = (params: ActionsProps) => {
      setInterimNotesModalData({
        id: params.data._id,
        dealId: params.data.dealId,
        interimNotes: params.data.interimNotes,
      });
      setIsInterimNotesModalOpen(true);
    };

    const handleGeneratePdf = async (params: ActionsProps) => {
      const newWindow = window.open('', '_blank');
      newWindow?.document.write('Loading...');

      try {
        const { _id: contractId } = await getContractByDealId(params.data._id);
        const response = await generatePdf(contractId);
        let data = null;

        try {
          // If the body is the PDF file, we need to ignore the json parsing error.
          data = await response.json();
        } catch (error) {
          // ignore errors.
        }

        if (response.ok) {
          /**
           * If there is no data and the response is ok, it means the pdf was generated successfully
           * but sent as a file in the response body. So just open the pdf URL
           */
          if (!data && newWindow) {
            newWindow.location.href = response?.url;
          }

          if (data?.redirect && newWindow) {
            newWindow.location.href = data?.redirect;
          } else if (!data?.redirect && newWindow) {
            newWindow.close();
          }
        } else {
          throw new Error('Unable to generate PDF', data);
        }
      } catch (error) {
        console.log('error', error);
        showUserError(error as RequestResponse);
      }
    };

    const getActions = () => ({
      trackingActions: [
        {
          icon: 'EmailIcon',
          action: handleRemove,
          title: 'Deposit Reminder',
        },
        {
          icon: 'TrashIcon',
          action: handleRemove,
          title: 'Deposit/Contract Reminder',
        },
      ],
      accountingActions: [
        {
          title: 'Mark as Paid',
          action: handleMarkAsPaid,
          icon: 'DocumentCheckIcon',
        },
        {
          action: openDealForm,
          icon: 'BanknotesIcon',
          title: 'Make a payment',
        },
      ],
      contractingActions: [
        {
          title: 'Download (PDF)',
          icon: 'ArrowDownTrayIcon',
          action: handleGeneratePdf,
        },
        {
          title: 'Notes',
          icon: 'DocumentIcon',
          action: handleOpenInterimNotesModal,
        },
      ],
    });

    const columnTypes = useMemo(
      () => ({
        dateRangeColumn: {
          cellRenderer: DateRangeRenderer,
        },
        dateColumn: {
          ...defaultDateFilterProps,
          cellRenderer: DateRenderer,
        },
        dealColumn: {
          cellRenderer: DealRenderer,
          ...defaultTextFilterProps,
        },
        textColumn: {
          ...defaultTextFilterProps,
          cellRenderer: DefaultRenderer,
        },
        contractColumn: {
          ...defaultTextFilterProps,
          cellRenderer: DealRenderer,
        },
        numberColumn: {
          ...defaultNumberFilterProps,
          cellRenderer: NumberRenderer,
        },
        agentsColumn: {
          cellRenderer: MultiNameRenderer,
          ...defaultTextFilterProps,
        },
        percentColumn: {
          cellRenderer: PercentRenderer,
          ...defaultNumberFilterProps,
        },
        dealTypeColumn: {
          cellRenderer: cellComponents.DealTypeRenderer,
          ...defaultTextFilterProps,
        },
        nestedColumn: {
          ...defaultTextFilterProps,
          cellRenderer: DefaultRenderer,
          filterValueGetter: (params: any) => {
            const colId = params.column.colId;
            return params.data[colId]?.name;
          },
        },
        currencyColumn: {
          ...defaultNumberFilterProps,
          cellRenderer: CurrencyRenderer,
          filterValueGetter: (params: any) => {
            const colId = params.column.colId;
            return params.data[colId]?.amount;
          },
        },
        booleanColumn: {
          filter: BooleanFilter,
          cellRenderer: BooleanRenderer,
          valueFormatter: (params: any) => {
            const colId = params.column.colId;
            return params.data[colId];
          },
          valueGetter: (params: any) => {
            const colId = params.column.colId;
            return params.data[colId] === true ? 'yes' : 'no';
          },
        },
        actionColumn: {
          sortable: false,
          lockPinned: true,
          lockVisible: true,
          // width: 110,
          suppressMenu: true,
          lockPosition: false,
          suppressMovable: true,
          cellStyle: pinnedCellStyle,
          cellRenderer: ActionsRenderer,
          pinned: 'right' as ColumnPinnedType,
          // cellClass: 'drag-row',
          valueGetter: (params: any) => params.data._id,
          cellRendererParams: {
            actions: getActions(),
          },
        },
        nameColumn: {
          ...defaultTextFilterProps,
          cellRenderer: NameRenderer,
          valueFormatter: (params: any) => {
            const colId = params.column.colId;
            return params.data[colId];
          },
          valueGetter: (params: any) => {
            const colId = params.column.colId;
            return params.data[colId]?.name;
          },
          filterValueGetter: (params: any) => {
            const colId = params.column.colId;
            return params.data[colId]?.name;
          },
        },
      }),
      [defaultDateFilterProps, defaultNumberFilterProps, defaultTextFilterProps, getActions]
    );

    const sidebarColumns = {
      id: 'columns',
      iconKey: 'columns',
      labelKey: 'columns',
      labelDefault: 'Columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressValues: true,
        suppressPivotMode: true,
        suppressRowGroups: true,
      },
    };

    const onDisplayedColumnsChanged = (e: DisplayedColumnsChangedEvent<unknown>) => {
      if (onColumnsChange) {
        const cols = e.columnApi.getAllDisplayedColumns();
        const colIds = cols
          .filter((col) => col.getId() !== 'check')
          .map((col, index) => ({ position: index, name: col?.getColDef()?.colId || col?.getId() }));
        onColumnsChange(colIds);
      }
    };

    const gridOptions: GridOptions<unknown> = {
      pagination: true,
      //how many rows are fetched at one time, default 100
      cacheBlockSize: 100,
      suppressClickEdit: true,
      //how many rows to display per page, default 100
      paginationPageSize: 100,
      suppressContextMenu: true,
      colResizeDefault: 'shift',
      suppressRowTransform: true,
      rowModelType: 'serverSide',
      suppressScrollOnNewData: true,
      sideBar: showSideBar
        ? {
            toolPanels: [
              ...(hideSidebarColumns ? [] : [sidebarColumns]),
              {
                id: 'filters',
                iconKey: 'filter',
                labelKey: 'filters',
                labelDefault: 'Filters',
                toolPanel: 'agFiltersToolPanel',
              },
            ],
          }
        : false,
      ...rest.gridOptions,
    };

    const onFilterChanged = (e: FilterChangedEvent) => {
      if (setCustomFilterModel) {
        setCustomFilterModel(e.api.getFilterModel());
      }
    };

    const noRowsOverlayComponent = () => <span className="font-bold">No Rows to Show</span>;

    const components = { agDateInput: AgDateInput, ...cellComponents };

    const onConfirmModal = () => {
      const { modalAction, ..._rest } = modalData as ModalProps;
      modalAction && modalAction(_rest);

      setTimeout(() => {
        setModalData(null);
      }, 100);
    };

    return (
      <div className="ag-theme-alpine flex flex-1 h-full w-full">
        <AgGridReact
          rowSelection="multiple"
          ref={gridApiRef}
          animateRows
          columnTypes={columnTypes}
          getRowId={getRowId}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          noRowsOverlayComponent={noRowsOverlayComponent}
          onDisplayedColumnsChanged={onDisplayedColumnsChanged}
          {...rest}
          className={twMerge(
            clsx(
              'flex flex-1',
              // ag-root-wrapper classes
              '[&_.ag-root-wrapper]:flex [&_.ag-root-wrapper]:flex-1',
              rest.className
            )
          )}
          components={components}
          columnDefs={rest.columnDefs ?? [checkboxColumn]}
          gridOptions={gridOptions}
          defaultColDef={{ ...defaultColDef, ...rest?.defaultColDef }}
        />

        {isInterimNotesModalOpen && (
          <AddNoteModal
            show={isInterimNotesModalOpen}
            isLoading={isSavingInterimNotesModalOpen}
            values={{ interimNotes: interimNotesModalData?.interimNotes || '' }}
            setShow={setIsInterimNotesModalOpen}
            onCancel={() => setIsInterimNotesModalOpen(false)}
            onSubmit={(values) => handleUpdateInterimNotes(values.interimNotes)}
          />
        )}

        {modalData && (
          <ConfirmModal
            body={modalData?.body}
            title={modalData.title}
            onConfirm={onConfirmModal}
            onCancel={() => setModalData(null)}
          />
        )}
      </div>
    );
  }
);

export default GridTable;
