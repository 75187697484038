import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

export const ClientUnavailable: FC<ICellRendererParams> = (props) => {
  return !props.data?.monthBreak && !props.data?.clientAvailable ? (
    <div
      className="center-content text-left w-full font-extrabold text-xl text-black text-opacity-50"
      data-row-id={props.data?.id}
    >
      {'Client Unavailable'}
    </div>
  ) : (
    <></>
  );
};
