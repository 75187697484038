import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Badge, Button, Dropdown, MobileSideDrawer, Tabs } from '@united-talent-agency/onyx-components';
import { ListItemT } from '@united-talent-agency/onyx-components/Tabs/Tabs';
import { Download, Print } from '@united-talent-agency/onyx-components/Icons';

import useIsMobile from 'hooks/isMobile';
import { useFilters } from 'context/avails-filters/context';
import TimelineAgendaView from '../TimelineAgendaView';
import ShowsView from '../ShowsView';
import MobileDrawerHeader from 'components/MobileDrawerHeader';
import { TimelineAgendaData } from 'components/TimelineAgenda/types';
import { doCsvExportFromData } from 'grid-pages/utils/export-helpers';
import { bindProximityToShowsViewData, transformSearchDataToTimelineAgendaData } from '../helpers';
import { ShowTableDataType, ShowsAgendaData } from 'components/ShowsAgenda/types';
import { Position } from 'context/avails-filters/hook';
import LoadingIndicator from 'components/LoadingIndicator';
import { getCellValuesAsString } from 'components/ShowsAgenda/Body/CellContent';
import { SHOWS_AGENDA_COLUMNS } from '../constants';

const AvailsTabs: FC = () => {
  const isMobile = useIsMobile();
  const {
    collapsed,
    loading,
    actionsEnabled,
    results,
    dateRange,
    selectedLocation,
    date: date_,
    selectedClients,
  } = useFilters();

  // States
  const [actionsOpened, setActionsOpened] = useState(false);
  const [performingPrint, setPerformingPrint] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [agendaData, setAgendaData] = useState<TimelineAgendaData>([]);
  const [showsData, setShowsData] = useState<ShowsAgendaData>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const printPDFAction = useCallback(async () => {
    setActionsOpened(false);
    setPerformingPrint(true);

    setTimeout(window.print, 1000);
  }, []);

  useEffect(() => {
    const callback = () => {
      setPerformingPrint(false);
    };

    window.addEventListener('afterprint', callback);

    return () => {
      window.removeEventListener('afterprint', callback);
    };
  }, []);

  useEffect(() => {
    const locationJSON = selectedLocation?.value ? selectedLocation.value.toString() : '{}';
    const loc = (JSON.parse(locationJSON) as Position) || null;

    (async () => {
      const timelineAgendaData: TimelineAgendaData = await transformSearchDataToTimelineAgendaData(
        results.hits,
        dateRange,
        loc,
        selectedClients
      );

      setTotal(timelineAgendaData.length);
      // setAllTimelineAgendaData(timelineAgendaData);
      setAgendaData(timelineAgendaData);
    })();

    const showsAgendaData: ShowsAgendaData = bindProximityToShowsViewData(loc, results.hits);
    setShowsData(showsAgendaData);
  }, [results.hits, results.nbHits, dateRange, selectedLocation, selectedClients]);

  const selectedAgendaData: TimelineAgendaData = useMemo(() => agendaData.filter((row) => row.selected), [agendaData]);
  const selectedShowsData: ShowsAgendaData = useMemo(() => showsData.filter((row) => row.selected), [showsData]);

  const CSVTextJoinSeparator = ' - ';

  const downloadCSVAction = useCallback(() => {
    setActionsOpened(false);

    let csvData: string[][] = [[]];

    if (tabIndex === 0) {
      csvData = selectedAgendaData.reduce((acc, agendaHeaderRow, agendaHeaderRowIndex) => {
        if (agendaHeaderRowIndex === 0) {
          acc.push([]);
          acc[0][0] = 'Client';
        }

        acc.push([]);

        acc[agendaHeaderRowIndex + 1][0] = agendaHeaderRow?.timelineLabel ?? '';

        agendaHeaderRow.data.forEach((tableRow, tableRowIndex) => {
          const _date = moment(tableRow.date);
          const weekDay = _date.format('ddd');
          const fullDate = _date.format('MM/DD/YYYY');
          const columnName = [weekDay, fullDate].join(CSVTextJoinSeparator);

          if (!acc[0]?.includes(columnName)) {
            acc[0][tableRowIndex + 1] = columnName;
          }

          if (tableRow.unavailable) {
            acc[agendaHeaderRowIndex + 1][tableRowIndex + 1] = 'Unavailable';
          } else {
            tableRow.data?.forEach(({ label, address, statusLabel }) => {
              const values = [label, address, statusLabel];
              acc[agendaHeaderRowIndex + 1][tableRowIndex + 1] = values.join(CSVTextJoinSeparator);
            });
          }
        });

        return acc;
      }, [] as string[][]);
    } else {
      csvData = selectedShowsData?.reduce((acc, showsHeaderRow, showsHeaderRowIndex) => {
        if (showsHeaderRowIndex === 0) {
          acc.push(SHOWS_AGENDA_COLUMNS);
        }

        acc.push([]);

        showsHeaderRow.data.forEach((tableRow, tableRowIndex) => {
          if (tableRow.unavailable) {
            acc[showsHeaderRowIndex + 1][tableRowIndex] = 'Unavailable';
          } else {
            tableRow.data?.forEach((row) => {
              const values = getCellValuesAsString(row as ShowTableDataType, date_);
              acc[showsHeaderRowIndex + 1] = values;
            });
          }
        });

        return acc;
      }, [] as string[][]);
    }

    doCsvExportFromData(csvData, 'clients-availability');
  }, [selectedAgendaData, selectedShowsData, date_]);

  const ACTIONS = useMemo(
    () => [
      {
        icon: Print,
        label: 'Print (PDF)',
        action: printPDFAction,
      },
      {
        icon: Download,
        label: 'Download (CSV)',
        action: downloadCSVAction,
      },
    ],
    [printPDFAction, downloadCSVAction]
  );

  const handleToggleActions = useCallback(() => {
    setActionsOpened(!actionsOpened);
  }, [actionsOpened]);

  const CalendarTitle = useCallback(
    () => (
      <>
        <span className="mr-2">Calendar</span>
        <Badge size="md" text={total.toString()} bgColor="#DFDFDB" color="#000000" className="rounded-none font-bold" />
      </>
    ),
    [total]
  );

  const ShowsTitle = useCallback(
    () => (
      <>
        <span className="mr-2">{isMobile ? 'Shows' : 'Shows Within Range'}</span>
        <Badge
          size="md"
          text={results.hits.length.toString()}
          bgColor="#DFDFDB"
          color="#000000"
          className="rounded-none font-bold"
        />
      </>
    ),
    [isMobile, results.hits.length]
  );

  const handleOnChange = useCallback(
    (_data: TimelineAgendaData) => {
      setAgendaData(_data);
    },
    [agendaData]
  );

  const handleOnChangeShowsData = useCallback(
    (_data: ShowsAgendaData) => {
      setShowsData(_data);
    },
    [showsData]
  );

  const TimelineAgendaViewWrapper = useCallback(
    () => (
      <div className="flex flex-col flex-1 print:pt-3">
        <TimelineAgendaView
          performingPrint={performingPrint}
          data={agendaData}
          selectedData={selectedAgendaData}
          onChange={handleOnChange}
          onChangePage={() => {}}
          // page={page}
          total={total}
          // pageSize={pageSize}
          onChangePageSize={() => {}}
        />
      </div>
    ),
    [performingPrint, agendaData, selectedAgendaData, handleOnChange]
  );

  const ShowsAgendaViewWrapper = useCallback(
    () => (
      <div className="flex flex-col flex-1 print:pt-3">
        <ShowsView
          performingPrint={false}
          data={showsData}
          selectedData={selectedShowsData}
          onChange={handleOnChangeShowsData}
        />
      </div>
    ),
    [performingPrint, showsData, selectedShowsData, handleOnChangeShowsData]
  );

  const tabsList = useMemo(
    () =>
      [
        {
          label: <CalendarTitle />,
          component: TimelineAgendaViewWrapper,
        },
        {
          label: <ShowsTitle />,
          component: ShowsAgendaViewWrapper,
        },
      ] as ListItemT[],
    [CalendarTitle, ShowsTitle, TimelineAgendaViewWrapper, ShowsAgendaViewWrapper]
  );

  return (
    <div className="flex flex-col flex-1 m-0">
      {collapsed && loading && <LoadingIndicator />}
      {collapsed && !loading && (
        <div className="flex flex-col flex-1 relative">
          <Tabs
            list={tabsList}
            defaultIndex={0}
            onChange={(index) => setTabIndex(index)}
            variant={isMobile ? 'full' : 'standard'}
            className="relative [&>.tabs-root]:lg:mx-5 bg-[#F5F5F5] print:bg-transparent [&>.tabs-root]:print:hidden [&>*:last-child]:print:overflow-visible [&>*:last-child]:print:h-full print:overflow-visible"
          />
          <div className="absolute right-5 top-1.5 invisible md:visible m-2 lg:m-0 print:hidden">
            <Dropdown
              text="Actions"
              className="w-[150px]"
              disabled={!actionsEnabled}
              options={ACTIONS.map((opt, index) => (
                <div
                  id={index === 0 ? 'print' : 'download-csv'}
                  key={index}
                  className="flex items-center gap-2"
                  onClick={opt.action}
                >
                  <opt.icon width={16} />
                  {opt.label}
                </div>
              ))}
            />
          </div>
          {actionsEnabled && (
            <div className="print:hidden">
              <div className="fixed p-4 bottom-0 left-0 w-full visible md:invisible border-t border-[#DFDFDB] bg-white z-10">
                <div className="flex space-x-4">
                  <Button
                    className="flex-1 grow"
                    size="lg"
                    type="button"
                    onClick={() => {
                      tabIndex === 0
                        ? setAgendaData((data) => data.map((item) => ({ ...item, selected: false })))
                        : setShowsData((data) => data.map((item) => ({ ...item, selected: false })));
                    }}
                  >
                    Clear All
                  </Button>
                  <Button className="flex-1 grow" size="lg" variant="solid" type="button" onClick={handleToggleActions}>
                    Actions
                  </Button>
                </div>
              </div>
              <MobileSideDrawer
                isOpen={actionsOpened}
                variant="full"
                className="!max-h-44"
                header={() => <MobileDrawerHeader onClose={handleToggleActions} />}
                onClose={handleToggleActions}
              >
                {ACTIONS.map(({ label, action, icon: Icon }) => (
                  <Button
                    key={label}
                    className="border-none w-full text-left justify-start font-normal"
                    size="lg"
                    type="button"
                    onClick={action}
                  >
                    <Icon height={22} width={22} className="mx-4" />
                    <span className="text-sm">{label}</span>
                  </Button>
                ))}
              </MobileSideDrawer>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AvailsTabs;
