import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export type BlankCellProps = {
  testId?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const BlankCell: FC<BlankCellProps> = ({ testId, ...rest }) => (
  <div data-testid={testId} className="flex justify-center items-center" {...rest}>
    <span>--</span>
  </div>
);

export default BlankCell;
