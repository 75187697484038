import { StickyHeaderPage } from 'features/layouts';
import { PageHeader, MongoServerSide } from 'grid-pages/components';
import { useCallback } from 'react';

const DealsTestMongo = () => {
  const handleOnExport = () => {
    console.log('export');
  };

  const renderFixedContainer = useCallback(() => <PageHeader onExport={handleOnExport} title="Deals Mongo Test" />, []);

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="grid-height content-wrapper mt-10">
        <MongoServerSide />
      </div>
    </StickyHeaderPage>
  );
};

export default DealsTestMongo;
