import { XMarkIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@kaizen/draft-tooltip';
import { Spinner } from '@united-talent-agency/components';
import { SEARCH_TYPE } from 'deal-form/constants';
import { ERROR_MESSAGES } from 'deal-form/data/constants';
import { searchForOptions } from 'deal-form/helpers/dealHelpers';
import { iOptions } from 'deal-form/interfaces/general';
import React, { BaseSyntheticEvent, KeyboardEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import {
  FieldValues,
  FormState,
  RegisterOptions,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext,
} from 'react-hook-form';
import { useClickAway } from 'react-use';
import { alphabetize } from 'utils/helpers';

import { findNestedErrorById, sortOptionsAsc } from '../../helpers/formHelpers';

interface iDropdownProps {
  label: string | boolean;
  className?: string;
  placeholder?: string;
  id: string;
  secondaryId?: string;
  searchType?: SEARCH_TYPE;
  defaultValue?: string;
  index?: number;
  disabled?: boolean;
  handleChange?: (
    chosen: iOptions | null,
    setValueMethod: UseFormSetValue<FieldValues>,
    index?: number,
    trigger?: UseFormTrigger<FieldValues>
  ) => void;
  rules?: RegisterOptions;
  rulesCallback?: (formState: FormState<FieldValues>, index?: number) => RegisterOptions<FieldValues, string>;
  isRequired?: boolean;
  optionFormatter?: any;
  shouldSortOptions?: boolean;
  dataCy?: string;
}

export const DropdownWithSearch = ({
  id,
  secondaryId,
  label = false,
  placeholder = '',
  className = '',
  searchType,
  handleChange,
  index,
  defaultValue = '',
  disabled = false,
  isRequired = false,
  rules = {},
  rulesCallback,
  shouldSortOptions = false,
  dataCy,
}: iDropdownProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [useDefaultValue, setUseDefaultValue] = useState<string | null>(defaultValue);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [options, setOptions] = useState<iOptions[] | null>(null);
  const [chosen, setChosen] = useState<iOptions | null>(null);
  const [previousChosen, setPreviousChosen] = useState<iOptions | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    getValues,
    setValue,
    control,
    trigger,
    formState,
    formState: { errors },
    clearErrors,
    setError,
  } = useFormContext();

  const invalidResultsErrorId = `${id}_searcherror`;
  const { onBlur, name, ref } = register(id, { ...(rulesCallback ? { ...rulesCallback(formState, index) } : rules) });

  const openRef = useRef(null);
  useClickAway(openRef, () => {
    resetOptionsDropdown();
    onBlur({ target: ref });
  });

  const hasErrors =
    errors && (!!findNestedErrorById(id, errors) || !!findNestedErrorById(invalidResultsErrorId, errors));

  useEffect(() => {
    if (chosen) {
      setValue(id, chosen.label, { shouldDirty: true });
      // resetOptionsDropdown();

      if (handleChange && chosen.data) {
        handleChange(chosen, setValue, index, trigger);
      }

      // onBlur({ target: ref });

      // Revalidates non custom errors, prevents it from staying invalid
      trigger(id);
      // Clear the custom error now
      clearErrors([invalidResultsErrorId]);
    }
  }, [chosen]);

  useEffect(() => {
    if (control._defaultValues && getValues(id)) {
      try {
        const value = getValues(id);
        if (value !== '' && value !== undefined) {
          const idValue = value.value || value;
          setChosen({ id: idValue, label: idValue });
          setPreviousChosen({ id: idValue, label: idValue });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [control._defaultValues]);

  const resetOptionsDropdown = () => {
    setOptions(null);
    setIsOpen(false);
  };

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setUseDefaultValue(null);
    setChosen(null);
    setIsOpen(true);
    setSearchTerm(value);

    if (value !== '' && searchType) {
      let filterOptions: iOptions[] = [];

      if (!isLoading) {
        setIsLoading(true);
      }

      try {
        filterOptions = await searchForOptions(searchType, value);
        if (shouldSortOptions) {
          sortOptionsAsc(filterOptions);
        }

        if (filterOptions.length > 0) {
          setOptions(filterOptions);
        }
      } catch (e) {
        console.log(e);
      }

      setIsLoading(false);
    } else {
      resetOptionsDropdown();
    }
  };

  const handleSelection = (option: iOptions | null) => {
    setChosen(option);
    setPreviousChosen(option);
    setSearchTerm(null);
  };

  const handleArrowKeyDown = (direction = 'down') => {
    if (!options) {
      return;
    }

    if (!isOpen) {
      setIsOpen(true);
      return;
    }

    const currentIndex = chosen
      ? options.sort(alphabetize).findIndex((option) => option.id === chosen?.id)
      : options.length;

    const prevIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
    const newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;

    setChosen(options[direction === 'up' ? prevIndex : newIndex]);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const key = e.key;

    if (key === 'Enter') {
      e.preventDefault();
      setIsOpen(false);
      setSearchTerm(null);
    }

    if (key === 'ArrowUp') {
      // e.preventDefault();
      handleArrowKeyDown('up');
    }

    if (key === 'ArrowDown') {
      // e.preventDefault();
      handleArrowKeyDown('down');
    }

    if (key === 'Escape') {
      setIsOpen(false);
      setSearchTerm(null);
    }
  };

  const handleClear = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setUseDefaultValue(null);
    handleSelection(null);
    setValue(id, '', { shouldDirty: true });
    if (handleChange) handleChange(null, setValue, index, trigger);

    if (isOpen) {
      setIsOpen(false);
    }
  };

  const getValue = () => {
    return searchTerm || chosen?.label || useDefaultValue || '';
  };

  const parseSecondary = (field: string, data: any) => {
    if (field === 'address') {
      // if address is set, use the city, state, country or nothing
      return `${data.city && data.city !== 'no data' ? data.city : ''}${
        data.state && data.state !== 'no data' ? `, ${data.state}` : ''
      }${data.country && data.country !== 'no data' ? `, ${data.country}` : ''}`;
    } else if (field === 'contacts') {
      // if contact is set, use the first email address or nothing
      const email = data?.find(({ contactType }: { contactType: string }) => contactType.toLowerCase() === 'email');
      const phone = data?.find(
        ({ contactType }: { contactType: string }) =>
          contactType.toLowerCase() === 'mobile phone' || contactType.toLowerCase() === 'office phone'
      );
      let result = email?.contact ? email?.contact + '\n' : '';
      result += phone?.contact ? phone?.contact : '';
      return result;
    }

    return '';
  };

  return (
    <div className="form-field-wrapper">
      {label !== false && <label className="standard-label">{label}</label>}
      <div
        className={`h-10 cursor-pointer w-full border border-solid ${
          hasErrors ? 'border-cinnabar' : 'border-black'
        } select-none relative text-sm
        after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3 ${className}`}
        data-cy={dataCy}
      >
        <div className="w-full h-full">
          {secondaryId && chosen && chosen.data && chosen.data[secondaryId] ? (
            <Tooltip
              classNameOverride="h-full"
              position="below"
              text={parseSecondary(secondaryId, chosen.data[secondaryId])}
            >
              <input
                className="w-full h-full px-3 py-2 disabled:opacity-50"
                type="text"
                autoComplete="off"
                name={name} // assign name prop
                aria-label={name}
                ref={ref} // assign ref prop
                placeholder={placeholder}
                value={getValue()}
                onChange={handleSearch}
                disabled={disabled}
                onKeyDown={handleKeyDown}
                onBlur={() => {
                  if (isRequired && !chosen && searchTerm) {
                    // TODO: This should be refactored to let the component accept a custom error
                    setError(invalidResultsErrorId, {
                      type: 'invalidSearchResults',
                      message: ERROR_MESSAGES.INVALID_SEARCH_RESULTS,
                    });
                  }

                  // Repopulate field with original value if it's empty
                  if (!chosen && previousChosen && !isOpen && !searchTerm) {
                    handleSelection(previousChosen);
                  }
                }}
              />
            </Tooltip>
          ) : (
            <input
              className="w-full h-full px-3 py-2 disabled:opacity-50"
              type="text"
              data-testid="search-input-testid"
              autoComplete="off"
              name={name} // assign name prop
              aria-label={name}
              ref={ref} // assign ref prop
              placeholder={placeholder}
              value={getValue()}
              onChange={handleSearch}
              disabled={disabled}
              onKeyDown={handleKeyDown}
              onBlur={() => {
                if (isRequired && !chosen && searchTerm) {
                  // TODO: This should be refactored to let the component accept a custom error
                  setError(invalidResultsErrorId, {
                    type: 'invalidSearchResults',
                    message: ERROR_MESSAGES.INVALID_SEARCH_RESULTS,
                  });
                }

                // Repopulate field with original value if it's empty
                if (!chosen && previousChosen && !isOpen && !searchTerm) {
                  handleSelection(previousChosen);
                }
              }}
            />
          )}
          {isLoading ? (
            <div className="absolute h-full width-[20px] top-0 flex items-center right-2">
              <Spinner size={20} />
            </div>
          ) : null}

          <button
            className={`${isRequired || chosen === null ? 'hidden' : ''} top-0 absolute right-[40px] h-full`}
            onClick={handleClear}
            data-id="clear"
            disabled={disabled}
          >
            <XMarkIcon width={20} />
          </button>
        </div>

        <ul
          ref={openRef}
          className={`border border-solid border-black list-none m-0 p-0 absolute top-full border-t-0 -left-[1px] -right-[1px] transition-opacity duration-300 max-h-[300px] overflow-y-auto overflow-x-hidden bg-white shadow-header z-10 ${
            options && options.length > 0 ? 'opacity-1 pointer-events-auto z-10' : 'opacity-0 pointer-events-none'
          }`}
          data-testid="dropdown-options-testid"
        >
          {options &&
            isOpen &&
            options.sort(alphabetize).map((option) => (
              <li
                key={option.id}
                className={`px-3 py-2 border-t border-solid border-greyCloudy hover:underline text-sm whitespace-pre-wrap ${
                  option.id === chosen?.id ? 'bg-greyQuill underline' : ''
                }`}
                onClick={() => {
                  handleSelection(option);
                  setIsOpen(false);
                }}
              >
                {option.label}
                {secondaryId && option.data && option.data[secondaryId] && (
                  <>
                    <br />
                    <span className="text-black text-xs">
                      {typeof option.data[secondaryId] === 'string'
                        ? typeof option.data[secondaryId]
                        : parseSecondary(secondaryId, option.data[secondaryId])}
                    </span>
                  </>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
