import { FC } from 'react';
import { Address } from 'types/data-schemas';

export type AddressRendererProps = {
  testId?: string;
  value?: Pick<Address, 'address' | 'city' | 'state' | 'country'>;
};

const AddressRenderer: FC<AddressRendererProps> = ({ testId, value }) =>
  value ? (
    <p data-testid={testId}>
      <span>{value?.address}</span>
      <br />
      <span>
        {value?.city}
        {value?.state && `, ${value?.state}`}
      </span>
      <br />
      <span>{value?.country}</span>
    </p>
  ) : (
    <></>
  );

export default AddressRenderer;
