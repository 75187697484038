import clsx from 'clsx';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

const Root: FC<PropsWithChildren<HTMLAttributes<HTMLTableRowElement>>> = ({ children, className, ...props }) => (
  <tr className={twMerge(clsx('w-full', className))} {...props}>
    {children}
  </tr>
);

export default Root;
