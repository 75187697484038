import { FC } from 'react';

interface ArtistBannerBgProps {
  imageUrl?: string;
}

const ArtistBannerBg: FC<ArtistBannerBgProps> = ({ imageUrl }) => {
  return (
    <div
      className={`w-full h-full min-h-[285px] md:min-h-[300px] absolute top-0 overflow-hidden ${
        !imageUrl && 'bg-cinematicBlur bg-center '
      }`}
    >
      {imageUrl && (
        <>
          <div className="absolute w-full h-full z-[-1] bg-black"></div>
          <div className="relative overflow-hidden w-full h-[285px] md:h-[300px] translate-y-0">
            <div
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
              className={`inline fixed top-0 left-0 w-full h-[300px] bg-[#212529] bg-[length:100vw] bg-[50%_50%] z-[-1] blur-md scale-110 opacity-50  `}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};

export default ArtistBannerBg;
