import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { FieldValues, UseFormSetValue, useFormContext } from 'react-hook-form';

import Button, { ButtonProps } from 'components/Button';

interface iButtonProps extends ButtonProps {
  onClick: (values: FieldValues, setValueMethod?: UseFormSetValue<FieldValues>) => void;
  className?: string;
  updateFormName?: string;
  shouldValidate?: boolean;
  shouldDirty?: boolean;
}

const ButtonWithContext: FC<PropsWithChildren<iButtonProps>> = ({
  children,
  onClick,
  updateFormName,
  className = '',
  shouldValidate = false,
  shouldDirty = false,
  ...props
}) => {
  const { setValue, getValues } = useFormContext();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    const values = getValues();
    const updateValue = onClick(values, setValue);

    if (updateFormName) {
      setValue(updateFormName, updateValue, { shouldValidate, shouldDirty });
    }
  };

  return (
    <Button className={className} variant="primary" onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default ButtonWithContext;
