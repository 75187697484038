import { FC } from 'react';
import Button, { ButtonProps } from 'components/Button';
import cypressTags from 'support/cypressTags';

export type SplitDealButtonProps = {
  isSplit: boolean;
} & ButtonProps;

export const SplitDealButton: FC<SplitDealButtonProps> = ({ isSplit, ...rest }) => (
  <Button dataCy={cypressTags.DEAL_FORM.SPLIT_DEAL_BUTTON} variant="primary" {...rest}>
    {isSplit ? 'Remove Split Deal' : 'Split Deal'}
  </Button>
);
