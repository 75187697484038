import React, { useCallback, useEffect, useMemo } from 'react';
import { ShowsAgendaData } from 'components/ShowsAgenda/types';
import { TableRowData } from 'components/Table/types';
import { useFilters } from 'context/avails-filters/context';
import ShowsAgenda from 'components/ShowsAgenda';
import { INITIAL_STATE } from 'context/avails-filters/hook';

export interface ShowsAgendaViewProps {
  data: ShowsAgendaData;
  selectedData: ShowsAgendaData;
  performingPrint?: boolean;
  onChange?: (data: ShowsAgendaData) => void;
}

const ShowsView: React.FC<ShowsAgendaViewProps> = ({ data, selectedData, performingPrint, onChange }) => {
  const { setActionsEnabled } = useFilters();

  const hasSelection = useMemo(() => data?.some((row: TableRowData) => row?.selected), [data]);

  useEffect(() => {
    setActionsEnabled(hasSelection);

    return () => {
      // Reset when unmounted.
      setActionsEnabled(INITIAL_STATE.ACTIONS_ENABLED);
    };
  }, [hasSelection, setActionsEnabled]);

  const handleOnChange = useCallback(
    (newData: ShowsAgendaData) => {
      onChange && onChange(newData);
    },
    [onChange]
  );

  return (
    <div className="flex flex-col flex-1 w-full pb-8 print:pb-0">
      <ShowsAgenda.Full data={performingPrint ? selectedData : data} onChange={handleOnChange} />
    </div>
  );
};

export default ShowsView;
