import { Dispatch, ReactElement, SetStateAction, useMemo } from 'react';

import iconContacts from '../../assets/icon-contacts.svg';
import iconDeals from '../../assets/icon-deals.svg';
import iconRouting from '../../assets/icon-routing.svg';
import { Dictionary } from '../../types/dictionary';
import { NavItem } from './NavItem';
import { MobileNavItem } from './MobileNavItem';
import { User } from 'types';
import { hasAccessToAccountingQueue, isUserFromAllowedDepartments } from 'utils/helpers';

interface SubNavItem {
  path: string;
  label: string;
}

const CONTACTS_SUB_NAV = [
  { path: '/contacts', label: 'Contacts' },
  { path: '/contacts/venues', label: 'Venues' },
  { path: '/contacts/promoters', label: 'Promoters' },
];
const REPORTS_SUB_NAV = [
  { path: '/reports/deals', label: 'Settled Deals' },
  { path: '/reports/tracking', label: 'Deposit Tracking' },
  { path: '/reports/accountingQueue', label: 'Accounting Queue' },
  { path: '/reports/contracts', label: 'Contract Tracking' },
  { path: '/reports/customReports', label: 'Custom Reports' },
];

const LINKS: Dictionary<{ icon: string; route: string; label: string; subMenu?: SubNavItem[] }> = {
  Routing: { icon: iconRouting, route: '/routing', label: 'Roster' },
  Contacts: { icon: iconContacts, route: '/contacts', label: 'Contacts', subMenu: CONTACTS_SUB_NAV },
  Reports: { icon: iconDeals, route: '/reports', label: 'Reports', subMenu: REPORTS_SUB_NAV },
  Avails: { icon: iconDeals, route: '/availability', label: 'Availability' },
};

const formatLinks = (user: User | null) => {
  if (!isUserFromAllowedDepartments(user)) return {};

  if (hasAccessToAccountingQueue(user)) return LINKS;

  const linksClone = {
    ...LINKS,
  };

  linksClone.Reports.subMenu = linksClone.Reports.subMenu?.filter(
    (option) => option.path !== '/reports/accountingQueue'
  );

  return linksClone;
};

export const Nav = ({
  setIsOpen,
  className = '',
  mobileView,
  user,
}: {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  className: string;
  mobileView: boolean;
  user: User | null;
}): ReactElement => {
  const links = useMemo(() => formatLinks(user), [user]);

  return (
    <div className={className}>
      {Object.keys(links).map((item, index) => {
        const { route, label, subMenu } = links[item];

        return mobileView ? (
          <MobileNavItem
            key={`${index}-${route}`}
            route={route}
            label={label}
            subMenu={subMenu}
            setIsOpen={setIsOpen}
          />
        ) : (
          <NavItem key={`${index}-${route}`} route={route} label={label} subMenu={subMenu} setIsOpen={setIsOpen} />
        );
      })}
    </div>
  );
};
