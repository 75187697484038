import { TableRow } from 'types';

const ShowStatuses = new Set(['Pending', 'Confirmed', 'Cancelled', 'Settled', 'Rescheduling']);
const DraftStatuses = new Set(['Sketch', 'Rejected']);

/**
 * TypeGuard for Show
 *
 * @param { TableRow } row: indeterminate row data
 * @returns {boolean}
 */
export const isShow = (row: TableRow): boolean => ShowStatuses.has(row.status as string);

/**
 * TypeGuard for Draft
 *
 * @param { TableRow } row: indeterminate row data
 * @returns { boolean }
 */
export const isDraft = (row: TableRow): boolean => DraftStatuses.has(row.status as string);

/**
 * TypeGuard for an Empty Row
 * @param { TableRow } row: indeterminate row data
 * @returns { boolean }
 */
export const isEmpty = (row: TableRow): boolean => {
  return !row.status;
};

/**
 * Check if two dates are equal.
 *
 * @param {Date} d1: first date
 * @param {Date} d2: second date
 * @returns {boolean}
 */
export const datesEqual = (d1: Date, d2: Date): boolean =>
  d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();

/**
 * Removes a specific TableRow from the list of TableRows.
 *
 * Accounts for displayDate, prev & nextLocation
 * @param tableRows - current list of tableRows.
 * @param currentRow - row to remove.
 */
export const removeRow = (tableRows: TableRow[], currentRow: TableRow): TableRow[] => {
  const { id, date, clientId, displayDate, prevLocation, nextLocation, clientAvailable } = currentRow;
  const idx = tableRows.findIndex((row) => row.id === currentRow.id);
  let newRows: TableRow[];

  if (displayDate) {
    const sameDateRows = tableRows.filter((row) => {
      return datesEqual(new Date(row.date), new Date(currentRow.date)) && !row.monthBreak;
    });
    if (sameDateRows.length > 1) {
      newRows = tableRows.filter((row) => row.id !== currentRow.id);
      newRows[idx].displayDate = true;
    } else {
      newRows = [...tableRows];
      newRows[idx] = { id, date, clientId, displayDate, prevLocation, nextLocation, clientAvailable };
    }
  } else {
    // remove row entirely... as it's display is not needed.
    newRows = tableRows.filter((row) => row.id !== currentRow.id);
  }
  return newRows;
};
