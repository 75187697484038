import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type MoreButtonProps = {
  dataLength: number;
} & HTMLAttributes<HTMLDivElement>;

const MoreButton: FC<MoreButtonProps> = ({ dataLength, className, ...props }) => (
  <div className={twMerge(clsx('px-2 py-0.5 bg-[#F2F2EF] cursor-pointer group/more-btn', className))} {...props}>
    <p className="text-sm font-bold group-hover/more-btn:underline">{`${dataLength - 1} more`}</p>
  </div>
);

export default MoreButton;
