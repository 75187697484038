export const CATEGORY_NAMES = {
  deal: 'DEAL',
  contract: 'CONTRACT',
  show: 'SHOW DETAILS',
};

export enum SEARCH_TYPE {
  EMPLOYEE = 'employee',
  CONTACT = 'contact',
  VENUE = 'venue',
  COMPANY = 'company',
}

export const ORGANISM_STATUS = {
  UNSAVED_CHANGES: 'UNSAVED CHANGES',
  UNABLE_TO_SAVE: 'UNABLE TO SAVE',
  SUCCESSFULLY_SAVED: 'SUCCESSFULLY SAVED',
};
