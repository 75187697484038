import { StickyHeaderPage } from 'features/layouts';
import { PageHeader } from 'grid-pages/components';
import { PRESETS, PresetReport } from 'grid-pages/pages/PresetReport';
import { ReactElement, useCallback, useState } from 'react';

/**
 * Accounting Queue Editor
 */
export const Accounting = (): ReactElement => {
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const handleOnExport = () => {
    setIsExporting(true);
  };

  const renderFixedContainer = useCallback(() => <PageHeader onExport={handleOnExport} title="Accounting Queue" />, []);

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="main-wrapper">
        <div className="grid-height content-wrapper md:pt-10">
          <PresetReport isExporting={isExporting} setIsExporting={setIsExporting} preset={PRESETS.ACCOUNTING} />
        </div>
      </div>
    </StickyHeaderPage>
  );
};
