import { FC } from 'react';
import BlankCell from '../BlankCell';

export type DealRendererProps = {
  value?: number;
  data?: Record<string, unknown>;
};

const DealRenderer: FC<DealRendererProps> = ({ value, data }) => {
  if (!value) {
    return <BlankCell />;
  }

  return data?._id ? (
    <a href={`/deal/${data?._id}`} target="_blank" className="text-curiousBlue" rel="noopener">
      {value}
    </a>
  ) : (
    <span>{value}</span>
  );
};

export default DealRenderer;
