import { SelectOptionT } from '@united-talent-agency/onyx-components';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { RoutingClient } from 'api/clients-routing';
import {
  DEFAULT_SELECT_OPTION,
  MAX_GUARANTEE,
  MIN_GUARANTEE,
  defaultAvailsSearchResult,
} from 'containers/availability/constants';
import { applyAvailsSearch } from 'api/search-client';
import { AvailsShowType } from 'types/Show';

export interface DateRange {
  start: Date;
  end: Date;
}

interface GuaranteeRange {
  min: number;
  max: number;
}

export interface Position {
  latitude: string;
  longitude: string;
}

export interface AvailsFilters {
  dateRange: DateRange;
  includeConfirmedShows: boolean;
  genres: string[];
  showType: string;
  clients: string[];
  guarantee: GuaranteeRange;
  location: Position | null;
  locationRadius: number;
  includeLocationRadius: boolean;
}

export interface CustomShowsHitType extends AvailsShowType {
  _id: string;
  readonly objectID: string;
  readonly _highlightResult?: {} | undefined;
  readonly _snippetResult?: {} | undefined;
  readonly _rankingInfo?: any;
  readonly _distinctSeqID?: number | undefined;
}

export interface AvailsSearchResult {
  hits: CustomShowsHitType[];
  nbHits: number;
  page: number;
  nbPages: number;
}

export type SortableColums =
  | 'Dates'
  | 'Client'
  | 'Responsible Agent'
  | 'Venue'
  | 'Location'
  | 'Deal Memo #'
  | 'Status'
  | 'Radius'
  | 'Proximity (Mi)'
  | 'Notes';

export type SortOrder = 'asc' | 'desc';

export interface SortBy {
  column: SortableColums;
  order: SortOrder;
}

const INITIAL_DATE_RANGE = { start: new Date(), end: new Date() };
const INITIAL_GUARANTEE_RANGE: GuaranteeRange = { min: MIN_GUARANTEE, max: MAX_GUARANTEE };
const INITIAL_SORTED_BY: SortBy = { column: 'Client', order: 'asc' };

export const INITIAL_STATE = {
  // COMMON
  LOADING: false,
  COLLAPSED: false,
  RESULTS: defaultAvailsSearchResult,

  // DATE
  DATE: INITIAL_DATE_RANGE.start,
  DAYS_AFTER: 0,
  DAYS_BEFORE: 0,
  DATE_RANGE: INITIAL_DATE_RANGE,
  INCLUDE_CONFIRMED_SHOWS: false,

  // CLIENTS
  MY_CLIENTS: [],
  SHOW_ALL_MY_CLIENTS: false,
  SELECTED_CLIENTS: [],

  // GENRES
  SELECTED_GENRES: [],
  GENRES_FOCUSED_VIEW: false,

  // SHOW TYPES
  SELECTED_SHOW_TYPE: DEFAULT_SELECT_OPTION,
  SHOW_TYPES_FOCUSED_VIEW: false,

  // LOCATION
  LOCATION_RADIUS: 0,
  SELECTED_LOCATION: DEFAULT_SELECT_OPTION,
  INCLUDE_LOCATION_RADIUS: false,

  // GUARANTEE
  GUARANTEE_RANGE: INITIAL_GUARANTEE_RANGE,

  // HEADER
  HEADER_COLLAPSED: false,

  // GRID_ACTIONS
  ACTIONS_ENABLED: false,

  // SORT ACTIONS
  SORTED_BY: INITIAL_SORTED_BY,
};

const useFiltersState = () => {
  // START - FILTER STATES

  // ? This shadow of INITIAL_DATE_RANGE constants is to avoid Dates are initiated before the useFiltersState is executed, and make inconsistency during test
  const INITIAL_DATE_RANGE_SHADOW = { start: new Date(), end: new Date() };

  // COMMON
  const [loading, setLoading] = useState<boolean>(INITIAL_STATE.LOADING);
  const [collapsed, setCollapsed] = useState<boolean>(INITIAL_STATE.COLLAPSED);
  const [results, setResults] = useState<AvailsSearchResult>(INITIAL_STATE.RESULTS);

  // DATE
  const [date, setDate] = useState(INITIAL_DATE_RANGE_SHADOW.start);
  const [daysAfter, setDaysAfter] = useState(INITIAL_STATE.DAYS_AFTER);
  const [daysBefore, setDaysBefore] = useState(INITIAL_STATE.DAYS_BEFORE);
  const [dateRange, setDateRange] = useState<DateRange>(INITIAL_DATE_RANGE_SHADOW);
  const [includeConfirmedShows, setIncludeConfirmedShows] = useState<boolean>(INITIAL_STATE.INCLUDE_CONFIRMED_SHOWS);

  // CLIENTS
  const [myClients, setMyClients] = useState<RoutingClient[]>(INITIAL_STATE.MY_CLIENTS);
  const [showAllMyClients, setShowAllMyClients] = useState<boolean>(INITIAL_STATE.SHOW_ALL_MY_CLIENTS);
  const [selectedClients, setSelectedClients] = useState<SelectOptionT[]>(INITIAL_STATE.SELECTED_CLIENTS);

  // GENRES
  const [selectedGenres, setSelectedGenres] = useState<SelectOptionT[]>(INITIAL_STATE.SELECTED_GENRES);
  const [genresFocusedView, setGenresFocusedView] = useState<boolean>(INITIAL_STATE.GENRES_FOCUSED_VIEW);

  // SHOW TYPES
  const [selectedShowType, setSelectedShowType] = useState<SelectOptionT>(INITIAL_STATE.SELECTED_SHOW_TYPE);
  const [showTypesFocusedView, setShowTypesFocusedView] = useState<boolean>(INITIAL_STATE.SHOW_TYPES_FOCUSED_VIEW);

  // LOCATION
  const [locationRadius, setLocationRadius] = useState<number>(INITIAL_STATE.LOCATION_RADIUS);
  const [selectedLocation, setSelectedLocation] = useState<SelectOptionT>(INITIAL_STATE.SELECTED_LOCATION);
  const [includeLocationRadius, setIncludeLocationRadius] = useState<boolean>(INITIAL_STATE.INCLUDE_LOCATION_RADIUS);

  // GUARANTEE
  const [guaranteeRange, setGuaranteeRange] = useState<GuaranteeRange>(INITIAL_STATE.GUARANTEE_RANGE);

  // HEADER CONTROLLER
  const [headerCollapsed, setHeaderCollapsed] = useState<boolean>(INITIAL_STATE.HEADER_COLLAPSED);

  // GRID ACTIONS
  const [actionsEnabled, setActionsEnabled] = useState<boolean>(INITIAL_STATE.ACTIONS_ENABLED);

  // SHOWS VIEW SORT ACTIONS
  const [sortedBy, setSortedBy] = useState<SortBy>(INITIAL_STATE.SORTED_BY);

  // END - FILTER STATES

  // START - SIDE EFFECTS
  useEffect(() => {
    setDateRange({
      start: moment(date).subtract(daysBefore, 'days').toDate(),
      end: moment(date).add(daysAfter, 'days').toDate(),
    });
  }, [daysAfter, daysBefore, date]);

  const mountedFilters: AvailsFilters = useMemo(() => {
    // Check if selectedLocation?.value is defined and not null
    const locationJSON = selectedLocation?.value ? selectedLocation.value.toString() : '{}';

    return {
      dateRange,
      includeConfirmedShows,
      clients: selectedClients.map((client) => client.value?.toString() ?? ''),
      showType: selectedShowType?.value?.toString() ?? '',
      genres: selectedGenres?.map((genre) => genre.value?.toString() ?? ''),
      guarantee: guaranteeRange,
      location: (JSON.parse(locationJSON) as Position) || null,
      includeLocationRadius,
      locationRadius,
    };
  }, [
    dateRange,
    includeConfirmedShows,
    selectedClients,
    selectedShowType,
    guaranteeRange,
    selectedGenres,
    selectedLocation,
    locationRadius,
    includeLocationRadius,
  ]);

  const clearAll = useCallback(() => {
    setDate(INITIAL_DATE_RANGE_SHADOW.start);
    setDaysBefore(0);
    setDaysAfter(0);
    setIncludeConfirmedShows(false);
    setSelectedClients([]);
    setSelectedGenres([]);
    setSelectedShowType(DEFAULT_SELECT_OPTION);
    setGuaranteeRange(INITIAL_GUARANTEE_RANGE);
    setSelectedLocation({});
    setIncludeLocationRadius(false);
    setLocationRadius(0);
  }, []);

  // Check if all my clients are included in the `values` array
  const allMyClientsSelected = useCallback(
    () =>
      Boolean(myClients.length) &&
      myClients.every((client) => selectedClients.some(({ label: { _id } }) => _id === client._id)),
    []
  );

  // Function to perform the search and update results
  const performSearch = useCallback(async () => {
    // Make the API call here to get calendar view data
    try {
      setLoading(true);
      const res = await applyAvailsSearch(process.env.REACT_APP_ALGOLIA_SHOWS_INDEX ?? '', mountedFilters, 200);
      setResults(res);
      setLoading(false);
    } catch (error) {
      console.error('hook.ts - PerformSearch Error: ', error);
      setLoading(false);
    }
  }, [mountedFilters, headerCollapsed, dateRange]);

  useEffect(() => {
    collapsed && performSearch && performSearch();
  }, [collapsed, performSearch]);

  useEffect(() => setShowAllMyClients(allMyClientsSelected()), [selectedClients, myClients]);

  const toggleCollapsed = useCallback(() => setCollapsed((oldState) => !oldState), []);

  // END - SIDE EFFECTS

  return {
    // COMMON
    results,
    loading,
    collapsed,
    mountedFilters,
    clearAll,
    setLoading,
    setCollapsed,
    toggleCollapsed,

    // DATE
    date,
    daysBefore,
    daysAfter,
    includeConfirmedShows,
    dateRange,
    setDate,
    setDaysAfter,
    setDaysBefore,
    setIncludeConfirmedShows,

    // CLIENTS
    selectedClients,
    showAllMyClients,
    myClients,
    setMyClients,
    setSelectedClients,
    setShowAllMyClients,

    // GENRES
    selectedGenres,
    genresFocusedView,
    setSelectedGenres,
    setGenresFocusedView,

    // SHOW TYPE
    selectedShowType,
    showTypesFocusedView,
    setSelectedShowType,
    setShowTypesFocusedView,

    // LOCATION
    locationRadius,
    selectedLocation,
    includeLocationRadius,
    setLocationRadius,
    setSelectedLocation,
    setIncludeLocationRadius,

    // GUARANTEE
    guaranteeRange,
    setGuaranteeRange,

    // HEADER CONTROLLER
    headerCollapsed,
    setHeaderCollapsed,

    // GRID ACTIONS
    actionsEnabled,
    setActionsEnabled,

    // SORT ACTIONS
    sortedBy,
    setSortedBy,
  };
};

export interface IUseFiltersState extends ReturnType<typeof useFiltersState> {}

export default useFiltersState;
