import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Dot } from 'deal-form/ui/Dot';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { iCategoryView } from 'types/deal';

interface DropdownWithToggleProps {
  propsStyles?: string;
  closeToggle?: () => void;
  onUpdate: (values: iCategoryView) => void;
}

const ToggleItem = ({
  color,
  isChecked,
  label,
  onChange,
}: {
  color: string;
  isChecked: boolean;
  label: string;
  onChange: () => void;
}) => {
  return (
    <li>
      <label className="px-4 py-3 flex justify-between items-center w-full cursor-pointer">
        <div className="flex flex-row items-center justify-between">
          <Dot color={color} />
          <span className={`text-greyCod font-bold text-[16px]`}>{label}</span>
        </div>

        <div className="relative inline-block w-12 h-6 outline-none overflow-hidden">
          <input
            type="checkbox"
            className="absolute -top-full -left-full"
            value="contract"
            id="contract"
            checked={isChecked}
            onChange={onChange}
          />
          <div
            className={`
                    absolute top-0 left-0 right-0 bottom-0 ${
                      isChecked ? 'bg-greyCod' : 'bg-[#f2f4f7]'
                    } transition-all duration-300 rounded-full  
                    before:absolute before:h-4 before:w-4 before:left-1 before:bottom-1 ${
                      isChecked ? 'before:bg-white' : 'before:bg-white'
                    } before:transition-all before:duration-300 before:rounded-full ${
                      isChecked ? 'before:translate-x-6' : ''
                    } ${isChecked ? '' : 'before:shadow-toggle'}`}
          />
        </div>
      </label>
    </li>
  );
};

export const DropdownWithToggles = ({ propsStyles, onUpdate }: DropdownWithToggleProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [categoryView, setCategoryView] = useState<iCategoryView>({ contract: true, deal: true, show: true });

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    onUpdate(categoryView);
  }, [categoryView]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const _onClick = () => {
    toggleOpen();
  };

  return (
    <>
      <div
        className={`cursor-pointer w-auto min-w-[145px] border border-solid border-white px-4 py-2 select-none relative -mt-2 z-10 text-sm ${
          isOpen ? 'active' : ''
        }
      after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3 ${propsStyles}`}
        onClick={_onClick}
      >
        <div className="flex flex-row items-center justify-between text-sm text-white">
          Category View
          <ChevronDownIcon width={15} className={`transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`} />
        </div>
        <ul
          ref={ref}
          className={`py-2 w-72 list-none m-0 p-0 absolute mt-1 top-full border-t-0 -left-[1px] -right-[1px] transition-opacity duration-300 overflow-y-auto overflow-x-hidden bg-white shadow-dropdown ${
            isOpen ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'
          }`}
        >
          <ToggleItem
            label="Contract"
            color="bg-goldDrop"
            isChecked={categoryView.contract}
            onChange={() => setCategoryView({ ...categoryView, contract: !categoryView.contract })}
          />
          <ToggleItem
            label="Deal"
            color="bg-candlelight"
            isChecked={categoryView.deal}
            onChange={() => setCategoryView({ ...categoryView, deal: !categoryView.deal })}
          />
          <ToggleItem
            label="Show Details"
            color="bg-sushi"
            isChecked={categoryView.show}
            onChange={() => setCategoryView({ ...categoryView, show: !categoryView.show })}
          />
        </ul>
      </div>
    </>
  );
};
