import { CheckIcon } from '@heroicons/react/24/outline';
import { FC, useMemo } from 'react';

export type BooleanRendererProps = {
  value?: any;
  testId?: string;
  valueFormatted?: boolean;
};

const BooleanRenderer: FC<BooleanRendererProps> = ({ testId, valueFormatted, value }) => {
  const isTrue = useMemo(() => {
    if (valueFormatted !== undefined && valueFormatted === true) {
      return true;
    } else if (value !== undefined && (value === true || value === 'true')) {
      return true;
    }

    return false;
  }, [value, valueFormatted]);

  const show = useMemo(() => valueFormatted !== undefined || value !== undefined, [value, valueFormatted]);

  return (
    <div data-testid={testId} className="flex justify-center">
      {show && <>{isTrue ? <CheckIcon data-testid="check-icon" width={16} color="black" /> : <></>}</>}
    </div>
  );
};

export default BooleanRenderer;
