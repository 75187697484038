import { iUpdateShowPayload } from 'api/shows';
import { iOrganismTicketScalingTier, iOrganismTicketTaxesItem } from 'deal-form/interfaces/organisms';
import { Fee, Show, Tax, Ticket } from 'types';

export const createTicketScalingPayload = (
  show: Show,
  currency: string,
  tiers: iOrganismTicketScalingTier[]
): iUpdateShowPayload => {
  const showTickets: Ticket[] = tiers?.map((tier, index) => ({
    currency: currency.toUpperCase() || 'USD',
    advance: tier.advance.value,
    note: tier.note,
    available: +tier.available,
    comp: +tier.comps,
    dayOfShow: tier.dayOf.value || 0,
    tier: tier.tier,
    taxes: show.ticketTiers[index]?.taxes || [],
    fees: show.ticketTiers[index]?.fees || [],
    sellableCapacity: tier.sellable || 0,
    grossPotential: tier.gross || 0,
  }));

  return {
    showId: show._id,
    updateBody: {
      ticketTiers: showTickets,
    },
  };
};

export const createTicketScalingCombinedPayload = (
  show: Show,
  currency: string,
  scalingTiers: iOrganismTicketScalingTier[],
  taxesTiers: iOrganismTicketTaxesItem[]
): iUpdateShowPayload => {
  const showTickets: Ticket[] = scalingTiers?.map((scalingTier) => {
    return {
      currency: currency.toUpperCase() || 'USD',
      advance: scalingTier.advance.value,
      note: scalingTier.note,
      available: +scalingTier.available,
      comp: +scalingTier.comps,
      dayOfShow: scalingTier.dayOf.value || 0,
      tier: scalingTier.tier,
      taxes: (taxesTiers || []).map(({ fee, calculation, description, mathType }) => {
        return {
          note: description,
          percent: fee,
          beforeFee: calculation === 'true',
          type: mathType,
        } as Tax;
      }),
      fees: (scalingTier.fees || []).map(({ fee, calculation, description, percent, feeType }) => {
        return {
          amount: fee?.value,
          percent,
          percentFlag: feeType.toLowerCase() === 'percentage',
          type: calculation,
          description,
        } as Fee;
      }),
      sellableCapacity: scalingTier.sellable || 0,
      grossPotential: scalingTier.gross || 0,
    };
  });

  return {
    showId: show._id,
    updateBody: {
      ticketTiers: showTickets,
    },
  };
};
