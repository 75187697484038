import { ICellEditorParams, KeyCode } from 'ag-grid-community';
import React, {
  KeyboardEventHandler,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { CSSObjectWithLabel, SingleValue, StylesConfig } from 'react-select/dist/declarations/src';
import SelectComponent from 'react-select/dist/declarations/src/Select';

import CypressTags from '../../../../support/cypressTags';

export type ValueType = {
  _id: string;
  name: string;
  color?: string;
  [key: string]: any; // eslint-disable-line
};

export type SimpleOption = {
  value: string;
  name?: string;
  color?: string;
};

const OptionLabel = (value: string): ReactNode => {
  return <div data-cy={CypressTags.DROPDOWN_OPTION}>{value}</div>;
};

export const generateAsyncDropdown = (
  componentName: string,
  prepareOptions: (newInput: string) => Promise<ValueType[]>
): React.ForwardRefExoticComponent<ICellEditorParams & React.RefAttributes<unknown>> => {
  const component = forwardRef((props: ICellEditorParams, ref) => {
    /**
     * Initialize the value being handled inside this component.
     */
    const selectRef = useRef<SelectComponent<ValueType>>(null);
    const [inputValue, setInputValue] = useState('');

    const createInitialState = () => {
      if (props.eventKey === KeyCode.BACKSPACE || props.eventKey === KeyCode.DELETE || !props.value) {
        return {
          name: '',
        };
      } else if (props.charPress) {
        return {
          name: props.charPress,
        };
      } else {
        return props.value;
      }
    };

    const [componentValue, setComponentValue] = useState<SingleValue<ValueType>>(createInitialState() as ValueType);

    /**
     * onMounted.
     */
    useEffect(() => {
      // Set focus on select input
      setTimeout(() => selectRef.current?.focus(), 0);
    }, []);

    useEffect(() => {
      if (props.charPress) {
        //  This allows the first key to register into the input field,forcing an "input" state
        setInputValue((inputValue + props.charPress) as string);
      }
    }, []);

    /**
     * Component Editor Lifecycle methods.
     */
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return componentValue;
        },

        isCancelBeforeStart() {
          return false;
        },

        isCancelAfterEnd() {
          return false;
        },
      };
    });

    /**
     * Prepare select options.
     */
    const loadOptions = (selectInput: string) =>
      new Promise<ValueType[]>((resolve) => {
        if (selectInput.length < 3) {
          resolve([]);
        } else {
          prepareOptions(selectInput)
            .then((results) => {
              resolve(results);
            })
            .catch(console.error);
        }
      });

    /**
     * When field is being updated.
     */
    const onChange = (newValue: SingleValue<ValueType>) => {
      // this ONLY fires when the field is changed then 'onBlur'/'OnFocusOut/ event is called
      setComponentValue(newValue);
    };

    /**
     * When keypress is triggered.
     */
    const onKeyDown: KeyboardEventHandler = (event) => {
      // This ONLY fires in a "input"state, not a component state.
      if (event.code === 'Enter') {
        setTimeout(() => props.stopEditing(), 10);
      }
    };
    const changeHandler = (newValue: string) => {
      // handler for changes to input field
      setInputValue(newValue);
    };
    /**
     * Style of the select component.
     */
    const style = {
      control: (provided) => ({
        ...provided,
        width: '100%',
        borderWidth: 0,
        boxShadow: '0',
      }),
    } as StylesConfig<ValueType>;

    return (
      <div data-row-id={props.data?.id}>
        <AsyncSelect
          ref={selectRef}
          cacheOptions
          defaultOptions
          placeholder="Type to search..."
          loadOptions={loadOptions}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o._id}
          formatOptionLabel={(o) => OptionLabel(o.name)} // will this work?
          value={componentValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          styles={style}
          inputValue={inputValue}
          onInputChange={changeHandler}
        />
      </div>
    );
  });

  component.displayName = componentName;

  return component;
};

export const generateSimpleDropdown = (
  componentName: string,
  options: SimpleOption[] | ((props: ICellEditorParams) => SimpleOption[])
): React.ForwardRefExoticComponent<ICellEditorParams & React.RefAttributes<unknown>> => {
  const component = forwardRef((props: ICellEditorParams, ref) => {
    /**
     * Initialize the value being handled inside this component.
     */
    const createInitialState = () => {
      if (props.eventKey === KeyCode.BACKSPACE || props.eventKey === KeyCode.DELETE || !props.value) {
        return { value: '' };
      } else if (props.charPress) {
        return { value: props.charPress };
      } else {
        return { value: props.value || '' };
      }
    };

    const selectRef = useRef<SelectComponent<SimpleOption>>(null);
    const [value, setValue] = useState<SingleValue<SimpleOption>>(createInitialState() as SimpleOption);

    /**
     * onMounted.
     */
    useEffect(() => {
      // Set focus on select input
      setTimeout(() => selectRef.current?.focus(), 0);
    }, []);

    /**
     * Component Editor Lifecycle methods.
     */
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value?.value || '';
        },

        isCancelBeforeStart() {
          return false;
        },

        isCancelAfterEnd() {
          return false;
        },
      };
    });

    /**
     * When field is being updated.
     */
    const onChange = (newValue: SingleValue<SimpleOption>) => {
      setValue(newValue);
    };

    /**
     * When keypress is triggered.
     */
    const onKeyDown: KeyboardEventHandler = (event) => {
      if (event.code === 'Enter') {
        setTimeout(() => props.stopEditing(), 10);
      }
    };

    /**
     * Generate color styles.
     *
     * @param {string} color
     * @returns {CSSObjectWithLabel}
     */
    const dot = (color = 'transparent'): CSSObjectWithLabel => ({
      alignItems: 'center',
      display: 'flex',

      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });

    /**
     * Style of the select component.
     */
    const style = {
      control: (provided) => ({
        ...provided,
        width: '100%',
        borderWidth: 0,
        boxShadow: '0',
      }),
      option: (provided, { data }) => ({ ...provided, ...(data.color ? dot(data.color) : {}) }),
      singleValue: (provided, { data }) => ({ ...provided, ...(data.color ? dot(data.color) : {}) }),
      input: (provided) => ({ ...provided, ...(value?.color ? dot(value?.color) : {}) }),
    } as StylesConfig<SimpleOption>;

    return (
      <div>
        <Select
          ref={selectRef}
          isSearchable={false}
          options={typeof options === 'function' ? options(props) : options}
          getOptionLabel={(o) => o.name || o.value}
          getOptionValue={(o) => o.value}
          formatOptionLabel={(o) => OptionLabel(o.value)}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          menuIsOpen={true}
          styles={style}
        />
      </div>
    );
  });

  component.displayName = componentName;

  return component;
};
