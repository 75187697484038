/**
 * CALCULATIONS MODULE
 *
 * takes an array of equestions and runs them in order
 * on the field(s) that are provided in the equations array
 *
 * @id          string    field id
 * @equation    array     equestions to run
 *    @type     enum      type of equation
 *    @fields   string[]  ids of the fields to apply to the equation
 * @index       number    if the calculation is part of an array field this index is injected
 * @currency    string    if there is a currency, this will render it appropriately
 *
 */
import clsx from 'clsx';
import { TICKETING_CURRENCIES } from 'deal-form/data/constants';
import { formatCurrency } from 'deal-form/helpers/dealHelpers';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface iEquation {
  type: 'add' | 'subtract' | 'multiply' | 'divide';
  fields: string[];
}

interface iCalculationProps {
  id: string;
  equation?: iEquation[];
  index?: number;
  currency?: string;
  containerClassName?: string;
  dataCy?: string;
}

export const Calculation = ({
  id,
  equation,
  index,
  currency,
  containerClassName = '',
  dataCy,
  ...props
}: iCalculationProps) => {
  const [tallied, setTallied] = useState(0);

  const { register, control, getValues, resetField } = useFormContext();
  const watchAllFields = useWatch({ control });

  useEffect(() => {
    // Using `resetField` instead of `setValue` helps reset the default value to the new value.
    // This is to avoid the field showing as modified (dirty) when its value is changed externally.
    resetField(id, { defaultValue: tallied });
  }, [tallied]);

  useEffect(() => {
    let result = 0;
    equation?.forEach(({ type, fields }) => {
      let subtraction = -1;
      switch (type) {
        case 'add':
          fields.forEach((field) => {
            const fieldId =
              field.indexOf('index') !== -1 && index !== undefined ? field.replace('index', String(index)) : field;
            subtraction = subtraction === -1 ? getValues(fieldId) : subtraction + getValues(fieldId);
          });
          break;
        case 'subtract':
          fields.forEach((field) => {
            const fieldId =
              field.indexOf('index') !== -1 && index !== undefined ? field.replace('index', String(index)) : field;
            subtraction = subtraction === -1 ? getValues(fieldId) : subtraction - getValues(fieldId);
          });
          break;
        case 'multiply':
          fields.forEach((field) => {
            const fieldId =
              field.indexOf('index') !== -1 && index !== undefined ? field.replace('index', String(index)) : field;
            subtraction = subtraction === -1 ? getValues(fieldId) : subtraction * getValues(fieldId);
          });
          break;
        default:
          break;
      }
      result += subtraction;
    });

    setTallied(result);
  }, [id, watchAllFields]);

  return (
    <div className={twMerge(clsx('form-field-wrapper px-2', containerClassName))}>
      <p
        data-cy={dataCy}
        {...register(id)}
        className="w-full whitespace-pre-line min-h-[2.5rem] text-sm leading-10"
        {...props}
      >
        {currency && TICKETING_CURRENCIES.find((cur) => cur.id.toLowerCase() === currency.toLowerCase())?.symbol}
        {currency ? formatCurrency(getValues(id)) : getValues(id)}
        {currency && ` ${currency}`}
      </p>
    </div>
  );
};

Calculation.defaultProps = {
  containerClassName: '',
};
