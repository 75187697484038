import { updateContract } from 'api';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  CONTRACT_INFO_EMPTY_FORM,
  CONTRACT_STATUSES_PHASE1,
  CONTRACT_STATUSES_PHASE2,
  CONTRACT_STATUSES_PHASE3,
  CONTRACT_TEMPLATE_TYPES,
  CONTRACT_WIRE_ACCOUNTS,
} from 'deal-form/data/organisms';
import { iFormProps } from 'deal-form/interfaces/general';
import { iOrganismContractInfo } from 'deal-form/interfaces/organisms';
import { iContractSignerBlockOptions } from 'deal-form/interfaces/general';
import { MasterDealWarning } from 'deal-form/ui/MasterDealWarning';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Deal } from 'types';
import { selectContractSignerOptionsBasedOnTerritoryInfo } from 'utils/helpers';
import { DateInput } from 'deal-form/form-controls/DateInput';
import { Dropdown } from '../../form-controls/Dropdown';
import { Form } from '../../form-controls/Form';
import { Label } from '../../form-controls/Label';
import { getMasterShow } from '../../helpers/showHelpers';
import submitToNetsuite from 'utils/submitToNetsuite';
import cypressTags from 'support/cypressTags';

const ContractInfoForm: React.FC<iFormProps> = ({ contract, deal, setContract, isOpen, locked }) => {
  const [contractData, setContractData] = useState<Partial<iOrganismContractInfo> | null>(null);
  const [statusOptions, setStatusOptions] = useState(CONTRACT_STATUSES_PHASE1);
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [filteredWireAccounts, setFilteredWireAccounts] = useState(CONTRACT_WIRE_ACCOUNTS);
  const [contractSignerBlockOptions, setContractSignerBlockOptions] = useState<iContractSignerBlockOptions[]>([]);

  const getWireTransferAccountOptions = (contractInfo: any, wireAccounts: any) => {
    const wireTransferId = contractInfo.utaWireTransfer;
    const wireTransferAccount = wireAccounts.filter((account: any) => account.id === wireTransferId);
    setFilteredWireAccounts(wireTransferAccount);
  };

  const emptyUtaWireTransfer = [
    {
      id: '0',
      label: 'No Results, Change Accounting Info',
    },
  ];

  useEffect(() => {
    if (contract) {
      getWireTransferAccountOptions(contract, CONTRACT_WIRE_ACCOUNTS);
      let dealToUse = deal;
      if (contract && contract.masterDeal && contract.crossed !== 'Neither') {
        dealToUse = contract.masterDeal;
        setMasterDeal(contract.masterDeal);
      } else {
        setMasterDeal(null);
      }

      const { shows } = dealToUse;
      let dueDate: Date;
      if (shows && shows.length) {
        //if no due date is set, it should auto populate to 30 days before first show
        const momentDate = moment.utc(getMasterShow(shows).date);
        dueDate = momentDate.subtract(30, 'days').toDate();
      }

      const { template, utaWireTransfer, due, clientContractSignerBlockLabel } = contract;

      if (due) {
        dueDate = due;
      }

      const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';
      const isTotalFinalsSubmittedToNetsuite = deal.finals;

      if (!isDealSubmittedToNetsuite) {
        setStatusOptions(CONTRACT_STATUSES_PHASE1);
      } else if (isDealSubmittedToNetsuite && !isTotalFinalsSubmittedToNetsuite) {
        setStatusOptions(CONTRACT_STATUSES_PHASE2);
      } else if (isDealSubmittedToNetsuite && isTotalFinalsSubmittedToNetsuite) {
        setStatusOptions(CONTRACT_STATUSES_PHASE3);
      }

      const defaultValues: iOrganismContractInfo = {
        status: contract.status || '',
        template: template || 'Music LA',
        wireTransfer: utaWireTransfer,
        contractDue: dueDate!,
        clientContractSignerBlockLabel: clientContractSignerBlockLabel || '--',
      };

      setContractData(defaultValues);
      setContractSignerBlockOptions(
        selectContractSignerOptionsBasedOnTerritoryInfo(
          deal?.client?.territoryInfo?.contractSignerBlocks || [],
          contract?.financeInfo?.territory
        )
      );
    } else {
      setContractData(CONTRACT_INFO_EMPTY_FORM);
    }
  }, [contract, deal]);

  const onSubmit = async (data: iOrganismContractInfo) => {
    if (setContract && contract) {
      const newContractInfo = {
        due: moment(data.contractDue).utc().toDate(),
        template: data.template,
        status: data.status,
        utaWireTransfer: data.wireTransfer,
        contractSignerLabel: data.clientContractSignerBlockLabel || null,
      };

      try {
        setCanSubmit(false);
        const updatedContract = await updateContract(contract._id, {
          ...contract,
          ...newContractInfo,
        });

        setContract(updatedContract);

        const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';

        if (data.status === 'Confirmed' && !isDealSubmittedToNetsuite) {
          submitToNetsuite(contract, deal, false, undefined, setContract);
        } else if (isDealSubmittedToNetsuite) {
          submitToNetsuite(contract, deal, false);
        }

        setTimeout(() => setCanSubmit(true), 500);
      } catch (e) {
        console.error('there was an error processing your request', e);
        setCanSubmit(true);
      }
    }
  };

  return (
    contractData && (
      <>
        {masterDeal && contract && (
          <MasterDealWarning crossed={contract.crossed === 'Crossed'} fields={['All Contract Info Fields']} />
        )}
        <Form
          canSubmit={canSubmit}
          className="fixed-first-column-with-labels items-center"
          onSubmit={onSubmit}
          defaultValues={contractData}
          disabled={!isOpen || locked}
          formName={FORM_NAMES.CONTRACT_INFO}
        >
          <Label isRequired>Template</Label>
          <Dropdown
            label={false}
            placeholder="Template"
            options={CONTRACT_TEMPLATE_TYPES}
            id="template"
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            dataCy={cypressTags.DEAL_FORM.CONTRACT_INFO.TEMPLATE}
          />

          <Label isRequired>UTA Wire Transfer</Label>
          <Dropdown
            label={false}
            placeholder="UTA Wire Transfer"
            options={filteredWireAccounts || emptyUtaWireTransfer[0]}
            id="wireTransfer"
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            dataCy={cypressTags.DEAL_FORM.CONTRACT_INFO.WIRE_TRANSFER_DROPDOWN}
          />

          <Label>Contract Signer Block</Label>
          {contractSignerBlockOptions.length === 0 ? (
            <div className="text-sm">--</div>
          ) : (
            <Dropdown
              label={false}
              placeholder={''}
              options={contractSignerBlockOptions}
              id="clientContractSignerBlockLabel"
              disabled={!isOpen || locked || contractSignerBlockOptions.length === 0}
            />
          )}

          <Label className="col-start-3" isRequired>
            Status
          </Label>
          <Dropdown
            label={false}
            placeholder="Contract Status"
            id="status"
            options={statusOptions}
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            dataCy={cypressTags.DEAL_FORM.CONTRACT_INFO.STATUS}
          />

          <Label isRequired>Contract Due</Label>
          <DateInput
            ignoreTime
            placeholder="Due"
            id="contractDue"
            disabled={!isOpen || locked}
            rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
          />
        </Form>
      </>
    )
  );
};

export default ContractInfoForm;
