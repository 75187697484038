import { TrashIcon } from '@heroicons/react/24/outline';
import { DatePicker } from 'antd';
import { getColDefs, getReports } from 'api/grid-pages';
import { FilterModel } from 'grid-pages/interfaces';
import GridTable, { checkboxColumn } from 'grid-pages/organisms/GridTable';
import { getConfigFromData } from 'grid-pages/utils/configs';
import { RANGES, getInitialDateRange } from 'grid-pages/utils/date-utils';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/Button';
import { DropdownWithAction, IdString } from 'components/dropdown-with-action/DropdownWithAction';

import dayjs from 'dayjs';
import { dateToUTC, getDateFormatByBrowser } from 'utils/helpers';
import { DeleteReportModal, NewReportModal } from 'grid-pages/components';

interface iCustomReports {
  isExporting?: boolean;
  setIsExporting?: (val: boolean) => void;
  setTitle: Dispatch<SetStateAction<string>>;
}

const { RangePicker } = DatePicker;

export const CustomReports = ({ isExporting, setIsExporting, setTitle }: iCustomReports) => {
  const navigate = useNavigate();

  // States
  const [columnDefs, setColumnDefs] = useState<any[] | null>(null);
  const [reports, setReports] = useState<any[] | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [chosenReport, setChosenReport] = useState<IdString | null>(null);
  const [reportToDelete, setReportToDelete] = useState<IdString | null>(null);
  const [filterModel, setFilterModel] = useState<FilterModel | null>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showColumns, setShowColumns] = useState<boolean>(false);

  const colDefs = useRef<
    {
      name: string;
      position: number;
    }[]
  >([]);

  let { reportId } = useParams();

  useEffect(() => {
    if (reportId && reports) {
      const reportToLoad = reports.find((rep) => rep._id === reportId);

      if (!reportToLoad) {
        return navigate('/reports/customReports');
      }

      setChosenReport({ id: reportToLoad._id, label: reportToLoad.name });
      setTitle(reportToLoad.name);

      const parsedCols = getConfigFromData(reportToLoad.columns);

      setFilterModel(reportToLoad.filterModel || null);
      let tempParse = parsedCols.filter(
        (col) => col.field !== 'actualBuyoutsTotal' && col.colId !== 'accountingTotalEarned'
      );
      setColumnDefs(tempParse);

      // TODO: set on load properly
      if (reportToLoad.dateRange) {
        setStartDate(reportToLoad.dateRange.start);
        setEndDate(reportToLoad.dateRange.end);
      } else {
        const dateRange = getInitialDateRange(RANGES.THREE_MONTHS);
        setStartDate(dateRange.startDate);
        setEndDate(dateRange.endDate);
      }
    } else if (reportId === undefined && reports) {
      const dateRange = getInitialDateRange(RANGES.THREE_MONTHS);
      setStartDate(dateRange.startDate);
      setEndDate(dateRange.endDate);

      setColumnDefs(null);
      setChosenReport(null);
      setFilterModel(null);
      setTitle('Custom Reports');

      fetchColumnConfigs();
    }
  }, [reportId, reports?.length]);

  const fetchColumnConfigs = async () => {
    const cols = await getColDefs();
    const parsedCols = getConfigFromData(cols);
    let tempParse = parsedCols.filter(
      (col) => col.field !== 'actualBuyoutsTotal' && col.colId !== 'accountingTotalEarned'
    );
    setColumnDefs(tempParse);
  };

  const handleCreateReport = (reportOption: IdString) => {
    // Refresh report template options and chosen template
    fetchReportTemplates().then(() => {
      setChosenReport(reportOption);
      setTitle(reportOption.label);
    });
  };

  const fetchReportTemplates = async () => {
    const reportsData = await getReports();
    setReports(reportsData);
  };

  useEffect(() => {
    // this is the default date range, should not be used if there is a saved range
    const dateRange = getInitialDateRange(RANGES.THREE_MONTHS);
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);

    // set the filters to the start and end date
    const defaultFilter = {
      startDate: {
        dateFrom: moment.utc(dateRange.startDate).format('YYYY-MM-DD hh:mm:ss'),
        dateTo: moment.utc(dateRange.endDate).format('YYYY-MM-DD hh:mm:ss'),
        filterType: 'date',
        type: 'inRange',
      },
    };

    setFilterModel(defaultFilter);

    fetchReportTemplates();
  }, []);

  const handleGridColumnsChange = (
    gridColDefs: {
      name: string;
      position: number;
    }[]
  ) => {
    // TODO: set on load
    colDefs.current = gridColDefs;
  };

  const resetReport = async () => {
    setColumnDefs(null);
    setChosenReport(null);

    // set the filters to the start and end date
    const defaultFilter = {
      startDate: { dateFrom: startDate, dateTo: endDate, filterType: 'date', type: 'inRange' },
    };

    setFilterModel(defaultFilter);
    setTitle('Custom Reports');
  };

  const handleFilterToggle = () => {
    setShowFilters(!showFilters);
  };

  const handleColumnToggle = () => {
    setShowColumns(!showColumns);
  };

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      {isModalOpen && (
        <NewReportModal
          title="Save your report"
          showModal={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          colDefs={colDefs.current}
          onCreateReport={handleCreateReport}
          filterModel={filterModel}
          onValidate={(name, setIsValidMethod) => {
            const isValid =
              !reports?.find((report) => report.name.toLowerCase() === name.toLowerCase()) && name.trim() !== '';
            setIsValidMethod(isValid);
          }}
          start={startDate?.toString() || ''}
          end={endDate?.toString() || ''}
        />
      )}
      {isDeleteModalOpen && reportToDelete && (
        <DeleteReportModal
          title="Are you sure you want to delete this custom report?"
          showModal={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          reportToDelete={reportToDelete}
          onDeleteReport={resetReport}
        />
      )}
      {reports && (
        <div className="py-4 mx-4 md:mx-0 flex justify-between flex-col gap-2 md:flex-row">
          <div className="flex items-center">
            <RangePicker
              id="startDate"
              defaultValue={[dayjs(startDate), dayjs(endDate)]}
              format={getDateFormatByBrowser()}
              onChange={(value: any) => {
                setStartDate(dateToUTC(value?.[0]?.$d) || null);
                setEndDate(dateToUTC(value?.[1]?.$d) || null);
              }}
              className="flex flex-1 h-10 peer w-full appearance-none border border-solid border-black px-2 py-1 text-sm"
            />
          </div>

          {/* TODO Move all this to the page header */}
          <div className="flex gap-x-2">
            <DropdownWithAction
              label="Saved Reports"
              items={reports.map((report: any) => {
                return {
                  id: report._id,
                  label: report.name,
                };
              })}
              onChange={(chosen) => {
                navigate(`/reports/customReports/${chosen.id}`);
              }}
              value={chosenReport}
              listStyles={`bg-white max-h-[50vh] overflow-y-auto min-w-[240px] top-[45px]`}
              onActionClick={(item) => {
                setReportToDelete(item);
                setIsDeleteModalOpen(true);
              }}
              actionSlot={<TrashIcon className="w-4 h-4" />}
            />
            <Button onClick={() => setIsModalOpen(true)}>Save Report</Button>
            <Button
              rightIcon={
                <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M31.8751 15L26.875 20V23.125L23.125 26.875V20L18.125 15V13.75H31.8751V15ZM20 15L24.375 19.375V23.75L25.625 22.5V19.375L30 15H20Z"
                    fill="black"
                  />
                </svg>
              }
              onClick={handleFilterToggle}
            >
              <span className="hidden md:block">Filters</span>
            </Button>
            <Button onClick={handleColumnToggle}>
              <span className="hidden md:block">Edit Columns</span>
            </Button>
          </div>
        </div>
      )}
      {columnDefs ? (
        <GridTable
          pageId="custom"
          rowSelection="multiple"
          isExporting={isExporting}
          endDate={endDate}
          startDate={startDate}
          showSideBar={true}
          showFilters={showFilters}
          showColumns={showColumns}
          columnDefs={[checkboxColumn, ...columnDefs]}
          customFilterModel={filterModel}
          setIsExporting={setIsExporting}
          setCustomFilterModel={setFilterModel}
          onColumnsChange={handleGridColumnsChange}
        />
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};
