import clsx from 'clsx';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type ClientNameProps = {
  isCollapsed: boolean;
  name: string;
  url: string;
};

export const ClientName: FC<ClientNameProps> = ({ isCollapsed, url, name }) => (
  <div
    className={twMerge(
      clsx('font-extended text-white uppercase cursor-pointer flex items-center', {
        'ml-6 text-[32px] leading-[35px] md:text-lg md:ml-0': isCollapsed,
        'text-[32px] leading-[35px]': !isCollapsed,
      })
    )}
  >
    <a
      className="mr-2 font-bold tracking-wide md:font-normal md:tracking-normal"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  </div>
);
