import { algoliaClient } from 'api/search-client';
import { ISearchParams, RadiusUnit } from 'pages/venue';
import React, { useEffect, useState } from 'react';
import { IdName } from 'types';

import { DropdownWithSearch } from 'components/dropdown-with-search/DropdownWithSearch';
import { TextInput } from 'components/input/TextInput';
import { Pill } from 'components/pill/NewPill';
import { NumberInput } from 'components/input/NumberInput';
import { getOptions } from 'utils/locationiq';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { Position } from 'context/avails-filters/hook';

interface VenueSearchProps {
  setSearchParams: (params: ISearchParams) => void;
}

export default function VenueSearch({ setSearchParams }: VenueSearchProps) {
  const [venueName, setVenueName] = useState('');

  const [cityResults, setCityResults] = useState<Array<IdName>>([]);
  const [venueTypeResults, setVenueTypeResults] = useState<Array<IdName>>([]);

  const [chosenCities, setChosenCities] = useState<Array<string>>([]);
  const [choosenLocations, setChosenLocations] = useState<Array<Position>>([]); // [{latitude: 123, longitude: 123}
  const [chosenVenueTypes, setChosenVenueTypes] = useState<Array<string>>([]);
  const [minCapacity, setMinCapacity] = useState<string | undefined>(undefined);
  const [maxCapacity, setMaxCapacity] = useState<string | undefined>(undefined);
  const [radiusUnit, setRadiusUnit] = useState<RadiusUnit>();
  const [radius, setRadius] = useState<number | undefined>(undefined);

  const index = process.env.REACT_APP_ALGOLIA_VENUE_INDEX || '';
  const searchIndex = algoliaClient.initIndex(index);

  const getLocation = useDebouncedCallback(async (searchText: string) => {
    try {
      const options = await getOptions(searchText);

      setCityResults(options.map((option) => ({ id: option.label, name: option.label, value: option.value })));
    } catch (error) {
      console.log('error', error);
      setCityResults([]);
    }
  }, 100);

  const getVenueTypes = () => {
    searchIndex.searchForFacetValues('venueType', '').then(({ facetHits }) => {
      const results: IdName[] = facetHits.map((hit) => {
        return {
          id: hit.value,
          name: `${hit.value}`,
        };
      });
      setVenueTypeResults(results);
    });
  };

  const handleChangeRadius = (value: string) => {
    setRadius(Number(value));
  };

  useEffect(() => {
    getVenueTypes();
  }, []);

  useEffect(() => {
    doSearch();
  }, [chosenCities, venueName, chosenVenueTypes, minCapacity, maxCapacity, radiusUnit, radius]);

  const doSearch = async () => {
    const params = {
      name: venueName,
      cities: chosenCities,
      venueTypes: chosenVenueTypes,
      minCapacity: minCapacity,
      maxCapacity: maxCapacity,
      radiusUnit: radiusUnit,
      radius: radius,
      locations: choosenLocations,
    };
    setSearchParams(params);
  };

  const getNewChosen = (chosenArr: string[], value: string) => {
    const newValues = chosenArr.filter((val) => val !== value);
    return newValues;
  };

  const handleUpdateCity = (value: IdName | null) => {
    if (value && !chosenCities.includes(value.id)) {
      const newValues: string[] = [...chosenCities, value?.id?.split(',')[0].trim()];
      setChosenCities(newValues);
      const newLocations: Position[] = [...choosenLocations, JSON.parse(value?.value?.toString() || '{}') as Position];
      setChosenLocations(newLocations);
    }
  };

  return (
    <div>
      <div className="my-4 flex gap-4">
        <TextInput placeholder="Text Search" onChange={(value) => setVenueName(value)} />
      </div>

      <div className="my-4 flex gap-4">
        <div className="w-1/2 lg:w-[360px]">
          <DropdownWithSearch
            placeholder="City, State, Country"
            options={cityResults}
            clearOnChosen={true}
            onChange={async (value) => await getLocation(value)}
            onUpdate={handleUpdateCity}
          />
        </div>
        <div className="w-[180px] relative">
          <NumberInput placeholder="Radius" onChange={(value) => handleChangeRadius(value)} />
          <div className="absolute opacity-100 right-0 top-1/2 transform -translate-y-1/2 text-sm text-grey flex flex-row justify-center items-center">
            {['km', 'mi'].map((unit, idx) => (
              <React.Fragment key={unit}>
                <div
                  className={`cursor-pointer px-2 ${idx === 1 ? 'rounded-r' : ''} py-2 ${
                    idx === 0 ? '-mr-[2px]' : '-ml-[2px]'
                  } w-9 text-center ${radiusUnit === unit ? 'bg-greyTapa text-white' : ''}`}
                  onClick={() => setRadiusUnit(unit as RadiusUnit)}
                >
                  {unit}
                </div>
                {idx === 0 && <span>|</span>}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="my-4 flex gap-4">
        <DropdownWithSearch
          placeholder="Venue Type"
          options={venueTypeResults}
          clearOnChosen={true}
          onUpdate={(value) => {
            if (value && !chosenVenueTypes.includes(value.id)) {
              const newValues: string[] = [...chosenVenueTypes, value?.id];
              setChosenVenueTypes(newValues);
            }
          }}
        />

        <div className="flex items-center">
          <span className="standard-label">Capacity:</span>
          <NumberInput placeholder="Min Capacity" onChange={(value: string) => setMinCapacity(value)} />
          <span className="mx-2 text-sm">to</span>
          <NumberInput placeholder="Max Capacity" onChange={(value: string) => setMaxCapacity(value)} />
        </div>
      </div>

      <div className="flex gap-2 mb-4">
        {chosenCities.map((city, i) => (
          <Pill
            key={city + i}
            pillText={`City: ${city}`}
            onClear={() => {
              setChosenCities(getNewChosen(chosenCities, city));
            }}
          />
        ))}
        {chosenVenueTypes.map((venueType, i) => (
          <Pill
            key={venueType + i}
            pillText={`Venue Type: ${venueType}`}
            onClear={() => {
              setChosenVenueTypes(getNewChosen(chosenVenueTypes, venueType));
            }}
          />
        ))}
      </div>
    </div>
  );
}
