import { createContext, useContext, ReactNode } from 'react';
import markerSDK from '@marker.io/browser';

type MarkerWidget = ReturnType<typeof markerSDK.loadWidget>;

interface MarkerContextType {
  load: () => void;
  unload: () => void;
}

const MarkerContext = createContext<MarkerContextType | undefined>(undefined);

const MarkerProvider = ({ children }: { children: ReactNode }) => {
  let widgetInstance: MarkerWidget | null = null;
  let isLoading: boolean = false;

  const load = async () => {
    if (isLoading || widgetInstance) return;

    isLoading = true;

    widgetInstance = await markerSDK.loadWidget({
      project: process.env.REACT_APP_MARKER || '',
      useNativeScreenshot: true,
    });

    isLoading = false;
  };

  const unload = () => {
    if (isLoading || !widgetInstance) return;

    widgetInstance.unload();
    widgetInstance = undefined;
  };

  return (
    <MarkerContext.Provider
      value={{
        load,
        unload,
      }}
    >
      {children}
    </MarkerContext.Provider>
  );
};

const useMarker = (): MarkerContextType => {
  const ctx = useContext(MarkerContext);

  if (!ctx) {
    throw new Error("You don't have access to Marker context");
  }

  return ctx;
};

export { MarkerProvider, useMarker };
