import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@kaizen/draft-tooltip';
import { useEditDealContext } from 'context/editDealContext';
import { CATEGORY_NAMES } from 'deal-form/constants';
import { FORM_NAMES, OVERVIEW_ORGANISM_SECTIONS } from 'deal-form/data/constants';
import React, { ReactElement, cloneElement, useEffect, useState, useContext } from 'react';
import { OverviewContext } from '../../../context/overviewContext';
import { iOverviewContextValues } from 'types/deal';

import { ORGANISM_STATUS } from '../../constants';
import { getOrganismStatus } from '../../helpers/formHelpers';
import { Dot } from '../../ui/Dot';

const CATEGORY_COLOR_MAP = {
  [CATEGORY_NAMES.contract]: 'bg-goldDrop',
  [CATEGORY_NAMES.deal]: 'bg-candlelight',
  [CATEGORY_NAMES.show]: 'bg-sushi',
};

interface IOrganismProps {
  label: FORM_NAMES;
  categories?: string[];
  children: ReactElement;
  tooltipInfo?: string;
  displaySaveStatus?: boolean;
  dataCy?: string;
}

const Organism: React.FC<IOrganismProps> = ({
  label,
  children,
  categories,
  tooltipInfo,
  displaySaveStatus,
  dataCy,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shouldRender, setShouldRender] = useState<boolean>(true);
  const { overviewSelectedOrganism, setOverviewSelectedOrganism } = useContext<iOverviewContextValues>(OverviewContext);

  const { registerOrganism, organisms, categoryView } = useEditDealContext();
  // const { registerOrganism, organisms } = useEditDealContext();

  async function handleOverviewOrganismOpenClose(setter: (setValue: any) => void) {
    // find the section that matches the overviewSelectedOrganism
    const matchingSection = OVERVIEW_ORGANISM_SECTIONS.find(
      (section) => section.href === `#${overviewSelectedOrganism}`
    );
    // if the section exists, check if the current organism is in the sections coveringOrganismIds
    if (matchingSection) {
      const matchingOrganism = matchingSection.coveringOrganismIds.includes(label.replace(/\s+/g, '-').toLowerCase());
      matchingOrganism && setter(true);
    }
  }

  useEffect(() => {
    handleOverviewOrganismOpenClose(() => setIsOpen(true));
  }, [overviewSelectedOrganism]);

  // TODO: refactor this code
  useEffect(() => {
    let newShouldRender = false;

    categories?.forEach((val) => {
      if (val === CATEGORY_NAMES.deal && categoryView.deal === true) newShouldRender = true;
      if (val === CATEGORY_NAMES.contract && categoryView.contract === true) newShouldRender = true;
      if (val === CATEGORY_NAMES.show && categoryView.show === true) newShouldRender = true;
    });

    setShouldRender(newShouldRender);
  }, [categoryView]);

  const toggleOrganismOpen = () => {
    handleOverviewOrganismOpenClose(() => setOverviewSelectedOrganism && setOverviewSelectedOrganism('-'));
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    label && registerOrganism(label);
  }, [label]);

  useEffect(() => {
    setOverviewSelectedOrganism && setOverviewSelectedOrganism(label.replace(/\s+/g, '-').toLowerCase());
  }, []);

  const organism = organisms[label];
  const organismState = getOrganismStatus(organism?.isDirty, organism?.isValid);

  if (!shouldRender) return <></>; // why wee need this line

  return (
    <div
      id={label.replace(/\s+/g, '-').toLowerCase()}
      className={`w-full my-4 pb-10 mx-auto bg-white shadow-organism ${isOpen ? 'h-auto' : 'h-20 overflow-hidden'}`}
      data-testid="container-organism-testid"
    >
      <div
        className="h-20 flex flex-row items-center px-8 border-b border-solid border-greyMasala cursor-pointer justify-between  bg-black text-white"
        onClick={toggleOrganismOpen}
        data-testid="toggle-organism-testid"
        data-cy={dataCy}
      >
        <div className="flex flex-row">
          {categories && categories?.length > 0 && (
            <div className="flex flex-row items-center">
              {categories?.map((cat) => <Dot key={`category-cat-${cat}`} color={CATEGORY_COLOR_MAP[cat]} />)}
            </div>
          )}
          <h3 className="text-organismHead font-extended" data-testid="label-organism-testid">
            {label}
          </h3>

          {tooltipInfo && (
            <div className="ml-2 mr-4" data-testid="tooltip-info-testid">
              <Tooltip position="below" text="Some text to show on hover">
                <InformationCircleIcon className="w-5 h-5 text-greyCloudy" />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="inline-flex justify-self-end">
          {organism && displaySaveStatus && (
            <div
              data-testid="save-status-testid"
              className={`px-2 py-1 mx-2 rounded font-medium font-sans text-greyCod text-xs 
              ${organismState === ORGANISM_STATUS.UNSAVED_CHANGES && 'bg-candlelight'}
              ${organismState === ORGANISM_STATUS.UNABLE_TO_SAVE && 'bg-merlot text-greyQuill'}
              ${organismState === ORGANISM_STATUS.SUCCESSFULLY_SAVED && 'bg-sushi'}`}
            >
              {organismState}
            </div>
          )}
          <ChevronDownIcon width={24} className={`${isOpen ? 'rotate-180' : ''}`} />
        </div>
      </div>

      <div className={`form mx-8 py-6 ${isOpen ? '' : 'pt-10 collapsed'}`}>
        {cloneElement(children, { isOpen: isOpen })}
      </div>
    </div>
  );
};

Organism.defaultProps = {
  categories: [],
  displaySaveStatus: false,
};

export default Organism;
