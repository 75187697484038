import { OrganismWarning } from '../OrganismWarning';

interface iProps {
  crossed: boolean;
  fields: string[];
  text?: string;
}

export const MasterDealWarning = ({ crossed, fields, text }: iProps) => (
  <OrganismWarning>
    {crossed ? (
      <p className="my-2">
        This event is part of a <b>crossed offer</b>.{' '}
        {text ? text : 'Updating the following fields will update the contract instead of the individual deal:'}
      </p>
    ) : (
      <p className="my-2">
        This event is part of a <b>non-crossed offer</b>.{' '}
        {text ? text : 'Updating the following fields will update the contract instead of the individual deal:'}
      </p>
    )}
    <ul>
      {fields.map((field, index) => (
        <li key={field.toLowerCase() + index}>• {field}</li>
      ))}
    </ul>
  </OrganismWarning>
);

MasterDealWarning.defaultProps = {
  crossed: false,
  fields: [],
};
