import { ColDef, ColGroupDef, IDateFilterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { getDealGrids } from 'api/grid-pages';
import { formatPercent } from 'deal-form/helpers/dealHelpers';
import { CurrencyRenderer, DateRenderer, NameRenderer, NumberRenderer } from 'grid-pages/components';
import { staticCellStyle } from 'grid-pages/grid-styles';
import { iCurrencyNumber, iPerson } from 'grid-pages/interfaces';
import GridTable from 'grid-pages/organisms/GridTable';
import { dateFilterComparator, sortKeyComparator } from 'grid-pages/utils/sorting-helpers';
import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * Default props of a column.
 */
const defaultProps = {
  resizable: true,
  lockPinned: true,
  cellStyle: staticCellStyle,
  minWidth: 75,
  sortable: false,
};

/**
 * Column definitions.
 */
const ColumnDefinitions: Array<ColDef | ColGroupDef> = [
  {
    field: 'check',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    sortable: false,
    maxWidth: 44,
    headerClass: 'select-all',
    cellClass: 'select-check-box',
    cellStyle: {
      padding: '8px 10px',
    },
  },
  {
    ...defaultProps,
    field: 'date',
    headerName: 'Date',
    type: ['dateColumn'],
  },
  {
    ...defaultProps,
    field: 'client',
    headerName: 'Client',
    type: ['nameColumn'],
    sortable: true,
  },
  {
    ...defaultProps,
    field: 'city',
    headerName: 'City',
    filter: 'agTextColumnFilter',
    sortable: true,
  },
  {
    ...defaultProps,
    field: 'state',
    headerName: 'State',
    filter: 'agTextColumnFilter',
    width: 160,
    sortable: true,
  },
  {
    ...defaultProps,
    field: 'country',
    headerName: 'Country',
    filter: 'agTextColumnFilter',
    sortable: true,
  },
  {
    ...defaultProps,
    field: 'venue',
    headerName: 'Venue',
    filter: 'agTextColumnFilter',
    sortable: true,
  },
  {
    ...defaultProps,
    field: 'dealStatus',
    headerName: 'Deal Status',
    width: 180,
  },
  {
    ...defaultProps,
    field: 'contractStatus',
    headerName: 'Contract Status',
    width: 180,
    sortable: true,
  },
  {
    ...defaultProps,
    field: 'guarantee',
    headerName: 'Guarantee',
    type: ['currencyColumn'],
    sortable: true,
  },
  {
    ...defaultProps,
    field: 'overage',
    headerName: 'Overage',
    type: ['currencyColumn'],
  },
  {
    ...defaultProps,
    field: 'totalPayout',
    headerName: 'Total Payout',
    type: ['currencyColumn'],
  },
  {
    ...defaultProps,
    field: 'agencyReduction',
    headerName: 'Agency Reduction',
    type: ['currencyColumn'],
  },
  {
    ...defaultProps,
    field: 'ticketsSold',
    headerName: 'Total Tickets Sold',
    filter: 'agNumberColumnFilter',
    type: ['numberColumn'],
  },
  {
    ...defaultProps,
    field: 'sellable',
    headerName: 'Total Sellable Capacity',
    filter: 'agNumberColumnFilter',
  },
  {
    ...defaultProps,
    field: 'percentSold',
    headerName: 'Total Percent Sold',
    filter: 'agNumberColumnFilter',
  },
  {
    ...defaultProps,
    field: 'gbor',
    headerName: 'GBOR',
    type: ['currencyColumn'],
  },
  {
    ...defaultProps,
    field: 'nbor',
    headerName: 'NBOR',
    type: ['currencyColumn'],
  },
  {
    ...defaultProps,
    field: 'dealId',
    headerName: 'Deal ID',
    filter: 'agTextColumnFilter',
    // sortable: true,
  },
  {
    ...defaultProps,
    field: 'agent',
    headerName: 'Agent',
    type: ['nameColumn'],
    sortable: true,
  },
  // {
  //   ...defaultProps,
  //   field: 'agencyCommission',
  //   headerName: 'Agency Commission Total',
  //   type: ['currencyColumn'],
  // },
  {
    ...defaultProps,
    field: 'paymentScheduleDateDue',
    headerName: 'Payment Schedule Date Due',
    width: 250,
    type: ['dateColumn'],
    // sortable: true,
  },
  {
    ...defaultProps,
    field: 'paymentScheduleAmount',
    headerName: 'Payment Schedule Amount',
    type: ['currencyColumn'],
  },
  {
    ...defaultProps,
    field: 'paymentScheduleReceivedDate',
    headerName: 'Payment Schedule Received Date',
    width: 250,
    type: ['dateColumn'],
    // sortable: true,
  },
  {
    ...defaultProps,
    field: 'finalTotals',
    headerName: 'Final Totals',
    type: ['currencyColumn'],
  },
  {
    ...defaultProps,
    field: 'confirmed',
    headerName: 'Deal Date Confirmed',
    type: ['dateColumn'],
    // sortable: true,
  },
];

interface iTableRow {
  id: string;
  date: Date;
  client: iPerson;
  city: string;
  state: string;
  country: string;
  venue: string;
  dealStatus: string;
  contractStatus: string;
  guarantee: iCurrencyNumber;
  overage: iCurrencyNumber;
  totalPayout: iCurrencyNumber;
  agencyReduction: iCurrencyNumber;
  ticketsSold: number;
  sellable: number;
  percentSold: string;
  gbor: iCurrencyNumber;
  nbor: iCurrencyNumber;
  dealId: string;
  agent: iPerson;
  // agencyCommission: iCurrencyNumber;
  paymentScheduleDateDue: Date | string;
  paymentScheduleAmount: iCurrencyNumber;
  paymentScheduleReceivedDate: Date | string;
  finalTotals: iCurrencyNumber;
  confirmed: Date;
}

const MongoClientSide = () => {
  // Refs
  const gridApiRef = useRef<AgGridReact>(null);

  // States
  const [tableData, setTableData] = useState<iTableRow[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      unSortIcon: true,
      floatingFilter: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      dateColumn: {
        cellRenderer: DateRenderer,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        } as IDateFilterParams,
      },
      currencyColumn: {
        cellRenderer: CurrencyRenderer,
        comparator: (valueA: any, valueB: any) => sortKeyComparator(valueA, valueB, 'amount'),
      },
      numberColumn: {
        cellRenderer: NumberRenderer,
      },
      nameColumn: {
        cellRenderer: NameRenderer,
        filter: 'agTextColumnFilter',
        filterValueGetter: (params: any) => {
          const colId = params.column.colId;
          return params.data[colId]?.name;
        },
        comparator: (valueA: any, valueB: any) => sortKeyComparator(valueA, valueB || '', 'name'),
      },
    };
  }, []);

  const getParsedRows = (data: any) => {
    const parsedData: iTableRow[] = data.map((item: any) => {
      // const agents = item.agents && item.agents.length && item.agents.find((agent: any) => agent.type === 'Papering');
      const { agents, client, venue } = item;
      const dealCurrency = item.currency || 'USD';

      const itemData: iTableRow = {
        id: item._id,
        date: item.startDate,
        client: client || { name: '' },
        city: venue?.city || '',
        state: venue?.state || '',
        country: venue?.country || '',
        venue: venue?.name || '',
        dealStatus: item.dealStatus,
        contractStatus: item.contractStatus,
        guarantee: {
          amount: item.guarantee,
          currency: dealCurrency,
        },
        overage: {
          amount: item.overage,
          currency: dealCurrency,
        },
        totalPayout: item.totalPayout,
        agencyReduction: item.totalAgencyReductions,
        ticketsSold: item.totalTicketsSold || '--',
        sellable: item.totalTickets || '--',
        percentSold: item.totalPercentSold ? formatPercent(item.totalPercentSold) + '%' : '--',
        gbor: item.estimatedGrossPotential,
        nbor: item.estimatedNetPotential,
        dealId: item.id || '',
        agent: agents[0] || { name: '' },
        // agencyCommission: {
        //   amount: calculations?.agencyCommission?.amount,
        //   currency: calculations?.agencyCommission?.currency || dealCurrency,
        // },
        paymentScheduleDateDue: item.paymentScheduleDueDate,
        paymentScheduleAmount: item.expectedDepositsTotal,
        paymentScheduleReceivedDate: item.paymentScheduleReceivedDate,
        finalTotals: item.finalTotals,
        confirmed: item.dealDateConfirmed,
      };
      return itemData;
    });
    return parsedData;
  };

  const fetchGridData = async () => {
    const results = await getDealGrids('?limit=100000');
    const { rows } = results;

    if (rows) {
      const parsedHits = getParsedRows(rows);
      setTableData(parsedHits);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      fetchGridData();
    }
  }, []);

  const getRowId = useMemo(() => {
    //Used for identifying selected rows
    return (params: any) => {
      return params.data.id;
    };
  }, []);

  return (
    <div className="ag-theme-alpine h-full">
      <GridTable
        pageId="mongo-client-side"
        ref={gridApiRef}
        getRowId={getRowId}
        rowData={tableData}
        rowSelection="multiple"
        defaultColDef={defaultColDef}
        gridOptions={{
          suppressClickEdit: true,
          colResizeDefault: 'shift',
          suppressScrollOnNewData: true,
          suppressRowTransform: true,
          pagination: true,
          // paginationPageSize: 100, //how many rows to display per page, default 100
        }}
        animateRows
        columnDefs={ColumnDefinitions}
        columnTypes={columnTypes}
      />
    </div>
  );
};

export default MongoClientSide;
