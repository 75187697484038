import { getPresetReports } from 'api/grid-pages';
import GridTable, { checkboxColumn } from 'grid-pages/organisms/GridTable';
import { getConfigFromData } from 'grid-pages/utils/configs';
import { RANGES, getInitialDateRange } from 'grid-pages/utils/date-utils';
import { FC, useEffect, useState } from 'react';
import utc from 'dayjs/plugin/utc';

import { DatePicker } from 'antd';
import Button from 'components/Button';
import dayjs from 'dayjs';
import { getDateFormatByBrowser } from 'utils/helpers';
import moment from 'moment';
const { RangePicker } = DatePicker;

dayjs.extend(utc);

export enum PRESETS {
  ACCOUNTING = 'accounting',
  CONTRACTING = 'contracting',
  DEALS = 'deals',
  TRACKING = 'tracking',
}

interface iPresetReport {
  preset: PRESETS;
  datePreset?: RANGES;
  isExporting?: boolean;
  setIsExporting?: (val: boolean) => void;
}

export const PresetReport: FC<iPresetReport> = ({
  preset,
  datePreset = RANGES.THREE_MONTHS,
  isExporting,
  setIsExporting,
}) => {
  // States
  const [isReady, setIsReady] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [columnDefs, setColumnDefs] = useState<any[] | null>(null);
  const [dealGridsParams, setDealGridsParams] = useState<string>('');

  const fetchReportTemplates = async () => {
    const reportToLoad = await getPresetReports(preset);
    const parsedCols = getConfigFromData(reportToLoad.columns);

    setColumnDefs(parsedCols);
    setDealGridsParams(`&preset=${reportToLoad.preset}`);

    if (reportToLoad.dateRange) {
      setStartDate(moment(reportToLoad.dateRange.start).utc().toDate());
      setEndDate(moment(reportToLoad.dateRange.end).utc().toDate());
    } else {
      const dateRange = getInitialDateRange(datePreset);

      setStartDate(moment(dateRange.startDate).utc().toDate());
      setEndDate(moment(dateRange.endDate).utc().toDate());
    }
  };

  useEffect(() => {
    setIsReady(false);
    if (startDate !== null && endDate !== null) {
      setIsReady(true);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchReportTemplates();
  }, []);

  const handleFilterToggle = () => {
    setShowFilters(!showFilters);
  };

  const FunnelIcon = () => (
    <svg width="50" height="40" viewBox="0 0 50 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.8751 15L26.875 20V23.125L23.125 26.875V20L18.125 15V13.75H31.8751V15ZM20 15L24.375 19.375V23.75L25.625 22.5V19.375L30 15H20Z" />
    </svg>
  );

  return (
    <div className="flex flex-col flex-1 h-full w-full">
      <div className="py-4 flex justify-between">
        <div className="flex items-center pl-4 md:pl-0">
          {isReady && (
            <RangePicker
              id="startDate"
              format={getDateFormatByBrowser()}
              className="peer h-10 w-full appearance-none border border-solid border-black px-2 py-1 text-sm"
              defaultValue={
                startDate !== null && endDate !== null ? [dayjs(startDate).utc(), dayjs(endDate).utc()] : null
              }
              value={[dayjs(startDate).utc(), dayjs(endDate).utc()]}
              onChange={(value: any) => {
                const newStartDate = moment(value?.[0]?.$d)
                  .utc()
                  .toDate();
                const newEndDate = moment(value?.[1]?.$d)
                  .utc()
                  .toDate();

                setStartDate(newStartDate || null);
                setEndDate(newEndDate || null);
              }}
            />
          )}
        </div>
        <Button rightIcon={<FunnelIcon />} onClick={handleFilterToggle}>
          <span className="hidden md:block">Filters</span>
        </Button>
      </div>

      {columnDefs ? (
        <GridTable
          showSideBar
          hideSidebarColumns
          pageId={preset}
          isExporting={isExporting}
          endDate={endDate}
          startDate={startDate}
          showFilters={showFilters}
          columnDefs={[checkboxColumn, ...columnDefs]}
          dealGridsParams={dealGridsParams}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setIsExporting={setIsExporting}
        />
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};
