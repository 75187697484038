import React, { createContext, PropsWithChildren, useContext } from 'react';

import useFiltersState, { IUseFiltersState } from './hook';

const AvailsFiltersContext = createContext<ReturnType<typeof useFiltersState> | undefined>(undefined);

export const useFilters = () => {
  const contextValue = useContext(AvailsFiltersContext as React.Context<IUseFiltersState>);

  if (!contextValue) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return contextValue;
};

const AvailsFiltersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const state = useFiltersState();

  return <AvailsFiltersContext.Provider value={state}>{children}</AvailsFiltersContext.Provider>;
};

export default AvailsFiltersProvider;
