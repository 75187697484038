// TODO - [ONX-1015] - add testing coverage for logic - this is passed to get lifecycle skeleton into dev asap
//   to facilitate parallel work
import { Icons, colors } from '@united-talent-agency/components';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { ignoreRuleViolation } from 'api/RoutingApiCalls';
import { findVenues } from 'api/venue';
import { FC, Fragment, HTMLAttributes, MouseEventHandler, PropsWithChildren, useContext } from 'react';
import styled from 'styled-components/macro';
import { RuleViolation } from 'types';

import CypressTags from '../../../../support/cypressTags';
import { ViolationWithDate } from '../../../../types/ViolationsMap';
import { RoutingContext } from '../../index';
import { generateAsyncDropdown } from '../dropdown/Dropdown';

const VIOLATES_TEXT = 'Violates radius clause';

/**
 * Venue renderer.
 */
export const VenueRenderer: FC<ICellRendererParams> = (props) => {
  const { violations, setViolations } = useContext(RoutingContext);
  const ownsViolation = violations[props.data._id]?.owned?.some((violation: RuleViolation) => !violation.ignored);
  const color = ownsViolation ? colors.red : 'black';

  const violatingUp = () => {
    return violations[props.data._id]?.inherited?.some(
      (violation: ViolationWithDate) => violation.date <= props.data.date && !violation.ignored
    );
  };

  const violatingDown = () => {
    return violations[props.data._id]?.inherited?.some(
      (violation: ViolationWithDate) => violation.date >= props.data.date && !violation.ignored
    );
  };

  const clearViolation: MouseEventHandler<HTMLDivElement> = async () => {
    const row: RowNode = props.node as RowNode;
    await ignoreRuleViolation(row, violations, setViolations);
  };

  return props.data?.monthBreak ? (
    <div data-row-id={props.data?.id}>
      <div
        data-cy={CypressTags.ROUTING.MONTH_BREAK}
        style={{ textAlign: 'left', width: '100%', fontWeight: 800, fontSize: '20px' }}
      >
        {new Date(props.data.date).toLocaleString('en-US', { month: 'long', year: 'numeric' })}
      </div>
    </div>
  ) : (
    <div className="center-content" data-row-id={props.data?.id}>
      <Fragment>
        {(violatingUp() || violatingDown()) && (
          <RadiusViolationContainer>
            <RadiusViolationButton onClick={clearViolation}>
              <Icons.XCloseIcon color="white" width={13} />
            </RadiusViolationButton>
          </RadiusViolationContainer>
        )}
        <VenueRendererContainer>
          <ViolationTextContainer>
            {violatingUp() && <div data-cy={CypressTags.ROUTING.VIOLATES_UP}>{VIOLATES_TEXT}</div>}
          </ViolationTextContainer>
          <span data-cy={ownsViolation ? CypressTags.ROUTING.VIOLATION : ''} style={{ color: color }}>
            {props.data?.venue?.name}{' '}
          </span>
          <ViolationTextContainer>
            {violatingDown() && <div data-cy={CypressTags.ROUTING.VIOLATES_DOWN}>{VIOLATES_TEXT}</div>}
          </ViolationTextContainer>
        </VenueRendererContainer>
      </Fragment>
    </div>
  );
};

/**
 * Venue editor.
 */
export const VenueEditor = generateAsyncDropdown('VenueEditor', findVenues);

/*
|--------------------------------------------------------------------------
| Styled components
|--------------------------------------------------------------------------
|
| Styled components used in this source file's scope.
|
*/

const VenueRendererContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ViolationTextContainer = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  color: red;
`;

const RadiusViolationContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 150px;
  height: 32px;
  position: absolute;
  align-items: center;
`;

const RadiusViolationButton = styled.div<PropsWithChildren<HTMLAttributes<HTMLDivElement>>>`
  margin-right: -5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${colors.red};
  cursor: pointer;
`;
