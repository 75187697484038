import { ReactElement } from 'react';

interface PillProps {
  width?: number;
  pillText?: string;
  type?: string;
  onClear?: () => void;
  style?: Record<string, unknown>;
}

export const Pill = ({ width, pillText, type, style }: PillProps): ReactElement => {
  return (
    <div style={style}>
      <div
        className={`min-w-[${width}px] ${
          type === 'warning' ? 'bg-merlot' : 'bg-black'
        } text-white text-sm rounded-full px-3 py-2`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center mt-[2px] mr-[10px]">{pillText}</div>
        </div>
      </div>
    </div>
  );
};
