import { iOptions } from 'deal-form/interfaces/general';
import { FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import { isObjectEmpty } from 'utils/helpers';

import { ORGANISM_STATUS } from '../../constants';

type errorObj = FieldError | FieldErrors;
/**
 * Recursively parses error messages generated by react-hook-form
 * The input object can contain:
 * - one or multiple error objects
 * - an object of multiple error objects
 * - an array of objects that contain multiple error objects
 *
 * @param errorMessageObj - FieldErrors object
 * @returns - list of error message strings
 */
const flattenErrorMessageObj = (errorMessageObj: errorObj): string[] => {
  if (!errorMessageObj) return [];

  const keys = Object.keys(errorMessageObj);

  const newObject = keys.map((key) => {
    const objectProperty = errorMessageObj[key as keyof errorObj];

    // TODO: fix ignore
    // @ts-ignore
    if (objectProperty?.message) return objectProperty?.message;

    if (typeof objectProperty === 'object') {
      // TODO: fix ignore
      // @ts-ignore
      return flattenErrorMessageObj(objectProperty);
    } else if (Array.isArray(objectProperty)) {
      const newMessages = objectProperty.map((element) => {
        return [...flattenErrorMessageObj(element)];
      });
      return newMessages.flat();
    }
  });

  const uniqueResults = Array.from(new Set(newObject.flat()));

  return uniqueResults;
};

/**
 * Returns a list of error messages from an FieldErrors object
 *
 * @param {object} errorsObj - FieldErrors object
 * @returns {array} - list of error message strings
 */
export const getMessagesFromErrorObj = (errorsObj: FieldErrors) => {
  if (errorsObj && isObjectEmpty(errorsObj)) return [];

  const errorsList = flattenErrorMessageObj(errorsObj);

  return errorsList;
};

/**
 * Returns an error from a list of react-hook-form errors
 * that may or may not be nested from a matching the id
 * For example: From id `buyers.0.buyerCompany.name`, it will
 * return the contents of the errors.buyers[0][buyerCompany][name] if found.
 *
 * @param {string} id - field id
 * @param {object} errors - react-hook-form errors object
 * @returns {object} error - single error matching id
 */
export const findNestedErrorById = (id: string, errors: FieldErrors<FieldValues>) =>
  id
    .split('.')
    .reduce(
      (prev, curr) => (prev && (prev[curr as keyof FieldErrors<FieldValues>] as FieldErrors<FieldValues>)) || null,
      errors
    );

export const getMaxCharMessage = (fieldName: string, max: number) =>
  `Please reduce ${fieldName} to maximum ${max} characters`;

export const getOrganismStatus = (isDirty: boolean, isValid: boolean) => {
  if (isDirty && isValid) {
    return ORGANISM_STATUS.UNSAVED_CHANGES;
  }

  if (!isValid) {
    return ORGANISM_STATUS.UNABLE_TO_SAVE;
  }

  return ORGANISM_STATUS.SUCCESSFULLY_SAVED;
};

/**
 * Sorts and options array in ascending order
 *
 * @param {array} options - list of options
 */
export const sortOptionsAsc = (options: iOptions[]) => {
  return options.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
};
