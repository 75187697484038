import { convertCurrencyAndUpdateContractById, formatDate } from 'deal-form/helpers/dealHelpers';
import { iFormProps } from 'deal-form/interfaces/general';
import React, { Fragment, useEffect, useState } from 'react';
import { useArtistCurrency } from '../../helpers/useArtistCurrency';

import { formatCurrencyWithSymbol, getTicketCurrency } from '../../helpers/dealHelpers';
import { ColumnLabels } from '../../ui/ColumnLabels';

import cypressTags from 'support/cypressTags';
const { TICKET_CALCULATIONS } = cypressTags.DEAL_FORM;
export const COLUMN_LABELS = [
  { value: 'Show' },
  { value: 'Gross Potential' },
  { value: 'Total Fees' },
  { value: 'Total Taxes' },
  { value: 'Net Potential' },
];
export const GRID_LAYOUT = 'grid grid-cols-5 gap-x-4 items-center';

// Define a function to convert and update the state variable
async function convertAndUpdate(amount: number, from: string, to: string, exchangeRates?: any, contractId?: string) {
  try {
    const value = await convertCurrencyAndUpdateContractById(amount, from, to, exchangeRates, contractId);
    return value;
  } catch (error) {
    // Handle errors here if necessary
    console.error('Error:', error);
    return null; // or some default value
  }
}

// Technically the only organism that isn't a form but keeping the same structure in case that changes
const TicketCalculationsForm: React.FC<iFormProps> = ({ deal, contract }) => {
  const [currency, setCurrency] = useState<string>(getTicketCurrency(deal));
  const dealCurrency = useArtistCurrency(deal);
  const [grossTotal, setGrossTotal] = useState<number>(0);
  const [feesTotal, setFeesTotal] = useState<number>(0);
  const [taxesTotal, setTaxesTotal] = useState<number>(0);
  const [netTotal, setNetTotal] = useState<number>(0);
  const [convertedGrossTotal, setConvertedGrossTotal] = useState<number>(0);
  const [convertedFeesTotal, setConvertedFeesTotal] = useState<number>(0);
  const [convertedTaxesTotal, setConvertedTaxesTotal] = useState<number>(0);
  const [convertedNetTotal, setConvertedNetTotal] = useState<number>(0);

  useEffect(() => {
    let ticketCurrency = getTicketCurrency(deal);
    deal.shows.forEach((show) => {
      if (show.ticketTiers.length > 0) {
        ticketCurrency = show.ticketTiers[0].currency;
      }
    });

    setCurrency(ticketCurrency);
  }, [deal]);

  useEffect(() => {
    let grossTotalHolder = 0;
    let feesTotalHolder = 0;
    let taxesTotalHolder = 0;
    let netTotalHolder = 0;
    deal.shows.map((show) => {
      if (show.ticketTiers.length > 0) {
        const grossPotential = show.ticketTiers.reduce((acc, obj) => {
          return acc + (obj.available - obj.comp) * obj.advance;
        }, 0);

        let showFees = 0;
        let showTaxes = 0;

        show.ticketTiers.forEach(({ fees, taxes, available, comp, advance }) => {
          const sellableTickets = available - +comp;
          const tierGross = (available - comp) * advance;

          let tierFees = 0;
          if (fees.length > 0) {
            tierFees += fees.reduce((feeAcc, obj) => {
              if (obj.percentFlag === true && obj.percent !== undefined)
                return obj.type === 'Built-In' ? feeAcc + (+obj.percent / 100) * advance : feeAcc;
              return obj.type === 'Built-In' ? feeAcc + obj.amount : feeAcc;
            }, 0);

            tierFees *= sellableTickets;
          }
          showFees += tierFees;

          let tierTaxes = 0;
          if (taxes.length > 0) {
            tierTaxes += taxes.reduce((taxAcc, taxObj) => {
              let calculatedGross = 0;
              if (taxObj.beforeFee) {
                if (taxObj.type === 'Multiplier') {
                  calculatedGross = tierGross * (taxObj.percent / 100);
                } else {
                  calculatedGross = tierGross - tierGross / (1 + taxObj.percent / 100);
                }
              } else {
                if (taxObj.type === 'Multiplier') {
                  calculatedGross = (tierGross - tierFees) * (taxObj.percent / 100);
                } else {
                  calculatedGross = tierGross - tierFees - (tierGross - tierFees) / (1 + taxObj.percent / 100);
                }
              }
              return taxAcc + calculatedGross;
            }, 0);
            showTaxes += tierTaxes;
          }
        });
        grossTotalHolder += grossPotential;
        feesTotalHolder += +showFees;
        taxesTotalHolder += showTaxes;
        netTotalHolder += grossPotential - showFees - showTaxes;
        // set grossTotal, feesTotal, taxesTotal, netTotal
      }
    });
    setGrossTotal(grossTotalHolder);
    setFeesTotal(feesTotalHolder);
    setTaxesTotal(taxesTotalHolder);
    setNetTotal(netTotalHolder);

    Promise.all([
      convertAndUpdate(
        grossTotalHolder,
        currency,
        dealCurrency?.id || currency,
        contract?.exchangeRates,
        contract?._id
      ),
      convertAndUpdate(feesTotalHolder, currency, dealCurrency?.id || currency, contract?.exchangeRates, contract?._id),
      convertAndUpdate(
        taxesTotalHolder,
        currency,
        dealCurrency?.id || currency,
        contract?.exchangeRates,
        contract?._id
      ),
      convertAndUpdate(netTotalHolder, currency, dealCurrency?.id || currency, contract?.exchangeRates, contract?._id),
    ]).then(([conGrossTotal, conFeesTotal, conTaxesTotal, conNetTotal]) => {
      setConvertedGrossTotal(conGrossTotal || 0);
      setConvertedFeesTotal(conFeesTotal || 0);
      setConvertedTaxesTotal(conTaxesTotal || 0);
      setConvertedNetTotal(conNetTotal || 0);
    });
  }, [deal, dealCurrency]);

  return (
    <div>
      <ColumnLabels labels={COLUMN_LABELS} containerClassName={GRID_LAYOUT} />
      <div className={`${GRID_LAYOUT} text-sm leading-10`}>
        {deal.shows.map((show) => {
          if (show.ticketTiers.length > 0) {
            const grossPotential = show.ticketTiers.reduce((acc, obj) => {
              return acc + (obj.available - obj.comp) * obj.advance;
            }, 0);

            let showFees = 0;
            let showTaxes = 0;

            show.ticketTiers.forEach(({ fees, taxes, available, comp, advance }) => {
              const sellableTickets = available - +comp;
              const tierGross = (available - comp) * advance;

              let tierFees = 0;
              if (fees.length > 0) {
                tierFees += fees.reduce((feeAcc, obj) => {
                  if (obj.percentFlag === true && obj.percent !== undefined)
                    return obj.type === 'Built-In' ? feeAcc + (+obj.percent / 100) * advance : feeAcc;
                  return obj.type === 'Built-In' ? feeAcc + obj.amount : feeAcc;
                }, 0);

                tierFees *= sellableTickets;
              }
              showFees += tierFees;

              let tierTaxes = 0;
              if (taxes.length > 0) {
                tierTaxes += taxes.reduce((taxAcc, taxObj) => {
                  let calculatedGross = 0;
                  if (taxObj.beforeFee) {
                    if (taxObj.type === 'Multiplier') {
                      calculatedGross = tierGross * (taxObj.percent / 100);
                    } else {
                      calculatedGross = tierGross - tierGross / (1 + taxObj.percent / 100);
                    }
                  } else {
                    if (taxObj.type === 'Multiplier') {
                      calculatedGross = (tierGross - tierFees) * (taxObj.percent / 100);
                    } else {
                      calculatedGross = tierGross - tierFees - (tierGross - tierFees) / (1 + taxObj.percent / 100);
                    }
                  }
                  return taxAcc + calculatedGross;
                }, 0);
                showTaxes += tierTaxes;
              }
            });

            return (
              <Fragment key={`ticket-calculation-${show._id}`}>
                <div>
                  {formatDate(show.date)} - {show.earlyLate === 'Neither' ? 'Only Show' : show.earlyLate}
                </div>
                <div>{formatCurrencyWithSymbol(grossPotential, currency)}</div>
                <div>{formatCurrencyWithSymbol(showFees, currency)}</div>
                <div>{formatCurrencyWithSymbol(showTaxes, currency)}</div>
                <div>{formatCurrencyWithSymbol(grossPotential - showFees - showTaxes, currency)}</div>
              </Fragment>
            );
          } else {
            // // Update currency when the show has no tiers
            return null;
          }
        })}

        <div className="form-label">Total</div>
        <div data-cy={TICKET_CALCULATIONS.GROSS_POTENTIAL}>{formatCurrencyWithSymbol(grossTotal, currency)}</div>
        <div data-cy={TICKET_CALCULATIONS.TOTAL_FEES}>{formatCurrencyWithSymbol(feesTotal, currency)}</div>
        <div data-cy={TICKET_CALCULATIONS.TOTAL_TAXES}>{formatCurrencyWithSymbol(taxesTotal, currency)}</div>
        <div data-cy={TICKET_CALCULATIONS.NET_POTENTIAL}>{formatCurrencyWithSymbol(netTotal, currency)}</div>
      </div>
      {dealCurrency?.id !== currency && (
        <div className={`${GRID_LAYOUT} text-sm leading-10`}>
          <div className="form-label">Total(Converted)</div>
          <div>
            {convertedGrossTotal ? formatCurrencyWithSymbol(convertedGrossTotal, dealCurrency?.id || 'USD') : '--'}
          </div>
          <div>
            {convertedFeesTotal ? formatCurrencyWithSymbol(convertedFeesTotal, dealCurrency?.id || 'USD') : '--'}
          </div>
          <div>
            {convertedTaxesTotal ? formatCurrencyWithSymbol(convertedTaxesTotal, dealCurrency?.id || 'USD') : '--'}
          </div>
          <div>{convertedNetTotal ? formatCurrencyWithSymbol(convertedNetTotal, dealCurrency?.id || 'USD') : '--'}</div>
        </div>
      )}
    </div>
  );
};

export default TicketCalculationsForm;
