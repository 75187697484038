import { Divider, DualRangeSlider, FormSelect } from '@united-talent-agency/onyx-components';

import { createRangeValues } from '../helpers';
import { useFilters } from 'context/avails-filters/context';
import { MAX_GUARANTEE, MIN_GUARANTEE } from '../constants';
import { useMemo } from 'react';

const getValueWithUnit = (value: number) => {
  const THOUSAND = 1000;
  const MILLION = Math.pow(THOUSAND, 2);
  const BILLION = Math.pow(THOUSAND, 3);

  if (value >= BILLION) {
    return `${value / BILLION}B`;
  } else if (value >= MILLION) {
    return `${value / MILLION}M`;
  } else if (value >= THOUSAND) {
    return `${value / THOUSAND}K`;
  }

  return value;
};

export const formatValueToGuaranteeFilterValue = (value: number) => {
  const croppedValue = Math.max(Math.min(value, MAX_GUARANTEE), MIN_GUARANTEE);

  return `$${getValueWithUnit(croppedValue)}`;
};

interface DuaRangeSliderValue {
  from: string | number;
  to: string | number;
}

const GuaranteeFilter = () => {
  const { guaranteeRange, setGuaranteeRange } = useFilters();

  const rangeValues = createRangeValues(MAX_GUARANTEE);
  const rangeNumbers = rangeValues.map((value) => Number(value.value));

  const findClosest = (arr: number[], target: number): number => {
    let closest = arr[0];
    arr.forEach((val) => {
      if (Math.abs(val - target) < Math.abs(closest - target)) {
        closest = val;
      }
    });
    return closest;
  };

  const handleRangeChange = (value: DuaRangeSliderValue) => {
    const closestMin = findClosest(rangeNumbers, Number(value.from));
    const closestMax = findClosest(rangeNumbers, Number(value.to));

    setGuaranteeRange({ min: closestMin, max: closestMax });
  };

  const minLabel = useMemo(() => formatValueToGuaranteeFilterValue(guaranteeRange?.min), [guaranteeRange?.min]);
  const maxLabel = useMemo(() => formatValueToGuaranteeFilterValue(guaranteeRange?.max), [guaranteeRange?.max]);

  return (
    <div className="mt-4">
      <div className="pb-4">
        <DualRangeSlider
          min={MIN_GUARANTEE}
          max={MAX_GUARANTEE}
          from={guaranteeRange.min}
          to={guaranteeRange.max}
          onChange={(value) => handleRangeChange(value)}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <FormSelect
          label="Minimum"
          options={rangeValues}
          menuPosition="fixed"
          className="w-full"
          value={{
            value: guaranteeRange.min,
            label: minLabel,
          }}
          onChange={(value: any) => setGuaranteeRange({ ...guaranteeRange, min: Number(value.value) })}
        />
        <Divider className="w-5" />
        <FormSelect
          label="Maximum"
          options={rangeValues}
          menuPosition="fixed"
          value={{
            value: guaranteeRange.max,
            label: maxLabel,
          }}
          className="w-full"
          onChange={(value: any) => setGuaranteeRange({ ...guaranteeRange, max: Number(value.value) })}
        />
      </div>
    </div>
  );
};

export default GuaranteeFilter;
