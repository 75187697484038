import { formatCurrencyWithSymbol } from 'deal-form/helpers/dealHelpers';
import { ColDef } from 'ag-grid-community';
import BlankCell from '../BlankCell';
import { FC } from 'react';

export type CurrencyRendererValue = {
  amount: number;
  currency: string;
};

export type CurrencyRendererProps = {
  value?: CurrencyRendererValue;
  data?: any;
  colDef?: ColDef;
};

const CurrencyRenderer: FC<CurrencyRendererProps> = (props) => {
  const { value } = props;
  return value?.amount || value?.amount === 0 ? (
    <div data-cy={`${props?.data?._id}-${props?.colDef?.field}`} data-value={value}>
      {formatCurrencyWithSymbol(Number(value?.amount) || 0, value?.currency)}
    </div>
  ) : (
    <BlankCell />
  );
};

export default CurrencyRenderer;
