import clsx, { ClassValue } from 'clsx';
import { FC, PropsWithChildren, ThHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

export type TableHeaderCellProps = {
  className?: ClassValue;
  // eslint-disable-next-line no-undef
} & Omit<PropsWithChildren<ThHTMLAttributes<HTMLTableHeaderCellElement>>, 'className'>;

const Cell: FC<TableHeaderCellProps> = ({ children, className, ...props }) => (
  <th
    className={twMerge(
      clsx(
        'bg-secondary p-0 print:bg-transparent h-[56px] print:h-[32px] min-w-[150px] max-h-full max-w-full md:min-w-[218px] print:min-w-[180px] print:md:min-w-[180px] print:text-xs print:border-b print:border-[6B6A69]',
        className
      )
    )}
    {...props}
  >
    {children}
  </th>
);

export default Cell;
