import { OnyxThemeProvider } from '@united-talent-agency/components';
import Provider from '@united-talent-agency/onyx-components/Provider';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@united-talent-agency/onyx-components/styles/onyx.css';
import { initializeDataDog } from 'support/dataDog';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AvailsFiltersProvider from 'context/avails-filters/context';
import { MarkerProvider } from 'context/useMarker';

const LAUNCHDARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID || '';

/**
 * Data Dog RUM tracking initialization
 *
 * active host is interrogated against the allowable env flags using this map:
 *    "prod": phonesheet.unitedtalent.com
 *    "staging": phonesheet.staging.unitedtalent.com
 *    "dev": phonesheet.dev.unitedtalent.com
 *    "local": localhost
 */
initializeDataDog(window.location.hostname, ['staging', 'prod']);
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <LDProvider clientSideID={LAUNCHDARKLY_CLIENT_ID}>
      <MarkerProvider>
        <OnyxThemeProvider>
          <Provider>
            <AvailsFiltersProvider>
              <App />
              <ToastContainer hideProgressBar={true} />
            </AvailsFiltersProvider>
          </Provider>
        </OnyxThemeProvider>
      </MarkerProvider>
    </LDProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
