import { StickyHeaderPage } from 'features/layouts';
import { PageHeader } from 'grid-pages/components';
import { PRESETS, PresetReport } from 'grid-pages/pages/PresetReport';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import { ReactElement, useCallback, useState } from 'react';

/**
 * Tracking Grid
 */
export const Tracking = (): ReactElement => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();

  // States
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const handleOnExport = () => {
    setIsExporting(true);
  };

  const renderFixedContainer = useCallback(() => <PageHeader onExport={handleOnExport} title="Deposit Tracking" />, []);

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="main-wrapper">
        <div
          className="grid-height content-wrapper md:pt-10"
          onMouseOver={lockOverscrollNavigation}
          onMouseLeave={unlockOverscrollNavigation}
        >
          <PresetReport isExporting={isExporting} setIsExporting={setIsExporting} preset={PRESETS.TRACKING} />
        </div>
      </div>
    </StickyHeaderPage>
  );
};
