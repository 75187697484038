import { ParsedUrlQuery, parse } from 'querystring';

interface Props {
  setToken: (token: string) => void;
}

// TODO - [https://us02web.zoom.us/j/82204534501?pwd=c1dPd2FaemJCMEFVcXMyMFc2dWdEZz09]
//  - Need smoke-testing for the following:
//  - What happens when Azure fails Auth process ?
//  - What happens when Onyx fails Auth process ?
//  - What happens when an expired token is used ?
export const LoginCallback = (props: Props) => {
  const hash: ParsedUrlQuery = parse(window.location.hash);
  const token = hash['#jwt'] as string;
  props.setToken(token);
  localStorage.setItem('accessToken', token);
  window.location.href = '/';
  return <div />;
};
