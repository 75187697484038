import { VenueInfo } from '../api/venue';

export enum EntityType {
  'Industry Contact' = 'Industry Contact',
  Client = 'Client',
  Employee = 'Employee',
  'Shared Contact' = 'Shared Contact',
}

export enum EntityDesignateType {
  Company = 'Company',
  Person = 'Person',
  Venue = 'Venue',
}

export enum PhoneType {
  OfficePhone = 'Office Phone',
  AssistantPhone = 'Assistant Phone',
  MobilePhone = 'Mobile Phone',
  HomePhone = 'Home Phone',
}

export enum EmailType {
  Email = 'Email',
  AssistantEmail = 'Assistant Email',
}

export const ContactType = { ...PhoneType, ...EmailType };
export type tContactType = PhoneType | EmailType;

export enum AddressType {
  Home = 'Home',
  Work = 'Work',
}

export enum DealType {
  Flat = 'Flat',
  PlusBackend = 'Plus Backend',
  DoorDeal = 'Door Deal',
  FixedSplit = 'Fixed Split',
  VersusGross = 'Versus Gross',
  VersusNet = 'Versus Net',
  Capped = 'Capped',
}

export interface Address {
  _id: string;
  type: AddressType;
  address: string;
  suite?: string;
  state?: string;
  city?: string;
  zip?: string;
  country?: string;
  description?: string;
  directions?: string;
  private?: boolean;
  primary?: boolean;
}

export interface Contact {
  _id: string;
  contact: string;
  contactType: tContactType;
  description?: string;
  private?: boolean;
  primary?: boolean;
  source?: string;
}

export type ExternalRelationship = {
  _id: string;
  type: string;
  person?: { name: string };
  group?: { name: string };
};

// TerritoryType? to match the other enums here?
export enum Territory {
  AllTerritories = 'All Territories',
  RestOfWorld = 'Rest of World',
  Canada = 'Canada',
  LatinAmerica = 'Latin America',
  Scandinavia = 'Scandinavia',
  UnitedStates = 'United States',
}

export interface TerritoryData {
  territory: Territory;
  label: string;
  primary?: boolean;
  text?: string;
}

export interface Loanout {
  primary?: boolean;
  territory: Territory;
  groupId?: { _id: string; name: string };
  personId?: { _id: string; name: string };
  relationshipType: string;
}

export enum OfficeLocation {
  LosAngeles = 'Los Angeles Office',

  // Data is: New York 888 Office
  NewYork = 'New York Office',
  Nashville = 'Nashville Office',
  Florida = 'Florida Office',
  Scandinavia = 'Scandinavia Office',
  London = 'London Office',
}

export enum Department {
  Comedy = 'Comedy',
  Music = 'Music',
}

export enum Subsidiary {
  UnitedTalentAgency = 'United Talent Agency, LLC',
  TheAgencyGroup = 'The Agency Group Ltd. UK',
  TheAgencyGroupScandinavia = 'The Agency Group Scandinavia AB',
}

export type UTARelationship = {
  _id: string;
  type: string;
  utaEmployee: { name: string; _id: string; department?: string };
};

export type Venue = {
  _id: string;
  addresses?: Address[];
  name?: string;
  address?: Address;
  venueInfo?: VenueInfo;
};

export enum TicketFinals {
  Yes = 'Yes',
  Festival = 'Festival',
  Private = 'Private',
  NoPoll = 'No Poll',
}

export interface Provision {
  _id?: string;
  option: string;
  note: string;
}

export enum ReductionType {
  'Agent',
  'Client',
}
