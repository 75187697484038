import { useCallback, useEffect, useMemo } from 'react';

export enum OrientationEnum {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  BOTH = 'both',
}

/**
 * Lock and unlock the overscroll to avoid unwanted navigations.
 * @param {OrientationEnum} - orientation to lock/unlock. Default: 'both'
 * @returns an array with two functions: [lockOverscrollNavigation, unlockOverscrollNavigation].
 */
const useOverscrollNavigationLock = (orientation: OrientationEnum = OrientationEnum.BOTH) => {
  const overscrollClass = useMemo(() => {
    if (orientation === OrientationEnum.VERTICAL) {
      return 'overscroll-y-none';
    }

    if (orientation === OrientationEnum.HORIZONTAL) {
      return 'overscroll-x-none';
    }

    return 'overscroll-none';
  }, [orientation]);

  const lockOverscrollNavigation = useCallback(() => {
    const exist = document.body.classList.contains(overscrollClass);

    if (!exist) {
      document.body.classList.add(overscrollClass);
    }
  }, [overscrollClass]);

  const unlockOverscrollNavigation = useCallback(() => {
    document.body.classList.remove(overscrollClass);
  }, [overscrollClass]);

  useEffect(() => {
    return unlockOverscrollNavigation();
  }, [unlockOverscrollNavigation]);

  return [lockOverscrollNavigation, unlockOverscrollNavigation];
};

export default useOverscrollNavigationLock;
