import { ColumnMovedEvent, GridReadyEvent, RowNode } from 'ag-grid-community';
import { useCallback, useContext, useEffect } from 'react';
import { User } from 'types';

import { ColumnDefinitions, defaultCsvExportParams, getRowStyle } from '../containers/routing/RoutingTableColumns';
import { RoutingContext } from '../features/routing';
import '../features/routing/routing-table/tableStyles.scss';
import CypressTags from '../support/cypressTags';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import GridTable from 'grid-pages/organisms/GridTable';

// Used to bias the selectedDateIndex row towards the top-middle of the table view.
export const SELECTED_OFFSET = 5;

interface Props {
  user: User;
}

const RoutingTable = ({ user }: Props) => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();

  // Contexts
  const { gridApi, columnApi, selectedIndex, saveColumnState, setGridApi, setColumnApi } = useContext(RoutingContext);
  const { tableRows } = useContext(RoutingContext);

  const getRowId = useCallback((params: { data: { _id: string; id: string } }) => {
    return params.data._id || params.data.id;
  }, []);

  /**
   * Scrolls to the selectedDateIndex in the table when the rows or selectedDate changes.
   */
  useEffect(() => {
    const index = selectedIndex + SELECTED_OFFSET;
    if (gridApi && tableRows && tableRows.length >= index) {
      gridApi.ensureIndexVisible(index, 'middle');
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (user && user.preferences) {
      const state = user.preferences.routingColumnState || [{}];
      if (state) {
        // Restore column state
        columnApi?.applyColumnState({ state, applyOrder: true });
      }
    }
  }, [user, columnApi]);

  /**
   * Handles all cell edits within the grid (not including actions)
   * 
   * API calls originating from the grid leverage these handlers
   * Driving distance on-Hover and ignoring radius clause are not included here
   *
   * @param {NewValueParams} event
   *
  const onCellValueChanged = async (event: NewValueParams) => {
    const field = event.column.getColId();
    const rowIndex = tableRows.findIndex((row: TableRow) => row.id === event.data.id);
    const draftToShow = draftToShowStatus(event.oldValue, event.newValue);

    if (isEmpty(event.data) || (field === 'status' && draftToShow)) {
      await createRow(
        event,
        draftToShow,
        rowIndex,
        tableRows,
        setTableRows,
        violations,
        setViolations,
        updateInProgress,
        setUpdateInProgress
      );
    } else {
      await updateRow(
        event,
        rowIndex,
        tableRows,
        setTableRows,
        violations,
        setViolations,
        updateInProgress,
        setUpdateInProgress
      );
    }
  };
  */

  /**
   * When ag-grid finished the first render.
   *
   * @param {GridReadyEvent} params
   */
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    params.columnApi.autoSizeAllColumns(false);
  };

  /**
   * When column order has been updated.
   *
   * @param {ColumnMovedEvent} params
   */
  const onColumnMoved = (params: ColumnMovedEvent) => {
    // Save column state
    void saveColumnState(params.columnApi.getColumnState());

    // Collect row nodes of month breaks
    const rowNodes: RowNode[] = [];
    params.api.forEachNode((rowNode) => {
      if (rowNode.data?.monthBreak) rowNodes.push(rowNode as RowNode);
    });

    // Redraw all month break rows
    params.api.redrawRows({ rowNodes });
  };

  return (
    <div
      data-cy={CypressTags.ROUTING.TABLE}
      className="bg-white pt-0 mx-0 w-full max-w-full border-0 border-solid border-[#F2F2F2] h-[calc(100vh-300px-5rem)] max-h-[1200px]"
      onMouseOver={lockOverscrollNavigation}
      onMouseLeave={unlockOverscrollNavigation}
    >
      <GridTable
        pageId="routing"
        suppressExcelExport
        rowData={tableRows}
        groupHeaderHeight={0}
        tooltipShowDelay={500}
        rowDragEntireRow={false}
        columnDefs={ColumnDefinitions}
        defaultCsvExportParams={defaultCsvExportParams}
        getRowId={getRowId}
        getRowStyle={getRowStyle}
        onGridReady={onGridReady}
        onColumnMoved={onColumnMoved}
        defaultColDef={{
          sortable: false,
          // Caution: The "autoHeight: true" property creates a wrapper around custom renders, so this may change the behavior of floating panels!
          autoHeight: false,
          cellStyle: {
            padding: '0',
          },
          floatingFilter: false,
        }}
        gridOptions={{
          pagination: false,
          rowModelType: 'clientSide',
          suppressContextMenu: false,
          onRowClicked: (params) => params.api.ensureIndexVisible(params.rowIndex || 0),
        }}
      />
    </div>
  );
};

export default RoutingTable;
