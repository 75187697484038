import { ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@kaizen/draft-tooltip';
import { ICellRendererParams } from 'ag-grid-community';
import { isViolation } from 'containers/routing/RoutingTableColumns';
import { ReactElement, useCallback } from 'react';

/**
 * Radius Clause renderer.
 *
 * @param {ICellRendererParams} props
 */
export const RadiusClauseRenderer = (props: ICellRendererParams): ReactElement => {
  const radius = props.data?.rule?.radius;
  const value = props.value ? props.value : radius;
  const label = value >= 0 ? 'Mi' : '';

  if (props.data.clientAvailable === false) return <></>;
  const violating = isViolation(props.data.violations);

  const handleMouseEnter = useCallback(() => {
    const { _id, violations } = props.data;

    violations.forEach((violation: { id: string }) => {
      // highlight the row of the show who's clause is being violated
      document.querySelectorAll(`div[row-id='${violation.id}']`).forEach((row) => {
        !row.classList.contains('highlighted-row') && row.classList.add('highlighted-row');
      });

      // move the current row to higher z-index
      document.querySelectorAll(`div[row-id='${_id}']`).forEach((row) => {
        !row.classList.contains('radius-row') && row.classList.add('radius-row');
      });
    });
  }, []);

  const handleMouseLeave = useCallback(() => {
    const { _id, violations } = props.data;
    violations.forEach((violation: { id: string }) => {
      // remove the hightlight for the row of the show who's clause is being violated
      document.querySelectorAll(`div[row-id='${violation.id}']`).forEach((row) => {
        row.classList.contains('highlighted-row') && row.classList.remove('highlighted-row');
      });
      // move the current row to higher z-index
      document.querySelectorAll(`div[row-id='${_id}']`).forEach((row) => {
        row.classList.contains('radius-row') && row.classList.remove('radius-row');
      });
    });
  }, []);

  return (
    <div className="center-content overflow-visible" data-row-id={props.data.id}>
      <>
        <span>{value !== undefined && `${value} ${label}`}</span>
        {violating && (
          <Tooltip
            position="below"
            text={
              <div>
                <span
                  className={`font-bold ${props.data.violations[0].distance === -1 ? 'text-lucky' : 'text-cinnabar'}`}
                >
                  {props.data.violations[0].distance === -1 ? 'Possible ' : ''}Radius Clause Violation
                </span>
                <br />
                {props.data.violations[0].distance === -1 ? (
                  <>
                    This venue is a violation of the radius
                    <br />
                    clause timeline, but the location is unknown
                  </>
                ) : (
                  `This venue is within ${props.data.violations[0].distance} Mi of a clause violation`
                )}
              </div>
            }
          >
            {props.data.violations[0].distance === -1 ? (
              <ExclamationTriangleIcon
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                width={24}
                className="fill-lucky"
              />
            ) : (
              <ExclamationCircleIcon
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                width={24}
                className="fill-cinnabar"
              />
            )}
          </Tooltip>
        )}
      </>
    </div>
  );
};
RadiusClauseRenderer.displayName = 'RadiusClauseRenderer';
