import { IAction } from 'api/RoutingApiCalls';
import { ActionsProps } from 'grid-pages/interfaces';
import { FC, useEffect, useState } from 'react';

import HeroIcon from 'components/icons/HeroIcon';
import MobileSideDrawer from '@united-talent-agency/onyx-components/MobileSideDrawer';
import Button from '@united-talent-agency/onyx-components/Button';
import MobileDrawerHeader from 'components/MobileDrawerHeader';

export type ActionsRendererProps = {
  testId?: string;
} & Partial<ActionsProps>;

const ActionsRenderer: FC<ActionsRendererProps> = (props) => {
  // States
  const [visible, setVisible] = useState(false);
  const [actions, setActions] = useState<IAction[]>([]);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const itemActions = props?.colDef?.field && props?.actions?.[props?.colDef?.field];
  const color = (itemActions?.length ?? 0) > 0 ? 'black' : '#828181';

  useEffect(() => {
    if (!Array.isArray(itemActions)) {
      return;
    }

    const parsedActions = itemActions?.map(({ icon, title, action }) => ({
      icon,
      title,
      action: () => action(props),
    }));

    setActions(parsedActions);
  }, [props]);

  return (
    props && (
      <div
        className="flex justify-center items-center"
        data-testid={props.testId}
        data-row-id={props.data.id}
        data-deal-id={props.data.deal}
      >
        <div
          data-testid="drawer-opener"
          className="text-xl cursor-pointer"
          style={{ color: color }}
          onClick={visible ? hide : show}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            />
          </svg>
        </div>
        {visible && (
          <div
            // className="hidden md:block text-greyCod bg-white"
            className=" text-greyCod bg-white z-10"
            data-row-id={props.data.id}
            data-deal-id={props.data.deal}
          >
            <div className="hidden md:block absolute z-10 bg-white left-1/5 -translate-x-full border-px border-merlot shadow-dropdown w-max">
              {actions.map(({ icon, title, action }) => (
                <div
                  data-testid="action-desktop"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(false);
                    action();
                  }}
                  key={title}
                  className="p-4 hover:bg-[#29a1db26] w-full cursor-pointer flex items-center font-bold min-w-[180px]"
                >
                  <HeroIcon iconString={icon || ''} width={20} className="mr-2" /> {title}
                </div>
              ))}
            </div>
            <div
              data-testid="drawer-closer"
              onClick={visible ? hide : show}
              className="w-[100vw] h-[100vw] fixed top-0 left-0 opacity-0"
            />

            <MobileSideDrawer
              isOpen
              variant="full"
              className="!max-h-44"
              onClose={hide}
              header={() => <MobileDrawerHeader onClose={hide} />}
            >
              {actions.map(({ icon, title, action }) => (
                <Button
                  key={title}
                  size="lg"
                  data-testid="action-mobile"
                  type="button"
                  className="border-none w-full text-left justify-start font-normal"
                  onClick={action}
                >
                  <HeroIcon iconString={icon || ''} width={20} className="mr-2" />
                  <span className="text-sm"> {title}</span>
                </Button>
              ))}
            </MobileSideDrawer>
          </div>
        )}
      </div>
    )
  );
};

export default ActionsRenderer;
