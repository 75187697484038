import { CURRENCIES } from 'deal-form/data/constants';
import { iCurrencyOption } from 'deal-form/interfaces/general';
import { iOrganismDealTerms } from 'deal-form/interfaces/organisms';
import { numberWithCommas } from 'features/common/editors/CurrencyCellEditor';
import { FocusEventHandler, ReactElement } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { Capped, Deal, DoorDeal, FixedSplit, Flat, PlusBackend, Term, Terms, Versus } from 'types';

import { getMasterShow } from '../showHelpers';

type ComposedTerm = DoorDeal & Flat & Capped & PlusBackend & FixedSplit & Versus;

export interface TermProps<T extends FieldValues> {
  control: Control<T>;
  submit: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

export const TERM_TEXT_ERROR = 'Invalid Parameters for Deal Term Language';

export const TYPE_OPTIONS: {
  label: string;
  value: string;
}[] = [
  { label: 'Flat', value: 'flat' },
  { label: 'Plus Backend', value: 'plusBackend' },
  { label: 'Fixed Split', value: 'fixedSplit' },
  { label: 'Versus', value: 'versus' },
  { label: 'Capped', value: 'capped' },
];

export interface TermValues {
  percent: number;
  bonus: number;
  afterAmount: number;
  cap: number;
  guarantee: number;
  contractDisplay: string;
  buyerProfit: number;
  versusType: string; // For determining Versus-Gross & Versus-Net
  travelAccommodations?: string;
}

export interface FormValues extends TermValues {
  currency: { label: string; value: string };
  dealType: { label: string; value: string; component?: (_props: TermProps<FormValues>) => ReactElement };
}

let selectedCurrency: iCurrencyOption = CURRENCIES[0];

/**
 * Returns the Deal Term type as a String
 * @param term
 */
const getTermType = (term: Terms): string => Object.keys(term).filter((key) => key !== '_id')[0];

/**
 * Scrapes the Deal Terms from within a Deal
 * @param deal
 */
export const getTerms = (deal: Deal) => {
  const masterShow = getMasterShow(deal.shows);
  const term = masterShow.performers[0].terms;
  const termType = getTermType(term);
  const typeOption = TYPE_OPTIONS.filter((t) => t.value === termType)[0];
  const data = term[termType] as DoorDeal | Flat | Capped | PlusBackend | FixedSplit | Versus;

  return {
    currency: { label: data.currency, value: data.currency },
    dealType: { label: typeOption.label, value: termType },
    percent: (data as ComposedTerm).percent || 0,
    bonus: data?.bonus || 0,
    afterAmount: (data as ComposedTerm).afterAmount || 0,
    cap: (data as ComposedTerm).cap || 0,
    guarantee: (data as ComposedTerm).guarantee || 0,
    contractDisplay: (data as ComposedTerm).contractDisplay || '',
    buyerProfit: (data as ComposedTerm).buyerProfit || 0,
    versusType: (data as ComposedTerm).versusType || 'Net',
    travelAccommodations: term[termType].travelAccommodations || '',
  };
};

/**
 * Converts Term values back into explicit Numbers
 * this is done for more straight-forward truthy checks
 * @param values: form values to convert
 */
const convertTerms = (values: FormValues): TermValues => ({
  ...values,
  cap: Number(values.cap),
  guarantee: Number(values.guarantee),
  contractDisplay: values.contractDisplay,
  buyerProfit: Number(values.buyerProfit),
  percent: Number(values.percent),
  bonus: Number(values.bonus),
  afterAmount: Number(values.afterAmount),
});

/**
 * Generates the contract display for a given dealType
 * @param data
 * @param currency: optional, otherwise will use USD
 */
export const generateContractDisplay = (data: FormValues, currency?: iCurrencyOption): string => {
  if (currency) selectedCurrency = currency;
  const values = { ...convertTerms(data) } as TermValues;
  const termType = data.dealType.value;
  const map: Record<string, (v: TermValues) => string> = {
    doorDeal: () => doorDeal(values),
    capped: () => doorDeal(values, true),
    flat,
    versus,
    plusBackend,
    fixedSplit,
  };

  return map[termType]?.(values);
};

/**
 * Creates Update payload for Terms
 * slices data with respect to the termType
 * Copied from the old form utils and modified for our data structure
 * @param data
 * @returns Array<terms: Terms>}
 */
export const createTermsPayload = (data: iOrganismDealTerms, travelAccommodations?: string): Terms => {
  const { percentage, currency, bonus, afterAmount, cappedAt, guarantee, contractDisplay, promoterProfit, versusType } =
    data;
  const type = data.dealType.id;
  const common = {
    currency,
    guarantee: guarantee?.value,
    bonus: bonus?.value,
    contractDisplay,
    travelAccommodations: travelAccommodations || '',
  } as Term;

  // term takes the shape of what ever 'type' has been selected.
  let term;
  switch (type) {
    case 'flat':
      term = {
        ...common,
      } as Flat;
      break;
    case 'plusBackend':
      term = {
        ...common,
        guarantee: guarantee?.value,
        percent: percentage,
        buyerProfit: promoterProfit,
        cap: cappedAt?.value,
      } as PlusBackend;
      break;
    case 'fixedSplit':
      term = {
        ...common,
        percent: percentage,
        afterAmount: afterAmount?.value,
        cap: cappedAt?.value,
      } as FixedSplit;
      break;
    case 'versus':
      term = {
        ...common,
        percent: percentage,
        versusType,
        cap: cappedAt?.value,
      } as Versus;
      break;
    default:
      break;
  }
  return { [type]: term } as Terms;
};

/**
 * Formats value into contract currency or percentage text
 * @param value: data to format
 * @param valueType: either '%' or '$', defaults to '$'
 */
const format = (value: number, valueType = '$') => {
  const formatted = numberWithCommas(Number(value));
  return valueType === '$' ? `${selectedCurrency.symbol}${formatted} ${selectedCurrency.label}` : `${formatted}%`;
};

/**
 * Flat Terms contract text logic
 * @param values: UI form values sliced as Flat terms
 */
export const flat = (values: Flat): string => {
  const { guarantee, bonus } = values;
  let text = '';

  if (guarantee !== undefined && !Number(bonus)) {
    text = `${format(guarantee)} Flat Guarantee`;
  } else if (guarantee && bonus) {
    text = `${format(guarantee)} Guarantee PLUS ${format(bonus)} at XX Paid Admissions`;
  } else if (bonus && !guarantee) {
    text = `${format(bonus)} at XX Paid Admissions`;
  } else {
    text = TERM_TEXT_ERROR;
  }
  return text;
};

/**
 * Door Deal Terms contract text logic
 *
 *  - note - Capped Terms are a subset of DoorDeal
 *  - note - FixedSplit | Versus terms also have cases that
 *           reduce to Door Deal terms language
 * @param values: UI form values sliced as Flat terms
 * @param isCapped: flag to denote Capped terms
 */
export const doorDeal = (values: DoorDeal | Versus | FixedSplit | Capped, isCapped = false) => {
  const { percent, afterAmount, bonus, cap } = values as Capped;
  let text;
  const noAfter = `after all approved and documented fees and taxes`;

  if (percent) {
    const terms: string[] = [`${format(percent, '%')} of the Gross Box Office Receipts`];

    if (afterAmount && bonus) {
      terms.push(`${format(afterAmount)} in expenses`);
      terms.push(`PLUS ${format(bonus)} at XX Paid Admissions`);
    } else if (bonus) {
      terms.push(`${noAfter}`);
      terms.push(`PLUS ${format(bonus)} at XX Paid Admissions`);
    } else if (afterAmount) {
      terms.push(`after ${format(afterAmount)} in expenses`);
    } else {
      terms.push(`${noAfter}`);
    }

    // handle Capped language
    if (cap && isCapped) {
      const _cap = `capped at ${format(cap)}`;
      terms.length <= 2 ? terms.push(_cap) : terms.splice(terms.length - 1, 0, _cap);
    }

    text = terms.join(' ');
  } else {
    text = TERM_TEXT_ERROR;
  }
  return text;
};

/**
 * FixedSplit Terms contract text logic
 * @param values: UI form values sliced as FixedSplit terms
 */
export const fixedSplit = (values: FixedSplit) => {
  const { percent, afterAmount, bonus, guarantee, cap } = values;
  let text = '';
  const guaranteeText = `${format(guarantee)} Guarantee`;

  if (percent) {
    if (guarantee !== undefined && afterAmount) {
      text = `${guaranteeText} PLUS ${format(
        percent,
        '%'
      )} of the Gross Box Office Receipts after all approved and documented fees, taxes, and ${format(
        afterAmount
      )} in expenses`;
    } else if (afterAmount) {
      text = `${format(
        percent,
        '%'
      )} of the Gross Box Office Receipts after all approved and documented fees, taxes, and ${format(
        afterAmount
      )} in expenses`;
    } else {
      text = `${format(
        percent,
        '%'
      )} of the Gross Box Office Receipts after all approved and documented fees and taxes`;
    }

    if (bonus) {
      text += ` PLUS ${format(bonus)} at XX Paid Admissions`;
    }
    if (cap) {
      const _cap = ` capped at ${format(cap)}`;
      text += _cap;
    }
    text += `.`;
  }
  return text;
};

/**
 * PlusBackend Terms contract text logic
 * @param values: UI form values sliced as PlusBackend terms
 */
export const plusBackend = (values: PlusBackend) => {
  const { percent, bonus, guarantee, buyerProfit, cap } = values;
  let text = '';
  const guaranteeText = `${format(guarantee)} Guarantee.`;
  const percentageText = `Artist to receive ${format(
    percent,
    '%'
  )} of any backend profits made, less taxes & fees. Guarantee is subject to withholding tax`;
  const promoterText = `${format(buyerProfit, '%')} promoter profit`;

  if (percent) {
    if (guarantee !== undefined) {
      if (buyerProfit) {
        text = `${guaranteeText} ${percentageText} and ${promoterText}`;
      } else {
        text = `${guaranteeText} ${percentageText}`;
      }
    } else {
      text = `${percentageText}`;
    }

    if (bonus) {
      text += ` PLUS ${format(bonus)} at XX Paid Admissions`;
    }
    if (cap) {
      const _cap = ` capped at ${format(cap)}`;
      text += _cap;
    }
    text += `.`;
  }
  return text;
};

/**
 * Versus contract text logic
 * @param values: UI form values sliced as Versus terms
 */
export const versus = (values: Versus) => {
  const { guarantee, percent, versusType, bonus, cap } = values;
  let text = '';
  const guaranteeText = `${format(guarantee)} Guarantee`;
  const percentageTextGross = `${format(
    percent,
    '%'
  )} of the Gross Box Office Receipts after all approved and documented fees and taxes, whichever is greater`;
  const percentageTextNet = `${format(
    percent,
    '%'
  )} of the Gross Box Office Receipts after all approved and documented fees, taxes, and expenses, whichever is greater`;

  if (percent) {
    if (guarantee !== undefined) {
      if (versusType === 'Net') {
        text = `${guaranteeText} VERSUS ${percentageTextNet}`;
      } else {
        text = `${guaranteeText} VERSUS ${percentageTextGross}`;
      }
    } else {
      if (versusType === 'Net') {
        text = `${percentageTextNet}`;
      } else {
        text = `${percentageTextGross}`;
      }
    }

    if (bonus) {
      text += ` PLUS ${format(bonus)} at XX Paid Admissions`;
    }
    if (cap) {
      const _cap = ` capped at ${format(cap)}`;
      text += _cap;
    }
    text += `.`;
  }
  return text;
};
