import Content from './Content';
import Full from './Full';
import Root from './Root';

const Row = {
  Root,
  Full,
  Content,
};

export default Row;
