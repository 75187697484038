import { useState, FC } from 'react';
import { ChevronDownIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { useEditDealContext } from 'context/editDealContext';
import { toast } from 'react-toastify';
import { classes } from 'support/react-toastify';
import ToastWrapper from 'components/toast/ToastWrapper';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { GENERATE_PREIVIEW_OPTIONS } from '../../features/deal-form/header/Header';
import { handleSelect } from 'features/deal-form/header/utils';

export type PreviewDealDropdownProps = {
  contractId: string;
};

export const PreviewDealDropdown: FC<PreviewDealDropdownProps> = ({ contractId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { hasUnsavedChanged } = useEditDealContext();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const _onClick = () => {
    if (!hasUnsavedChanged()) {
      toggleOpen();
    } else {
      toast.warn(ToastWrapper('You have unsaved changes in the form, please save them before continuing', ''), {
        className: classes.warningClass,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div
        className={twMerge(
          clsx(
            'cursor-pointer w-full rounded px-4 py-2 select-none relative text-sm',
            // After:
            'after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3',
            {
              disabled: hasUnsavedChanged(),
              active: isOpen,
            }
          )
        )}
        onClick={_onClick}
      >
        <div className="flex flex-row items-center justify-between gap-x-4 font-extended text-white hover:underline">
          Preview
          <ChevronDownIcon
            width={15}
            className={twMerge(clsx('transition-all duration-200', { 'rotate-180': isOpen }))}
          />
        </div>
        <ul
          className={twMerge(
            clsx(
              'border whitespace-nowrap border-solid border-greyTapa list-none m-0 p-0 absolute top-full border-t-0 -right-[1px] transition-opacity duration-300 max-h-[200px] overflow-scroll overflow-x-visible bg-white shadow-dropdown z-10',
              {
                'opacity-1 pointer-events-auto z-10': isOpen,
                'opacity-0 pointer-events-none': !isOpen,
              }
            )
          )}
        >
          {GENERATE_PREIVIEW_OPTIONS?.map((item) => (
            <li
              key={item.id}
              className="px-4 py-2 border-t border-solid border-greyCloudy text-sm font-medium flex flex-row items-center hover:underline hover:bg-greyQuill"
              onClick={() => handleSelect({ option: item, contractId })}
            >
              <DocumentArrowDownIcon width={16} className="hover:cursor-pointer mr-2" /> {item.label}
            </li>
          ))}
        </ul>
      </div>
      {isOpen && (
        <div
          // Make this layer focusable to support closing on focus
          tabIndex={0}
          role="button"
          className="fixed top-0 left-0 opacity-0 w-[100vw] h-[100vh] z-0"
          onFocus={() => {
            setIsOpen(false);
          }}
          onClick={() => {
            setIsOpen(false);
          }}
        ></div>
      )}
    </>
  );
};
