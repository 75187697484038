import { ICellRendererParams } from 'ag-grid-community';
import { FC, useContext, useState } from 'react';

import { getActions } from '../../../../api/RoutingApiCalls';
import cypressTags from '../../../../support/cypressTags';
import { RoutingContext } from '../../index';
import { ActionsMenu } from './ActionsMenu';

export const ActionsRenderer: FC<ICellRendererParams> = (row) => {
  const showActions = !row.data?.monthBreak;
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const { tableRows, setTableRows, violations, setViolations, setShowNewDealModal, setShowRejectDealModal } =
    useContext(RoutingContext);

  const actions = getActions(
    row,
    tableRows ? tableRows : [],
    setTableRows,
    violations,
    setViolations,
    setShowNewDealModal,
    setShowRejectDealModal
  );

  return (
    <>
      {showActions && (
        <div
          className="flex justify-center items-center"
          data-row-id={row.data.id}
          data-deal-id={row.data.deal}
          data-cy={`${row.node.id}-${cypressTags.ROUTING.ACTIONS}`}
        >
          <div
            className={`text-xl ${actions.length > 0 ? '!text-black cursor-pointer' : 'text-greyTapa'}`}
            onClick={visible ? hide : show}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </div>
          {visible && (
            <div className="text-greyCod" data-row-id={row.data.id} data-deal-id={row.data.deal}>
              <ActionsMenu actionItems={actions} closeToggle={hide} alignRight={true} width={100} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
