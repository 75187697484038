import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { CURRENCIES, TICKETING_CURRENCIES } from 'deal-form/data/constants';
import { iCurrencyOption } from 'deal-form/interfaces/general';
import { iCurrencyField } from 'deal-form/interfaces/organisms';
import { BaseSyntheticEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import {
  FieldValues,
  FormState,
  RegisterOptions,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useClickAway } from 'react-use';
import { iCurrencyDefinition } from 'types/editDeal';
import { alphabetize } from 'utils/helpers';

import { findNestedErrorById } from '../../helpers/formHelpers';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

export type THandleCurrencyChange = (
  value: iCurrencyField,
  index?: number,
  setValueMethod?: UseFormSetValue<FieldValues>,
  getValues?: UseFormGetValues<FieldValues>,
  trigger?: UseFormTrigger<FieldValues>,
  parentindex?: number
) => void;

interface iCurrencyProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string | boolean;
  className?: string;
  rules?: RegisterOptions;
  dealCurrency?: iCurrencyDefinition;
  containerClassName?: string;
  overrideCurrency?: string;
  overrideCurrencyParam?: string;
  index?: number;
  parentindex?: number;
  disabled?: boolean;
  disableIf?: {
    field: string;
    value: string | number | boolean;
  };
  handleChange?: THandleCurrencyChange;
  rulesCallback?: (formState: FormState<FieldValues>, index?: number) => RegisterOptions<FieldValues, string>;
  displayOnlyInput?: boolean;
  allCurrencies?: boolean;
  dataCy?: string;
}

export const Currency = ({
  id,
  className,
  containerClassName,
  overrideCurrency,
  label = false,
  rules = {},
  dealCurrency,
  overrideCurrencyParam,
  index,
  parentindex,
  disabled = false,
  disableIf,
  displayOnlyInput,
  allCurrencies = false,
  dataCy,
  handleChange,
  rulesCallback,
  ...props
}: iCurrencyProps) => {
  const {
    register,
    setValue,
    getValues,
    control,
    formState,
    formState: { errors },
    clearErrors,
    trigger,
  } = useFormContext();

  const watchAllFields = useWatch({ control });

  // States
  const [isOpen, setIsOpen] = useState(false);
  const [chosen, setChosen] = useState<iCurrencyOption | null>(null);
  const [defaultAmount, setDefaultAmount] = useState('');
  const [overrideCurrencyByParam, setOverrideCurrencyByParam] = useState('');

  const hasErrors = errors && !!findNestedErrorById(id, errors);
  const isRequired = Object.keys(rules).includes('required');

  const openRef = useRef(null);
  useClickAway(openRef, () => {
    setIsOpen(false);
  });

  // Clear errors when component unmounts
  useEffect(() => () => clearErrors(id), []);

  useEffect(() => {
    //if value exisits on initial load, set as value
    const defaultValue = getValues(id);

    if (defaultValue?.value) {
      setDefaultAmount(defaultValue.value);
    }
    if (defaultValue?.currency) {
      const defaultCurrencyObject: iCurrencyOption | undefined = TICKETING_CURRENCIES.find(
        (cur) => cur.id === defaultValue.currency
      );

      if (defaultCurrencyObject) {
        setChosen(defaultCurrencyObject);
      }
    }
  }, []);

  useEffect(() => {
    if (chosen) {
      setValue(`${id}.currency`, chosen.id, { shouldDirty: true });
    }
  }, [chosen]);

  useEffect(() => {
    if (overrideCurrencyParam !== undefined && overrideCurrencyParam.includes('index')) {
      const overrideField = overrideCurrencyParam.replace('index', String(index));
      const valueInUpperCase = getValues(overrideField)?.toUpperCase();

      setOverrideCurrencyByParam(valueInUpperCase || '');
    }
  }, [overrideCurrencyParam, getValues, setOverrideCurrencyByParam, watchAllFields]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const _onClick = () => {
    if (!disabled) {
      toggleOpen();
    }
  };

  useEffect(() => {
    if (overrideCurrency && chosen) {
      //Needs to override chosen for symbol to update
      const newCurrency = TICKETING_CURRENCIES.find((cur) => cur.id.toLowerCase() === overrideCurrency.toLowerCase());
      if (newCurrency && newCurrency.id !== chosen.id) {
        setChosen(newCurrency);
      }
    }
  }, [overrideCurrency]);

  let disable = disabled;

  if (disableIf) {
    const value = getValues(disableIf.field.replace('{index}', `${index}`).replace('{parentindex}', `${parentindex}`));

    disable = value === disableIf.value;
  }

  const currencyOptions = allCurrencies ? TICKETING_CURRENCIES : CURRENCIES;

  let symbol = '';

  if (chosen?.symbol) {
    symbol = chosen.symbol;
  }

  const foundTicketingCurrency = TICKETING_CURRENCIES.find((cur) => {
    if (overrideCurrency) {
      return cur.id === overrideCurrency.toUpperCase();
    }

    return cur.id === overrideCurrencyByParam?.toUpperCase();
  });

  if (foundTicketingCurrency?.symbol) {
    symbol = foundTicketingCurrency.symbol;
  }

  let selectLabel;

  if (chosen && displayOnlyInput) {
    selectLabel = chosen.id;
  } else if (chosen && !displayOnlyInput) {
    selectLabel = chosen.label;
  } else if (dealCurrency) {
    selectLabel = dealCurrency.label;
  } else {
    selectLabel = 'Currency';
  }

  return (
    <>
      <div className={twMerge(clsx('form-field-wrapper', containerClassName))}>
        {label !== false && (
          <label
            className={twMerge(
              clsx('standard-label', {
                '!self-start': containerClassName && containerClassName.includes('flex-col'),
              })
            )}
          >
            {label} {isRequired ? <span className="text-cinnabar">*</span> : null}
          </label>
        )}
        <div className="relative w-full grow flex">
          <div className="inset-y-0 flex items-center pr-1 pointer-events-none">
            {symbol}
            {displayOnlyInput && '1.00'}
          </div>
          {!displayOnlyInput && (
            <div className="w-auto grow pr-2">
              <input
                defaultValue={defaultAmount}
                {...register(`${id}.value`, {
                  // TODO: refactor to combine rulesCallback and rules
                  ...(rulesCallback ? { ...rulesCallback(formState, index) } : rules),
                  onBlur: (e: BaseSyntheticEvent<HTMLInputElement>) => {
                    e.target.value = Number(e.target.value).toFixed(2);
                  },
                  onChange: () => {
                    handleChange && handleChange(getValues(id), index, setValue, getValues, trigger, parentindex);
                  },
                })}
                className={twMerge(
                  clsx(
                    'w-full border border-solid h-10 text-sm pl-2 pr-2 py-1 peer border-black disabled:opacity-50 mr-2 [appearance:textfield]',
                    {
                      'border-cinnabar': hasErrors,
                    },
                    className
                  )
                )}
                {...props}
                type="number"
                step="0.01"
                disabled={disable}
                placeholder={props.placeholder || ' '}
                aria-describedby={`${id}_error_help`}
                data-cy={dataCy}
              />
            </div>
          )}

          {overrideCurrency && <span className="text-sm leading-10">{overrideCurrency}</span>}
          {overrideCurrencyParam && <span className="text-sm leading-10 w-8">{overrideCurrencyByParam || 'USD'}</span>}
          {!overrideCurrency && !overrideCurrencyParam && (
            <div
              data-testid="currency-input-toggle-testid"
              className={twMerge(
                clsx(
                  'h-10 w-28 border border-solid border-black cursor-pointer px-3 py-2 select-none relative text-base inline-block',
                  // after
                  'after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3',
                  {
                    'opacity-50 cursor-default': disable,
                    active: isOpen,
                  },
                  className
                )
              )}
              onClick={_onClick}
            >
              <div className="flex flex-row items-center justify-between text-sm">
                {selectLabel}
                <ChevronDownIcon width={15} className={`transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`} />
              </div>

              <ul
                ref={openRef}
                className={twMerge(
                  clsx(
                    'border border-solid border-black list-none m-0 p-0 absolute top-full border-t-0 -left-[1px] -right-[1px] transition-opacity duration-300 max-h-[300px] overflow-scroll overflow-x-hidden bg-white shadow-header z-10 opacity-0 pointer-events-none',
                    {
                      'opacity-1 pointer-events-auto z-10': isOpen,
                    }
                  )
                )}
              >
                {currencyOptions?.sort(alphabetize)?.map((option: iCurrencyOption) => (
                  <li
                    key={option.id}
                    className={twMerge(
                      clsx('px-3 py-2 border-t border-solid border-greyCloudy hover:underline text-sm', {
                        'bg-greyQuill underline': option.id === chosen?.id,
                      })
                    )}
                    onClick={() => setChosen(option)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Currency.defaultProps = {
  className: '',
  containerClassName: '',
  label: false,
  rules: {},
  disabled: false,
  allCurrencies: false,
};
