import { FC } from 'react';
import { TimelineAgendaData } from '../../../TimelineAgenda/types';
import Row from '.';

export interface TimelineAgendaTableHeaderRowProps {
  data: TimelineAgendaData;
}

const Full: FC<TimelineAgendaTableHeaderRowProps> = ({ data }) => (
  <Row.Root>
    <Row.Content data={data} />
  </Row.Root>
);

export default Full;
