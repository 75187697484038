import { get, post, remove } from './index';

/**
 * Gets all the deals with Settled status for the Deals page.
 * @param urlParams – Parameters for pagination, sorting, etc
 * @returns an array of data with deal, show and calculations info for each deal
 */
export const getContractDeals = async (urlparams: string): Promise<any> => {
  return await get(`/touring/contracts/deals${urlparams}`).then((response) => {
    return response.body;
  });
};

/**
 * Method used to fetch contracting queue
 */
export const getContractingQueue = async (urlparams: string) => {
  const response = await get(`/touring/contracting${urlparams}`);
  return response.body;
};

/**
 * Method used to fetch accounting queue
 */
export const getAccountingQueue = async (urlparams: string) => {
  const response = await get(`/touring/accounting${urlparams}`);
  return response.body;
};

/**
 * Method used to fetch tracking data
 */
export const getTrackingData = async (urlparams: string) => {
  const response = await get(`/touring/contracts/tracking${urlparams}`);
  return response.body;
};

/**
 * Method used to fetch materialized view
 */
export const getDealGrids = async (urlparams: string): Promise<any> => {
  const searchParams = new URLSearchParams(urlparams);

  return await get(`/touring/dealgrids?${searchParams.toString()}`).then((response) => {
    return response.body;
  });
};

/**
 * Method used to fetch column definitions
 */
export const getColDefs = async (): Promise<any> => {
  return await get(`/touring/columns`).then((response) => {
    return response.body;
  });
};

/**
 * Method used to fetch custom reports
 */
export const getReports = async (): Promise<any> => {
  return await get(`/touring/reports`).then((response) => {
    return response.body;
  });
};

/**
 * Method used to create a new custom report
 */
export const createReport = async (report: any): Promise<any> => {
  const response = await post(`/touring/reports`, report);
  return response.body;
};

/**
 * Method used to create a new custom report
 */
export const deleteReport = async (urlparams: any): Promise<any> => {
  const response = await remove(`/touring/reports/${urlparams}`);
  return response.body;
};

/**
 * Method used to fetch preset reports
 */
export const getPresetReports = async (urlparams: string): Promise<any> => {
  return await get(`/touring/reports/preset/${urlparams}`).then((response) => {
    return response.body;
  });
};
