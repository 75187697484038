import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ReactElement } from 'react';

import { OVERVIEW_ORGANISM_SECTIONS } from 'deal-form/data/constants';

import { useOverviewMenuHook } from '../../support/hooks';

export default function FlyoutMenu(): ReactElement {
  const { overviewMenuOpen, setOverviewMenuOpen, overviewRef, onLinkClick } = useOverviewMenuHook();
  const desktopSortedSections = OVERVIEW_ORGANISM_SECTIONS.sort((a, b) => a.desktopSortOrder - b.desktopSortOrder);
  return (
    <div className="relative mb-2" ref={overviewRef}>
      <div
        className="inline-flex items-left gap-x-1 text-sm leading-6 text-white cursor-pointer"
        onClick={() => setOverviewMenuOpen(!overviewMenuOpen)}
      >
        <span className="ml-1 mb-1 text-white">Overview</span>
        <ChevronDownIcon
          width={15}
          className={`h-6 w-5 ` + `transition-all duration-200 ${overviewMenuOpen ? 'rotate-180' : ''}`}
          aria-hidden="true"
        />
      </div>

      {overviewMenuOpen && (
        <div className="absolute z-10 -left-1/4 mt-5 flex w-screen max-w-max px-4 -translate-y-4">
          <div className="w-screen max-w-lg flex-auto overflow-hidden bg-white text-sm leading-6 shadow-lg  lg:max-w-4xl">
            <div className="grid grid-cols-1 gap-x-4 gap-y-1 p-4 lg:grid-cols-3">
              {desktopSortedSections.map((section) => (
                <a
                  key={section.name}
                  href={section.href}
                  className="font-semibold text-gray-900 cursor-pointer"
                  onClick={(event) => onLinkClick(section.href, event)}
                >
                  <div className={`group relative flex gap-x-4 p-4 hover:bg-greyPearl `}>
                    <div>
                      {section.name}
                      <p className="mt-1 text-grey text-xs">{section.description}</p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
