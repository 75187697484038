import { handleUpdateDealShowsResponse, iUpdateShowPayload, updateDealShows } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  MERCHANDISE_EMPTY_FORM,
  MERCHANDISE_EMPTY_MERCH_ITEM,
  MERCHANDISE_OPTIONS_SELLER,
  MERCHANDISE_OPTIONS_TYPE,
} from 'deal-form/data/organisms';
import { DropdownWithStaticSearch } from 'deal-form/form-controls/DropdownWithStaticSearch';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismMerchandise, iOrganismMerchandiseItem } from 'deal-form/interfaces/organisms';
import React, { useMemo, useState } from 'react';
import { Deal } from 'types';
import { Merchandise } from 'types/Show';

import { FieldValues, UseFormGetValues, UseFormReset } from 'react-hook-form';
import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { TextArea } from '../../form-controls/TextArea';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { getMasterShow } from '../../helpers/showHelpers';

export const COLUMN_LABELS = [
  { value: 'Type', isRequired: true },
  { value: '%' },
  { value: 'Seller', isRequired: true },
  { value: 'Description' },
];
export const GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_1fr_3fr] gap-2 ';

const MerchandiseForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked }) => {
  const [canSubmit, setCanSubmit] = useState(true);
  const [customTierOptions, setCustomTierOptions] = useState<iOptions[]>([]);

  const onSubmit = async (data: iOrganismMerchandise) => {
    const updateData: iUpdateShowPayload[] = [];
    const mappedMerch: Merchandise[] = [];

    // grab and validate contacts to save
    if (data.merchandise.length > 0) {
      data.merchandise.forEach(({ type, description, percentage, seller }) => {
        if (type && seller) {
          const m: Merchandise = {
            note: description,
            percent: +percentage,
            seller: seller,
            type: type,
          };
          mappedMerch.push(m);
        }
      });
    }

    // add the contacts to all the shows
    deal.shows.forEach((s) => {
      const updatedShow = {
        showId: s._id,
        updateBody: {
          merchandise: mappedMerch || [],
        },
      };
      updateData.push(updatedShow);
    });

    // submit the change
    if (updateData.length > 0) {
      try {
        setCanSubmit(false);

        const response = await updateDealShows({
          dealId: deal._id.toString(),
          updates: updateData,
        });

        if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });
        setTimeout(() => setCanSubmit(true), 500);
      } catch (e) {
        console.log('ERROR SAVING MERCHENDISE DATA', e);
        setCanSubmit(true);
      }
    }
  };

  const getMerchandise = (d: Deal) => {
    const merchandise: iOrganismMerchandiseItem[] = [];
    const masterShow = getMasterShow(d.shows);

    masterShow.merchandise.forEach((item) => {
      merchandise.push({
        description: item.note,
        type: item.type,
        percentage: item.percent,
        seller: item.seller,
      });
    });
    return merchandise;
  };

  const defaultValues = useMemo(
    () =>
      getMerchandise(deal)
        ? {
            merchandise: getMerchandise(deal),
          }
        : MERCHANDISE_EMPTY_FORM,
    [deal]
  );

  const handleAddCustomOption = (newOption: string) => {
    setCustomTierOptions([...customTierOptions, { id: newOption, label: newOption, data: {} }]);
  };

  /**
   * override the built in append functionality
   *
   * @param index row to be removed
   */
  const handleRemoveOverride = async (
    index: number,
    reset: UseFormReset<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>
  ) => {
    const vals: iOrganismMerchandise = getValues?.() as iOrganismMerchandise;

    if (vals?.merchandise?.length) {
      const isNew = vals.merchandise?.[index]?.isNew || false;
      vals.merchandise.splice(index, 1);

      if (isNew) {
        reset(vals);
      } else {
        onSubmit(vals);
      }
    } else {
      reset();
    }
  };

  return (
    <Form
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      className={`form-row full-width ${canSubmit ? '' : 'opacity-50'}`}
      defaultValues={defaultValues}
      disabled={!isOpen || locked}
      formName={FORM_NAMES.MERCHANDISE}
    >
      <FieldArray
        gridClassName={GRID_LAYOUT}
        groupName="merchandise"
        columns={COLUMN_LABELS}
        addButtonLabel="Add Merchandise"
        emptyRow={MERCHANDISE_EMPTY_MERCH_ITEM}
        limit={20}
        triggerFields={['type', 'seller']}
        disabled={!isOpen || locked || !canSubmit}
        overrideRemove={handleRemoveOverride}
      >
        <DropdownWithStaticSearch
          placeholder="Type"
          id="type"
          maxLength={32}
          addCustomOption={handleAddCustomOption}
          customOptions={customTierOptions}
          staticOptions={MERCHANDISE_OPTIONS_TYPE}
          disabled={!isOpen || locked}
          rules={{ required: 'Please select a valid ‘Type’ option' }}
          customOptionLabel="Create Custom Type"
        />
        <Input
          placeholder="Percentage"
          fieldType="percent"
          id="percentage"
          step="0.1"
          rules={{
            min: { value: 0.0, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
            max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
          }}
          disabled={!isOpen || locked}
        />
        <Dropdown
          placeholder="Seller"
          id="seller"
          options={MERCHANDISE_OPTIONS_SELLER}
          disabled={!isOpen || locked}
          rules={{ required: 'Please select a valid ‘Seller’ option' }}
        />
        <TextArea
          placeholder="Type description here."
          rules={{
            maxLength: {
              value: 1024,
              message: getMaxCharMessage('the description', 1024),
            },
          }}
          id="description"
          rows={1}
          disabled={!isOpen || locked}
        />
      </FieldArray>
    </Form>
  );
};

export default MerchandiseForm;
