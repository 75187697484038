import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { Dictionary } from 'types/dictionary';

import { ActionsRenderer } from './actions/Actions';
import { AgesEditor, AgesRenderer } from './ages/Ages';
import { BuyerCompanyEditor, BuyerCompanyRenderer, BuyerPersonEditor, BuyerPersonRenderer } from './buyer/Buyer';
import { ClientUnavailable } from './client-unavailable/ClientUnavailable';
import { DealTypeEditor, DealTypeRenderer } from './deal-type/DealType';
import { EventDateRenderer } from './event-date/EventDate';
import { CurrencyCellEditor } from './generic-editors/CurrencyCellEditor';
import { LargeInputCellEditor } from './generic-editors/LargeInputCellEditor';
import { NumericCellEditor } from './generic-editors/NumericCellEditor';
import { PercentageCellEditor } from './generic-editors/PercentageCellEditor';
import { GuaranteeRenderer } from './guarantee/Guarantee';
import { MerchRenderer } from './merch/Merch';
import { MonthBreak } from './month-break/MonthBreak';
// import { NotesRenderer } from './notes/Notes';
import { RadiusClauseRenderer } from './radius-clause/RadiusClause';
import { SellableCapacityRenderer } from './sellable-capacity/SellableCapacity';
import { DealStatusEditor, DealStatusRenderer } from './status/Status';
import { TimelineRenderer } from './timeline/Timeline';
import { VenueEditor, VenueRenderer } from './venue/Venue';
import { WalkoutPotentialRenderer } from './walkout-potential/WalkoutPotential';

export interface DropDownCandidate {
  value: string;
  color?: string;
}

/**
 * Fallback renderer simply outputs the text format of the value.
 *
 * @param {ICellRendererParams} props
 * @returns {ReactElement}
 */
const FallbackRenderer: FC<ICellRendererParams> = (props) => {
  const value = props.valueFormatted ? props.valueFormatted : props.value;
  return (
    <div className="center-content" data-row-id={props.data.id}>
      <span>{value || ''}</span>
    </div>
  );
};

/**
 * Unified renderer for all columns.
 *
 *  Returns <MonthBreak /> if current cell is the first column and month-break row.
 *  Returns corresponding renderer instance in rest of the cases.
 *
 * @param {ICellRendererParams} props
 * @returns {ReactElement}
 */
const UnifiedRenderer: FC<ICellRendererParams> = (props) => {
  const colId = props.column?.getColId();
  const rendererMap: Dictionary<FC<ICellRendererParams>> = {
    _: FallbackRenderer,
    ages: AgesRenderer,
    buyerCompany: BuyerCompanyRenderer,
    buyerPerson: BuyerPersonRenderer,
    city: FallbackRenderer,
    country: FallbackRenderer,
    dealType: DealTypeRenderer,
    guarantee: GuaranteeRenderer,
    hardMerch: MerchRenderer,
    hold: FallbackRenderer,
    holdNumber: FallbackRenderer,
    // notes: NotesRenderer,
    phone: FallbackRenderer,
    radius: RadiusClauseRenderer,
    sellableCapacity: SellableCapacityRenderer,
    softMerch: MerchRenderer,
    state: FallbackRenderer,
    status: DealStatusRenderer,
    timeAfter: FallbackRenderer,
    timeBefore: FallbackRenderer,
    tix: FallbackRenderer,
    venue: VenueRenderer,
    walkoutPotential: WalkoutPotentialRenderer,
  };
  const Renderer = rendererMap[colId || '_'];
  const currentFirstColId = props.columnApi.getColumnState().filter((c) => c.pinned === null)[0].colId;

  if (colId === undefined) {
    return <></>;
  } else if (props.data?.monthBreak && currentFirstColId === colId) {
    return <MonthBreak {...props} />;
  } else if (!props.data?.clientAvailable && currentFirstColId === colId) {
    return <ClientUnavailable {...props} />;
  } else {
    return <Renderer {...props} />;
  }
};

export {
  CurrencyCellEditor,
  FallbackRenderer,
  NumericCellEditor,
  PercentageCellEditor,
  UnifiedRenderer,
  //  custom editors
  ActionsRenderer,
  AgesRenderer,
  AgesEditor,
  BuyerCompanyRenderer,
  BuyerCompanyEditor,
  BuyerPersonRenderer,
  BuyerPersonEditor,
  DealStatusRenderer,
  DealStatusEditor,
  DealTypeRenderer,
  DealTypeEditor,
  EventDateRenderer,
  GuaranteeRenderer,
  MerchRenderer,
  // NotesRenderer,
  RadiusClauseRenderer,
  SellableCapacityRenderer,
  TimelineRenderer,
  VenueRenderer,
  VenueEditor,
  WalkoutPotentialRenderer,
  ClientUnavailable,
  LargeInputCellEditor,
};
