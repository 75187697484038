import { CURRENCIES } from 'deal-form/data/constants';
import { FC, PropsWithChildren, createContext, useContext, useState } from 'react';
import { iCategoryView } from 'types/deal';
import { TEditDealContext, iCurrencyDefinition, iEditDealOrganism, iEditDealOrganisms } from 'types/editDeal';

export const EditDealContext = createContext<TEditDealContext | null>(null);

const EditDealProvider: FC<PropsWithChildren> = ({ children }) => {
  const [organisms, setOrganisms] = useState<iEditDealOrganisms>({});
  const [defaultCurrency, setDefaultCurrency] = useState<iCurrencyDefinition>(CURRENCIES[0]);
  const [categoryView, setCategoryView] = useState<iCategoryView>({ contract: true, deal: true, show: true });

  /**
   * add the organism to the object to be validated with the form
   *
   * @param {string} id - the id of the organism
   */
  const registerOrganism = (id: string) => {
    const newOrganism: iEditDealOrganism = {
      isValid: true,
      isDirty: false,
    };

    setOrganisms((prevState) => ({ ...prevState, [id]: newOrganism }));
  };

  /**
   * update the state of the selected organism to be either valid or invalid
   *
   * @param {string} id the id of the organism
   * @param {boolean} isValid is the form valid, and can be submitted
   */
  const updateOrganismValid = (id: string, isValid: boolean) => {
    if (organisms && organisms[id]) {
      const updatedOrganism = organisms[id];
      updatedOrganism.isValid = isValid;
      setOrganisms({ ...organisms, [id]: updatedOrganism });
    }
  };

  /**
   * update the organism object with a boolean to determine if changes
   * have been made to the content
   *
   * @param {string} id the id of the organism
   * @param {boolean} isDirty has the organism been edited
   */
  const updateOrganismDirty = (id: string, isDirty: boolean) => {
    if (organisms && organisms[id]) {
      const updatedOrganism = organisms[id];
      updatedOrganism.isDirty = isDirty;
      setOrganisms({ ...organisms, [id]: updatedOrganism });
    }
  };

  /**
   * check all registered organisms to see if there are any changes that are
   * unsaved
   *
   * @returns {boolean} are there unsaved changes on any of the organisms
   */
  const hasUnsavedChanged = () => {
    let isDirty = false;
    Object.keys(organisms).forEach((key) => {
      if (organisms[key].isDirty) isDirty = true;
    });
    return isDirty;
  };

  /**
   * check if the organism has unsaved changes
   *
   * @param {string} id the id of the organism
   * @returns {boolean} are there unsaved changes on the organism
   */
  const isOrganismDirty = (id: string) => {
    const organism = organisms[id];

    return organism?.isDirty;
  };

  return (
    <EditDealContext.Provider
      value={{
        organisms,
        registerOrganism,
        updateOrganismValid,
        updateOrganismDirty,
        defaultCurrency,
        setDefaultCurrency,
        hasUnsavedChanged,
        isOrganismDirty,
        categoryView,
        setCategoryView,
      }}
    >
      {children}
    </EditDealContext.Provider>
  );
};

export default EditDealProvider;

export const useEditDealContext = () => {
  return useContext(EditDealContext) as TEditDealContext;
};
