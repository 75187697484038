interface WireTransferMappings {
  [key: string]: string;
}

const wireTransferMappings: WireTransferMappings = {
  // Office, Subsidiary, Line of Business, Department, Currency
  '[Atlanta Office][United Talent Agency, LLC][Music][Music][USD]': 'US Music USD',
  '[Atlanta Office][United Talent Agency, LLC][Music][Music][CAD]': 'US Music/Comedy CAD',
  '[Atlanta Office][United Talent Agency, LLC][Music][Music][AUD]': 'US Music/Comedy AUD',
  '[Atlanta Office][United Talent Agency, LLC][Music][Music][EUR]': 'US Music/Comedy EUR',
  '[Atlanta Office][United Talent Agency, LLC][Music][Music][GBP]': 'US Music/Comedy GBP',
  '[Los Angeles Office][United Talent Agency, LLC][Music][Music][USD]': 'US Music USD',
  '[Los Angeles Office][United Talent Agency, LLC][Music][Music][CAD]': 'US Music/Comedy CAD',
  '[Los Angeles Office][United Talent Agency, LLC][Music][Music][AUD]': 'US Music/Comedy AUD',
  '[Los Angeles Office][United Talent Agency, LLC][Music][Music][EUR]': 'US Music/Comedy EUR',
  '[Los Angeles Office][United Talent Agency, LLC][Music][Music][GBP]': 'US Music/Comedy GBP',
  '[Nashville Office][United Talent Agency, LLC][Music][Music][USD]': 'US Music USD',
  '[Nashville Office][United Talent Agency, LLC][Music][Music][CAD]': 'US Music/Comedy CAD',
  '[Nashville Office][United Talent Agency, LLC][Music][Music][AUD]': 'US Music/Comedy AUD',
  '[Nashville Office][United Talent Agency, LLC][Music][Music][EUR]': 'US Music/Comedy EUR',
  '[Nashville Office][United Talent Agency, LLC][Music][Music][GBP]': 'US Music/Comedy GBP',
  '[New York Office][United Talent Agency, LLC][Music][Music][USD]': 'US Music USD',
  '[New York Office][United Talent Agency, LLC][Music][Music][CAD]': 'US Music/Comedy CAD',
  '[New York Office][United Talent Agency, LLC][Music][Music][AUD]': 'US Music/Comedy AUD',
  '[New York Office][United Talent Agency, LLC][Music][Music][EUR]': 'US Music/Comedy EUR',
  '[New York Office][United Talent Agency, LLC][Music][Music][GBP]': 'US Music/Comedy GBP',
  '[Atlanta Office][United Talent Agency, LLC][Music - Circle][Music][USD]': 'US Music USD',
  '[Atlanta Office][United Talent Agency, LLC][Music - Circle][Music][CAD]': 'US Music/Comedy CAD',
  '[Atlanta Office][United Talent Agency, LLC][Music - Circle][Music][AUD]': 'US Music/Comedy AUD',
  '[Atlanta Office][United Talent Agency, LLC][Music - Circle][Music][EUR]': 'US Music/Comedy EUR',
  '[Atlanta Office][United Talent Agency, LLC][Music - Circle][Music][GBP]': 'US Music/Comedy GBP',
  '[Los Angeles Office][United Talent Agency, LLC][Music - Circle][Music][USD]': 'US Music USD',
  '[Los Angeles Office][United Talent Agency, LLC][Music - Circle][Music][CAD]': 'US Music/Comedy CAD',
  '[Los Angeles Office][United Talent Agency, LLC][Music - Circle][Music][AUD]': 'US Music/Comedy AUD',
  '[Los Angeles Office][United Talent Agency, LLC][Music - Circle][Music][EUR]': 'US Music/Comedy EUR',
  '[Los Angeles Office][United Talent Agency, LLC][Music - Circle][Music][GBP]': 'US Music/Comedy GBP',
  '[Nashville Office][United Talent Agency, LLC][Music - Circle][Music][USD]': 'US Music USD',
  '[Nashville Office][United Talent Agency, LLC][Music - Circle][Music][CAD]': 'US Music/Comedy CAD',
  '[Nashville Office][United Talent Agency, LLC][Music - Circle][Music][AUD]': 'US Music/Comedy AUD',
  '[Nashville Office][United Talent Agency, LLC][Music - Circle][Music][EUR]': 'US Music/Comedy EUR',
  '[Nashville Office][United Talent Agency, LLC][Music - Circle][Music][GBP]': 'US Music/Comedy GBP',
  '[New York Office][United Talent Agency, LLC][Music - Circle][Music][USD]': 'US Music USD',
  '[New York Office][United Talent Agency, LLC][Music - Circle][Music][CAD]': 'US Music/Comedy CAD',
  '[New York Office][United Talent Agency, LLC][Music - Circle][Music][AUD]': 'US Music/Comedy AUD',
  '[New York Office][United Talent Agency, LLC][Music - Circle][Music][EUR]': 'US Music/Comedy EUR',
  '[New York Office][United Talent Agency, LLC][Music - Circle][Music][GBP]': 'US Music/Comedy GBP',
  '[Atlanta Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][USD]': 'US Comedy USD',
  '[Atlanta Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][CAD]': 'US Music/Comedy CAD',
  '[Atlanta Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][AUD]': 'US Music/Comedy AUD',
  '[Atlanta Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][EUR]': 'US Music/Comedy EUR',
  '[Atlanta Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][GBP]': 'US Music/Comedy GBP',
  '[Los Angeles Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][USD]': 'US Comedy USD',
  '[Los Angeles Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][CAD]':
    'US Music/Comedy CAD',
  '[Los Angeles Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][AUD]':
    'US Music/Comedy AUD',
  '[Los Angeles Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][EUR]':
    'US Music/Comedy EUR',
  '[Los Angeles Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][GBP]':
    'US Music/Comedy GBP',
  '[Nashville Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][USD]': 'US Comedy USD',
  '[Nashville Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][CAD]':
    'US Music/Comedy CAD',
  '[Nashville Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][AUD]':
    'US Music/Comedy AUD',
  '[Nashville Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][EUR]':
    'US Music/Comedy EUR',
  '[Nashville Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][GBP]':
    'US Music/Comedy GBP',
  '[New York Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][USD]': 'US Comedy USD',
  '[New York Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][CAD]': 'US Music/Comedy CAD',
  '[New York Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][AUD]': 'US Music/Comedy AUD',
  '[New York Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][EUR]': 'US Music/Comedy EUR',
  '[New York Office][United Talent Agency, LLC][Talent Representation][Comedy and Touring][GBP]': 'US Music/Comedy GBP',
  '[London Office][United Talent Agency UK Operations Limited][Music][Music][USD]': 'London Office USD',
  '[London Office][United Talent Agency UK Operations Limited][Music][Music][AUD]': 'London Office AUD',
  '[London Office][United Talent Agency UK Operations Limited][Music][Music][EUR]': 'London Office EUR',
  '[London Office][United Talent Agency UK Operations Limited][Music][Music][GBP]': 'London Office GBP',
  '[London Office][United Talent Agency UK Operations Limited][Music][Music][NOK]': 'London Office NOK',
  '[London Office][United Talent Agency UK Operations Limited][Music][Music][SEK]': 'London Office SEK',
  '[London Office][United Talent Agency UK Operations Limited][Music - Echo][Music][USD]': 'London Office USD',
  '[London Office][United Talent Agency UK Operations Limited][Music - Echo][Music][AUD]': 'London Office AUD',
  '[London Office][United Talent Agency UK Operations Limited][Music - Echo][Music][EUR]': 'London Office EUR',
  '[London Office][United Talent Agency UK Operations Limited][Music - Echo][Music][GBP]': 'London Office GBP',
  '[London Office][United Talent Agency UK Operations Limited][Music - Echo][Music][NOK]': 'London Office NOK',
  '[London Office][United Talent Agency UK Operations Limited][Music - Echo][Music][SEK]': 'London Office SEK',
  '[London Office][United Talent Agency UK Operations Limited][Talent Representation][Comedy and Touring][USD]':
    'London Office USD',
  '[London Office][United Talent Agency UK Operations Limited][Talent Representation][Comedy and Touring][AUD]':
    'London Office AUD',
  '[London Office][United Talent Agency UK Operations Limited][Talent Representation][Comedy and Touring][EUR]':
    'London Office EUR',
  '[London Office][United Talent Agency UK Operations Limited][Talent Representation][Comedy and Touring][GBP]':
    'London Office GBP',
  '[London Office][United Talent Agency UK Operations Limited][Talent Representation][Comedy and Touring][NOK]':
    'London Office NOK',
  '[London Office][United Talent Agency UK Operations Limited][Talent Representation][Comedy and Touring][SEK]':
    'London Office SEK',
  '[Scandinavia Office][UTA Scandinavia AB][Music][Music][USD]': 'Malmo Office USD',
  '[Scandinavia Office][UTA Scandinavia AB][Music][Music][AUD]': 'Malmo Office AUD',
  '[Scandinavia Office][UTA Scandinavia AB][Music][Music][EUR]': 'Malmo Office EUR',
  '[Scandinavia Office][UTA Scandinavia AB][Music][Music][GBP]': 'Malmo Office GBP',
  '[Scandinavia Office][UTA Scandinavia AB][Music][Music][NOK]': 'Malmo Office NOK',
  '[Scandinavia Office][UTA Scandinavia AB][Music][Music][SEK]': 'Malmo Office SEK',
};

const getWireTransferAccount = (
  loc: string,
  currency: string,
  subsidiary: string,
  department: string,
  lineOfBusiness: string
) => {
  const key = `[${loc}][${subsidiary}][${lineOfBusiness}][${department}][${currency}]`;
  return wireTransferMappings[key] || 'No Wire Transfer Account Found';
};

export default getWireTransferAccount;
