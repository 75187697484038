import { FC, PropsWithChildren, createContext, useState } from 'react';
import { iOverviewContextValues } from 'types/deal';

const OverviewContext = createContext<iOverviewContextValues>({});

const OverviewProvider: FC<PropsWithChildren> = ({ children }) => {
  const [overviewSelectedOrganism, setOverviewSelectedOrganism] = useState<string>('');

  return (
    <OverviewContext.Provider value={{ overviewSelectedOrganism, setOverviewSelectedOrganism }}>
      {children}
    </OverviewContext.Provider>
  );
};

export default OverviewProvider;

export { OverviewContext };
