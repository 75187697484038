import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Badge, Button, Divider, SelectOptionT } from '@united-talent-agency/onyx-components';
import { ArrowDown, ArrowUp, Filters } from '@united-talent-agency/onyx-components/Icons';

import FilterCategoryHeader from './FilterCategoryHeader';
import ClientFilter from '../ClientFilter';
import LocationFilter from '../LocationFilter';
import GenresFilter from '../GenresFilter';
import ShowTypesFilter from '../ShowTypesFilter';
import GuaranteeFilter, { formatValueToGuaranteeFilterValue } from '../GuaranteeFilter';
import DateFilter from '../DateFilter';

import MobileFocusedView from '../MobileFocusedView';

import { DEFAULT_SELECT_OPTION, GENRE_OPTIONS, SHOW_TYPES_OPTIONS } from '../constants';
import { useFilters } from 'context/avails-filters/context';

import '../styles.scss';
import FiltersPreview, { FilterPreviewData } from '../FiltersPreview';
import { getDateFormatByBrowser } from 'utils/helpers';

interface FilterConfig {
  title: string;
  component: React.ComponentType<any>;
  values?: any;
  onValuesChange?: (value: any) => void;
  setMyClientsSelected?: (selected: boolean) => void;
}

const FILTERS: FilterConfig[] = [
  {
    title: 'Clients',
    component: ClientFilter,
  },
  { title: 'Genre', component: GenresFilter },
  {
    title: 'Date',
    component: DateFilter,
  },
  { title: 'Location', component: LocationFilter },
  {
    title: 'Show Type',
    component: ShowTypesFilter,
  },
  { title: 'Guarantee', component: GuaranteeFilter },
];

interface FilterContainerProps {
  currentDeskId: string;
}

const FilterContainer = ({ currentDeskId }: FilterContainerProps) => {
  const {
    // COMMON
    collapsed,
    mountedFilters,
    clearAll,
    toggleCollapsed,

    // DATE
    date,
    daysBefore,
    daysAfter,

    // CLIENTS
    myClients,
    selectedClients,
    showAllMyClients,

    // GENRES
    selectedGenres,
    genresFocusedView,
    setSelectedGenres,
    setGenresFocusedView,

    // SHOW TYPE
    selectedShowType,
    showTypesFocusedView,
    setSelectedShowType,
    setShowTypesFocusedView,

    // LOCATION
    locationRadius,
    selectedLocation,

    // GUARANTEE
    guaranteeRange,
  } = useFilters();

  const applyFilters = useCallback(() => {
    // It just closes the filters, as the side effect of closing the filters is the triggering of a new request.
    toggleCollapsed();
  }, [mountedFilters, toggleCollapsed]);

  const selectedClientsFilteredByMyClients: SelectOptionT[] = useMemo(() => {
    const myClientIds = new Set(myClients.map((client) => client._id));

    // Filter out clients that exist in myClientIds set
    return selectedClients.filter((client) => !myClientIds.has(client.label._id));
  }, [myClients, selectedClients]);

  const filterCount = useMemo(
    () =>
      (
        Number(selectedClients.length > 0) +
        Number(selectedGenres.length > 0) +
        Number(!!selectedShowType?.value?.toString().length) +
        1 + // Assuming that the date will always be selected by default
        Number(selectedShowType?.value !== DEFAULT_SELECT_OPTION.value)
      ).toString(),
    [selectedShowType, selectedGenres, selectedClients]
  );

  const clientsFilterPreviewValue = useMemo(
    () =>
      (showAllMyClients && myClients.length !== 0
        ? [...selectedClientsFilteredByMyClients, { label: { name: 'My Clients (All)' } }]
        : selectedClients
      )
        ?.map(({ label: { name } }) => name)
        .join(', '),
    [showAllMyClients, selectedClientsFilteredByMyClients, selectedClients]
  );

  const dateFilterPreviewValue = useMemo(() => {
    const displayFormat = getDateFormatByBrowser();

    // Date
    const currentDateStr = moment(date).format(displayFormat);

    const RANGE_UNIT = 'days';
    // Date - Before
    const beforeDate = moment(date).subtract(daysBefore, RANGE_UNIT);
    const beforeDateStr = beforeDate.format(displayFormat);

    // Date - After
    const afterDate = moment(date).add(daysAfter, RANGE_UNIT);
    const afterDateStr = afterDate.format(displayFormat);

    let rangeStr = '';
    if (daysBefore > 0 || daysAfter > 0) {
      rangeStr = `** and between **${beforeDateStr}** and **${afterDateStr}`;
    }

    return `${currentDateStr}${rangeStr}`;
  }, [date, daysBefore, daysAfter]);

  const locationFilterPreviewValue = useMemo(
    () =>
      selectedLocation?.label?.length
        ? `${selectedLocation?.label}**${locationRadius > 0 ? ` within **${locationRadius}** miles` : ''}**`
        : '',
    [selectedLocation?.label, locationRadius]
  );

  const guaranteeFilterPreviewValue = useMemo(() => {
    let { min, max } = guaranteeRange;

    if (!min && !max) {
      return '';
    }

    const minStr = formatValueToGuaranteeFilterValue(min);
    const maxStr = formatValueToGuaranteeFilterValue(max);

    return `**Minimum **${minStr}** - Maximum **${maxStr}`;
  }, [guaranteeRange]);

  const filtersPreviewData: FilterPreviewData[] = useMemo(
    () => [
      {
        label: 'Client',
        value: clientsFilterPreviewValue,
      },
      {
        label: 'Genre',
        value: selectedGenres?.map(({ label }) => label)?.join(', '),
      },
      {
        label: 'On',
        value: dateFilterPreviewValue,
      },
      {
        label: 'Located in',
        value: locationFilterPreviewValue,
      },
      {
        label: 'Guarantee',
        value: guaranteeFilterPreviewValue,
      },
    ],
    [
      clientsFilterPreviewValue,
      selectedGenres,
      dateFilterPreviewValue,
      locationFilterPreviewValue,
      guaranteeFilterPreviewValue,
    ]
  );

  const allGenres = useMemo(
    () =>
      GENRE_OPTIONS?.map((option) => ({
        ...option,
        checked: selectedGenres?.some(({ value }) => value === option?.value),
      })),
    [selectedGenres]
  );

  const allShowTypes = useMemo(
    () =>
      SHOW_TYPES_OPTIONS?.map((option) => ({
        ...option,
        checked: option?.value === selectedShowType?.value,
      })),
    [selectedShowType]
  );

  return (
    <div className="relative">
      {[genresFocusedView, showTypesFocusedView].includes(true) ? (
        <div className="fixed top-0 right-0 bottom-0 left-0 w-full h-full max-h-screen z-50 avails-mobile">
          <div className={genresFocusedView ? 'flex' : 'hidden'}>
            <MobileFocusedView
              isMulti
              title="Genre"
              clearButtonLabel="Clear Genre"
              data={allGenres}
              onLeaveFocusView={() => setGenresFocusedView(false)}
              onChange={(data) => setSelectedGenres(data?.filter(({ checked }) => checked) as SelectOptionT[])}
            />
          </div>
          <div className={showTypesFocusedView ? 'flex' : 'hidden'}>
            <MobileFocusedView
              title="Show Type"
              clearButtonLabel="Clear Show Type"
              data={allShowTypes}
              onChange={(data) => setSelectedShowType(data?.find(({ checked }) => checked) as SelectOptionT)}
              onLeaveFocusView={() => setShowTypesFocusedView(false)}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        className={twMerge(
          clsx(
            'py-2 lg:py-[20px] m-0 relative lg:m-5 filter-container print:lg:pt-4 print:lg:pb-5 print:lg:m-0 border-b border-b-[#DFDFDB] lg:border-b-0  bg-white print:bg-transparent print:border-b-0',
            {
              'h-0': showTypesFocusedView || genresFocusedView,
            }
          )
        )}
      >
        <div
          onClick={toggleCollapsed}
          className="px-4 flex gap-3 items-center justify-center lg:justify-start lg:px-5 py-[10px] lg:py-0 print:hidden cursor-pointer"
        >
          <h2 className="text-base font-bold lg:text-2xl leading-7">Clients Availability</h2>

          <div className="hidden lg:block">
            {collapsed ? <ArrowDown height={24} width={24} /> : <ArrowUp height={24} width={24} />}
          </div>
          <div className="lg:hidden flex items-center">
            <Badge size="md" text={filterCount} bgColor="#DFDFDB" color="#000000" className="rounded-none mr-3" />
            <Filters height={24} width={24} />
          </div>
        </div>
        <FiltersPreview
          data={filtersPreviewData}
          className={[
            'hidden',
            'print:block',
            {
              'lg:block': collapsed,
            },
          ]}
        />
        {!collapsed && (
          <div className="filter-body">
            <div className="px-4 lg:px-5 grid grid-cols-1 lg:grid-cols-2 gap-5 py-5 md:gap-x-12 pb-4 divide-[#DFDFDB] divide-y md:divide-none">
              {FILTERS.map((filter) => (
                <div key={filter.title} className="pt-6 pb-2">
                  <FilterCategoryHeader title={filter.title} />
                  {React.createElement(filter.component, {
                    currentDeskId,
                  })}
                </div>
              ))}
            </div>
            <div className="inset-x-0 bottom-0 lg:static lg:py-4 py-2">
              <div className="block lg:hidden">
                <Divider />
              </div>
              <div
                className={`px-4 lg:py-4 lg:px-5 py-2 flex flex-1 lg:justify-end justify-center gap-5 items-center lg:relative bg-white ${
                  collapsed ? 'hidden' : 'block'
                }`}
              >
                <Button type="button" className="lg:mr-3 w-full lg:w-auto lg:border-0" onClick={clearAll}>
                  Clear All
                </Button>
                <Button variant="solid" type="button" onClick={applyFilters} className="w-full lg:w-auto">
                  Apply
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterContainer;
