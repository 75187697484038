import { updateDeal } from 'api';
import { FORM_NAMES } from 'deal-form/data/constants';
import { PROVISIONS_EMPTY_FORM, PROVISIONS_EMPTY_FORM_ITEM } from 'deal-form/data/organisms';
import { FieldArray } from 'deal-form/form-controls/FieldArray';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismProvisions } from 'deal-form/interfaces/organisms';
import { MasterDealWarning } from 'deal-form/ui/MasterDealWarning';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Deal } from 'types';
import { Provision } from 'types/data-schemas';

import { DropdownWithStaticSearch } from '../../form-controls/DropdownWithStaticSearch';
import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';

const GRID_LAYOUT = 'grid grid-cols-2 gap-5 py-4';

const AdditionalProvisionsForm: React.FC<iFormProps> = ({ deal, setDeal, contract, setContract, isOpen, locked }) => {
  const [provisionOptions, setProvisionOptions] = useState<iOptions[]>();
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  // const [dealToUse, setDealToUse] = useState<Deal>(deal);
  const [crossed, setCrossed] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    let dealToUse = deal;
    if (contract && contract.masterDeal && contract.crossed === 'Crossed') {
      dealToUse = contract.masterDeal;
      setMasterDeal(contract.masterDeal);
      setCrossed(true);
    } else {
      setMasterDeal(null);
      setCrossed(false);
    }
    if (dealToUse?.client?.provisions && dealToUse.client.provisions.length > 0) {
      const newProvisions: iOptions[] = [];
      dealToUse.client.provisions.forEach(({ option, note }) => {
        const newProvision: iOptions = {
          id: option,
          label: option,
          data: note,
        };
        newProvisions.push(newProvision);
      });
      newProvisions.push({ id: 'Custom', label: 'Custom', data: '' });
      setProvisionOptions(newProvisions);
    }
  }, [deal, contract]);

  const onSubmit = async (data: iOrganismProvisions) => {
    const dealToUse = getDealToUse();

    try {
      setCanSubmit(false);
      const updatedProvisions: Provision[] = [];

      if (data.provisions.length > 0) {
        data.provisions.forEach(({ label, content }) => {
          const provision: Provision = { note: content, option: label };
          updatedProvisions.push(provision);
        });
      }
      const updatedDeal = await updateDeal(dealToUse._id, {
        ...dealToUse,
        provision: updatedProvisions,
      });

      if (updatedDeal) {
        if (contract && masterDeal && crossed && setContract) {
          setContract({
            ...contract,
            masterDeal: updatedDeal as Deal,
          });
        } else if (setDeal) setDeal(updatedDeal);
        setTimeout(() => setCanSubmit(true), 500);
      }
    } catch (e) {
      console.log('ERROR updated provisions', e);
      setCanSubmit(true);
    }
  };

  /**
   * This function fires when the provision is selected, it replaces the text in the
   * field with the text associated with the selected item
   *
   * @param chosen the data about the person being selected
   * @param setValueMethod the function to set a value on the form
   * @param index the id of the form row to be effected
   */
  const handleChangeProvision = (
    chosen: iOptions | null,
    setValueMethod?: UseFormSetValue<FieldValues>,
    index?: number
  ) => {
    if (setValueMethod && index !== undefined) setValueMethod(`provisions.${index}.content`, chosen?.data);
  };

  /**
   * This function fires when the content field is typed in, in case we need to always change
   * the type to Custom if someone changes the field
   *
   * @param setValueMethod function to change the value ont he form
   * @param index index of the form row
   */
  const handleContentChange = (setValueMethod?: UseFormSetValue<FieldValues>, index?: number) => {
    if (setValueMethod && index !== undefined) setValueMethod(`provisions.${index}.label`, 'Custom');
  };

  const getDealToUse = () => (masterDeal && crossed ? masterDeal : deal);

  const defaultValues = useMemo(
    () =>
      getDealToUse()?.provision?.length === 0
        ? PROVISIONS_EMPTY_FORM
        : {
            provisions: getDealToUse()?.provision.map(({ note, option }) => {
              return { label: option, content: note };
            }),
          },
    [deal, masterDeal]
  );

  const contentPlaceHolder = useMemo(
    () =>
      provisionOptions
        ? 'Either select a provision above or type a custom one here.'
        : 'This artist does not have set provisions stored against them, you can create your own provision here.',
    [provisionOptions]
  );

  return (
    <>
      {masterDeal && crossed && <MasterDealWarning crossed={crossed} fields={['Additional Provisions']} />}

      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.ADDITIONAL_PROVISIONS}
      >
        <FieldArray
          gridClassName={GRID_LAYOUT}
          groupName="provisions"
          addButtonLabel="Add Provision"
          emptyRow={PROVISIONS_EMPTY_FORM_ITEM}
          rearrangeable={false}
          disabled={!isOpen || locked}
        >
          <DropdownWithStaticSearch
            id="label"
            label="Provision"
            handleChange={handleChangeProvision}
            staticOptions={provisionOptions || []}
            disabled={!isOpen || locked || !provisionOptions}
            placeholder="Please select a Provision"
          />

          <TextArea
            label={false}
            placeholder={contentPlaceHolder}
            rules={{
              required: 'Please enter you provision description or remove this item.',
            }}
            handleChange={handleContentChange}
            id="content"
            containerClassName="col-start-1 col-span-full"
            rows={6}
            disabled={!isOpen || locked}
          />
        </FieldArray>
      </Form>
    </>
  );
};

export default AdditionalProvisionsForm;
