import { Checkbox } from '@united-talent-agency/onyx-components';
import { CheckboxProps } from '@united-talent-agency/onyx-components/Forms/Checkbox/Checkbox';
import { TableRowData } from 'components/Table/types';
import { TimelineAgendaData } from 'components/TimelineAgenda/types';
import { FC, useCallback } from 'react';
import Table from '../../Table';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { TableHeaderCellProps } from 'components/Table/Header/Cell';

export interface HeaderColumnProps extends TableHeaderCellProps {
  row: TableRowData;
  index: number;
  data: TimelineAgendaData;
  setData: (data: TimelineAgendaData) => void;
}

type HandleOnSelectFn = (index: number) => Required<CheckboxProps>['onChange'];

const BodyCell: FC<HeaderColumnProps> = ({ row, index, data, setData, className }) => {
  const handleOnSelect = useCallback<HandleOnSelectFn>(
    (_index) => (e) => {
      setData(
        data?.map((_row, rowIndex) => ({
          ..._row,
          selected: _index === rowIndex ? e.target.checked : _row.selected,
        }))
      );
    },
    [setData]
  );

  return (
    <Table.Header.Cell
      key={`row-${index}`}
      className={twMerge(
        clsx(
          'sticky print:relative left-0 bg-transparent max-w-none min-w-[74px] md:min-w-[212px] print:min-w-[74px] print:md:min-w-[124px] print:border-b-0',
          className
        )
      )}
    >
      <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-1 min-w-[80px] max-h-full md:min-w-[218px] print:min-w-[80px] print:md:min-w-[130px] bg-gradient-to-r from-[calc(100%-6px)] from-transparent via-[rgba(0,0,0,0.1)] via-[calc(100%-6px)] to-transparent to-100% print:bg-none" />
      <div className="flex absolute top-0 right-[6px] bottom-0 left-0 min-w-[74px] md:min-w-[212px] print:min-w-[74px] print:md:min-w-[124px] border-b-2 border-solid border-[#f5f5f5]">
        <div className="flex h-full w-full bg-white print:bg-transparent max-h-full overflow-hidden">
          <div className="flex flex-1 flex-row items-center px-4 md:px-6 print:px-0 print:md:px-0 overflow-x-hidden">
            <div className="relative flex max-h-full max-md:flex-1 md:items-center max-w-full overflow-hidden">
              <Checkbox checked={row?.selected} onChange={handleOnSelect(index)} className="print:hidden" />
              <span
                className={twMerge(
                  clsx(
                    // mobile and common
                    'w-full max-h-full max-w-full ml-2 md:ml-0 pt-2 pb-2 text-sm text-center font-normal max-md:overflow-hidden max-md:[writing-mode:vertical-rl] justify-center',
                    // desktop
                    'md:inline md:relative md:pl-6 md:whitespace-normal md:text-left',
                    // print
                    'print:md:pl-2 print:ml-0 print:text-xs print:font-bold'
                  )
                )}
              >
                {row?.timelineLabel}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Table.Header.Cell>
  );
};

export default BodyCell;
