import { ICellEditorParams, KeyCode } from 'ag-grid-community';
import { KeyboardEventHandler, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

export const LargeInputCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  useEffect(() => {
    if (props.eventKey === KeyCode.BACKSPACE || props.eventKey === KeyCode.DELETE || !props.value) {
      setValue('');
    }
    if (!props.value) {
      setValue(props.charPress);
    }
  }, []);
  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any value greater than 1000
        return false;
      },
    };
  });
  const onKeyDown: KeyboardEventHandler = (event) => {
    if (event.code === 'Enter') {
      setTimeout(() => props.stopEditing(), 10);
    }
  };
  return (
    <textarea
      ref={inputRef}
      value={value}
      onChange={(event) => setValue(event.target.value)}
      style={{ width: '100%' }}
      maxLength={300}
      cols={50}
      rows={6}
      onKeyDown={onKeyDown}
    />
  );
});

LargeInputCellEditor.displayName = 'LargeInputCellEditor';
