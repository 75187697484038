import { FormSelect, SelectOptionT } from '@united-talent-agency/onyx-components';

import { SHOW_TYPES_OPTIONS } from '../constants';
import { useFilters } from 'context/avails-filters/context';
import useIsMobile from 'hooks/isMobile';

const ShowTypesFilter = () => {
  const { selectedShowType, showTypesFocusedView, setSelectedShowType, setShowTypesFocusedView } = useFilters();
  const isMobile = useIsMobile();

  return (
    <div className="">
      <FormSelect
        placeholder="Select Show Type"
        label="Show Type"
        isSearchable
        options={SHOW_TYPES_OPTIONS}
        menuPosition="fixed"
        menuShouldBlockScroll
        value={selectedShowType}
        menuIsOpen={isMobile ? showTypesFocusedView : undefined}
        onFocus={() => isMobile && setShowTypesFocusedView(true)}
        onChange={(value: any) => setSelectedShowType(value)}
        noOptionsMessage="No Show Type"
        loadOptions={async (inputValue: string) => {
          return new Promise<SelectOptionT[]>((resolve) => {
            resolve(
              SHOW_TYPES_OPTIONS.reduce<SelectOptionT[]>((result, i) => {
                if (i.label.toLocaleLowerCase().includes(inputValue.toLowerCase())) {
                  return result.concat(i);
                }
                return result;
              }, [])
            );
          });
        }}
        className="w-full"
      />
    </div>
  );
};

export default ShowTypesFilter;
