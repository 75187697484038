import { forwardRef, PropsWithChildren } from 'react';

const Root = forwardRef<HTMLTableElement, PropsWithChildren>(({ children }, ref) => (
  <table
    ref={ref}
    className="border-collapse overflow-auto inline-block w-full bg-white print:bg-transparent h-full max-h-[70vh] print:max-h-none print:overflow-visible"
  >
    {children}
  </table>
));

export default Root;
