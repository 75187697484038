import { SEARCH_TYPE } from 'deal-form/constants';
import { TICKETING_CURRENCIES } from 'deal-form/data/constants';
import { iAddressProps, iCurrencyOption, iOptions } from 'deal-form/interfaces/general';
import { Deal } from 'types';

import { get } from '../../../api';
import { getMasterShow } from '../showHelpers';
import moment from 'moment';
import { getDateFormatByBrowser } from 'utils/helpers';

export const getShowFromTierId = (id: string) => {
  return {
    showId: id.split('|')[0],
    showTime: id.split('|')[1],
    tierLabel: id.split('|')[2],
  };
};

export const searchForOptions = async (searchType: SEARCH_TYPE, searchTerm: string) => {
  // default test data
  let parsedOptions: iOptions[] = [
    {
      id: 'option1',
      label: 'Option 1',
      data: { foo: 'bar' },
    },
    {
      id: 'option2',
      label: 'Option 2',
      data: { foo: 'bar' },
    },
    {
      id: 'option3',
      label: 'Option 3',
      data: { foo: 'bar' },
    },
    {
      id: 'option4',
      label: 'Something Else',
      data: { foo: 'bar' },
    },
    {
      id: 'option5',
      label: 'Something 1',
      data: { foo: 'bar' },
    },
  ];

  // The API doesn't accept special chars `[!'()*]`
  const encodedSearchTerm = encodeURIComponent(searchTerm.replace(/[!'()*]/g, ' '));

  let searchUrl = undefined;
  switch (searchType) {
    case 'employee':
      searchUrl = `/touring/people/${encodedSearchTerm}/Employee`;
      break;
    case 'contact':
      searchUrl = `/touring/people/${encodedSearchTerm}`;
      break;
    case 'venue':
      searchUrl = `/venues/search/${encodedSearchTerm}`;
      break;
    case 'company':
      searchUrl = `/touring/companies/${encodedSearchTerm}`;
      break;
  }

  if (!searchUrl) return [];

  const options = await get(searchUrl);

  parsedOptions = options.body.map((option: { _id: string; name: string }) => {
    return {
      id: option._id,
      label: option.name,
      data: option,
    };
  });

  //TODO replace with relevant api call and parse into option array
  let filterOptions: iOptions[] = [];
  switch (searchType) {
    case 'employee':
    case 'contact':
    case 'venue':
    case 'company':
      filterOptions = parsedOptions.filter((option: { label: string }) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      break;

    default:
      break;
  }
  return filterOptions;
};

export const formatAddress = (address: iAddressProps) => {
  if (address && typeof address !== 'string') {
    let addressString = '';
    let addressLine = address.address;
    const cityStateZip = `${address.city}, ${address.state} ${address.zip}`;
    // Add suite to display if present
    if (address.suite && address.suite !== 'no data') {
      addressLine += ` ${address.suite}`;
    }
    addressString = `${addressLine}\n${cityStateZip}`;
    // Add country to display if present
    if (address.country) {
      addressString += `\n${address.country}`;
    }

    return addressString;
  }

  return '';
};

export const wasBeforeToday = (date: Date): boolean => {
  const today = new Date();
  return new Date(formatDate(date)).getTime() < new Date(formatDate(today)).getTime();
};

export const formatDate = (date?: Date | string, utc?: boolean): string => {
  if (utc !== undefined) {
    return moment(date).utc(utc).format(getDateFormatByBrowser());
  }

  return moment(date).format(getDateFormatByBrowser());
};

export const formatPercent = (value: string | number): string => {
  if (Number.isNaN(+value)) {
    return '0.00';
  }
  return String(Number(value).toFixed(2));
};

export const formatCurrency = (value: string | number): string => {
  if (Number.isNaN(+value)) {
    return '0.00';
  }

  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  // return numberWithCommas(Number(value).toFixed(2));
};

export const formatNumber = (value: string | number): string => {
  if (Number.isNaN(+value)) {
    return '0';
  }

  return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  // return numberWithCommas(Number(value).toFixed(2));
};

export const formatCurrencyWithSymbol = (value: number, currency: string): string => {
  return `${TICKETING_CURRENCIES.find((el: iCurrencyOption) => el?.id?.toUpperCase() === currency)
    ?.symbol}${formatCurrency(value)} ${currency}`;
};

export const formatTotals = (totalObj?: { amount: number; currency: string }) => ({
  value: totalObj?.amount || 0,
  currency: totalObj?.currency || 'USD',
});

/**
 * Returns the ticket currency for a deal
 *
 * @param {object} deal - deal object
 * @returns {string} currency - deal currency
 */
export const getTicketCurrency = (deal: Deal) => getMasterShow(deal.shows)?.ticketTiers?.[0]?.currency || deal.currency;

/**
 * Renames deal type names
 *
 * @param {string} dealTypeName - a deal type name
 * @returns {strings} formatted deal type name
 */
export const parseDealTypeName = (dealTypeName: string) => (dealTypeName === 'Plus Backend' ? 'Plus' : dealTypeName);

export const convertCurrencyAndUpdateContractById = async (
  amount: number,
  from: string,
  to: string,
  exchangeRates?: any,
  contractId?: string
) => {
  try {
    if (from === to) return amount;
    if (
      exchangeRates &&
      exchangeRates[from] &&
      exchangeRates[to] &&
      (exchangeRates[from][to] || exchangeRates[to][from])
    ) {
      if (exchangeRates[from][to]) {
        return amount * exchangeRates[from][to];
      } else {
        return amount * (1 / exchangeRates[to][from]);
      }
    } else {
      let requestString = `/touring/exchangeRates?fromCurrency=${from}&toCurrency=${to}`;
      if (contractId) {
        requestString += `&contractId=${contractId}`;
      }
      const response = await get(requestString);
      return amount * response.body.conversionResponse.rates[to];
    }
  } catch (error) {
    console.log(error);
    return 0;
  }
};
