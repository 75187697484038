import clsx from 'clsx';
import { ButtonHTMLAttributes, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as ScrollToTopArrowIcon } from '../../assets/scroll-to-top-arrow.svg';

const ScrollFAB: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...props }) => (
  <button
    type="button"
    className={twMerge(
      clsx(
        'fixed bottom-8 right-8 flex flex-col justify-center items-center pt-[18px] pb-2.5 px-3.5 bg-black border border-[#808080] text-[#808080] hover:text-white',
        className
      )
    )}
    {...props}
  >
    <ScrollToTopArrowIcon className="w-5 h-6" />
    <span className="text-[10px] font-medium">TOP</span>
  </button>
);

export default ScrollFAB;
