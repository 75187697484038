import { getBackstageUser, sendUserInvite } from 'api/backstage';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import Button from 'components/Button';
import cypressTags from 'support/cypressTags';

import { RoutingClient } from '../../api/clients-routing';
import { Checkbox } from '../../deal-form/form-controls/Checkbox';
import { Input } from '../../deal-form/form-controls/Input';
import { Modal } from '../../features/common/modal/Modal';
import { isValidUrl } from 'utils/cloudinary';

enum SHARE_STEPS {
  EMAIL = 0,
  NAME = 1,
  SUCCESS = 2,
  FAILURE = 3,
}

interface IModalShareBackstage {
  show: boolean;
  setShow: any;
  client: RoutingClient | null;
}

const defaultValues = {
  emailAddress: '',
  tier: false,
  firstName: '',
  lastName: '',
  error: '',
};

const TEST_EMAIL = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

export const ModalShareBackstage = ({ show, setShow, client }: IModalShareBackstage) => {
  const [currentStep, setCurrentStep] = useState<SHARE_STEPS>(SHARE_STEPS.EMAIL);
  const [accessRequestSent, setAccessRequestSent] = useState<boolean>(false);

  const methods = useForm({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const submitForm = async (data: FieldValues) => {
    const sendData = {
      email: data.emailAddress,
      firstName: data.firstName,
      lastName: data.lastName,
      clientId: client?._id,
      tier: data.tier ? '2' : '1',
    };

    const { ok, body } = await sendUserInvite(sendData);
    if (ok) {
      setCurrentStep(SHARE_STEPS.SUCCESS);
    } else {
      console.log('There was an error sending the invite', body);
      setCurrentStep(SHARE_STEPS.FAILURE);
    }
  };

  const handleClose = async () => {
    setShow(false);
    setIsEmailValid(false);
    setError('');
    reset();
    setCurrentStep(SHARE_STEPS.EMAIL);
  };

  const watchEmail = watch('emailAddress');

  useEffect(() => {
    if (watchEmail) {
      if (errors.emailAddress) {
        setIsEmailValid(false);
      } else {
        setIsEmailValid(TEST_EMAIL.test(watchEmail));
      }
    }
  }, [watchEmail, errors.emailAddress]);

  return (
    <Modal
      showModal={show}
      showClose={false}
      setShowModal={setShow}
      title={`${client && client?.name} | Share Backstage | Step ${currentStep + 1} of 3`}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <>
            <div className="flex">
              {client?.profile_pic && isValidUrl(client?.profile_pic) && (
                <img className="object-cover w-16 h-16 mr-8" src={client?.profile_pic} alt="client profile pic" />
              )}
              <p aria-label="share-tnc">
                Before you share any sensitive information about <strong>{client?.name}</strong>, please make sure you
                trust the person you're sharing it with and consider the potential consequences of sharing such
                information.
              </p>
            </div>

            {currentStep === SHARE_STEPS.EMAIL && (
              <div className=" pt-4">
                <label className="form-label">Email</label>
                <Input
                  dataCy={cypressTags.ROSTER.BACKSTAGE_EMAIL_INPUT}
                  type="email"
                  label={false}
                  id="emailAddress"
                  rules={{
                    required: 'Please enter a valid email address',
                    pattern: TEST_EMAIL,
                    maxLength: {
                      value: 320,
                      message: 'Please enter a valid email address',
                    },
                  }}
                />
              </div>
            )}

            {currentStep === SHARE_STEPS.NAME && (
              <>
                <div className=" pt-4">
                  <label className="form-label">
                    Email: <strong>{watch('emailAddress')}</strong>
                  </label>
                </div>
                <div className=" pt-4">
                  <label className="form-label">First Name</label>
                  <Input label={false} id="firstName" placeholder="First Name" />
                </div>
                <div className=" pt-4">
                  <label className="form-label pt-4">Last Name</label>
                  <Input label={false} id="lastName" placeholder="Last Name" />
                </div>
              </>
            )}

            {(currentStep === SHARE_STEPS.NAME || currentStep === SHARE_STEPS.EMAIL) && (
              <div className=" pt-4">
                <label className="form-label pt-4">
                  <Checkbox label={`Show Financials (Tier 2)`} id="tier" checkColor={'black'} />
                </label>
                {error && (
                  <label className="text-sm mt-8 text-red pb-4" id="error">
                    {`Access for ${getValues(
                      'emailAddress'
                    )} has been disabled and artists can no longer be shared with their
                  account. If you believe this was an error, please reach out to backstagesupport@unitedtalent.com for
                  assistance.`}
                  </label>
                )}
              </div>
            )}

            {currentStep === SHARE_STEPS.SUCCESS && (
              <>
                <h4 className="text-xl mt-8 font-semibold pb-4" data-cy={cypressTags.ROSTER.BACKSTAGE_SHARE_TITLE}>
                  Thank you - an email has been sent to {getValues('emailAddress')}
                </h4>
              </>
            )}
            {currentStep === SHARE_STEPS.FAILURE && (
              <>
                <h4 className="text-xl mt-8 font-semibold pb-4">
                  There was an error sending this invite. Please try again later.
                </h4>
              </>
            )}
          </>

          <div className="flex flex-row justify-between pt-6">
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>

            {currentStep === SHARE_STEPS.NAME && (
              <Button
                dataCy={cypressTags.ROSTER.BACKSTAGE_SHARE_ARTIST_BTN}
                variant="secondary"
                disabled={accessRequestSent}
                onClick={async () => {
                  setAccessRequestSent(true);
                  await handleSubmit(submitForm)();
                  setAccessRequestSent(false);
                }}
              >
                Send Access
              </Button>
            )}
            {currentStep === SHARE_STEPS.EMAIL && (
              <Button
                dataCy={cypressTags.ROSTER.BACKSTAGE_COMPLETE_EMAIL_NEXT_BTN}
                variant="secondary"
                onClick={async (event) => {
                  event.preventDefault();
                  setError('');
                  const email = getValues('emailAddress');
                  const { ok, body } = await getBackstageUser(encodeURIComponent(email));
                  if (body?.status == 409) {
                    setError(body?.userMessage);
                  } else {
                    if (ok) {
                      setValue('firstName', body?.firstName);
                      setValue('lastName', body?.lastName);
                    } else {
                      console.log('Not found', body);
                    }
                    setCurrentStep(SHARE_STEPS.NAME);
                  }
                }}
                disabled={!isEmailValid || errors.emailAddress ? true : false}
              >
                Next
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
