import { FC, PropsWithChildren, ReactElement } from 'react';

interface IMobileNav {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const MobileNav: FC<PropsWithChildren<IMobileNav>> = ({ children, isOpen, setIsOpen }): ReactElement => {
  return (
    <>
      <div className="flex items-center md:hidden">
        <button
          className="focus:outline-none font-extended pl-8 py-2 text-white flex flex-row whitespace-nowrap"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.1665 4.33301H23.8332V6.3965H2.1665V4.33301Z" fill="white" />
            <path d="M2.1665 11.5552H23.8332V13.6187H2.1665V11.5552Z" fill="white" />
            <path d="M2.1665 18.7774H23.8332V20.8409H2.1665V18.7774Z" fill="white" />
          </svg>

          {/* Menu */}
        </button>
      </div>
      <div
        className={`fixed md:hidden top-0  ${
          isOpen ? 'right-0' : 'left-full'
        } transition-all duration-200 ease-out w-5/6 min-h-full bg-white  overflow-y-auto h-screen`}
      >
        <div className="w-full flex flex-row justify-between py-8 pt-7">
          <h3 className="font-extrabold text-black text-xl pl-8">TOURING</h3>
          <button className="focus:outline-none pr-8" onClick={() => setIsOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </>
  );
};

export default MobileNav;
