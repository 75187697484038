import { UTARelationship } from 'types/data-schemas';
import { Person } from 'types/person';

import { get } from './index';

export type Employer = {
  title: string;
  _id: string;
  group: { name: string };
};

export const findPeople = async (name: string): Promise<Person[]> => {
  if (!name) {
    return [];
  }
  const response = await get(`/touring/people/${name}`);
  return response.body;
};
/**
 * Find person with type of Employee by name,if no name is given return an empty array so that dropdowns aren't populated.
 * @param name - string to be searched by
 */
export const findEmployee = async (name: string): Promise<Person[]> => {
  if (!name) {
    return [];
  }
  const response = await get(`/touring/people/${name}/Employee`);
  return response.body;
};
/**
 * Find agents related to a client.
 * @param clientId - string to be searched by
 */
export const findClientAgents = async (clientId: string): Promise<UTARelationship[]> => {
  if (!clientId) {
    return [];
  }
  const response = await get(`/v2/internal-teams/person-to-uta-employee?person=${clientId}`);
  return response.body;
};
