import React, { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

import './Button.style.css';

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  variant?: 'primary' | 'secondary' | 'stroke';
  size?: 'sm' | 'md';
  dataCy?: string;
}

const Button = ({
  leftIcon,
  rightIcon,
  children,
  className,
  variant = 'primary',
  size = 'md',
  style = {},
  dataCy,
  ...props
}: ButtonProps) => {
  const variantClass = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    stroke: 'btn-stroke',
  };

  const sizeClass = {
    sm: 'h-8',
    md: 'h-10',
  };

  return (
    <button
      style={style}
      className={twMerge(
        'w-auto flex flex-row items-center justify-center font-medium flex-nowrap cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:no-underline gap-x-1',
        variantClass[variant],
        sizeClass[size],
        className && className
      )}
      {...props}
      data-cy={dataCy}
    >
      {leftIcon && leftIcon}
      <span className="whitespace-nowrap">{children}</span>
      {rightIcon && rightIcon}
    </button>
  );
};

export default Button;
