import React from 'react';

const DocumentExportIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.53125 14.2812H17.1741L15.4809 15.9809L16.4062 16.9062L19.6875 13.625L16.4062 10.3438L15.4809 11.2691L17.1741 12.9688H8.53125V14.2812Z"
      fill="black"
    />
    <path
      d="M14.4375 9.68751V7.06251C14.438 6.97614 14.4214 6.89053 14.3888 6.81057C14.3561 6.73062 14.308 6.65789 14.2472 6.59657L9.65344 2.00282C9.59212 1.942 9.51939 1.89388 9.43944 1.86122C9.35948 1.82857 9.27387 1.81201 9.1875 1.81251H2.625C2.2769 1.81251 1.94306 1.95079 1.69692 2.19693C1.45078 2.44307 1.3125 2.77691 1.3125 3.12501V18.875C1.3125 19.2231 1.45078 19.5569 1.69692 19.8031C1.94306 20.0492 2.2769 20.1875 2.625 20.1875H13.125C13.4731 20.1875 13.8069 20.0492 14.0531 19.8031C14.2992 19.5569 14.4375 19.2231 14.4375 18.875V17.5625H13.125V18.875H2.625V3.12501H7.875V7.06251C7.875 7.41061 8.01328 7.74445 8.25942 7.99059C8.50556 8.23673 8.8394 8.37501 9.1875 8.37501H13.125V9.68751H14.4375ZM9.1875 7.06251V3.39407L12.8559 7.06251H9.1875Z"
      fill="black"
    />
  </svg>
);

export default DocumentExportIcon;
