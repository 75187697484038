import { updateContract, updateDeal } from 'api';
import { getBusinessEntities } from 'api/business';
import { getSignerBlocks } from 'api/signerBlocks';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import { SPLIT_DEAL_EMPTY_FORM, SPLIT_DEAL_EMPTY_ITEM, SPLIT_DEAL_OPTIONS_SPLIT_TYPE } from 'deal-form/data/organisms';
import { Currency, THandleCurrencyChange } from 'deal-form/form-controls/Currency';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismSplitDeal, iOrganismSplitDealItem } from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Deal } from 'types';
import { ISplitDeal } from 'types/deal';

import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Input, THandleInputChange } from '../../form-controls/Input';
import { TextArea } from '../../form-controls/TextArea';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { MasterDealWarning } from 'deal-form/ui/MasterDealWarning';
import { useArtistCurrency } from '../../helpers/useArtistCurrency';
import cypressTags from 'support/cypressTags';

const { SPLIT_DEAL_SECTION } = cypressTags.DEAL_FORM;
const GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_1fr] gap-2 ';

const SplitDealsForm: React.FC<iFormProps> = ({ deal, setDeal, contract, setContract, isOpen }) => {
  const [businessNames, setBusinessNames] = useState<iOptions[]>([]);
  const [signerBlocks, setSignerBlocks] = useState<iOptions[]>([]);
  const [canSubmit, setCanSubmit] = useState(true);
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  const [hiddenFields, setHiddenFields] = useState<any>([]);
  const artistCurrency = useArtistCurrency(deal);
  const updateRowCount = (numberOfRows: number) => {
    let tempHiddenFields = [{ index: 0, value: '', ids: ['splits.0.splitType'] }];
    for (let i = 0; i < numberOfRows + 1; i++) {
      if (i === 0) continue;
      tempHiddenFields.push({ index: i, value: '', ids: [`splits.${i}.mustSplitType`] });
    }
    setHiddenFields(tempHiddenFields);
  };

  const onSubmit = async (data: iOrganismSplitDeal) => {
    // map the data to the correct format
    const mappedValues: ISplitDeal[] = data.splits.map((split) => ({
      amount: split.amount.value,
      currency: artistCurrency?.id || 'USD',
      splitPercent: split.percentage !== undefined ? +split.percentage : 100,
      contractSplitType: split.splitType || 'Client',
      artistBusiness: split.clientBusinessName.data,
      contractDisplay: split.dealDisplay,
      contractSignerBlock: split.contractSignerBlock,
      balanceRemittance: split.balanceRemittance,
      splitType: split.splitType !== '' ? split.mustSplitType : split.splitType,
    }));
    // validate the split data here

    const dealToUse = masterDeal || deal;

    // submit the change
    if (mappedValues.length > 0) {
      try {
        setCanSubmit(false);
        const updatedDeal = await updateDeal(dealToUse._id, {
          ...dealToUse,
          dealSplits: mappedValues,
        });
        if (updatedDeal) {
          if (contract && masterDeal) {
            const updatedContract = await updateContract(contract._id, { ...contract, masterDeal: updatedDeal });
            setContract && setContract(updatedContract);
          } else if (setDeal) setDeal(updatedDeal);
        }
        setTimeout(() => setCanSubmit(true), 500);
      } catch (e) {
        console.log('ERROR SAVING DEAL SPLITS', e);
        setCanSubmit(true);
      }
    } else if (mappedValues.length === 0) {
      // clear the deal splites
      try {
        setCanSubmit(false);
        const updatedDeal = await updateDeal(dealToUse._id, {
          ...dealToUse,
          dealSplits: [],
        });
        if (updatedDeal) {
          if (contract && masterDeal) {
            const updatedContract = await updateContract(contract._id, { ...contract, masterDeal: updatedDeal });
            setContract && setContract(updatedContract);
          } else if (setDeal) setDeal(updatedDeal);
        }
        setTimeout(() => setCanSubmit(true), 500);
      } catch (e) {
        console.log('ERROR SAVING DEAL SPLITS', e);
        setCanSubmit(true);
      }
    }
  };

  const getSplitDeal = (d: Deal) => {
    const splits: iOrganismSplitDealItem[] = [];

    if (d.dealSplits && d.dealSplits.length > 0) {
      d.dealSplits.forEach((split) => {
        splits.push({
          amount: {
            value: split.amount || 0,
            currency: split.currency,
          },
          balanceRemittance: split.balanceRemittance || '',
          clientBusinessName: {
            name: split.artistBusiness?.name || '',
            data: split.artistBusiness || null,
          },
          contractSignerBlock: split.contractSignerBlock,
          dealDisplay: split.contractDisplay || '',
          percentage: split.splitPercent,
          splitType: split.contractSplitType,
          mustSplitType: undefined,
        });
      });
    }

    return splits;
  };

  const getContractSignerBlocks = async (clientId: string) => {
    const signerData = await getSignerBlocks(clientId);

    if (signerData && signerData.length > 0) {
      const signerBlockOptions = signerData.map((option) => ({
        id: `${option.label}-${option.territory}`,
        label: `${option.label} (${option.territory})`,
        data: option.text,
      }));
      setSignerBlocks(signerBlockOptions);
    }
  };

  const getBusinessData = async (clientId: string) => {
    const businessData = await getBusinessEntities(clientId);

    if (businessData && businessData.length > 0) {
      const businessOptions = businessData.map((option, index) => ({
        id: option.name + index,
        label: option.name,
        data: option,
      }));
      setBusinessNames(businessOptions);
    } else {
      const businessOptions = [
        {
          name: 'Test Co LLC Tax ID',
          territory: 'All Territories',
          primary: true,
          taxID: '99-9999999',
        },
        {
          name: 'Test Co 2 LLC Tax ID',
          territory: 'All Territories',
          primary: true,
          taxID: '99-9999992',
        },
      ].map((option) => ({
        id: option.name,
        label: `${option.name} (${option.taxID})`,
        data: option,
      }));
      setBusinessNames(businessOptions);
    }
  };

  useEffect(() => {
    if (contract && contract.masterDeal && contract.crossed === 'Crossed') {
      setMasterDeal(contract.masterDeal);
    } else {
      setMasterDeal(null);
    }

    const { client } = deal;

    if (client) {
      getBusinessData(client._id);
      getContractSignerBlocks(client._id);
    }
  }, [deal, contract]);

  const defaultValues: iOrganismSplitDeal = useMemo(() => {
    const dealToUse = contract && contract.masterDeal && contract.crossed === 'Crossed' ? contract.masterDeal : deal;
    let splits: iOrganismSplitDealItem[] = [];
    if (getSplitDeal(dealToUse)) {
      splits = getSplitDeal(dealToUse).map((dealSplit) => {
        if (dealSplit.splitType === 'Client') dealSplit.mustSplitType = 'Client (Artist)';
        else dealSplit.mustSplitType = '';
        return dealSplit;
      });
      if (splits.length === 0) splits.push(SPLIT_DEAL_EMPTY_ITEM);
      updateRowCount(splits.length);
      return { splits };
    } else {
      return SPLIT_DEAL_EMPTY_FORM;
    }
  }, [deal, contract]);

  const handleChangeBusiness = (
    chosen: iOptions | null,
    setValueMethod?: UseFormSetValue<FieldValues>,
    index?: number
  ) => {
    if (setValueMethod) setValueMethod(`splits.${index}.clientBusinessName.data`, chosen?.data);
  };

  /**
   * when the split percentage changes, we need to update the amount value
   * to align with the current split.
   *
   * @param {string} value the split percent
   * @param {number} index the id of the item being edited in the array
   * @param {UseFormSetValue} setValueMethod the function to set a value on the form
   * @param {UseFormGetValues} getValues method to get the form data since we are out of context
   */
  const handleChangeSplit: THandleInputChange = (value, index, setValueMethod, getValues, trigger) => {
    if (getValues && setValueMethod) {
      const dealToUse = contract && contract.masterDeal && contract.crossed === 'Crossed' ? contract : deal;

      const guarantee = dealToUse.guarantee === 0 ? 1000 : dealToUse.guarantee;
      const splitAmount = (+value / 100) * guarantee;
      setValueMethod(`splits.${index}.amount.value`, splitAmount.toFixed(2));

      if (trigger) {
        // TODO: possibly only pass the amount and percentange ids to tigger to avoid
        // rendering other fields unnecessarily
        trigger();
      }
    }
  };

  /**
   * when the amount changes, we need to update the split percentage
   * to align with the current amount - guarantee ratio.
   *
   * @param {iCurrencyField} value the current value of the amount
   * @param {number} index the id of the item being edited in the array
   * @param {UseFormSetValue} setValueMethod the function to set a value on the form
   * @param {UseFormGetValues} getValues method to get the form data since we are out of context
   */
  const handleChangeAmount: THandleCurrencyChange = (value, index, setValueMethod, getValues, trigger) => {
    if (getValues && setValueMethod) {
      const dealToUse = contract && contract.masterDeal && contract.crossed === 'Crossed' ? contract : deal;

      const guarantee = dealToUse.guarantee === 0 ? 1000 : dealToUse.guarantee;
      const splitPercentage = (value.value / guarantee) * 100;
      setValueMethod(`splits.${index}.percentage`, splitPercentage.toFixed(2));

      if (trigger) {
        // TODO: possibly only pass the amount and percentange ids to tigger to avoid
        // rendering other fields unnecessarily
        trigger();
      }
    }
  };

  if ((contract && contract.guarantee === 0) || (!contract && deal.guarantee === 0))
    return <p>There are no financials to split on this deal (yet)</p>;

  return (
    <>
      {masterDeal && <MasterDealWarning crossed={true} fields={['All Split Deal fields']} />}
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className="form-row full-width"
        defaultValues={defaultValues}
        disabled={!isOpen}
        formName={FORM_NAMES.SPLIT_DEAL}
      >
        <FieldArray
          gridClassName={GRID_LAYOUT}
          groupName="splits"
          addButtonLabel="Add Contract Split"
          emptyRow={SPLIT_DEAL_EMPTY_ITEM}
          disabled={!isOpen}
          rules={{
            validate: (value: any) => {
              return value?.length !== 1 || ERROR_MESSAGES.MINIMUM_CONTRACT_SPLIT;
            },
          }}
          highlightOnError={false}
          hideFields={hiddenFields}
          lockRules={(_, index) => {
            if (index === 0) return { isLocked: true, fields: ['mustSplitType'] };
            else return { isLocked: false, fields: [] };
          }}
          updateRowCount={updateRowCount}
          limit={5}
        >
          <Currency
            id="amount"
            label="Amount"
            overrideCurrency={artistCurrency?.id}
            containerClassName="!flex-col !items-start"
            handleChange={handleChangeAmount}
            rules={{
              // TODO: This won't be needed when we merge the Currency's `rules` and `rulesCallback`
              required: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL,
            }}
            rulesCallback={() => ({
              required: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL,
              min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
              validate: (_, values) => {
                const totalAmount = values.splits.reduce(
                  (acc: number, curr: iOrganismSplitDealItem) => acc + Number(curr.amount.value),
                  0
                );
                const dealToUse = contract && contract.masterDeal && contract.crossed === 'Crossed' ? contract : deal;
                return totalAmount === dealToUse.guarantee || ERROR_MESSAGES.SPLIT_DEAL_AMOUNTS;
              },
            })}
            dataCy={SPLIT_DEAL_SECTION.AMOUNT_INPUT}
          />

          <Input
            placeholder="Split %"
            label="Split %"
            fieldType="percent"
            containerClassName="!flex-col !items-start"
            id="percentage"
            step="0.1"
            handleChange={handleChangeSplit}
            disabled={!isOpen}
            rules={{
              // TODO: This won't be needed when we merge the Input's `rules` and `rulesCallback`
              required: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL,
            }}
            rulesCallback={() => ({
              min: { value: 1, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
              max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
              validate: (_, values) => {
                const totalPercentage = values.splits.reduce(
                  (acc: number, curr: iOrganismSplitDealItem) => acc + Number(curr.percentage),
                  0
                );

                return totalPercentage === 100 || ERROR_MESSAGES.SPLIT_DEAL_AMOUNTS;
              },
            })}
            dataCy={SPLIT_DEAL_SECTION.SPLIT_PERCENT}
          />
          {/* disabled input if array field array is at 0 show disabled Client (Artist) */}
          <Dropdown
            placeholder="Client (Artist)"
            label="Contract Split Type"
            id="mustSplitType"
            containerClassName="!flex-col !items-start"
            options={SPLIT_DEAL_OPTIONS_SPLIT_TYPE}
            rules={{ required: ERROR_MESSAGES.CONTRACT_SPLIT_TYPE }}
            disabled={true}
            rulesCallback={(_, values) => {
              return { disabled: values && values > 0 ? true : false };
            }}
            dataCy={SPLIT_DEAL_SECTION.CONTRACT_SPLIT_TYPE}
          />
          {/* only displayed when the after first split */}
          <Dropdown
            placeholder="Contract Split Type"
            label="Contract Split Type"
            id="splitType"
            containerClassName="!flex-col !items-start"
            options={SPLIT_DEAL_OPTIONS_SPLIT_TYPE}
            rules={{ required: ERROR_MESSAGES.CONTRACT_SPLIT_TYPE }}
            dataCy={SPLIT_DEAL_SECTION.CONTRACT_SPLIT_TYPE}
          />

          <Dropdown
            placeholder="Client Business Name"
            label="Client Business Name"
            id="clientBusinessName.name"
            handleChange={handleChangeBusiness}
            containerClassName="!flex-col !items-start"
            options={businessNames}
            rules={{ required: ERROR_MESSAGES.CLIENT_BUSINESS_NAME }}
            dataCy={SPLIT_DEAL_SECTION.CLIENT_BUSINESS_NAME}
          />

          <Dropdown
            id="contractSignerBlock"
            placeholder="Contract Signer Block"
            label="Contract Signer Block"
            containerClassName="!flex-col !items-start"
            options={signerBlocks}
          />

          <TextArea
            placeholder="Add your Deal Display copy here"
            label="Deal Display"
            rules={{
              maxLength: {
                value: 1024,
                message: getMaxCharMessage('the Deal Display', 1024),
              },
            }}
            id="dealDisplay"
            rows={2}
            disabled={!isOpen}
            containerClassName="col-span-full !flex-col !items-start"
            dataCy={SPLIT_DEAL_SECTION.DEAL_DISPLAY}
          />

          <TextArea
            placeholder="Add your Balance remittance copy here"
            label="Balance Remittance"
            rules={{
              maxLength: {
                value: 1024,
                message: getMaxCharMessage('the Balance Remittance', 1024),
              },
            }}
            id="balanceRemittance"
            rows={2}
            disabled={!isOpen}
            containerClassName="col-span-full !flex-col !items-start"
            dataCy={SPLIT_DEAL_SECTION.BALANCE_REMITTANCE}
          />
        </FieldArray>
      </Form>
    </>
  );
};

export default SplitDealsForm;
