import React, { useState, useRef, useContext } from 'react';
import { useClickAway } from 'react-use';
import { OverviewContext } from '../context/overviewContext';
import { iOverviewContextValues } from 'types/deal';

function useOverviewMenuHook() {
  const [overviewMenuOpen, setOverviewMenuOpen] = useState(false);
  const { setOverviewSelectedOrganism } = useContext<iOverviewContextValues>(OverviewContext);

  const overviewRef = useRef(null);

  useClickAway(overviewRef, () => {
    setOverviewMenuOpen(false);
  });

  const onLinkClick = async (hash: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const element = document.querySelector(hash) as HTMLElement;
    const container = document.querySelector('.sticky-container') as HTMLElement;

    if (element && container) {
      const scrollPosition = element.offsetTop - window.innerHeight * 0.1;
      container.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }

    if (setOverviewSelectedOrganism) {
      setOverviewSelectedOrganism(hash.split('#')[1]);
      setOverviewMenuOpen(false);
    }
  };
  return { overviewMenuOpen, setOverviewMenuOpen, overviewRef, onLinkClick };
}

export { useOverviewMenuHook };
