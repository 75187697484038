import { LabelHTMLAttributes } from 'react';

interface ILabel extends LabelHTMLAttributes<HTMLLabelElement> {
  label: string;
  containerClassName?: string;
  className?: string;
  isRequired?: boolean;
}

/*
 * Used for the common form label when in a FieldArray
 */
const RepeaterLabel = ({ label, containerClassName, className, isRequired, ...props }: ILabel) => (
  <div className={containerClassName}>
    <label className={className} {...props}>
      {label} {isRequired ? <span className="text-cinnabar">*</span> : null}
    </label>
  </div>
);

RepeaterLabel.defaultProps = {
  isRequired: false,
};

export default RepeaterLabel;
