import { useEffect, useRef } from 'react';

const useDebouncedCallback = <A extends any[], T>(callback: (...args: A) => Promise<T>, wait: number) => {
  // track args & timeout handle between calls
  const argsRef = useRef<A>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const cleanup = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  // make sure our timeout gets cleared if
  // our consuming component gets unmounted
  useEffect(() => cleanup, []);

  return async (...args: A) =>
    await new Promise<T>((resolve) => {
      // capture latest args
      argsRef.current = args;

      // clear debounce timer
      cleanup();

      // start waiting again
      timeout.current = setTimeout(() => {
        if (argsRef.current) {
          resolve(callback(...argsRef.current));
        }
      }, wait);
    });
};

export default useDebouncedCallback;
