import { BaseSyntheticEvent, ChangeEvent, InputHTMLAttributes, useEffect, useState } from 'react';
import {
  FieldValues,
  FormState,
  RegisterOptions,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext,
} from 'react-hook-form';

import { findNestedErrorById } from '../../helpers/formHelpers';
import { formatPercentNumber } from 'utils/helpers';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type TFieldType = 'text' | 'percent' | 'number' | 'date' | 'time';

export type THandleInputChange = (
  value: string,
  index?: number,
  setValueMethod?: UseFormSetValue<FieldValues>,
  getValues?: UseFormGetValues<FieldValues>,
  trigger?: UseFormTrigger<FieldValues>,
  parentindex?: number
) => void;

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  fieldType?: TFieldType;
  label?: string | boolean;
  className?: string;
  rules?: RegisterOptions;
  containerClassName?: string;
  suffix?: string;
  index?: number;
  parentindex?: number;
  disableIf?: {
    field: string;
    value: string | number | boolean;
  };
  disabled?: boolean;
  handleChange?: THandleInputChange;
  rulesCallback?: (formState: FormState<FieldValues>, index?: number) => RegisterOptions<FieldValues, string>;
  roundNumber?: boolean;
  dataCy?: string;
}

export const Input = ({
  id,
  className = '',
  label = false,
  rules = {},
  fieldType = 'text',
  containerClassName = '',
  suffix,
  index,
  parentindex,
  handleChange,
  rulesCallback,
  disabled = false,
  disableIf,
  roundNumber = true,
  dataCy,
  ...props
}: IInputProps) => {
  const [defaultValue, setDefaultValue] = useState<string | number>('');

  const {
    setValue,
    register,
    getValues,
    formState,
    formState: { errors },
    trigger,
    clearErrors,
  } = useFormContext();

  const hasErrors = errors && !!findNestedErrorById(id, errors);
  const isRequired = Object.keys(rules).includes('required');

  // Clear errors when component unmounts
  useEffect(() => () => clearErrors(id), []);

  useEffect(() => {
    //if value exisits on initial load, set as value
    const dv = getValues(id);
    if (dv) {
      setDefaultValue(dv);
    }
  }, []);

  const formType = fieldType === 'percent' ? 'number' : fieldType;

  const disable =
    disabled ||
    (disableIf &&
      getValues(disableIf.field.replace('{index}', `${index}`).replace('{parentindex}', `${parentindex}`)) ===
        disableIf.value);

  return (
    <div className={twMerge(clsx('form-field-wrapper', containerClassName))}>
      {label !== false && (
        <label className={`standard-label ${containerClassName.includes('flex-col') ? '!self-start' : ''}`}>
          {label} {isRequired ? <span className="text-cinnabar">*</span> : null}
        </label>
      )}
      <div className="relative w-full ">
        <input
          defaultValue={defaultValue}
          {...register(id, {
            // TODO: refactor to combine rulesCallback and rules
            ...(rulesCallback ? { ...rulesCallback(formState, index) } : rules),
            onBlur: (e: BaseSyntheticEvent<HTMLInputElement>) => {
              if (fieldType === 'percent') {
                e.target.value = formatPercentNumber(e.target.value).toString();
              }
              if (fieldType === 'number') {
                // Avoid empty fields
                if (roundNumber) {
                  e.target.value = Number(e.target.value).toFixed();
                } else {
                  e.target.value = Number(e.target.value);
                }
              }
            },
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              if (fieldType === 'number') {
                // Prevents `-` and `+` characters added to the end of a number
                e.target.value = e.target.value.toString();
              }
              if (fieldType === 'percent') {
                if (!Number.isNaN(e?.target?.value)) {
                  if (+e.target.value > 100) e.target.value = '100';
                  if (+e.target.value < 0) e.target.value = '0';
                }
              }
              if (handleChange) handleChange(e.target.value, index, setValue, getValues, trigger, parentindex);
            },
          })}
          className={`h-10 border border-solid border-black px-3 disabled:opacity-50 py-1  text-sm appearance-none peer w-full ${className} ${
            hasErrors ? 'border-cinnabar' : ''
          }`}
          {...props}
          id={id}
          type={formType}
          disabled={disable}
          placeholder={props.placeholder || ' '}
          aria-describedby={`${id}_error_help`}
          data-cy={dataCy}
        />

        {fieldType === 'percent' && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">%</div>
        )}

        {suffix && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">{suffix}</div>
        )}

        {/* TODO: do we need inline errors? */}
        {/* {hasErrors && (
          <p id={`${id}_error_help`} className="mt-1 mb-2 text-xs text-cinnabar">
            <>
              <span className="font-medium">Error!</span> {errors?.[id]?.type}
            </>
          </p>
        )} */}
      </div>
    </div>
  );
};
