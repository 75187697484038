import { ColumnState } from 'ag-grid-community';

import { get, patch } from './index';

type V3SearchOptions = {
  search?: string;
  desk?: string;
  skip?: number;
  limit?: number | null;
  sort?: string;
  showAll?: boolean;
  showFormer?: boolean;
};
export type RoutingClient = {
  _id: string;
  name: string;
  profile_pic?: string;
  confirmedUpcomingShows?: Record<string, unknown>[];
};

/**
 * Makes an API call to the GET /clients/routing resource
 * @param v3SearchOptions: search options supported by v3
 * @param _get: dependency injected api method
 **/
export const getClientsRoutingInfo = (
  {
    search = '',
    desk = '',
    sort = 'name ASC',
    skip = 0,
    limit = 5,
    showAll = false,
    showFormer = false,
  }: V3SearchOptions = {},
  _get = get
): Promise<{ count: number; data: RoutingClient[] }> => {
  const queryParams = showAll ? [`sendAll=true`] : [];
  if (showFormer) {
    queryParams.push(`showFormer=true`);
  }
  //v3 specific params
  if (search) {
    queryParams.push(`search=${search}`);
  }
  if (desk && !showAll) {
    queryParams.push(`desk=${desk}`);
  }
  if (sort) {
    queryParams.push(`sort=${sort}`);
  }
  if (skip || skip === 0) {
    queryParams.push(`skip=${skip}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  return _get(`/v0_touring/clients/routing?${queryParams.join('&')}`).then((response) => {
    const { errors, data, count } = (response || {}).body || {};
    if (errors) {
      //errors is an array, but v3 only returns a single error in the array
      console.error('Handled API Error from /clients/routing', errors);
      return Promise.reject(errors[0]);
    }
    return { data: data, count: count };
  });
};

/**
 * Method for saving the user routing columns state to the user collection
 */
export const saveRoutingTableState = async (state: ColumnState[], id: string): Promise<void> => {
  return await patch(`/touring/routing/user-preferences/${id}`, {
    preferences: { routingColumnState: state },
  }).then((response) => response.body.preferences?.routingColumnState);
};

/**
 * Method for fetching client information
 */
export const getClientData = async (id: string): Promise<RoutingClient> => {
  return await get(`/person/${id}`).then((response) => {
    return response.body;
  });
};
