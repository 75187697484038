import clsx, { ClassValue } from 'clsx';
import { FC, HTMLAttributes } from 'react';
import type { Components } from 'react-markdown';
import ReactMarkdown from 'react-markdown';
import { twMerge } from 'tailwind-merge';

const MARKDOWN_COMPONENTS: Components = {
  p: 'span',
};

export type FilterPreviewData = {
  label: string;
  value: string;
};

export type FiltersPreviewProps = {
  data: FilterPreviewData[];
  className?: ClassValue;
} & Omit<HTMLAttributes<HTMLDivElement>, 'className'>;

const FiltersPreview: FC<FiltersPreviewProps> = ({ data, className = [], ...props }) => {
  const filteredData = data?.filter(
    ({ label, value }) => ![null, undefined, ''].includes(value as any) && label?.length
  );

  return filteredData?.length ? (
    <div className={twMerge(clsx('px-4 lg:px-5 print:px-0 print:lg:px-0', className))} {...props}>
      {filteredData?.map(({ label, value }, index) => {
        const lastItem = index + 1 === filteredData?.length || filteredData[index + 1]?.label === 'Guarantee';

        const valueInBold = `**${value}**`;
        const textWithoutUnusedBold = valueInBold.replace(/(^\*{4,}|\*{4,}$)/g, '');

        return (
          <span key={label} className="text-sm leading-6">
            {label === 'Guarantee' && <br />}
            <span>{`${label}: `}</span>
            <ReactMarkdown components={MARKDOWN_COMPONENTS}>{textWithoutUnusedBold}</ReactMarkdown>
            {label === 'Client' ? (
              <br />
            ) : (
              !lastItem && label !== 'Guarantee' && <span className="text-[#b4b4b4]">{` | `}</span>
            )}
          </span>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

export default FiltersPreview;
