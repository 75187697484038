import { FC } from 'react';
import { TimelineAgendaData } from '../../TimelineAgenda/types';
import Header from '.';

export interface TimelineAgendaTableHeaderFullProps {
  data: TimelineAgendaData;
}

const Full: FC<TimelineAgendaTableHeaderFullProps> = ({ data }) => (
  <Header.THead>
    <Header.Row.Full data={data} />
  </Header.THead>
);

export default Full;
