import { FC } from 'react';
import { formatDate } from 'deal-form/helpers/dealHelpers';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

export type EventDateDetailsProps = {
  eventDate: string;
  eventEndDate: string;
  showOnMobile: boolean;
};

export const EventDateDetails: FC<EventDateDetailsProps> = ({ eventDate, eventEndDate, showOnMobile }) => (
  <div className={twMerge(clsx('px-2 text-sm md:text-lg', { 'hidden md:flex': !showOnMobile }))}>
    •{' '}
    {eventEndDate && eventEndDate !== eventDate
      ? `${formatDate(eventDate)} - ${formatDate(eventEndDate)}`
      : formatDate(eventDate)}
  </div>
);
