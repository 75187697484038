import { FC, ReactElement, useCallback, useState } from 'react';
import { ArrowBackward, Close, Search } from '@united-talent-agency/onyx-components/Icons';
import {
  Button,
  Checkbox,
  Divider,
  InputText,
  RadioButton,
  SelectOptionT,
} from '@united-talent-agency/onyx-components';

interface MobileSelectOptionT extends SelectOptionT {
  checked?: boolean;
}

interface MobileFocusedViewProps {
  title: string;
  isMulti?: boolean;
  clearButtonLabel?: string;
  applyButtonLabel?: string;
  data?: MobileSelectOptionT[] | SelectOptionT[];
  onChange?: (data: MobileSelectOptionT[]) => void;
  onLeaveFocusView: () => void;
}

const MobileFocusedView: FC<MobileFocusedViewProps> = ({
  title,
  isMulti,
  clearButtonLabel = 'Clear',
  applyButtonLabel = 'Apply',
  data,
  onLeaveFocusView,
  onChange,
}): ReactElement => {
  // States
  const [searchInput, setSearchInput] = useState<string>('');

  const handleOnClickClearButton = useCallback(() => {
    if (data?.length) {
      onChange && onChange(data?.map((item) => ({ ...item, checked: false })));
      onLeaveFocusView && onLeaveFocusView();
    }
  }, [data, onChange, onLeaveFocusView]);

  const handleOptionChange = (option: MobileSelectOptionT) => {
    onChange &&
      onChange(
        (data as MobileSelectOptionT[])?.map((item) => {
          const isClickedOption = item?.value === option?.value;
          let checked = !!item?.checked;

          if (isMulti) {
            if (isClickedOption) {
              checked = !option?.checked;
            }
          } else {
            checked = isClickedOption;
          }

          return {
            ...item,
            checked,
          };
        })
      );
  };

  return (
    <>
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-white" />
      <div className="flex h-screen pt-40 flex-col flex-1 min-h-[calc(100vh-5rem)] lg:hidden bg-white z-10">
        <div className="flex flex-row items-center justify-between px-4 min-h-[50px] h-[50px] border-b border-b-[#DFDFDB]">
          <ArrowBackward onClick={onLeaveFocusView} />
          <h3 className="font-base font-bold">{title}</h3>
          <Close onClick={onLeaveFocusView} />
        </div>
        <div className="flex flex-col flex-1 max-h-full w-full py-[10px] gap-3 overflow-hidden">
          <div className="flex w-full px-4">
            <InputText
              leftIcon={<Search className="fill-gray-500" width={18} height={18} />}
              className="w-full"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onClear={() => setSearchInput('')}
            />
          </div>
          <div className="flex flex-col max-h-[calc(100vh-270px)] px-4 overflow-y-scroll divide-y divide-[#DFDFDB]">
            {(data as MobileSelectOptionT[])
              ?.filter(({ label }) => (label ?? '').toLowerCase().includes(searchInput.toLowerCase()))
              .map((option) => (
                <div
                  key={option.label}
                  className="flex flex-row justify-between items-center px-1 py-4"
                  onClick={() => handleOptionChange(option)}
                >
                  <p>{option.label}</p>
                  {isMulti ? (
                    <Checkbox size="lg" checked={option?.checked} />
                  ) : (
                    <RadioButton size="lg" checked={option?.checked} />
                  )}
                </div>
              ))}
          </div>
        </div>
        <Divider />
        <div className="px-4 py-[10px] flex justify-center gap-5 items-center bg-white">
          <Button onClick={handleOnClickClearButton} className="w-full flex-1 flex">
            {clearButtonLabel}
          </Button>
          <Button variant="solid" onClick={onLeaveFocusView} className="w-full flex-1 flex">
            {applyButtonLabel}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MobileFocusedView;
