import Full from './Full';
import Root from './Root';
import Content from './Content';

const Row = {
  Full,
  Root,
  Content,
};

export default Row;
