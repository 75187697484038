import React from 'react';

interface TextInputProps {
  defaultValue?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const TextInput: React.FunctionComponent<TextInputProps> = ({ defaultValue, placeholder, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //TODO formatting checks
    onChange(event.target.value);
  };

  return (
    <input
      className="border border-solid border-greyTapa px-4 py-2 rounded text-sm w-full"
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={handleChange}
      autoComplete="off"
    />
  );
};
