import { FC } from 'react';
import Table from '../..';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { SHOWS_AGENDA_COLUMNS } from 'containers/availability/constants';
import { TableHeaderCellProps } from '../Cell';
import { SortDown, SortUp } from '@united-talent-agency/onyx-components/Icons';
import { useFilters } from 'context/avails-filters/context';
import { SortableColums } from 'context/avails-filters/hook';

export type ShowsAgendaTableHeaderRowProps = TableHeaderCellProps & {
  handleSort: (col: SortableColums) => void;
};

export const ShowsAgendaHeaderContent: FC<ShowsAgendaTableHeaderRowProps> = ({ className, ...props }) => {
  const { sortedBy } = useFilters();

  const SortIcon = ({ col, handleSort }: { col: string; handleSort: (col: string) => void }) => {
    return (
      <div onClick={() => handleSort(col)}>
        {sortedBy.column === col ? (
          sortedBy.order === 'desc' ? (
            <SortDown className="w-4 h-4" />
          ) : (
            <SortUp className="w-4 h-4" />
          )
        ) : (
          <SortDown className="w-4 h-4" />
        )}
      </div>
    );
  };

  return (
    <>
      {SHOWS_AGENDA_COLUMNS?.map((col) => {
        return (
          <Table.Header.Cell
            key={`head-cell-${col}`}
            className={twMerge(
              clsx(
                `items-center print:text-uta-grey h-[48px] print:h-[32px] max-h-full max-w-full min-w-[120px] print:min-w-[88px] print:md:min-w-[88px]
                ${col === 'Dates' ? 'md:min-w-[120px]' : 'md:min-w-[200px]'}
                `,
                className
              )
            )}
            {...props}
          >
            <div className="flex items-center px-5 gap-1 print:gap-0">
              <span className="font-bold text-sm print:text-xs leading-none print:leading-3 text-[#6B6A69]">{col}</span>
              <SortIcon col={col} handleSort={(col_) => props.handleSort(col_ as SortableColums)} />
            </div>
          </Table.Header.Cell>
        );
      })}
    </>
  );
};
