import { SelectOptionT } from '@united-talent-agency/onyx-components';
import axios, { AxiosError, AxiosResponse } from 'axios';
const locationiqApiKey = process.env.REACT_APP_LOCATIONIQ_API_KEY ?? '';

export interface LocationIQAddress {
  city: string;
  town: string;
  name: string;
  county: string;
  municipality: string;
  region: string;
  state: string;
  country: string;
  country_code: string;
}

export interface LocationIQMatchQuality {
  matchcode: string;
  matchtype: string;
  matchlevel: string;
}

export interface LocationIQResponse {
  place_id: string;
  licence: string;
  osm_type: string;
  osm_id: string;
  boundingbox: [string, string, string, string];
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
  icon: string;
  address: LocationIQAddress;
  matchQuality: LocationIQMatchQuality;
}

/**
 * Fetches data for an address with Locationiq API
 * @param {string} searchText - human readable address string
 * @returns {Promise.<{data:[Object], failure:boolean, message:string}>} -
 * - data is an array of query result objects
 * - message is returned when error occurs
 * - failure represents if validation failed or not
 */
const dataFetcher = async (searchText?: string) => {
  if ([null, undefined].includes(searchText as undefined | null) || !searchText?.length) {
    return {
      failure: true,
      result: { geoLocation: null, address: null },
      message: 'undefined or null query address.',
    };
  }

  let response: AxiosResponse<LocationIQResponse[]>;

  try {
    response = await axios.get<LocationIQResponse[]>('https://us1.locationiq.com/v1/autocomplete', {
      params: {
        q: searchText ?? '',
        key: locationiqApiKey,
        format: 'json',
        addressdetails: 1,
        tag: 'place:city,place:town,place:state,place:country',
        dedupe: 1,
        'accept-language': 'native',
      },
    });
  } catch (error) {
    return {
      failure: true,
      result: { geoLocation: null, address: null },
      message: (error as AxiosError)?.message,
    };
  }

  const data = response?.data;

  if (!response?.data?.length) {
    return {
      failure: true,
      result: { geoLocation: null, address: null },
      message: 'undefined or null response data from Locationiq API',
    };
  }

  return { data };
};

export const getOptions = async (text: string) => {
  try {
    const { data } = await dataFetcher(text);

    // Must be ordered by priority
    const acceptedPlaceNames = ['city', 'town', 'name', 'county'];

    const getFirstMatch = (address: LocationIQAddress): string | undefined =>
      Object.entries(address)?.find(([key]) => acceptedPlaceNames?.find((name) => name === key))?.[1];

    const filteredResults = data?.filter(
      ({ address }) => !!getFirstMatch(address) && address?.state && address?.country
    );

    const response = filteredResults?.map<SelectOptionT>(({ address, lat: latitude, lon: longitude }) => {
      const addressLabelInfos = [getFirstMatch(address), address?.state, address?.country];
      const label = addressLabelInfos.filter((info) => !!info).join(', ');

      const value = JSON.stringify({ latitude, longitude });

      return { label, value };
    });

    return response ?? [];
  } catch (error) {
    return [];
  }
};

export default dataFetcher;
