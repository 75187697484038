import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';

const CLOUD_NAME = 'uta';

// Creates a Cloudinary instance and sets the Cloud configuration parameter.
const cld = new Cloudinary({
  cloud: {
    cloudName: CLOUD_NAME,
  },
});

/**
 * Returns true if URL's hostname is cloudinary.com
 *
 * @param {string} imageURL - image url
 * @returns {boolean} whether the url is from cloudinary.com
 */
// we need to add cdn.unitedtalent.com to the list of allowed domains
export const isCloudinaryImage = (imageURL: string) => {
  const url = new URL(imageURL);

  return url.hostname.includes('cloudinary.com') || url.hostname.includes('dca.unitedtalent.com');
};

/**
 * Returns true if valid URL's
 *
 * @param {string} imageURL - image url
 * @returns {boolean} whether the url is valid or not
 */

export const isValidUrl = (imageURL: string) => {
  // Regular expression for a valid URL
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlPattern.test(imageURL);
};

/**
 * Extracts the cloudinary public id from a URL
 *
 * @param {string} imageURL - image url
 * @returns {string} public id
 */
export const getCloudinaryIdFromUrl = (imageURL: string): string => {
  const imageURLParts = imageURL?.split('/');

  return imageURLParts[imageURLParts.length - 1]?.split('.')[0];
};

/**
 * Returns a cloudinary image object from a cloudinary URL
 *
 * @param {string} imageURL - image url
 * @returns {object} CloudinaryImage object
 */
export const getCloudinaryImgFromUrl = (imageURL: string) => {
  const id = getCloudinaryIdFromUrl(imageURL);

  return cld.image(id);
};

/**
 * Returns the URL for a transformed image if the input is a cloudinary URL,
 * if not it returns the original url.
 *
 * @param {string} imageURL - image url
 * @returns {string} image url
 */
export const getArtistThumbUrl = async (imageUrl: string, width: number, height: number) => {
  if (!imageUrl || !isValidUrl(imageUrl)) {
    return '';
  }
  const processedUrl =
    imageUrl && isCloudinaryImage(imageUrl)
      ? getCloudinaryImgFromUrl(imageUrl)
          .resize(fill().width(width).height(height).aspectRatio('1:1').gravity(autoGravity()))
          .toURL()
      : imageUrl;

  const is404 = await checkUrlStatus(processedUrl);

  if (is404) {
    return imageUrl;
  } else {
    return processedUrl;
  }
};

async function checkUrlStatus(url: string) {
  try {
    const response = await fetch(url);

    if (response.status === 404) {
      // URL returns a 404 status code
      console.log('URL returns a 404 status code.');
      return true;
    } else {
      // URL does not return a 404 status code
      console.log('URL does not return a 404 status code.');
      return false;
    }
  } catch (error) {
    // An error occurred while trying to fetch the URL
    console.error('An error occurred:', error);
    return false;
  }
}
