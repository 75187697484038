import moment from 'moment';

export enum RANGES {
  THREE_MONTHS = 'three_months',
  PAST_THREE_MONTHS = 'past_three_months',
  COMING_THREE_MONTHS = 'coming_three_months',
}

export const getInitialDateRange = (range: RANGES) => {
  const today = moment();
  let startDate = today.clone();
  let endDate = today.clone();

  switch (range) {
    case RANGES.THREE_MONTHS:
      startDate = startDate.subtract(1, 'month').startOf('month');
      endDate = endDate.add(1, 'month').endOf('month');
      break;
    case RANGES.PAST_THREE_MONTHS:
      startDate = startDate.subtract(3, 'month');
      break;
    case RANGES.COMING_THREE_MONTHS:
      endDate = endDate.add(3, 'month');
      break;

    default:
      break;
  }

  return {
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
  };
};
