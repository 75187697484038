import { createReport } from 'api/grid-pages';
import { Modal, iModalProps } from 'features/common/modal/Modal';
import { FilterModel } from 'grid-pages/interfaces';
import moment from 'moment';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import Button from 'components/Button';
import { IdString } from 'components/dropdown-basic/DropdownBasic';

interface ColDef {
  name: string;
  position: number;
}

export interface NewReportModalProps extends iModalProps {
  end: string;
  start: string;
  testId?: string;
  colDefs: ColDef[];
  filterModel?: FilterModel | null;
  onCreateReport: (reportOption: IdString) => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  onValidate: (name: string, setIsValidMethod: Dispatch<SetStateAction<boolean>>) => void;
}

const NewReportModal: FC<NewReportModalProps> = ({
  end,
  start,
  testId,
  colDefs,
  filterModel = null,
  onValidate,
  setIsModalOpen,
  onCreateReport,
  ...props
}) => {
  // States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reportName, setReportName] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleSubmitReport = async () => {
    if (!isValid) {
      return;
    }

    setIsSubmitting(true);

    const report = {
      name: reportName,
      columns: colDefs,
      // All user saved reports should be private at this time
      global: false,
      ...(filterModel && { filterModel: filterModel }),
      dateRange: {
        start: moment.utc(start),
        end: moment.utc(end),
      },
    };

    try {
      if (testId) {
        if (onCreateReport) {
          onCreateReport({
            id: '1',
            label: 'test',
          });
        }
      } else {
        const newReport = await createReport(report);

        const reportOption = { id: newReport.response._id, label: newReport.response.name };

        if (onCreateReport) {
          onCreateReport(reportOption);
        }
      }

      setIsSubmitting(false);
      setIsModalOpen(false);
    } catch (e) {
      setIsSubmitting(false);
      setIsModalOpen(false);
      console.log(e);
    }
  };

  return (
    <Modal {...props}>
      <div className="mt-1 mb-12">
        <p>You need to add a name in order to save this report</p>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitReport();
        }}
      >
        <div className="mb-6">
          <label htmlFor="startDate" className="standard-label">
            Report Name
          </label>

          <input
            className="peer h-10 w-full appearance-none border border-solid border-black px-2 py-1 text-sm"
            placeholder="Report Name"
            id="reportName"
            value={reportName.trimStart()}
            onChange={(e) => {
              onValidate(e.target.value, setIsValid);
              setReportName(e.target.value);
            }}
            disabled={isSubmitting}
            maxLength={50}
          />
          {!isValid && (
            <span className="text-cinnabar text-xs font-bold mt-3 col-span-full block">
              Error:{' '}
              {reportName.trim() === ''
                ? 'You must give the Custom Report a name in order to save it.'
                : 'A report with that name already exists.'}
            </span>
          )}
        </div>
      </form>

      <div className="mt-10 flex gap-[50px]">
        <Button
          disabled={isSubmitting}
          onClick={() => {
            setIsModalOpen(false);
          }}
          className="flex-1"
          variant="stroke"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmitReport}
          disabled={!reportName || isSubmitting || !isValid}
          variant="secondary"
          className="flex-1"
        >
          Save Report
        </Button>
      </div>
    </Modal>
  );
};

export default NewReportModal;
