import { TimelineAgendaData } from 'components/TimelineAgenda/types';
import { FC } from 'react';
import Table from '.';

type TableFullProps = {
  data: TimelineAgendaData;
};

const Full: FC<TableFullProps> = ({ data }) => (
  <Table.Root>
    <Table.Header.Full data={data} />
    <Table.Body.Full data={data} />
  </Table.Root>
);

export default Full;
