import { ICellRendererParams } from 'ag-grid-community';
import { FC, ReactElement } from 'react';

import { generateSimpleDropdown } from '../dropdown/Dropdown';
import { DropDownCandidate as Candidate } from '../index';

export const TYPE_CANDIDATES: Candidate[] = [
  { value: 'Flat' },
  { value: 'Plus Backend' },
  { value: 'Capped' },
  { value: 'Door Deal' },
  { value: 'Fixed Split' },
  { value: 'Versus Gross' },
  { value: 'Versus Net' },
];

export const TYPE_OPTIONS: {
  label: string;
  value: string;
}[] = [
  { label: 'Flat', value: 'flat' },
  { label: 'Plus', value: 'plusBackend' },
  { label: 'Fixed Split', value: 'fixedSplit' },
  { label: 'Versus', value: 'versus' },
];

/**
 * Deal Type renderer.
 */
export const DealTypeRenderer: FC<ICellRendererParams> = (props: ICellRendererParams): ReactElement => {
  const { data: row } = props;
  // get the value
  const value = props.value ? props.value : row?.dealType;
  // check to see f there is a 'translation' for the value
  const useableValue = TYPE_OPTIONS.find((el) => el.value === value)
    ? TYPE_OPTIONS.find((el) => el.value === value)?.label
    : value.toProperCase();

  return (
    <div className="center-content" data-row-id={props.data.id}>
      {useableValue ? `${useableValue}` : <></>}{' '}
    </div>
  );
};

/**
 * Deal Type editor.
 */
export const DealTypeEditor = generateSimpleDropdown('DealTypeEditor', TYPE_CANDIDATES);
