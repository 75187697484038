import { StickyHeaderPage } from 'features/layouts';
import { PageHeader } from 'grid-pages/components';
import { PRESETS, PresetReport } from 'grid-pages/pages/PresetReport';
import { RANGES } from 'grid-pages/utils/date-utils';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import { ReactElement, useCallback, useState } from 'react';

/**
 * Accounting Queue Editor
 */
export const Contracts = (): ReactElement => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();

  // States
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const handleOnExport = () => {
    setIsExporting(true);
  };

  const renderFixedContainer = useCallback(
    () => <PageHeader onExport={handleOnExport} title="Contract Tracking" />,
    []
  );

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="main-wrapper">
        <div
          className="grid-height content-wrapper md:pt-10"
          onMouseOver={lockOverscrollNavigation}
          onMouseLeave={unlockOverscrollNavigation}
        >
          <PresetReport
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            preset={PRESETS.CONTRACTING}
            datePreset={RANGES.COMING_THREE_MONTHS}
          />
        </div>
      </div>
    </StickyHeaderPage>
  );
};
