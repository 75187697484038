import { toast } from 'react-toastify';
import cypressTags from 'support/cypressTags';
import { classes } from 'support/react-toastify';

import ToastWrapper from 'components/toast/ToastWrapper';

import { apiServerUrl } from '../urls';

export { getContractByDealId, getContractById, updateContract } from '././contracts';
export { findCompanies } from './company';
export { createDeal, getDealById, getDealClauses, updateDeal } from './deals';
export { findEmployee, findPeople } from './person';
export { updateDealShows, type UpdateShowsRequest } from './shows';
export { findVenues } from './venue';

// Subset of the fetch Response, it automatically calls .json() and puts it in the body
interface BaseResponse {
  url: string;
  status: number;
  headers: Headers;
  ok: boolean;
}

// Standard response, body is up to the API
export interface RequestResponse extends BaseResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
}

/**
 * Creates Request Headers for making an Authenticated Request to the API
 * @param [contentType] - can override for different request body types, pdf, form, etc
 */
export const getHeaders = (contentType = 'application/json') => {
  const token = localStorage.getItem('accessToken');
  const headers = new Headers();
  const bearer = `JWT ${token}`;
  headers.append('Authorization', bearer);
  headers.append('Accept', contentType);
  headers.append('Content-Type', contentType);
  return headers;
};

interface ApiResponse {
  json: () => unknown;
  url: string;
  status: number;
  headers: Headers;
  ok: boolean;
}

/**
 * Creates common format for response objects
 * @param res - response object
 */
const formatResponse = async (res: ApiResponse) => {
  return {
    body: await res.json(),
    url: res.url,
    status: res.status,
    headers: res.headers,
    ok: res.ok,
  };
};

/**
 * Currently the best way of dealing with an expired token is to just remove
 * the token and let the application re-authenticate
 *
 * @param res
 */
const handleUnAuthorizedRequest = (res: ApiResponse) => {
  if (res.status === 401) {
    localStorage.removeItem('accessToken');
  }
  return res;
};

export const get = async (path: string): Promise<RequestResponse> =>
  fetch(`${apiServerUrl}${path}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(handleUnAuthorizedRequest)
    .then((res) => formatResponse(res));

export const post = async (path: string, payload: unknown): Promise<RequestResponse> =>
  fetch(`${apiServerUrl}${path}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  })
    .then(handleUnAuthorizedRequest)
    .then((res) => formatResponse(res));

export const patch = (path: string, payload: unknown): Promise<RequestResponse> =>
  fetch(`${apiServerUrl}${path}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  })
    .then(handleUnAuthorizedRequest)
    .then((res) => formatResponse(res));

export const remove = (path: string): Promise<RequestResponse> =>
  fetch(`${apiServerUrl}${path}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
    .then(handleUnAuthorizedRequest)
    .then((res) => formatResponse(res));

export const showUserError = (err: RequestResponse) => {
  let errorMessage = err.toString() || '';
  if (err.body) {
    errorMessage = err.body?.message;
  }
  toast(ToastWrapper(errorMessage, cypressTags.TOURING_NOTIFICATION.ERROR), {
    className: classes.errorClass,
    autoClose: false,
  });
};
