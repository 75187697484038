import { EntityDesignateType, EntityType } from './types/data-schemas';

// eslint-disable-next-line no-undef
export const defaultUrl = process.env.REACT_APP_TOURING_URL;
// eslint-disable-next-line no-undef
export const apiServerUrl = process.env.REACT_APP_API_SERVER;

export const industryContactPersonProfileUrl = '/contacts/industry-contact/person';
// export const clientPersonProfileUrl = '/contacts/client/person';
export const companyProfileUrl = `/contacts/company`;
export const venueProfileUrl = `/contacts/venue`;
export const employeeProfileUrl = `/contacts/employee`;

// eslint-disable-next-line no-undef
export const externalPersonProfileUrl = `${process.env.REACT_APP_PROFILE_URL}/profile`;

const profileUrlsByEntityDesignateType = {
  [EntityDesignateType.Company]: companyProfileUrl,
  [EntityDesignateType.Person]: externalPersonProfileUrl,
  [EntityDesignateType.Venue]: venueProfileUrl,
};

export const contactUrlForId = (id: string) => {
  // let subdomain = 'profile';
  // if (process.env.NODE_ENV !== 'production' || window.location.href.includes('dev')) {
  //   subdomain += '.dev';
  // }
  return `${process.env.REACT_APP_PROFILE_URL}/profile/${id}`;
};

export const promoterUrlForId = (id: string) => {
  // let subdomain = 'profile';
  // if (process.env.NODE_ENV !== 'production' || window.location.href.includes('dev')) {
  //   subdomain += '.dev';
  // }
  return `${process.env.REACT_APP_PROFILE_URL}/company/${id}`;
};

export const venueUrlForId = (id: string) => {
  return `${process.env.REACT_APP_PROFILE_URL}/venue/${id}`;
};

export const getEntityProfileUrl = (
  entityDesignateType: EntityDesignateType,
  id: string,
  type?: EntityType
): string => {
  if (entityDesignateType === EntityDesignateType.Person && type === EntityType['Industry Contact']) {
    return `${industryContactPersonProfileUrl}/${id}`;
  }
  if (entityDesignateType === EntityDesignateType.Person && type === EntityType.Client) {
    return contactUrlForId(id);
  }
  if (entityDesignateType === EntityDesignateType.Person && type === EntityType.Employee) {
    return `${employeeProfileUrl}/${id}`;
  }

  return `${profileUrlsByEntityDesignateType[entityDesignateType]}/${id}`;
};
