import { ProcessCellForExportParams } from 'ag-grid-community';
import moment from 'moment';

import { getDateFormatByBrowser } from 'utils/helpers';
import { Dictionary } from '../../types/dictionary';

export type CellSerializer = (cell: any, row: any) => string; // eslint-disable-line

/**
 * Serialize a cell data into string for csv export.
 *
 * @param {ProcessCellForExportParams} params
 * @returns {string}
 */
export const serializeCellData = (params: ProcessCellForExportParams): string => {
  const serializers: Dictionary<CellSerializer> = {
    date: serializeDate,
    venue: serializeVenue,
    city: serializeCity,
    state: serializeState,
    country: serializeCountry,
    'rule.radius': serializeRadiusClause,
    'rule.timeBefore': serializeDaysPrior,
    'rule.timeAfter': serializeDaysAfter,
    hold: serializeField,
    holdNumber: serializeField,
    guarantee: serializeField,
    walkoutPotential: serializeField,
    notes: serializeField,
    sellableCapacity: serializeField,
    hardMerch: serializeField,
    softMerch: serializeField,
    tix: serializeField,
    ages: serializeField,
    status: serializeField,
    dealType: serializeField,
  };
  const serializer: CellSerializer = serializers[params.column.getColId()];

  return serializer ? serializer(params.value, params.node?.data) : '';
};

/**
 * General serialization.
 *
 * @param {any} cell
 * @returns {string}
 */
export const serializeField: CellSerializer = (cell) => {
  return cell && cell.toString ? cell.toString() : '';
};

/**
 * Date serialization.
 *
 * @param {any} cell
 * @returns {string}
 */
export const serializeDate: CellSerializer = (cell) => {
  return moment.utc(cell).format(getDateFormatByBrowser());
};

/**
 * Venue serialization.
 *
 * @param {any} cell
 * @param {any} row
 * @returns {string}
 */
export const serializeVenue: CellSerializer = (cell, row) => {
  return cell?.name ? cell.name : row?.venue?.name || '';
};

/**
 * City serialization.
 *
 * @param {any} _cell
 * @param {any} row
 * @returns {string}
 */
export const serializeCity: CellSerializer = (_cell, row) => {
  return row?.venue?.address?.city || '';
};

/**
 * State serialization.
 *
 * @param {any} _cell
 * @param {any} row
 * @returns {string}
 */
export const serializeState: CellSerializer = (_cell, row) => {
  return row?.venue?.address?.state || '';
};

/**
 * Country serialization.
 *
 * @param {any} _cell
 * @param {any} row
 * @returns {string}
 */
export const serializeCountry: CellSerializer = (_cell, row) => {
  return row?.venue?.address?.country || '';
};

/**
 * Radius Clause serialization.
 *
 * @param {any} _cell
 * @param {any} row
 * @returns {string}
 */
export const serializeRadiusClause: CellSerializer = (_cell, row) => {
  return row?.rule?.radius ? `${row.rule.radius} ${row.rule.radiusUnit === 'miles' ? 'mi' : 'km'}` : '';
};

/**
 * Days Prior serialization.
 *
 * @param {any} _cell
 * @param {any} row
 * @returns {string}
 */
export const serializeDaysPrior: CellSerializer = (_cell, row) => {
  return row?.rule?.timeBefore || '';
};

/**
 * Days After serialization.
 *
 * @param {any} _cell
 * @param {any} row
 * @returns {string}
 */
export const serializeDaysAfter: CellSerializer = (_cell, row) => {
  return row?.rule?.timeAfter || '';
};

/**
 * Currency serialization.
 *
 * @param {any} cell
 * @returns {string}
 */
export const serializeCurrency: CellSerializer = (cell) => {
  return cell.amount ? Number(cell.amount).toFixed(2).toString() : '';
};

/**
 * Buyer serialization.
 *
 * @param {any} cell
 * @returns {string}
 */
export const serializeName: CellSerializer = (cell) => {
  return cell?.name || '';
};

/**
 * Deal Type serialization.
 *
 * @param {any} _cell
 * @param {any} row
 * @returns {string}
 */
export const serializeDealType: CellSerializer = (_cell, row) => {
  return row?.terms?.termsType?.name || '';
};

/**
 * Buyer phone serialization.
 *
 * @param {any} _cell
 * @param {any} row
 * @returns {string}
 */
export const serializePhone: CellSerializer = (_cell, row) => {
  const contacts = row?.buyerPerson?.contacts;
  const phoneContacts =
    contacts && contacts.filter((contact: any) => contact.contactType?.toLowerCase().includes('phone'));

  return phoneContacts && phoneContacts.length ? phoneContacts[0].contact : '';
};
