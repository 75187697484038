import Row from './Row';
import Root from './Root';
import Cell from './Cell';
import Full from './Full';
import Content from './Content';

const Body = {
  Full,
  Cell,
  Row,
  Root,
  Content,
};

export default Body;
