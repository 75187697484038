/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-unused-vars */
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { ReactElement, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import CypressTags from '../../support/cypressTags';

interface MonthDatePickerProps {
  monthsList?: string[];
  years?: string[];
  month?: string;
  year?: string;
  date?: string;
  onChangeMonth?: (month: string) => void;
  onChangeYear?: (year: string) => void;
  onChangeDate?: (dateString: string) => void;
}

export const MonthDatePicker = ({
  month,
  year,
  // date,
  monthsList,
  years,
  onChangeMonth,
  onChangeYear,
}: // onChangeDate,
MonthDatePickerProps): ReactElement => {
  const [openYearDropdown, setOpenYearDropdown] = useState<boolean>(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setOpenYearDropdown(false);
  });

  const updateMonth = (newMonth: string) => {
    onChangeMonth && onChangeMonth(newMonth);
  };

  const updateYear = (newYear: string) => {
    onChangeYear && onChangeYear(newYear);
  };

  // const updateSelectedDate = (newDate: string) => {
  //   onChangeDate && onChangeDate(newDate);
  // };

  return (
    <div className="flex items-center md:border-t-[0.5px] border-t-solid border-t-white text-white overflow-x-auto">
      <div
        className="flex flex-row justify-between items-center flex-[2_2_20em] md:min-h-[88px]"
        data-cy={CypressTags.ROUTING.MONTH_PICKER}
      >
        {(monthsList || moment.months()).map((monthString, index) => {
          if (month === monthString) {
            return (
              <div key={index}>
                <div
                  className="text-black bg-white py-2 px-6"
                  data-cy={CypressTags.ROUTING.SELECTED_MONTH}
                  id={`selectedMonth_${monthString}`}
                  key={index}
                  onClick={() => updateMonth(monthString)}
                >
                  {monthString.substr(0, 3)}
                </div>
              </div>
            );
          } else {
            return (
              <div key={index}>
                <div
                  className="cursor-pointer py-2 px-6 hover:text-black hover:bg-white"
                  data-cy={CypressTags.ROUTING.UNSELECTED_MONTH}
                  id={`selectedMonth_${monthString}`}
                  key={index}
                  onClick={() => updateMonth(monthString)}
                >
                  {monthString.substr(0, 3)}
                </div>
              </div>
            );
          }
        })}
      </div>
      <div
        className="relative text-sm flex basis-[106px] shrink-0 min-h-[3em] items-center justify-end"
        data-cy={CypressTags.ROUTING.YEAR_PICKER}
      >
        <button
          className="border outline-0 bg-inherit cursor-pointer font-bold p-[10px]"
          onClick={() => {
            setOpenYearDropdown(!openYearDropdown);
          }}
        >
          {year}
          <div className={`pl-[10px] float-right`}>
            {openYearDropdown ? <ChevronDownIcon width={20} /> : <ChevronUpIcon width={20} />}
          </div>
        </button>
        {openYearDropdown && (
          <ul
            ref={ref}
            className="fixed max-h-[150px] overflow-hidden overflow-y-auto bg-white px-3 -mt-2 top-72 right-10 max-w-fit min-w-fit w-full shadow-lg text-black"
          >
            {years?.map((y) => {
              return (
                <li
                  className="cursor-pointer no-underline px-4 py-2 hover:underline"
                  key={`date-${y}`}
                  onClick={() => {
                    updateYear(y);
                    setOpenYearDropdown(false);
                  }}
                >
                  {y}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};
