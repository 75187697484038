import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

import { generateSimpleDropdown } from '../dropdown/Dropdown';

/**
 * Ages renderer.
 */
export const AgesRenderer: FC<ICellRendererParams> = (props) => {
  const { data: row } = props;
  const ages = props.value ? props.value : row?.ages;
  return (
    <div className="center-content" data-row-id={row.id}>
      {' '}
      {ages ? `${ages}` : <></>}{' '}
    </div>
  );
};

/**
 * Ages editor.
 */
export const AgesEditor = generateSimpleDropdown('AgesEditor', [
  { value: 'All Ages' },
  { value: '18+' },
  { value: '21+' },
]);
