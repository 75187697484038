import { get, post } from 'api';

/**
 * Post the email and client info to send invite
 * @returns the user object
 */
export const sendUserInvite = async (params: any): Promise<any> => {
  const response = await post(`/backstageAdmin/createShare`, params);
  return response;
};

/**
 * Post the email to get Backstage user
 * @returns the user object
 */
export const getBackstageUser = async (email: string): Promise<any> => {
  const response = await get(`/backstageAdmin/user?email=${email}`);
  return response;
};
