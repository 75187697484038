import { PaginationProps } from 'components/Pagination';
import TimelineAgenda from 'components/TimelineAgenda';
import { TimelineAgendaData, ClauseViolationDataType } from 'components/TimelineAgenda/types';
import { useFilters } from 'context/avails-filters/context';
import { INITIAL_STATE } from 'context/avails-filters/hook';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import { FC, useCallback, useEffect, useMemo } from 'react';

interface SearchDataAddress {
  address: string;
  suite?: string;
  primary?: string;
  city?: string;
  zip?: string;
  country?: string;
}

interface SearchDataGeolocation {
  type: string;
  coordinates: [number, number];
}

interface SearchDataVenue {
  name?: string;
  type?: string;
  address: SearchDataAddress;
  geolocation?: SearchDataGeolocation;
}

interface SearchDataShow {
  title: string;
  status: string;
  type?: string;
  date?: Date;
  confirmedOn?: Date;
  createdOn?: Date;
  notes?: string;
  venue: SearchDataVenue;
  clauseViolationData?: ClauseViolationDataType;
}

interface SearchDataDate {
  date: Date;
  shows?: SearchDataShow[];
  unavailable?: boolean;
}

export interface CalendarViewData {
  client: {
    id: number | string;
    name: string;
    // ...
  };
  dates: SearchDataDate[];
}

export interface SearchData {
  client: {
    id: number | string;
    name: string;
    // ...
  };
  dates: SearchDataDate[];
}

export type TimelineAgendaViewProps = {
  data: TimelineAgendaData;
  selectedData: TimelineAgendaData;
  performingPrint?: boolean;
  onChange?: (data: TimelineAgendaData) => void;
} & PaginationProps;

const TimelineAgendaView: FC<TimelineAgendaViewProps> = ({ performingPrint, data, selectedData, onChange }) => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();
  const { setActionsEnabled } = useFilters();

  const hasSelection = useMemo(() => data?.some((row) => row?.selected), [data]);

  useEffect(() => {
    setActionsEnabled(hasSelection);

    return () => {
      // Reset when unmounted.
      setActionsEnabled(INITIAL_STATE.ACTIONS_ENABLED);
    };
  }, [hasSelection, setActionsEnabled]);

  const handleOnChange = useCallback(
    (newData: TimelineAgendaData) => {
      onChange && onChange(newData);
    },
    [onChange]
  );

  return (
    <div
      className="flex flex-col flex-1 w-full pb-8 print:pb-0"
      onMouseOver={lockOverscrollNavigation}
      onMouseLeave={unlockOverscrollNavigation}
    >
      <TimelineAgenda.Full data={performingPrint ? selectedData : data} onChange={handleOnChange} />
    </div>
  );
};

export default TimelineAgendaView;
