import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';
import { FC } from 'react';

import { getDateFormatByBrowserRouting } from 'utils/helpers';
import CypressTags from '../../../../support/cypressTags';

/**
 * Event Date renderer.
 *
 * @param {ICellRendererParams} props
 */
export const EventDateRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const value = moment.utc(new Date(props.value)).format(getDateFormatByBrowserRouting());
  return props.data?.monthBreak || props.data?.displayDate === false ? (
    <span />
  ) : (
    <div
      className="center-content"
      data-row-id={props.data.id}
      data-cy={CypressTags.ROUTING.TABLE_DATE}
      data-value={value}
    >
      {value}
    </div>
  );
};
EventDateRenderer.displayName = 'EventDateRenderer';
