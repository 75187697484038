import { ICellRendererParams, NewValueParams, RowNode } from 'ag-grid-community';
import { RequestResponse, patch, post, remove, showUserError } from 'api';
import ToastWrapper from 'components/toast/ToastWrapper';
import { iModalState } from 'features/routing/context';
import { toast } from 'react-toastify';
import cypressTags from 'support/cypressTags';
import { classes } from 'support/react-toastify';
import { Person, TableRow, Venue, ViolationsMap } from 'types';
import { v4 as uuidv4 } from 'uuid';

import { isDraft, isEmpty, isShow, removeRow } from '../containers/routing/rowParsers';
import { SetState, convertResponse } from '../features/routing';
import { DropDownCandidate } from '../features/routing/routing-table';
import { STATUS_CANDIDATES as CANDIDATES } from '../features/routing/routing-table/status/Status';
import {
  duplicateInherited,
  parseInherited,
  removeInherited,
} from '../features/routing/routing-table/utils/parseViolations';

const FORWARD = 'forward';
const BACKWARD = 'backward';

export const addDeal = (date: Date, setShowNewDealModal: SetState<iModalState>) => {
  setShowNewDealModal({ isOpen: true, date });
};

/**
 * Handles entering in data to an empty TableRow OR transitions from a Draft --> Show TableRow
 *
 * @param event - Ag-Grid event triggered
 * @param draftToShow - Flag for determining state-change from DraftShow to Show
 * @param rowIndex - Index of the GridRow being edited
 * @param tableRows - current grid rows
 * @param setTableRows - SetState hook for grid rows
 * @param violations - current violations map
 * @param setViolations - SetState hook for violations
 */
export const createRow = async (
  event: NewValueParams,
  draftToShow: boolean,
  rowIndex: number,
  tableRows: TableRow[],
  setTableRows: SetState<TableRow[]>,
  violations: ViolationsMap,
  setViolations: SetState<ViolationsMap>,
  updateInProgress: boolean,
  setUpdateInProgress: SetState<boolean>
): Promise<void> => {
  const { data, column } = event;
  const field = column.getColId();

  let newValue = event.newValue;
  // if (field === 'radius') newValue = newValue;
  // if (['timeBefore', 'timeAfter'].includes(field)) newValue = toMs(newValue);
  let specificRoute = 'draft';

  if (draftToShow && data.buyerPerson) {
    specificRoute = 'showDeal/fromDraft';
  } else if (draftToShow && (data.buyerPerson === undefined || !data.buyerPerson._id)) {
    specificRoute = 'show';
  }

  const payload = {
    data: convertPayload({ ...data, [field]: newValue }),
  };

  if (!updateInProgress) {
    setUpdateInProgress(true);
    post(`/touring/routingTable/${specificRoute}/`, payload)
      .then((resp) => {
        if (resp.ok) {
          const newRows = [...tableRows];
          newRows[rowIndex] = {
            ...convertResponse(resp.body.data),
            date: data.date,
            id: data.id,
            _id: resp.body.data._id,
          };

          // if Draft -> Show then the violations are treated like a duplicate
          if (draftToShow) {
            setViolations(duplicateInherited(violations, data._id, resp.body.data._id));
          } else {
            setViolations(parseInherited(resp.body.data._id, violations, resp.body.violations));
          }
          setTableRows(newRows);
        } else {
          showUserError(resp);
        }
        setUpdateInProgress(false);
      })
      .catch((err) => {
        showUserError(err);
        setUpdateInProgress(false);
      });
  } else {
    toast.warn(
      ToastWrapper('Routing create in-progress!! Ignoring cell change.', cypressTags.TOURING_NOTIFICATION.WARNING),
      {
        className: classes.warningClass,
        autoClose: false,
      }
    );
  }
};

/**
 * Handles updates to data within a TableRow
 *
 * @param event - Ag-Grid event triggered
 * @param rowIndex - Index of the GridRow being edited
 * @param tableRows - current grid rows
 * @param setTableRows - SetState hook for grid rows
 * @param violations - current violations map
 * @param setViolations - SetState hook for violations
 * @param updateInProgress - boolean indicating there is an update endpoint that was sent and has not returned.
 * @param setUpdateInProgress - SetState hook for updateInProgress.
 */
export const updateRow = async (
  event: NewValueParams,
  rowIndex: number,
  tableRows: TableRow[],
  setTableRows: SetState<TableRow[]>,
  violations: ViolationsMap,
  setViolations: SetState<ViolationsMap>,
  updateInProgress: boolean,
  setUpdateInProgress: SetState<boolean>
): Promise<void> => {
  const { data, newValue, oldValue, column } = event;
  const field = column.getColId();
  const payload = handleFieldCoupling(field, newValue, oldValue);
  const patchParams = `${data.clientId}/${data._id}`;
  let specificRoute = `show/${patchParams}`;
  let requestMethod = patch;
  const isValidBuyerPersonUpdate = field === 'buyerPerson' && payload?.buyerPerson?._id;
  const isInvalidBuyerPersonUpdate = field === 'buyerPerson' && !payload.buyerPerson;
  const isNotBuyerPersonUpdate = field !== 'buyerPerson';

  if (isDraft(data) && !isShow(payload as TableRow)) {
    specificRoute = `draft/${patchParams}`;
  } else if (data?.deal && (isNotBuyerPersonUpdate || isValidBuyerPersonUpdate)) {
    specificRoute = `showDeal/${patchParams}`;
  } else if (data?.deal && isInvalidBuyerPersonUpdate) {
    toast.warn(ToastWrapper('Buyer can not be empty', cypressTags.TOURING_NOTIFICATION.WARNING), {
      className: classes.warningClass,
      autoClose: false,
    });
    return;
  } else if (isValidBuyerPersonUpdate) {
    specificRoute = 'showDeal/fromShow';
    requestMethod = post;
  }

  if (!updateInProgress) {
    setUpdateInProgress(true);
    requestMethod(`/touring/routingTable/${specificRoute}`, {
      data: convertPayload(data),
      updates: payload,
    })
      .then((resp) => {
        if (resp.ok) {
          const newRows = [...tableRows];
          newRows[rowIndex] = {
            ...data,
            ...convertResponse(resp.body.data),
            date: data.date,
            id: data.id,
          };
          setTableRows(newRows);
          setViolations(parseInherited(resp.body.data._id, violations, resp.body.violations));
        } else {
          showUserError(resp);
        }
        setUpdateInProgress(false);
      })
      .catch((err) => {
        showUserError(err);
        setUpdateInProgress(false);
      });
  } else {
    toast.warn(
      ToastWrapper('Routing update in-progress!! Ignoring cell change.', cypressTags.TOURING_NOTIFICATION.WARNING),
      {
        className: classes.warningClass,
        autoClose: false,
      }
    );
  }
};

export const cannotRejectRow = () => {
  toast.warn(
    ToastWrapper(
      'You cannot reject confirmed shows within a deal or shows within deals that are linked or contain multiple shows',
      ''
    ),
    {
      className: classes.warningClass,
      autoClose: false,
    }
  );
};

/**
 * Deletes a populated or partially populated table row
 * @param params
 * @param tableRows
 * @param setTableRows - state setting method
 * @param violations -
 * @param setViolations -
 */
export const deleteRow = async (
  params: ICellRendererParams,
  tableRows: TableRow[],
  setTableRows: SetState<TableRow[] | null>,
  violations: ViolationsMap,
  setViolations: SetState<ViolationsMap>
): Promise<void> => {
  // toast.warn(ToastWrapper('Deleting row, this may take a few seconds', ''), {
  //   className: classes.warningClass,
  //   autoClose: false,
  // });
  const currentRow = params.data;

  // await remove(`/touring/routingTable/${currentRow.clientId}/${currentRow._id}`)
  await remove(`/touring/deals/${currentRow.dealId}`)
    .then((resp) => {
      if (resp.ok) {
        setViolations(removeInherited(violations, currentRow._id));
        setTableRows(removeRow(tableRows, currentRow));
      } else {
        showUserError(resp);
      }
    })
    .catch((err) => {
      showUserError(err);
    });
};

/**
 * Duplicates a TableRow while accounting for TableRow display concerns
 *
 * @param _withBuyer - flag to determine if duplicate should carry
 *  buyerCompany / buyerPhone / phoneNumber information of its seed
 * @param currentRow - row to duplicate
 * @param tableRows - list of current rows
 * @param setTableRows - set state hook for grid rows
 * @param violations - current violations map
 * @param setViolations - set state hook for violations map
 */
export const duplicateRow = async (
  _withBuyer: boolean,
  currentRow: TableRow,
  tableRows: TableRow[],
  setTableRows: SetState<TableRow[] | null>,
  violations: ViolationsMap,
  setViolations: SetState<ViolationsMap>
): Promise<void> => {
  // TODO: ONX-1288: clean up withBuyer

  const payload = { isDraft: isDraft(currentRow) };
  const id = currentRow._id;
  await post(`/touring/routingTable/${currentRow.clientId}/duplicate/${id}`, payload)
    .then((resp) => {
      if (resp.ok) {
        const idx = tableRows.findIndex((row) => row.id === currentRow.id);
        const newRows = [...tableRows];
        const duplicate = { ...convertResponse(resp.body), displayDate: false, id: uuidv4() };
        newRows.splice(idx + 1, 0, duplicate);
        setViolations(duplicateInherited(violations, currentRow._id as string, resp.body._id));
        setTableRows(newRows);
      } else {
        showUserError(resp);
      }
    })
    .catch(showUserError);
};

/**
 * Adds a new TableRow to the grid that is "empty" of Show information except for
 * having a status of "Sketch"
 *
 * @param currentRow - row whose action menu was selected for Empty Row Creation
 * @param tableRows - current list of TableRows
 * @param setTableRows - set state hook for list of TableRows
 */
export const createEmptyRow = async (
  currentRow: TableRow,
  tableRows: TableRow[],
  setTableRows: SetState<TableRow[]>
): Promise<void> => {
  const { clientId, date } = currentRow;
  await post(`/touring/routingTable/${clientId}/`, {
    data: { date, clientId, status: 'Sketch' },
  })
    .then((resp) => {
      if (resp.ok) {
        const idx = tableRows.findIndex((row) => row.id === currentRow.id);
        const newRows = [...tableRows];
        newRows.splice(idx + 1, 0, {
          ...resp.body,
          prevLocation: currentRow.prevLocation,
          nextLocation: currentRow.nextLocation,
          displayDate: false,
          id: uuidv4(),
        });
        setTableRows(newRows);
      } else {
        showUserError(resp);
      }
    })
    .catch(showUserError);
};

/**
 * Helps to open a new window with the corresponding deal Id
 * @param id - deal Id
 */
export const goToDealForm = (id: string) => {
  window.open(`/old-deal/${id}`);
};

export const editDeal = (id: string) => {
  window.open(`/deal/${id}`);
};

/**
 * Handle API response to update rows after toggling client availability
 */
export const handleClientAvailableRes = (
  available: boolean,
  currentRow: TableRow,
  tableRows: TableRow[],
  setTableRows: SetState<TableRow[] | null>,
  resp: RequestResponse
): void => {
  if (resp.ok) {
    const idx = tableRows.findIndex((row) => row.id === currentRow.id);

    const newRows = [...tableRows];
    const newRow = {
      date: currentRow.date,
      clientId: currentRow.clientId,
      displayDate: true,
      clientAvailable: available,
      id: uuidv4(),
      _id: undefined,
    };
    if (!newRow.clientAvailable) {
      newRow._id = resp.body.data._id;
    }
    newRows[idx] = newRow;
    setTableRows(newRows);
  }
};

/**
 * Toggle client availability boolean value
 * TableRow must be empty.
 * When date toggles to unavailable,
 * a DraftShow is created to block date and it is replaced in the grid.
 * When date toggles to available,
 * the DraftShow is deleted and filled in with an empty row.
 */
export const toggleClientAvailable = async (
  currentRow: TableRow,
  tableRows: TableRow[],
  setTableRows: SetState<TableRow[] | null>
): Promise<void> => {
  const { clientId, date, clientAvailable, _id } = currentRow;
  const create = {
    method: post,
    url: `/touring/routingTable/draft`,
    payload: { data: { date, clientId, status: 'Sketch', clientAvailable: false } },
  };
  const destroy = {
    method: remove,
    url: `/touring/routingTable/${clientId}/${_id}`,
    payload: {},
  };
  const { method, url, payload } = clientAvailable && !_id ? create : destroy;

  await method(url, payload)
    .then((resp) => {
      const available = resp.status === 200;
      handleClientAvailableRes(available, currentRow, tableRows, setTableRows, resp);
    })
    .catch(showUserError);
};

export interface IAction {
  title: string;
  icon?: string;
  action: (...params: any[]) => void;
}

/**
 * Consumed by the Actions.tsx Renderer, method determines the actions available in
 * each TableRow's, pinned right, Actions Menu
 *
 * @param params - full renderer params consumed by Renderer
 * @param tableRows - current list of TableRows
 * @param setTableRows - set state hook for TableRows
 * @param violations - current violations map
 * @param setViolations - set state hook for violations map
 */
export const getActions = (
  params: ICellRendererParams,
  tableRows: TableRow[],
  setTableRows: SetState<TableRow[] | null>,
  violations: ViolationsMap,
  setViolations: SetState<ViolationsMap>,
  setShowNewDealModal: SetState<iModalState>,
  setShowRejectDealModal: SetState<iModalState>
): IAction[] => {
  const row = params.data;
  let withBuyer = true;
  const actions = [];
  const dealId = params.node.data.deal || row.deal;

  const isSettledDeal = row.dealStatus === 'Settled';

  if (row.clientAvailable) {
    actions.push({
      title: 'Add Deal',
      icon: 'PlusIcon',
      action: () => addDeal(row.date, setShowNewDealModal),
      dataCy: cypressTags.ROUTING.ADD_DEAL_BUTTON,
    });
  }

  if (row._id && !isEmpty(row) && row.clientAvailable && row.dealStatus === 'Pending' && row.status === 'Pending') {
    actions.push({
      title: 'Reject Deal',
      icon: 'TrashIcon',
      action: () => setShowRejectDealModal({ isOpen: true, dealId }),
    });

    // opens the new Deal modal with the values preselected
    actions.push({
      title: 'Duplicate Deal',
      icon: 'DocumentDuplicateIcon',
      action: () => duplicateRow(withBuyer, row, tableRows, setTableRows, violations, setViolations),
    });
    // don't know what this is
    // actions.push({
    //   title: 'Empty Row, Same Date',
    //   action: () => createEmptyRow(row, tableRows, setTableRows),
    // });
  }

  if (isEmpty(row) || !row.clientAvailable) {
    actions.push({
      title: 'Toggle Client Availability',
      icon: 'CalendarDaysIcon',
      action: () => toggleClientAvailable(row, tableRows, setTableRows),
      dataCy: cypressTags.ROUTING.TOGGLE_CLIENT_AVAILABILITY,
    });
  }

  // obsolete
  // if (row.buyerCompany || row.buyerPerson) {
  //   withBuyer = false;
  //   actions.push({
  //     title: 'Duplicate Show Without Buyer',
  //     action: () => duplicateRow(withBuyer, row, tableRows, setTableRows, violations, setViolations),
  //   });
  // }

  if (dealId && !isSettledDeal) {
    actions.push({
      title: 'Edit Deal',
      icon: 'PencilIcon',
      action: () => editDeal(dealId),
      dataCy: cypressTags.ROUTING.EDIT_DEAL_BUTTON,
    });
  }

  if (isSettledDeal) {
    actions.push({
      title: 'View Deal',
      icon: 'EyeIcon',
      action: () => editDeal(dealId),
    });
  }

  return actions;
};

/**
 * Determines payload based on the column being edited
 *
 * @param field - column being updated
 * @param newValue - new desired value for column cell
 * @param oldValue - old value of column cell
 */
export const handleFieldCoupling = (
  field: string,
  newValue: Person | string | number | undefined | Venue,
  oldValue: Person | string | number | undefined | Venue
) => {
  let payload;
  switch (field) {
    case 'venue':
      if (oldValue && !newValue) {
        payload = { venue: null, city: null, state: null, country: null, violations: [] };
      } else {
        payload = { venue: newValue };
      }
      break;
    case 'buyerPerson':
      if (oldValue && typeof newValue === 'object' && !newValue?.name) {
        payload = { buyerPerson: null };
      } else {
        payload = { buyerPerson: newValue };
      }
      break;

    default:
      payload = { [field]: newValue };
      break;
  }

  return convertPayload(payload as Partial<TableRow>);
};

/**
 * Determines if the transition from oldValue -> newValue constitutes a state change from Draft -> Show
 * @param oldValue - old status value
 * @param newValue - new status value
 */
export const draftToShowStatus = (oldValue: string, newValue: string): boolean => {
  const DRAFT_STATUSES = CANDIDATES.draft.map((item: DropDownCandidate) => item.value);
  const SHOW_STATUSES = CANDIDATES.show.map((item: DropDownCandidate) => item.value);
  return DRAFT_STATUSES.includes(oldValue) && SHOW_STATUSES.includes(newValue);
};

/**
 * Method to convert the displays units of radius, daysAfter, daysBefore to its persistence units
 * which is in meters for radius and milliseconds for daysBefore/After
 * @param payload - payload to convert\
 * @returns converted value.
 */
const convertPayload = (payload: Partial<TableRow>) => {
  const { radius, timeBefore, timeAfter } = payload;
  payload.venue = payload.venue?._id ? payload.venue : undefined;
  payload.timeBefore = timeBefore ? Number(timeBefore) : undefined;
  payload.timeAfter = timeAfter ? Number(timeAfter) : undefined;
  payload.radius = radius ? Number(radius) : undefined;
  return payload;
};

/**
 * Business logic to update the previous and next locations for the udpated venue in the current show
 *
 * Scenario 1: There can be a completely new show where venue is being added for first time, in such case
 * the oldVenue is going to be undefined which is being checked
 * Scenario 2: There can a show where venue is updated and then we check for the next, prev locations of
 * other shows which are not equal to old venue and update correspondingly
 * Scenario 3: There can be a situation where a venue is updated and but the users chooses the same venue
 * as the updated venue, in such case we don't need to update the next, prev locations which is being
 * checked
 *
 * @param event - instance of an AG-Grid event
 * @param index - initial index to start the loop either forward/backward
 * @param updatedRows - result variable where updated rows with prev, next locations are stored
 * @param direction - variable to determine the direction of looping
 * @returns {RowNode[]} updatedRows
 */
const updatePrevNextLocations = (
  event: NewValueParams,
  index: number,
  updatedRows: RowNode[],
  direction: string
): RowNode[] => {
  const field = direction === FORWARD ? 'prevLocation' : 'nextLocation';
  while (index) {
    index = direction === FORWARD ? ++index : --index;
    const node = event.api.getRowNode(index.toString() as string);
    if (node) {
      node.data[field] = event.newValue;
      updatedRows.push(node.data);
      if (node.data.venue && node.data[field]?._id === event.newValue._id) {
        break;
      }
    }
  }
  return updatedRows;
};

/**
 * Updates a row's ruleViolations to be ignored.
 * @param {RowNode} row, the row to ignore
 * @param {ViolationsMap} violations, the map of owned and inherited violations for all the shown tableRows
 * @param {SetState<ViolationsMap>} setViolations, violations setter.
 * @returns {Promise<void>}, if the violation was successfully ignored
 */
export const ignoreRuleViolation = async (
  row: RowNode,
  violations: ViolationsMap,
  setViolations: SetState<ViolationsMap>
): Promise<void> => {
  return patch(`/touring/shows/${row.data._id}/ignoreRuleViolations`, {})
    .then((resp) => {
      if (resp.ok) {
        setViolations(removeInherited(violations, row.data._id));
      } else showUserError(resp);
    })
    .catch((err) => {
      showUserError(err);
    });
};

/**
 * Method to update the previous and next locations of neighbouring shows when a venue
 * is updated in a particular show
 * @param {NewValueParams} event
 * @returns {RowNode[]} updatedRows
 */
export const updateRowsWithNewLocation = (event: NewValueParams): RowNode[] => {
  let updatedRows = [] as RowNode[];

  // Get the current Index to start with
  const index = event.node?.rowIndex;

  if (index) {
    // Loop forward until nearest prevLocation also update the prevLocation as we loop through
    updatedRows = updatePrevNextLocations(event, index, updatedRows, FORWARD);

    // Loop backward until nearest nextLocation also update the nextLocation as we loop through
    updatedRows = updatePrevNextLocations(event, index, updatedRows, BACKWARD);
  }
  return updatedRows;
};
