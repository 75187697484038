import Body from './Body';
import Full from './Full';
import Header from './Header';
import Root from './Root';

const Table = {
  Header,
  Body,
  Root,
  Full,
};

export default Table;
