import { formatPercent } from 'deal-form/helpers/dealHelpers';

import BlankCell from '../BlankCell';
import { FC } from 'react';

export type PercentRendererProps = {
  value?: number;
};

const PercentRenderer: FC<PercentRendererProps> = ({ value }) =>
  value ? <span>{formatPercent(value) + '%'}</span> : <BlankCell />;

export default PercentRenderer;
