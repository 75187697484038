import { CheckCircleIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
// import { set } from 'cypress/types/lodash';
import { ERROR_MESSAGES } from 'deal-form/data/constants';
import { iOptions } from 'deal-form/interfaces/general';
import React, { BaseSyntheticEvent, KeyboardEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import {
  FieldValues,
  FormState,
  RegisterOptions,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext,
} from 'react-hook-form';
import { useClickAway } from 'react-use';
import { alphabetize } from 'utils/helpers';

import { findNestedErrorById } from '../../helpers/formHelpers';

interface iDropdownProps {
  label?: string | boolean;
  className?: string;
  placeholder?: string;
  id: string;
  maxLength?: number;
  defaultValue?: string;
  index?: number;
  disabled?: boolean;
  handleChange?: (
    chosen: iOptions | null,
    setValueMethod: UseFormSetValue<FieldValues>,
    index?: number,
    trigger?: UseFormTrigger<FieldValues>
  ) => void;
  rules?: RegisterOptions;
  rulesCallback?: (formState: FormState<FieldValues>, index?: number) => RegisterOptions<FieldValues, string>;
  isRequired?: boolean;
  staticOptions: iOptions[];
  customOptions?: iOptions[];
  addCustomOption?: (value: string) => void;
  customOptionLabel?: string;
  dataCy?: string;
}

export const DropdownWithStaticSearch = ({
  id,
  label = false,
  placeholder = '',
  className = '',
  handleChange,
  index,
  defaultValue = '',
  disabled = false,
  isRequired = false,
  rules = {},
  rulesCallback,
  staticOptions,
  maxLength,
  addCustomOption,
  customOptions = [],
  dataCy,
  customOptionLabel,
}: iDropdownProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [useDefaultValue, setUseDefaultValue] = useState<string | null>(defaultValue);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [options, setOptions] = useState<iOptions[] | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const [isCreatingCustom, setIsCreatingCustom] = useState<boolean>(false);

  const [customItemValue, setCustomItemValue] = useState<string>('');

  const [chosen, setChosen] = useState<iOptions | null>(null);
  const [previousChosen, setPreviousChosen] = useState<iOptions | null>(null);

  const ref = useRef(null);
  useClickAway(ref, () => {
    resetOptionsDropdown();
  });

  const {
    register,
    getValues,
    setValue,
    control,
    trigger,
    watch,
    formState,
    formState: { errors },
    setError,
  } = useFormContext();

  const invalidResultsErrorId = `${id}_searcherror`;

  const { name } = register(id, { ...(rulesCallback ? { ...rulesCallback(formState, index) } : rules) });

  const hasErrors =
    errors && (!!findNestedErrorById(id, errors) || !!findNestedErrorById(invalidResultsErrorId, errors));

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  React.useEffect(() => {
    // watch for changes to the field
    const subscription = watch((_value, { name }) => {
      if (name === id) {
        const v = getValues(id);
        // if the value is different, set the chosen value
        if (v !== chosen?.id) {
          const optionFromId = staticOptions?.find((option) => option.id === v) || {
            id: v,
            label: v,
            data: {},
          };

          setChosen(optionFromId);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (chosen) {
      const currentValue = getValues(id);

      // Only do update if chosen value has changed
      if (chosen.id !== currentValue) {
        // TODO: We may want to refactor this to use react-hook-form's Controller
        // In the meantime, we set `dirty` programmatically to true to reflect the value has changed
        setValue(id, chosen.id, { shouldDirty: true });
        // resetOptionsDropdown();
        trigger(id);

        if (handleChange) handleChange(chosen, setValue, index, trigger);
        // onBlur({ target: ref });

        // Clear the custom error now
        // clearErrors([invalidResultsErrorId]);
        const element: HTMLSelectElement = document.getElementById(`${id}-${name}`) as HTMLSelectElement;

        if (element) {
          if (getValues(id) !== element.value) {
            element.value = chosen.id;
          }
        }
      }
    }
  }, [chosen, id, setValue]);

  useEffect(() => {
    if (isOpen === false) {
      setSearchValue('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (control._defaultValues && getValues(id)) {
      try {
        const value = getValues(id);

        if (value !== undefined) {
          const idValue = value.value || value;

          const optionFromId = staticOptions?.find((option) => option.id === idValue) || {
            id: value,
            label: value,
            data: {},
          };

          if (optionFromId) {
            setChosen(optionFromId);
            setPreviousChosen(optionFromId);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [control._defaultValues, staticOptions]);

  const resetOptionsDropdown = () => {
    setOptions(null);
    setIsOpen(false);
  };

  /**
   * handle dropdown client
   * when the user clicks on the field, it will dropdown a list of all the current
   * options available. If the user clicks on the field again, it will close the dropdown
   *
   */
  const handleDropDownClick = () => {
    if (isOpen) {
      setIsOpen(false);
      // resetOptionsDropdown();
    } else {
      if (!disabled && staticOptions) {
        const customOption = customOptionLabel ? [{ id: customOptionLabel, label: customOptionLabel, data: {} }] : [];

        const enhancedOptions = [...customOption, ...customOptions, ...staticOptions] as iOptions[];

        if (enhancedOptions.length > 0) {
          setOptions(enhancedOptions);
        }
      }
      setIsOpen(true);
    }
  };

  const handleArrowKeyDown = (direction = 'down') => {
    if (!options) {
      return;
    }

    if (!isOpen) {
      setIsOpen(true);
      return;
    }

    const currentIndex = options.sort(alphabetize).findIndex((option) => option.id === chosen?.id);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
    const newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;

    setChosen(options[direction === 'up' ? prevIndex : newIndex]);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
    const key = e.key;

    if (key === 'ArrowUp') {
      e.preventDefault();
      handleArrowKeyDown('up');
    }

    if (key === 'ArrowDown') {
      e.preventDefault();
      handleArrowKeyDown('down');
    }

    if (key === 'Escape' || key === 'Tab') {
      e.preventDefault();
      setCustomItemValue('');
      setIsCreatingCustom(false);
      setIsOpen(false);
    }
  };

  const handleUpdateCustom = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomItemValue(event.target.value);
  };

  const handleAddCustomItem = () => {
    if (customItemValue === '') return setIsCreatingCustom(false);
    // check to see if the value exists,
    if (
      customOptions.find((option) => option.label === customItemValue) ||
      staticOptions.find((option) => option.label === customItemValue)
    ) {
      console.log("this value already exists, don't add it");
      // TODO: do we want to say anything here?
    } else {
      if (addCustomOption) addCustomOption(customItemValue);
    }

    const option = { label: customItemValue, id: customItemValue, data: {} };
    // set the new option
    setChosen(option);
    setPreviousChosen(option);
    setSearchTerm(null);
    setCustomItemValue('');

    setIsCreatingCustom(false);
    // if it does then just selected it an move on
    // if it doesn't, then add it to the list of options and select it
  };

  const handleSelection = (selectedOption: iOptions | null) => {
    // if the selection is to create a new option
    if (customOptionLabel && selectedOption?.label === customOptionLabel) {
      setIsOpen(false);
      setCustomItemValue('');
      return setIsCreatingCustom(true);
    }

    setChosen(selectedOption);
    setPreviousChosen(selectedOption);
    setSearchTerm(null);
    setIsOpen(false);
  };

  const handleClear = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    setUseDefaultValue(null);
    handleSelection(null);
    setValue(id, '', { shouldDirty: true });

    if (handleChange) handleChange(null, setValue, index, trigger);

    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const key = e.key;

    if (key === 'Enter') {
      e.preventDefault();
      setIsOpen(false);

      if (customOptionLabel && chosen?.id === customOptionLabel) {
        setIsOpen(false);
        setCustomItemValue('');
        return setIsCreatingCustom(true);
      }
    }

    if (key === 'ArrowUp') {
      e.preventDefault();
      handleArrowKeyDown('up');
    }

    if (key === 'ArrowDown') {
      e.preventDefault();
      handleArrowKeyDown('down');
    }

    if (key === 'Escape' || key === 'Tab') {
      if (customOptionLabel && chosen?.id === customOptionLabel) {
        setIsOpen(false);
        setCustomItemValue('');
        return setIsCreatingCustom(true);
      }
      // e.preventDefault();
      setCustomItemValue('');
      setIsCreatingCustom(false);
      setIsOpen(false);
    }
  };

  const getValue = () => {
    return searchTerm || chosen?.label || useDefaultValue || '';
  };

  return (
    <div className="form-field-wrapper" data-testid="dropdown-with-static-search" data-cy={dataCy}>
      {label !== false && <label className="standard-label">{label}</label>}
      <div
        className={`h-10 w-full border border-solid ${disabled ? 'opacity-50' : 'cursor-pointer'} ${
          hasErrors ? 'border-cinnabar' : 'border-black'
        } select-none relative text-sm ${isOpen ? 'active' : ''}
        after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3 ${className}`}
      >
        {isCreatingCustom ? (
          <div className="flex flex-row h-full w-full">
            <input
              className="h-full px-3 py-2 disabled:opacity-50 text-ellipsis max-w-[90%] overflow-hidden table-cell whitespace-nowrap w-full"
              type="text"
              autoComplete="off"
              autoFocus
              name={name} // assign name prop
              // ref={ref} // assign ref prop
              placeholder={placeholder}
              id={`${id}-${name}`}
              value={customItemValue}
              onChange={handleUpdateCustom}
              maxLength={maxLength || 100} // assign maxLength prop
              tabIndex={0}
              disabled={disabled}
              onBlur={() => {
                if (isRequired && !chosen && searchTerm) {
                  // TODO: This should be refactored to let the component accept a custom error
                  setError(invalidResultsErrorId, {
                    type: 'invalidSearchResults',
                    message: ERROR_MESSAGES.INVALID_SEARCH_RESULTS,
                  });
                }
                // Repopulate field with original value if it's empty
                if (!chosen && previousChosen && !isOpen && !searchTerm) {
                  handleSelection(previousChosen);
                }
              }}
              data-testid="input-add-custom-option-testid"
            />
            <CheckCircleIcon
              width={24}
              className="text-sushiOne hover:text-black"
              onClick={handleAddCustomItem}
              data-testid="add-custom-btn-testid"
            />
            <XMarkIcon
              width={24}
              className="mx-1 text-merlot hover:text-black"
              onClick={() => {
                setIsCreatingCustom(false);
              }}
            />
          </div>
        ) : (
          <div
            className="flex flex-row items-center justify-between max-w-full w-full h-full px-3 py-2 "
            data-testid="dropdown-static-search-testid"
            onClick={handleDropDownClick}
          >
            {isOpen ? (
              <input
                type="text"
                className="flex flex-row items-center justify-between max-w-full w-full h-full px-3 py-2 z-[150] outline-none"
                onKeyDown={handleSearchKeyDown}
                onChange={(event) => {
                  return setSearchValue(event.target.value);
                }}
                autoFocus
                value={searchValue}
                tabIndex={0}
                data-testid="search-input-testid"
              />
            ) : (
              <button
                onClick={handleDropDownClick}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                className={`text-ellipsis max-w-[90%] overflow-hidden table-cell whitespace-nowrap ${
                  !getValue() || getValue() === '' ? 'opacity-40' : ''
                }`}
                data-testid="dropdown-toggle-testid"
              >
                {getValue() || placeholder}
              </button>
            )}
            <div className="flex flex-row gap-2">
              <ChevronDownIcon width={15} className={`transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`} />
            </div>

            <div
              className={`${isRequired || chosen === null ? 'hidden' : ''} top-2 absolute right-9 h-full`}
              onClick={handleClear}
              data-id="clear"
            >
              <XMarkIcon width={20} />
            </div>
          </div>
        )}

        {!isCreatingCustom && (
          <ul
            ref={ref}
            className={`border border-solid border-black list-none m-0 p-0 absolute top-full border-t-0 -left-[1px] -right-[1px] transition-opacity duration-300 max-h-[300px] overflow-auto overflow-x-hidden bg-white shadow-header z-10 ${
              options && options.length > 0 ? 'opacity-1 pointer-events-auto z-10' : 'opacity-0 pointer-events-none'
            }`}
            data-testid="dropdown-static-search-options-testid"
          >
            {options &&
            isOpen &&
            options.filter((o) => o.label.toLowerCase().includes(searchValue.toLowerCase())).length > 0
              ? options
                  .filter((o) => o.label.toLowerCase().includes(searchValue.toLowerCase()))
                  .sort(alphabetize)
                  .map((option, i) => (
                    <li
                      key={`dwss-item-${option.id}`}
                      className={`px-3 py-2 border-t border-solid border-greyCloudy hover:underline text-sm ${
                        customOptionLabel && option.label === customOptionLabel ? 'font-bold' : ''
                      } ${option.id === chosen?.id ? 'bg-greyQuill underline' : ''}`}
                      onClick={() => handleSelection(option)}
                      tabIndex={i + 1}
                    >
                      {option.label}
                    </li>
                  ))
              : isOpen && (
                  <li
                    className="px-3 py-2 border-t border-solid border-greyCloudy hover:underline text-sm whitespace-pre-wrap"
                    onClick={() => {
                      setChosen(null);
                      setIsOpen(false);
                    }}
                    role="option"
                  >
                    no results
                  </li>
                )}
          </ul>
        )}
      </div>
    </div>
  );
};
