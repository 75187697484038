import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { updateDealShows } from 'api';
import { handleUpdateDealShowsResponse } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import { BILLING_TYPES, EMPTY_BILLING_FORM } from 'deal-form/data/organisms';
import ButtonWithContext from 'deal-form/form-controls/ButtonWithContext';
import { Checkbox } from 'deal-form/form-controls/Checkbox';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismBilling } from 'deal-form/interfaces/organisms';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Performer } from 'types';

import { DropdownWithStaticSearch } from '../../form-controls/DropdownWithStaticSearch';
import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { Label } from '../../form-controls/Label';
import { TextArea } from '../../form-controls/TextArea';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { getMasterShow } from '../../helpers/showHelpers';
import { formatPercentNumber } from 'utils/helpers';
import cypressTags from 'support/cypressTags';

const { BILLING_INFO } = cypressTags.DEAL_FORM;

const BillingForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked }) => {
  const [billingData, setBillingData] = useState<Partial<iOrganismBilling> | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [customTierOptions, setCustomTierOptions] = useState<iOptions[]>([]);

  useEffect(() => {
    const masterShow = getMasterShow(deal.shows);

    const billing = masterShow.performers[0].billing;

    if (billing) {
      setBillingData({
        billingPercent: billing.percent || undefined,
        billingType: billing.type || '',
        notes: billing.notes || '',
        appearingWith: billing.appearingWith || '',
        includeInDeal: billing.includeInDeal !== undefined ? billing.includeInDeal : true,
      });
    } else {
      setBillingData(EMPTY_BILLING_FORM);
    }
  }, [deal]);

  const updatedPerformers = useCallback(
    (performers: Performer[], data: iOrganismBilling) =>
      performers.map((performer) => ({
        ...performer,
        billing: {
          ...performer.billing,
          percent: data?.billingPercent ? formatPercentNumber(`${data.billingPercent}`) : 0,
          type: data.billingType,
          notes: data.notes,
          includeInDeal: data.includeInDeal,
        },
      })),
    []
  );

  const onSubmit = useCallback(
    async (data: iOrganismBilling) => {
      const updates = deal.shows.map((s) => ({
        showId: s._id,
        updateBody: { performers: updatedPerformers(s.performers, data) },
      }));

      try {
        // prevent duplicate updates
        setCanSubmit(false);

        const response = await updateDealShows({
          dealId: deal._id.toString(),
          updates,
        });

        if (setDeal) {
          handleUpdateDealShowsResponse(response, {
            deal,
            setDeal: (newDeal) => {
              setDeal(newDeal);
              setCanSubmit(true);
            },
          });
        }
      } catch (e) {
        console.log('ERROR UPDATING BILLING', e);
        setCanSubmit(true);
      }
    },
    [deal, setDeal, updatedPerformers]
  );

  const handleClearClause = useCallback((_values: FieldValues, setValueMethod?: UseFormSetValue<FieldValues>) => {
    setValueMethod && setValueMethod('description', '');
  }, []);

  const generateDisplay = useCallback((values: FieldValues) => {
    const valuesToDisplay = [];

    if (![undefined, ''].includes(values?.billingPercent)) {
      valuesToDisplay.push(`${formatPercentNumber(values.billingPercent)}%`);
    }

    valuesToDisplay.push(values.billingType);

    return `${valuesToDisplay.join(' ')}.`;
  }, []);

  const handleAddCustomOption = useCallback((newOption: string) => {
    setCustomTierOptions((oldState) => [...oldState, { id: newOption, label: newOption, data: {} }]);
  }, []);

  return (
    billingData && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className="fixed-first-column-with-labels lg:grid-cols-[200px_1fr_160px_1fr_1fr_1fr]"
        defaultValues={billingData}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.BILLING}
      >
        <Label className="flex items-center" htmlFor="billingPercent">
          Billing %
        </Label>

        <Input
          label={false}
          fieldType="percent"
          rules={{
            min: { value: 0, message: ERROR_MESSAGES.PERCENTAGE_ZERO },
            max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ZERO },
          }}
          id="billingPercent"
          step="0.01"
          disabled={!isOpen || locked}
          dataCy={BILLING_INFO.PERCENT_INPUT}
        />

        <Label htmlFor="billingType" className="flex items-center" isRequired>
          Billing Type
        </Label>
        <DropdownWithStaticSearch
          disabled={!isOpen || locked}
          id="billingType"
          isRequired
          label={false}
          placeholder="None"
          rules={{
            required: 'Please select a valid ‘Billing Type’',
          }}
          staticOptions={BILLING_TYPES}
          addCustomOption={handleAddCustomOption}
          customOptions={customTierOptions}
          customOptionLabel="Create Custom Type"
          dataCy={BILLING_INFO.TYPE}
        />
        <div className="col-span-2"></div>

        <Label className="form-label self-start" htmlFor="notes">
          Billing Display
        </Label>
        <TextArea
          label={false}
          placeholder="Type description here."
          rules={{
            maxLength: {
              value: 180,
              message: getMaxCharMessage('the description', 180),
            },
          }}
          id="notes"
          containerClassName="col-span-5"
          rows={4}
          disabled={!isOpen || locked}
          dataCy={BILLING_INFO.NOTES}
        />

        <div className="col-start-2 col-span-2">
          <Checkbox id="includeInDeal" checkColor="black" label="Show on Contract" disabled={!isOpen || locked} />
        </div>

        <div className="col-start-5 col-span-2 flex justify-end">
          <div className="flex flex-row gap-2">
            <ButtonWithContext
              variant="primary"
              onClick={handleClearClause}
              disabled={!isOpen || locked}
              updateFormName="notes"
              shouldDirty
              dataCy={BILLING_INFO.CLEAR_DISPLAY}
            >
              CLEAR/REMOVE DISPLAY
            </ButtonWithContext>
            <ButtonWithContext
              leftIcon={<ArrowPathIcon width={24} height={24} />}
              variant="primary"
              onClick={generateDisplay}
              disabled={!isOpen || locked}
              updateFormName="notes"
              shouldDirty
              dataCy={BILLING_INFO.GENERATE_DISPLAY}
            >
              GENERATE DISPLAY
            </ButtonWithContext>
          </div>
        </div>
      </Form>
    )
  );
};

export default BillingForm;
