import { ShareIcon, UserIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cypressTags from 'support/cypressTags';
import { getArtistThumbUrl, isCloudinaryImage, isValidUrl } from 'utils/cloudinary';

const THUMBNAIL_WIDTH_HEIGHT = 720;

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
  <linearGradient id="g">
    <stop stop-color="#333" offset="20%" />
    <stop stop-color="#222" offset="50%" />
    <stop stop-color="#333" offset="70%" />
  </linearGradient>
</defs>
<rect width="${w}" height="${h}" fill="#333" />
<rect id="r" width="${w}" height="${h}" fill="url(#g)" />
<animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

type CardProps = {
  name: string;
  imgUrl?: string;
  onShareClick: () => void;
  link: string;
};

export default function ArtistCard({ name, imgUrl, link, onShareClick }: CardProps) {
  const [isImageValid] = useState(imgUrl ? isValidUrl(imgUrl) && isCloudinaryImage(imgUrl) : false);
  const [artistImage, setArtistImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function fetchArtistImage() {
      const imageUrl = await getArtistThumbUrl(imgUrl || '', THUMBNAIL_WIDTH_HEIGHT, THUMBNAIL_WIDTH_HEIGHT); // Assuming the image URL is in the response as text
      setArtistImage(imageUrl);
    }
    fetchArtistImage();
  }, []);
  return (
    <div className="max-w-sm group">
      <div className="max-w-sm relative">
        <Link to={link}>
          <div
            className={`aspect-square overflow-hidden ${
              !isImageValid && 'border border-white'
            } flex items-center justify-center`}
          >
            {isImageValid ? (
              artistImage ? (
                <img src={artistImage} data-cy={cypressTags.ROSTER.CLIENT_CARD_CLIENT_IMAGE_FROM_LINK} />
              ) : (
                <img
                  src={`data:image/svg+xml;base64,${toBase64(shimmer(360, 360))}`}
                  alt={name}
                  className="w-full h-full object-cover"
                />
              )
            ) : (
              <div
                className="flex w-full items-center justify-center"
                data-cy={cypressTags.ROSTER.CLIENT_CARD_CLIENT_IMAGE_DEFAULT}
              >
                <UserIcon className="w-1/2 h-1/2 text-white" />
              </div>
            )}
          </div>
        </Link>

        <button
          data-cy={cypressTags.ROSTER.SHARE_BUTTON}
          className="m-1 text-viridian cursor-pointer absolute right-4 top-4 bg-white p-2 md:opacity-0 sm:opacity-100 group-hover:opacity-100"
          title="Share Backstage View"
          onClick={(e) => {
            e.stopPropagation();
            onShareClick();
          }}
        >
          <ShareIcon width={16} className="cursor-pointer" />
        </button>
        <h5
          className="text-base md:text-lg lg:text-lg font-extended uppercase text-white mt-4 leading-tight"
          data-cy={cypressTags.ROSTER.CLIENT_CARD_CLIENT_NAME}
        >
          {name}
        </h5>
      </div>
    </div>
  );
}
