import { ReactElement, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

export interface SubNavItem {
  path: string;
  label: string;
}
interface SubNavProps {
  items: SubNavItem[];
  doOpen?: boolean;
}

export const SubNav = ({ items, doOpen }: SubNavProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const getLinkStyles = (isActive: boolean): string => {
    return `px-4 py-3 text-nav leading-4 no-underline text-black ${
      isActive ? 'font-bold' : 'font-normal'
    } hover:opacity-50`;
  };

  useEffect(() => {
    setIsOpen(doOpen || false);
  }, [doOpen]);

  return isOpen ? (
    <div className="absolute top-[-8px] left-[-8px] pt-[32px] w-[160px] z-0">
      <div className="bg-white p-2 shadow-dropdown">
        {items.map((item: SubNavItem, index) => (
          <NavLink key={`${index}-${item.path}`} end to={item.path}>
            {({ isActive }) => (
              <div className={getLinkStyles(isActive)}>
                <span>{item.label}</span>
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};
