const getTemplateString = (officeLocation: string, department: string) => {
  if (department === 'Comedy and Touring') {
    return 'Comedy';
  }

  if (department === 'Music') {
    switch (officeLocation) {
      case 'New York Office':
        return 'Music NYC';
      case 'Los Angeles Office':
        return 'Music LA';
      case 'Scandinavia Office':
        return 'Music Malmo';
      case 'Nashville Office':
        return 'Music Nashville';
      case 'London Office':
        return 'Music London';
      case 'Atlanta Office':
        return 'Music LA';
      default:
        return 'Music LA';
    }
  }

  // Default return for any other departments
  return 'Music LA';
};

export default getTemplateString;
