import * as HIcons from '@heroicons/react/24/outline';

interface IHeroIcon {
  iconString: string;
  width?: number;
  className?: string;
}

const HeroIcon = ({ iconString = '', width = 16, className = '' }: IHeroIcon) => {
  if (iconString === '') return null;

  //@ts-ignore
  const Icon = HIcons[iconString];

  if (!Icon) return null;
  return (
    <>
      <Icon width={width} className={className} />
    </>
  );
};

export default HeroIcon;
