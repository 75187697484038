import { deleteReport } from 'api/grid-pages';
import { Modal, iModalProps } from 'features/common/modal/Modal';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import Button from 'components/Button';
import { IdString } from 'components/dropdown-basic/DropdownBasic';

export interface DeleteReportModalProps extends iModalProps {
  testId?: string;
  reportToDelete?: IdString;
  onDeleteReport: () => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteReportModal: FC<DeleteReportModalProps> = ({
  testId,
  reportToDelete,
  onDeleteReport,
  setIsModalOpen,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitReport = async () => {
    setIsSubmitting(true);

    try {
      if (!testId) {
        await deleteReport(reportToDelete?.id);
      }

      if (onDeleteReport) {
        onDeleteReport();
      }

      setIsSubmitting(false);
      setIsModalOpen(false);
    } catch (e) {
      setIsSubmitting(false);
      setIsModalOpen(false);
      console.log(e);
    }
  };

  return (
    <Modal {...props}>
      <div className="mt-1 mb-12">
        <p>By deleting the custom report you will lose the entire report.</p>
      </div>

      <div className="mt-6 flex justify-between gap-[30px]">
        <Button
          disabled={isSubmitting}
          onClick={() => {
            setIsModalOpen(false);
          }}
          className="flex-1"
          variant="stroke"
        >
          Cancel
        </Button>

        <Button
          onClick={(e) => {
            e.preventDefault();
            handleSubmitReport();
          }}
          disabled={!reportToDelete || isSubmitting}
          variant="secondary"
          className="flex-1"
        >
          Yes, Delete Report
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteReportModal;
