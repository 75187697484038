import { updateDeal } from 'api';
import { FORM_NAMES } from 'deal-form/data/constants';
import { BALANCE_REMITTAL_EMPTY_FORM } from 'deal-form/data/organisms';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismBalanceRemittal } from 'deal-form/interfaces/organisms';
import { OrganismWarning } from 'deal-form/ui/OrganismWarning';
import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { Dropdown } from '../../form-controls/Dropdown';
import { Form } from '../../form-controls/Form';
import { Label } from '../../form-controls/Label';
import { TextArea } from '../../form-controls/TextArea';

/**
 * This function fires when a balance remittance is selected, it replaces the
 * text in the field with the text associated with the selected item
 *
 * @param chosen the data about the person being selected
 * @param setValueMethod the function to set a value on the form
 */
const handleOptionChange = (chosen: iOptions | null, setValueMethod?: UseFormSetValue<FieldValues>) => {
  if (setValueMethod) setValueMethod('remittanceText', chosen?.data, { shouldValidate: true });
};

const BalanceRemittanceForm: React.FC<iFormProps> = ({ deal, setDeal, contract, isOpen, locked }) => {
  const [formData, setFormData] = useState<iOrganismBalanceRemittal | null>(null);
  const [options, setOptions] = useState<iOptions[]>();
  const [canSubmit, setCanSubmit] = useState(true);
  const [isSplitDeal, setIsSplitDeal] = useState(false);

  useEffect(() => {
    let remittanceData = BALANCE_REMITTAL_EMPTY_FORM;

    setIsSplitDeal(!!deal.dealSplits && deal.dealSplits?.length > 0);

    const balanceRemittances = deal.client?.territoryInfo?.balanceRemittances || [];

    if (balanceRemittances.length > 0) {
      const balanceRemittancesOptions = balanceRemittances.map((option) => ({
        id: option.label,
        label: option.label,
        data: option.text,
      }));

      setOptions(balanceRemittancesOptions);
    }

    if (deal && deal.balanceRemittance) {
      remittanceData = {
        remittanceName: deal.balanceRemittance?.name || '',
        remittanceText: deal.balanceRemittance?.text || '',
      };
    }
    setFormData(remittanceData);
  }, [deal, contract]);

  const onSubmit = async (data: iOrganismBalanceRemittal) => {
    setCanSubmit(false);

    const updatedDeal = await updateDeal(deal._id, {
      ...deal,
      balanceRemittance: {
        name: data.remittanceName || '',
        type: 'Balance Remittance',
        text: data.remittanceText,
      },
    });

    if (setDeal && updatedDeal) {
      setDeal(updatedDeal);
    }

    setTimeout(() => setCanSubmit(true), 500);
  };

  return (
    <>
      {isSplitDeal ? (
        <OrganismWarning>
          <p className="my-2">
            This event is part of a Split Deal. The Balance Remittance details have been updated within the Split Deal
            section, which override the content below and will be included in the Contract.
          </p>
        </OrganismWarning>
      ) : null}
      {formData ? (
        <Form
          className="grid grid-cols-[200px_1fr_120px_1fr_120px_1fr] gap-5"
          canSubmit={canSubmit}
          onSubmit={onSubmit}
          defaultValues={formData}
          disabled={!isOpen || locked || isSplitDeal}
          formName={FORM_NAMES.BALANCE_REMITTANCE}
        >
          <Label className="self-center" htmlFor="remittanceName">
            Balance Remittance
          </Label>
          <Dropdown
            containerClassName="col-span-3"
            disabled={!isOpen || locked || !options || isSplitDeal}
            id="remittanceName"
            options={options}
            placeholder="Please select a Balance Remittance"
            handleChange={handleOptionChange}
          />

          <Label className=" self-start col-span-1 row-start-2" htmlFor="remittanceText">
            Balance Remittance Display
          </Label>
          <TextArea
            containerClassName="col-span-5 row-start-2"
            disabled={!isOpen || locked || isSplitDeal}
            id="remittanceText"
            label={false}
            placeholder="Type description here."
            rows={4}
          />
        </Form>
      ) : null}
    </>
  );
};

export default BalanceRemittanceForm;
