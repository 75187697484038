import { AgGridReact } from 'ag-grid-react';
import { StickyHeaderPage } from 'features/layouts';
import { PageHeader, AlgoliaServerSide } from 'grid-pages/components';
import { useCallback, useRef } from 'react';

const DealsTestAlgoliaServer = () => {
  const gridApiRef = useRef<AgGridReact>(null);

  const handleOnExport = () => {
    console.log('export');
  };

  const renderFixedContainer = useCallback(
    () => <PageHeader onExport={handleOnExport} title="Algolia Server Side Test" />,
    []
  );

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="grid-height content-wrapper mt-10">
        <AlgoliaServerSide gridApiRef={gridApiRef} />
      </div>
    </StickyHeaderPage>
  );
};

export default DealsTestAlgoliaServer;
