import { FC } from 'react';
import { LoadingSpinner } from '@united-talent-agency/onyx-components';

/**
 * Facilitates the presentation of the loading spinner by centering it in the container where it is used.
 */
const LoadingIndicator: FC = () => (
  <div className="flex justify-center h-full items-center">
    <LoadingSpinner size={40} data-testid="spinner-loader" />
  </div>
);

export default LoadingIndicator;
