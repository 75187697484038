import { ICellEditorParams, KeyCode } from 'ag-grid-community';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components/macro';

/**
 * Currency Cell Editor.
 *
 * @param {ICellEditorParams} props
 * @param {ForwardedRef} ref
 */
export const PercentageCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  /**
   * Initialize the value being handled inside this component.
   */
  const createInitialState = () => {
    let startValue;

    if (props.eventKey === KeyCode.BACKSPACE || props.eventKey === KeyCode.DELETE) {
      startValue = '';
    } else if (props.charPress) {
      startValue = props.charPress;
    } else {
      startValue = props.value != null ? props.value : '';
    }

    return { value: startValue };
  };
  const initialState = createInitialState();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(initialState.value);

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  /* Utility Methods */

  const cancelBeforeStart = props.charPress && '1234567890.'.indexOf(props.charPress) < 0;

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      isCancelBeforeStart() {
        return cancelBeforeStart;
      },

      isCancelAfterEnd() {
        const numValue = Number(value);
        let cancel: boolean;

        switch (props.column.getColId()) {
          case 'softMerch':
          case 'hardMerch':
            cancel = 0 > numValue || 100 < numValue;
            break;

          default:
            cancel = value < 0;
            break;
        }

        return cancel;
      },
    };
  });

  return (
    <div className="editor-container">
      <StyledCurrencyInput
        ref={inputRef}
        suffix="%"
        defaultValue={value}
        decimalsLimit={2}
        onValueChange={(v) => setValue(v)}
      />
    </div>
  );
});
PercentageCellEditor.displayName = 'PercentageCellEditor';

export const StyledCurrencyInput = styled(CurrencyInput)`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  &:focus-visible {
    border: none;
    outline: none;
  }
`;
