import { FC } from 'react';
import BlankCell from '../BlankCell';

export type NumberRendererProps = {
  value?: number;
};

const NumberRenderer: FC<NumberRendererProps> = ({ value }) => (
  <>{value ? Number(value).toLocaleString() : <BlankCell />}</>
);

export default NumberRenderer;
