import clsx from 'clsx';
import { useFilters } from 'context/avails-filters/context';
import React, { FC, HTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type StickyContainerProps = PropsWithChildren<
  {
    renderFixedContainer?: () => React.JSX.Element;
  } & HTMLAttributes<HTMLDivElement>
>;

export const StickyHeaderPage: FC<StickyContainerProps> = ({ children, renderFixedContainer, className, ...props }) => {
  // Refs
  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  // States
  const [fixedContentHeight, setFixedContentHeight] = useState<number>(0);

  const { setHeaderCollapsed, collapsed } = useFilters();

  useEffect(() => {
    const handleScroll = () => {
      // prevent the scroll if `collapsed` is true
      if (collapsed) {
        return;
      }
      const content = containerRef.current;

      if (content) {
        const scrolledAmount = content.scrollTop;
        if (scrolledAmount > 25) {
          setHeaderCollapsed(true);
        } else {
          setHeaderCollapsed(false);
        }
      }
    };

    // Attach the scroll event listener
    const content = containerRef.current;
    if (content !== null) {
      content.addEventListener('scroll', handleScroll);
    }

    // Cleanup
    return () => {
      if (content !== null) {
        content.removeEventListener('scroll', handleScroll);
      }
    };
  }, [collapsed]);

  useEffect(() => {
    const fixedHeaderElement = document.querySelector('.fixed-header');

    const handleResize = () => {
      setFixedContentHeight(collapsed ? 180 : fixedHeaderElement?.clientHeight ?? 0);
    };

    handleResize();

    contentRef.current?.addEventListener('resize', handleResize);

    let mutation: MutationObserver;
    if (fixedHeaderElement) {
      mutation = new MutationObserver(handleResize);
      mutation.observe(fixedHeaderElement, { childList: true, subtree: true });
    }

    // Cleanup
    return () => {
      contentRef.current?.removeEventListener('resize', handleResize);
      mutation && mutation.disconnect();
    };
  }, [collapsed]);

  return (
    <div
      ref={containerRef}
      className={twMerge(
        clsx(
          'sticky-container flex flex-1 flex-col h-full w-full min-h-full max-h-screen overflow-auto print:max-h-none print:overflow-visible',
          className
        )
      )}
      {...props}
    >
      <div className="sticky top-0 z-20">
        <div ref={contentRef} className="fixed-header absolute top-0 left-0 flex flex-col w-full">
          {renderFixedContainer && renderFixedContainer()}
        </div>
      </div>
      <div className="flex flex-1 flex-col w-full h-full z-10" style={{ paddingTop: fixedContentHeight }}>
        {children}
      </div>
    </div>
  );
};
