import { convertCurrencyAndUpdateContractById, formatCurrencyWithSymbol } from 'deal-form/helpers/dealHelpers';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface ITotalsProps {
  id: string;
  label?: string | boolean;
  className?: string;
  itemClassName?: string;
  identifier: string;
  total: string;
  type?: 'number' | 'currency';
  currencyOverride?: string;
  multiplier?: number;
  convertTo?: string;
  convertFrom?: string;
  contractId?: string;
  exchangeRates?: any;
  dataCy?: string;
}

async function convertAndUpdate(amount: number, from: string, to: string, exchangeRates?: any, contractId?: string) {
  try {
    const value = await convertCurrencyAndUpdateContractById(amount, from, to, exchangeRates, contractId);
    return value;
  } catch (error) {
    console.error('Error:', error);
    return 0;
  }
}

const Totals = ({
  id,
  total,
  type = 'currency',
  currencyOverride = 'USD',
  className = '',
  itemClassName = '',
  label = 'Total',
  identifier = '',
  multiplier = 1,
  convertTo,
  convertFrom,
  contractId,
  exchangeRates,
  dataCy,
}: ITotalsProps) => {
  const [tallied, setTallied] = useState(0);
  const [currency, setCurrency] = useState('');
  const [totalFormatted, setTotalFormatted] = useState(0);
  const [totalFormattedConverted, setTotalFormattedConverted] = useState(0);

  const { getValues, control } = useFormContext();

  const watchAllFields = useWatch({ control, name: id });

  const setCurrencyFromInput = (inputCurrency: string) => {
    if (inputCurrency && inputCurrency !== '') {
      setCurrency(inputCurrency?.toUpperCase());
    } else {
      currencyOverride && setCurrency(currencyOverride);
    }
  };

  useEffect(() => {
    const vals = getValues(id);

    let result = 0;

    if (!Array.isArray(vals) && type === 'currency') {
      setCurrencyFromInput(vals?.currency);
      result = Number(vals[total]);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      vals.forEach((element: any) => {
        if (type === 'currency') {
          setCurrencyFromInput(element?.[total]?.currency);

          const updatedValue = element[total];
          const numberValue = updatedValue.value || updatedValue.value === 0 ? Number(updatedValue.value) : undefined;

          if (numberValue !== undefined) {
            result += numberValue === -1 ? 0 : numberValue;
          } else {
            result += +updatedValue === -1 ? 0 : +updatedValue;
          }
        } else {
          result += +element[total];
        }
      });
    }

    setTallied(result);
  }, [id, total, watchAllFields, currencyOverride]);

  useEffect(() => {
    multiplier && setTotalFormatted(multiplier * tallied);
  }, [tallied, multiplier]);

  useEffect(() => {
    if (convertFrom && convertTo) {
      convertAndUpdate(totalFormatted, convertFrom, convertTo, exchangeRates, contractId).then((value) =>
        setTotalFormattedConverted(value)
      );
    }
  }, [totalFormatted, convertFrom, convertTo, exchangeRates, contractId]);

  const totalFormattedCurrency = useMemo(() => {
    if (type === 'currency') {
      return formatCurrencyWithSymbol(totalFormatted, currency as string);
    }

    return totalFormatted;
  }, [currency, totalFormatted, type]);

  const totalFormattedCurrencyConverted = useMemo(() => {
    if (type === 'currency') {
      return formatCurrencyWithSymbol(totalFormattedConverted, convertTo as string);
    }

    return totalFormattedConverted;
  }, [convertTo, totalFormattedConverted, type]);

  return (
    <>
      <div className={`relative w-full flex flex-row ${className}`} data-cy={dataCy}>
        {label !== false && (
          <label className="inline-block mt-1.5 text-lg font-medium whitespace-nowrap self-end pr-2">{label}</label>
        )}
        <div className={`pl-4 ${itemClassName}`}>
          {identifier !== '' && (
            <div className="w-full text-greyCloudy text-xs -mb-1">{identifier || 'Amount Due'}</div>
          )}
          <div className="text-sm">{tallied ? totalFormattedCurrency : '--'}</div>
        </div>
        {!!totalFormattedConverted && (
          <div className={`pl-4 ${itemClassName}`}>
            {identifier !== '' && (
              <div className="w-full text-greyCloudy text-xs -mb-1">{identifier || 'Amount Due'} (Converted)</div>
            )}
            <div className="text-sm">{tallied ? totalFormattedCurrencyConverted : '--'}</div>
          </div>
        )}
      </div>
    </>
  );
};

Totals.defaultProps = {
  type: 'currency',
  currencyOverride: 'USD',
  className: '',
  itemClassName: '',
  label: 'Total',
  identifier: '',
  multiplier: 1,
};

export default Totals;
