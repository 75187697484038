import { useMachine } from '@xstate/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getContractByDealId, getDealClauses, showUserError } from 'api';
import { getClientData } from 'api/clients-routing';
import { getCalculationsByDealId } from 'api/contracts';
import EditDealProvider, { useEditDealContext } from 'context/editDealContext';
import { CATEGORY_NAMES } from 'deal-form/constants';
import { FORM_NAMES } from 'deal-form/data/constants';
import AdditionalClausesForm from 'deal-form/forms/AdditionalClausesForm';
import AdditionalProvisionsForm from 'deal-form/forms/AdditionalProvisionsForm';
import BalanceRemittanceForm from 'deal-form/forms/BalanceRemittanceForm';
import BillingForm from 'deal-form/forms/BillingForm';
import BuyerForm from 'deal-form/forms/BuyerForm';
import BuyoutsForm from 'deal-form/forms/BuyoutsForm';
import CalculatedValuesForm from 'deal-form/forms/CalculatedValuesForm';
import ClientForm from 'deal-form/forms/ClientForm';
import ContractInfoForm from 'deal-form/forms/ContractInfoForm';
import FinancialForm from 'deal-form/forms/FinancialForm';
import FixedExpensesForm from 'deal-form/forms/FixedExpensesForm';
import LinkedDealsForm from 'deal-form/forms/LinkedDealsForm';
import MerchandiseForm from 'deal-form/forms/MerchandiseForm';
import PaymentScheduleForm from 'deal-form/forms/PaymentScheduleForm';
import PaymentsOnClientBehalfForm from 'deal-form/forms/PaymentsOnClientBehalfForm';
import PaymentsToClientForm from 'deal-form/forms/PaymentsToClientForm';
import RadiusClauseForm from 'deal-form/forms/RadiusClauseForm';
import ScheduleInputForm from 'deal-form/forms/ScheduleInputForm';
import SettlementTotalsForm from 'deal-form/forms/SettlementTotalsForm';
import ShowContactsForm from 'deal-form/forms/ShowContactsForm';
import ShowDetailsForm from 'deal-form/forms/ShowDetailsForm';
import ShowLocationForm from 'deal-form/forms/ShowLocationForm';
import SplitDealsForm from 'deal-form/forms/SplitDealsForm';
import TermsOfTheDealForm from 'deal-form/forms/TermsOfTheDealForm';
import TicketCalculationsForm from 'deal-form/forms/TicketCalculationsForm';
import TicketFeesForm from 'deal-form/forms/TicketFeesForm';
import TicketScalingForm from 'deal-form/forms/TicketScalingForm';
import TicketTaxesForm from 'deal-form/forms/TicketTaxesForm';
import TicketScalingCombinedForm from 'deal-form/forms/TicketScalingCombinedForm';
import TravelAccommodationsForm from 'deal-form/forms/TravelAccommodationsForm';
import VariableExpensesForm from 'deal-form/forms/VariableExpensesForm';
import Organism from 'deal-form/layout/Organism';
import { Header } from 'features/deal-form';
import { StickyHeaderPage } from 'features/layouts';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useBeforeUnload } from 'react-use';
import { Clause, Contract, Deal } from 'types';
import { iCalculations } from 'types/deal';
import OverviewProvider from '../context/overviewContext';
import { contractMachine } from '../features/deal-form/form-state/contract-state';
import { isValidUrl } from 'utils/cloudinary';
import CypressTags from 'support/cypressTags';

const {
  SHOW_LOCATION,
  BUYER_SECTION,
  CLIENT_SECTION,
  CONTRACT_INFO,
  SHOW_DETAILS,
  TERMS_SECTION,
  SETTLEMENTS_SECTION,
  TICKET_SECTION,
  ACCOUNTING_SECTION,
  BILLING_INFO,
  BUYOUTS,
  PAYMENT_SCHEDULE,
  TICKET_CALCULATIONS,
  EXPENSES_SECTION,
  VARIABLE_EXPENSES,
  CALCULATED_VALUES_SECTION,
  LINKED_DEALS_SECTION,
  SPLIT_DEAL_SECTION,
} = CypressTags.DEAL_FORM;

const dealIdFromLocation = () => {
  const ID_INDEX = 4;
  return window.location.href.split('/')[ID_INDEX];
};

const BeforeUnloadContainer = () => {
  const { hasUnsavedChanged } = useEditDealContext();

  const dirtyFn = useCallback(() => {
    return hasUnsavedChanged();
  }, [hasUnsavedChanged]);

  useBeforeUnload(dirtyFn, 'You have unsaved changes, are you sure?');

  return null;
};

/**
 * Deal Editor
 */
export const DealForm = (): ReactElement => {
  const dealId = dealIdFromLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [formState, send] = useMachine(contractMachine);
  const [deal, setDeal] = useState<Deal>({} as Deal);
  const [calculations, setCalculations] = useState<iCalculations>({} as iCalculations);
  const [contract, setContract] = useState<Contract>({} as Contract);
  const [clauses, setClauses] = useState<Clause[] | null>(null);
  const [isLinkedDeal, setIsLinkedDeal] = useState<boolean>(false);
  const [isSplitDeal, setIsSplitDeal] = useState<boolean>(false);
  const [artistProfilePic, setArtistProfilePic] = useState<string | undefined>(undefined);
  const { combineTicketScaling } = useFlags();

  const fetchDeal = (_dealId: string) => {
    getContractByDealId(_dealId)
      .then((response: Contract) => {
        const contractDeal = response.deals.find((d) => d._id === _dealId);
        if (contractDeal) setDeal(contractDeal);
        setContract(response);
        setCalculations(response.calculations);
        send('INIT', { ...response });
      })
      .catch(showUserError);
  };

  const fetchCalculations = (_dealId: string) => {
    getCalculationsByDealId(_dealId)
      .then((response: iCalculations) => {
        setCalculations(response);
        //   send('INIT', { ...response });
      })
      .catch(showUserError);
  };

  const fetchClauses = async (clientId: string) => {
    await getDealClauses(clientId).then((response: Clause[]) => {
      setClauses(response);
    });
  };

  // check for tag in url on page reload
  useEffect(() => {
    const splitUrlByHashtag = window.location.href.split('#');
    // if there is a hashtag in the url, redirect to the url without the hashtag otherwise the page will not load
    if (splitUrlByHashtag[1]) {
      window.location.replace(splitUrlByHashtag[0]);
    }
  }, []);

  useEffect(() => {
    fetchCalculations(dealId);
    if (contract.masterDeal) {
      setIsLinkedDeal(contract.crossed !== 'Neither');

      const isSplit =
        contract.crossed === 'Crossed' && contract.masterDeal.dealSplits && contract.masterDeal.dealSplits?.length > 0;
      setIsSplitDeal(isSplit || false);
      if (contract.crossed === 'Non-Crossed' && deal.dealSplits && deal.dealSplits?.length > 0) {
        setIsSplitDeal(deal.dealSplits.length > 0);
      }
    } else {
      if (deal && deal.dealSplits) {
        setIsSplitDeal(deal.dealSplits.length > 0);
      }
    }
  }, [contract, deal]);

  // This useEffect is used to fetch the initial deal display when navigated from the routing table
  useEffect(() => {
    if (dealId) {
      fetchDeal(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (deal?.client?._id) {
      fetchClauses(deal.client._id);

      const getProfilePic = async () => {
        const data = await getClientData(deal.client._id);
        const artistImgUrl = (data && isValidUrl(data?.profile_pic || '') && data.profile_pic) || undefined;
        setArtistProfilePic(artistImgUrl);
      };
      getProfilePic();
    }
  }, [deal.client]);

  useEffect(() => {
    send('UPDATE', contract);
  }, [contract]);

  // ensures that the page has Contract, Deal, and Show information necessary for rendering.
  const hasDealAndContract =
    deal?.shows?.length > 0 && (contract.commission || contract.commission === 0) && clauses && clauses.length > 0;

  useEffect(() => {
    if (hasDealAndContract) {
      const hash = window.location.hash;
      if (hash) {
        const anchor = document.querySelector(hash);
        setTimeout(() => {
          anchor?.scrollIntoView(false);
        }, 100);
      }
    }
  }, [hasDealAndContract]);

  const dealLocked = contract.status === 'Settled';
  const contractLocked = contract.netsuiteStatus === 'Set';

  const renderFixedContainer = useCallback(
    () =>
      hasDealAndContract ? (
        <Header
          deal={deal}
          dealId={deal._id}
          shows={deal.shows}
          contract={contract}
          client={deal.client}
          setContract={setContract}
          isSplitDeal={isSplitDeal}
          setIsSplitDeal={setIsSplitDeal}
          artistProfilePic={artistProfilePic}
          dealType={deal.termTypes?.[0] || ''}
        />
      ) : (
        <></>
      ),
    [deal?._id, deal?.termTypes, deal?.client, deal?.shows, contract, isSplitDeal, artistProfilePic, hasDealAndContract]
  );

  return (
    <EditDealProvider>
      <OverviewProvider>
        <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
          {hasDealAndContract ? (
            <div>
              <BeforeUnloadContainer />
              <div className="relative z-0 gap-4 pb-10 content-wrapper pt-2">
                {isLinkedDeal && (
                  <Organism
                    label={FORM_NAMES.LINKED_DEALS}
                    categories={[CATEGORY_NAMES.contract]}
                    dataCy={LINKED_DEALS_SECTION.TITLE}
                  >
                    <LinkedDealsForm deal={deal} locked={dealLocked} contract={contract} setContract={setContract} />
                  </Organism>
                )}

                <Organism
                  label={FORM_NAMES.CLIENT}
                  categories={[CATEGORY_NAMES.contract]}
                  dataCy={CLIENT_SECTION.TITLE}
                >
                  <ClientForm
                    deal={deal}
                    locked={dealLocked || contractLocked}
                    contract={contract}
                    setDeal={setDeal}
                    setContract={setContract}
                  />
                </Organism>

                <Organism
                  label={FORM_NAMES.FINANCIAL}
                  categories={[CATEGORY_NAMES.deal]}
                  dataCy={ACCOUNTING_SECTION.TITLE}
                >
                  <FinancialForm
                    deal={deal}
                    contract={contract}
                    setContract={setContract}
                    locked={dealLocked || contractLocked}
                  />
                </Organism>

                <Organism
                  label={FORM_NAMES.CONTRACT_INFO}
                  categories={[CATEGORY_NAMES.contract]}
                  dataCy={CONTRACT_INFO.TITLE}
                >
                  <ContractInfoForm deal={deal} locked={dealLocked} contract={contract} setContract={setContract} />
                </Organism>

                <Organism
                  label={FORM_NAMES.SHOW_LOCATION}
                  categories={[CATEGORY_NAMES.show]}
                  dataCy={SHOW_LOCATION.TITLE}
                >
                  <ShowLocationForm
                    deal={deal}
                    locked={dealLocked}
                    setDeal={setDeal}
                    contract={contract}
                    setContract={setContract}
                  />
                </Organism>

                <Organism
                  label={FORM_NAMES.SHOW_DETAILS}
                  categories={[CATEGORY_NAMES.show, CATEGORY_NAMES.deal]}
                  dataCy={SHOW_DETAILS.TITLE}
                >
                  <ShowDetailsForm
                    deal={deal}
                    setDeal={setDeal}
                    locked={dealLocked}
                    contract={contract}
                    setContract={setContract}
                  />
                </Organism>

                <Organism
                  label={FORM_NAMES.BILLING}
                  categories={[CATEGORY_NAMES.deal, CATEGORY_NAMES.show]}
                  dataCy={BILLING_INFO.TITLE}
                >
                  <BillingForm deal={deal} locked={dealLocked || contractLocked} setDeal={setDeal} />
                </Organism>

                <Organism label={FORM_NAMES.SCHEDULE} categories={[CATEGORY_NAMES.show]}>
                  <ScheduleInputForm deal={deal} locked={dealLocked} setDeal={setDeal} contract={contract} />
                </Organism>

                <Organism
                  label={FORM_NAMES.TERMS_OF_THE_DEAL}
                  categories={[CATEGORY_NAMES.deal, CATEGORY_NAMES.show]}
                  dataCy={TERMS_SECTION.TITLE}
                >
                  <TermsOfTheDealForm
                    deal={deal}
                    setDeal={setDeal}
                    contract={contract}
                    setContract={setContract}
                    locked={dealLocked}
                    contractLocked={contractLocked}
                  />
                </Organism>

                {isSplitDeal && (
                  <Organism
                    label={FORM_NAMES.SPLIT_DEAL}
                    categories={[CATEGORY_NAMES.contract]}
                    dataCy={SPLIT_DEAL_SECTION.TITLE}
                  >
                    <SplitDealsForm
                      deal={deal}
                      setDeal={setDeal}
                      contract={contract}
                      setContract={setContract}
                      locked={dealLocked}
                    />
                  </Organism>
                )}

                <Organism label={FORM_NAMES.BUYOUTS} categories={[CATEGORY_NAMES.deal]} dataCy={BUYOUTS.TITLE}>
                  <BuyoutsForm
                    deal={deal}
                    setDeal={setDeal}
                    calculations={calculations}
                    locked={dealLocked}
                    contract={contract}
                  />
                </Organism>

                <Organism
                  label={FORM_NAMES.PAYMENT_SCHEDULE}
                  categories={[CATEGORY_NAMES.deal]}
                  dataCy={PAYMENT_SCHEDULE.TITLE}
                >
                  <PaymentScheduleForm
                    deal={deal}
                    setDeal={setDeal}
                    calculations={calculations}
                    locked={dealLocked}
                    isSplitDeal={isSplitDeal}
                  />
                </Organism>

                <Organism label={FORM_NAMES.TRAVEL_ACCOMMODATIONS} categories={[CATEGORY_NAMES.show]}>
                  <TravelAccommodationsForm
                    deal={deal}
                    setDeal={setDeal}
                    contract={contract}
                    setContract={setContract}
                    locked={dealLocked}
                  />
                </Organism>

                <Organism label={FORM_NAMES.MERCHANDISE} categories={[CATEGORY_NAMES.show]}>
                  <MerchandiseForm deal={deal} setDeal={setDeal} locked={dealLocked} />
                </Organism>

                <Organism
                  label={FORM_NAMES.RADIUS_CLAUSE}
                  categories={[CATEGORY_NAMES.deal, CATEGORY_NAMES.show]}
                  // tooltipInfo="Some information on hover TBD"
                >
                  <RadiusClauseForm deal={deal} setDeal={setDeal} locked={dealLocked} />
                </Organism>

                {combineTicketScaling && (
                  <Organism
                    label={FORM_NAMES.TICKET_SCALING}
                    categories={[CATEGORY_NAMES.show]}
                    dataCy={TICKET_SECTION.TITLE}
                  >
                    <TicketScalingCombinedForm deal={deal} locked={dealLocked} setDeal={setDeal} contract={contract} />
                  </Organism>
                )}

                {!combineTicketScaling && (
                  <Organism
                    label={FORM_NAMES.TICKET_SCALING}
                    categories={[CATEGORY_NAMES.show]}
                    dataCy={TICKET_SECTION.TITLE}
                  >
                    <TicketScalingForm deal={deal} locked={dealLocked} setDeal={setDeal} />
                  </Organism>
                )}

                {!combineTicketScaling && (
                  <Organism label={FORM_NAMES.TICKET_FEES} categories={[CATEGORY_NAMES.show]}>
                    <TicketFeesForm deal={deal} locked={dealLocked} setDeal={setDeal} />
                  </Organism>
                )}

                {!combineTicketScaling && (
                  <Organism label={FORM_NAMES.TICKET_TAXES} categories={[CATEGORY_NAMES.show]}>
                    <TicketTaxesForm deal={deal} setDeal={setDeal} locked={dealLocked} />
                  </Organism>
                )}

                <Organism
                  label={FORM_NAMES.TICKET_CALCULATIONS}
                  categories={[CATEGORY_NAMES.contract, CATEGORY_NAMES.deal, CATEGORY_NAMES.show]}
                  displaySaveStatus={false}
                  dataCy={TICKET_CALCULATIONS.TITLE}
                >
                  <TicketCalculationsForm deal={deal} locked={dealLocked} contract={contract} />
                </Organism>

                <Organism
                  label={FORM_NAMES.FIXED_EXPENSES}
                  categories={[CATEGORY_NAMES.deal, CATEGORY_NAMES.show]}
                  dataCy={EXPENSES_SECTION.TITLE}
                >
                  <FixedExpensesForm
                    deal={deal}
                    locked={dealLocked}
                    setDeal={setDeal}
                    calculations={calculations}
                    contract={contract}
                  />
                </Organism>

                <Organism
                  label={FORM_NAMES.VARIABLE_EXPENSES}
                  categories={[CATEGORY_NAMES.deal, CATEGORY_NAMES.show]}
                  dataCy={VARIABLE_EXPENSES.TITLE}
                >
                  <VariableExpensesForm
                    deal={deal}
                    locked={dealLocked}
                    setDeal={setDeal}
                    calculations={calculations}
                    contract={contract}
                  />
                </Organism>

                <Organism
                  label={FORM_NAMES.CALCULATED_VALUES}
                  categories={[CATEGORY_NAMES.contract, CATEGORY_NAMES.deal]}
                  dataCy={CALCULATED_VALUES_SECTION.TITLE}
                >
                  <CalculatedValuesForm deal={deal} setDeal={setDeal} calculations={calculations} locked={dealLocked} />
                </Organism>

                <Organism label={FORM_NAMES.ADDITIONAL_PROVISIONS} categories={[CATEGORY_NAMES.deal]}>
                  <AdditionalProvisionsForm
                    deal={deal}
                    locked={dealLocked}
                    setDeal={setDeal}
                    contract={contract}
                    setContract={setContract}
                  />
                </Organism>

                {clauses && (
                  <Organism label={FORM_NAMES.ADDITIONAL_CLAUSES} categories={[CATEGORY_NAMES.deal]}>
                    <AdditionalClausesForm
                      deal={deal}
                      locked={dealLocked}
                      clauses={clauses}
                      setDeal={setDeal}
                      contract={contract}
                      setContract={setContract}
                    />
                  </Organism>
                )}

                <Organism label={FORM_NAMES.BUYER} categories={[CATEGORY_NAMES.deal]} dataCy={BUYER_SECTION.TITLE}>
                  <BuyerForm
                    deal={deal}
                    locked={dealLocked || contractLocked}
                    setDeal={setDeal}
                    contract={contract}
                    setContract={setContract}
                  />
                </Organism>

                <Organism label={FORM_NAMES.SHOW_CONTACTS} categories={[CATEGORY_NAMES.show]}>
                  <ShowContactsForm deal={deal} setDeal={setDeal} locked={dealLocked} />
                </Organism>

                <Organism
                  label={FORM_NAMES.SETTLEMENT_TOTALS}
                  categories={[CATEGORY_NAMES.deal]}
                  dataCy={SETTLEMENTS_SECTION.TOTALS.TITLE}
                >
                  <SettlementTotalsForm
                    deal={deal}
                    setDeal={setDeal}
                    locked={dealLocked}
                    calculations={calculations}
                    contract={contract}
                    setContract={setContract}
                  />
                </Organism>

                <Organism label={FORM_NAMES.BALANCE_REMITTANCE} categories={[CATEGORY_NAMES.deal, CATEGORY_NAMES.show]}>
                  <BalanceRemittanceForm
                    deal={deal}
                    locked={dealLocked}
                    setDeal={setDeal}
                    contract={contract}
                    setContract={setContract}
                  />
                </Organism>

                <Organism label={FORM_NAMES.PAYMENTS_TO_CLIENT} categories={[CATEGORY_NAMES.deal]}>
                  <PaymentsToClientForm deal={deal} setDeal={setDeal} locked={dealLocked} contract={contract} />
                </Organism>

                <Organism label={FORM_NAMES.PAYMENTS_ON_CLIENTS_BEHALF} categories={[CATEGORY_NAMES.deal]}>
                  <PaymentsOnClientBehalfForm deal={deal} setDeal={setDeal} locked={dealLocked} contract={contract} />
                </Organism>
              </div>
            </div>
          ) : (
            <div className="w-full h-[100vh] flex justify-center items-center">
              <p>Loading...</p>
            </div>
          )}
        </StickyHeaderPage>
      </OverviewProvider>
    </EditDealProvider>
  );
};
