import { StickyHeaderPage } from 'features/layouts';
import { PageHeader, AlgoliaClientSide } from 'grid-pages/components';
import { useCallback } from 'react';

const DealsTestAlgolia = () => {
  const handleOnExport = () => {
    console.log('export');
  };

  const renderFixedContainer = useCallback(
    () => <PageHeader onExport={handleOnExport} title="Algolia Client Side Test" />,
    []
  );

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="grid-height content-wrapper mt-10">
        <AlgoliaClientSide />
      </div>
    </StickyHeaderPage>
  );
};

export default DealsTestAlgolia;
