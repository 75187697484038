import clsx from 'clsx';
import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { Badge } from '@united-talent-agency/onyx-components';
import { twMerge } from 'tailwind-merge';
import { AvailsShowStatus } from 'types/Show';
import { getStatus } from 'containers/availability/helpers';
import { ShowTableDataType } from '../types';
import { useFilters } from 'context/avails-filters/context';
import { getDateFormatByBrowser, toLocaleDateString } from 'utils/helpers';
import moment from 'moment';

export const getResponsibleAgentName = (agents: ShowTableDataType['agents']) => {
  const agent = agents?.find((ag: any) => ag?.type?.toString().toLowerCase() === 'Responsible Agent');
  if (agent) {
    return agent?.person?.name?.toString() ?? '';
  } else {
    return agents?.[0]?.person?.name?.toString() ?? '';
  }
};

export const getRadiusClause = (
  date: Date,
  rule: { radius: number | string; timeBefore: number | string; timeAfter: number | string; text?: string }
) => {
  if (!rule?.radius && !rule?.timeBefore && !rule?.timeAfter) {
    return [''];
  }

  const dateBefore = new Date(date).getTime() - Number(rule.timeBefore) * 24 * 60 * 60 * 1000;
  const dateAfter = new Date(date).getTime() + Number(rule.timeAfter) * 24 * 60 * 60 * 1000;

  // format the dates
  const dateBeforeFormatted = toLocaleDateString(dateBefore);

  const dateAfterFormatted = toLocaleDateString(dateAfter);

  return [`${dateBeforeFormatted} - ${dateAfterFormatted}`, `Within ${rule.radius} miles`];
};

const displayFormat = getDateFormatByBrowser();

export const getCellValues = (row: ShowTableDataType, date: Date): any[] => [
  moment(row?.show?.date).format(displayFormat),
  // toLocaleDateString(row?.show?.date),
  row?.client?.name,
  getResponsibleAgentName(row?.agents) ?? '',
  row?.venue?.name ?? '',
  row?.venue?.address?.address ?? '',
  row?.deal?.number?.toString(),
  '', // the status is filled by a separate logic
  row?.show?.rule ? getRadiusClause(date, row?.show.rule) : ['', ''],
  row.proximity?.toString() ?? '',
  row?.show?.notes ?? '',
];

export const getCellValuesAsString = (row: ShowTableDataType, date: Date): string[] =>
  getCellValues(row, date)?.map((val) => {
    if (val === undefined) {
      return '';
    }

    return Array.isArray(val) ? val.join('') : val;
  });

export type BodyCellContentProps = PropsWithChildren<{
  data?: any;
  columnIndex?: number;
  column?: any;
  rowIndex?: number;
}>;

const CellContent: FC<BodyCellContentProps> = ({ data, columnIndex }) => {
  const { date } = useFilters();
  const rowData = data?.data[0].data[0];
  const errorClasses = {
    'text-[#E95344]': !!data?.clauseViolationData,
  };

  useEffect(() => {}, [data]);

  const getStatusData = useCallback(() => {
    return getStatus(rowData?.show?.status as AvailsShowStatus);
  }, [data]);

  const statusColor = getStatusData()?.statusColor ?? '#fff';
  const statusBg = getStatusData()?.statusBg ?? '#000';
  const statusLabel = getStatusData()?.statusLabel ?? 'Unknown';
  const statusText = getStatusData()?.statusText ?? 'U';

  const getLabel = useCallback(
    (row: ShowTableDataType) => {
      const labels = getCellValues(row, date);

      return columnIndex !== undefined && columnIndex < labels.length ? labels[columnIndex] : '';
    },
    [getRadiusClause, columnIndex]
  );

  const ShowStatus = () => {
    return (
      <div>
        <Badge text={statusText} size="md" color={statusColor} bgColor={statusBg} className="[&&]:w-10" />{' '}
        <span className="text-sm">{statusLabel}</span>
      </div>
    );
  };

  const Cell = useCallback(
    () => (
      <div className="cell-container flex flex-1 flex-col gap-1 text-left">
        {columnIndex === 6 ? (
          <ShowStatus />
        ) : columnIndex === 7 ? (
          <div className="flex flex-col gap-1">
            <span className="text-sm">{getLabel(rowData ?? ({} as ShowTableDataType))?.[0]}</span>
            <span className="text-sm">{getLabel(rowData ?? ({} as ShowTableDataType))?.[1]}</span>
          </div>
        ) : (
          <span className="text-sm">{getLabel(rowData ?? ({} as ShowTableDataType))}</span>
        )}
      </div>
    ),
    [getLabel]
  );

  return (
    <div className={twMerge(clsx('flex flex-1 flex-col justify-center px-5', errorClasses))}>
      <Cell />
    </div>
  );
};

export default CellContent;
