import { handleUpdateDealShowsResponse, iUpdateShowPayload, updateDealShows } from 'api/shows';
import { SEARCH_TYPE } from 'deal-form/constants';
import { FORM_NAMES } from 'deal-form/data/constants';
import {
  SHOW_CONTACTS_CONTACT_TYPES,
  SHOW_CONTACTS_EMPTY_FORM,
  SHOW_CONTACTS_EMPTY_ITEM,
  SHOW_CUSTOM_CONTACTS_EMPTY_ITEM,
} from 'deal-form/data/organisms';
import { DropdownWithSearch } from 'deal-form/form-controls/DropdownWithSearch';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismShowContact, iOrganismShowContacts } from 'deal-form/interfaces/organisms';
import React, { useMemo, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Contact, Deal, Person } from 'types';

import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { getMasterShow } from '../../helpers/showHelpers';

export const COLUMN_LABELS = [{ value: 'Type' }, { value: 'Name' }];
export const GRID_LAYOUT = 'grid grid-cols-2 gap-2 ';

const getContacts = (d: Deal) => {
  const contacts: iOrganismShowContact[] = [];
  const customContacts: iOrganismShowContact[] = [];
  const masterShow = getMasterShow(d.shows);

  masterShow.contacts.forEach((contact) => {
    if (contact?.name) {
      customContacts.push({
        type: contact.type,
        name: contact.name,
      });
    }
    if (contact?.person) {
      contacts.push({
        type: contact.type,
        person: {
          name: contact?.person?.name,
          data: contact?.person,
        },
      });
    }
  });
  return { contacts, customContacts };
};

const ShowContactsForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked }) => {
  const [canSubmit, setCanSubmit] = useState(true);

  const onSubmit = async (data: iOrganismShowContacts) => {
    const updateData: iUpdateShowPayload[] = [];
    const mappedContacts: Contact[] = [];

    // grab and validate contacts to save
    if (data.contacts.length > 0) {
      data.contacts.forEach(({ type, person }) => {
        if (type && person && person.data) {
          const p: Person = person.data;
          mappedContacts.push({ type, person: p });
        }
      });
    }

    if (data.customContacts.length > 0) {
      data.customContacts.forEach(({ type, name }) => {
        if (type && name) {
          mappedContacts.push({ type, name });
        }
      });
    }

    // add the contacts to all the shows
    deal.shows.forEach((s) => {
      const updatedShow = {
        showId: s._id,
        updateBody: {
          contacts: mappedContacts || [],
        },
      };
      updateData.push(updatedShow);
    });

    // submit the change
    try {
      setCanSubmit(false);
      const response = await updateDealShows({
        dealId: deal._id.toString(),
        updates: updateData,
      });

      if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });
      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR SAVING SHOW CONTACTS DATA', e);
      setCanSubmit(true);
    }
  };

  const defaultValues = useMemo(() => {
    const { contacts = [], customContacts = [] } = getContacts(deal);

    if (contacts || customContacts) {
      return {
        contacts,
        customContacts,
      };
    }

    console.log('empty');

    return SHOW_CONTACTS_EMPTY_FORM;
  }, [deal]);

  const handleChangeName = (chosen: iOptions | null, setValueMethod: UseFormSetValue<FieldValues>, index?: number) => {
    setValueMethod(`contacts.${index}.person.name`, chosen?.data.name);
    setValueMethod(`contacts.${index}.person.data`, chosen?.data);
    // if (showLocationFormData !== null)
    //   setShowLocationFormData({ ...showLocationFormData, address: chosen?.data.address });
  };

  return (
    <Form
      canSubmit={canSubmit}
      disabled={!isOpen || locked}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      formName={FORM_NAMES.SHOW_CONTACTS}
    >
      <FieldArray
        className="col-span-2"
        gridClassName={GRID_LAYOUT}
        groupName="contacts"
        columns={COLUMN_LABELS}
        emptyRow={SHOW_CONTACTS_EMPTY_ITEM}
        disabled={!isOpen || locked}
        addButtonLabel="Add Contact"
      >
        <Dropdown
          label={false}
          placeholder="Type"
          options={SHOW_CONTACTS_CONTACT_TYPES}
          id="type"
          disabled={!isOpen || locked}
          rules={{ required: 'Please select a valid ‘Contact Type’' }}
        />

        <DropdownWithSearch
          placeholder="Name"
          label={false}
          id="person.name"
          secondaryId="contacts"
          searchType={SEARCH_TYPE.CONTACT}
          handleChange={handleChangeName}
          disabled={!isOpen || locked}
          isRequired={true}
          rules={{ required: 'Please select a valid ‘Contact Name’' }}
        />
      </FieldArray>

      <div className="border-t border-greyMineShaft pt-6">
        <FieldArray
          className="col-span-2"
          gridClassName={GRID_LAYOUT}
          groupName="customContacts"
          emptyRow={SHOW_CUSTOM_CONTACTS_EMPTY_ITEM}
          disabled={!isOpen || locked}
          addButtonLabel="Add Custom Contact"
        >
          <Input
            label={false}
            placeholder="Type"
            rules={{
              required: 'Please enter a valid ‘Contact Type’',
              minLength: {
                value: 3,
                message: 'A ‘Contact Type’ must be at least 3 characters long',
              },
            }}
            id="type"
            disabled={!isOpen || locked}
          />
          <Input
            label={false}
            placeholder="Name"
            rules={{
              required: 'Please enter a valid ‘Contact Name’',
              minLength: {
                value: 3,
                message: 'A ‘Contact Name’ must be at least 3 characters long',
              },
            }}
            id="name"
            disabled={!isOpen || locked}
          />
        </FieldArray>
      </div>
    </Form>
  );
};

export default ShowContactsForm;
