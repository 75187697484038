import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { ReactElement, ReactNode, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

export interface IdString {
  id: string | number;
  label: string;
}
interface DropdownProps {
  label: string;
  items?: IdString[];
  propsStyles?: string;
  listStyles?: string;
  onActionClick: (props: any) => void;
  onChange: (chosen: IdString) => void;
  value?: IdString | null;
  actionSlot: ReactNode;
}

export const DropdownWithAction = ({
  label,
  items,
  propsStyles,
  listStyles,
  onActionClick,
  value,
  onChange,
  actionSlot,
}: DropdownProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsOpen(false);
  });

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const _onClick = () => {
    toggleOpen();
  };

  return (
    <div
      className={`w-full border border-solid border-greyTapa px-4 py-2 select-none relative text-sm ${
        isOpen ? 'active' : ''
      }
      after:h-0 after:absolute after:w-0 after:top-1/2 after:right-3 ${propsStyles ? propsStyles : 'bg-white'}`}
      onClick={_onClick}
    >
      <div className="flex flex-row items-center justify-between cursor-pointer">
        {value ? value.label : label}{' '}
        <ChevronDownIcon width={15} className={`transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </div>
      <ul
        ref={ref}
        className={`border border-solid border-greyTapa list-none m-0 p-0 absolute top-full -left-[1px] -right-[1px] transition-opacity duration-300 max-h-[300px] bg-inherit shadow-header z-10 ${
          isOpen ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'
        } ${listStyles ? listStyles : ''} `}
      >
        {items?.length && items.length > 0 ? (
          items?.map((item) => {
            const isChosen = item.id === value?.id;
            return (
              <li key={item.id} className="group/deleteItem transition-bg duration-200 flex hover:bg-whiteWildSand ">
                <button className="text-left px-4 py-2 flex-1 flex cursor-pointer" onClick={() => onChange(item)}>
                  {isChosen && <CheckIcon width={18} className="mr-1" />}
                  {item.label}
                </button>
                {actionSlot && onActionClick && (
                  <button
                    onClick={() => onActionClick(item)}
                    className="transition-opacity duration-200 pl-2 pr-4 opacity-0 group-hover/deleteItem:opacity-100"
                  >
                    {actionSlot}
                  </button>
                )}
              </li>
            );
          })
        ) : (
          <li className="flex ">
            <span className="text-left px-4 py-2">No saved reports</span>
          </li>
        )}
      </ul>
    </div>
  );
};
