import { ContractSignerBlock } from 'types/company';

import { get } from './index';

/**
 * Find Business entities related to a client
 * @param clientId - string to be searched by
 */
export const getSignerBlocks = async (clientId: string): Promise<ContractSignerBlock[]> => {
  if (!clientId) {
    return [];
  }
  const response = await get(`/touring/clients/${clientId}/getContractSignerBlocks`);
  return response.body;
};
