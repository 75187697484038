/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowRightIcon, CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useEditDealContext } from 'context/editDealContext';
import { FORM_NAMES } from 'deal-form/data/constants';
import { getMessagesFromErrorObj } from 'deal-form/helpers/formHelpers';
import React, { FormHTMLAttributes, ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'components/Button';

import { ORGANISM_STATUS } from '../../constants';
import { getOrganismStatus } from '../../helpers/formHelpers';
import cypressTags from 'support/cypressTags';

interface iFormProps extends FormHTMLAttributes<HTMLFormElement> {
  defaultValues: any;
  children: ReactElement | ReactElement[];
  onSubmit: (data: any) => void;
  className?: string;
  isSaveButton?: boolean;
  canSubmit?: boolean;
  disabled?: boolean;
  isFormSubmit?: boolean;
  formName?: FORM_NAMES;
  mode?: 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all';
}

export const Form = ({
  defaultValues,
  children,
  onSubmit,
  className,
  isSaveButton,
  disabled,
  formName,
  canSubmit,
  mode,
  ...props
}: iFormProps): ReactElement => {
  const methods = useForm({ defaultValues, mode, reValidateMode: 'onChange' });
  const {
    handleSubmit,
    reset,
    formState,
    formState: { isDirty, isValid },
  } = methods;

  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const { updateOrganismDirty, updateOrganismValid } = useEditDealContext();

  useEffect(() => {
    // TODO: is this something that we ALWAYS want to do? if forces reset if the deal changes
    Object.keys(defaultValues).forEach((key) => {
      if (!Array.isArray(defaultValues[key]) && typeof defaultValues[key] === 'object') {
        if (defaultValues[key].value !== undefined && defaultValues[key].currency !== undefined) {
          defaultValues[key].value = Number(defaultValues[key].value).toFixed(2);
        }
      }
    });
    reset(defaultValues);
  }, [defaultValues]);

  // TODO: store errors in context
  const errors = formName ? getMessagesFromErrorObj(formState.errors) : [];

  useEffect(() => {
    if (formName === undefined) return;
    updateOrganismDirty(formName, isDirty);
    updateOrganismValid(formName, isValid);

    const organismState = getOrganismStatus(isDirty, isValid);
    setIsSaveDisabled(
      organismState === ORGANISM_STATUS.UNABLE_TO_SAVE || organismState === ORGANISM_STATUS.SUCCESSFULLY_SAVED
    );
  }, [isDirty, isValid]);

  const getIcon = (side: string) => {
    let icon;
    const organismState = getOrganismStatus(isDirty, isValid);

    if (side === 'left') {
      if (organismState === ORGANISM_STATUS.SUCCESSFULLY_SAVED) {
        icon = <CheckIcon width={24} height={24} className="text-sushi" />;
      } else if (organismState === ORGANISM_STATUS.UNABLE_TO_SAVE) {
        icon = <ExclamationCircleIcon width={24} height={24} className="text-cinnabar" />;
      }
    } else if (side === 'right' && organismState === ORGANISM_STATUS.UNSAVED_CHANGES) {
      icon = <ArrowRightIcon width={16} height={16} />;
    }
    return icon;
  };
  const getLabel = () => {
    let label = 'Save';
    const organismState = getOrganismStatus(isDirty, isValid);

    if (organismState === ORGANISM_STATUS.SUCCESSFULLY_SAVED) {
      label = 'Saved';
    } else if (organismState === ORGANISM_STATUS.UNABLE_TO_SAVE) {
      label = 'Unable To Save';
    }

    return label;
  };

  return (
    <FormProvider {...methods}>
      <form
        {...props}
        onSubmit={(e) => {
          e.preventDefault();
        }}
        name={formName}
        className={className}
        data-testid="form-testid"
      >
        {React.Children.map(children, (child) => {
          return child.props.name
            ? React.createElement(child.type, {
                ...{
                  ...child.props,
                  register: methods.register,
                  key: child.props.name,
                },
              })
            : child;
        })}
        {isSaveButton && !disabled && (
          <Button
            className={`col-span-full ml-auto mr-2 border border-black disabled:opacity-100 ${
              getOrganismStatus(isDirty, isValid) === ORGANISM_STATUS.UNSAVED_CHANGES && 'bg-black text-white'
            }`}
            onClick={handleSubmit(onSubmit)}
            variant="primary"
            leftIcon={getIcon('left')}
            rightIcon={getIcon('right')}
            disabled={isSaveDisabled || !canSubmit}
            data-testid="submit-btn-testid"
            dataCy={`${formName}-${cypressTags.DEAL_FORM.SUBMIT_BUTTON}`}
          >
            {getLabel()}
          </Button>
        )}

        {errors.length ? (
          <div className="text-cinnabar text-xs font-bold mt-3 col-span-full">
            <p className="mb-2">Errors:</p>
            <ul>
              {errors.map((message: string, index: number) => (
                <li key={`${message}-${index}`}>{message}</li>
              ))}
            </ul>
          </div>
        ) : null}
      </form>
    </FormProvider>
  );
};

Form.defaultProps = {
  className: '',
  isSaveButton: true,
  disabled: false,
  canSubmit: true,
  mode: 'onBlur',
};
