import { iOptions } from 'deal-form/interfaces/general';
import {
  iOrganismBalanceRemittal,
  iOrganismBilling,
  iOrganismBuyouts,
  iOrganismCalculatedValues,
  iOrganismClauses,
  iOrganismClientPayments,
  iOrganismClientsBehalfPayments,
  iOrganismDealTerms,
  iOrganismFinancials,
  iOrganismFixedExpenses,
  iOrganismLinkedDeals,
  iOrganismMerchandise,
  iOrganismPaymentSchedule,
  iOrganismProvisions,
  iOrganismSchedule,
  iOrganismScheduleEvent,
  iOrganismScheduleShow,
  iOrganismSettlementFinals,
  iOrganismSettlementTotals,
  iOrganismShowContacts,
  iOrganismShowDetails,
  iOrganismShowDetailsItem,
  iOrganismShowLocation,
  iOrganismSplitDeal,
  iOrganismSplitDealItem,
  iOrganismTicketFees,
  iOrganismTicketScaling,
  iOrganismTicketScalingTier,
  iOrganismTicketTaxes,
  iOrganismTravelAccommodations,
  iOrganismVariableExpenses,
  iOrganismsSchedule,
} from 'deal-form/interfaces/organisms';

/*********************
  LINKED DEALS
/*********************/
export const LINKED_DEAL_EMPTY_FORM: iOrganismLinkedDeals = {
  tourDealTerms: '',
  additionalProvisions: '',
  travelAccommodations: '',
};

/*********************
  CLIENT
/*********************/
export const CLIENT_AGENT_EMPTY_ITEM = {
  agentType: '',
  agentName: '',
};

export const CLIENT_EMPTY_FORM = {
  client: '',
  artistBusinessName: '',
  artistTaxId: '',
  companyAddress: '',
  commission: 0,
  contractAdministrator: {
    name: '',
    _id: '',
  },
  paperingAgent: '',
  responsibleAgent: [],
  bookingAgent: {
    name: '',
    _id: '',
  },
  touringTeam: [],
  additionalBookingAgents: [],
};

/*********************
  END CLIENT
/*********************/

/*********************
  FINANCIALS
/*********************/
export const FINANCIALS_EMPTY_FORM: iOrganismFinancials = {
  location: 'Los Angeles Office',
  subsidiary: 'United Talent Agency, LLC',
  lineOfBusiness: 'Music',
  department: 'Music',
};

export const FINANCIALS_LOCATION_OPTIONS = [
  'Los Angeles Office',
  'New York Office',
  'Nashville Office',
  'Scandinavia Office',
  'London Office',
  'Atlanta Office',
].map((o) => ({ label: o, id: o }));

export const FINANCIALS_BUSINESS_OPTIONS: iOptions[] = [
  'Music',
  'Music - Circle',
  'Music - Echo',
  'Talent Representation',
].map((o) => ({
  label: o,
  id: o,
}));

export const FINANCIALS_SUBSIDIARY_OPTIONS: iOptions[] = [
  'United Talent Agency, LLC',
  'United Talent Agency UK Operations Limited',
  'UTA Scandinavia AB',
].map((o) => ({
  label: o,
  id: o,
}));

export const FINANCIALS_DEPARTMENT_OPTIONS: iOptions[] = [
  'Music',
  'Comedy and Touring',
  'Music Brand Partnerships',
].map((o) => ({
  label: o,
  id: o,
}));
/*********************
  END FINANCIALS
/*********************/

/*********************
  BALANCE REMITTANCE
/*********************/
export const BALANCE_REMITTAL_EMPTY_FORM: iOrganismBalanceRemittal = {
  remittanceName: '',
  remittanceText: '',
};

/*********************
  BUYER
/*********************/
export const BUYER_EMPTY_FORM_ITEM = {
  buyerCompany: {
    name: '',
    data: null,
  },
  buyerName: { name: '', data: null },
};
export const BUYER_EMPTY_FORM = {
  buyers: [BUYER_EMPTY_FORM_ITEM],
};
/*********************
  END BUYER
/*********************/

/*********************
  BILLING
/*********************/
export const BILLING_TYPES: iOptions[] = [
  'Headline',
  'Co-Headline',
  'Special Guest',
  'Festival',
  'Private Show',
  'Headline Speaker',
  'Guest Speaker',
  'Moderator',
  'Moderated Conversation',
  'Equal Festival',
  'Support',
  'Direct Support',
  'An Evening With...',
  'Closed Show',
  'Host / Emcee',
  'Showcase',
  'Fundraiser',
  'After-Party',
  'To Be Approved by United Talent Agency',
  'DJ Set',
  'Festival Headline',
  'Stage Headline',
].map((o) => ({ label: o, id: o }));

export const EMPTY_BILLING_FORM: iOrganismBilling = {
  includeInDeal: true,
  billingPercent: 0,
  billingType: '',
  notes: '',
};
/*********************
  END BILLING
/*********************/

/*********************
  CONTRACT INFO
/*********************/

export const CONTRACT_TEMPLATE_TYPES: iOptions[] = [
  'Comedy',
  'Music NYC',
  'Music LA',
  'Music Nashville',
  'Music London',
  'Music Malmo',
  'Music Nashville Union',
  'LaPolt Law LA',
  'LaPolt Law NYC',
  'Virtual LA',
  'Virtual Nashville',
  'Virtual NYC',
  'deadmau5',
  'Live Nation - LONDON',
  'Live Nation - MALMO',
  'Post Malone - North & South America',
  'Post Malone - Rest of World',
  'San Holo',
].map((option) => ({
  label: option,
  id: option,
}));

export const CONTRACT_STATUSES: iOptions[] = [
  'Pending',
  'Rejected',
  'Confirmed',
  'Agent Review',
  'Ready to Issue',
  'Contract Issued',
  'Buyer Signed',
  'Markup Review',
  'To Client',
  'Fully Executed',
  'Settled',
  'Cancelled',
  'Cancelled with Payment',
].map((option) => ({
  label: option,
  id: option,
}));

export const CONTRACT_STATUSES_PHASE1: iOptions[] = [
  'Pending',
  'Rejected',
  'Confirmed',
  'Agent Review',
  'Ready to Issue',
].map((option) => ({
  label: option,
  id: option,
}));

export const CONTRACT_STATUSES_PHASE2: iOptions[] = [
  'Contract Issued',
  'Buyer Signed',
  'Markup Review',
  'To Client',
  'Fully Executed',
  'Cancelled',
  'Cancelled with Payment',
].map((option) => ({
  label: option,
  id: option,
}));

export const CONTRACT_STATUSES_PHASE3: iOptions[] = ['Settled', 'Cancelled', 'Cancelled with Payment'].map(
  (option) => ({
    label: option,
    id: option,
  })
);

export const CONTRACT_WIRE_ACCOUNTS: iOptions[] = [
  {
    id: '5',
    label: 'US Music USD',
  },
  {
    id: '3',
    label: 'US Comedy USD',
  },
  {
    id: '11',
    label: 'US Music/Comedy CAD',
  },
  {
    id: '18',
    label: 'US Music/Comedy CAD 2',
  },
  {
    id: '19',
    label: 'US Music USD 2',
  },
  {
    id: '20',
    label: 'London Office USD 2',
  },
  {
    id: '21',
    label: 'London Office EUR 2',
  },
  {
    id: '22',
    label: 'London Office GBP 2',
  },
  {
    id: '23',
    label: 'London Office AUD 2',
  },
  {
    id: '31',
    label: 'US Music/Comedy EUR',
  },
  {
    id: '32',
    label: 'US Music/Comedy GBP',
  },
  {
    id: '33',
    label: 'US Music/Comedy AUD',
  },
  {
    id: '4',
    label: 'Greater Talent Network, Inc.',
  },
  {
    id: '12',
    label: 'London Office GBP',
  },
  {
    id: '13',
    label: 'London Office USD',
  },
  {
    id: '14',
    label: 'London Office EUR',
  },
  {
    id: '15',
    label: 'London Office AUD',
  },
  {
    id: '16',
    label: 'London Office SEK',
  },
  {
    id: '17',
    label: 'London Office NOK',
  },
  {
    id: '24',
    label: 'Malmo Office GBP',
  },
  {
    id: '25',
    label: 'Malmo Office USD',
  },
  {
    id: '26',
    label: 'Malmo Office EUR',
  },
  {
    id: '27',
    label: 'Malmo Office AUD',
  },
  {
    id: '28',
    label: 'Malmo Office SEK',
  },
  {
    id: '29',
    label: 'Malmo Office NOK',
  },
  {
    id: '30',
    label: 'Malmo Office DKK',
  },
];

export const CONTRACT_WIRE_TRANSFER_DEFEAULT_VALUE =
  CONTRACT_WIRE_ACCOUNTS.find((value) => value.label === 'US Comedy USD')?.id.toString() || '';

export const CONTRACT_INFO_EMPTY_FORM = {
  template: '',
  status: '',
  contractDue: undefined,
  wireTransfer: '',
  clientContractSignerBlockLabel: '',
};

/*********************
  END CONTRACT INFO
/*********************/

/*********************
  CALCULATED VALUES
/*********************/
export const CALCULATED_VALUES_EMPTY_FORM: iOrganismCalculatedValues = {
  splitPointOverride: {
    value: 0,
    currency: '',
  },
  breakEvenOverride: {
    value: 0,
    currency: '',
  },
  walkoutOverride: {
    value: 0,
    currency: '',
  },
  netOverride: {
    value: 0,
    currency: '',
  },
};
/*********************
  END CALCULATED VALUES
/*********************/

/*********************
  SHOW LOCATION
/*********************/
export const EMPTY_SHOW_LOCATION_FORM: iOrganismShowLocation = {
  address: {},
  ages: '',
  title: '',
  venue: {
    name: '',
    data: null,
  },
  territory: '',
};

export const SHOW_AGES: iOptions[] = [
  { id: 'all', label: 'All Ages' },
  { id: '18+', label: '18+' },
  { id: '21+', label: '21+' },
];

export const SHOW_TERRITORIES: iOptions[] = [
  'United States',
  'Canada',
  'Rest of World',
  'Scandinavia',
  'Latin America',
].map((option) => ({ label: option, id: option }));
/*********************
  END SHOW LOCATION
/*********************/

/*********************
  SHOW DETAILS
/*********************/
export const SHOW_DETAILS_EMPTY_ITEM: iOrganismShowDetailsItem = {
  isNew: true,
  date: undefined,
  showTime: '',
  status: 'Pending',
  cancelled: '',
  confirmed: '',
  notes: '',
  guarantee: 0,
  public: false,
  isMasterShow: false,
  _id: '',
};

export const SHOW_DETAILS_EMPTY_FORM: iOrganismShowDetails = {
  type: '',
  // announcement: '',
  // onSaleDate: '',
  public: false,
  showDetails: [SHOW_DETAILS_EMPTY_ITEM],
};

export const SHOW_TYPES: iOptions[] = ['Festival', 'Public', 'College', 'Corporate', 'Private', 'Casino'].map(
  (option) => ({ label: option, id: option })
);

export const SHOW_DETAILS_SHOW_TIME_OPTIONS: iOptions[] = [
  { id: 'Neither', label: 'Only Show' },
  { id: 'Early', label: 'Early' },
  { id: 'Late', label: 'Late' },
];

export const SHOW_DETAILS_STATUS_OPTIONS_PAST: String[] = [
  'Confirmed',
  'Cancelled',
  'Cancelled with Payment',
  'Rescheduling',
  'Rejected',
];

export const SHOW_DETAILS_STATUS_OPTIONS: iOptions[] = [
  '1st Hold',
  '2nd Hold',
  '3rd Hold',
  '4th Hold',
  '5th Hold',
  '6th Hold',
  'Blocked',
  'Cancelled',
  'Cancelled with Payment',
  'Confirmed',
  'Pending',
  'Rejected',
  'Rescheduling',
  'Routing',
  // 'Settled',
  'Work Visa',
].map((option) => ({ label: option, id: option }));

export const TIME_ZONES = [
  { name: 'Pacific/Pago_Pago', offset: '-11:00', abbr: 'SST' },
  { name: 'Pacific/Honolulu', offset: '-10:00', abbr: 'HAST' },
  { name: 'America/Los_Angeles', offset: '-08:00', abbr: 'PT' },
  { name: 'America/Tijuana', offset: '-08:00', abbr: 'PT' },
  { name: 'America/Denver', offset: '-07:00', abbr: 'MT' },
  { name: 'America/Phoenix', offset: '-07:00', abbr: 'MT' },
  { name: 'America/Mazatlan', offset: '-07:00', abbr: 'MT' },
  { name: 'America/Chicago', offset: '-06:00', abbr: 'CT' },
  { name: 'America/Mexico_City', offset: '-06:00', abbr: 'CT' },
  { name: 'America/Regina', offset: '-06:00', abbr: 'CT' },
  { name: 'America/Guatemala', offset: '-06:00', abbr: 'CT' },
  { name: 'America/Bogota', offset: '-05:00', abbr: 'COT' },
  { name: 'America/New_York', offset: '-05:00', abbr: 'ET' },
  { name: 'America/Lima', offset: '-05:00', abbr: 'PET' },
  { name: 'America/Caracas', offset: '-04:00', abbr: 'VET' },
  { name: 'America/Halifax', offset: '-04:00', abbr: 'AT' },
  { name: 'America/Guyana', offset: '-04:00', abbr: 'GYT' },
  { name: 'America/La_Paz', offset: '-04:00', abbr: 'BOT' },
  { name: 'America/Argentina/Buenos_Aires', offset: '-03:00', abbr: 'ART' },
  { name: 'America/Godthab', offset: '-03:00', abbr: 'WGT' },
  { name: 'America/Montevideo', offset: '-03:00', abbr: 'UYT' },
  { name: 'America/St_Johns', offset: '-03:30', abbr: 'NT' },
  { name: 'America/Santiago', offset: '-03:00', abbr: 'Chile Time' },
  { name: 'America/Sao_Paulo', offset: '-02:00', abbr: 'Brazil Time' },
  { name: 'Atlantic/South_Georgia', offset: '-02:00', abbr: 'South Georgia Time' },
  { name: 'Atlantic/Azores', offset: '-01:00', abbr: 'Azores Time' },
  { name: 'Atlantic/Cape_Verde', offset: '-01:00', abbr: 'CVT' },
  { name: 'Africa/Casablanca', offset: '+00:00', abbr: 'WET' },
  { name: 'Europe/Dublin', offset: '+00:00', abbr: 'Irish Time' },
  { name: 'Europe/Lisbon', offset: '+00:00', abbr: 'WET' },
  { name: 'Europe/London', offset: '+00:00', abbr: 'British Time' },
  { name: 'Africa/Monrovia', offset: '+00:00', abbr: 'GMT' },
  { name: 'Africa/Algiers', offset: '+01:00', abbr: 'CET' },
  { name: 'Africa/Lagos', offset: '+01:00', abbr: 'WAT' },
  { name: 'Europe/Amsterdam', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Berlin', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Brussels', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Budapest', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Belgrade', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Prague', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Copenhagen', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Madrid', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Paris', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Rome', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Stockholm', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Vienna', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Warsaw', offset: '+01:00', abbr: 'CET' },
  { name: 'Europe/Athens', offset: '+02:00', abbr: 'EET' },
  { name: 'Europe/Bucharest', offset: '+02:00', abbr: 'EET' },
  { name: 'Africa/Cairo', offset: '+02:00', abbr: 'EET' },
  { name: 'Asia/Jerusalem', offset: '+02:00', abbr: 'Israel Time' },
  { name: 'Africa/Johannesburg', offset: '+02:00', abbr: 'SAST' },
  { name: 'Europe/Helsinki', offset: '+02:00', abbr: 'EET' },
  { name: 'Europe/Kiev', offset: '+02:00', abbr: 'EET' },
  { name: 'Europe/Kaliningrad', offset: '+02:00', abbr: 'EET' },
  { name: 'Europe/Riga', offset: '+02:00', abbr: 'EET' },
  { name: 'Europe/Sofia', offset: '+02:00', abbr: 'EET' },
  { name: 'Europe/Tallinn', offset: '+02:00', abbr: 'EET' },
  { name: 'Europe/Vilnius', offset: '+02:00', abbr: 'EET' },
  { name: 'Europe/Istanbul', offset: '+03:00', abbr: 'TRT' },
  { name: 'Asia/Baghdad', offset: '+03:00', abbr: 'AST' },
  { name: 'Africa/Nairobi', offset: '+03:00', abbr: 'EAT' },
  { name: 'Europe/Minsk', offset: '+03:00', abbr: 'MSK' },
  { name: 'Asia/Riyadh', offset: '+03:00', abbr: 'AST' },
  { name: 'Europe/Moscow', offset: '+03:00', abbr: 'MSK' },
  { name: 'Asia/Tehran', offset: '+03:30', abbr: 'Iran Time' },
  { name: 'Asia/Baku', offset: '+04:00', abbr: 'AZT' },
  { name: 'Europe/Samara', offset: '+04:00', abbr: 'SAMT' },
  { name: 'Asia/Tbilisi', offset: '+04:00', abbr: 'GET' },
  { name: 'Asia/Yerevan', offset: '+04:00', abbr: 'AMT' },
  { name: 'Asia/Kabul', offset: '+04:30', abbr: 'AFT' },
  { name: 'Asia/Karachi', offset: '+05:00', abbr: 'PKT' },
  { name: 'Asia/Yekaterinburg', offset: '+05:00', abbr: 'YEKT' },
  { name: 'Asia/Tashkent', offset: '+05:00', abbr: 'UZT' },
  { name: 'Asia/Colombo', offset: '+05:30', abbr: 'IST' },
  { name: 'Asia/Almaty', offset: '+06:00', abbr: 'ALMT' },
  { name: 'Asia/Dhaka', offset: '+06:00', abbr: 'Bangladesh Standard Time' },
  { name: 'Asia/Rangoon', offset: '+06:30', abbr: 'MMT' },
  { name: 'Asia/Bangkok', offset: '+07:00', abbr: 'ICT' },
  { name: 'Asia/Jakarta', offset: '+07:00', abbr: 'WIT' },
  { name: 'Asia/Krasnoyarsk', offset: '+07:00', abbr: 'KRAT' },
  { name: 'Asia/Shanghai', offset: '+08:00', abbr: 'CST' },
  { name: 'Asia/Hong_Kong', offset: '+08:00', abbr: 'Hong Kong Time' },
  { name: 'Asia/Kuala_Lumpur', offset: '+08:00', abbr: 'MYT' },
  { name: 'Asia/Irkutsk', offset: '+08:00', abbr: 'IRKT' },
  { name: 'Asia/Singapore', offset: '+08:00', abbr: 'SGT' },
  { name: 'Asia/Taipei', offset: '+08:00', abbr: 'CST' },
  { name: 'Asia/Ulaanbaatar', offset: '+08:00', abbr: 'ULAT' },
  { name: 'Australia/Perth', offset: '+08:00', abbr: 'AWST' },
  { name: 'Asia/Yakutsk', offset: '+09:00', abbr: 'YAKT' },
  { name: 'Asia/Seoul', offset: '+09:00', abbr: 'KST' },
  { name: 'Asia/Tokyo', offset: '+09:00', abbr: 'JST' },
  { name: 'Australia/Darwin', offset: '+09:30', abbr: 'ACST' },
  { name: 'Australia/South', offset: '+09:30', abbr: 'ACT' },
  { name: 'Australia/Brisbane', offset: '+10:00', abbr: 'AET' },
  { name: 'Australia/Queensland', offset: '+10:00', abbr: 'AEST' },
  { name: 'Pacific/Guam', offset: '+10:00', abbr: 'ChST' },
  { name: 'Asia/Magadan', offset: '+10:00', abbr: 'MAGT' },
  { name: 'Asia/Vladivostok', offset: '+10:00', abbr: 'VLAT' },
  { name: 'Pacific/Port_Moresby', offset: '+10:00', abbr: 'PGT' },
  { name: 'Australia/Hobart', offset: '+11:00', abbr: 'AET' },
  { name: 'Australia/Sydney', offset: '+11:00', abbr: 'AET' },
  { name: 'Pacific/Guadalcanal', offset: '+11:00', abbr: 'SBT' },
  { name: 'Pacific/Noumea', offset: '+11:00', abbr: 'NCT' },
  { name: 'Pacific/Majuro', offset: '+12:00', abbr: 'MHT' },
  { name: 'Pacific/Auckland', offset: '+13:00', abbr: 'NZST' },
  { name: 'Pacific/Fakaofo', offset: '+13:00', abbr: 'TKT' },
  { name: 'Pacific/Fiji', offset: '+13:00', abbr: 'FJT' },
  { name: 'Pacific/Tongatapu', offset: '+13:00', abbr: 'TOT' },
  { name: 'Etc/UTC', offset: '+00:00', abbr: 'UTC' },
].map(({ name, abbr, offset }) => {
  return {
    label: `(GMT${offset}) ${name.replaceAll('_', ' ')} - ${abbr}`,
    id: name,
    data: {
      name: name,
      offset: offset,
      abbr: abbr,
    },
  };
});

/*********************
  END SHOW DETAILS
/*********************/

/*********************
  SCHEDULE INPUT
/*********************/
export const SCHEDULE_TYPES: iOptions[] = [
  'Load In',
  'Sound Check',
  'Doors',
  'Show',
  'Intermission',
  'Artist',
  'Stage',
  'Curfew',
].map((option) => ({ label: option, id: option }));

export const SCHEDULE_INPUT_EMPTY_ITEM = {
  show: '',
  description: '',
};

export const SCHEDULE_INPUT_EMPTY_FORM: iOrganismsSchedule = {
  schedule: [SCHEDULE_INPUT_EMPTY_ITEM],
};

export const SCHEDULE_SHOW_EVENT_EMPTY_ITEM: iOrganismScheduleEvent = {
  type: '',
  timeString: '',
  comments: '',
};

export const SCHEDULE_SHOW_EMPTY_ITEM: iOrganismScheduleShow = {
  show: '',
  showTime: '',
  setLength: 0,
  notes: '',
  events: [SCHEDULE_SHOW_EVENT_EMPTY_ITEM],
};

export const SCHEDULE_EMPTY_ITEM: iOrganismSchedule = {
  shows: [SCHEDULE_SHOW_EMPTY_ITEM],
};

export const SCHEDULE_EMPTY_FORM: iOrganismSchedule = {
  shows: [],
};

/*********************
  END SCHEDULE INPUT 
/*********************/

/*********************
  DEAL TERMS 
/*********************/
export const DEAL_TERMS_EMPTY_FORM: iOrganismDealTerms = {
  currency: 'usd',
  dealType: {
    id: '',
    label: '',
  },
  contractDisplay: '',
};

export const DEAL_TERMS_TYPE_OPTIONS: iOptions[] = [
  { id: 'flat', label: 'Flat' },
  { id: 'plusBackend', label: 'Plus' },
  { id: 'fixedSplit', label: 'Fixed Split' },
  { id: 'versus', label: 'Versus' },
];

export const DEAL_TERMS_VERSUS_TYPES: iOptions[] = [
  { id: 'Gross', label: 'Gross' },
  { id: 'Net', label: 'Net' },
];
/*********************
  END TERMS
/*********************/

/*********************
  SHOW CONTACTS
/*********************/
export const SHOW_CONTACTS_EMPTY_ITEM = {
  type: '',
  person: { name: '', data: null },
};

export const SHOW_CUSTOM_CONTACTS_EMPTY_ITEM = {
  type: '',
  name: '',
};

export const SHOW_CONTACTS_EMPTY_FORM: iOrganismShowContacts = {
  contacts: [SHOW_CONTACTS_EMPTY_ITEM],
  customContacts: [SHOW_CUSTOM_CONTACTS_EMPTY_ITEM],
};

export const SHOW_CONTACTS_CONTACT_TYPES: iOptions[] = [
  'Production',
  'Middle Buyer',
  'Ticket Counts',
  'Ticketing',
  'Show Advance',
  'Contract Signatory',
  'Marketing',
  'Box Office',
  'Accounting',
  'Merchandise',
  'Contracts',
  'Additional Contact',
  'Buyer CC',
  'Client',
  'Comedian',
  'Promoter',
].map((option) => ({
  label: option,
  id: option,
}));
/*********************
  END SHOW CONTACTS
/*********************/

/*********************
  TICKET SCALING
/*********************/
export const TICKET_SCALING_EMPTY_TIER_ITEM: iOrganismTicketScalingTier = {
  isNew: true,
  showId: '',
  tier: 'General Admission',
  advance: {
    value: 0,
    currency: '',
  },
  dayOf: {
    value: 0,
    currency: '',
  },
  available: 0,
  comps: 0,
  sellable: 0,
  gross: 0,
  note: '',
  fees: [],
};

export const TICKET_SCALING_EMPTY_FORM: iOrganismTicketScaling = {
  currency: 'USD',
  tiers: [],
  allShowsFlag: false,
  ticketScalingNotes: '',
};

export const TICKET_SCALING_TICKET_TIERS = [
  'General Admission',
  'VIP',
  'Reserved',
  'Balcony',
  'Orchestra',
  'Students',
  'Public',
  'Festival 1 Day Pass',
  'Festival Weekend Pass',
  'Festival VIP',
  '4-Pack',
  'Minor',
  'Lawn',
  'Free',
  'RSVP',
  'P1',
  'P2',
  'P3',
  'P4',
  'GA',
  'Platinum',
  'Pit',
  'Groupon',
  'LivingSocial',
  'Goldstar',
  'P5',
  'P6',
  'Reserved 1',
  'Reserved 2',
  'Reserved 3',
  'Floor',
  'Reserved 4',
  'Reserved 5',
  'Reserved 6',
  'Mezzanine',
  'Loge',
  'Upper Balcony',
  'VIP 1',
  'VIP 2',
  'VIP 3',
  'VIP 4',
  'VIP M&G',
  'VIP Early',
  'Entry',
  'VIP Merch',
  'Premium',
].map((t) => ({ label: t, id: t, data: {} }));
/*********************
  END TICKET SCALING
/*********************/

/*********************
  TICKET FEES
/*********************/
export const TICKET_FEES_EMPTY_TIER_ITEM = {
  tier: '',
  feeType: 'flat',
  fee: { value: 0, currency: '' },
  calculation: '',
  gross: {
    value: 0,
    currency: '',
  },
  description: '',
};

export const TICKET_FEES_EMPTY_FORM: iOrganismTicketFees = {
  tiers: [],
};

export const TICKET_FEES_FEE_TYPE_OPTIONS: iOptions[] = ['Built-In', 'On-Top'].map((item) => ({
  label: item,
  id: item,
}));

export const TICKET_FEES_FEETYPE_OPTIONS: iOptions[] = [
  'Charity',
  'Facility',
  'Flat',
  'FMF',
  'Parking',
  'Percentage',
  'Tax',
  'Custom',
].map((item) => ({
  label: item,
  id: item.toLowerCase(),
}));

/*********************
  END TICKET FEES
/*********************/

/*********************
  TICKET TAXES
/*********************/
export const TICKET_TAXES_EMPTY_TIER_ITEM = {
  tier: '',
  fee: 0,
  calculation: '',
  mathType: '',
  gross: {
    value: 0,
    currency: 'USD',
  },
  description: '',
};

export const TICKET_TAXES_EMPTY_FORM: iOrganismTicketTaxes = {
  tiers: [],
};

export const TICKET_TAXES_CALCULATION: iOptions[] = [
  {
    id: 'true',
    label: 'Before Fees',
  },
  {
    id: 'false',
    label: 'After Fees',
  },
];
export const TICKET_TAXES_MATH_TYPE: iOptions[] = ['Multiplier', 'Divider'].map((t) => ({ label: t, id: t }));
/*********************
  END TICKET TAXES
/*********************/

/*********************
  PROVISIONS
/*********************/
export const PROVISIONS_EMPTY_FORM_ITEM = {
  label: '',
  content: '',
};
export const PROVISIONS_EMPTY_FORM: iOrganismProvisions = {
  provisions: [],
};
/*********************
  END PROVISIONS
/*********************/

/*********************
  CLAUSES
/*********************/
export const CLAUSE_EMPTY_CLAUSE_ITEM = {
  label: '',
  content: '',
};

export const CLAUSE_EMPTY_FORM: iOrganismClauses = {
  clauses: [],
};
/*********************
  END CLAUSES
/*********************/
export const TRAVEL_EMPTY_FORM: iOrganismTravelAccommodations = {
  accommodation: '',
};
/*********************
  TRAVEL ACCOMMODATIONS
/*********************/

export const EMPTY_FORM: iOrganismTravelAccommodations = {
  accommodation: '',
};

/*********************
  END TRAVEL ACCOMMODATIONS
/*********************/

/*********************
  SPLIT DEAL
/*********************/
export const SPLIT_DEAL_EMPTY_ITEM: iOrganismSplitDealItem = {
  amount: {
    value: 0,
    currency: '',
  },
  balanceRemittance: '',
  clientBusinessName: {
    name: '',
    data: null,
  },
  clientTaxVatNumber: '',
  contractSignerBlock: '',
  dealDisplay: '',
  percentage: 0,
  splitType: '',
  mustSplitType: 'Client (Artist)',
};

export const SPLIT_DEAL_EMPTY_FORM: iOrganismSplitDeal = {
  splits: [SPLIT_DEAL_EMPTY_ITEM],
};

export const SPLIT_DEAL_OPTIONS_SPLIT_TYPE: iOptions[] = [
  {
    id: 'Production',
    label: 'Production',
  },
  {
    id: 'Management',
    label: 'Management',
  },
];

export const SPLIT_DEAL_OPTIONS_SIGNER_BLOCK: iOptions[] = [
  {
    id: 'Temp1',
    label: 'Temp 1',
  },
  {
    id: 'Temp 2',
    label: 'Temp 2',
  },
];
/*********************
  END SPLIT DEAL
/*********************/

/*********************
  MERCHANDISE
/*********************/
export const MERCHANDISE_EMPTY_MERCH_ITEM = {
  isNew: true,
  type: '',
  percentage: 0,
  seller: '',
  description: '',
};

export const MERCHANDISE_EMPTY_FORM: iOrganismMerchandise = {
  merchandise: [MERCHANDISE_EMPTY_MERCH_ITEM],
};

export const MERCHANDISE_OPTIONS_TYPE: iOptions[] = [
  {
    id: 'Soft Goods',
    label: 'Soft Goods',
  },
  {
    id: 'Hard Goods',
    label: 'Hard Goods',
  },
  {
    id: 'All',
    label: 'All',
  },
];

export const MERCHANDISE_OPTIONS_SELLER: iOptions[] = [
  {
    id: 'Venue',
    label: 'Venue',
  },
  {
    id: 'Artist',
    label: 'Artist',
  },
  {
    id: 'Other',
    label: 'Other',
  },
];
/*********************
  END MERCHANDISE
/*********************/

/*********************
  FIXED EXPENSES
/*********************/
export const FIXED_EXPENSES_EMPTY_EXPENSE_ITEM = {
  type: '',
  amount: {
    value: 0,
    currency: '',
  },
  notes: '',
};

export const FIXED_EXPENSES_EMPTY_FORM: iOrganismFixedExpenses = {
  expenses: [],
  fixedExpensesTotal: {
    value: 0,
    currency: '',
  },
};

export const FIXED_EXPENSES_LIMIT = 50;
export const VARIABLE_EXPENSES_TYPES: iOptions[] = [
  'Expenses',
  'ASCAP',
  'BMI',
  'GMR',
  'PRS',
  'SESAC',
  'SOCAN',
  'Insurance',
  'Rent',
  'Ticket Commison',
  'Credit Card',
  'VAT',
  'VIP Lift',
  'Custom',
].map((o) => ({ label: o, id: o }));

export const RADIUS_CLAUSE_UNITS: iOptions[] = ['Miles', 'Kilometers'].map((o) => ({ label: o, id: o }));

export const FIXED_EXPENSES_TYPES: iOptions[] = [
  'Expense',
  'Accommodations',
  'Advertising',
  'ASCAP/BMI/SESAC',
  'Backline',
  'Barricades',
  'Bootleg Security',
  'Box Office',
  'Catering',
  'Cleanup',
  'Electrician',
  'Equipment Rental',
  'Flights',
  'Ground Transportation',
  'House Nut',
  'Insurance',
  'Lights',
  'Loaders',
  'Medic',
  'Miscellaneous',
  'Monitors',
  'Parking',
  'Permits & Licenses',
  'Phone',
  'Piano Tuner',
  'Police',
  'Production Manager',
  'Production Nut',
  'Projectionist',
  'Promoter Rep',
  'Rent',
  'Riggers',
  'Runner',
  'Sound & Lights',
  'Security',
  'Setup',
  'Sound',
  'Staff',
  'Stage',
  'Stage Manager',
  'Stagehands',
  'Support',
  'Ticket Printing',
  'Ticket Seller',
  'Ticket Taker',
  'Towels',
  'Ushers',
  'Utilities',
  'Video',
  'Visas',
  'Custom',
].map((o) => ({ label: o, id: o }));
/*********************
  END FIXED EXPENSES
/*********************/

/*********************
  VARIABLE EXPENSES
/*********************/
export const VARIABLE_EXPENSES_EMPTY_ITEM = {
  isNew: true,
  type: '',
  currency: '',
  guarantee: {
    value: 0,
    currency: '',
  },
  cap: {
    value: 0,
    currency: '',
  },
  ticketPercentage: 0,
  gBor_nBor: '',
  perHead: {
    value: 0,
    currency: '',
  },
  perHeadCapacity: '',
  notes: '',
  calculation: 'perHead',
};

export const VARIABLE_EXPENSES_EMPTY_FORM: iOrganismVariableExpenses = {
  expenses: [VARIABLE_EXPENSES_EMPTY_ITEM],
  variableExpensesTotal: {
    value: 0,
    currency: '',
  },
};

export const VARIABLE_EXPENSES_BOR_OPTIONS: iOptions[] = [
  {
    id: 'Gross',
    label: 'GBOR',
  },
  {
    id: 'Net',
    label: 'NBOR',
  },
];

export const VARIABLE_EXPENSES_CALC_OPTIONS: iOptions[] = [
  {
    id: 'perHead',
    label: 'Per Head',
  },
  {
    id: 'boxOffice',
    label: 'Box Office',
  },
];

export const VARIABLE_EXPENSES_CAPACITY_TYPES: iOptions[] = [
  {
    id: 'Total',
    label: 'Total',
  },
  {
    id: 'Sellable',
    label: 'Sellable',
  },
];

/*********************
  END VARIABLE EXPENSES
/*********************/

/*********************
  PAYMENT SCHEDULE
/*********************/
export const PAYMENT_SCHEDULE_EMPTY_EXPECTED_PAYMENT_ITEM = {
  showId: '',
  type: 'Deposit',
  dateExpected: '',
  amount: {
    value: 0,
    currency: 'USD',
  },
  paymentTo: '',
  notes: '',
};
export const PAYMENT_SCHEDULE_EMPTY_EXPECTED_PAYMENT_ITEM_SPLIT_DEAL = {
  showId: '',
  type: 'Deposit',
  dateExpected: '',
  amount: {
    value: 0,
    currency: 'USD',
  },
  paymentTo: '',
  splitType: '',
  notes: '',
};
export const PAYMENT_SCHEDULE_EMPTY_RECEIVED_PAYMENT_ITEM = {
  showId: '',
  type: 'Balance',
  dateReceived: '',
  amount: {
    value: 0,
    currency: 'USD',
  },
  paymentTo: '',
  notes: '',
};

export const PAYMENT_SCHEDULE_EMPTY_FORM: iOrganismPaymentSchedule = {
  paymentsExpected: [],
  paymentsReceived: [],
  paymentScheduleNotes: '',
};

export const PAYMENT_SCHEDULE_NOTES: iOptions[] = [
  'Deposit due on receipt of contract',
  'Deposit due prior to announcement',
  'Deposit due one (1) month prior to performance',
  'Deposit due one (1) month prior to first performance (multishow contract)',
  'Balance due',
  'Balance & Any Overage Due (Pending Final Settlement)',
  'Production Contribution Deposit (Net & Free of Tax) Due One (1) Month Prior to Performance',
  'Production Contribution Balance Due',
  'Production Contribution Paid Direct to Artist (per Invoice)',
  'Production Contribution Paid Direct to Prod company (per Invoice)',
  'VAT on Deposit',
  'VAT on Balance',
  '50% Deposit',
  '50% Balance',
].map((o) => ({ label: o, id: o }));

export const PAYMENT_SCHEDULE_PAYMENT_TYPE: iOptions[] = ['Balance', 'Deposit'].map((o) => ({ label: o, id: o }));

export const PAYMENT_SCHEDULE_PAYMENT_TO: iOptions[] = ['3rd Party', 'Artist', 'UTA'].map((t) => ({
  label: t,
  id: t,
}));
/*********************
  END PAYMENT SCHEDULE
/*********************/

/*********************
  SETTLEMENT TOTALS
/*********************/
export const SETTLEMENT_TOTALS_EMPTY_FORM: iOrganismSettlementTotals = {
  grossBoxOfficeRevenue: {
    value: 0,
    currency: 'USD',
  },
  netBoxOfficeRevenue: {
    value: 0,
    currency: 'USD',
  },
  guarantee: {
    value: 0,
    currency: 'USD',
  },
  overage: {
    value: 0,
    currency: 'USD',
  },
  overageNotes: '',
  taxWithholding: {
    value: 0,
    currency: 'USD',
  },
  totalTicketsSold: 0,
  totalCompTickets: 0,
};
/*********************
  END SETTLEMENT TOTALS
/*********************/

/*********************
  BUYOUTS
/*********************/
export const BUYOUTS_EMPTY_PAYMENT_ITEM = {
  amountDue: {
    value: 0,
    currency: '',
  },
  type: '',
  paymentTo: '',
  dateReceived: undefined,
  description: '',
  showId: '',
};

export const BUYOUTS_EMPTY_FORM: iOrganismBuyouts = {
  buyouts: [],
  totalBuyouts: {
    value: 0,
    currency: '',
  },
};

export const BUYOUTS_PAYMENT_TYPE: iOptions[] = [
  'Travel',
  'Production',
  'Catering',
  'Sponsorship',
  'Support',
  'Other',
  // 'Backline',
  // 'Flight',
  // 'Ground Transportation',
].map((o) => ({ label: o, id: o.split(' ').join('') }));

export const BUYOUTS_PAYMENT_TO: iOptions[] = ['3rd Party', 'Artist', 'UTA'].map((t) => ({ label: t, id: t }));
/*********************
  END BUYOUTS
/*********************/

/*********************
  REDUCTIONS
/*********************/
export const REDUCTIONS_EMPTY_ITEM = {
  amount: {
    value: 0,
    currency: '',
  },
  type: '',
  description: '',
};

export const REDUCTIONS_TYPE_OPTIONS = ['Agent', 'Client'].map((item) => ({ label: item, id: item }));
/*********************
  END REDUCTIONS
/*********************/

/*********************
  SETTLEMENT FINALS
/*********************/
export const SETTLEMENT_FINALS_EMPTY_FORM: iOrganismSettlementFinals = {
  tiers: [],
};
/*********************
  END SETTLEMENT FINALS
/*********************/

/*********************
  PAYMENT TO CLIENTS
/*********************/
export const PAYMENT_TO_CLIENT_EMPTY_ITEM = {
  amount: {
    value: 0,
    currency: '',
  },
  clientPaymentType: '',
  memo: '',
};

export const PAYMENT_TO_CLIENT_EMPTY_FORM: iOrganismClientPayments = {
  payments: [],
};

export const PAYMENTS_TO_CLIENT_TYPE = [{ id: 'Other', label: 'VAT Charged by Client to Buyer' }];
/*********************
  END PAYMENT TO CLIENTS
/*********************/

/*********************
  PAYMENTS ON CLIENTS BEHALF
/*********************/
export const PAYMENT_CLIENTS_BEHALF_EMPTY_ITEM = {
  amount: {
    value: 0,
    currency: '',
  },
  clientPaymentType: '',
  memo: '',
};

export const PAYMENT_CLIENTS_BEHALF_EMPTY_FORM: iOrganismClientsBehalfPayments = {
  payments: [],
};

export const PAYMENT_CLIENTS_BEHALF_PAYMENT_TYPES: iOptions[] = [
  { id: 'Hotel', label: 'Expense HTL Costs' },
  { id: 'Train', label: 'Train Costs' },
  { id: 'Car', label: 'Car Costs' },
  { id: 'Interpreter', label: 'Interpreter Costs' },
  { id: 'Sound', label: 'Sound Costs' },
  { id: 'Truck', label: 'Truck Costs' },
  { id: 'Lighting', label: 'Lighting Costs' },
  { id: 'Studio', label: 'Studio Costs' },
  { id: 'Airfare', label: 'Airfare' },
  { id: 'Curfew', label: 'Curfew Breach' },
  { id: 'Damage', label: 'Damages' },
  { id: 'TaxAdvisor', label: 'Tax Advisor' },
  { id: 'Fine', label: 'Fines' },
  { id: 'Catering', label: 'Catering' },
  { id: 'Backline', label: 'Backline Hire' },
  { id: 'BankCharges', label: 'Bank Charges' },
  { id: 'Withholding Tax', label: 'Withholding Tax' },
  { id: 'Other', label: 'Other Fees' },
];

/*********************
  END PAYMENTS ON CLIENTS BEHALF
/*********************/

/*********************
  FINANCIALS
/*********************/
export const LOB_TYPES: iOptions[] = ['Music', 'Music - Circle', 'Music - Echo', 'Speakers'].map((option) => ({
  label: option,
  id: option,
}));

export const LOCATION_TYPES: iOptions[] = [
  'Los Angeles Office',
  'New York Office',
  'Nashville Office',
  'Scandinavia Office',
  'London Office',
].map((option) => ({
  label: option,
  id: option,
}));

export const DEPARTMENT_TYPES: iOptions[] = ['Music', 'Comedy and Touring'].map((option) => ({
  label: option,
  id: option,
}));

export const SUBSIDIARY_TYPES: iOptions[] = ['United Talent Agency, LLC', 'The Agency Group Ltd'].map((option) => ({
  label: option,
  id: option,
}));

export const TERRITORY_TYPES: iOptions[] = [
  'All Territories',
  'Rest of World',
  'Canada',
  'Latin America',
  'Scandinavia',
  'United States',
].map((option) => ({
  label: option,
  id: option,
}));
/*********************
  FINANCIALS
/*********************/
