import { ReactElement, ChangeEventHandler } from 'react';
import { FormSelect, Sliderbar, Switch } from '@united-talent-agency/onyx-components';
import { useFilters } from 'context/avails-filters/context';
import { getOptions } from 'utils/locationiq';
import useDebouncedCallback from 'hooks/useDebouncedCallback';

const LocationFilter = (): ReactElement => {
  const {
    // Location latitude and longitude
    selectedLocation,
    setSelectedLocation,
    // Location radius
    locationRadius,
    setLocationRadius,
    // Include location toggle
    includeLocationRadius,
    setIncludeLocationRadius,
  } = useFilters();

  const handleBeforeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLocationRadius(Number(e.target.value));
  };

  const handleConfirmedShowsChange = (value: boolean | undefined) => {
    setIncludeLocationRadius(!!value);
  };

  const DEBOUNCE_TIME = 500;

  /**
   * Note: It is mandatory to return the new options here, as the FormSelect component
   * has rendering bugs due to internal methods that do not use memoization techniques.
   * Therefore, every update outside of it can cause it to be re-rendered and lose focus.
   */
  const loadOptionsCallback = useDebouncedCallback(getOptions, DEBOUNCE_TIME);

  return (
    <>
      <FormSelect
        isSearchable
        dropdownIndicator={<></>}
        className="w-full"
        label="City, State, Country"
        placeholder="Search a location..."
        noOptionsMessage="No Location"
        value={selectedLocation}
        onChange={(value: any) => setSelectedLocation(value)}
        loadOptions={loadOptionsCallback}
        menuShouldBlockScroll
      />
      <div className="mt-5">
        <label className="text-sm">
          Within <span className="font-bold">{locationRadius}</span> Miles
        </label>
        <div className="mt-2">
          <Sliderbar value={locationRadius} min={0} max={800} onChange={handleBeforeChange} />
        </div>
      </div>
      <div className="toggle-container mt-6 flex items-center gap-3">
        <Switch size="lg" checked={includeLocationRadius} onChange={handleConfirmedShowsChange} />
        <span className="text-sm font-normal">Include Clients with Radius Conflicts</span>
      </div>
    </>
  );
};

export default LocationFilter;
