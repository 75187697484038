import { Venue } from 'types';

import { Address, Contact, EntityDesignateType, ExternalRelationship } from '../types/data-schemas';
import { get } from './index';

export type Website = {
  _id: string;
  websiteType: string;
  websiteLink: string;
};

export type Capacity = {
  description?: string;
  max?: boolean;
  primary?: boolean;
  _id: string;
  capacity: string;
};

export type VenueInfo = {
  venueType: string;
  seatingType: string[];
  capacities: Capacity[];
  websites?: Website[];
  geoLocation?: unknown;
};

export interface VenueDetails {
  name: string;
  _id: string;
  venueInfo: VenueInfo;
  type: string;
  contacts?: Contact[];
  addresses?: Address[];
  designateType?: EntityDesignateType.Venue;
}

export const getVenueDetails = async (id: string): Promise<VenueDetails> => {
  const response = await get(`/touring/venue-info?venueId=${id}`);
  return response.body;
};

export const getVenueToPersonRelationship = async (id: string): Promise<ExternalRelationship[]> => {
  const response = await get(`/v2/external-teams/group-to-person?group=${id}`);
  return response.body;
};

export const getVenueToCompanyRelationship = async (id: string): Promise<ExternalRelationship[]> => {
  const response = await get(`/v2/external-teams/group-to-group?group=${id}`);
  return response.body;
};

export const getVenues = async (name: string): Promise<VenueDetails[]> => {
  const response = await get(`/touring/venues?name=${name}`);
  return response.body;
};

export const findVenues = async (name: string): Promise<Venue[]> => {
  if (!name) {
    return [];
  }
  const response = await get(`/venues/search/${name}`);
  return response.body;
};
