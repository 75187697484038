import { updateDeal } from 'api';
import { FORM_NAMES } from 'deal-form/data/constants';
import { CLAUSE_EMPTY_CLAUSE_ITEM, CLAUSE_EMPTY_FORM } from 'deal-form/data/organisms';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismClauses } from 'deal-form/interfaces/organisms';
import { MasterDealWarning } from 'deal-form/ui/MasterDealWarning';
import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Clause, Deal } from 'types';

import { DropdownWithStaticSearch } from '../../form-controls/DropdownWithStaticSearch';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';

const GRID_LAYOUT = 'grid grid-cols-2 gap-5 py-4';

const AdditionalClausesForm: React.FC<iFormProps> = ({
  deal,
  setDeal,
  contract,
  setContract,
  clauses,
  isOpen,
  locked,
}) => {
  const [clauseOptions, setClauseOptions] = useState<iOptions[]>();
  const [clauseData, setClauseData] = useState<iOrganismClauses | null>(null);
  const [crossed, setCrossed] = useState<boolean>(false);
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);

  const onSubmit = async (data: iOrganismClauses) => {
    const dealToUse = masterDeal ? masterDeal : deal;

    try {
      setCanSubmit(false);

      const updatedClauses: Clause[] = [];
      if (data.clauses.length > 0) {
        data.clauses.forEach(({ content, label }) => {
          const clause: Clause = { text: content, type: 'Clause', name: label };
          updatedClauses.push(clause);
        });
      }
      const updatedDeal = await updateDeal(dealToUse._id, {
        ...dealToUse,
        clauses: updatedClauses,
      });

      if (updatedDeal) {
        if (contract && masterDeal && setContract) {
          setContract({
            ...contract,
            masterDeal: updatedDeal,
          });
        } else if (setDeal) setDeal(updatedDeal);
      }
      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR updated buyer', e);
      setCanSubmit(true);
    }
  };

  useEffect(() => {
    if (clauses) {
      const formattedClauses: iOptions[] = clauses.map((clause) => {
        return {
          id: clause._id || clause.name.toLowerCase().replaceAll(' ', '-'),
          label: clause.name,
          data: clause.text,
        };
      });
      formattedClauses.push({ id: 'Custom', label: 'Custom', data: '' });

      setClauseOptions(formattedClauses);
    }
  }, [clauses]);

  useEffect(() => {
    let dealToUse = deal;
    if (contract && contract.masterDeal && contract.crossed !== 'Neither') {
      dealToUse = contract.masterDeal;
      setMasterDeal(contract.masterDeal);
      setCrossed(contract.crossed === 'Crossed');
    } else {
      setMasterDeal(null);
      setCrossed(false);
    }

    const defaultValues =
      dealToUse.clauses.length === 0
        ? CLAUSE_EMPTY_FORM
        : {
            clauses: dealToUse.clauses.map(({ name, text }) => {
              return { label: name, content: text };
            }),
          };
    setClauseData(defaultValues);
  }, [deal, contract]);

  /**
   * This function fires when the provision is selected, it replaces the text in the
   * field with the text associated with the selected item
   *
   * @param chosen the data about the person being selected
   * @param setValueMethod the function to set a value on the form
   * @param index the id of the form row to be effected
   */
  const handleChangeClause = (
    chosen: iOptions | null,
    setValueMethod?: UseFormSetValue<FieldValues>,
    index?: number
  ) => {
    if (setValueMethod && index !== undefined)
      setValueMethod(`clauses.${index}.content`, chosen?.data, { shouldValidate: true });
  };

  /**
   * This function fires when the content field is typed in, in case we need to always change
   * the type to Custom if someone changes the field
   *
   * @param setValueMethod function to change the value ont he form
   * @param index index of the form row
   */
  const handleContentChange = (setValueMethod?: UseFormSetValue<FieldValues>, index?: number) => {
    if (setValueMethod && index !== undefined) setValueMethod(`clauses.${index}.label`, 'Custom');
  };

  return (
    clauseData && (
      <>
        {masterDeal && <MasterDealWarning crossed={crossed} fields={['Additional Clauses']} />}
        <Form
          onSubmit={onSubmit}
          canSubmit={canSubmit}
          defaultValues={clauseData}
          disabled={!isOpen || locked}
          formName={FORM_NAMES.ADDITIONAL_CLAUSES}
        >
          <FieldArray
            gridClassName={GRID_LAYOUT}
            groupName="clauses"
            addButtonLabel="Add Clause"
            emptyRow={CLAUSE_EMPTY_CLAUSE_ITEM}
            rearrangeable={false}
            disabled={!isOpen || locked}
          >
            <DropdownWithStaticSearch
              id="label"
              label="Clause"
              staticOptions={clauseOptions || []}
              placeholder="Please select a clause"
              disabled={!isOpen || locked}
              handleChange={handleChangeClause}
            />

            <TextArea
              label={false}
              placeholder="Either select a clause above or type a custom one here."
              rules={{
                required: 'Please select/create a clause or remove this item',
                maxLength: {
                  value: 3000,
                  message: 'Please reduce the Clause to maximum 1200 characters',
                },
              }}
              handleChange={handleContentChange}
              id="content"
              containerClassName="col-start-1 col-span-full"
              rows={6}
              disabled={!isOpen || locked}
            />
          </FieldArray>
        </Form>
      </>
    )
  );
};

export default AdditionalClausesForm;
