import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

import { isDraft, isEmpty, isShow } from '../../../../containers/routing/rowParsers';
import { generateSimpleDropdown } from '../dropdown/Dropdown';
import { DropDownCandidate as Candidate, DropDownCandidate } from '../index';

interface StatusCandidate extends Candidate {
  color: string;
}

export const STATUS_CANDIDATES: Record<string, StatusCandidate[]> = {
  draft: [
    {
      value: 'Rejected',
      color: '#ec8007',
    },
    {
      value: 'Sketch',
      color: '#8f8f8f',
    },
  ],
  show: [
    {
      value: 'Cancelled',
      color: '#DD6C53',
    },
    {
      value: 'Cancelled with Payment',
      color: '#DD6C53',
    },
    {
      value: 'Rescheduling',
      color: '#5CA0C4',
    },
    {
      value: 'Confirmed',
      color: '#A9B662',
    },
    {
      value: 'Settled',
      color: '#A9B662',
    },
    {
      value: 'Pending',
      color: '#b49c3c',
    },
  ],
};

export const DRAFT_STATUSES = STATUS_CANDIDATES.draft.map((item: DropDownCandidate) => item.value);
export const SHOW_STATUSES = STATUS_CANDIDATES.show.map((item: DropDownCandidate) => item.value);

export const SETTLED_SHOW_CANDIDATE: Candidate = {
  value: 'Settled',
  color: '#5a22ba',
};

/**
 * Deal Status renderer.
 */
export const DealStatusRenderer: FC<ICellRendererParams> = (props) => {
  const candidates = [...STATUS_CANDIDATES.draft, ...STATUS_CANDIDATES.show];
  const value = props.value ? props.value : props.data?.status;
  const labelObj = candidates.filter((candidate) => candidate.value === value)[0] || {
    color: '#b49c3c',
  };

  if (!props.data.clientAvailable) return <></>;

  return (
    <>
      <div className="center-content" data-row-id={props.data?.id}>
        {value && labelObj && (
          <span className="flex h-10">
            <div className="mt-[15px] w-[10px] h-[10px] rounded-full mr-[5px]" style={{ background: labelObj.color }} />
            {props.value === 'Cancelled with Payment' ? 'Cancelled w/ Payment' : props.value}
          </span>
        )}
      </div>
    </>
  );
};

/**
 * Deal Status editor.
 */
export const DealStatusEditor = generateSimpleDropdown('DealStatusEditor', (props) => {
  const { data: row } = props;

  if (isEmpty(row)) {
    return STATUS_CANDIDATES.draft;
  } else if (isDraft(row)) {
    return [...STATUS_CANDIDATES.draft, ...STATUS_CANDIDATES.show];
  } else if (isShow(props.data)) {
    return [...STATUS_CANDIDATES.show, ...(row.status === 'Confirmed' ? [SETTLED_SHOW_CANDIDATE] : [])];
  }

  return [];
});
