import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DatePicker } from 'antd';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { getDateFormatByBrowser } from 'utils/helpers';
import dayjs, { Dayjs } from 'dayjs';
import { DatePickerType } from 'antd/es/date-picker';
import { IDate, IDateParams } from 'ag-grid-community';

export type AGDateInputRef = IDate;

type DatePickerProps = Pick<DatePickerType, 'defaultProps'>['defaultProps'];

export type AGDateInputProps = {
  testId?: string;
} & Pick<IDateParams, 'onDateChanged'> &
  DatePickerProps;

const AGDateInput = forwardRef<AGDateInputRef, AGDateInputProps>(
  ({ testId, onDateChanged, className, popupClassName, ...rest }, outterRef) => {
    // States
    const [value, setValue] = useState<Date | null>(null);

    useImperativeHandle(
      outterRef,
      () => ({
        getDate: () => value,
        setDate: (date: Date | null) => setValue(date),
      }),
      [value]
    );

    useEffect(() => {
      onDateChanged && onDateChanged();
    }, [value]);

    const handleOnChange = (_value: Dayjs | null) => {
      setValue(_value?.toDate() ?? null);
    };

    return (
      <DatePicker
        data-testid={testId}
        {...(rest as unknown as DatePickerType)}
        picker="date"
        value={value ? dayjs(value) : undefined}
        onChange={handleOnChange}
        format={getDateFormatByBrowser()}
        placeholder={getDateFormatByBrowser()}
        popupClassName={twMerge(clsx('ag-custom-component-popup', popupClassName))}
        className={twMerge(
          clsx(
            `h-10 border rounded-none px-3 disabled:opacity-50 py-1 text-sm appearance-none peer w-full border-[var(--ag-input-border-color)]`,
            className
          )
        )}
      />
    );
  }
);

export default AGDateInput;
