import { updateContract } from 'api';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  FINANCIALS_BUSINESS_OPTIONS,
  FINANCIALS_DEPARTMENT_OPTIONS,
  FINANCIALS_EMPTY_FORM,
  FINANCIALS_LOCATION_OPTIONS,
  FINANCIALS_SUBSIDIARY_OPTIONS,
  CONTRACT_WIRE_ACCOUNTS,
} from 'deal-form/data/organisms';
import { iFormProps } from 'deal-form/interfaces/general';
import { iOrganismFinancials } from 'deal-form/interfaces/organisms';
import React, { useEffect, useState } from 'react';

import getWireTransferAccount from '../../../utils/wireAccountLogic';
import { Dropdown } from '../../form-controls/Dropdown';
import { Form } from '../../form-controls/Form';
import { Label } from '../../form-controls/Label';
import getTemplateString from '../../../utils/templateHelper';
import cypressTags from 'support/cypressTags';
const { ACCOUNTING_SECTION } = cypressTags.DEAL_FORM;
const FinancialForm: React.FC<iFormProps> = ({ deal, contract, setContract, isOpen, locked }) => {
  const [formData, setFormData] = useState<iOrganismFinancials | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);

  const getWireTransfer = (data: any, contractInfo: any) => {
    const wireTransferResult = getWireTransferAccount(
      data.location,
      contractInfo.deals[0].currency,
      data.subsidiary,
      data.department,
      data.lineOfBusiness
    );
    return wireTransferResult;
  };

  useEffect(() => {
    if (contract && contract.financeInfo) {
      const financialData: iOrganismFinancials = {
        location: contract.financeInfo.location || FINANCIALS_EMPTY_FORM.location,
        subsidiary: contract.financeInfo.subsidiary || FINANCIALS_EMPTY_FORM.subsidiary,
        lineOfBusiness: contract.financeInfo.lineOfBusiness || FINANCIALS_EMPTY_FORM.lineOfBusiness,
        department: contract.financeInfo.department || FINANCIALS_EMPTY_FORM.department,
      };
      setFormData(financialData);
    } else {
      setFormData(FINANCIALS_EMPTY_FORM);
    }
  }, [deal, contract]);

  const onSubmit = async (values: iOrganismFinancials) => {
    if (contract) {
      try {
        setCanSubmit(false);

        const wireTransferName = getWireTransfer(values, contract);

        const wireTransferId = CONTRACT_WIRE_ACCOUNTS.find((account) => account.label === wireTransferName)?.id;

        const utaWireTransfer = wireTransferId || 'No Results, Change Accounting Info';

        const templateString = getTemplateString(values?.location || '', values?.department || '');

        const updatedContract = await updateContract(contract._id, {
          ...contract,
          financeInfo: {
            territory: contract.financeInfo?.territory,
            location: values.location,
            subsidiary: values.subsidiary,
            lineOfBusiness: values.lineOfBusiness,
            department: values.department,
          },
          utaWireTransfer,
          template: templateString,
        });
        if (updatedContract && setContract) {
          setContract(updatedContract);
        }
        setTimeout(() => setCanSubmit(true), 500);
      } catch (error) {
        console.log('Error updating Finance Info', error);
        setCanSubmit(true);
      }
    }
  };

  const GRID_LAYOUT = 'grid grid-cols-[200px_400px] gap-5 items-center';

  return (
    formData && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        defaultValues={formData}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.FINANCIAL}
      >
        <div className={GRID_LAYOUT}>
          <Label isRequired>Location</Label>
          <Dropdown
            disabled={!isOpen || locked}
            placeholder="Location"
            id="location"
            options={FINANCIALS_LOCATION_OPTIONS}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            dataCy={ACCOUNTING_SECTION.LOCATION_DROPDOWN}
          />

          <Label isRequired>Subsidiary</Label>
          <Dropdown
            disabled={!isOpen || locked}
            placeholder="Subsidiary"
            id="subsidiary"
            options={FINANCIALS_SUBSIDIARY_OPTIONS}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            dataCy={ACCOUNTING_SECTION.SUBSIDIARY_DROPDOWN}
          />

          <Label isRequired>Line of Business</Label>
          <Dropdown
            disabled={!isOpen || locked}
            placeholder="Line of Business"
            id="lineOfBusiness"
            options={FINANCIALS_BUSINESS_OPTIONS}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            dataCy={ACCOUNTING_SECTION.LINE_OF_BUSINESS_DROPDOWN}
          />

          <Label isRequired>Department</Label>
          <Dropdown
            disabled={!isOpen || locked}
            placeholder="Department"
            id="department"
            options={FINANCIALS_DEPARTMENT_OPTIONS}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            dataCy={ACCOUNTING_SECTION.DEPARTMENT_DROPDOWN}
          />
        </div>
      </Form>
    )
  );
};

export default FinancialForm;
