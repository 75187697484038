import { useEffect, useState, useRef, FC } from 'react';
import { ChevronDownIcon, UserIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { getBusinessEntities } from 'api/business';
import { useEditDealContext } from 'context/editDealContext';
import { throttle } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { default as CypressTags } from 'support/cypressTags';
import { classes } from 'support/react-toastify';
import { Contract, Deal, Person, Show } from 'types';
import { EntityDesignateType, EntityType } from 'types/data-schemas';
import { getEntityProfileUrl } from 'urls';
import { getArtistThumbUrl } from 'utils/cloudinary';

import ArtistBannerBg from 'components/artist-banner-bg';
import { DropdownWithToggles } from 'components/dropdown-with-toggle/dropdownWithToggle';
import FlyoutMenu from 'components/flyout-menu/flyoutMenu';
import FlyoutMenuMobile from 'components/flyout-menu/flyoutMenuMobile';
import ToastWrapper from 'components/toast/ToastWrapper';

import { LinkedDealsModal } from '../linked-deals/LinkedDealsModal';
import MobileSubMenuModal from 'components/dealform-mobile-menu/mobileSubMenuModal';
import { useOverviewMenuHook } from 'support/hooks';
import { useClickAway } from 'react-use';
import { SplitDealButton } from '../../../components/SplitDealButton';
import { EventDateDetails } from '../../../components/EventDateDetails';
import { VenueDetails } from '../../../components/VenueDetails';
import { ClientName } from '../../../components/ClientName';
import { ContractStatusIndicator } from '../../../components/ContractStatusIndicator';
import { PreviewDealDropdown } from '../../../components/PreviewDealDropdown';
import { handleSelect } from './utils';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import cypressTags from 'support/cypressTags';

export const GENERATE_PREIVIEW_OPTIONS = [
  { id: 'previewDealMemo', label: 'Generate Deal Memo(s)' },
  { id: 'previewContract', label: 'Generate Contract(s)' },
];

const THUMBNAIL_WIDTH_HEIGHT = 160;

interface Props {
  deal: Deal;
  shows: Show[];
  dealId: string;
  client: Person;
  dealType: string;
  contract: Contract;
  isSplitDeal?: boolean;
  artistProfilePic?: string;
  setContract?: (c: Contract) => void;
  setIsSplitDeal?: (val: boolean) => void;
}

export const Header: FC<Props> = ({
  deal,
  shows,
  client,
  dealId,
  contract,
  dealType,
  isSplitDeal = false,
  artistProfilePic,
  setContract,
  setIsSplitDeal,
}) => {
  const firstShow = shows?.sort((a, b) => (a.date > b.date ? 1 : -1))[0];
  const lastShow = shows.length > 1 ? shows?.sort((a, b) => (a.date > b.date ? -1 : -1))[0] : null;
  const { overviewMenuOpen, setOverviewMenuOpen, overviewRef, onLinkClick } = useOverviewMenuHook();

  // States
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [dealFormModalOpen, setDealFormModalOpen] = useState<boolean>(false);

  // Refs
  const dealFormModalRef = useRef(null);
  useClickAway(dealFormModalRef, () => {
    setDealFormModalOpen(false);
  });

  const handleThrottledScroll = throttle(
    () => {
      const scrollPosition = Math.round(window.pageYOffset);
      setIsCollapsed(scrollPosition > 0);
    },
    150,
    { leading: true, trailing: true }
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleThrottledScroll, true);
    }

    return () => window.removeEventListener('scroll', handleThrottledScroll);
  }, []);

  const { setCategoryView } = useEditDealContext();

  const date = new Date(firstShow.date);

  const endDate = lastShow ? new Date(lastShow.date) : null;

  const formatEventDate = (_date?: Date | null) =>
    _date?.toLocaleDateString('en-US', {
      weekday: 'short',
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      timeZone: 'UTC',
    }) ?? '';

  const eventDate = formatEventDate(date);
  const eventEndDate = formatEventDate(endDate);

  const venue = firstShow.venue;

  const handleSubmitSplitDeal = async () => {
    const dealToUse = contract.masterDeal && contract.crossed === 'Crossed' ? contract.masterDeal : deal;

    if (dealToUse?.client._id === undefined) {
      return;
    }

    if (
      dealToUse?.guarantee !== undefined &&
      +dealToUse?.guarantee === 0 &&
      contract?.guarantee !== undefined &&
      +contract?.guarantee === 0
    ) {
      return toast.warn(
        ToastWrapper(
          'You must enter a Guarantee amount to be able to Split a Deal',
          cypressTags.DEAL_FORM.SPLIT_DEAL_ERROR
        ),
        {
          className: classes.warningClass,
          autoClose: 3000,
        }
      );
    }

    const businessData = await getBusinessEntities(dealToUse?.client._id);

    if (businessData && businessData.length < 1) {
      return toast.warn(
        ToastWrapper('You must have at least one business entities', cypressTags.DEAL_FORM.SPLIT_DEAL_ERROR),
        {
          className: classes.warningClass,
          autoClose: 3000,
        }
      );
    }

    if (isSplitDeal && dealToUse?.dealSplits && dealToUse?.dealSplits.length > 0) {
      return toast.warn(ToastWrapper('Deal Split has been configured and saved, please remove the splits first', ''), {
        className: classes.warningClass,
        autoClose: 3000,
      });
    }

    // it is eligible to split, now do something
    setIsSplitDeal && setIsSplitDeal(!isSplitDeal);
  };

  const transition = 'transition-all duration-450 ease-in';

  const [artistImage, setArtistImage] = useState<string | undefined>(undefined);
  useEffect(() => {
    async function fetchArtistImage() {
      const imageUrl = await getArtistThumbUrl(artistProfilePic || '', THUMBNAIL_WIDTH_HEIGHT, THUMBNAIL_WIDTH_HEIGHT); // Assuming the image URL is in the response as text
      setArtistImage(imageUrl);
    }
    fetchArtistImage();
  }, []);

  return (
    <div
      className={twMerge(clsx('w-full h-[270px] md:h-[300px]', { 'z-50 md:z-10': isCollapsed }))}
      data-cy={cypressTags.DEAL_FORM.HEADER.CONTAINER}
    >
      <Helmet>
        <title>Touring | {client.name} deal</title>
      </Helmet>
      <div
        data-cy={CypressTags.DEAL_FORM.HEADER.CONTAINER}
        className={twMerge(
          clsx(
            'z-10 top-0 bg-white text-greyMasala font-sans text-lg font-normal h-[270px] md:h-[300px] flex flex-col justify-end w-full',
            {
              '-translate-y-[150px] md:-translate-y-[100px]': isCollapsed,
              'translate-y-[0]': !isCollapsed,
            },
            transition
          )
        )}
      >
        <ArtistBannerBg imageUrl={artistImage} />

        <div className="relative w-full content-wrapper">
          <div className="w-full flex justify-between items-center">
            {/* Duplicating the client information blocks to gracefully fade them when transitioning between collapsed states. */}
            <div className="grid grid-cols-1 w-full">
              <div
                className={twMerge(
                  clsx(
                    'flex flex-row items-end text-white col-start-1 row-[1/2] transition-opacity duration-200 ease-in-out',
                    {
                      'opacity-1': isCollapsed,
                      'opacity-0': !isCollapsed,
                    }
                  )
                )}
              >
                <ClientName
                  isCollapsed={true}
                  name={client.name}
                  url={getEntityProfileUrl(EntityDesignateType.Person, deal?.client._id, EntityType.Client)}
                />

                <VenueDetails venue={venue} showOnMobile={false} />
                {isCollapsed && (
                  <EventDateDetails eventDate={eventDate} eventEndDate={eventEndDate} showOnMobile={false} />
                )}
              </div>
              {isCollapsed && (
                <div className="flex flex-row md:hidden items-end text-white col-start-1 row-1 mt-2 w-full ml-6">
                  <VenueDetails venue={venue} showOnMobile={true} />
                  <EventDateDetails eventDate={eventDate} eventEndDate={eventEndDate} showOnMobile={true} />
                </div>
              )}
              <div
                className={twMerge(
                  clsx('flex col-start-1 row-[1/2] transition-opacity duration-200 ease-in-out', {
                    'opacity-0': isCollapsed,
                    'opacity-1': !isCollapsed,
                  })
                )}
              >
                <div className="aspect-square w-20 h-20  mr-6 overflow-hidden hidden md:block">
                  {artistImage ? (
                    <img src={artistImage} alt={client.name} />
                  ) : (
                    <UserIcon className="w-full h-full bg-white text-black mix-blend-screen" />
                  )}
                </div>

                <div className="flex flex-col justify-between text-white ml-4 md:ml-0">
                  <div className="flex flex-row  md:hidden self-start mb-3">
                    <ContractStatusIndicator contractStatus={contract.status} />
                  </div>
                  <ClientName
                    isCollapsed={false}
                    name={client.name}
                    url={getEntityProfileUrl(EntityDesignateType.Person, deal?.client._id, EntityType.Client)}
                  />

                  {!isCollapsed && (
                    <div className="flex mt-2">
                      <VenueDetails venue={venue} showOnMobile={true} />
                      <EventDateDetails eventDate={eventDate} eventEndDate={eventEndDate} showOnMobile={true} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="hidden md:flex md:flex-row md:self-end">
              <ContractStatusIndicator contractStatus={contract.status} />
            </div>
          </div>

          <div
            className={twMerge(
              clsx(
                {
                  'py-4': isCollapsed,
                  'pt-2 pb-4 md:py-6': !isCollapsed,
                },
                transition
              )
            )}
          >
            <hr
              className={twMerge(
                clsx('border-greyCloudy', {
                  'hidden md:block': isCollapsed,
                  block: !isCollapsed,
                })
              )}
            />
          </div>

          <div
            className={twMerge(
              clsx('w-full justify-end items-center md:mb-4', {
                'hidden md:flex': isCollapsed,
                'flex flex-row': !isCollapsed,
              })
            )}
          >
            {/* dropdown for category */}
            <div className="flex flex-row items-center ml-4 md:ml-0 mr-6 md:mr-10">
              <DropdownWithToggles onUpdate={setCategoryView} />
            </div>
            <div className="items-center mr-auto hidden md:flex md:flex-row">
              <FlyoutMenu />
            </div>
            <div className="items-center mr-auto flex flex-row  md:hidden">
              <div className="relative mb-2" ref={overviewRef}>
                <div
                  className="inline-flex items-left gap-x-1 text-sm leading-6 text-white cursor-pointer"
                  onClick={() => setOverviewMenuOpen(!overviewMenuOpen)}
                >
                  <span className="ml-0 mb-1 text-white">Overview</span>
                  <ChevronDownIcon
                    width={15}
                    className={twMerge(clsx('h-6 w-5 transition-all duration-200', { 'rotate-180': overviewMenuOpen }))}
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                ref={dealFormModalRef}
                className="flex flex-row items-center md:hidden bg-white px-4 py-2 mb-2 ml-0 font-extended mr-4"
                onClick={() => {
                  setDealFormModalOpen(!dealFormModalOpen);
                }}
              >
                <EllipsisHorizontalIcon width={26}></EllipsisHorizontalIcon>
              </button>
            </div>
            <div className="text-sm gap-x-4 hidden md:flex">
              {/* preview button */}
              {/* <PreviewDeal contractId={contract._id} /> */}
              <PreviewDealDropdown contractId={contract?._id} />

              <SplitDealButton isSplit={isSplitDeal} onClick={handleSubmitSplitDeal} />
              {/* link deal button */}
              <LinkedDealsModal
                dealId={dealId}
                dealType={dealType}
                contract={contract}
                setContract={setContract}
                mobileView={false}
              />
            </div>
          </div>
        </div>

        {/* <hr className="border-greyCloudy mt-2 -mb-2" /> */}
      </div>
      {dealFormModalOpen && (
        <MobileSubMenuModal setModalOpen={setDealFormModalOpen}>
          <div className="grid grid-cols-1 justify-items-start ml-4 gap-6">
            <button
              className="text-black"
              onClick={() => handleSelect({ option: GENERATE_PREIVIEW_OPTIONS[0], contractId: contract?._id })}
            >{`Generate Deal Memo(s)`}</button>
            <button
              className="text-black"
              onClick={() => handleSelect({ option: GENERATE_PREIVIEW_OPTIONS[1], contractId: contract?._id })}
            >{`Generate Contract(s)`}</button>
            <button
              data-cy={cypressTags.DEAL_FORM.SPLIT_DEAL_BUTTON}
              className="text-black"
              onClick={handleSubmitSplitDeal}
            >
              {isSplitDeal ? 'Remove Split Deal' : 'Split Deal'}
            </button>
            <LinkedDealsModal
              dealId={dealId}
              dealType={dealType}
              contract={contract}
              setContract={setContract}
              mobileView={true}
            />
          </div>
        </MobileSubMenuModal>
      )}
      {overviewMenuOpen && (
        <div className="flex md:hidden" ref={overviewRef}>
          <MobileSubMenuModal setModalOpen={setOverviewMenuOpen}>
            <FlyoutMenuMobile overviewRef={overviewRef} onLinkClick={onLinkClick}></FlyoutMenuMobile>
          </MobileSubMenuModal>
        </div>
      )}
    </div>
  );
};
