import { FC } from 'react';
import Body from '.';
import { TableData } from '../types';
import { BodyCellFullProps } from './Cell/Full';

export interface BodyProps {
  data: TableData;
  renderCellContent?: FC<BodyCellFullProps<any>>;
}

const Full: FC<BodyProps> = ({ data, renderCellContent }) => (
  <Body.Root>
    <Body.Content data={data} renderCellContent={renderCellContent} />
  </Body.Root>
);

export default Full;
