import { RuleViolation, ViolationsMap } from '../../../../types';
import { ViolationWithDate } from '../../../../types/ViolationsMap';

/**
 * Returns a unique array, uniqueness is of object is determined by the provided 'value'
 * @param array
 * @param value
 */
const uniqueByValue = <T>(array: T[], value: keyof T): T[] => {
  return array.filter((elem, index) => array.findIndex((obj) => obj[value] === elem[value]) === index);
};

/**
 * Determines which keys to interrogate from the Updated and Old Violations map to determine how
 * to update TableRows & Violations state.
 *
 * @param updatedId - id of tableRow that was updated
 * @param old - current ViolationsMap in context
 * @param updated - ViolationsMap updates to digest
 */
export const parseKeys = (updatedId: string, old: ViolationsMap, updated: ViolationsMap): string[] => {
  const oldKeys: string[] = [];

  Object.keys(old).forEach((key) => {
    let oldInherited = old[key]?.inherited ? old[key].inherited : [];
    let oldOwned = old[key]?.owned ? old[key].owned : [];
    oldInherited = oldInherited.filter((v) => v.show !== updatedId);
    oldOwned = oldOwned.filter((v) => v.show !== updatedId);
    if (oldOwned.length > 0 || oldInherited.length > 0) {
      oldKeys.push(key);
    }
  });

  const NEW: string[] = [...Object.keys(updated), ...oldKeys].filter(
    (value, index, self_) => self_.indexOf(value) === index
  );

  return NEW;
};

/**
 * Determines all the map of 'Owned' Violations to propagate through the Violations map from the
 * Old and Updated Maps
 *
 * @param updatedId
 * @param old
 * @param updated
 */
export const parseOwned = (
  updatedId: string,
  old: ViolationsMap,
  updated: ViolationsMap
): Record<string, RuleViolation[]> => {
  const keys = parseKeys(updatedId, old, updated);
  const ownedMap: Record<string, RuleViolation[]> = {};
  keys.forEach((key: string) => {
    if (key === updatedId) {
      ownedMap[key] = updated[key]?.owned ? updated[key].owned : [];
    } else {
      const fromOld = old[key]?.owned ? old[key].owned : [];
      const fromUpdated = updated[key]?.owned ? updated[key].owned : [];
      ownedMap[key] = uniqueByValue([...fromOld, ...fromUpdated], 'show');
    }
  });
  return ownedMap;
};

/**
 * Determines all 'Inherited' Violations, returns the updated ViolationsMap for state.
 *
 * @param updatedId
 * @param old
 * @param updated
 */
export const parseInherited = (updatedId: string, old: ViolationsMap, updated: ViolationsMap): ViolationsMap => {
  const ownedMap: Record<string, RuleViolation[]> = parseOwned(updatedId, old, updated);
  const final: ViolationsMap = {};
  for (const [key, owned] of Object.entries(ownedMap)) {
    final[key] = { ...final[key], owned };
    for (const violation of owned) {
      const id = violation.show;
      const fromUpdated = updated[id]?.inherited ? updated[id].inherited : [];
      const prev = final[id]?.inherited ? final[id].inherited : [];
      const all = uniqueByValue([...prev, ...fromUpdated, { ...violation, show: key } as ViolationWithDate], 'show');
      final[id] = { ...final[id], inherited: all as ViolationWithDate[] };
    }
  }
  return final;
};

/**
 * Duplicates all owned and inherited violations for a new TableRow
 * @param old
 * @param findId
 * @param duplicateId
 */
export const duplicateInherited = (old: ViolationsMap, findId: string, duplicateId: string): ViolationsMap => {
  const violations = { ...old };
  violations[duplicateId] = { ...violations[findId] };

  // Duplicate all instances of inherited with findId
  Object.keys(violations).forEach((key) => {
    const inherited = violations[key]?.inherited?.filter((v) => v.show === findId);
    const owned = violations[key]?.owned?.filter((v) => v.show === findId);
    if (inherited && inherited.length > 0) {
      violations[key].inherited.push({ ...inherited[0], show: duplicateId });
    }
    if (owned && owned.length > 0) {
      violations[key].owned.push({ ...owned[0], show: duplicateId });
    }
  });
  return violations;
};

/**
 * Completely removes all instances of violations relating to the removedId
 *
 * @param old
 * @param removeId
 */
export const removeInherited = (old: ViolationsMap, removeId: string): ViolationsMap => {
  const violations = { ...old };
  delete violations[removeId];

  Object.keys(violations).forEach((key) => {
    const inherited = violations[key]?.inherited;
    const owned = violations[key]?.owned;
    if (inherited && inherited.length > 0) {
      violations[key].inherited = violations[key].inherited.filter((v) => v.show !== removeId);
    }
    if (owned && owned.length > 0) {
      violations[key].owned = violations[key].owned.filter((v) => v.show !== removeId);
    }
  });
  return violations;
};
