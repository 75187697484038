import { iPerson } from 'grid-pages/interfaces';
import { contactUrlForId } from 'urls';

import BlankCell from '../BlankCell';
import { FC } from 'react';

export type NameRendererProps = {
  valueFormatted?: iPerson;
};

const NameRenderer: FC<NameRendererProps> = ({ valueFormatted }) => {
  if (!valueFormatted?._id || !valueFormatted?.name) {
    return <BlankCell />;
  }

  return valueFormatted?._id || valueFormatted?.url ? (
    <a
      href={valueFormatted?.url || contactUrlForId(valueFormatted?._id)}
      className="text-curiousBlue"
      rel="noopener"
      target="_blank"
    >
      {valueFormatted?.name}
    </a>
  ) : (
    <span>{valueFormatted?.name}</span>
  );
};

export default NameRenderer;
