import { Checkbox } from '@united-talent-agency/onyx-components';
import { CheckboxProps } from '@united-talent-agency/onyx-components/Forms/Checkbox/Checkbox';
import { TableRowData } from 'components/Table/types';
import { FC, useCallback } from 'react';
import Table from '../../Table';
import { ShowsAgendaData } from '../types';

export interface HeaderColumnProps {
  row: TableRowData;
  index: number;
  data: ShowsAgendaData;
  setData: (data: ShowsAgendaData) => void;
}

type HandleOnSelectFn = (index: number) => Required<CheckboxProps>['onChange'];

const BodyCell: FC<HeaderColumnProps> = ({ row, index, data, setData }) => {
  const handleOnSelect = useCallback<HandleOnSelectFn>(
    (_index) => (e) => {
      setData(
        data?.map((_row, rowIndex) => ({
          ..._row,
          selected: _index === rowIndex ? e.target.checked : _row.selected,
        }))
      );
    },
    [setData]
  );

  return (
    <Table.Header.Cell
      key={`row-${index}`}
      className="sticky print:relative left-0 bg-transparent max-w-none min-w-[64px] md:min-w-[64px] print:min-w-[64px] print:md:min-w-[64px] print:border-b-0"
    >
      <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-1 min-w-[72px] max-h-full md:min-w-[72px] print:min-w-[72px] print:md:min-w-[72px] bg-gradient-to-r from-[calc(100%-6px)] from-transparent via-[rgba(0,0,0,0.1)] via-[calc(100%-6px)] to-transparent to-100% print:bg-none" />
      <div className="flex absolute top-0 right-[6px] bottom-0 left-0 min-w-[64px] md:min-w-[64px] print:min-w-[64px] print:md:min-w-[64px] border-b-2 border-solid border-[#f5f5f5]">
        <div className="flex h-full w-full bg-white print:bg-transparent">
          <div className="flex flex-1 flex-row items-center px-4 md:px-6 print:px-0 print:md:px-0 overflow-x-hidden">
            <div className="relative flex max-md:flex-1 md:items-center">
              <Checkbox checked={row?.selected} onChange={handleOnSelect(index)} className="print:hidden" />
              <span className="absolute md:pl-6 print:md:pl-2 ml-2 print:ml-0 md:left-full md:ml-0 max-md:top-1/2 max-md:left-1/2 max-md:-translate-y-1/2 max-md:-translate-x-1/2 text-sm whitespace-nowrap max-md:rotate-90 print:text-xs font-normal print:font-bold">
                {row?.timelineLabel}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Table.Header.Cell>
  );
};

export default BodyCell;
