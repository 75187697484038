import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import HeroIcon from 'components/icons/HeroIcon';

const MENU_CONTENT_ITEM_HEIGHT = 43;
const MENU_HEIGHT = 500;

interface IActionsMenu {
  forwardRef?: any;
  actionItems: any[];
  width?: number;
  className?: string;
  topOffset?: number;
  closeToggle: () => void;
  alignRight?: boolean;
}

export const ActionsMenu = ({
  forwardRef,
  actionItems,
  width,
  className = '',
  topOffset = 32,
  closeToggle,
  alignRight,
}: IActionsMenu) => {
  const [isOpen, setIsOpen] = useState(true);
  const ref = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useClickAway(ref, () => {
    closeToggle();
  });

  /*
   * find the position of the dropdown menu in reference to the window using a ref passed in from the consumer
   * forwardRef contains the value of its offsetTop, which is the distance of the outer border of the element
   * that ref was attached to, relative to the inner border of the top of the offsetParent node
   * so this value along with those of the window height and scrollY (number of pixels that the document is
   * currently scrolled vertically) can give an idea of where the menu is and whether to bounce it up instead of down
   */
  const menuPositionTop =
    forwardRef && window?.scrollY - forwardRef?.current?.offsetTop + window?.innerHeight < MENU_HEIGHT;
  const getCustomStyles = () => {
    return menuPositionTop ? { marginTop: -(actionItems.length * MENU_CONTENT_ITEM_HEIGHT) - topOffset } : {};
  };

  return (
    <div ref={ref} style={getCustomStyles()} className="flex flex-col flex-wrap relative z-[1]">
      {isOpen && (
        <div className={`${className} inline-block`}>
          <div className={`block absolute min-w-[${width || 250}px] shadow-lg bg-white ${alignRight ? 'right-0' : ''}`}>
            {(menuPositionTop ? [...actionItems].reverse() : actionItems).map((item: any) => {
              const InlineComponent = item.Component
                ? item.Component
                : () => (
                    <div style={{ display: 'flex', alignItems: 'center', height: MENU_CONTENT_ITEM_HEIGHT }}>
                      <HeroIcon iconString={item.icon} width={16} className="mr-2" />
                      {item.title}
                    </div>
                  );
              return (
                <span
                  data-cy={item?.dataCy}
                  onClick={() => {
                    !item.Component && item.action();
                    !item.Component && toggleOpen();
                  }}
                  key={item.title}
                  className="px-4 no-underline block cursor-pointer hover:bg-[#29a1db26]"
                >
                  <InlineComponent />
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
