// import { SearchBar } from '@united-talent-agency/components';
import { AgGridReact } from 'ag-grid-react';
import { StickyHeaderPage } from 'features/layouts';
import { PageHeader } from 'grid-pages/components';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import PromotersTable from 'promoters/organisms/Table';
import { ReactElement, useCallback, useRef, useState } from 'react';

/**
 * Contact redirect Editor
 */
export const Promoters = (): ReactElement => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();

  // States
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const gridApiRef = useRef<AgGridReact>(null);

  const handleOnExport = () => {
    setIsExporting(true);
  };

  const renderFixedContainer = useCallback(() => <PageHeader onExport={handleOnExport} title="Promoters" />, []);

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="main-wrapper">
        <div
          className="grid-height content-wrapper md:pt-10"
          onMouseOver={lockOverscrollNavigation}
          onMouseLeave={unlockOverscrollNavigation}
        >
          <PromotersTable gridApiRef={gridApiRef} isExporting={isExporting} setIsExporting={setIsExporting} />
        </div>
      </div>
    </StickyHeaderPage>
  );
};
