import { StickyHeaderPage } from 'features/layouts';
import { PageHeader } from 'grid-pages/components';
import { PRESETS, PresetReport } from 'grid-pages/pages/PresetReport';
import { RANGES } from 'grid-pages/utils/date-utils';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import { useCallback, useState } from 'react';

const Deals = () => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();

  // States
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const handleOnExport = () => {
    setIsExporting(true);
  };

  const renderFixedContainer = useCallback(() => <PageHeader onExport={handleOnExport} title="Settled Deals" />, []);

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="main-wrapper">
        <div
          className="grid-height content-wrapper md:pt-10"
          onMouseOver={lockOverscrollNavigation}
          onMouseLeave={unlockOverscrollNavigation}
        >
          <PresetReport
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            preset={PRESETS.DEALS}
            datePreset={RANGES.PAST_THREE_MONTHS}
          />
        </div>
      </div>
    </StickyHeaderPage>
  );
};

export default Deals;
