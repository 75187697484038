import { TableRowData } from 'components/Table/types';
import { FC } from 'react';
import Row from '.';
import Cell from '../Cell';
import { BodyCellFullProps } from '../Cell/Full';

type BodyRowFullProps = {
  row: TableRowData;
  index: number;
  renderCellContent?: FC<BodyCellFullProps<any>>;
};

const RowFull: FC<BodyRowFullProps> = ({ row, index, renderCellContent }) => (
  <Row.Root index={index} printable={row?.selected} className="print:even:bg-[#f5f5f5]">
    {row?.data?.map((column, columnIndex) => (
      <Cell.Full column={column} columnIndex={columnIndex} rowIndex={index} key={columnIndex}>
        {renderCellContent && renderCellContent({ column, columnIndex, rowIndex: index })}
      </Cell.Full>
    ))}
  </Row.Root>
);

export default RowFull;
