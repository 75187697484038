import { Control, Controller, FieldValues, Path, PathValue, UseFormGetValues } from 'react-hook-form';

interface Props<T extends FieldValues> {
  layout: string;
  field: Path<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  control: Control<T>;
  getValues: UseFormGetValues<T>;
  dataCy: string;
  text?: string;
  style?: string;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const formattedTotal = (value: number) => {
  const formatted = formatter.format(value || 0);
  return `${formatted} USD`;
};

export const Totals = <T extends FieldValues>(props: Props<T>) => (
  <div data-cy={props.dataCy}>
    <Controller
      name={props.field}
      defaultValue={props.defaultValue}
      control={props.control}
      render={({ field: { value } }) => {
        return (
          <div className={'w-full'}>
            <div className={props.layout}>
              <div className={`pt-3 ${props.style} col-span-1 pr-1 place-self-end text-lg font-medium items-center`}>
                Total
              </div>
              <div className={'pl-4'}>
                <div className={'w-full text-greyCloudy text-xs'}>{props.text || 'Amount Due'}</div>
                <div className={'text-sm'}>{formattedTotal(value)}</div>
              </div>
            </div>
          </div>
        );
      }}
    />
  </div>
);
