import { updateDealShows } from 'api';
import { handleUpdateDealShowsResponse } from 'api/shows';
import { FORM_NAMES } from 'deal-form/data/constants';
import { TRAVEL_EMPTY_FORM } from 'deal-form/data/organisms';
import { iFormProps } from 'deal-form/interfaces/general';
import { iOrganismTravelAccommodations } from 'deal-form/interfaces/organisms';
import { MasterDealWarning } from 'deal-form/ui/MasterDealWarning';
import React, { useEffect, useMemo, useState } from 'react';
import { Deal, Show } from 'types';

import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { getMasterShow } from '../../helpers/showHelpers';

/**
 * Helper method to extract terms type to access travel accommodations
 * @param show
 * @returns string
 */
const getTermsType = (show: Show) => {
  let termsType = '';
  for (const key of Object.keys(show.performers[0].terms)) {
    if (key !== '_id') {
      termsType = key;
      break;
    }
  }
  return termsType;
};

/**
 * Helper method to format payload
 * @param show, termsType, travelAccommodations
 * @param termsType
 * @param travelAccommodations
 * @returns [{showId, updateBody}]
 */
const createUpdatePayloads = (show: Show, termsType: string, travelAccommodations: string) => {
  const updatedTerms = {
    ...show.performers[0].terms,
    [termsType]: {
      ...show.performers[0].terms[termsType],
      travelAccommodations: travelAccommodations,
    },
  };

  return [
    {
      showId: show._id,
      updateBody: {
        performers: [
          {
            terms: updatedTerms,
            billing: show.performers[0].billing,
          },
        ],
      },
    },
  ];
};

const TravelAccommodationsForm: React.FC<iFormProps> = ({ deal, setDeal, contract, setContract, isOpen, locked }) => {
  const [show, setShow] = useState<Show | null>(null);
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  const [termsType, setTermsType] = useState<string>('flat');
  const [crossed, setCrossed] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    let dealToUse = deal;
    if (contract && contract.masterDeal && contract.crossed === 'Crossed') {
      dealToUse = contract.masterDeal;
      setMasterDeal(contract.masterDeal);
      setCrossed(true);
    } else {
      setMasterDeal(null);
      setCrossed(false);
    }

    const masterShow = getMasterShow(dealToUse.shows);
    setShow(masterShow);
    setTermsType(getTermsType(masterShow));
  }, [deal, contract]);

  const onSubmit = async (data: iOrganismTravelAccommodations) => {
    const dealToUse = masterDeal && crossed ? masterDeal : deal;
    const masterShow = getMasterShow(dealToUse.shows);

    const updates = createUpdatePayloads(masterShow, termsType, data.accommodation);
    try {
      setCanSubmit(false);
      const response = await updateDealShows({ dealId: dealToUse._id, updates });
      if (contract && masterDeal && crossed && setContract) {
        setContract({
          ...contract,
          masterDeal: response.body as Deal,
        });
      } else if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });

      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('there was an error', e);
      setCanSubmit(true);
    }
  };

  const defaultValues = useMemo(
    () =>
      show?.performers?.[0]?.terms?.[termsType]?.travelAccommodations
        ? { accommodation: show?.performers?.[0]?.terms?.[termsType]?.travelAccommodations }
        : TRAVEL_EMPTY_FORM,
    [show]
  );

  return (
    <>
      {masterDeal && crossed && <MasterDealWarning crossed={crossed} fields={['Travel Accommodations']} />}

      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className=""
        defaultValues={defaultValues}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.TRAVEL_ACCOMMODATIONS}
      >
        <TextArea
          containerClassName="pb-4"
          label={false}
          placeholder="I can type anything here."
          rules={{
            maxLength: {
              value: 1200,
              message: getMaxCharMessage('your response', 1200),
            },
          }}
          id="accommodation"
          rows={6}
          disabled={!isOpen || locked}
        />
      </Form>
    </>
  );
};

export default TravelAccommodationsForm;
