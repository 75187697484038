//
// This component is deprecated, use TicketScalingCombined instead!
//

import { handleUpdateDealShowsResponse, iUpdateShowPayload, updateDealShows } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  TICKET_FEES_EMPTY_FORM,
  TICKET_FEES_EMPTY_TIER_ITEM,
  TICKET_FEES_FEETYPE_OPTIONS,
  TICKET_FEES_FEE_TYPE_OPTIONS,
} from 'deal-form/data/organisms';
import { Currency } from 'deal-form/form-controls/Currency';
import { Input } from 'deal-form/form-controls/Input';
import { TextField } from 'deal-form/form-controls/TextField';
import { formatDate, getShowFromTierId, getTicketCurrency } from 'deal-form/helpers/dealHelpers';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iCurrencyField, iOrganismTicketFees, iOrganismTicketFeesItem } from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Fee } from 'types';

import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';
import Totals from '../../form-controls/Totals';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { EmptyOrganismMessage } from '../../ui/EmptyOrganismMessage';

export const COLUMN_LABELS = [
  { value: 'Tier', isRequired: true },
  { value: 'Fee Type', isRequired: true },
  { value: 'Fee Amount', isRequired: false },
  { value: 'Fee Percentage', isRequired: false },
  { value: 'Calculation', isRequired: true },
  { value: 'Gross' },
  { value: 'Description' },
];
export const GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_0.5fr_0.5fr_0.5fr_150px_2fr] gap-2 ';

// interface ITicketFeeField {
//   index?: number;
//   handleChange?: any;
//   disabled?: boolean;
//   id?: string;
// }

/**
 * Returns a formatted ticket fee id generated from show and tier properties
 *
 * @param {string} id - show id
 * @param {string} earlyLate - show early or late label
 * @param {string} tier - tier
 * @returns
 */
const formatTicketFeeId = (id: string, earlyLate: string, tier: string) =>
  `${id}|${earlyLate === 'Neither' ? 'Only Show' : earlyLate}|${tier}`;

const TicketFeesForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked }) => {
  const [availableTierOptions, setAvailableTierOptions] = useState<iOptions[]>([]);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [applyToAll, setApplyToAll] = useState<boolean>(false);
  const [isShowSettled, setIsShowSettled] = useState<boolean>(false);

  // const FeeFieldPercent: FC<ITicketFeeField> = ({ id, index, disabled, handleChange }) => {
  //   const { getValues } = useFormContext();
  //   const ft = getValues(`tiers.${index}.feeType`);

  //   return (
  //     <Input
  //       // containerClassName={`${ft !== 'percentage' ? 'hidden' : ''}`}
  //       label={false}
  //       fieldType="percent"
  //       rules={{
  //         min: { value: 0.1, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
  //         max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
  //       }}
  //       id={`${id}`}
  //       handleChange={handleChange}
  //       step="0.1"
  //       disabled={disabled || ft !== 'percentage'}
  //     />
  //   );
  // };

  const onSubmit = async (data: iOrganismTicketFees) => {
    let errorsFound = false;
    // do a quick validation
    data.tiers.forEach((t) => {
      if (!t.tier || t.tier === '' || t.calculation === '' || (!t.fee?.value && !t.percent)) errorsFound = true;
    });

    if (errorsFound) return;
    const updateData: iUpdateShowPayload[] = [];

    // loop through the shows
    deal.shows.forEach(({ _id, ticketTiers }) => {
      const updatedTiers = [...ticketTiers];
      const feesInShow =
        applyToAll && !isShowSettled ? data.tiers : data.tiers.filter((t) => getShowFromTierId(t.tier).showId === _id);

      updatedTiers.forEach((t) => {
        // check to see if the tier has any fees
        const feesInTier = feesInShow.filter((f) => getShowFromTierId(f.tier).tierLabel === t.tier);

        if (feesInTier.length > 0) {
          // if yes, replace
          t.fees = feesInTier.map(({ fee, calculation, description, percent, feeType }) => {
            return {
              amount: fee?.value,
              percent,
              percentFlag: feeType.toLowerCase() === 'percentage',
              type: calculation,
              description,
            } as Fee;
          });
        } else {
          // if no, clear []
          t.fees = [];
        }
      });
      // loop through the shows
      updateData.push({ showId: _id, updateBody: { ticketTiers: updatedTiers } });
    });
    if (updateData.length === 0) return;

    try {
      setCanSubmit(false);
      const response = await updateDealShows({
        dealId: deal._id.toString(),
        updates: updateData,
      });

      if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });
      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR UPDATING TICKET FEES', e);
      setCanSubmit(true);
    }
  };

  useEffect(() => {
    const applyAll = deal?.allShowsFlag || false;
    setApplyToAll(applyAll);

    //show only master show if tiers are applied to all shows
    const dealsToShow = applyAll ? deal.shows.filter((s) => s.masterShowFlag === true) : deal.shows;

    const opts: iOptions[] = [];

    dealsToShow.forEach((show) => {
      show.ticketTiers.forEach((tier) => {
        const option: iOptions = {
          id: formatTicketFeeId(show._id, show.earlyLate, tier.tier),
          label: `${applyAll ? 'All Shows' : formatDate(show.date)} ${
            applyAll ? '–' : `(${show.earlyLate === 'Neither' ? 'Only Show' : show.earlyLate})`
          }\n${tier.tier}`,
          disabled: show.status === 'Settled',
        };
        opts.push(option);
      });
    });

    setAvailableTierOptions(opts);
  }, [deal]);

  const checkForTicketFees = (applyAll: boolean) => {
    //show only master show if tiers are applied to all shows

    // check to see if any shows are settled
    const isShowSettledVal = deal.shows.some((show) => show.status === 'Settled');
    setIsShowSettled(isShowSettledVal);

    const dealsToShow = applyAll ? deal.shows.filter((s) => s.masterShowFlag === true) : deal.shows;

    const allFees: iOrganismTicketFeesItem[] = [];

    dealsToShow.forEach(({ _id, earlyLate, ticketTiers }) => {
      if (ticketTiers.length > 0) {
        ticketTiers.forEach(({ fees, currency, tier, available, comp, advance }) => {
          if (fees.length > 0) {
            fees.forEach(({ amount, description, type, percentFlag, percent }) => {
              const sellable = available - comp;
              const item: iOrganismTicketFeesItem = {
                calculation: type,
                description,
                feeType: percentFlag === true ? 'percentage' : 'flat',
                percent,
                fee: {
                  value: amount,
                  currency,
                },
                tier: formatTicketFeeId(_id, earlyLate, tier),
                gross: {
                  value:
                    type === 'Built-In'
                      ? percentFlag && percent
                        ? sellable * ((+percent / 100) * advance)
                        : +sellable * +amount
                      : -1, // this is the issue
                  currency,
                },
              };
              allFees.push(item);
            });
          }
        });
      }
    });
    return allFees;
  };

  const defaultValues = useMemo(() => {
    const allFees = checkForTicketFees(deal.allShowsFlag || false);
    return allFees.length > 0 ? { tiers: allFees } : TICKET_FEES_EMPTY_FORM;
  }, [deal]);

  /**
   * handle fee change
   *
   * The calculation on the form needs to extend beyond the current organism so it
   * is happening here
   *
   * @param val           value from the field
   * @param index         index of the field array
   * @param setValue      set value function to set a value in the organism state
   * @param getValues     get values function to get all the values of the organism state
   */
  const handleFeeChange = (
    val: iCurrencyField,
    index?: number,
    setValue?: UseFormSetValue<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>
  ) => {
    if (setValue) {
      const calc = getValues ? getValues(`tiers.${index}.calculation`) : '';
      const tier = getValues ? getValues(`tiers.${index}.tier`) : '';
      const showDetails = getShowFromTierId(tier);

      const showFromId = deal.shows.find((s) => s._id === showDetails.showId);
      const tierFromShow = showFromId?.ticketTiers.find((ticketTier) => ticketTier.tier === showDetails.tierLabel);

      if (tierFromShow) {
        // const tierGross = (tierFromShow?.available - tierFromShow?.comp) * tierFromShow.advance;
        const sellable = tierFromShow?.available - tierFromShow?.comp;
        if (calc === 'Built-In') {
          setValue(`tiers.${index}.gross.value`, sellable * +val.value);
          setValue(`tiers.${index}.gross.currency`, tierFromShow.currency);
        } else if (calc === 'On-Top') {
          setValue(`tiers.${index}.gross.value`, -1);
          setValue(`tiers.${index}.gross.currency`, tierFromShow.currency);
        }
      }
    }
  };

  /**
   * handle fee change
   *
   * The calculation on the form needs to extend beyond the current organism so it
   * is happening here
   *
   * @param val           value from the field
   * @param index         index of the field array
   * @param setValue      set value function to set a value in the organism state
   * @param getValues     get values function to get all the values of the organism state
   */
  const handleFeePercentChange = (
    val: string,
    index?: number,
    setValue?: UseFormSetValue<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>
  ) => {
    if (setValue) {
      const calc = getValues ? getValues(`tiers.${index}.calculation`) : '';
      const tier = getValues ? getValues(`tiers.${index}.tier`) : '';
      const showDetails = getShowFromTierId(tier);

      const showFromId = deal.shows.find((s) => s._id === showDetails.showId);
      const tierFromShow = showFromId?.ticketTiers.find((ticketTier) => ticketTier.tier === showDetails.tierLabel);

      if (tierFromShow) {
        // const tierGross = (tierFromShow?.available - tierFromShow?.comp) * tierFromShow.advance;
        const sellable = tierFromShow?.available - tierFromShow?.comp;

        if (calc === 'Built-In') {
          setValue(`tiers.${index}.gross.value`, sellable * ((+val / 100) * tierFromShow.advance));
          setValue(`tiers.${index}.gross.currency`, tierFromShow.currency);
        } else if (calc === 'On-Top') {
          setValue(`tiers.${index}.gross.value`, -1);
          setValue(`tiers.${index}.gross.currency`, tierFromShow.currency);
        }
      }
    }
  };

  return (
    <Form
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      className="form-row full-width"
      defaultValues={defaultValues}
      disabled={!isOpen || locked}
      formName={FORM_NAMES.TICKET_FEES}
      isSaveButton={availableTierOptions.length > 0}
    >
      <FieldArray
        gridClassName={GRID_LAYOUT}
        groupName="tiers"
        columns={COLUMN_LABELS}
        addButtonLabel="Add Tier"
        emptyRow={TICKET_FEES_EMPTY_TIER_ITEM}
        disabled={!isOpen || locked}
        lockRules={(field) => {
          const rule = {
            isLocked: false,
            fields: [] as string[],
          };

          const fieldShowId = field.tier.split('|')[0];

          const matchingShow = deal.shows.find((show) => show._id === fieldShowId);

          // if a show status is Settled it should not be updated any more
          if (matchingShow && matchingShow.status === 'Settled') {
            rule.isLocked = true;
            rule.fields = ['tier', 'fee', 'calculation', 'description', 'feeType'];
          }

          if (field.isMasterShow) {
            rule.isLocked = true;
          }

          return rule;
        }}
      >
        <Dropdown
          placeholder="Tier"
          id="tier"
          options={availableTierOptions}
          disabled={!isOpen || locked}
          rules={{
            required: ERROR_MESSAGES.REQUIRED_FIELDS,
          }}
        />

        <Dropdown
          placeholder="Fee Type"
          id="feeType"
          options={TICKET_FEES_FEETYPE_OPTIONS}
          disabled={!isOpen || locked}
          rules={{
            required: ERROR_MESSAGES.REQUIRED_FIELDS,
          }}
        />

        <Currency
          placeholder="Fee"
          id="fee"
          rules={{
            min: { value: 0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
          }}
          overrideCurrency={getTicketCurrency(deal)}
          handleChange={handleFeeChange}
          disabled={!isOpen || locked}
          disableIf={{ field: 'tiers.{index}.feeType', value: 'percentage' }}
        />

        <Input
          label={false}
          fieldType="percent"
          rules={{
            min: { value: 0, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
            max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
          }}
          id="percent"
          handleChange={handleFeePercentChange}
          step="0.1"
          disabled={!isOpen || locked}
          disableIf={{ field: 'tiers.{index}.feeType', value: 'flat' }}
        />

        <Dropdown
          placeholder="Calculation"
          id="calculation"
          options={TICKET_FEES_FEE_TYPE_OPTIONS}
          disabled={!isOpen || locked}
          rules={{
            required: 'Please select a valid ‘Calculation’ type',
          }}
        />

        <TextField text="--" id="gross" isCurrency className="leading-10" />

        <TextArea
          placeholder="Type description here."
          rules={{
            maxLength: {
              value: 1024,
              message: getMaxCharMessage('the description', 1024),
            },
          }}
          id="description"
          rows={1}
          disabled={!isOpen || locked}
        />
      </FieldArray>

      <>
        {availableTierOptions.length > 0 ? (
          <div className={`${GRID_LAYOUT} pr-10`}>
            <label className="form-label col-start-3 self-end">Total</label>
            <Totals
              className="whitespace-nowrap"
              id="tiers"
              total="gross"
              currencyOverride={deal.currency}
              identifier="Gross"
              label={false}
              multiplier={applyToAll ? deal.shows.length : undefined}
            />
          </div>
        ) : (
          <EmptyOrganismMessage>Please enter a Ticket Scaling tier to edit Ticket Fees.</EmptyOrganismMessage>
        )}
      </>
    </Form>
  );
};

export default TicketFeesForm;
