import { CheckIcon } from '@heroicons/react/24/outline';
import { InputHTMLAttributes, useEffect, useState } from 'react';

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string | boolean;
  className?: string;
  handleChange?: (id: string) => void;
  indeterminate?: number | boolean;
}

export const PlainCheckbox = ({ id, className, label, handleChange, indeterminate, ...props }: ICheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(props.checked || false);

  useEffect(() => {
    setChecked(props.checked || false);
  }, [props.checked]);
  return (
    <div className="relative w-full">
      <label className="relative flex cursor-pointer items-center">
        <input
          className={`bg-transparent h-4 w-4 appearance-none rounded-sm ${
            props.disabled ? 'border border-greyCloudy' : 'border border-greyCod'
          } ${checked ? (props.disabled ? 'bg-greyCloudy' : 'bg-greyCod') : ''} ${className}`}
          {...props}
          type="checkbox"
          aria-describedby={`${id}_error_help`}
          onChange={() => (handleChange ? handleChange(id) : setChecked(!checked))}
          data-testid="plain-checkbox-testid"
        />
        {indeterminate ? (
          <div className="absolute left-0 top-[-1px] flex h-full w-4 items-center justify-center text-white">–</div>
        ) : (
          checked &&
          !indeterminate && (
            <div className="absolute left-0 top-0 flex h-full w-4 items-center">
              <CheckIcon className="text-black" width={16} color="white" />
            </div>
          )
        )}
        {label !== false && (
          <span className={`ml-2 text-sm ${props.disabled ? 'text-greyCloudy' : 'text-greyCod'}`}>{label}</span>
        )}
      </label>
    </div>
  );
};

PlainCheckbox.defaultProps = {
  label: false,
  className: '',
};
