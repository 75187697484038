import { toast } from 'react-toastify';
import ToastWrapper from 'components/toast/ToastWrapper';
import { classes } from 'support/react-toastify';
import { post, updateDeal, getContractById, getDealById } from 'api';
import cypressTags from 'support/cypressTags';

const submitToNetsuite = async (contract: any, deal: any, isFinals: boolean, setDeal?: any, setContract?: any) => {
  // get latest deal version
  const latestDeal = await getDealById(deal._id);
  if (latestDeal) deal = latestDeal;

  const showStatuses = deal.shows.map((show: any) => show.status);
  // show warning if there is no confirmed, cancelled OR cancelled with payment shows
  if (
    !showStatuses.includes('Cancelled') &&
    !showStatuses.includes('Confirmed') &&
    !showStatuses.includes('Cancelled with Payment')
  ) {
    return toast.warn(
      ToastWrapper(
        'You must have at least one Confirmed, Cancelled OR Cancelled with Payment show to submit a deal',
        cypressTags.DEAL_FORM.SUBMIT_TO_NETSUITE_ERROR
      ),
      {
        className: classes.warningClass,
        autoClose: 3000,
      }
    );
  }

  if (!deal.buyer?.signatory && !contract.masterDeal?.buyer?.signatory) {
    return toast.warn(ToastWrapper('You must have a buyer signatory to submit to finance', ''), {
      className: classes.warningClass,
      autoClose: 3000,
    });
  }

  if (deal.agents.length === 0) {
    return toast.warn(ToastWrapper('You must have at least one agent to submit to finance', ''), {
      className: classes.warningClass,
      autoClose: 3000,
    });
  }

  if (!deal?.client.netsuiteId || deal?.client.netsuiteId === '')
    return toast.warn(
      ToastWrapper(
        `Client ${deal?.client?.name} is missing NetsuiteID, please create one in the Profiles Application`,
        ''
      ),
      {
        className: classes.warningClass,
        autoClose: 3000,
      }
    );

  // check if any of the agents are missing netsuiteId
  // skip agent if agent type is 'Contract Administrator'
  const agents = deal.agents;
  for (let i = 0; i < agents.length; i++) {
    if (
      agents[i].type !== 'Contract Administrator' &&
      (!agents[i].person.netsuiteId || agents[i].person.netsuiteId === '')
    )
      return toast.warn(
        ToastWrapper(
          `Agent ${agents[i].person.name} is missing NetsuiteID, please create one in the Profiles Application`,
          ''
        ),
        {
          className: classes.warningClass,
          autoClose: 3000,
        }
      );
  }

  // show toast message that submission is running in the background
  toast(ToastWrapper('Submitting to Finance, please wait for confirmation (~20 seconds)', ''), {
    className: classes.successClass,
    autoClose: 4000,
  });

  const MAX_RETRIES = 5;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      const sendToNetsuite = await post('/touring/contract/toNetsuite', contract._id);

      if (sendToNetsuite.ok && sendToNetsuite.body?.success) {
        // switching deal finals to true after submitting to netsuite
        if (isFinals) {
          const updatedDeal = await updateDeal(deal._id, { ...deal, finals: true });
          if (updatedDeal && setDeal) setDeal(updatedDeal);
        }

        // update contract to latest version after submitting to netsuite
        if (setContract) {
          const updatedContract = await getContractById(contract._id);
          if (updatedContract) setContract(updatedContract);
        }

        toast(ToastWrapper('Deal was successfully submitted to Finance.', ''), {
          className: classes.successClass,
          autoClose: 3000,
        });
        break;
      } else {
        toast(ToastWrapper('Your deal cannot be submitted at this moment, try again later.', ''), {
          className: classes.errorClass,
          autoClose: 2000,
        });
        break;
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.log(err);
        retries++;
        if (retries === MAX_RETRIES) {
          toast(ToastWrapper('Your deal cannot be submitted at this moment, try again later.', ''), {
            className: classes.errorClass,
            autoClose: 2000,
          });
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1500));
          toast(ToastWrapper(`Submit Failed, Retrying (${retries}/${MAX_RETRIES - 1})...`, ''), {
            className: classes.errorClass,
            autoClose: 2000,
          });
        }
      }
    }
  }
};

export default submitToNetsuite;
