import { ICellEditorParams, KeyCode } from 'ag-grid-community';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { EditorContainer } from './Containers';

/**
 * Get string representation of number with separators.
 *
 * @param {number|string} x
 * @returns {string}
 */
export const numberWithCommas = (x: number | string): string => {
  return x.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

/**
 * Currency Cell Editor.
 *
 * @param {ICellEditorParams} props
 * @param {ForwardedRef} ref
 */
export const CurrencyCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  /**
   * Initialize the value being handled inside this component.
   */
  const createInitialState = () => {
    let startValue;

    if (props.eventKey === KeyCode.BACKSPACE || props.eventKey === KeyCode.DELETE) {
      startValue = '';
    } else if (props.charPress) {
      startValue = props.charPress;
    } else {
      startValue = props.value != null ? props.value : '';
    }

    return { value: startValue };
  };
  const initialState = createInitialState();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(initialState.value);

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  /* Utility Methods */

  const cancelBeforeStart = props.charPress && '1234567890.'.indexOf(props.charPress) < 0;

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      isCancelBeforeStart() {
        return cancelBeforeStart;
      },

      isCancelAfterEnd() {
        return value < 0;
      },
    };
  });

  return (
    <EditorContainer>
      <div className="h-full w-full focus-visible:border-none">
        <CurrencyInput
          ref={inputRef}
          prefix="$"
          defaultValue={value}
          decimalsLimit={2}
          onValueChange={(v) => setValue(v)}
        />
      </div>
    </EditorContainer>
  );
});
CurrencyCellEditor.displayName = 'CurrencyCellEditor';
