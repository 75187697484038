import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { updateDealShows } from 'api';
import { handleUpdateDealShowsResponse, iUpdateShowPayload } from 'api/shows';
import { FORM_NAMES } from 'deal-form/data/constants';
import { RADIUS_CLAUSE_UNITS } from 'deal-form/data/organisms';
import ButtonWithContext from 'deal-form/form-controls/ButtonWithContext';
import { Checkbox } from 'deal-form/form-controls/Checkbox';
import { DropdownWithStaticSearch } from 'deal-form/form-controls/DropdownWithStaticSearch';
import { iFormProps } from 'deal-form/interfaces/general';
import { iOrganismRadiusClause } from 'deal-form/interfaces/organisms';
import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { Label } from '../../form-controls/Label';
import { TextArea } from '../../form-controls/TextArea';
import { getMasterShow } from '../../helpers/showHelpers';

const GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_1fr_200px_1fr_1fr_1fr_1fr] gap-5 items-center';

const generateDisplay = (values: FieldValues): string => {
  const generatedText = `Artist agrees not to perform within ${values.distance} ${values.miles.toLowerCase()}, ${
    values.daysBefore
  } days before and ${values.daysAfter} days after show date.`;
  return generatedText;
};

const RadiusClauseForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked }) => {
  const { shows } = deal;

  const [radiusClauseData, setRadiusClauseData] = useState<iOrganismRadiusClause | null>({
    distance: 0,
    daysBefore: 0,
    daysAfter: 0,
    display: '',
    showOnContract: false,
    miles: 'Miles',
  });

  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    const radius = getMasterShow(deal.shows).rule;

    setRadiusClauseData({
      distance: radius?.radius || 0,
      daysBefore: radius?.timeBefore || 0,
      daysAfter: radius?.timeAfter || 0,
      display: radius?.text || '',
      showOnContract: radius.printToDeal,
      miles: radius?.milesFlag ? 'Miles' : 'Kilometers',
    });
  }, [shows]);

  const handleClearClause = (_values: FieldValues, setValueMethod?: UseFormSetValue<FieldValues>) => {
    if (setValueMethod) {
      setValueMethod('distance', 0);
      setValueMethod('daysBefore', 0);
      setValueMethod('daysAfter', 0);
      setValueMethod('display', '');
      setValueMethod('showOnContract', false);
      setValueMethod('miles', 'Miles');
    }
  };

  const onSubmit = async (data: iOrganismRadiusClause) => {
    // eslint-disable-next-line no-unreachable
    const newRule = {
      // radius: toMeters(data.distance),
      // timeBefore: toMs(data.daysBefore),
      // timeAfter: toMs(data.daysAfter),
      radius: data.distance,
      timeBefore: data.daysBefore,
      timeAfter: data.daysAfter,
      text: data.display,
      printToDeal: data.showOnContract,
      milesFlag: data.miles === 'Miles',
    };
    const updateData: iUpdateShowPayload[] = [];

    const updatedShow = {
      showId: getMasterShow(shows)._id,
      updateBody: {
        rule: newRule,
      },
    };

    updateData.push(updatedShow);

    try {
      setCanSubmit(false);

      const response = await updateDealShows({
        dealId: deal._id.toString(),
        updates: updateData,
      });

      if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });

      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('error submitting');
      setCanSubmit(true);
    }
  };

  return (
    radiusClauseData && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className={GRID_LAYOUT}
        defaultValues={radiusClauseData}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.RADIUS_CLAUSE}
      >
        <Label className="text-right">Distance</Label>
        <Input fieldType="number" id="distance" disabled={!isOpen || locked} />
        <Label className="text-right">Units</Label>
        <DropdownWithStaticSearch id="miles" staticOptions={RADIUS_CLAUSE_UNITS || []} disabled={!isOpen || locked} />
        <Label className="text-right">Days Before</Label>
        <Input fieldType="number" id="daysBefore" disabled={!isOpen || locked} />
        <Label className="text-right">Days After</Label>
        <Input fieldType="number" id="daysAfter" disabled={!isOpen || locked} />
        <div className="text-right">
          <Label className="form-label">Radius Clause Display</Label>
        </div>
        <TextArea
          label={false}
          placeholder="This is your radius clause."
          id="display"
          containerClassName="col-span-7"
          rows={6}
          disabled={!isOpen || locked}
        />
        <div className="col-start-2 col-span-2">
          <Checkbox id="showOnContract" checkColor="black" label="Show on Contract" disabled={!isOpen || locked} />
        </div>
        <div className="col-start-7 col-span-2 flex justify-end">
          <div className="flex flex-row gap-2">
            <ButtonWithContext
              variant="primary"
              onClick={handleClearClause}
              disabled={!isOpen || locked}
              updateFormName="display"
              shouldDirty
            >
              CLEAR/REMOVE CLAUSE
            </ButtonWithContext>
            <ButtonWithContext
              leftIcon={<ArrowPathIcon width={24} height={24} />}
              variant="primary"
              onClick={generateDisplay}
              disabled={!isOpen || locked}
              updateFormName="display"
              shouldValidate
              shouldDirty
            >
              GENERATE DISPLAY
            </ButtonWithContext>
          </div>
        </div>
      </Form>
    )
  );
};

export default RadiusClauseForm;
