import { iOrganismShowDetailsItem } from 'deal-form/interfaces/organisms';
import { Deal, Show } from 'types';
import { formatDate } from '../dealHelpers';
import moment from 'moment';

/**
 * Returns a the mastershow from a list of shows
 *
 * @param {object} shows - list of shows
 * @returns {object} show - the master show
 */
export const getMasterShow = (shows: Show[]) => shows.filter((show) => show.masterShowFlag)?.[0] || shows[0];

/**
 * Returns a list of shows sorted by `createdOn`, with the masterShow always
 * as the first element of the array
 *
 * @param {object} shows - list of shows
 * @returns {object} shows - sorted list of shows
 */
export const getSortedShows = (shows: Show[]) => {
  // Remove masterShow
  const nonMaster = shows.filter((show) => !show.masterShowFlag);

  // Sort by createdOn
  // TODO: if same date, sort criteria should be Only > Early > Late
  const sortedShows = nonMaster.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const masterShow = shows.find((show) => show.masterShowFlag);

  // Master show should always be first on the array
  if (masterShow) {
    sortedShows.unshift(masterShow);
  }

  return sortedShows;
};

/**
 * Returns a list of shows with show details form properties
 *
 * @param {object} deal - a deal containing a list of shows
 * @returns {object} details - list of shows
 */
export const getShowDetails = (deal: Deal): iOrganismShowDetailsItem[] => {
  const sortedShows = getSortedShows(deal.shows);

  const details = sortedShows.map((show) => ({
    cancelled: show.canceledOn ? formatDate(show.canceledOn) : '',
    confirmed: show.confirmedOn ? formatDate(show.confirmedOn) : '',
    date: moment(show.date).utc().toDate(),
    showTime: show.earlyLate,
    status: show.status,
    public: show.public,
    notes: show?.notes ? show.notes : '',
    guarantee: show?.guarantee ? show.guarantee : 0,
    isMasterShow: show.masterShowFlag,
    // Avoid conflict with `id` prop assigned by useFieldArray
    _id: show._id,
  }));

  return details;
};
