import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

import CypressTags from '../../../../support/cypressTags';

export const MonthBreak: FC<ICellRendererParams> = (props) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const givenDate = new Date(props.data.date);
  const parsedMonth = givenDate.getUTCMonth();
  const parsedDate = monthNames[parsedMonth] + ' ' + givenDate.getUTCFullYear();
  return props.data?.monthBreak ? (
    <div className="center-content text-left w-full font-extrabold text-xl" data-cy={CypressTags.ROUTING.MONTH_BREAK}>
      {parsedDate}
    </div>
  ) : (
    <></>
  );
};
