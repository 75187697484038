import { ColDef } from 'ag-grid-community';
import { FC } from 'react';

export type StringRendererProps = {
  value?: string;
  data?: any;
  colDef?: ColDef;
};

const StringRenderer: FC<StringRendererProps> = (props) => {
  const { value } = props;

  return (
    <div data-cy={`${props?.data?._id}-${props?.colDef?.field}`} data-value={value}>
      {value}
    </div>
  );
};

export default StringRenderer;
