import { ICellRendererParams } from 'ag-grid-community';
import { findCompanies } from 'api/company';
import { findPeople } from 'api/person';
import { FC } from 'react';

import { generateAsyncDropdown } from '../dropdown/Dropdown';

/**
 * Buyer Company renderer.
 */
export const BuyerCompanyRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  return (
    <div className="center-content" data-row-id={props.data.id}>
      {props.data?.buyerCompany?.name}
    </div>
  );
};

/**
 * Buyer Company editor.
 *
 * Legacy -> [EntityType.Client, EntityType['Industry Contact'] (not sure if needed)
 */
export const BuyerCompanyEditor = generateAsyncDropdown('BuyerCompanyEditor', findCompanies);

/**
 * Buyer Person renderer.
 */
export const BuyerPersonRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  return (
    <div className="center-content" data-row-id={props.data.id}>
      {props.data?.buyerPerson?.name}
    </div>
  );
};

/**
 * Buyer Person editor.
 */
export const BuyerPersonEditor = generateAsyncDropdown('BuyerPersonEditor', findPeople);
