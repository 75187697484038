import { ReactNode } from 'react';

interface ILabel {
  className?: string;
  children: ReactNode;
  htmlFor?: string;
  isRequired?: boolean;
}

/*
 * Common form label for most fields
 */
export const Label = ({ children, className, htmlFor, isRequired }: ILabel) => (
  <label htmlFor={htmlFor} className={`form-label ${className ? className : ''}`}>
    {children} {isRequired ? <span className="text-cinnabar">*</span> : null}
  </label>
);

Label.defaultProps = {
  isRequired: false,
};
