import moment from 'moment';
import { FC, useMemo } from 'react';
import { TimelineAgendaData } from '../../../TimelineAgenda/types';
import Table from '../..';
import { TableHeaderCellProps } from '../Cell';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

export type TimelineAgendaTableHeaderRowProps = {
  data: TimelineAgendaData;
} & TableHeaderCellProps;

export type ShowsAgendaTableHeaderRowProps = TableHeaderCellProps;

const Content: FC<TimelineAgendaTableHeaderRowProps> = ({ data, className, ...props }) => {
  const rows = useMemo(
    () =>
      data.reduce((acc, row) => {
        row?.data?.forEach((cell) => {
          const fullDate = moment(cell.date).format('MM/DD/YYYY');

          if (cell?.date && !acc.includes(fullDate)) {
            acc.push(fullDate);
          }
        });

        return acc;
      }, [] as string[]),
    [data]
  );

  return (
    <>
      {rows?.map((date) => {
        const _date = moment(date);

        const keyDate = _date.format(moment.HTML5_FMT.DATE);
        const weekDay = _date.format('ddd');

        return (
          <Table.Header.Cell
            key={`head-cell-${keyDate}`}
            className={twMerge(clsx('items-center justify-center print:text-uta-grey', className))}
            {...props}
          >
            <div className="flex items-center justify-center flex-col gap-1 print:gap-0">
              <span className="font-medium text-sm print:text-xs leading-none print:leading-3 uppercase">
                {weekDay}
              </span>
              <span className="font-bold text-sm print:text-xs leading-none print:leading-3">{date}</span>
            </div>
          </Table.Header.Cell>
        );
      })}
    </>
  );
};

export default Content;
