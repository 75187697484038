import { showUserError } from 'api';
import { generateMemoPdf, generatePdf } from 'api/doc-generation';
import { iOptions } from 'deal-form/interfaces/general';
import { toast } from 'react-toastify';
import { classes } from 'support/react-toastify';
import ToastWrapper from 'components/toast/ToastWrapper';

const onGeneratePdf = async (contractId: string) => {
  const response = await generatePdf(contractId);

  if (response.ok) {
    const data = await response.json();
    return data.redirect;
    // window.open(data.redirect, '_blank');
  } else if (response.status === 409) {
    return toast.warn(ToastWrapper('A PDF is being generated; please try again in a few seconds.', ''), {
      className: classes.warningClass,
      autoClose: 3000,
    });
  } else {
    console.log('error', response);
    showUserError(response);
  }
};

const onGenerateMemoPdf = async (contractId: string) => {
  const response = await generateMemoPdf(contractId);

  if (response.ok) {
    const data = await response.json();
    return data.redirect;
    // window.open(data.redirect, '_blank');
  } else if (response.status === 409) {
    return toast.warn(ToastWrapper('A PDF is being generated; please try again in a few seconds.', ''), {
      className: classes.warningClass,
      autoClose: 3000,
    });
  } else {
    console.log('error', response);
    showUserError(response);
  }
};

export const handleSelect = async ({ option, contractId }: { option: iOptions; contractId: string }) => {
  const newWindow = window.open('', '_blank');
  newWindow?.document.write('Loading...');

  let url;

  if (option.id === 'previewContract') {
    url = await onGeneratePdf(contractId);
  } else {
    // alert('generate deal memo');
    url = await onGenerateMemoPdf(contractId);
  }

  const numberOfTries = 10;

  if (url && newWindow) {
    if (url < numberOfTries) {
      newWindow.close();
    } else {
      newWindow.location.href = url;
    }
  } else if (!url && newWindow) {
    newWindow.close();
  }
};
