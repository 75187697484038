import { createDealFromModal } from 'api/deals';
import EditDealProvider from 'context/editDealContext';
import { SEARCH_TYPE } from 'deal-form/constants';
import { DropdownWithSearch } from 'deal-form/form-controls/DropdownWithSearch';
import { Form } from 'deal-form/form-controls/Form';
import { iOptions } from 'deal-form/interfaces/general';
import { iOrganismNewDealForm } from 'deal-form/interfaces/organisms';
import { Modal, iModalProps } from 'features/common/modal/Modal';
import { SetState } from 'features/routing';
import { iModalState } from 'features/routing/context';
import { useMemo, useState } from 'react';
import { FieldValues, UseFormSetValue, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { classes } from 'support/react-toastify';
import Button from 'components/Button';
import ToastWrapper from 'components/toast/ToastWrapper';
import { DateInput } from 'deal-form/form-controls/DateInput';
import { Dropdown } from 'deal-form/form-controls/Dropdown';
import { Input } from 'deal-form/form-controls/Input';
import { SHOW_DETAILS_STATUS_OPTIONS } from 'deal-form/data/organisms';
import moment from 'moment';
import cypressTags from 'support/cypressTags';
interface iNewDealModal extends iModalProps {
  showDate?: Date;
  client: string;
  fetchTableRows: (artistId: string, daysPrior?: number, daysAfter?: number) => Promise<void>;
  setShowNewDealModal: SetState<iModalState>;
}

export interface iNewDeal {
  date: string;
  clientId: string;
  status: string;
  notes: string;
  buyerPerson: string;
  buyerCompany: string;
  venue: string;
}

interface iSubmitButtons {
  submitFunction: (data: iOrganismNewDealForm) => void;
  isSubmitting: boolean;
}

/**
 * submit buttons component that can accept the form submission function as context
 *
 * @param submitFunction - function - handle form submission
 * @param setEditAfterSave - function - setter for to open a new window with the editor
 * @returns
 */
const SubmitButtons = ({ submitFunction, isSubmitting }: iSubmitButtons) => {
  const { handleSubmit, setValue } = useFormContext<iOrganismNewDealForm>();
  return (
    <div className="flex flex-row justify-between pt-6">
      <Button
        variant="primary"
        onClick={() => {
          handleSubmit(submitFunction)();
        }}
        disabled={isSubmitting}
        dataCy={cypressTags.ROUTING.ADD_NEW_DEAL_MODAL.SAVE_AND_CLOSE}
      >
        Save and Close
      </Button>
      <Button
        variant="secondary"
        onClick={() => {
          setValue('goToDealForm', true);
          handleSubmit(submitFunction)();
        }}
        disabled={isSubmitting}
        dataCy={cypressTags.ROUTING.ADD_NEW_DEAL_MODAL.SAVE_AND_EDIT}
      >
        Save and Edit
      </Button>
    </div>
  );
};

/**
 * modal to create a new deal
 * all fields are required
 * accepts pre-defined date and client (both optional?)
 *
 * @param date - Date object - the date of the show to assign to the new deal
 * @returns JSX Element - the modal
 */
export const NewDealModal = ({ client, showDate, fetchTableRows, setShowNewDealModal, ...props }: iNewDealModal) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showStatusOptions = SHOW_DETAILS_STATUS_OPTIONS.filter(
    (option) => option.id !== 'Cancelled' && option.id !== 'Rejected' && option.id !== 'Cancelled with Payment'
  );

  const handleSubmit = async ({
    buyerCompany,
    buyerName,
    venue,
    date,
    goToDealForm,
    status: showStatus,
    notes: showNotes,
  }: iOrganismNewDealForm) => {
    if (
      !buyerCompany.data ||
      buyerCompany.data?._id === '' ||
      !buyerName?.data ||
      buyerName?.data?._id === '' ||
      !venue.data ||
      venue.data?._id === '' ||
      date === '' ||
      !date
    ) {
      return null;
    }

    setIsSubmitting(true);
    const body: iNewDeal = {
      date: moment(date).toISOString(),
      clientId: client,
      buyerPerson: buyerName?.data?._id || '',
      buyerCompany: buyerCompany?.data?._id || '',
      venue: venue?.data?._id || '',
      status: showStatus,
      notes: showNotes,
    };

    try {
      // submit
      const response = await createDealFromModal(body);
      if (response?.error) {
        setIsSubmitting(false);
        return toast.warn(ToastWrapper(response.error, ''), {
          className: classes.warningClass,
          autoClose: false,
        });
      }
      fetchTableRows(client);
      // // if the box is ticked to go to the deal form, open it
      if (goToDealForm) {
        window.open(`/deal/${response._id}`);
      }
      // // close the window
      setShowNewDealModal({ isOpen: false });
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const defaultValues = useMemo(
    () => ({
      buyerCompany: {
        name: '',
        data: null,
      },
      buyerName: {
        name: '',
        data: null,
      },
      venue: {
        name: '',
        data: null,
      },
      date: showDate,
      status: 'Pending',
      notes: '',
    }),
    [showDate]
  );

  const handleShowModal = (val: boolean) => {
    setShowNewDealModal({ isOpen: val });
  };

  /**
   * This function fires to update the data associated with the venue
   * primarily to update the venue address
   *
   * @param chosen the data about the venue being selected
   */
  const handleVenueChange = (chosen: iOptions | null, setValueMethod: UseFormSetValue<FieldValues>) => {
    setValueMethod(`venue.data`, chosen?.data);
  };

  /**
   * This function fires to update the data associated with the buyer company
   * primarily to update the company address
   *
   * @param chosen the data about the company being selected
   */
  const handleCompanyChange = (chosen: iOptions | null, setValueMethod: UseFormSetValue<FieldValues>) => {
    setValueMethod(`buyerCompany.name`, chosen?.data.name);
    setValueMethod(`buyerCompany.data`, chosen?.data);
  };

  /**
   * This function fires to update the data associated with the buyer name
   * primarily to update the contact info
   *
   * @param chosen the data about the person being selected
   */
  const handleBuyerNameChange = (chosen: iOptions | null, setValueMethod: UseFormSetValue<FieldValues>) => {
    setValueMethod(`buyerName.name`, chosen?.data.name);
    setValueMethod(`buyerName.data`, chosen?.data);
  };

  /**
   * This function fires to update the data associated with the show status
   *
   * @param chosen the data about the show status being selected
   */
  const handleShowStatusChange = (chosen: iOptions | null, setValueMethod?: UseFormSetValue<FieldValues>) => {
    setValueMethod && setValueMethod(`status`, chosen?.label);
  };

  /**
   * This function fires to update the data associated with the show notes
   *
   * @param chosen the data about the show notes being selected
   */
  const handleShowNotesChange = (value: string, _index?: number, setValueMethod?: UseFormSetValue<FieldValues>) => {
    setValueMethod && setValueMethod(`showNotes`, value);
  };

  return (
    <Modal {...props} setShowModal={handleShowModal}>
      <EditDealProvider>
        <Form
          mode="all"
          id="new-deal-form"
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          isSaveButton={false}
          className="min-w-[400px]"
        >
          <div className=" pt-0">
            <label className="form-label">First Show Date</label>
            <DateInput
              ignoreTime
              placeholder="Show Date"
              id="date"
              label={false}
              rulesCallback={() => ({
                required: 'Please select a valid date',
              })}
            />
          </div>

          <div className="pt-4">
            <label className="form-label">Venue</label>
            <DropdownWithSearch
              label={false}
              id="venue.name"
              secondaryId="address"
              searchType={SEARCH_TYPE.VENUE}
              handleChange={handleVenueChange}
              isRequired
              rules={{
                required: 'Please select a valid venue',
              }}
              dataCy={cypressTags.ROUTING.ADD_NEW_DEAL_MODAL.VENUE_INPUT}
            />
          </div>

          <div className="pt-4">
            <label className="form-label">Buyer Company</label>
            <DropdownWithSearch
              label={false}
              id="buyerCompany.name"
              searchType={SEARCH_TYPE.COMPANY}
              placeholder="Buyer Company"
              handleChange={handleCompanyChange}
              isRequired
              rules={{
                required: 'Please select a valid buyer company',
              }}
              dataCy={cypressTags.ROUTING.ADD_NEW_DEAL_MODAL.BUYER_COMPANY_INPUT}
            />
          </div>

          <div className="pt-4">
            <label className="form-label pt-4">Buyer Name</label>
            <DropdownWithSearch
              label={false}
              id="buyerName.name"
              secondaryId="contacts"
              searchType={SEARCH_TYPE.CONTACT}
              placeholder="Buyer Name"
              handleChange={handleBuyerNameChange}
              isRequired
              rules={{
                required: 'Please select a valid buyer name',
              }}
              dataCy={cypressTags.ROUTING.ADD_NEW_DEAL_MODAL.BUYER_NAME_INPUT}
            />
          </div>
          <div className="pt-4">
            <label className="form-label pt-4">Show Status</label>
            <Dropdown
              label={false}
              id="status"
              options={showStatusOptions}
              placeholder="Show Status"
              handleChange={handleShowStatusChange}
              rules={{
                required: 'Please select a valid show status',
              }}
              dataCy={cypressTags.ROUTING.ADD_NEW_DEAL_MODAL.SHOW_STATUS_INPUT}
            />
          </div>

          <div className=" pt-4">
            <label className="form-label pt-4">Show Notes</label>
            <Input
              label={false}
              id="notes"
              placeholder="Show Notes"
              handleChange={handleShowNotesChange}
              data-cy={cypressTags.ROUTING.ADD_NEW_DEAL_MODAL.NOTE_INPUT}
            />
          </div>

          <SubmitButtons submitFunction={handleSubmit} isSubmitting={isSubmitting} />
        </Form>
      </EditDealProvider>
    </Modal>
  );
};
