import { formatDateString } from 'grid-pages/utils/format';
import { FC } from 'react';

export type DateRangeRendererProps = {
  value: string[];
};

const DateRangeRenderer: FC<DateRangeRendererProps> = ({ value }) => {
  const dateStrings = value?.map((date) => formatDateString(date))?.join(' - ');
  return <>{dateStrings ?? ''}</>;
};

export default DateRangeRenderer;
