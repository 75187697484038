import { TextareaHTMLAttributes } from 'react';
import { FieldValues, FormState, RegisterOptions, UseFormSetValue, useFormContext } from 'react-hook-form';

import { findNestedErrorById } from '../../helpers/formHelpers';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  label?: string | boolean;
  className?: string;
  containerClassName?: string;
  rules?: RegisterOptions;
  index?: number;
  handleChange?: (setValueMethod: UseFormSetValue<FieldValues>, index?: number) => void;
  rulesCallback?: (formState: FormState<FieldValues>, index?: number) => RegisterOptions<FieldValues, string>;
  dataCy?: string;
}

export const TextArea = ({
  id,
  containerClassName,
  className,
  label,
  rules,
  index,
  handleChange,
  rulesCallback,
  dataCy,
  ...props
}: ITextAreaProps) => {
  // const [text, setText] = useState<string>('');
  const {
    register,
    setValue,
    formState,
    formState: { errors },
  } = useFormContext();

  const hasErrors = errors && !!findNestedErrorById(id, errors);

  return (
    <>
      <div className={twMerge(clsx('form-field-wrapper h-auto', containerClassName))}>
        {label !== false && (
          <label
            className={`standard-label ${
              containerClassName && containerClassName.includes('flex-col') ? '!self-start' : ''
            }`}
          >
            {label}
          </label>
        )}

        <textarea
          data-cy={dataCy}
          // defaultValue={text}
          {...register(
            id,
            // TODO: refactor to combine rulesCallback and rules
            {
              ...(rulesCallback ? { ...rulesCallback(formState, index) } : rules),
              onChange: () => {
                if (handleChange) handleChange(setValue, index);
              },
            }
          )}
          className={`relative border border-solid disabled:opacity-50 ${
            hasErrors ? 'border-cinnabar' : 'border-black'
          } px-2 py-2 w-full min-h-[2.5rem] appearance-none peer text-md ${className}`}
          {...props}
          id={id}
          placeholder={props.placeholder || ' '}
          aria-describedby={`${id}_error_help`}
        />
      </div>
    </>
  );
};

TextArea.defaultProps = {
  containerClassName: '',
  className: '',
  label: false,
  rule: {},
};
