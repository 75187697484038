import { SelectOptionT } from '@united-talent-agency/onyx-components';
import { AvailsSearchResult } from 'context/avails-filters/hook';

export const GENRE_OPTIONS: SelectOptionT[] = [
  'Afrobeats',
  'Adult Contemporary',
  'Alternative',
  'Blues',
  'Chefs',
  'Classical',
  'Comedy',
  'Country',
  'Electronic',
  'Folk',
  'Gospel',
  'Hip Hop',
  'Indie',
  'Jazz',
  'Literary',
  'Mediums',
  'Metal',
  'Open-Format',
  'Other',
  'Podcasts',
  'Pop',
  'Presenters',
  'Rap',
  'Reggae',
  'R&B',
  'Rock',
  'Singer-Songwriter',
  'Spanish Language',
  'Speakers',
  'YouTube',
].map((genre) => {
  return {
    label: genre,
    value: genre.toLowerCase(),
  };
});

export const SHOW_TYPES_OPTIONS: SelectOptionT[] = [
  'Any',
  'Casino',
  'College',
  'Corporate',
  'Festival',
  'Private',
  'Public',
].map((type) => {
  return {
    label: type,
    value: type.toLowerCase(),
  };
});

export const DEFAULT_SELECT_OPTION: SelectOptionT = { value: '', label: '' };

export const MIN_GUARANTEE = 0;
export const MAX_GUARANTEE = 1000000;

export const defaultAvailsSearchResult: AvailsSearchResult = {
  hits: [],
  nbHits: 0,
  page: 0,
  nbPages: 0,
};

export const SHOWS_AGENDA_COLUMNS = [
  'Dates',
  'Client',
  'Responsible Agent',
  'Venue',
  'Location',
  'Deal Memo #',
  'Status',
  'Radius',
  'Proximity (Mi)',
  'Notes',
];
