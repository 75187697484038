import { ICellEditorParams, KeyCode } from 'ag-grid-community';
import _ from 'lodash';
import { ChangeEvent, ChangeEventHandler, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

/**
 * Check whether given number string contains any special characters
 * or not
 * @param field string
 * @returns {boolean} valid
 */
export const checkForWholeNumber = (field: string): boolean => {
  const regexForWholeNumber = /^([1-9]\d*|0|^)$/;
  return regexForWholeNumber.test(field);
};

/**
 * Numeric Cell Editor
 * used for enforcing number-only value entry
 *
 * @param { ICellEditorParams } props
 * @param {ForwardedRef} ref
 */
export const NumericCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  /**
   * Initialize the value being handled inside this component.
   */
  const createInitialState = () => {
    let startValue;

    if (props.eventKey === KeyCode.BACKSPACE || props.eventKey === KeyCode.DELETE) {
      startValue = '';
    } else if (props.charPress) {
      const wholeNumber = checkForWholeNumber(props.charPress);
      startValue = wholeNumber ? props.charPress : '';
    } else {
      startValue = props.value || '';
    }

    return { value: startValue };
  };
  const initialState = createInitialState();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(initialState.value);

  /**
   * When input box is updated.
   *
   * @param {ChangeEvent} e - Event object of the change
   */
  const onChangeInput: ChangeEventHandler = (e: ChangeEvent) => {
    const newValue = (e.target as HTMLInputElement).value || '';
    if (!checkForWholeNumber(newValue)) {
      e.preventDefault();
      return;
    }
    if (!isNaN(Number(newValue))) setValue(newValue);
  };

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  /* Utility Methods */

  const cancelBeforeStart = props.charPress && '1234567890.'.indexOf(props.charPress) < 0;

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return cancelBeforeStart;
      },

      // Gets called once when editing is finished (ie if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        let cancel = false;

        switch (props.column.getColId()) {
          case 'softMerch':
          case 'hardMerch':
            cancel = !_.inRange(Number(value), 0, 100);
            break;
          case 'sellableCapacity':
            setValue(Math.floor(value));
            break;
          default:
            cancel = value < 0;
            break;
        }

        return cancel;
      },
    };
  });

  return (
    <div className="editor-container">
      <input
        className="border-0 outline-none h-full w-full focus-visible:border-0 focus-visible:outline-none"
        ref={inputRef}
        value={value}
        onChange={onChangeInput}
      />
    </div>
  );
});
NumericCellEditor.displayName = 'NumericCellEditor';
