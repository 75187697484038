import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

type BooleanFilterRef = {};
export type BooleanFilterProps = {
  testId?: string;
  filterChangedCallback: () => void;
};

const BooleanFilter = forwardRef<BooleanFilterRef, BooleanFilterProps>(({ testId, filterChangedCallback }, ref) => {
  // const colId = props.column.getColId();

  const [filterState, setFilterState] = useState<string | boolean>('off');

  useEffect(() => {
    // console.log('filterState UPDATED', colId, filterState);
    filterChangedCallback();
  }, [filterState]);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        // console.log('ACTIVE?', colId, filterState !== 'off');
        return filterState !== 'off';
      },
      getModel() {
        // console.log('GET MODEL', filterState);
        if (filterState === 'off') {
          return undefined;
        }

        return {
          filterType: 'boolean',
          filter: filterState,
        };
      },
    };
  });

  const changeHandler = useCallback((value: string | boolean) => {
    setFilterState(value);
  }, []);

  return (
    <div data-testid={testId} className="">
      <div>
        <button className="text-viridian underline active:text-curiousBlue" onClick={() => changeHandler('off')}>
          Clear
        </button>
      </div>
      <div>
        <label>
          <input type="radio" onChange={() => changeHandler(true)} checked={filterState === true}></input>
          True only
        </label>
      </div>
      <div>
        <label>
          <input type="radio" onChange={() => changeHandler(false)} checked={filterState === false}></input>
          False only
        </label>
      </div>
    </div>
  );
});

export default BooleanFilter;
