import { LinkIcon } from '@heroicons/react/24/outline';
import { iAgent, iPerson } from 'grid-pages/interfaces';
import { FC, useState } from 'react';
import { contactUrlForId } from 'urls';
import { promoterUrlForId } from 'urls';

import BlankCell from '../BlankCell';

type ItemLinkProps = {
  item: iAgent | iPerson;
  colId: string;
};

const ItemLink: FC<ItemLinkProps> = ({ item, colId }) => (
  <a
    href={colId === 'buyerCompany' ? promoterUrlForId(item._id) : contactUrlForId(item._id)}
    className="text-curiousBlue p-4 hover:bg-[#29a1db26] cursor-pointer block"
    rel="noopener"
    target="_blank"
  >
    {item.name}
  </a>
);

export type MultiNameRendererProps = {
  column: Record<string, any> & {
    colId: string;
  };
  value: iAgent[] | iPerson[];
  data: any;
};

const MultiNameRenderer: FC<MultiNameRendererProps> = ({ column, value: items, data }) => {
  // States
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  if (!items || items?.length === 0) {
    return <BlankCell data-row-id={data.id} data-deal-id={data.deal} />;
  }

  const handleClick = () => {
    if (items.length === 1) {
      window.open(contactUrlForId(items[0]._id));
    } else {
      setVisible((prev) => !prev);
    }
  };

  return (
    <div className="flex" data-row-id={data.id} data-deal-id={data.deal}>
      <div
        className="flex justify-between items-center w-full cursor-pointer hover:text-curiousBlue hover:underline"
        onClick={handleClick}
      >
        <span className="truncate pr-2">{items.map((item: any) => item.name).join(', ')}</span>
        <div>
          <LinkIcon className="w-4 h-4 text-curiousBlue" />
        </div>
      </div>

      {visible && (
        <div data-testid="modal" className="text-greyCod bg-white" data-row-id={data.id} data-deal-id={data.deal}>
          <ul className="block absolute z-10 bg-white right-0 -translate-x-full border-px border-merlot drop-shadow-[0_8px_16px_rgba(0,0,0,0.2)] w-max">
            {items.map((item, index) => (
              <li key={item._id + index} className="w-full">
                <ItemLink item={item} colId={column.colId} />
              </li>
            ))}
          </ul>
          <div onClick={visible ? hide : show} className="w-[100vw] h-[100vw] fixed top-0 left-0 opacity-0"></div>
        </div>
      )}
    </div>
  );
};

export default MultiNameRenderer;
