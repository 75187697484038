import clsx from 'clsx';
import { FC, PropsWithChildren, useCallback } from 'react';
import { Error as ErrorIcon, Location as LocationIcon } from '@united-talent-agency/onyx-components/Icons';
import { Badge, Tooltip } from '@united-talent-agency/onyx-components';
import { LocationType } from 'components/TimelineAgenda/types';
import { twMerge } from 'tailwind-merge';
import { AvailsShowStatus } from 'types/Show';
import { getStatus } from 'containers/availability/helpers';

export type BodyCellContentProps = PropsWithChildren<{
  data?: LocationType;
  disableRadiusClauseTooltip?: boolean;
}>;

const CellContent: FC<BodyCellContentProps> = ({ data, disableRadiusClauseTooltip, children }) => {
  const errorClasses = {
    'text-[#E95344]': !!data?.clauseViolationData,
  };

  const getStatusData = useCallback(() => {
    return getStatus(data?.status as AvailsShowStatus);
  }, [data]);

  const statusColor = getStatusData()?.statusColor ?? '#fff';
  const statusBg = getStatusData()?.statusBg ?? '#000';
  const statusLabel = getStatusData()?.statusLabel ?? 'Unknown';
  const statusText = getStatusData()?.statusText ?? 'U';

  const Cell = useCallback(
    () => (
      <div className="cell-container flex flex-1 flex-col gap-1 text-center">
        <div className="flex flex-col gap-1">
          {data?.label && (
            <p className="text-sm leading-none font-bold group-hover/table-td:underline">{data?.label}</p>
          )}
          {data?.address && (
            <p
              className={twMerge(
                clsx('text-sm whitespace-pre-line', {
                  'text-uta-grey': !data?.clauseViolationData,
                  'text-[#E95344]': !!data?.clauseViolationData,
                })
              )}
            >
              <LocationIcon className="fill-[currentColor] h-4 w-4 mr-[2px] inline-block mb-[2px]" />
              {data?.address}
              {!!data?.clauseViolationData && (
                <ErrorIcon className="fill-[currentColor] h-4 w-4 ml-[2px] inline-block mb-[2px]" />
              )}
            </p>
          )}
        </div>
        {data?.status && (
          <div className="flex flex-1 flex-row flex-wrap justify-center items-center gap-1 text-sm text-center">
            <Badge text={statusText} size="md" color={statusColor} bgColor={statusBg} className="[&&]:w-10" />{' '}
            <span>{statusLabel}</span>
          </div>
        )}
        {children}
      </div>
    ),
    []
  );

  return (
    <div className={twMerge(clsx('flex flex-1 flex-col items-center justify-center px-5 py-[10px]', errorClasses))}>
      {data?.clauseViolationData && !disableRadiusClauseTooltip ? (
        <Tooltip placement="top">
          <Tooltip.Trigger>
            <Cell />
          </Tooltip.Trigger>
          <Tooltip.Content className="flex flex-col py-1 px-2 gap-0.5 text-center text-xs font-medium shadow-[0_1px_6px_0_rgba(0,0,0,0.25)] [&&>svg]:w-3.5 [&&>svg]:h-3.5 print:hidden">
            <span className="text-[#E95344]">Radius Clause Violation</span>
            <span>
              {`This venue is within ${data?.clauseViolationData?.distance} mi and ${data?.clauseViolationData?.days} days of a clause
              violation`}
            </span>
          </Tooltip.Content>
        </Tooltip>
      ) : (
        <Cell />
      )}
    </div>
  );
};

export default CellContent;
