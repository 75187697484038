import {
  CellClassFunc,
  ColDef,
  ColGroupDef,
  CsvExportParams,
  ITooltipParams,
  RowClassParams,
  RowStyle,
  ShouldRowBeSkippedParams,
} from 'ag-grid-community';
import { parseDealTypeName } from 'deal-form/helpers/dealHelpers';
import { IFrontendViolation } from 'types/TableRow';

import GridTooltip from 'components/grid-tooltip';

import { serializeCellData } from './RoutingTableSerializer';
import { isEmpty } from './rowParsers';
import { BooleanRenderer, CurrencyRenderer } from 'grid-pages/components';
import StringRenderer from 'grid-pages/components/StringRenderer';

/**
 * Style of a normal cell.
 */
export const staticCellStyle = {
  padding: '0 5px',
  margin: '0',
  display: 'block',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap ',
  lineHeight: '40px',
};

/**
 * Style of a normal cell.
 */
export const radiusCellStyle = {
  padding: '0 5px',
  margin: '0',
  display: 'block',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'visible',
  whiteSpace: 'nowrap ',
  lineHeight: '40px',
};

/**
 * Style of a cell with notes
 */
export const notesCellStyle = {
  paddingTop: '12px',
  margin: '0',
  display: 'block',
  textAlign: 'center',
  maxWidth: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap ',
};

/**
 * Style of a cell under pinned column.
 */
export const pinnedCellStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'visible',
  textOverflow: 'initial',
};

export const isViolation = (violations: IFrontendViolation[] | undefined): boolean => {
  return Array.isArray(violations) && violations.length > 0;
};

/**
 * Callback to generate style of a row.
 *
 * @param {RowClassParams} params
 * @returns {RowStyle}
 */
export const getRowStyle = (params: RowClassParams): RowStyle => {
  if (params.data?.monthBreak) {
    return {
      margin: 0,
      border: 0,
      background: '#ada59f',
    };
  } else if (!params.data?.clientAvailable) {
    return {
      margin: 0,
      border: 0,
      background: '#dfdfdb',
    };
  } else if (isViolation(params?.data?.violations)) {
    const isHardViolation = params.data.violations.every(
      (violation: { distance: number }) => violation.distance === -1
    );
    return {
      color: isHardViolation ? '#ba9b1c' : 'red',
      overflowY: 'visible',
    };
  } else {
    return {};
  }
};

/**
 * Callback to generate class of a cell.
 *
 * @param { RowClassParams } params
 * @returns {string | string[]}
 */
export const getCellClass: CellClassFunc = (params: RowClassParams): string | string[] => {
  // We return drag-row css class only on non-month header rows because technically we can't move month headers
  // and only move the actual shows
  if (params.data?.monthBreak) {
    return 'month-break';
  } else if (!params.data?.clientAvailable) {
    return 'client-unavailable';
  } else {
    return params.data?.status === ('Sketch' || 'Pending') ? 'drag-row' : '';
  }
};

/**
 * Default props of a column.
 */
const defaultProps = {
  resizable: true,
  lockPinned: true,
  cellStyle: staticCellStyle,
  cellClass: getCellClass,
  // cellRenderer: 'UnifiedRenderer',
  minWidth: 75,
  wrapHeaderText: true,
};

/**
 * Default props for dropdown columns.
 */

const toolTipValueGetter = (params: ITooltipParams) => ({
  value: params.value,
});

/**
 * Callback to determine current row should be skipped in csv export.
 *
 * @param {ShouldRowBeSkippedParams} params
 * @returns {boolean}
 */
const shouldRowBeSkipped = (params: ShouldRowBeSkippedParams): boolean => {
  return isEmpty(params.node.data);
};

/**
 * Default CSV export parameters.
 */
export const defaultCsvExportParams: CsvExportParams = {
  shouldRowBeSkipped,
  skipColumnGroupHeaders: true,
  processCellCallback: serializeCellData,
};

const NUMERIC_CELL_EDITOR = 'NumericCellEditor';
// const CURRENCY_CELL_EDITOR = 'CurrencyCellEditor;
const PERCENTAGE_CELL_EDITOR = 'PercentageCellEditor';

/**
 * Column definitions.
 */
export const ColumnDefinitions: Array<ColDef | ColGroupDef> = [
  {
    field: 'timeline',
    valueGetter: (params) => params.data.venue,
    headerName: '',
    cellRenderer: 'TimelineRenderer',
    resizable: false,
    lockPosition: true,
    lockPinned: true,
    pinned: 'left',
    cellStyle: pinnedCellStyle,
    cellClass: getCellClass,
    maxWidth: 30,
    suppressMenu: true,
  },
  {
    field: 'date',
    valueGetter: (params) => params.data.date,
    headerName: 'Show Date',
    cellRenderer: 'EventDateRenderer',
    resizable: false,
    lockVisible: true,
    lockPosition: true,
    lockPinned: true,
    pinned: 'left',
    cellStyle: pinnedCellStyle,
    cellClass: getCellClass,
    maxWidth: 100,
    suppressMenu: true,
  },
  {
    ...defaultProps,
    cellStyle: staticCellStyle,
    field: 'venue',
    headerName: 'Venue',
    cellEditor: 'VenueEditor',
    lockVisible: true,
    editable: false,
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,
    valueGetter: (params) => {
      if (params.data?.monthBreak) {
        return '';
      } else if (!params.data.clientAvailable) {
        return 'Client Unavailable';
      }
      return params.data.venue?.name || params.data.venue;
    },
  },
  {
    ...defaultProps,
    field: 'city',
    valueGetter: (params) => params.data.venue?.address?.city || params.data.city,
    headerName: 'City',
    lockVisible: true,
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,

    editable: false,
  },
  {
    ...defaultProps,
    field: 'state',
    valueGetter: (params) => params.data.venue?.address?.state || params.data.state,
    headerName: 'State',
    width: 120,

    editable: false,
  },
  {
    ...defaultProps,
    field: 'country',
    valueGetter: (params) => params.data.venue?.address?.country || params.data.country,
    headerName: 'Country',
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,

    editable: false,
  },
  {
    headerName: 'RDD',
    marryChildren: true,
    children: [
      {
        resizable: true,
        cellStyle: radiusCellStyle,
        cellClass: getCellClass,
        minWidth: 75,
        wrapHeaderText: true,
        field: 'radius',
        headerName: 'Radius Clause',
        cellRenderer: 'RadiusClauseRenderer',
        valueGetter: (params) => {
          return params?.data?.radius
            ? `${params.data.radius} ${params.data.radiusUnit === 'miles' ? 'mi' : 'km'}`
            : '';
        },
        cellEditor: NUMERIC_CELL_EDITOR,
        width: 150,
        editable: false,
        suppressMovable: true,
      },
      {
        ...defaultProps,
        field: 'timeBefore',
        headerName: 'Days Prior',
        valueGetter: (params) => params.data?.timeBefore || undefined,
        cellEditor: NUMERIC_CELL_EDITOR,
        width: 150,
        editable: false,
        suppressMovable: true,
      },
      {
        ...defaultProps,
        field: 'timeAfter',
        headerName: 'Days After',
        valueGetter: (params) => params.data?.timeAfter || undefined,
        cellEditor: NUMERIC_CELL_EDITOR,
        width: 150,
        editable: false,
        suppressMovable: true,
      },
    ],
  },
  {
    ...defaultProps,
    field: 'status',
    headerName: 'Show Status',
    cellRenderer: 'DealStatusRenderer',
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,
    editable: false,
    lockVisible: true,
  },
  {
    ...defaultProps,
    field: 'buyerCompany',
    headerName: 'Buyer Company',
    cellEditor: 'BuyerCompanyEditor',
    editable: false,
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,
    valueGetter: (params) => params.data?.buyerCompany?.name,
    cellRenderer: StringRenderer,
  },
  {
    ...defaultProps,
    field: 'buyerPerson',
    headerName: 'Buyer Name',
    cellEditor: 'BuyerPersonEditor',
    editable: false,
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,
    valueGetter: (params) => params.data?.buyerPerson?.name,
  },
  {
    ...defaultProps,
    cellStyle: radiusCellStyle,
    field: 'dealType',
    headerName: 'Deal Type',
    cellEditor: 'DealTypeEditor',
    editable: false,
    valueGetter: (params) => parseDealTypeName(params.data?.terms?.termsType?.name),
    cellRenderer: StringRenderer,
  },
  {
    ...defaultProps,
    field: 'phone',
    headerName: 'Phone',
    editable: false,
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,
    valueGetter: (params) => {
      const contacts = params.data?.buyerPerson?.contacts;
      const phoneContacts =
        contacts && contacts.filter((contact: any) => contact.contactType?.toLowerCase().includes('phone'));
      return phoneContacts && phoneContacts.length ? phoneContacts[0].contact : undefined;
    },
  },
  {
    ...defaultProps,
    field: 'walkoutPotential',
    headerName: 'Walkout Potential',
    cellRenderer: CurrencyRenderer,
    type: ['currencyColumn'],

    editable: false,
  },
  {
    ...defaultProps,
    field: 'ages',
    headerName: 'Ages',
    cellEditor: 'AgesEditor',
    editable: false,
  },
  {
    ...defaultProps,
    field: 'softMerch',
    headerName: 'Soft Merch',
    cellEditor: PERCENTAGE_CELL_EDITOR,
    // editable: true,
    editable: false,
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,
    valueGetter: (params) => (params.data?.softMerch ? Number(params.data?.softMerch).toFixed(2) + '%' : ''),
  },
  {
    ...defaultProps,
    field: 'hardMerch',
    headerName: 'Hard Merch',
    cellEditor: PERCENTAGE_CELL_EDITOR,
    // editable: true,
    editable: false,
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,
    valueGetter: (params) => (params.data?.hardMerch ? Number(params.data?.hardMerch).toFixed(2) + '%' : ''),
  },
  {
    ...defaultProps,
    headerName: 'Backstage',
    field: 'public',
    editable: false,
    // @ts-ignore
    cellRenderer: (props) => (props?.data?.deal ? <BooleanRenderer {...props} /> : <></>),
  },
  {
    ...defaultProps,
    cellStyle: notesCellStyle,
    headerName: 'Show Notes',
    field: 'notes',
    editable: false,
    tooltipComponent: GridTooltip,
    tooltipValueGetter: toolTipValueGetter,
  },
  {
    ...defaultProps,
    field: 'guarantee',
    headerName: 'Guarantee',
    cellRenderer: CurrencyRenderer,
    type: ['currencyColumn'],
    editable: false,
  },
  {
    ...defaultProps,
    field: 'sellableCapacity',
    headerName: 'Sellable Capacity',
    valueGetter: (params) => {
      return params.data?.sellableCapacity?.toString?.().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '--';
    },
    editable: false,
  },
  {
    field: 'actions',
    valueGetter: (params) => params.data._id,
    headerName: 'Actions',
    cellRenderer: 'ActionsRenderer',
    suppressMovable: true,
    lockVisible: true,
    lockPosition: false,
    lockPinned: true,
    pinned: 'right',
    cellStyle: pinnedCellStyle,
    cellClass: getCellClass,
    suppressMenu: true,
  },
];
