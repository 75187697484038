import { deleteDealShow, handleUpdateDealShowsResponse, iUpdateShowPayload, updateDealShows } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  SHOW_DETAILS_EMPTY_ITEM,
  SHOW_DETAILS_SHOW_TIME_OPTIONS,
  SHOW_DETAILS_STATUS_OPTIONS,
  SHOW_TYPES,
  TIME_ZONES,
} from 'deal-form/data/organisms';
import { Checkbox } from 'deal-form/form-controls/Checkbox';
import { SelectAllCheckbox } from 'deal-form/form-controls/Checkbox/selectAllCheckbox';
import { Dropdown } from 'deal-form/form-controls/Dropdown';
import { TextField } from 'deal-form/form-controls/TextField';
import { iFormProps } from 'deal-form/interfaces/general';
import {
  iOrganismShowDetails,
  iOrganismShowDetailsExpandedTime,
  iOrganismShowDetailsItem,
} from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { useWindowSize, windowSizes } from 'support/windowResize';

import { DateInput } from 'deal-form/form-controls/DateInput';
import { Input } from 'deal-form/form-controls/Input';
import { Label } from 'deal-form/form-controls/Label';
import moment from 'moment';
import { FieldArray, FieldArrayColumn } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { TextArea } from '../../form-controls/TextArea';
import { getMasterShow, getShowDetails } from '../../helpers/showHelpers';
import { getContractById } from 'api';
import submitToNetsuite from 'utils/submitToNetsuite';
import cypressTags from 'support/cypressTags';

export const TOP_GRID_LAYOUT = 'md:grid md:grid-cols-[0.5fr_1fr_1fr] gap-4 ';
export const GRID_LAYOUT = 'md:grid md:grid-cols-[0.5fr_1fr_1fr_1fr] gap-2 ';
const COLUMN_LABELS: FieldArrayColumn[] = [
  { value: '', checkboxId: 'public', checked: false },
  { value: 'Show Date', isRequired: true },
  { value: 'Show Time', isRequired: true },
  { value: 'Show Status', isRequired: true },
  { value: '', type: 'field_space' },
  { value: '', type: 'field_group' },
  { value: 'Show Notes' },
  { value: 'Show Guarantee' },
];

const DateGroup: React.FC<{ id: string; width: number }> = ({ id, width }) => {
  const [index, setIndex] = React.useState(-1);

  React.useEffect(() => {
    const newIndex = id.match(/\d/);
    if (newIndex !== null && newIndex.length > 0) setIndex(Number(newIndex[0]));
  }, [id]);

  return (
    <div className="flex w-full" id="field_group">
      <div className="flex-1">
        <div className="font-medium text-sm text-black my-[2px]">Confirmed On</div>
        <TextField
          label={width < windowSizes.MD ? 'Confirmed On' : false}
          containerClassName={'!flex-col'}
          text="--"
          id={`showDetails.${index}.confirmed`}
          className="leading-10"
        />
      </div>
      <div className="flex-1">
        <div className="font-medium text-sm text-black my-[2px]">Cancelled On</div>
        <TextField
          label={width < windowSizes.MD ? 'Cancelled On' : false}
          containerClassName={'!flex-col'}
          text="--"
          id={`showDetails.${index}.cancelled`}
          className="leading-10"
        />
      </div>
    </div>
  );
};

const ShowDetailsForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked, contract, setContract }) => {
  const [showDetails, setShowDetails] = useState<iOrganismShowDetailsItem[]>([]);
  const [showDetailsToRemove, setShowDetailsToRemove] = useState<iOrganismShowDetailsItem[]>([]);
  const [canSubmit, setCanSubmit] = useState(true);
  const [columnLabels, setColumnLabels] = useState<FieldArrayColumn[]>(COLUMN_LABELS);
  const [announceDate, setAnnounceDate] = useState('');
  const [onSaleDate, setOnSaleDate] = useState('');
  const { width } = useWindowSize();

  /**
   * override the built in append functionality
   *
   * @param index row to be removed
   */
  const handleRemoveShow = async (index: number) => {
    setShowDetailsToRemove([...showDetailsToRemove, showDetails[index]]);
  };

  const handleResetAnnounceDate = (value: string | Date | null | undefined) => {
    setAnnounceDate(value?.toString() || '');
  };

  const handleResetOnSaleDate = (value: string | Date | null | undefined) => {
    setOnSaleDate(value?.toString() || '');
  };

  const onSubmit = async (data: iOrganismShowDetails, dontResetCanSubmit?: boolean) => {
    const updatableShows = data.showDetails.filter((show) => show.status !== 'Settled');

    const updateData: iUpdateShowPayload[] = [];
    const announcementDateTime: iOrganismShowDetailsExpandedTime = { date: '', time: '', tz: '', offset: '', abbr: '' };

    if (data?.announcementDateTime) {
      const announcementDateString = data.announcementDateTime.date || '';

      let announceFormattedDate;

      if (moment(announcementDateString, 'DD-MM-YYYY', true).isValid()) {
        announceFormattedDate = moment(announcementDateString, 'DD-MM-YYYY').format('YYYY-MM-DD');
      } else if (moment(announcementDateString, 'YYYY-MM-DD')) {
        announceFormattedDate = announcementDateString;
      } else {
        console.log('Invalid date format:', announcementDateString);
      }

      announcementDateTime.date = announceDate ? announceFormattedDate || '' : '';
      announcementDateTime.offset =
        TIME_ZONES.find((tz) => tz.data.name === data?.announcementDateTime?.tz)?.data.offset || '';
      announcementDateTime.time = data?.announcementDateTime?.time || '';
      announcementDateTime.tz = data?.announcementDateTime?.tz || '';
      announcementDateTime.abbr = TIME_ZONES.find((tz) => tz.id === data?.announcementDateTime?.tz)?.data.abbr || '';
    }

    const onSaleDateTime: iOrganismShowDetailsExpandedTime = { date: '', time: '', tz: '', offset: '', abbr: '' };

    if (data?.onSaleDateTime) {
      const onSaleDateString = data.onSaleDateTime.date || '';

      let onSaleFormattedDate;

      if (moment(onSaleDateString, 'DD-MM-YYYY', true).isValid()) {
        onSaleFormattedDate = moment(onSaleDateString, 'DD-MM-YYYY').format('YYYY-MM-DD');
      } else if (moment(onSaleDateString, 'YYYY-MM-DD')) {
        onSaleFormattedDate = onSaleDateString;
      } else {
        console.log('Invalid date format:', onSaleDateString);
      }

      onSaleDateTime.date = onSaleDate ? onSaleFormattedDate || '' : '';
      onSaleDateTime.offset = TIME_ZONES.find((tz) => tz.data.name === data?.onSaleDateTime?.tz)?.data.offset || '';
      onSaleDateTime.time = data?.onSaleDateTime?.time || '';
      onSaleDateTime.tz = data?.onSaleDateTime?.tz || '';
      onSaleDateTime.abbr = TIME_ZONES.find((tz) => tz.id === data?.onSaleDateTime?.tz)?.data.abbr || '';
    }

    if (updatableShows.length === 0) {
      const newShow: iUpdateShowPayload = {
        showId: deal.shows[0]?._id,
        updateBody: {
          announcementDateTime: announcementDateTime || undefined,
          onSaleDateTime: onSaleDateTime || undefined,
        },
      };

      updateData.push(newShow);
    }
    // if (data.showDetails.length > 2) return;
    // update all the existing shows that are not settled

    if (updatableShows.length > 0) {
      updatableShows.forEach(({ date, notes, showTime, status: showStatus, _id, guarantee, public: isPublic }) => {
        // check to see if there is enough information to submit a show
        if (showStatus !== '' && showTime !== '' && date) {
          // align them with the data.shows
          const showToUpdate = deal.shows.find((show) => show._id === _id);

          // create the updated values
          const updatedShow: iUpdateShowPayload = {
            showId: showToUpdate?._id || '',
            updateBody: {
              earlyLate: showTime,
              notes: notes,
              guarantee: guarantee,
              // hour zero date
              date: moment(date).utc().startOf('day').toDate(),
              status: showStatus,
              type: data.type,
              announcementDateTime: announcementDateTime || undefined,
              onSaleDateTime: onSaleDateTime || undefined,
              public: isPublic,
            },
          };

          // add the updated show to the update array
          updateData.push(updatedShow);
        }
      });
    }

    if (showDetailsToRemove.length) {
      setCanSubmit(false);
      try {
        await Promise.all(showDetailsToRemove.map((s) => deleteDealShow(deal._id, s._id)));
      } catch (e) {
        console.log('ERROR REMOVING SHOWS', e);
      }
      if (!updateData.length) setCanSubmit(true);
    }

    // submit the changes to existing shows
    if (updateData.length > 0) {
      try {
        setCanSubmit(false);

        const response = await updateDealShows({
          dealId: deal._id.toString(),
          updates: updateData,
        });

        if (setDeal && dontResetCanSubmit !== true) handleUpdateDealShowsResponse(response, { deal, setDeal });
        if (dontResetCanSubmit !== true) setTimeout(() => setCanSubmit(true), 500);

        //update client side contract
        if (contract) {
          await getContractById(contract?._id).then((res: any) => {
            if (res && setContract) {
              setContract({ ...res });
            }
          });
        }

        return response.body;
      } catch (e) {
        console.log('ERROR SAVING SHOW LOCATION DATA', e);
        setCanSubmit(true);
      } finally {
        const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';

        if (isDealSubmittedToNetsuite) {
          submitToNetsuite(contract, deal, false);
        } else if (!isDealSubmittedToNetsuite && contract?.status === 'Confirmed') {
          submitToNetsuite(contract, deal, false, undefined, setContract);
        }
      }
    }
  };

  useEffect(() => {
    const sDetails = getShowDetails(deal);
    setShowDetails(sDetails);
    const hasFalse = sDetails.some((item) => item.public === false);
    updateColumns(hasFalse);
  }, [contract, deal]);

  const defaultValues = useMemo(() => {
    return {
      type: getMasterShow(deal.shows)?.type || '',
      announcementDateTime:
        getMasterShow(deal.shows)?.announcementDateTime &&
        getMasterShow(deal.shows)?.announcementDateTime.date !== 'Invalid date'
          ? getMasterShow(deal.shows)?.announcementDateTime
          : { date: '', time: '', tz: '', offset: '' },
      onSaleDateTime:
        getMasterShow(deal.shows)?.onSaleDateTime && getMasterShow(deal.shows)?.onSaleDateTime.date !== 'Invalid date'
          ? getMasterShow(deal.shows)?.onSaleDateTime
          : { date: '', time: '', tz: '', offset: '' },
      showDetails: showDetails,
      public: getMasterShow(deal.shows)?.public || false,
    };
  }, [contract, deal, showDetails]);

  const updateColumns = (hasFalse: boolean) => {
    const labels = [...columnLabels];
    const labelIndex = labels.findIndex((col) => col.checkboxId === 'public');
    if (labelIndex) {
      labels[labelIndex].checked = !hasFalse;
      setColumnLabels(labels);
    }
  };

  return (
    <Form
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      className={`full-width ${canSubmit ? '' : 'opacity-50'}`}
      defaultValues={defaultValues}
      disabled={!isOpen || locked}
      formName={FORM_NAMES.SHOW_DETAILS}
    >
      <div className="flex flex-col w-full gap-y-4 col-span-full mb-8">
        <div className="flex flex-row gap-4">
          <Label isRequired htmlFor="type" className="min-w-[120px]">
            Type
          </Label>
          <Dropdown
            placeholder="Type"
            options={SHOW_TYPES}
            id="type"
            disabled={!isOpen || locked}
            rules={{
              required: 'Please select a valid ‘Venue’ option',
            }}
            containerClassName="mb-4 md:mb-0 max-w-sm"
          />
        </div>
        <div className="flex flex-row gap-4">
          <Label htmlFor="announcementDateTime.date" className="min-w-[120px]">
            Announce Date
          </Label>
          <DateInput
            placeholder="Announce Date"
            id="announcementDateTime.date"
            disabled={!isOpen || locked}
            containerClassName="mb-4 md:mb-0  max-w-sm"
            handleChange={(_value, _index, _setValueMethod, _getValues, trigger) => {
              if (trigger) {
                handleResetAnnounceDate(_value);
              }
            }}
          />
          <Input
            placeholder="Announcement Time"
            fieldType="time"
            id="announcementDateTime.time"
            // required
            disabled={!isOpen || locked}
            containerClassName="max-w-xs"
            // rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
          />
          <Dropdown
            placeholder="Timezone"
            id="announcementDateTime.tz"
            options={TIME_ZONES}
            disabled={!isOpen || locked}
            containerClassName="max-w-md"
            // rules={{ required: "Please select a valid 'Timezone' option" }}
          />
        </div>
        <div className="flex flex-row gap-4">
          <Label htmlFor="onSaleDateTime.date" className="min-w-[120px]">
            On Sale Date
          </Label>
          <DateInput
            placeholder="On Sale Date"
            id="onSaleDateTime.date"
            disabled={!isOpen || locked}
            containerClassName="mb-4 md:mb-0 max-w-sm"
            handleChange={(_value, _index, _setValueMethod, _getValues, trigger) => {
              if (trigger) {
                handleResetOnSaleDate(_value);
              }
            }}
          />
          <Input
            placeholder="On Sale Time"
            fieldType="time"
            id="onSaleDateTime.time"
            // required
            disabled={!isOpen || locked}
            containerClassName="max-w-xs"
            // rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
          />
          <Dropdown
            placeholder="Timezone"
            id="onSaleDateTime.tz"
            containerClassName="max-w-md"
            options={TIME_ZONES}
            disabled={!isOpen || locked}
            // rules={{ required: "Please select a valid 'Timezone' option" }}
          />
        </div>
      </div>

      <hr className="opacity-20" />

      <div className="flex items-center">
        <Label htmlFor="checkAll" className="w-[70px]">
          Backstage
        </Label>
        <SelectAllCheckbox id="checkAll" containerClassName="mb-4 md:mb-0 standard-label" checkColor="black" />
      </div>

      <FieldArray
        className="col-span-2 mt-0"
        gridClassName={GRID_LAYOUT}
        groupName="showDetails"
        columns={width > windowSizes.MD ? columnLabels : undefined}
        addButtonLabel="Add Show"
        emptyRow={SHOW_DETAILS_EMPTY_ITEM}
        overrideRemove={handleRemoveShow}
        columnLabelsType="repeats"
        forceAppend={true}
        forceRemove={true}
        // disabled={!isOpen || locked || !canSubmit}
        lockRules={(field) => {
          const rule = {
            isLocked: false,
            fields: [] as string[],
          };

          // if a show status is Settled it should not be updated any more
          if (field.status === 'Settled') {
            rule.isLocked = true;
            rule.fields = ['dateString', 'showTime', 'status', 'notes', 'public', 'guarantee'];
          }

          if (field.isMasterShow) {
            rule.isLocked = true;
          }

          return rule;
        }}
      >
        <Checkbox
          id="public"
          containerClassName={'mb-4 md:mb-0 md:ml-[70px] standard-label !w-auto'}
          checkColor="black"
        />

        <DateInput
          id="date"
          ignoreTime
          placeholder="Show Date"
          containerClassName="!flex-col mb-4 md:mb-0"
          disabled={!isOpen || locked}
          label={width < windowSizes.MD ? 'Show Date' : false}
          rulesCallback={(formState, index) => ({
            required: ERROR_MESSAGES.VALID_DATE,
            validate: (value) => {
              if (formState?.defaultValues?.showDetails?.[index as number]?.date) {
                // all good if it matches the existing date
                if (formState?.defaultValues?.showDetails?.[index as number]?.date === value) {
                  return true;
                }
              }
            },
          })}
          handleChange={(_value, _index, _setValueMethod, _getValues, trigger) => {
            if (trigger) {
              trigger();
            }
          }}
          dataCy={cypressTags.DEAL_FORM.SHOW_DETAILS.SHOW_DATE}
        />

        <Dropdown
          disabled={!isOpen || locked}
          id="showTime"
          containerClassName={'!flex-col mb-4 md:mb-0'}
          label={width < windowSizes.MD ? 'Show Time' : false}
          options={SHOW_DETAILS_SHOW_TIME_OPTIONS}
          placeholder="Show Time"
          rulesCallback={(_formState, index) => ({
            required: ERROR_MESSAGES.REQUIRED_FIELDS,
            validate: (_, values) => {
              const date = values.showDetails[index as number]?.date;

              // Find all shows for the same date
              const showsGroup: iOrganismShowDetailsItem[] = values.showDetails.filter(
                (show: iOrganismShowDetailsItem) => show.date === date
              );

              // If there aren't multiple shows for the same date
              if (showsGroup.length && showsGroup.length < 2) {
                return true;
              }

              // If there are multiple shows on the same date and
              // one of them is an Only Show
              if (showsGroup.some((show) => show.showTime === 'Neither')) {
                return ERROR_MESSAGES.SHOW_DETAILS_ONLY_SHOW;
              }

              const uniqueDateShows = new Set(showsGroup.map((show) => show.showTime));

              // If multiple shows on the same date have the same time
              if (uniqueDateShows.size !== showsGroup.length) {
                return ERROR_MESSAGES.SHOW_DETAILS_DUPLICATE_SHOW_TIME;
              }
            },
          })}
          // TODO: This won't be needed when we merge the Dropdown's `rules` and `rulesCallback`
          hideClearButton
          handleChange={(_chosen, _setValueMethod, _index, trigger) => {
            if (trigger) {
              trigger();
            }
          }}
          dataCy={cypressTags.DEAL_FORM.SHOW_DETAILS.SHOW_TIME}
        />

        <Dropdown
          disabled={!isOpen || locked}
          containerClassName={'!flex-col mb-4 md:mb-0'}
          id="status"
          label={width < windowSizes.MD ? 'Status' : false}
          options={SHOW_DETAILS_STATUS_OPTIONS}
          placeholder="Show Status"
          rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
        />

        <div id="field_space" />

        <DateGroup id="field_group" width={width} />

        <TextArea
          containerClassName={'!flex-col mb-4 md:mb-0'}
          label={width < windowSizes.MD ? 'Show Note' : false}
          disabled={!isOpen || locked}
          placeholder="Type show notes here."
          id="notes"
          rows={1}
        />

        <Input
          fieldType="number"
          containerClassName={'!flex-col mb-4 md:mb-0'}
          label={width < windowSizes.MD ? 'Show Guarantee' : false}
          disabled={!isOpen || locked}
          placeholder="Type show guarantee here."
          id="guarantee"
          roundNumber={false}
          dataCy={cypressTags.DEAL_FORM.SHOW_DETAILS.SHOW_GUARANTEE}
        />
      </FieldArray>
    </Form>
  );
};

export default ShowDetailsForm;
