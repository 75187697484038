import { Bars3Icon } from '@heroicons/react/24/outline';
import { useEditDealContext } from 'context/editDealContext';
// import { iGenericFormRow } from 'deal-form/interfaces/general';
import { AddButton } from 'deal-form/ui/AddButton';
import { ColumnLabels } from 'deal-form/ui/ColumnLabels';
import { EmptyOrganismMessage } from 'deal-form/ui/EmptyOrganismMessage';
import { RemoveButton } from 'deal-form/ui/RemoveButton';
import React, { Children, ReactElement, ReactNode, cloneElement, useEffect } from 'react';
import {
  FieldArrayWithId,
  FieldValues,
  RegisterOptions,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormReset,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { v4 as uuid } from 'uuid';

export interface FieldArrayColumn {
  value: string;
  isRequired?: boolean;
  checkboxId?: string;
  checked?: boolean;
  type?: 'field' | 'field_space' | 'field_group';
}

interface iFieldArrayProps {
  columns?: FieldArrayColumn[];
  columnLabelsType?: 'single' | 'repeats';
  gridClassName: string;
  className?: string;
  groupName: string;
  emptyRow: any;
  limit?: number;
  addButtonLabel?: string;
  rearrangeable?: boolean;
  columnClassName?: string;
  children: ReactNode | ReactNode[];
  overrideAppend?: (getValues?: UseFormGetValues<FieldValues>, isDirty?: boolean, isValid?: boolean) => void;
  overrideRemove?: (
    index: number,
    reset: UseFormReset<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>,
    remove?: UseFieldArrayRemove,
    isDirty?: boolean,
    isValid?: boolean
  ) => void;
  forceAppend?: boolean;
  forceRemove?: boolean;
  hideFields?: { index: number; value: string; ids: string[] }[];
  appendCallback?: (index: number) => void;
  disabled?: boolean;
  showAll?: boolean;
  isNested?: boolean;
  rules?: RegisterOptions;
  index?: number;
  parentindex?: number;
  highlightOnError?: boolean;
  triggerFields?: string[];
  id?: string;
  lockRules?: (field: any, index: number) => { isLocked: boolean; fields: string[] | [] };
  formName?: string;
  showDivider?: boolean;
  disableIf?: {
    field: string;
    value: string | number | boolean | undefined;
  };
  disableMessage?: string;
  showLabel?: boolean;
  updateRowCount?: (numRows: number) => void;
}

type FieldArrayField = FieldArrayWithId & {
  _id?: string;
};

export const FieldArray = ({
  columns,
  columnLabelsType = 'single',
  gridClassName,
  emptyRow,
  children,
  groupName,
  addButtonLabel,
  rearrangeable,
  limit = 20,
  className = '',
  columnClassName = '',
  overrideAppend,
  overrideRemove,
  forceAppend,
  forceRemove,
  isNested = false,
  hideFields,
  appendCallback,
  disabled = false,
  disableIf,
  showAll = false,
  showDivider = true,
  rules = {},
  triggerFields,
  highlightOnError = true,
  lockRules,
  disableMessage = '',
  updateRowCount,
  ...props
}: iFieldArrayProps): ReactElement => {
  const {
    control,
    formState: { errors, isDirty, isValid },
    getValues,
    reset,
    trigger,
    setValue,
    // watch,
  } = useFormContext();
  const { updateOrganismValid } = useEditDealContext();

  const [componentId, setComponentId] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    if (!componentId) setComponentId(uuid());
  }, []);

  let group = groupName;

  if (isNested && props.index !== undefined) {
    group = groupName.replace('{index}', props.index.toString());
  }
  const { fields, append, remove } = useFieldArray({ control, name: group, rules });

  // React Hook Form appends the root property to formState.errors?.fieldArray?.root
  const hasErrors = !!errors[group]?.root;

  const arrayChildren = Children.toArray(children);
  const gridLayout = rearrangeable ? 'grid-cols-[40px_1fr_40px]' : 'grid-cols-[1fr_40px]';

  const ColumnLabel = ({ column }: { column: { value: string; isRequired?: boolean } }) => (
    <div className={`font-medium text-sm text-black my-[2px] ${columnClassName}`}>
      <div className="flex self-start">
        {column.value}
        {column.isRequired ? <span className="text-cinnabar">*</span> : null}
      </div>
    </div>
  );

  const hideAddButton =
    disableIf &&
    getValues(disableIf.field.replace('{index}', `${props.index}`).replace('{parentindex}', `${props.parentindex}`)) ===
      disableIf.value;

  return (
    <div
      className={`form-table my-4 ${className}  ${
        hasErrors && highlightOnError ? 'border border-solid border-cinnabar' : ''
      }`}
      id={`${componentId}`}
      data-cy={groupName}
    >
      {fields.length > 0 && columns && columnLabelsType === 'single' && (
        <ColumnLabels
          labels={columns}
          containerClassName={`${gridClassName} pr-10`}
          hasChildFields={fields.length > 0}
          updateChecked={(id: string, checked: boolean) => {
            fields.forEach((_, index) => {
              const fieldId = `${group}.${index}.${id}`;
              setValue(fieldId, checked, { shouldDirty: true });
            });
          }}
        />
      )}
      {fields.map((field: FieldArrayField, index) => {
        const isRowLocked: { isLocked: boolean; fields: string[] } | undefined = lockRules && lockRules(field, index);

        return (
          <React.Fragment key={`show-details-${field.id}-${index}`}>
            <div
              key={`show-details-${field.id}-${index}`}
              className={`grid ${gridLayout} bg-athensGrey md:bg-white pl-4 pr-2 py-4 md:p-0 mb-4 md:mb-0 border border-gallery md:border-none`}
            >
              {rearrangeable && (
                <button className="w-10 flex items-center justify-center">
                  <Bars3Icon width={24} />
                </button>
              )}
              <div className={`${gridClassName}`}>
                {Children.map(arrayChildren, (child, childIndex) => {
                  if (React.isValidElement(child)) {
                    const fieldId = `${group}.${index}.${child.props.id}`;
                    const hideField = hideFields && hideFields[index] ? hideFields[index].ids.includes(fieldId) : false;

                    return (
                      !hideField && (
                        <div
                          key={`field-${child.props.id}-${childIndex}`}
                          // Minimum width prevents columns from overflowing
                          // https://css-tricks.com/preventing-a-grid-blowout
                          className={`min-w-0 ${child.props.className || ''}`}
                        >
                          {columns &&
                            columnLabelsType === 'repeats' &&
                            columns[childIndex] &&
                            !['field_space', 'field_group'].includes(columns[childIndex]?.type!) && (
                              <ColumnLabel column={columns[childIndex]} />
                            )}

                          {child?.props?.id
                            ? cloneElement(child, {
                                ...child.props,
                                id: fieldId,
                                index,
                                parentindex: props.index,
                                // TODO: refactor by passing overrideDisabled
                                disabled:
                                  (isRowLocked?.isLocked && isRowLocked?.fields?.includes(child.props.id)) ||
                                  child.props.disabled,
                              })
                            : cloneElement(child)}
                        </div>
                      )
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              {/* TODO: refactor by passing overrideShowDelete */}
              {showAll || isRowLocked?.isLocked ? null : (
                <RemoveButton
                  disabled={disabled}
                  className={`self-start ${
                    columnLabelsType === 'repeats' ? 'pt-8' : 'pt-2'
                  } w-10 flex items-center justify-center`}
                  data-testid={`remove-btn-${index}`}
                  onClick={() => {
                    if (overrideRemove) {
                      overrideRemove(index, reset, getValues, remove, isDirty, isValid);
                      if (forceRemove) remove(index);
                    } else {
                      remove(index);
                    }
                    if (triggerFields) trigger(triggerFields);
                    setTimeout(() => {
                      // get the form

                      const formName = document.querySelector(`[id='${componentId}']`)?.closest('form')?.name;
                      // if it found the form, update the state of the form
                      if (formName) {
                        updateOrganismValid(formName, !(errors && Array.isArray(errors) && errors?.length > 0));
                      }
                    }, 250);
                  }}
                />
              )}
            </div>

            {showDivider ? (
              <hr className="hidden md:block my-2 border-[#D5D5D5]" />
            ) : (
              <hr className="hidden md:block my-2 border-transparent" />
            )}
          </React.Fragment>
        );
      })}

      {hideAddButton && disableMessage && <EmptyOrganismMessage>{disableMessage}</EmptyOrganismMessage>}

      {!hideAddButton && addButtonLabel && getValues(group).length < limit && (
        <AddButton
          disabled={disabled}
          className="add-button"
          onClick={() => {
            const numRows = getValues(group).length || 0;
            if (updateRowCount) {
              updateRowCount(numRows);
            }
            if (numRows < limit) {
              if (overrideAppend) {
                overrideAppend(getValues, isDirty, isValid);
                if (forceAppend) append(emptyRow);
              } else {
                append(emptyRow);
              }

              if (appendCallback) {
                appendCallback(emptyRow);
              }
            }
          }}
          text={addButtonLabel}
          dataCy={`${groupName}-add-button`}
        />
      )}
    </div>
  );
};
