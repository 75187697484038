//
// This component is deprecated, use TicketScalingCombined instead!
//

import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@kaizen/draft-tooltip';
import { updateDeal, updateDealShows } from 'api';
import { iUpdateShowPayload } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES, TICKETING_CURRENCIES } from 'deal-form/data/constants';
import { TICKET_SCALING_EMPTY_TIER_ITEM, TICKET_SCALING_TICKET_TIERS } from 'deal-form/data/organisms';
import { Calculation } from 'deal-form/form-controls/Calculation';
import { Checkbox } from 'deal-form/form-controls/Checkbox';
import { createTicketScalingPayload } from 'deal-form/helpers/submissionFormatting';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismTicketScaling, iOrganismTicketScalingTier } from 'deal-form/interfaces/organisms';
import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { Deal } from 'types';
import { tCurrency } from 'types/deal';
import { isWholeNumber } from 'utils/helpers';

import { Currency } from '../../form-controls/Currency';
import { Dropdown } from '../../form-controls/Dropdown';
import { DropdownWithStaticSearch } from '../../form-controls/DropdownWithStaticSearch';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { Label } from '../../form-controls/Label';
import { TextArea } from '../../form-controls/TextArea';
import Totals from '../../form-controls/Totals';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { formatDate } from 'deal-form/helpers/dealHelpers';

export const TICKET_LIMIT = 50;
export const COLUMN_LABELS = [
  { value: 'Show', isRequired: true },
  { value: 'Tier', isRequired: true },
  { value: 'Advance Price', isRequired: true },
  { value: 'Day of Price', isRequired: false },
  { value: 'Capacity', isRequired: true },
  { value: 'Comps', isRequired: true },
  { value: 'Sellable Capacity' },
  { value: 'Gross' },
];
export const GRID_LAYOUT = 'w-full grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr_0.5fr_80px_1fr] gap-2';

const duplicateTierValidation = (_: any, formValues: FieldValues) => {
  const combinedTiers = formValues.tiers.map((tier: iOrganismTicketScalingTier) => `${tier.showId}${tier.tier}`);
  const uniqueCombinedTiers = new Set(combinedTiers);

  return combinedTiers.length === uniqueCombinedTiers.size || ERROR_MESSAGES.DUPLICATE_SHOW_TIER;
};

const TicketScalingForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>(deal.currency);
  const [showDates, setShowDates] = useState<iOptions[]>([]);
  const [defaultValues, setDefaultValues] = useState<iOrganismTicketScaling | null>(null);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [applyToAll, setApplyToAll] = useState<boolean | null>(null);
  const [isShowSettled, setIsShowSettled] = useState<boolean>(false);
  const [customTierOptions, setCustomTierOptions] = useState<iOptions[]>([]);

  const getScalingTiers = () => {
    const tiers: iOrganismTicketScalingTier[] = [];
    let currency = deal.currency;

    // check to see if any shows are settled
    const isShowSettledVal = deal.shows.some((show) => show.status === 'Settled');
    setIsShowSettled(isShowSettledVal);

    //show only master show if tiers are applied to all shows and no shows are settled
    const dealsToShow =
      deal.allShowsFlag === true && !isShowSettled ? deal.shows.filter((s) => s.masterShowFlag === true) : deal.shows;
    const customTiers: iOptions[] = [];

    dealsToShow.forEach((show) => {
      show.ticketTiers.forEach((tier) => {
        if (tier.currency !== currency) {
          currency = tier.currency as tCurrency;
        }

        // check to see if the tier is custom, if it is then it needs to be added to the list of available tiers
        if (
          TICKET_SCALING_TICKET_TIERS.find((t) => t.id === tier.tier) === undefined &&
          customTiers.find((t) => t.id === tier.tier) === undefined
        ) {
          customTiers.push({ id: tier.tier, label: tier.tier });
        }
        const scalingTier = {
          showId: show._id,
          tier: tier.tier,
          advance: {
            value: tier.advance,
            currency: tier.currency,
          },
          dayOf: {
            value: tier.dayOfShow,
            currency: tier.currency,
          },
          available: tier.available,
          comps: tier.comp,
          note: tier.note,
          sellable: 0,
          gross: 0,
        };
        tiers.push(scalingTier);
      });
    });

    setCustomTierOptions(customTiers);
    setSelectedCurrency(currency);
    setApplyToAll(deal.allShowsFlag === true);

    return {
      currency: currency,
      ticketScalingNotes: deal.ticketScalingNotes,
      allShowsFlag: deal.allShowsFlag === true,
      tiers,
    };
  };

  useEffect(() => {
    // const applyAll = (deal?.allShowsFlag && deal.shows.length > 1) || false;
    setDefaultValues(getScalingTiers());

    const dateShows = deal.shows
      .sort(function (a, b) {
        if (new Date(a.date) === new Date(b.date)) {
          a.earlyLate === 'Early' ? 1 : -1;
        }

        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .map((show) => ({
        id: show._id,
        label: `${formatDate(show.date, false)} - ${show.earlyLate === 'Neither' ? 'Only Show' : show.earlyLate}`,
        disabled: show.status === 'Settled',
      }));

    // setApplyToAll(applyAll);
    setShowDates(dateShows);
  }, [deal]);

  const handleAddCustomOption = (newOption: string) => {
    setCustomTierOptions([...customTierOptions, { id: newOption, label: newOption, data: {} }]);
  };

  const onSubmit = async (data: iOrganismTicketScaling) => {
    // TODO: would there be a benefit in getting just the show or tier that was impacted by the change?
    // TODO: should we perform any additional data validation here?
    // ticket types for specific shows are meant to be unique (cant have two General Admission tiers on the same date)
    let updateData: iUpdateShowPayload[] = [];

    // If there're still tiers in the data
    // Add/update/remove tiers
    if (data.tiers && data.tiers.length) {
      const lastIndex = data.tiers.length - 1;

      if (applyToAll && !isShowSettled) {
        updateData = deal.shows.map((s) => createTicketScalingPayload(s, data.currency, data.tiers));
      } else if (data.tiers[lastIndex].showId && data.tiers[lastIndex].tier) {
        // get the show id
        deal.shows.forEach((s) => {
          // get the show date
          const showId = s._id;
          // get the tiers associated with that show
          const showTiers = data.tiers.filter((tierData) => tierData.showId === showId);
          // add the show to the query
          updateData.push(createTicketScalingPayload(s, data.currency, showTiers));
        });
      }
      // If there're no tiers in the data
      // Remove all ticketTiers from all shows
    } else {
      updateData = deal.shows.map((s) => createTicketScalingPayload(s, data.currency, []));
    }

    try {
      setCanSubmit(false);
      const updatedShowsResponse = await updateDealShows({ dealId: deal._id.toString(), updates: updateData });

      const updatedDeal = await updateDeal(deal._id.toString(), {
        ...(updatedShowsResponse.body as Deal),
        ticketScalingNotes: data.ticketScalingNotes,
        allShowsFlag: applyToAll && !isShowSettled,
      });

      if (setDeal) {
        setDeal(updatedDeal);
      }

      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log(e);
      setCanSubmit(true);
    }
  };

  const handleChangeCurrency = (chosen: iOptions | null) => {
    if (chosen) {
      setSelectedCurrency(chosen.id.toUpperCase());
    }
  };

  return (
    defaultValues && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className="form-row full-width"
        defaultValues={defaultValues}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.TICKET_SCALING}
      >
        {/* <div> Use this default currency: {defaultCurrency.label}</div> */}
        <div className=" flex">
          <Dropdown
            label="Ticketing Currency"
            placeholder="Currency"
            id="currency"
            options={TICKETING_CURRENCIES}
            disabled={!isOpen || locked}
            handleChange={handleChangeCurrency}
            rules={{
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
            }}
            containerClassName="md:max-w-xs"
          />
          {deal.shows.length > 1 && (
            <div className="ml-10 flex">
              <Checkbox
                containerClassName="pr-2"
                id="allShowsFlag"
                checkColor="black"
                label="Apply tiers to all shows"
                disabled={isShowSettled || !isOpen || locked}
                onHandleChange={(checked) => setApplyToAll(checked)}
              ></Checkbox>
              <Tooltip
                position="right"
                text="Applying tiers to all shows also applies Ticket Taxes and Ticket Fees to all shows. Apply to all shows is not available for deals with settled shows."
              >
                <InformationCircleIcon className="w-5 h-5 text-greyCloudy" />
              </Tooltip>
            </div>
          )}
        </div>
        <FieldArray
          gridClassName={GRID_LAYOUT}
          groupName="tiers"
          columns={COLUMN_LABELS}
          addButtonLabel="Add Tier"
          emptyRow={TICKET_SCALING_EMPTY_TIER_ITEM}
          disabled={!isOpen || locked}
          lockRules={(field) => {
            const rule = {
              isLocked: false,
              fields: [] as string[],
            };

            // if a show status is Settled it should not be updated any more
            if (deal.shows.find((s) => s._id === field.showId)?.status === 'Settled') {
              rule.isLocked = true;
              rule.fields = ['showId', 'tier', 'advance', 'dayOf', 'available', 'comps', 'note'];
            }

            return rule;
          }}
        >
          {applyToAll && !isShowSettled ? (
            <p className="text-sm px-4 leading-10">All Shows</p>
          ) : (
            <Dropdown
              placeholder="Show"
              id="showId"
              options={showDates}
              disabled={!isOpen || locked}
              rulesCallback={() => ({
                required: ERROR_MESSAGES.REQUIRED_FIELDS,
                validate: duplicateTierValidation,
              })}
              handleChange={(_chosen, _setValue, _index, trigger) => {
                if (trigger) {
                  trigger();
                }
              }}
              // TODO: This won't be needed when we merge the Dropdown's `rules` and `rulesCallback`
              hideClearButton
            />
          )}
          <DropdownWithStaticSearch
            placeholder="Tier"
            disabled={!isOpen || locked}
            id="tier"
            isRequired={true}
            label={false}
            maxLength={32}
            addCustomOption={handleAddCustomOption}
            customOptions={customTierOptions}
            staticOptions={TICKET_SCALING_TICKET_TIERS}
            rulesCallback={() => ({
              required: ERROR_MESSAGES.REQUIRED_FIELDS,
              validate: duplicateTierValidation,
            })}
            handleChange={(_chosen, _setValue, _index, trigger) => {
              if (trigger) {
                trigger();
              }
            }}
            customOptionLabel="Create Custom Tier"
          />

          <Currency
            placeholder="Advance"
            id="advance"
            overrideCurrency={selectedCurrency}
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL,
              min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
            }}
          />
          <Currency
            placeholder="Day of"
            id="dayOf"
            overrideCurrency={selectedCurrency}
            disabled={!isOpen || locked}
            rules={{
              min: { value: 0.0, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
            }}
          />
          <Input
            placeholder="Capacity"
            fieldType="number"
            id="available"
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.WHOLE_NUMBER,
              min: { value: 1, message: ERROR_MESSAGES.WHOLE_NUMBER },
              validate: (value) => isWholeNumber(value) || ERROR_MESSAGES.WHOLE_NUMBER,
            }}
          />
          <Input
            placeholder="Comps"
            fieldType="number"
            id="comps"
            disabled={!isOpen || locked}
            rules={{
              required: ERROR_MESSAGES.WHOLE_NUMBER,
              min: { value: 0, message: ERROR_MESSAGES.WHOLE_NUMBER },
              validate: (value) => isWholeNumber(value) || ERROR_MESSAGES.WHOLE_NUMBER,
            }}
          />
          <Calculation
            id="sellable"
            equation={[{ type: 'subtract', fields: ['tiers.index.available', 'tiers.index.comps'] }]}
          />
          <Calculation
            id="gross"
            equation={[{ type: 'multiply', fields: ['tiers.index.sellable', 'tiers.index.advance.value'] }]}
            currency={selectedCurrency}
          />
          <TextArea
            placeholder="Type description here."
            rules={{
              maxLength: {
                value: 1024,
                message: getMaxCharMessage('the description', 1024),
              },
            }}
            id="note"
            containerClassName="col-span-full"
            rows={2}
            disabled={!isOpen || locked}
          />
        </FieldArray>
        <div className={`${GRID_LAYOUT} pr-10`}>
          <label className="form-label col-start-4 self-end">Total</label>
          <Totals
            id="tiers"
            total="available"
            type="number"
            identifier="Capacity"
            label={false}
            multiplier={applyToAll ? deal.shows.length : undefined}
          />
          <Totals
            id="tiers"
            total="comps"
            type="number"
            identifier="Comps"
            label={false}
            multiplier={applyToAll ? deal.shows.length : undefined}
          />
          <Totals
            id="tiers"
            total="sellable"
            type="number"
            identifier="Sellable Capacity"
            label={false}
            multiplier={applyToAll ? deal.shows.length : undefined}
          />
          <Totals
            id="tiers"
            total="gross"
            identifier="Gross"
            label={false}
            currencyOverride={selectedCurrency}
            multiplier={applyToAll ? deal.shows.length : undefined}
          />
        </div>

        <div className="grid grid-cols-[100px_1fr_120px_1fr_120px_1fr] gap-5 pr-10 border-t border-greyCloudy pt-5 mt-5">
          <Label className=" self-start col-span-1 row-start-2" htmlFor="ticketScalingNotes">
            Ticketing Notes
          </Label>
          <TextArea
            containerClassName="col-span-5 row-start-2"
            disabled={!isOpen || locked}
            id="ticketScalingNotes"
            label={false}
            placeholder="Type notes here."
            rows={4}
            rules={{
              maxLength: {
                value: 2000,
                message: getMaxCharMessage('your notes', 2000),
              },
            }}
          />
        </div>
      </Form>
    )
  );
};

export default TicketScalingForm;
