import { Checkbox } from '@united-talent-agency/onyx-components';
import { CheckboxProps } from '@united-talent-agency/onyx-components/Forms/Checkbox/Checkbox';
import React from 'react';
import Table from '../../Table';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { TableHeaderCellProps } from 'components/Table/Header/Cell';

type HeaderProps = {
  checked?: CheckboxProps['checked'];
  onChange?: CheckboxProps['onChange'];
} & Omit<TableHeaderCellProps, 'onChange'>;

const Header: React.FC<HeaderProps> = ({ checked, onChange, className, ...props }) => (
  <Table.Header.Cell
    className={twMerge(
      clsx(
        'sticky print:relative left-0 max-w-none min-h-[56px] min-w-[74px] md:min-w-[212px] print:min-w-[74px] print:md:min-w-[124px] print:border-[#6B6A69] print:border-b',
        className
      )
    )}
    {...props}
  >
    <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-1 min-w-[80px] max-h-full md:min-w-[218px] print:min-w-[80px] print:md:min-w-[130px] bg-gradient-to-r from-[calc(100%-6px)] from-transparent via-[rgba(0,0,0,0.1)] via-[calc(100%-6px)] to-transparent to-100% print:bg-none" />
    <div className="flex absolute top-0 right-[6px] bottom-0 left-0 min-w-[74px] md:min-w-[212px] print:min-w-[74px] print:md:min-w-[124px]">
      <div className="flex flex-1 flex-row items-center print:w-full print:items-end px-4 md:px-6 print:md:px-0 print:px-0">
        <div className="flex max-md:flex-1 md:items-center print:items-end">
          <Checkbox checked={checked} onChange={onChange} className="print:hidden" />
          <span className="hidden md:flex print:flex md:pl-6 ml-2 print:md:pl-2 md:ml-0 text-sm whitespace-nowrap text-gray-500 font-bold print:items-end">
            Client
          </span>
        </div>
      </div>
    </div>
  </Table.Header.Cell>
);

export default Header;
