//
// This component is deprecated, use TicketScalingCombined instead!
//

import { handleUpdateDealShowsResponse, iUpdateShowPayload, updateDealShows } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  TICKET_TAXES_CALCULATION,
  TICKET_TAXES_EMPTY_FORM,
  TICKET_TAXES_EMPTY_TIER_ITEM,
  TICKET_TAXES_MATH_TYPE,
} from 'deal-form/data/organisms';
import { TextField } from 'deal-form/form-controls/TextField';
import { formatDate, getShowFromTierId } from 'deal-form/helpers/dealHelpers';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismTicketTaxes, iOrganismTicketTaxesItem } from 'deal-form/interfaces/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { Tax } from 'types';

import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { TextArea } from '../../form-controls/TextArea';
import Totals from '../../form-controls/Totals';
import { getTicketCurrency } from '../../helpers/dealHelpers';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import { EmptyOrganismMessage } from '../../ui/EmptyOrganismMessage';

export const COLUMN_LABELS = [
  { value: 'Tier', isRequired: true },
  { value: 'Fee', isRequired: true },
  { value: 'Calculation', isRequired: true },
  { value: 'Math Type', isRequired: true },
  { value: 'Tax Amount' },
  { value: 'Description' },
];
export const GRID_LAYOUT = 'grid grid-cols-[1fr_80px_1fr_1fr_150px_2fr] gap-2 ';

const formatEarlyLate = (earlyLate: string) => (earlyLate === 'Neither' ? 'Only Show' : earlyLate);

const TicketTaxesForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked }) => {
  const [availableTierOptions, setAvailableTierOptions] = useState<iOptions[]>([]);
  const [canSubmit, setCanSubmit] = useState(true);
  const [applyToAll, setApplyToAll] = useState<boolean>(false);
  const [isShowSettled, setIsShowSettled] = useState<boolean>(false);

  const onSubmit = async (data: iOrganismTicketTaxes) => {
    let errorsFound = false;
    // do a quick validation
    data.tiers.forEach((t) => {
      if (!t.tier || t.tier === '' || t.calculation === '' || t.fee === 0 || t.mathType === '') errorsFound = true;
    });

    if (errorsFound) return;
    const updateData: iUpdateShowPayload[] = [];

    // loop through the shows
    deal.shows.forEach(({ _id, ticketTiers }) => {
      const updatedTiers = [...ticketTiers];
      const taxesInShow =
        applyToAll && !isShowSettled ? data.tiers : data.tiers.filter((t) => getShowFromTierId(t.tier).showId === _id);

      updatedTiers.forEach((t) => {
        // check to see if the tier has any fees
        const taxesInTier = taxesInShow.filter((f) => getShowFromTierId(f.tier).tierLabel === t.tier);

        if (taxesInTier.length > 0) {
          // if yes, replace
          t.taxes = taxesInTier.map(({ fee, calculation, description, mathType }) => {
            return {
              note: description,
              percent: fee,
              beforeFee: calculation === 'true',
              type: mathType,
            } as Tax;
          });
        } else {
          // if no, clear []
          t.taxes = [];
        }
      });
      // loop through the shows
      updateData.push({ showId: _id, updateBody: { ticketTiers: updatedTiers } });
    });
    if (updateData.length === 0) return;

    try {
      setCanSubmit(false);
      const response = await updateDealShows({
        dealId: deal._id.toString(),
        updates: updateData,
      });

      if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });
      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR UPDATING TICKET TAXES', e);
      setCanSubmit(true);
    }
  };

  useEffect(() => {
    // check to see if any shows are settled
    const isShowSettledVal = deal.shows.some((show) => show.status === 'Settled');
    setIsShowSettled(isShowSettledVal);

    const applyAll = deal?.allShowsFlag || false;
    setApplyToAll(applyAll && !isShowSettledVal);

    //show only master show if tiers are applied to all shows
    const dealsToShow =
      applyAll && !isShowSettledVal ? deal.shows.filter((s) => s.masterShowFlag === true) : deal.shows;

    const opts: iOptions[] = [];
    dealsToShow.forEach((show) => {
      show.ticketTiers.forEach((tier) => {
        const option: iOptions = {
          id: `${show._id}|${formatEarlyLate(show.earlyLate)}|${tier.tier}`,
          label: `${applyAll ? 'All Shows' : formatDate(show.date)} ${
            applyAll ? '–' : `(${formatEarlyLate(show.earlyLate)})`
          }\n${tier.tier}`,
          disabled: show.status === 'Settled',
        };
        opts.push(option);
      });
    });
    setAvailableTierOptions(opts);
  }, [deal]);

  const checkForTicketTaxes = (applyAll: boolean) => {
    //show only master show if tiers are applied to all shows
    const dealsToShow = applyAll ? deal.shows.filter((s) => s.masterShowFlag === true) : deal.shows;

    const allTaxes: iOrganismTicketTaxesItem[] = [];
    dealsToShow.forEach(({ _id, earlyLate, ticketTiers }) => {
      if (ticketTiers.length > 0) {
        ticketTiers.forEach(({ taxes, fees, currency, tier, available, comp, advance }) => {
          if (taxes.length > 0) {
            taxes.forEach(({ percent, note, type, beforeFee }) => {
              const tierGross = (available - comp) * advance;
              const sellable = available - comp;
              let calculatedGross = 0;
              if (beforeFee) {
                if (type === 'Multiplier') {
                  calculatedGross = tierGross * (percent / 100);
                } else {
                  calculatedGross = tierGross - tierGross / (1 + percent / 100);
                }
              } else {
                let feeTotal = fees.reduce((acc, fee) => (fee.type === 'Built-In' ? Number(fee.amount) + acc : acc), 0);
                feeTotal *= sellable;
                if (type === 'Multiplier') {
                  calculatedGross = (tierGross - feeTotal) * (percent / 100);
                } else {
                  calculatedGross = tierGross - feeTotal - (tierGross - feeTotal) / (1 + percent / 100);
                }
              }

              const item: iOrganismTicketTaxesItem = {
                calculation: beforeFee ? 'true' : 'false',
                description: note,
                mathType: type || '',
                fee: percent || 0,
                tier: `${_id}|${formatEarlyLate(earlyLate)}|${tier}`,
                gross: {
                  value: calculatedGross, //type === 'Built-In' ? +tierGross + +amount : +tierGross,
                  currency,
                },
              };
              allTaxes.push(item);
            });
          }
        });
      }
    });
    return allTaxes;
  };

  const defaultValues = useMemo(() => {
    const allTaxes = checkForTicketTaxes(deal.allShowsFlag || false);

    return allTaxes.length > 0 ? { tiers: allTaxes } : TICKET_TAXES_EMPTY_FORM;
  }, [deal]);

  const emptyTierItemWithCurrency = useMemo(() => {
    const grossWithCurrency = {
      ...TICKET_TAXES_EMPTY_TIER_ITEM.gross,
      currency: getTicketCurrency(deal),
    };
    return { ...TICKET_TAXES_EMPTY_TIER_ITEM, gross: grossWithCurrency };
  }, [deal]);

  return (
    <Form
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      className="form-row full-width"
      defaultValues={defaultValues}
      disabled={!isOpen || locked}
      formName={FORM_NAMES.TICKET_TAXES}
      isSaveButton={availableTierOptions.length > 0}
    >
      <FieldArray
        gridClassName={GRID_LAYOUT}
        groupName="tiers"
        columns={COLUMN_LABELS}
        addButtonLabel="Add Tier"
        emptyRow={emptyTierItemWithCurrency}
        disabled={!isOpen || locked}
        lockRules={(field) => {
          const rule = {
            isLocked: false,
            fields: [] as string[],
          };

          const fieldShowId = field.tier.split('|')[0];
          const matchingShow = deal.shows.find((show) => show._id === fieldShowId);

          // if a show status is Settled it should not be updated any more
          if (matchingShow && matchingShow.status === 'Settled') {
            rule.isLocked = true;
            rule.fields = ['tier', 'percent', 'calculation', 'mathType', 'description', 'fee'];
          }

          return rule;
        }}
      >
        <Dropdown
          placeholder="Tier"
          id="tier"
          options={availableTierOptions}
          disabled={!isOpen || locked}
          rules={{ required: 'A ‘Tier’ selection is required' }}
        />

        <Input
          placeholder="Fee"
          fieldType="percent"
          id="fee"
          step="0.1"
          rules={{
            required: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL,
            min: { value: 1, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
            max: { value: 100, message: ERROR_MESSAGES.PERCENTAGE_ONE_DECIMAL },
          }}
          disabled={!isOpen || locked}
        />

        <Dropdown
          placeholder="Calculation"
          id="calculation"
          options={TICKET_TAXES_CALCULATION}
          disabled={!isOpen || locked}
          rules={{ required: 'A ‘Calculation’ selection is required' }}
        />

        <Dropdown
          placeholder="Math Type"
          id="mathType"
          options={TICKET_TAXES_MATH_TYPE}
          disabled={!isOpen || locked}
          rules={{ required: 'A ‘Math Type’ selection is required' }}
        />

        <TextField disabled={!isOpen || locked} text="--" id="gross" isCurrency className="leading-10" />
        <TextArea
          placeholder="Type description here."
          rules={{
            maxLength: {
              value: 1024,
              message: getMaxCharMessage('the description', 1024),
            },
          }}
          id="description"
          rows={1}
          disabled={!isOpen || locked}
        />
      </FieldArray>

      <>
        {availableTierOptions.length > 0 ? (
          <div className={`${GRID_LAYOUT} pr-10`}>
            <label className="form-label col-start-4 self-end">Total</label>
            <Totals
              id="tiers"
              total="gross"
              identifier="Tax Amount"
              label={false}
              multiplier={applyToAll ? deal.shows.length : undefined}
            />
          </div>
        ) : (
          <EmptyOrganismMessage>Please enter a Ticket Scaling tier to edit Ticket Taxes.</EmptyOrganismMessage>
        )}
      </>
    </Form>
  );
};

export default TicketTaxesForm;
