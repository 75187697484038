import { iCurrencyOption } from 'deal-form/interfaces/general';

export const CURRENCIES: iCurrencyOption[] = [
  { id: 'USD', label: 'USD($)', symbol: '$' },
  {
    id: 'CAD',
    label: 'CAD(CA$)',
    symbol: 'CA$',
  },
  {
    id: 'AUD',
    label: 'AUD(A$)',
    symbol: 'A$',
  },
  { id: 'EUR', label: 'EUR(€)', symbol: '€' },
  { id: 'GBP', label: 'GBP(£)', symbol: '£' },
  { id: 'SEK', label: 'SEK(kr)', symbol: 'kr' },
  {
    id: 'NOK',
    label: 'NOK',
    symbol: '',
  },
];

export const TICKETING_CURRENCIES: iCurrencyOption[] = [
  {
    id: 'AED',
    label: 'AED',
    symbol: '',
  },
  {
    id: 'ALL',
    label: 'ALL',
    symbol: '',
  },
  {
    id: 'AMD',
    label: 'AMD',
    symbol: '',
  },
  {
    id: 'ARS',
    label: 'ARS',
    symbol: '',
  },
  {
    id: 'AUD',
    label: 'AUD(A$)',
    symbol: 'A$',
  },
  {
    id: 'AZN',
    label: 'AZN',
    symbol: '',
  },
  {
    id: 'BAM',
    label: 'BAM',
    symbol: '',
  },
  {
    id: 'BGN',
    label: 'BGN',
    symbol: '',
  },
  {
    id: 'BHD',
    label: 'BHD',
    symbol: '',
  },
  {
    id: 'BRL',
    label: 'BRL(R$)',
    symbol: 'R$',
  },
  {
    id: 'CAD',
    label: 'CAD(CA$)',
    symbol: 'CA$',
  },
  {
    id: 'CHF',
    label: 'CHF',
    symbol: '',
  },
  {
    id: 'CLP',
    label: 'CLP',
    symbol: '',
  },
  {
    id: 'CNY',
    label: 'CNY(CN¥)',
    symbol: 'CN¥',
  },
  {
    id: 'COP',
    label: 'COP',
    symbol: '',
  },
  {
    id: 'CZK',
    label: 'CZK',
    symbol: '',
  },
  {
    id: 'DKK',
    label: 'DKK',
    symbol: '',
  },
  {
    id: 'DOP',
    label: 'DOP',
    symbol: '',
  },
  {
    id: 'EGP',
    label: 'EGP',
    symbol: '',
  },
  {
    id: 'EUR',
    label: 'EUR(€)',
    symbol: '€',
  },
  {
    id: 'GBP',
    label: 'GBP(£)',
    symbol: '£',
  },
  {
    id: 'GEL',
    label: 'GEL',
    symbol: '',
  },
  {
    id: 'HKD',
    label: 'HKD(HK$)',
    symbol: 'HK$',
  },
  {
    id: 'HRK',
    label: 'HRK',
    symbol: '',
  },
  {
    id: 'HUF',
    label: 'HUF',
    symbol: '',
  },
  {
    id: 'IDR',
    label: 'IDR',
    symbol: '',
  },
  {
    id: 'ILS',
    label: 'ILS(₪)',
    symbol: '₪',
  },
  {
    id: 'INR',
    label: 'INR(₹)',
    symbol: '₹',
  },
  {
    id: 'ISK',
    label: 'ISK',
    symbol: '',
  },
  {
    id: 'JOD',
    label: 'JOD',
    symbol: '',
  },
  {
    id: 'JPY',
    label: 'JPY(¥)',
    symbol: '¥',
  },
  {
    id: 'KRW',
    label: 'KRW(₩)',
    symbol: '₩',
  },
  {
    id: 'KWD',
    label: 'KWD',
    symbol: '',
  },
  {
    id: 'KZT',
    label: 'KZT',
    symbol: '',
  },
  {
    id: 'LKR',
    label: 'LKR',
    symbol: '',
  },
  {
    id: 'MAD',
    label: 'MAD',
    symbol: '',
  },
  {
    id: 'MOP',
    label: 'MOP',
    symbol: '',
  },
  {
    id: 'MXN',
    label: 'MXN(MX$)',
    symbol: 'MX$',
  },
  {
    id: 'MYR',
    label: 'MYR',
    symbol: '',
  },
  {
    id: 'NOK',
    label: 'NOK',
    symbol: '',
  },
  {
    id: 'NZD',
    label: 'NZD(NZ$)',
    symbol: 'NZ$',
  },
  {
    id: 'OMR',
    label: 'OMR',
    symbol: '',
  },
  {
    id: 'PHP',
    label: 'PHP(₱)',
    symbol: '₱',
  },
  {
    id: 'PLN',
    label: 'PLN',
    symbol: '',
  },
  {
    id: 'QAR',
    label: 'QAR',
    symbol: '',
  },
  {
    id: 'RON',
    label: 'RON',
    symbol: '',
  },
  {
    id: 'RSD',
    label: 'RSD',
    symbol: '',
  },
  {
    id: 'RUB',
    label: 'RUB',
    symbol: '',
  },
  {
    id: 'TRY',
    label: 'RY',
    symbol: '',
  },
  {
    id: 'SAR',
    label: 'SAR',
    symbol: '',
  },
  {
    id: 'SEK',
    label: 'SEK(kr)',
    symbol: 'kr',
  },
  {
    id: 'SGD',
    label: 'SGD',
    symbol: '',
  },
  {
    id: 'THB',
    label: 'THB',
    symbol: '',
  },
  {
    id: 'TWD',
    label: 'TWD(NT$)',
    symbol: 'NT$',
  },
  {
    id: 'UAH',
    label: 'UAH',
    symbol: '',
  },
  {
    id: 'USD',
    label: 'USD($)',
    symbol: '$',
  },
  {
    id: 'VND',
    label: 'VND(₫)',
    symbol: '₫',
  },
  {
    id: 'ZAR',
    label: 'ZAR',
    symbol: '',
  },
];

export enum FORM_NAMES {
  ADDITIONAL_CLAUSES = 'Additional Clauses',
  ADDITIONAL_PROVISIONS = 'Additional Provisions',
  BILLING = 'Billing',
  BALANCE_REMITTANCE = 'Balance Remittance',
  BUYER = 'Buyer',
  BUYOUTS = 'Buyouts',
  CALCULATED_VALUES = 'Calculated Values',
  CLIENT = 'Client',
  CONTRACT_INFO = 'Contract Info',
  FINANCIAL = 'Accounting',
  FIXED_EXPENSES = 'Fixed Expenses',
  LINKED_DEALS = 'Linked Deals',
  MERCHANDISE = 'Merchandise',
  PAYMENT_SCHEDULE = 'Payment Schedule',
  PAYMENTS_TO_CLIENT = 'Payments To Client',
  PAYMENTS_ON_CLIENTS_BEHALF = 'Payments on Client’s Behalf',
  RADIUS_CLAUSE = 'Radius Clause',
  REDUCTIONS = 'Reductions',
  SCHEDULE = 'Schedule',
  SETTLEMENT_FINALS = 'Settlement Finals',
  SETTLEMENT_STATUS = 'Settlement Status',
  SETTLEMENT_TOTALS = 'Settlement Totals',
  SHOW_CONTACTS = 'Show Contacts',
  SHOW_DETAILS = 'Show Details',
  SHOW_LOCATION = 'Show Location',
  TERMS_OF_THE_DEAL = 'Terms of the Deal',
  TICKET_CALCULATIONS = 'Ticket Calculations',
  TICKET_FEES = 'Ticket Fees',
  TICKET_SCALING = 'Ticket Scaling',
  TICKET_TAXES = 'Ticket Taxes',
  TICKET_TAXES_COMBINED = 'Taxes',
  TRAVEL_ACCOMMODATIONS = 'Travel Accommodations',
  VARIABLE_EXPENSES = 'Variable Expenses',
  SPLIT_DEAL = 'Split Deal',
  CURRENCY_CONVERTER = 'Currency Converter',
}

export const ERROR_MESSAGES = {
  CAP_MINIMUM_VALUE: 'Cap value cannot be a negetive number !',
  CLIENT_BUSINESS_NAME: 'Please select a valid Client Business Name',
  CONTRACT_SIGNER_BLOCK: 'Please select a valid Contract Signer Block',
  CONTRACT_SPLIT_TYPE: 'Please select a valid Contract Split Type',
  DATE_AFTER_TODAY: "Please select a valid future date based on today's date",
  DOLLAR_TWO_DECIMAL: 'Please enter a valid dollar amount with two decimal places',
  DUPLICATE_SHOW_TIER: 'Please select a unique Show and Tier combination',
  DUPLICATE_EVENT_TYPE: 'Please select a unique Event Type under the same Show',
  INVALID_SEARCH_RESULTS: 'No valid search results selected',
  MINIMUM_CONTRACT_SPLIT: 'Please add at least 2 Contract Splits',
  PERCENTAGE_ONE_DECIMAL: 'Please enter a valid % amount with one decimal place (Between 1-100) Maximum 100%',
  PERCENTAGE_ZERO: 'Please enter a valid % amount (Between 0-100) Maximum 100%',
  PERCENTAGE_SPLIT: 'Please enter a valid % amount with one decimal place (Between 1-99) Maximum 99%',
  REQUIRED_FIELDS: 'Please enter all required fields',
  SHOW_DETAILS_DUPLICATE_SHOW_TIME: 'Cannot enter multiple shows on the same time and calendar day',
  SHOW_DETAILS_ONLY_SHOW: 'Cannot select Only Show when there are multiple shows on the same date',
  SPLIT_DEAL_AMOUNTS: 'Your entered Split amounts do not equal 100% of the deal',
  VALID_DATE: 'Please select a valid date type (dd/mm/yyyy)',
  WHOLE_NUMBER_NO_DECIMAL: 'Select a valid whole number without decimal places',
  WHOLE_NUMBER: 'Please select a valid whole number without decimal places',
};

export const OVERVIEW_ORGANISM_SECTIONS = [
  {
    name: 'Client',
    description: 'Client / Accounting / Contract Info',
    href: '#client',
    coveringOrganismIds: ['client', 'accounting', 'contract-info'],
    mobileSortOrder: 1,
    desktopSortOrder: 1,
  },
  {
    name: 'Ticketing',
    description: 'Ticket Scaling / Ticket Calculations ',
    href: '#ticket-scaling',
    coveringOrganismIds: ['ticket-scaling', 'ticket-calculations', 'ticket-fees', 'ticket-taxes'],
    mobileSortOrder: 4,
    desktopSortOrder: 2,
  },
  {
    name: 'Contacts',
    description: 'Buyer / Show Contacts',
    href: '#buyer',
    coveringOrganismIds: ['buyer', 'show-contacts'],
    mobileSortOrder: 7,
    desktopSortOrder: 3,
  },
  {
    name: 'Show Details',
    description: 'Show Location / Show Details / Billing / Show Schedule',
    href: '#show-location',
    coveringOrganismIds: ['show-location', 'show-details', 'billing', 'schedule'],
    mobileSortOrder: 2,
    desktopSortOrder: 4,
  },
  {
    name: 'Expenses',
    description: 'Fixed Expenses / Variable Expenses / Calculated Values',
    href: '#fixed-expenses',
    coveringOrganismIds: ['fixed-expenses', 'variable-expenses', 'calculated-values'],
    mobileSortOrder: 5,
    desktopSortOrder: 5,
  },
  {
    name: 'Settlement',
    description: 'Settlement Totals / Balance Remittance /  Payments to Client / Payments on Client’s Behalf ',
    href: '#settlement-totals',
    coveringOrganismIds: [
      'settlement-totals',
      'payments-to-client',
      'balance-remittance',
      "payments-on-client's-behalf",
    ],
    mobileSortOrder: 8,
    desktopSortOrder: 6,
  },
  {
    name: 'Terms',
    description:
      'Terms of the Deal / Split Deal / Buyout / Payment Schedule / Travel Accommodations / Merchandise / Radius Clause',
    href: '#terms-of-the-deal',
    coveringOrganismIds: [
      'terms-of-the-deal',
      'split-deal',
      'buyouts',
      'payment-schedule',
      'travel-accommodations',
      'merchandise',
      'radius-clause',
    ],
    mobileSortOrder: 3,
    desktopSortOrder: 7,
  },
  {
    name: 'Provisions',
    description: 'Additional Provisions / Clauses',
    href: '#additional-provisions',
    coveringOrganismIds: ['additional-provisions', 'additional-clauses'],
    mobileSortOrder: 6,
    desktopSortOrder: 7,
  },
];
