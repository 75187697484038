import Cell from './Cell';
import Full from './Full';
import Row from './Row';
import THead from './THead';

const Header = {
  Full,
  Cell,
  THead,
  Row,
};

export default Header;
