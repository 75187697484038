import { ReactElement, ChangeEventHandler, useCallback } from 'react';
import { DateTimePicker, Sliderbar, Switch } from '@united-talent-agency/onyx-components';
import { useFilters } from '../../../context/avails-filters/context';

const DateFilter = (): ReactElement => {
  const {
    includeConfirmedShows,
    setIncludeConfirmedShows,
    date,
    setDate,
    daysAfter,
    setDaysAfter,
    daysBefore,
    setDaysBefore,
  } = useFilters();

  const handleBeforeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setDaysBefore(Number(e.target.value));
    },
    [setDaysBefore]
  );

  const handleAfterChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setDaysAfter(Number(e.target.value));
    },
    [setDaysAfter]
  );

  const handleConfirmedShowsChange = useCallback(
    (value: boolean | undefined) => {
      setIncludeConfirmedShows(!!value);
    },
    [setIncludeConfirmedShows]
  );

  return (
    <>
      <DateTimePicker onlyDate placement="top" label="Date" className="w-1/2" onChange={setDate} value={date} />
      <div className="mt-5">
        <label className="text-sm text-[#808080]">
          <span className="font-bold text-black">{daysBefore}</span> Days Before
        </label>
        <div className="mt-2">
          <Sliderbar value={daysBefore} min={0} max={30} onChange={handleBeforeChange} />
        </div>
      </div>
      <div className="mt-5">
        <label className="text-sm text-[#808080]">
          <span className="font-bold text-black">{daysAfter}</span> Days After
        </label>
        <div className="mt-2">
          <Sliderbar inverse value={daysAfter} min={0} max={30} onChange={handleAfterChange} />
        </div>
      </div>
      <div className="toggle-container mt-6 flex items-center gap-3">
        <Switch size="lg" checked={includeConfirmedShows} onChange={handleConfirmedShowsChange} />
        <span className="text-sm font-normal">Include Clients with Confirmed Shows on All Dates</span>
      </div>
    </>
  );
};

export default DateFilter;
