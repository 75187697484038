import { XMarkIcon } from '@heroicons/react/24/outline';
import { ButtonHTMLAttributes, MouseEventHandler } from 'react';

interface iRemoveButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: MouseEventHandler<HTMLButtonElement>;
  dataCy?: string;
  disabled?: boolean;
  className?: string;
}

export const RemoveButton = ({ onClick, dataCy, className, disabled, ...props }: iRemoveButtonProps) => (
  <button
    data-cy={dataCy}
    data-testid={dataCy}
    type="button"
    className={className}
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    <XMarkIcon color={disabled ? 'grey' : 'red'} width={24} />
  </button>
);

RemoveButton.defaultProps = {
  dataCy: '',
  className: '',
  disabled: false,
};
