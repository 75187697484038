import React, { FC, useCallback, useMemo } from 'react';
import { ColDef, ColGroupDef, GridReadyEvent, IDateFilterParams, IServerSideDatasource } from 'ag-grid-community';
import { AgGridReact as AgGrid } from 'ag-grid-react';
import { algoliaClient, searchClient } from 'api/search-client';
import { formatPercent } from 'deal-form/helpers/dealHelpers';
import { staticCellStyle } from 'grid-pages/grid-styles';
// import { iCurrencyNumber, iPerson } from 'grid-pages/interfaces';
import { searchPageDefaultCols } from 'grid-pages/utils/configs';
import { dateFilterComparator, sortKeyComparator } from 'grid-pages/utils/sorting-helpers';
import { CurrencyRenderer, DateRenderer, NameRenderer, NumberRenderer } from 'grid-pages/components';
import GridTable from 'grid-pages/organisms/GridTable';

// import { contactUrlForId } from 'urls';

interface ContactGridProps {
  gridApiRef: React.RefObject<AgGrid> | null;
  isExporting?: boolean;
  setIsExporting?: (val: boolean) => void;
}

const AlgoliaServerSide: FC<ContactGridProps> = ({ gridApiRef }) => {
  const index = 'dev_touring_deal_grids';
  const defaultColDef = useMemo(() => searchPageDefaultCols, []);

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.id;
    };
  }, []);

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { startRow = 0, endRow = 50, filterModel, sortModel } = params.request;

      let sortString = '';
      if (sortModel.length) {
        const { colId, sort } = sortModel[0];
        sortString = `_${colId}${sort === 'desc' ? '_desc' : ''}`;
      }

      let facets = [];
      if (filterModel['type']) {
        facets = filterModel['type'].values.map((v: string) => `type:${v}`);
      }

      let searchQuery = '';
      if (filterModel['contactName']) {
        searchQuery = filterModel['contactName'].filter;
      }

      const searchParams = {
        offset: startRow,
        length: endRow - startRow,
        facetFilters: [facets],
      };

      const indexToUse = `${index}${sortString}`;

      try {
        const searchIndex = algoliaClient.initIndex(indexToUse);
        const results = await searchClient(searchIndex, searchQuery, searchParams);
        const { hits, nbHits } = results;

        if (hits) {
          const parsedHits = hits.map((hit: any) => {
            const { venue, agents } = hit;
            return {
              ...hit,
              id: hit.objectID, //need id for csv export
              date: hit.startDate,
              city: venue?.city,
              state: venue?.state,
              country: venue?.country,
              venue: venue?.name,
              guarantee: {
                amount: hit.guarantee,
                currency: 'USD',
              },
              overage: {
                amount: hit.overage,
                currency: 'USD',
              },
              agencyReduction: hit.totalAgencyReductions,
              ticketsSold: hit.totalTickets,
              sellable: hit.totalTickets,
              percentSold: hit.totalPercentSold ? formatPercent(hit.totalPercentSold) + '%' : '--',
              gbor: hit.estimatedGrossPotential,
              nbor: hit.estimatedNetPotential,
              agents: agents && agents.length ? agents[0] : '',
              paymentScheduleDateDue: hit.paymentScheduleDueDate,
              paymentScheduleAmount: hit.expectedTotalPayments,
              confirmed: hit.dealDateConfirmed,
            };
          });

          params.success({
            rowData: parsedHits,
            rowCount: nbHits,
          });
        }
      } catch (error) {
        console.log('ERROR', error);
        params.fail();
      }
    },
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  }, []);

  const getRowHeight = (params: any) => {
    const height = params.data?.contacts?.length > 2 ? params.data?.contacts?.length * 20 : 40;
    return height;
  };

  const gridOptions: any = {
    localeText: {
      noRowsToShow: 'No data to display',
    },
    pagination: true,
    paginationPageSize: 50, //how many rows to display per page, default 100
    cacheBlockSize: 100, //how many rows are fetched at one time, default 100
    rowModelType: 'serverSide',
    getRowHeight: getRowHeight,
  };

  const columnTypes = useMemo(() => {
    return {
      dateColumn: {
        cellRenderer: DateRenderer,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        } as IDateFilterParams,
      },
      currencyColumn: {
        cellRenderer: CurrencyRenderer,
        comparator: (valueA: any, valueB: any) => sortKeyComparator(valueA, valueB, 'amount'),
      },
      numberColumn: {
        cellRenderer: NumberRenderer,
      },
      nameColumn: {
        cellRenderer: NameRenderer,
        filter: 'agTextColumnFilter',
        filterValueGetter: (params: any) => {
          const colId = params.column.colId;
          return params.data[colId]?.name;
        },
        comparator: (valueA: any, valueB: any) => sortKeyComparator(valueA, valueB || '', 'name'),
      },
    };
  }, []);

  const defaultProps = {
    resizable: true,
    lockPinned: true,
    cellStyle: staticCellStyle,
    minWidth: 75,
  };

  const ColumnDefinitions: Array<ColDef | ColGroupDef> = [
    {
      field: 'check',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      maxWidth: 44,
      headerClass: 'select-all',
      cellClass: 'select-check-box',
      cellStyle: {
        padding: '8px 10px',
      },
    },
    {
      ...defaultProps,
      field: 'date',
      headerName: 'Date',
      type: ['dateColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'client',
      headerName: 'Client',
      type: ['nameColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'city',
      headerName: 'City',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'state',
      headerName: 'State',
      filter: 'agTextColumnFilter',
      width: 160,
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'country',
      headerName: 'Country',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'venue',
      headerName: 'Venue',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'dealStatus',
      headerName: 'Deal Status',
      width: 180,
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'contractStatus',
      headerName: 'Contract Status',
      width: 180,
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'guarantee',
      headerName: 'Guarantee',
      type: ['currencyColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'overage',
      headerName: 'Overage',
      type: ['currencyColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'totalPayout',
      headerName: 'Total Payout',
      type: ['currencyColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'agencyReduction',
      headerName: 'Agency Reduction',
      type: ['currencyColumn'],
    },
    {
      ...defaultProps,
      field: 'ticketsSold',
      headerName: 'Total Tickets Sold',
      type: ['numberColumn'],
      filter: 'agNumberColumnFilter',
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'sellable',
      headerName: 'Total Sellable Capacity',
      filter: 'agNumberColumnFilter',
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'percentSold',
      headerName: 'Total Percent Sold',
      filter: 'agNumberColumnFilter',
    },
    {
      ...defaultProps,
      field: 'gbor',
      headerName: 'GBOR',
      type: ['currencyColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'nbor',
      headerName: 'NBOR',
      type: ['currencyColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'dealId',
      headerName: 'Deal ID',
      filter: 'agTextColumnFilter',
      sortable: true,
      // sortable: true,
    },
    {
      ...defaultProps,
      field: 'agents',
      headerName: 'Agent',
      type: ['nameColumn'],
      sortable: true,
    },
    // {
    //   ...defaultProps,
    //   field: 'agencyCommission',
    //   headerName: 'Agency Commission Total',
    //   type: ['currencyColumn'],
    // },
    {
      ...defaultProps,
      field: 'paymentScheduleDateDue',
      headerName: 'Payment Schedule Date Due',
      width: 250,
      type: ['dateColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'paymentScheduleAmount',
      headerName: 'Payment Schedule Amount',
      type: ['currencyColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'paymentScheduleReceivedDate',
      headerName: 'Payment Schedule Received Date',
      width: 250,
      type: ['dateColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'finalTotals',
      headerName: 'Final Totals',
      type: ['currencyColumn'],
      sortable: true,
    },
    {
      ...defaultProps,
      field: 'confirmed',
      headerName: 'Deal Date Confirmed',
      type: ['dateColumn'],
      sortable: true,
    },
  ];

  return (
    <div className="ag-theme-alpine h-full">
      <GridTable
        pageId="algolia-server-side"
        ref={gridApiRef}
        getRowId={getRowId}
        defaultColDef={defaultColDef}
        columnDefs={ColumnDefinitions}
        rowSelection={'multiple'}
        onGridReady={onGridReady}
        columnTypes={columnTypes}
        gridOptions={gridOptions}
      />
    </div>
  );
};

export default AlgoliaServerSide;
