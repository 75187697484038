import clsx from 'clsx';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export interface Venue {
  name: string;
  address?: {
    city?: string;
    state?: string;
  };
}

export type VenueDetailsProps = {
  venue: Venue;
  showOnMobile: boolean;
};

export const VenueDetails: FC<VenueDetailsProps> = ({ venue, showOnMobile }) => (
  <div
    className={twMerge(
      clsx('text-sm md:text-lg w-60 md:w-auto truncate md:text-clip', { 'hidden md:flex': !showOnMobile })
    )}
  >
    {venue?.name || ''} {venue?.address?.city ? `- ${venue?.address?.city}` : ''}
    {venue?.address?.state ? `, ${venue?.address?.state}` : ''}
  </div>
);
