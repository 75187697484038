import { handleUpdateDealShowsResponse, iUpdateShowPayload, updateDealShows } from 'api/shows';
import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import {
  SCHEDULE_EMPTY_FORM,
  SCHEDULE_SHOW_EMPTY_ITEM,
  SCHEDULE_SHOW_EVENT_EMPTY_ITEM,
  SCHEDULE_TYPES,
} from 'deal-form/data/organisms';
import { DropdownWithStaticSearch } from 'deal-form/form-controls/DropdownWithStaticSearch';
import { Input } from 'deal-form/form-controls/Input';
import { formatDate } from 'deal-form/helpers/dealHelpers';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismSchedule, iOrganismScheduleEvent, iOrganismScheduleShow } from 'deal-form/interfaces/organisms';
import React, { useEffect, useState } from 'react';
import { Schedule, Show } from 'types/Show';

import { Dropdown } from '../../form-controls/Dropdown';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Label } from '../../form-controls/Label';
import { TextArea } from '../../form-controls/TextArea';
import { getMaxCharMessage } from '../../helpers/formHelpers';
import submitToNetsuite from 'utils/submitToNetsuite';
import { FieldValues, UseFormGetValues, UseFormReset } from 'react-hook-form';

export const COLUMN_LABELS = [
  { value: 'Show', isRequired: true },
  { value: 'Show Start Time', isRequired: false },
  { value: 'Show End Time', isRequired: false },
  { value: 'Set Length (Minutes)' },
];
export const SUB_COLUMN_LABELS = [
  { value: 'Type', isRequired: true },
  { value: 'Time', isRequired: false },
  { value: 'Comments' },
];
export const GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_1fr_2fr] gap-2 ';
export const SUB_GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_3fr] gap-2 ';

const ScheduleInputForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked, contract }) => {
  const [scheduleData, setScheduleData] = useState<iOrganismSchedule | null>(null);
  const [availableShowOptions, setAvailableShowOptions] = useState<iOptions[]>([]);
  const [canSubmit, setCanSubmit] = useState(true);
  const [customTypeOptions, setCustomTypeOptions] = useState<iOptions[]>([]);

  useEffect(() => {
    const { shows } = deal;

    // are there shows?
    if (shows && shows.length) {
      // create a new show list
      const scheduleShows: iOrganismScheduleShow[] = [];

      // run through the shows
      shows.forEach((show: Show) => {
        // get the schedule from the show
        const { schedule } = show;

        if (schedule) {
          // try to make it work with the utour dates
          const updatedEvents: iOrganismScheduleEvent[] =
            schedule.events && schedule.events.length > 0
              ? schedule.events.map((event) => {
                  if (event.time && !event.timeString)
                    return {
                      ...event,
                      timeString: `${new Date(event.time).getUTCHours()}:${new Date(event.time).getUTCMinutes()}`,
                    };
                  return { ...event };
                })
              : [];

          // create a new schedule
          const newSchedule: iOrganismScheduleShow = {
            show: `${show._id}`,
            notes: schedule.notes,
            events: updatedEvents,
            setLength: schedule.setLength || 0,
            showTime: schedule.showTime || '',
            showEndTime: schedule.showEndTime || '',
          };
          // add the schedule to the list
          scheduleShows.unshift(newSchedule);
        }
      });

      setScheduleData({ shows: scheduleShows });

      const opts: iOptions[] = [];
      shows.forEach((show) => {
        const option: iOptions = {
          id: show._id,
          label: `${formatDate(show.date)} - ${show.earlyLate === 'Neither' ? 'Only Show' : show.earlyLate}`,
          disabled: show.status === 'Settled',
        };
        opts.push(option);
      });
      setAvailableShowOptions(opts);
    } else {
      setScheduleData(SCHEDULE_EMPTY_FORM);
    }
  }, [contract, deal]);

  const doShowUpdate = async (updates: iUpdateShowPayload[]) => {
    try {
      setCanSubmit(false);
      const response = await updateDealShows({
        dealId: deal._id,
        updates,
      });

      if (setDeal) handleUpdateDealShowsResponse(response, { deal, setDeal });
      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR UPDATING SCHEDULE', e);
      setCanSubmit(true);
    }
  };

  const onSubmit = async (data: iOrganismSchedule) => {
    // not sure why i need a last item
    const lastItem = data.shows[data.shows.length - 1];

    if (lastItem.show !== '') {
      const updates: iUpdateShowPayload[] = [];
      // loop through the shows
      deal.shows.forEach((show) => {
        // find the associated schedule in the data
        const newData = data.shows.find((s) => s.show === show._id);
        let scheduleBody: Schedule;
        if (newData) {
          scheduleBody = {
            showTime: newData.showTime,
            showEndTime: newData.showEndTime,
            setLength: newData.setLength,
            notes: newData.notes || '',
            events: newData.events,
          };

          const showUpdate: iUpdateShowPayload = {
            showId: show._id,
            updateBody: {
              schedule: scheduleBody,
            },
          };
          updates.push(showUpdate);
        }
      });

      const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';

      if (updates.length > 0) {
        await doShowUpdate(updates);
        if (isDealSubmittedToNetsuite) {
          submitToNetsuite(contract, deal, false);
        }
      }
    }
  };

  const handleAddCustomOption = (newOption: string) => {
    setCustomTypeOptions([...customTypeOptions, { id: newOption, label: newOption }]);
  };

  const handleRemoveOverride = async (
    _index: number,
    reset: UseFormReset<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>
  ) => {
    setTimeout(() => {
      const vals: iOrganismSchedule = getValues?.() as iOrganismSchedule;
      reset(vals, { keepDirty: true, keepIsValid: true });
    });
  };

  return (
    scheduleData && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className={`form-row full-width ${canSubmit ? '' : 'opacity-50'}`}
        defaultValues={scheduleData}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.SCHEDULE}
      >
        <FieldArray
          gridClassName={GRID_LAYOUT}
          groupName="shows"
          columns={COLUMN_LABELS}
          addButtonLabel="Add Schedule"
          emptyRow={SCHEDULE_SHOW_EMPTY_ITEM}
          limit={deal.shows.length}
          disabled={!isOpen || locked}
          columnLabelsType="repeats"
          lockRules={(field) => {
            const rule = {
              isLocked: false,
              fields: [] as string[],
            };
            const fieldShowId = field.show;
            const show = deal.shows.find((s) => s._id === fieldShowId);
            // if a show status is Settled it should not be updated any more
            if (show && show?.status === 'Settled') {
              rule.isLocked = true;
              rule.fields = ['show', 'showTime', 'showEndTime', 'setLength', 'notes', 'nestedFieldArray'];
            }

            if (field.isMasterShow) {
              rule.isLocked = true;
            }

            return rule;
          }}
          showAll
        >
          <Dropdown
            disabled={!isOpen || locked}
            placeholder="Show"
            id="show"
            options={availableShowOptions}
            rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
          />
          <Input
            placeholder="Show Start Time"
            fieldType="time"
            id="showTime"
            // required
            disabled={!isOpen || locked}
            required={false}
            // rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
          />
          <Input placeholder="Show End Time" fieldType="time" id="showEndTime" disabled={!isOpen || locked} />
          <Input
            placeholder="Set Length"
            fieldType="text"
            id="setLength"
            disabled={!isOpen || locked}
            maxLength={64}
            // rules={{
            // max: { value: 9999, message: 'Please enter a lower number' },
            // validate: (value) => isWholeNumber(value) || ERROR_MESSAGES.WHOLE_NUMBER,
            // }}
          />

          <FieldArray
            gridClassName={SUB_GRID_LAYOUT}
            className="col-span-4 ml-8 mr-4"
            groupName="shows.{index}.events"
            isNested
            id="nestedFieldArray"
            columns={SUB_COLUMN_LABELS}
            addButtonLabel="Add Schedule Item"
            emptyRow={SCHEDULE_SHOW_EVENT_EMPTY_ITEM}
            limit={SCHEDULE_TYPES.length}
            disabled={!isOpen || locked || !canSubmit}
            overrideRemove={handleRemoveOverride}
            forceRemove={true}
            lockRules={(field) => {
              const rule = {
                isLocked: false,
                fields: [] as string[],
              };

              const fieldShowEventId = field._id;

              const showEvent = deal.shows.find((s) => s.schedule?.events.find((e) => e._id === fieldShowEventId));

              // // if a show status is Settled it should not be updated any more
              if (showEvent && showEvent?.status === 'Settled') {
                rule.isLocked = true;
                rule.fields = ['type', 'timeString', 'comments'];
              }

              if (field.isMasterShow) {
                rule.isLocked = true;
              }

              return rule;
            }}
          >
            <DropdownWithStaticSearch
              label={false}
              placeholder="Type"
              id="type"
              addCustomOption={handleAddCustomOption}
              customOptions={customTypeOptions}
              staticOptions={SCHEDULE_TYPES}
              disabled={!isOpen || locked}
              maxLength={100}
              rules={{
                required: 'Please select a valid ‘Type’ option',
              }}
              customOptionLabel="Create Custom Schedule Item"
              rulesCallback={() => ({
                required: ERROR_MESSAGES.REQUIRED_FIELDS,
                validate: (_, values) => {
                  for (let i = 0; i < values.shows.length; i++) {
                    const usedValues = new Set();
                    for (let j = 0; j < values.shows[i].events.length; j++) {
                      const eventType = values?.shows?.[i]?.events?.[j]?.type;
                      if (eventType && usedValues.has(eventType)) {
                        return ERROR_MESSAGES.DUPLICATE_EVENT_TYPE;
                      } else if (eventType) {
                        usedValues.add(eventType);
                      }
                    }
                  }

                  return true;
                },
              })}
            />
            <Input placeholder="Time" fieldType="time" id="timeString" disabled={!isOpen || locked} />
            <TextArea
              placeholder="Type comments here."
              rules={{
                maxLength: {
                  value: 200,
                  message: getMaxCharMessage('the Comments Notes', 200),
                },
              }}
              id="comments"
              rows={1}
              disabled={!isOpen || locked}
            />
          </FieldArray>

          <Label htmlFor="notes">Notes</Label>
          <TextArea
            className="col-span-4"
            placeholder="Type schedule here."
            rules={{
              maxLength: {
                value: 200,
                message: getMaxCharMessage('the Schedule Notes', 200),
              },
            }}
            id="notes"
            rows={2}
            disabled={!isOpen || locked}
          />
        </FieldArray>
      </Form>
    )
  );
};

export default ScheduleInputForm;
