import { ColDef } from 'ag-grid-enterprise';
import { staticCellStyle } from 'grid-pages/grid-styles';

export const searchPageDefaultCols = {
  minWidth: 75,
  resizable: true,
  lockPinned: true,
  floatingFilter: true,
  cellStyle: staticCellStyle,
  unSortIcon: true,
  wrapText: true,
  autoHeight: true,
};

const columnDefinitions: Array<ColDef> = [
  {
    field: 'date',
    headerName: 'Date',
    type: ['dateColumn'],
  },
  {
    field: 'client',
    headerName: 'Client',
    type: ['nameColumn'],
  },
  {
    field: 'city',
    headerName: 'City',
    type: ['textColumn'],
  },
  { field: 'venue', headerName: 'Venue', type: ['textColumn'] },
  {
    field: 'guarantee',
    headerName: 'Guarantee',
    type: ['currencyColumn'],
  },
  {
    field: 'ticketsSold',
    headerName: 'Total Tickets Sold',
    type: ['numberColumn'],
  },
  {
    field: 'sellable',
    headerName: 'Total Sellable Capacity',
    type: ['numberColumn'],
  },
  {
    field: 'percentSold',
    headerName: 'Total Percent Sold',
    type: ['percentColumn'],
  },
  {
    field: 'gbor',
    headerName: 'GBOR',
    type: ['currencyColumn'],
  },
  {
    field: 'nbor',
    headerName: 'NBOR',
    type: ['currencyColumn'],
  },
  {
    field: 'dealId',
    headerName: 'Deal ID',
    type: ['dealColumn'],
  },
  {
    field: 'dealType',
    headerName: 'Deal Type',
    type: ['dealTypeColumn'],
  },
];

export const getConfigFromIds = (ids: string[]) => {
  const filtered = columnDefinitions.filter((col) => col.field && ids.includes(col.field));
  return filtered;
};

export const getConfigFromData = (data: any[]) => {
  const parsedCols: Array<ColDef> = data.map((col: any) => {
    let valueAndFilterGetters: Record<string, unknown> = {};

    const path = col.dataType === 'agents' && col.path.includes('name') ? col.path.split('.')[0] : col.path;
    const pathObj = path ? path.split('.') : null;

    const isNested = !col.renderer && pathObj && pathObj.length > 1;
    let filterParams = col.filterParams || null;
    filterParams = { ...filterParams };

    if (isNested) {
      valueAndFilterGetters = {
        valueGetter: (params: any) => {
          // for getting nested values, like venue.name etc. Only supports one level for now.
          return params.data[pathObj[0]]?.[pathObj[1]] || '';
        },
        filterValueGetter: (params: any) => {
          // for getting nested values, like venue.name etc. Only supports one level for now.
          return params.data[pathObj[0]]?.[pathObj[1]] || '';
        },
      };
    }

    let type: string | string[] = [];

    if (isNested) {
      type = 'nestedColumn';
    } else if (col.renderer || col.dataType) {
      type = [`${col.renderer || col.dataType}Column`];
    }

    if (col.name === 'dealType') {
      type = ['dealTypeColumn'];
    }

    return {
      colId: col.name,
      field: path,
      path,
      headerName: col.columnTitle,
      // filter: col.columnTitle !== 'Date', // make the date invisible, since it is exposed elsewhere
      type,
      ...valueAndFilterGetters,
      filterParams,
    };
  });

  return parsedCols;
};
