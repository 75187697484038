import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Spinner } from '@united-talent-agency/components';
import PatternBackground from 'features/layouts/pattern-bg';
import { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { EntityDesignateType, EntityType } from 'types/data-schemas';
import { getEntityProfileUrl } from 'urls';

import Button from 'components/Button';
import ArtistCard from 'components/client-card';
import SearchBar from 'components/search-bar';

import { Switch } from '@united-talent-agency/onyx-components';

// import CypressTags from '../../support/cypressTags';
import { RoutingClient, getClientsRoutingInfo } from '../../../api/clients-routing';
import { ModalShareBackstage } from '../../../components/ModalShareBackstage';
import cypressTags from 'support/cypressTags';

// const LINK_TEXT = `mb-0 text-center text-curiousBlue cursor-pointer no-underline hover:underline visited:no-underline`;
const LIMIT_COUNT = 80;

export const RoutingContainer = (props: { currentDeskId: string }): ReactElement => {
  const [clients, setClients] = useState<RoutingClient[]>([{ _id: '', name: '' }]);
  const clientsFound = clients.find((client) => !!client?._id);
  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentCount, setCurrentCount] = useState<number>(LIMIT_COUNT);
  const [showAll, setShowAll] = useState(false);
  const [showFormer, setShowFormer] = useState(false);

  const retrieveAndSetClients = (searchBy = '') => {
    setLoading(true);
    getClientsRoutingInfo({
      search: searchBy,
      desk: props.currentDeskId,
      skip: currentPage > 0 ? (currentPage - 1) * LIMIT_COUNT : 0,
      limit: LIMIT_COUNT,
      showAll: showAll,
      showFormer: showFormer,
    }).then((res: { count: number; data: RoutingClient[] }) => {
      setClients(res.data);
      setCurrentCount(res.count || res.data.length);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (searchString.length >= 3 || (!searchString.length && props.currentDeskId)) {
      retrieveAndSetClients(searchString);
    }
  }, [searchString, props.currentDeskId, currentPage, showAll, showFormer]);

  const [showShareModal, setShowShareModal] = useState(false);

  const [stateShareClient, setStateShareClient] = useState<RoutingClient | null>(null);
  const handleShowModal = (val: boolean) => {
    setShowShareModal(val);
  };
  return (
    <PatternBackground>
      <Helmet>
        <title>Touring | Roster</title>
      </Helmet>
      <div className={`min-w-800 content-wrapper flex flex-col pt-28 md:pt-[160px]`}>
        <div className="w-full mb-4 flex-col md:flex-row flex justify-between px-2 lg:px-0">
          <h1 className="text-[30px] font-medium md:font-extended uppercase text-white mb-8 md:mb-0">Roster</h1>

          <div className="border border-white flex p-[2px]">
            <button
              onClick={() => {
                setShowAll(false);
                setCurrentPage(1);
              }}
              className={`py-2 px-4 w-1/2 whitespace-nowrap ${
                !showAll ? 'bg-white text-black mix-blend-screen font-medium' : 'text-white'
              }`}
              data-cy={cypressTags.ROSTER.MY_CLIENTS_BUTTON}
            >
              My Clients
            </button>
            <button
              onClick={() => {
                setShowAll(true);
                setCurrentPage(1);
              }}
              className={`py-2 px-4 w-1/2 whitespace-nowrap ${
                showAll ? 'bg-white text-black mix-blend-screen font-medium' : 'text-white'
              }`}
              data-cy={cypressTags.ROSTER.ALL_CLIENTS_BUTTON}
            >
              All Clients
            </button>
          </div>
        </div>
        <div className="mb-4 flex items-center gap-4 justify-end">
          <Switch
            checked={showFormer}
            disabled={loading}
            onChange={(state) => setShowFormer(Boolean(state))}
            size="lg"
          />
          <span className="text-medium font-normal text-white">Show Former Clients</span>
        </div>
        <div className="mb-3 px-2 lg:px-0">
          <SearchBar
            value={searchString}
            onChange={(value) => {
              setCurrentPage(0);
              setSearchString(value);
            }}
            onClear={() => {
              setCurrentPage(0);
              setSearchString('');
              retrieveAndSetClients();
            }}
          />
        </div>

        <div
          className={`${
            currentCount >= LIMIT_COUNT ? 'h-[calc(100vh-400px)] mb-4' : 'h-[calc(100vh-318px)]'
          } overflow-y-auto px-2 lg:px-0 lg:my-5 no-scrollbar`}
        >
          {loading && (
            <div className="flex justify-center h-full items-center">
              <Spinner size={40} />
              <div className="flex items-center my-0 mx-4 text-white">Retrieving your clients ...</div>
            </div>
          )}
          <ul className="px-2 lg:px-0 grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-8 items-start list-none">
            {clientsFound &&
              !loading &&
              clients?.map((client, idx) => {
                const profileUrl = getEntityProfileUrl(EntityDesignateType.Person, client._id, EntityType.Client);

                return (
                  <li
                    key={`gallery-item-${idx}`}
                    className="w-full flex flex-col justify-center mb-5 mx-0"
                    data-cy={cypressTags.ROSTER.CLIENT_CARD_LIST_ITEM}
                  >
                    <div className="relative cursor-pointer">
                      <ArtistCard
                        name={client.name}
                        imgUrl={client.profile_pic}
                        link={`/routing/${client._id}/${client.name}`}
                        onShareClick={() => {
                          handleShowModal(true);
                          setStateShareClient(client);
                        }}
                      />
                    </div>
                    <div className="flex justify-between">
                      <a
                        className={`uppercase text-base text-viridian cursor-pointer`}
                        href={profileUrl}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                      >
                        Profile
                      </a>
                    </div>
                  </li>
                );
              })}
            {!clientsFound && !loading && <p className={`font-normal text-[24px] text-white mt-10`}>No results</p>}
          </ul>
        </div>
        {!loading && (clients.length >= LIMIT_COUNT || currentPage > 0) && (
          <div className="flex self-center gap-2">
            <Button
              onClick={() => {
                currentPage > 1 && setCurrentPage(currentPage - 1);
              }}
              variant="primary"
              leftIcon={<ChevronLeftIcon width={24} height={24} />}
              disabled={currentPage <= 1}
              dataCy={cypressTags.ROSTER.PREVIOUS_PAGE_BUTTON}
            >
              Prev
            </Button>

            <Button
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
              variant="primary"
              rightIcon={<ChevronRightIcon width={24} height={24} />}
              disabled={currentCount < LIMIT_COUNT}
              dataCy={cypressTags.ROSTER.NEXT_PAGE_BUTTON}
            >
              Next
            </Button>
          </div>
        )}
      </div>

      <ModalShareBackstage show={showShareModal} setShow={setShowShareModal} client={stateShareClient} />
    </PatternBackground>
  );
};
