import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { SubNav, SubNavItem } from './SubNav';

interface iNavItem {
  route: string;
  label: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  subMenu?: SubNavItem[];
}

export const NavItem = ({ route, label, subMenu, setIsOpen }: iNavItem): ReactElement => {
  const [showSubNav, setShowSubNav] = useState(false);

  const getLinkStyles = (isActive: boolean): string => {
    return `py-3 text-2xl no-underline text-black ${isActive ? 'font-bold' : 'font-normal'}`;
  };

  return (
    <>
      <div
        className={`relative ${subMenu ? 'hidden md:block' : ''}`}
        onMouseEnter={() => subMenu && setShowSubNav(true)}
        onMouseLeave={() => subMenu && setShowSubNav(false)}
      >
        <NavLink key={`nav-item-${label}`} to={route} onClick={() => setIsOpen && setIsOpen(false)}>
          {({ isActive }) => (
            <div
              className={`w-full text-start md:text-center text-2xl md:text-nav leading-4 no-underline text-black md:text-white
            ${isActive ? 'opacity-100 font-bold' : 'opacity-70'} 
            hover:opacity-100 relative z-10
            `}
            >
              <span className="flex">
                {label}
                {subMenu && <ChevronDownIcon color="white" width={12} className="ml-2" />}
              </span>
            </div>
          )}
        </NavLink>
        {subMenu && (
          <div className={`${showSubNav ? 'animate-[fade-in_300ms_ease-in-out_both]' : ''}`}>
            <SubNav items={subMenu} doOpen={showSubNav} />
          </div>
        )}
      </div>
      {subMenu && (
        <div className="mt-4 md:hidden">
          {subMenu.map((item: SubNavItem, index) => (
            <NavLink key={`${index}-${item.path}`} end to={item.path} onClick={() => setIsOpen && setIsOpen(false)}>
              {({ isActive }) => (
                <div className={getLinkStyles(isActive)}>
                  <span>{item.label}</span>
                </div>
              )}
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};
