// import { SearchBar } from '@united-talent-agency/components';
import { AgGridReact } from 'ag-grid-react';
import ContactsTable from 'contacts/organisms/Table';
import { StickyHeaderPage } from 'features/layouts';
import { PageHeader } from 'grid-pages/components';
import useOverscrollNavigationLock from 'hooks/lockPageOverscrollNavigation';
import { ReactElement, useCallback, useRef, useState } from 'react';

/**
 * Contact redirect Editor
 */
export const Contacts = (): ReactElement => {
  // Hooks
  const [lockOverscrollNavigation, unlockOverscrollNavigation] = useOverscrollNavigationLock();

  // States
  const [isExporting, setIsExporting] = useState<boolean>(false);
  // const [searchString, setSearchString] = useState('');

  const gridApiRef = useRef<AgGridReact>(null);

  const handleOnExport = () => {
    setIsExporting(true);
  };

  const renderFixedContainer = useCallback(() => <PageHeader onExport={handleOnExport} title="Contacts" />, []);

  return (
    <StickyHeaderPage renderFixedContainer={renderFixedContainer}>
      <div className="main-wrapper">
        <div
          className="grid-height content-wrapper md:pt-10"
          onMouseOver={lockOverscrollNavigation}
          onMouseLeave={unlockOverscrollNavigation}
        >
          {/* <div className="m-3">
          <SearchBar
            value={searchString}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setSearchString(target.value)}
            onClear={() => {
              setSearchString('');
            }}
          />
        </div> */}
          <ContactsTable
            gridApiRef={gridApiRef}
            // searchString={searchString}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
          />
        </div>
      </div>
    </StickyHeaderPage>
  );
};
