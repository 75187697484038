import { PlusIcon } from '@heroicons/react/20/solid';
import { ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  text: string;
  dataCy?: string;
  disabled?: boolean;
}

export const AddButton = ({ text, onClick, dataCy = '', disabled = false, ...props }: Props) => (
  <div className="items-center">
    <button
      className="py-4"
      data-cy={dataCy}
      data-testid={dataCy}
      type="button"
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <div
        className={`flex items-center space-x-1 text-sm font-extended hover:underline ${
          disabled ? 'text-greyCloudy' : 'text-black'
        }`}
      >
        <PlusIcon color={disabled ? 'grey' : '#black'} width={20} />
        <div>{text}</div>
      </div>
    </button>
  </div>
);
