import { TICKETING_CURRENCIES } from 'deal-form/data/constants';
import { formatCurrency } from 'deal-form/helpers/dealHelpers';
import { InputHTMLAttributes } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { Contact } from 'types/data-schemas';

import { findNestedErrorById } from '../../helpers/formHelpers';

interface iTextField extends InputHTMLAttributes<HTMLParagraphElement> {
  id: string;
  label?: string | boolean;
  className?: string;
  containerClassName?: string;
  text: string;
  index?: number;
  isCurrency?: boolean;
  currencySymbolOnly?: boolean;
  rules?: RegisterOptions;
  dataCy?: string;
}

export const TextField = ({
  id,
  containerClassName = '',
  className = '',
  text = '',
  label = false,
  isCurrency = false,
  currencySymbolOnly = false,
  rules = {},
  dataCy,
  ...props
}: iTextField) => {
  const {
    watch,
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  watch(id);

  const hasErrors = errors && !!findNestedErrorById(id, errors);

  let copy = text;
  // TODO : is there a better way to handle exceptions?
  if (id.includes('buyers') && id.includes('contacts')) {
    const contacts = getValues(id);
    copy =
      contacts !== undefined
        ? contacts.map((contact: Contact) => `${contact.contactType}: ${contact.contact}`).join('\n')
        : '';
  } else if (id.includes('buyers') && id.includes('address')) {
    const address = getValues(id);
    copy =
      address !== undefined && address.length > 0
        ? `${address[0].address}\n${address[0].city}, ${address[0].state} ${address[0].zip}`
        : '';
  } else {
    if (typeof getValues(id) === 'string') copy = getValues(id);
    if (typeof getValues(id) === 'number') copy = String(getValues(id));
    if (isCurrency) {
      const val = getValues(id);
      copy =
        val?.value !== -1
          ? `${TICKETING_CURRENCIES.find((cur) => cur?.id === val?.currency)?.symbol || ''}${formatCurrency(
              Number(val?.value)
            )} ${!currencySymbolOnly ? val?.currency : ''}`
          : '--';
    }
  }

  return (
    <div data-cy={dataCy} className={`${containerClassName !== '' ? containerClassName : 'form-field-wrapper'}`}>
      {label !== false && <label className="standard-label">{label}</label>}

      <p
        {...register(id, rules)}
        className={`w-full whitespace-pre-line min-h-[2.5rem] text-sm ${hasErrors ? 'text-cinnabar' : ''} ${className}`}
        {...props}
      >
        {copy !== '' ? copy : '--'}
      </p>
    </div>
  );
};
