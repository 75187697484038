import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact as AgGrid } from 'ag-grid-react';
import { algoliaClient, searchClient } from 'api/search-client';
import { NameRenderer } from 'grid-pages/components';
import { searchPageDefaultCols } from 'grid-pages/utils/configs';
import { ISearchParams } from 'pages/venue';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { venueUrlForId } from 'urls';

import { doCsvExportFromGrid } from 'grid-pages/utils/export-helpers';
import GridTable from 'grid-pages/organisms/GridTable';

interface venueRow {
  id: string | number;
  _highlightResult?: object;
  objectID?: string | number;
}

interface VenueGridProps {
  isExporting?: boolean;
  searchParams: ISearchParams;
  gridApiRef: React.RefObject<AgGrid> | null;
  setIsExporting?: (val: boolean) => void;
}

const VenueTable: FC<VenueGridProps> = ({ gridApiRef, isExporting, searchParams, setIsExporting }) => {
  const [currentGridData, setCurrentGridData] = useState<venueRow[] | []>([]);
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalHits, setTotalHits] = useState<number>(0);

  const index = process.env.REACT_APP_ALGOLIA_VENUE_INDEX || '';

  const defaultColDef = useMemo(() => searchPageDefaultCols, []);

  useEffect(() => {
    setShowGrid(false);
    setLoading(true);

    if (searchParams.name !== '' || searchParams.cities.length > 0) {
      const citiesFacets = searchParams.cities.map((c) => `address.city:${c}`);
      const venueTypeFacets = searchParams.venueTypes?.map((c) => `venueType:${c}`);
      const { minCapacity, maxCapacity } = searchParams;

      let capcityFilter = '';
      if (minCapacity && maxCapacity) capcityFilter = `capacity:${Number(minCapacity)} TO ${Number(maxCapacity)}`;
      else if (minCapacity && !maxCapacity) capcityFilter = `capacity > ${Number(minCapacity)}`;
      else if (!minCapacity && maxCapacity) capcityFilter = `capacity < ${Number(maxCapacity)}`;

      const queryParams: any = {
        hitsPerPage: 1000,
        filters: capcityFilter,
        facetFilters: [citiesFacets, venueTypeFacets],
      };

      if (index) {
        let locations: any[] = [];
        if (searchParams.locations && searchParams.locations.length > 0 && searchParams.radius) {
          let radiusInMeters = searchParams.radius;
          if (searchParams.radiusUnit === 'mi') {
            radiusInMeters *= 1609.34; // 1 mile = 1609.34 meters
          } else {
            radiusInMeters *= 1000; // 1 kilometer = 1000 meters km by default
          }
          locations = searchParams.locations.map((loc) => {
            return {
              aroundRadius: parseInt(radiusInMeters.toFixed(0)),
              aroundLatLng: `${parseFloat(loc.latitude).toFixed(2)},${parseFloat(loc.longitude).toFixed(2)}`,
            };
          });
        }

        doAlgoliaSearch(
          index,
          searchParams.name,
          queryParams,
          locations,
          (nbHits: number) => {
            setShowGrid(true);
            setLoading(false);
            setTotalHits(nbHits);
          },
          () => {
            console.log('Error ');
          }
        );
      }
    } else {
      setLoading(false);
    }
  }, [searchParams]);

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.objectID;
    };
  }, []);

  const getParsedHits = (hits: any[]) => {
    const parsedHits = hits.map((hit: any) => {
      return {
        ...hit,
        venueName: {
          name: hit.name,
          _id: hit.objectID,
          url: venueUrlForId(hit.objectID),
        },
      };
    });
    return parsedHits;
  };

  const doAlgoliaSearch = async (
    indexToUse: string,
    searchQuery: string,
    queryParams: any,
    locations?: any[],
    onSuccess?: any,
    onError?: any
  ) => {
    try {
      const searchIndex = algoliaClient.initIndex(indexToUse);
      const results = await searchClient(searchIndex, searchQuery, queryParams);
      const { hits, nbHits } = results;

      if (hits) {
        if (locations && locations?.length > 0) {
          const promises = locations.map((loc) => {
            return searchClient(searchIndex, searchQuery, {
              filters: queryParams.filters,
              facetFilters: queryParams.facetFilters.slice(1), // ignore the cities facet filter
              ...loc,
            });
          });

          const locationResults = await Promise.all(promises);

          const locationHits = locationResults.map((res) => res.hits).flat();

          const mergedHits = hits.concat(locationHits);
          const mergedHitsMap: any = {};
          mergedHits.forEach((hit: any) => {
            mergedHitsMap[hit.objectID] = hit;
          });
          const mergedHitsArray: any = Object.values(mergedHitsMap);
          setCurrentGridData(getParsedHits(mergedHitsArray));
          if (onSuccess) onSuccess(mergedHitsArray.length);
        } else {
          setCurrentGridData(getParsedHits(hits));
          if (onSuccess) onSuccess(nbHits);
        }
      }
    } catch (error) {
      if (onError) onError();
    }
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  }, []);

  const gridOptions: any = {
    rowModelType: 'clientSide',
    rowHeight: 60,
    pagination: true,
    paginationPageSize: 50, //how many rows to display per page, default 100
  };

  const [columnDefs] = useState([
    {
      maxWidth: 44,
      field: 'check',
      checkboxSelection: true,
      headerClass: 'select-all',
      headerCheckboxSelection: true,
      cellClass: 'select-check-box',
      cellStyle: {
        padding: '8px 10px',
      },
    },
    {
      sortable: true,
      field: 'venueName',
      headerName: 'Name',
      cellRenderer: NameRenderer,
      valueFormatter: (params: any) => {
        const colId = params.column.colId;
        return params.data[colId];
      },
      valueGetter: (params: any) => {
        const colId = params.column.colId;
        return params.data[colId]?.name;
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      valueGetter: (params: any) => {
        return params.data.address?.address || '';
      },
    },
    {
      field: 'city',
      headerName: 'City',
      valueGetter: (params: any) => {
        return params.data.address?.city || '';
      },
    },
    {
      field: 'state',
      headerName: 'State',
      valueGetter: (params: any) => {
        return params.data.address?.state || '';
      },
    },
    {
      field: 'country',
      headerName: 'Country',
      valueGetter: (params: any) => {
        return params.data.address?.country || '';
      },
    },
    {
      enableValue: true,
      field: 'venueType',
      headerName: 'Venue Type',
    },
    {
      sortable: true,
      field: 'capacity',
      headerName: 'Capacity',
    },
  ]);

  useEffect(() => {
    if (gridApiRef && gridApiRef.current && isExporting) {
      const numSelected = gridApiRef.current.api.getSelectedRows();
      const columns = gridApiRef.current.columnApi.getAllDisplayedColumns();
      if (columns) {
        const filteredCols = columns.filter((c) => c.getId() !== 'check').map((c) => c.getColId() || '');
        doCsvExportFromGrid(gridApiRef.current.api, 'venues', filteredCols, numSelected.length > 0 ? true : false);
      }
    }
    if (setIsExporting) setIsExporting(false);
  }, [isExporting]);

  return showGrid ? (
    <>
      <div>
        {totalHits > 1000 && (
          <p className="mb-4 text-sm">Displaying 1000 of {totalHits}. Add more search parameters to refine results.</p>
        )}
      </div>
      <GridTable
        ref={gridApiRef}
        pageId="venue"
        rowSelection="multiple"
        rowData={currentGridData}
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        defaultColDef={{ ...defaultColDef, sortable: false }}
        getRowId={getRowId}
        onGridReady={onGridReady}
      />
    </>
  ) : loading ? (
    <div>Loading...</div>
  ) : (
    <div>Refine by Venue Name, City, State or Country to see results.</div>
  );
};

export default VenueTable;
