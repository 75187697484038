import { ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { SubNavItem } from './SubNav';

interface iNavItem {
  route: string;
  label: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  subMenu?: SubNavItem[];
}

export const MobileNavItem = ({ route, label, subMenu, setIsOpen }: iNavItem): ReactElement => {
  const [showSubNav, setShowSubNav] = useState(false);
  const currentPath = window.location.pathname;

  return (
    <>
      <div
        className={`relative min-w-full ${subMenu ? 'hidden md:block' : ''}`}
        onMouseEnter={() => subMenu && setShowSubNav(true)}
        onMouseLeave={() => subMenu && setShowSubNav(false)}
      >
        <NavLink key={`nav-item-${label}`} to={route} onClick={() => setIsOpen && setIsOpen(false)}>
          <div
            className={`w-full text-start md:text-center text-lg md:text-nav leading-4 no-underline text-black font-medium `}
          >
            <span className="flex pl-8 mb-6">{label}</span>
            <div className="w-full bg-greyQuill h-px mt-8"></div>
          </div>
        </NavLink>
      </div>
      {subMenu && (
        <div className="w-full">
          <div className="w-full">
            <div
              className={`w-full text-start md:text-center text-lg md:text-nav leading-4 no-underline text-black font-medium flex`}
            >
              <div
                className="flex pl-8 "
                onClick={() => {
                  setShowSubNav(showSubNav);
                }}
              >
                {label}
              </div>
              <div className="w-full flex justify-end pr-8">
                {subMenu && (
                  <ChevronDownIcon
                    color="black"
                    width={17}
                    strokeWidth={'3'}
                    className={`mb-6 ` + `transition-all duration-200 ${showSubNav ? 'rotate-180' : ''}`}
                    onClick={() => setShowSubNav(!showSubNav)}
                  />
                )}
              </div>
            </div>
            {showSubNav &&
              subMenu.map((item: SubNavItem, index) => (
                <div
                  key={`nav-item-${label + index}`}
                  className={`w-full pr-8 ${currentPath === item.path ? 'bg-greyQuill' : ''}`}
                >
                  <div className="py-5 w-full pl-5">
                    <NavLink to={item.path} onClick={() => setIsOpen && setIsOpen(false)}>
                      <div
                        className={`w-full flex  justify-between text-start md:text-center md:text-nav leading-4 no-underline text-black font-normal text-md`}
                      >
                        {' '}
                        <span
                          className={`pl-8 leading-normal inline-block ${currentPath === item.path ? `font-bold` : ``}`}
                        >
                          {item.label}
                        </span>{' '}
                        {currentPath === item.path && (
                          <span className="leading-normal inline-block">
                            <CheckIcon color="black" strokeWidth={'3'} width={17} className="rotate-[15deg] stroke-3" />
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </div>
                  {index < subMenu.length - 1 && <div className="w-full bg-greyQuill h-px ml-4"></div>}
                </div>
              ))}
          </div>
          <div className="w-full bg-greyQuill h-px"></div>{' '}
        </div>
      )}
    </>
  );
};
