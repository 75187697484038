import { ReactNode } from 'react';

type PBProps = {
  children: ReactNode;
};
const PatternBackground = ({ children }: PBProps) => (
  <div className="bg-redBlack bg-cover bg-left md:bg-center w-full h-full min-h-screen">
    <div className="bg-linearGradient w-full min-h-screen h-full overflow-hidden">{children}</div>
  </div>
);

export default PatternBackground;
