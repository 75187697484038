import { showUserError, updateDeal, updateDealShows, updateContract } from 'api';
import { iUpdateShowPayload } from 'api/shows';
import { SEARCH_TYPE } from 'deal-form/constants';
import { FORM_NAMES } from 'deal-form/data/constants';
import {
  EMPTY_SHOW_LOCATION_FORM,
  SHOW_AGES,
  SHOW_TERRITORIES,
  CONTRACT_WIRE_ACCOUNTS,
} from 'deal-form/data/organisms';
import { DropdownWithSearch } from 'deal-form/form-controls/DropdownWithSearch';
import { DropdownWithStaticSearch } from 'deal-form/form-controls/DropdownWithStaticSearch';
import { formatAddress } from 'deal-form/helpers/dealHelpers';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismShowLocation } from 'deal-form/interfaces/organisms';
import React, { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Deal, FinanceInfo } from 'types';
import getWireTransferAccount from '../../../utils/wireAccountLogic';
import { Dropdown } from '../../form-controls/Dropdown';
import { Form } from '../../form-controls/Form';
import { Input } from '../../form-controls/Input';
import { Label } from '../../form-controls/Label';
import { TextField } from '../../form-controls/TextField';
import { getMasterShow } from '../../helpers/showHelpers';
import submitToNetsuite from 'utils/submitToNetsuite';
import cypressTags from 'support/cypressTags';

const ShowLocationForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked, contract, setContract }) => {
  const [showLocationFormData, setShowLocationFormData] = useState<iOrganismShowLocation | null>(null);
  const [address, setAddress] = useState<string>('');
  const [canSubmit, setCanSubmit] = useState(true);
  const [customAgeOptions, setCustomAgeOptions] = useState<iOptions[]>([]);

  const onSubmit = async (data: iOrganismShowLocation) => {
    const updateData: iUpdateShowPayload[] = [];

    // save commission rate to contract per deal/venue territory
    let commissionRate = '10';

    if (!deal.client.territoryInfo?.agencyCommissionRates) {
      commissionRate;
    } else if (deal.client.territoryInfo?.agencyCommissionRates?.length === 1) {
      commissionRate = deal.client.territoryInfo?.agencyCommissionRates[0]?.text!;
    } else if (deal.client.territoryInfo?.agencyCommissionRates?.length > 1) {
      commissionRate = deal.client.territoryInfo?.agencyCommissionRates?.find(
        (rate) => rate.territory === data.territory || rate.territory === 'All Territories'
      )?.text!;
    }

    // save finance info to contract per deal/venue territory
    if (deal.client?.territoryInfo?.touringAgentTerritories && contract) {
      if (deal.client.territoryInfo.touringAgentTerritories.length > 1) {
        const touringAgentTerritory = deal.client.territoryInfo.touringAgentTerritories.find(
          (territory) => territory.territory === data.territory
        );
        if (touringAgentTerritory) {
          const loc = touringAgentTerritory.location;
          const modifiedLocation = loc ? (loc.includes('Office') ? loc : `${loc} Office`) : undefined;
          const subsidiary = touringAgentTerritory.subsidiary;
          const modifiedSubsidiary = subsidiary
            ? subsidiary.includes('Scandinavia')
              ? 'UTA Scandinavia AB'
              : subsidiary.includes('UK')
              ? 'United Talent Agency UK Operations Limited'
              : 'United Talent Agency, LLC'
            : undefined;
          const wireTransferName = getWireTransferAccount(
            modifiedLocation || '',
            deal.currency,
            modifiedSubsidiary || '',
            touringAgentTerritory.department || '',
            touringAgentTerritory.lineOfBusiness || ''
          );

          const wireTransferId = CONTRACT_WIRE_ACCOUNTS.find((account) => account.label === wireTransferName)?.id;
          const utaWireTransfer = wireTransferId || 'No Results, Change Accounting Info';
          const updatedContract = await updateContract(contract._id, {
            ...contract,
            financeInfo: {
              territory: data.territory as FinanceInfo['territory'],
              location: modifiedLocation as FinanceInfo['location'],
              subsidiary: modifiedSubsidiary as FinanceInfo['subsidiary'],
              department: touringAgentTerritory.department as FinanceInfo['department'],
              lineOfBusiness: touringAgentTerritory.lineOfBusiness as FinanceInfo['lineOfBusiness'],
            },
            commission: Number(commissionRate) || 10,
            utaWireTransfer,
          });
          if (updatedContract && setContract) {
            setContract(updatedContract);
          }
        }
      }
    }

    deal.shows.forEach((s) => {
      if (data.venue) {
        const updatedShow = {
          showId: s._id,
          updateBody: {
            venue: data.venue.data || undefined,
            title: data.title,
            ages: data.ages,
          },
        };
        updateData.push(updatedShow);
      }
    });

    if (updateData.length > 0) {
      setCanSubmit(false);

      await updateDealShows({
        dealId: deal._id.toString(),
        updates: updateData,
      })
        .then(async (resp) => {
          if (resp.ok) {
            const updatedDeal = await updateDeal(deal._id, {
              ...(resp.body as Deal),
              territory: data.territory,
            });

            if (setDeal) setDeal(updatedDeal);
          } else {
            showUserError(resp);
            setCanSubmit(true);
          }
        })
        .catch((error) => {
          console.log('ERROR updating contract with linked deal tour terms', error);
          setCanSubmit(true);
        });
      const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';
      if (isDealSubmittedToNetsuite) {
        submitToNetsuite(contract, deal, false);
      }
      setTimeout(() => setCanSubmit(true), 500);
    }
  };

  useEffect(() => {
    const shows = deal.shows;
    const masterShow = getMasterShow(shows);
    const venue = masterShow?.venue;
    let defaultValues = EMPTY_SHOW_LOCATION_FORM;

    if (venue) {
      defaultValues = {
        venue: {
          name: venue.name,
          data: venue,
        },
        title: masterShow.title,
        ages: masterShow.ages || EMPTY_SHOW_LOCATION_FORM.ages,
        address: venue.address || '',
        territory: deal.territory || EMPTY_SHOW_LOCATION_FORM.territory,
        // Removed from MVP: https://unitedtalent.atlassian.net/browse/TOUR-1891
        // showOnItineraries: !masterShow.isInternalTitle,
      };
      setShowLocationFormData(defaultValues);
      setAddress(formatAddress(venue.address) || '');
    }
  }, [deal]);

  /**
   * This function fires to update the data associated with the venue
   * primarily to update the venue address
   *
   * @param chosen the data about the venue being selected
   */
  const handleVenueChange = (chosen: iOptions | null, setValueMethod: UseFormSetValue<FieldValues>) => {
    setValueMethod(`venue.data`, chosen?.data);
    setValueMethod(`address`, chosen?.data?.address);
    setAddress(formatAddress(chosen?.data?.address));
  };

  const handleAddCustomOption = (newOption: string) => {
    setCustomAgeOptions([...customAgeOptions, { id: newOption, label: newOption }]);
  };

  return (
    showLocationFormData && (
      <Form
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        className="fixed-first-column-with-labels items-center"
        defaultValues={showLocationFormData}
        disabled={!isOpen || locked}
        formName={FORM_NAMES.SHOW_LOCATION}
      >
        <Label isRequired>Venue</Label>
        {/* <Input label={false} placeholder="Venue Name" rules={{ required: false }} id="venue" /> */}
        <DropdownWithSearch
          label={false}
          id="venue.name"
          secondaryId="address"
          searchType={SEARCH_TYPE.VENUE}
          defaultValue={showLocationFormData?.venue.name}
          handleChange={handleVenueChange}
          disabled={!isOpen || locked}
          isRequired={true}
          rules={{
            required: 'Please select a valid ‘Venue’ option',
          }}
          shouldSortOptions
          dataCy={cypressTags.DEAL_FORM.SHOW_LOCATION.VENUE_DROPDOWN}
        />

        <Label>Title</Label>
        <Input
          label={false}
          placeholder="Show Title"
          rules={{ required: false }}
          id="title"
          disabled={!isOpen || locked}
        />

        <Label className="self-start" isRequired>
          Address
        </Label>
        <TextField
          dataCy={cypressTags.DEAL_FORM.SHOW_LOCATION.VENUE_ADDRESS}
          label={false}
          id="address"
          text={address}
          className="leading-5"
          containerClassName="pt-[2px]"
        />

        {/* Removed from MVP: https://unitedtalent.atlassian.net/browse/TOUR-1891 */}
        {/* <div className="col-start-4 self-start">
          <Checkbox id="showOnItineraries" label="Show on Itineraries" containerClassName="px-2" disabled={!isOpen || locked} />
        </div> */}

        <Label className="col-start-3">Ages</Label>
        <DropdownWithStaticSearch
          label={false}
          placeholder="Ages"
          id="ages"
          addCustomOption={handleAddCustomOption}
          customOptions={customAgeOptions}
          staticOptions={SHOW_AGES}
          disabled={!isOpen || locked}
          maxLength={100}
          customOptionLabel="Create Custom Age"
        />

        <Label>Venue Territory</Label>
        <Dropdown
          label={false}
          placeholder="Territory"
          id="territory"
          options={SHOW_TERRITORIES}
          disabled={!isOpen || locked}
          dataCy={cypressTags.DEAL_FORM.SHOW_LOCATION.TERRITORY_DROPDOWN}
        />
      </Form>
    )
  );
};

export default ShowLocationForm;
