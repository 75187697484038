import { updateDeal } from 'api';
import { useEditDealContext } from 'context/editDealContext';
import {
  PAYMENT_CLIENTS_BEHALF_EMPTY_FORM,
  PAYMENT_CLIENTS_BEHALF_EMPTY_ITEM,
  PAYMENT_CLIENTS_BEHALF_PAYMENT_TYPES,
} from 'deal-form/data/organisms';
import { iFormProps } from 'deal-form/interfaces/general';
import { iOrganismClientsBehalfPayments } from 'deal-form/interfaces/organisms';
import React, { useMemo, useState } from 'react';
import { Payment } from 'types/deal';

import { ERROR_MESSAGES, FORM_NAMES } from 'deal-form/data/constants';
import { Currency } from 'deal-form/form-controls/Currency';
import { DropdownWithStaticSearch } from 'deal-form/form-controls/DropdownWithStaticSearch';
import { FieldArray } from 'deal-form/form-controls/FieldArray';
import { TextArea } from 'deal-form/form-controls/TextArea';
import { getMaxCharMessage } from 'deal-form/helpers/formHelpers';
import { FieldValues, UseFormGetValues, UseFormReset } from 'react-hook-form';
import { Form } from '../../form-controls/Form';
import submitToNetsuite from 'utils/submitToNetsuite';

export const COLUMN_LABELS = [
  { value: 'Amount', isRequired: true },
  { value: 'Type', isRequired: true },
  { value: 'Description' },
];
export const GRID_LAYOUT = 'grid grid-cols-[1fr_1fr_3fr] gap-2 ';

const PaymentsOnClientBehalfForm: React.FC<iFormProps> = ({ deal, setDeal, isOpen, locked, contract }) => {
  const [canSubmit, setCanSubmit] = useState(true);

  //Test with grabbing the default currency from the context and passing it to the Currency input
  const { defaultCurrency } = useEditDealContext();
  const onSubmit = async (data: iOrganismClientsBehalfPayments) => {
    try {
      setCanSubmit(false);
      const updatedPayments: Payment[] = [];

      if (data.payments.length > 0) {
        data.payments.forEach(({ amount, clientPaymentType, memo }) => {
          const payment: Payment = {
            amount: amount.value,
            currency: amount.currency.toUpperCase() || defaultCurrency.id,
            type: clientPaymentType,
            memo,
          };
          updatedPayments.push(payment);
        });
      }

      const updatedDeal = await updateDeal(deal._id, {
        ...deal,
        paymentsOnClientsBehalf: updatedPayments,
      });

      if (updatedDeal && setDeal) setDeal(updatedDeal);

      const isDealSubmittedToNetsuite = contract?.netsuiteStatus === 'Set';

      if (isDealSubmittedToNetsuite) {
        submitToNetsuite(contract, deal, false);
      }

      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR updated payments', e);
      setCanSubmit(true);
    }
  };

  const defaultValues = useMemo(
    () =>
      deal.paymentsOnClientsBehalf.length === 0
        ? PAYMENT_CLIENTS_BEHALF_EMPTY_FORM
        : {
            payments: deal.paymentsOnClientsBehalf.map((payment) => {
              return {
                amount: {
                  currency: payment.currency || deal.currency.toLowerCase(),
                  value: payment.amount,
                },
                clientPaymentType: payment.type,
                memo: payment.memo,
              };
            }),
          },
    [deal]
  );

  /**
   * override the built in append functionality
   *
   * @param index row to be removed
   */
  const handleRemoveOverride = async (
    index: number,
    reset: UseFormReset<FieldValues>,
    getValues?: UseFormGetValues<FieldValues>
  ) => {
    const vals: iOrganismClientsBehalfPayments = getValues?.() as iOrganismClientsBehalfPayments;

    if (vals?.payments?.length) {
      const isNew = vals.payments?.[index]?.isNew || false;
      vals.payments.splice(index, 1);

      if (isNew) {
        reset(vals);
      } else {
        onSubmit(vals);
      }
    } else {
      reset();
    }
  };

  return (
    <Form
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      className={`form-row full-width ${canSubmit ? '' : 'opacity-50'}`}
      defaultValues={defaultValues}
      disabled={!isOpen || locked}
      formName={FORM_NAMES.PAYMENTS_ON_CLIENTS_BEHALF}
    >
      <FieldArray
        gridClassName={GRID_LAYOUT}
        groupName="payments"
        columns={COLUMN_LABELS}
        addButtonLabel="Add Payment"
        emptyRow={PAYMENT_CLIENTS_BEHALF_EMPTY_ITEM}
        disabled={!isOpen || locked || !canSubmit}
        overrideRemove={handleRemoveOverride}
      >
        <Currency
          id="amount"
          disabled={!isOpen || locked}
          dealCurrency={defaultCurrency}
          overrideCurrency={deal.currency}
          rules={{
            min: { value: 0.01, message: ERROR_MESSAGES.DOLLAR_TWO_DECIMAL },
          }}
        />
        <DropdownWithStaticSearch
          placeholder="Payment Type"
          id="clientPaymentType"
          staticOptions={PAYMENT_CLIENTS_BEHALF_PAYMENT_TYPES}
          disabled={!isOpen || locked}
          rules={{ required: "Please select a valid 'Type' option" }}
          isRequired
        />
        <TextArea
          placeholder="Type your description here."
          rules={{
            maxLength: {
              value: 1024,
              message: getMaxCharMessage('the description', 1024),
            },
          }}
          id="memo"
          rows={1}
          disabled={!isOpen || locked}
        />
      </FieldArray>
    </Form>
  );
};

export default PaymentsOnClientBehalfForm;
