import { ITooltipParams } from 'ag-grid-community';

const GridTooltip = (props: ITooltipParams) => {
  const valueToDisplay = props.value.value ? props.value.value : '';

  // don't show the little box if there is no text
  if (valueToDisplay === '') return <div></div>;

  return (
    <div className="max-w-[200px] z-50 bg-white border p-1">
      <p>{valueToDisplay}</p>
    </div>
  );
};

export default GridTooltip;
