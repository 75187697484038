import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { remove } from 'api';
import { Modal, iModalProps } from 'features/common/modal/Modal';
import { SetState } from 'features/routing';
import { iModalState } from 'features/routing/context';
import { toast } from 'react-toastify';

import Button from 'components/Button';
import ToastWrapper from 'components/toast/ToastWrapper';
import { FC, useCallback, useState } from 'react';

interface iRejectDealModal extends iModalProps {
  setShowRejectDealModal: SetState<iModalState>;
  dealId: string;
}

const RejectDealModal: FC<iRejectDealModal> = ({ setShowRejectDealModal, dealId = '', ...props }) => {
  // States
  const [loading, setLoading] = useState<boolean>(false);

  const handleShowModal = (val: boolean) => {
    setShowRejectDealModal({ isOpen: val });
  };

  const handleConfirm = useCallback(async () => {
    if (dealId) {
      setLoading(true);
      await remove(`/touring/deals/${dealId}`)
        .then((res) => {
          // reload the page to refresh the state cleanly
          res.status === 200 && window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          toast.warn(ToastWrapper('There was an error rejecting the deal', ''));
        })
        .finally(() => setLoading(false));
    }
  }, [dealId]);

  return (
    <Modal {...props} setShowModal={handleShowModal}>
      <div className="flex flex-col justify-center items-center p-4 gap-4 max-w-[450px]">
        <ExclamationCircleIcon width={60} height={60} className="text-greyCloudy" />
        <p className="text-sm text-center">Are you sure you want to reject this deal?</p>
        <div className="flex flex-row justify-between w-full">
          <Button disabled={loading} variant="primary" onClick={() => setShowRejectDealModal({ isOpen: false })}>
            CANCEL
          </Button>
          <Button disabled={loading} variant="secondary" onClick={handleConfirm}>
            CONFIRM
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RejectDealModal;
