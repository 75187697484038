import { iNewDeal } from 'containers/deals/NewDealModal';
import { Clause, Deal } from 'types';

import { preDefinedDealClause } from '../types/deal';
import { get, patch, post, remove } from './index';

/**
 * Method used to create a new deal
 * @param deal - an object of type DealNew
 * @returns Promise<DealNew> - a deal object
 */
export const createDeal = async (deal: Deal): Promise<Deal> => {
  const response = await post(`/touring/deals`, deal);
  return response.body;
};

/**
 * Method used to create a new deal from the modal
 *
 * @param body - an object of type iNewDeal that has the mandatory params for a new deal
 * @returns Promise<DealNew> - a deal object
 */
export const createDealFromModal = async (body: iNewDeal): Promise<Deal> => {
  const response = await post(`/touring/deals/createFromModal`, body);

  return response.body;
};

export const updateDeal = async (id: string, dealUpdateRequest: Deal) => {
  const response = await patch(`/touring/deals/${id}`, dealUpdateRequest);
  return response.body;
};

/**
 * Method used to fetch the corresponding deal/deals
 * @param id string - deal Id
 * @returns Promise<DealNew[]> - an array of deal objects
 */
export const getDealById = async (id: string): Promise<Deal> => {
  const response = await get(`/touring/deals/${id}`);
  return response.body;
};

/**
 * The DealsContainer is using an array of Deal objects to dislay rows.
 * If a Deal object has one or more ShowDates, each display row is an individual
 * ShowDate of the same Deal.
 * If a Deal object has no ShowDates (empty showDates array or no showDates property),
 * the Deal object is displayed as a row.
 * @returns an array of Deal objects
 */
export const getDeals = async (): Promise<Deal[]> => {
  const response = await get('/touring/deals');
  const dealsArray: Deal[] = [];
  if (response.body.data) {
    for (let i = 0; i < response.body.data.length; i++) {
      if (response.body.data[i].showDates) {
        if (response.body.data[i].showDates.length > 0) {
          for (let j = 0; j < response.body.data[i].showDates.length; j++) {
            const temp = Object.assign({}, response.body.data[i]);
            temp.showDates = [];
            temp.showDates.push(response.body.data[i].showDates[j]);
            dealsArray.push(temp);
          }
        } else {
          delete response.body.data[i].showDates;
          dealsArray.push(response.body.data[i]);
        }
      } else {
        dealsArray.push(response.body.data[i]);
      }
    }
  }
  return dealsArray;
};

/**
 * @returns an array of Deal objects
 */
export const getAllDeals = async (): Promise<Deal[]> => {
  return await get('/touring/deals').then((response) => {
    return response.body;
  });
};

/**
 * Additional clauses that needs to be populated in the deals form for all artists will be
 * fetched using this method
 * @returns an array of pre-defined deal clauses
 */
export const getDealClauses = async (clientId: string): Promise<Clause[]> => {
  return await get(`/touring/clauses/${clientId}`).then((response) => {
    return response.body;
  });
};

/**
 * Pre defined balance remittance clauses will be fetched using this method
 * @returns an array of balance remittance objects
 */
export const getPreDefinedBalanceRemittances = async (): Promise<preDefinedDealClause[]> => {
  return await get(`/touring/deals/balanceRemittances`).then((response) => {
    return response.body;
  });
};

/**
 * Gets all the linkable deals for the Link Deals modal
 * @returns an array of deals
 */
export const getLinkableDeals = async (dealID: string, startDate: string, endDate: string): Promise<any> => {
  const response = await get(`/touring/deals/${dealID}/getLinkableDeals?startDate=${startDate}&endDate=${endDate}`);
  return response.body;
};

/**
 * Post the linked deals from the Link Deals modal.
 * @returns the updated contract object
 */
export const linkDeals = async (contractID: string, dealsData: any): Promise<any> => {
  const response = await post(`/touring/contracts/${contractID}/setDeals`, dealsData);
  return response.body;
};

/**
 * Post the removed linked deal from the Linked Deals organism in the deal form.
 * @returns the updated contract object
 */
export const removeLinkedDeal = async (contractID: string, dealID: string): Promise<any> => {
  const response = await post(`/touring/contracts/${contractID}/removeDeal/${dealID}`, {});
  return response.body;
};

/**
 * Method used to delete the corresponding deal/deals
 * @param id string - deal Id
 */
export const removeDeal = async (id: string) => {
  const response = await remove(`/touring/deals/${id}`);
  return response;
};
