import { ICellRendererParams } from 'ag-grid-community';
import { numberWithCommas } from 'features/common/editors/CurrencyCellEditor';
import { FC } from 'react';

/**
 * Guarantee renderer.
 *
 * @param {ICellRendererParams} props
 */
export const GuaranteeRenderer: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const { data: row } = props.data;
  const value = props.value ? props.value : row?.guarantee;
  const formattedNumber = value ? `$ ${numberWithCommas(value)} USD` : null;
  return (
    <div className="center-content" data-row-id={props.data.id}>
      {value != null ? `${formattedNumber}` : <></>}
    </div>
  );
};
GuaranteeRenderer.displayName = 'GuaranteeRenderer';
