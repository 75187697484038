import { ViewColumnsIcon } from '@heroicons/react/24/outline';
import { Checkbox } from '@united-talent-agency/components';
import { FC, useContext, useEffect, useState } from 'react';

import Button from 'components/Button';

import { RoutingContext } from '../../features/routing';
import CypressTags from '../../support/cypressTags';
import cypressTags from '../../support/cypressTags';

export const EditColumnsMenu: FC = () => {
  const alwaysShowColumns = ['venue', 'city', 'status'];
  const { columnApi, saveColumnState } = useContext(RoutingContext);
  const [rerenderKey, setRerenderKey] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (!menuOpen) {
      if (columnApi) saveColumnState(columnApi.getColumnState());
    }
  }, [menuOpen]);

  return (
    <div data-cy={CypressTags.ROUTING.EDIT_COLUMNS_MENU_TOGGLE} className="w-full relative z-40 h-10">
      <Button
        rightIcon={<ViewColumnsIcon height={24} />}
        onClick={() => setMenuOpen(!menuOpen)}
        className="w-full h-full flex flex-row items-center justify-center btn-primary cursor-pointer "
        dataCy={cypressTags.ROUTING.EDIT_COLUMNS_MENU_TOGGLE_DESKTOP}
      >
        {/* <span className="w-6 h-6">
          <Icons.ColumnWidthOutlined />
        </span> */}

        <span className="pl-2 whitespace-nowrap cursor-pointer hidden lg:block">Edit Columns</span>
      </Button>
      <div
        className={`absolute left-0 top-[105%]  bg-white w-[220px] min-w-full transition-opacity duration-300 shadow-dropdown ${
          menuOpen ? 'opacity-1 pointer-events-auto z-40' : 'opacity-0 pointer-events-none'
        }`}
        data-cy={CypressTags.ROUTING.EDIT_COLUMNS_MENU}
      >
        {menuOpen && (
          <>
            <div
              // Make this layer focusable to support closing on focus
              tabIndex={0}
              role="button"
              className="fixed top-0 left-0 opacity-0 w-[100vw] h-[100vh] z-0"
              onFocus={() => {
                setMenuOpen(false);
              }}
              onClick={() => {
                setMenuOpen(false);
              }}
            ></div>
            <ul className="p-4 max-h-[calc(100vh-200px)] overflow-y-auto z-50">
              {(columnApi?.getColumns() || [])
                .filter((col) => !col.getPinned())
                .map((col) => {
                  const colId = col.getColId();
                  const isVisible = col.isVisible();
                  const { headerName } = col.getColDef();
                  const children = col.getParent().getChildren() || [];
                  const isGroupMaster = children[0].getUniqueId() === colId;
                  const shouldIndent = children.length > 1 && !isGroupMaster;
                  const alwaysChecked = alwaysShowColumns.includes(colId) || shouldIndent;

                  return (
                    <li key={colId} className={`flex items-center z-50 ${shouldIndent ? 'px-5' : 'px-0'}`}>
                      <Checkbox
                        readOnly={alwaysChecked}
                        disabled={alwaysChecked}
                        checked={isVisible}
                        onChange={
                          alwaysChecked
                            ? () => null
                            : () => {
                                const statesToUpdate = [{ colId, hide: isVisible }];
                                if (isGroupMaster) {
                                  children
                                    .map((c) => c.getUniqueId())
                                    .forEach((childColId) => {
                                      statesToUpdate.push({
                                        colId: childColId,
                                        hide: columnApi?.getColumn(childColId)?.isVisible() || false,
                                      });
                                    });
                                }

                                columnApi?.applyColumnState({ state: statesToUpdate });
                                setRerenderKey(rerenderKey + 1);
                              }
                        }
                      >
                        <div className="text-sm font-normal text-black cursor-pointer">{headerName || colId}</div>
                      </Checkbox>
                    </li>
                  );
                })}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
