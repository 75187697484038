import { updateContract, updateDeal } from 'api';
import { getBusinessEntities } from 'api/business';
import { findEmployee } from 'api/person';
import { SEARCH_TYPE } from 'deal-form/constants';
import { FORM_NAMES } from 'deal-form/data/constants';
import { CLIENT_AGENT_EMPTY_ITEM, CLIENT_EMPTY_FORM } from 'deal-form/data/organisms';
import { iFormProps, iOptions } from 'deal-form/interfaces/general';
import { iOrganismClient, iOrganismClientAgent } from 'deal-form/interfaces/organisms';
import { MasterDealWarning } from 'deal-form/ui/MasterDealWarning';
import React, { useEffect, useState } from 'react';
import { Person } from 'types';
import { Address } from 'types/data-schemas';
import { Agent, Deal } from 'types/deal';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { ERROR_MESSAGES } from '../../data/constants';
import { Dropdown } from '../../form-controls/Dropdown';
import { DropdownWithSearch } from '../../form-controls/DropdownWithSearch';
import { FieldArray } from '../../form-controls/FieldArray';
import { Form } from '../../form-controls/Form';
import { Label } from '../../form-controls/Label';
import cypressTags from 'support/cypressTags';

const AGENT_KEYS = {
  BOOKING_AGENT: 'bookingAgent',
  CONTRACT_ADMINISTRATOR: 'contractAdministrator',
  PAPERING_AGENT: 'paperingAgent',
  RESPONSIBLE_AGENT: 'responsibleAgent',
};

export const AGENT_TYPES = {
  BOOKING_AGENT: 'Booking Agent',
  CONTRACT_ADMINISTRATOR: 'Contract Administrator',
  PAPERING_AGENT: 'Papering Agent',
  RESPONSIBLE_AGENT: 'Responsible Agent',
};

const AGENTS_KEY_MAP = {
  [AGENT_KEYS.BOOKING_AGENT]: AGENT_TYPES.BOOKING_AGENT,
  [AGENT_KEYS.CONTRACT_ADMINISTRATOR]: AGENT_TYPES.CONTRACT_ADMINISTRATOR,
  [AGENT_KEYS.PAPERING_AGENT]: AGENT_TYPES.PAPERING_AGENT,
  [AGENT_KEYS.RESPONSIBLE_AGENT]: AGENT_TYPES.RESPONSIBLE_AGENT,
};

const AGENTS_TYPE_MAP = {
  Booking: AGENT_TYPES.BOOKING_AGENT,
  'Booking Agent': AGENT_TYPES.BOOKING_AGENT,
  Booking_Agent: AGENT_TYPES.BOOKING_AGENT,
  'Contract Administrator': AGENT_TYPES.CONTRACT_ADMINISTRATOR,
  Contract_Administrator: AGENT_TYPES.CONTRACT_ADMINISTRATOR,
  Papering: AGENT_TYPES.PAPERING_AGENT,
  'Papering Agent': AGENT_TYPES.PAPERING_AGENT,
  Papering_Agent: AGENT_TYPES.PAPERING_AGENT,
  'Responsible Agent': AGENT_TYPES.RESPONSIBLE_AGENT,
  Responsible_Agent: AGENT_TYPES.RESPONSIBLE_AGENT,
};

// Standardizes the agent type field and replaces old invalid values with
// the preferred ones next time the user saves this organism.
const mapAgentTypes = (agentType: string) => {
  return AGENTS_TYPE_MAP[agentType as keyof typeof AGENTS_TYPE_MAP];
};

const doTouringAgentsUpdate = async (agents: iOrganismClient['touringTeam']) => {
  const agentsPayload = [];

  if (agents) {
    for (let agent of agents) {
      const agentPromise = await findEmployee(agent.agentName);

      // Don't add property to the agentsPayload if we couldn't find a valid person
      if (agentPromise[0]) {
        agentsPayload.push(agentPromise[0]);
      }
    }
  }

  return agentsPayload;
};

const parseAgents = (agents: Agent[]): { agentType: string; agentName: string; autoAdded?: boolean }[] => {
  const filteredAgents = agents.map((agent) => ({
    agentType: agent?.type ? mapAgentTypes(agent?.type) : '',
    agentName: agent?.person?.name ?? '',
    autoAdded: agent?.autoAdded,
    _id: agent?.person?._id,
  }));

  return filteredAgents || [];
};

const parseTouringAgents = (touringTeam: Person[]): iOrganismClient['touringTeam'] => {
  const filteredAgents = touringTeam.map((person) => ({
    agentName: person?.name ?? '',
  }));

  return filteredAgents;
};

const getAgentsPayload = async (values: iOrganismClient) => {
  const parsedPrimaryAgents: Agent[] = [];
  Object.values(AGENT_KEYS).map((valueKey) => {
    if (valueKey !== AGENT_KEYS.RESPONSIBLE_AGENT && valueKey !== AGENT_KEYS.PAPERING_AGENT) {
      const agentRecord = values[valueKey as keyof iOrganismClient] as iOrganismClientAgent;
      parsedPrimaryAgents.push({
        person: {
          _id: agentRecord._id,
          name: agentRecord.name,
        },
        type: AGENTS_KEY_MAP[valueKey],
      });
    }
  });

  const parsedSecondaryAgents = values.additionalBookingAgents.map((agent) => ({
    person: {
      _id: agent._id,
      name: agent.name,
    },
    type: AGENT_TYPES.BOOKING_AGENT,
  }));

  const agentsPayload = [...parsedPrimaryAgents, ...parsedSecondaryAgents];

  return agentsPayload;
};

const ClientForm: React.FC<iFormProps> = ({ deal, contract, setDeal, setContract, isOpen, locked }) => {
  const [formData, setFormData] = useState<iOrganismClient | null>(null);
  const [businessNames, setBusinessNames] = useState<iOptions[]>([]);
  const [masterDeal, setMasterDeal] = useState<Deal | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [optionData, setOptionData] = useState<iOptions | null>(null);

  const handleBusinessChange = (chosen: iOptions | null) => {
    setOptionData(chosen);
  };

  const getBusinessData = async (clientId: string) => {
    const businessData = await getBusinessEntities(clientId);

    if (businessData) {
      const businessOptions = businessData.map((option, index) => ({
        id: option.name + index.toString(),
        label: option.name,
        data: option,
      }));
      setBusinessNames(businessOptions);
    }
  };

  const doBusinessUpdate = async (businessName: string) => {
    const businesses = businessNames.filter((b) => b.id === businessName);
    if (businesses && businesses.length) {
      const business = businesses[0];
      const updatedBusiness = {
        name: business.label,
        territory: business.data?.territory,
        primary: business.data?.primary || undefined,
        taxID: business.data?.taxID || undefined,
        vatNumber: business.data?.vatNumber || undefined,
        netsuiteId: business.data?.netsuiteId || undefined,
      };

      return updatedBusiness;
    } else {
      return;
    }
  };

  const handleAgentChange = (
    chosen: iOptions | null,
    setValueMethod: UseFormSetValue<FieldValues>,
    agentKey: string
  ) => {
    if (agentKey === 'bookingAgent' || agentKey === 'contractAdministrator') {
      setValueMethod(`${agentKey}.name`, chosen?.data.name);
      setValueMethod(`${agentKey}._id`, chosen?.data?._id);
    }
  };
  const handleAdditionalBookingAgentChange = (
    chosen: iOptions | null,
    index: number | undefined,
    setValueMethod: UseFormSetValue<FieldValues>,
    agentKey: string
  ) => {
    if (agentKey === 'additionalBookingAgents') {
      setValueMethod(`${agentKey}.[${index}]._id`, chosen?.data?._id);
      setValueMethod(`${agentKey}.[${index}].name`, chosen?.data.name);
    }
  };

  const getAddress = ({ address, city, zip, country, state }: Address): string => {
    return `${address} ${city} ${state} ${country} ${zip} `;
  };

  useEffect(() => {
    if (contract) {
      let dealToUse = deal;
      if (contract && contract.masterDeal && contract.crossed !== 'Neither') {
        dealToUse = contract.masterDeal;
        setMasterDeal(contract.masterDeal);
      } else {
        setMasterDeal(null);
      }

      const { commission } = contract;
      const { client, agents, artistBusiness, touringTeam } = dealToUse;

      if (client) {
        getBusinessData(client._id);

        const parsedAgents = parseAgents(agents);

        // Group agents array by agentType
        const groupedAgents = parsedAgents.reduce((acc, curr) => {
          acc[curr.agentType] = acc[curr.agentType] || [];
          acc[curr.agentType].push(curr);
          return acc;
        }, Object.create(null));

        const primaryBookingAgent = groupedAgents[AGENT_TYPES.BOOKING_AGENT]?.[0] || {
          name: '',
          _id: '',
        };

        const additionalBookingAgents =
          groupedAgents[AGENT_TYPES.BOOKING_AGENT]?.length > 1 &&
          [...groupedAgents[AGENT_TYPES.BOOKING_AGENT]].slice(1).map((agent) => ({
            name: agent.agentName,
            _id: agent._id,
          }));

        const clientData = {
          client: client.name,
          artistBusinessName: artistBusiness?.name || '',
          artistTaxId: artistBusiness?.taxID ? artistBusiness?.taxID : undefined,
          artistVatNumber: artistBusiness?.vatNumber ? artistBusiness?.vatNumber : undefined,
          companyAddress:
            (Array.isArray(client?.addresses) && client?.addresses.length > 0 && getAddress(client?.addresses[0])) ||
            '--',
          commission: commission,
          touringTeam: parseTouringAgents(touringTeam),
          paperingAgent: groupedAgents[AGENT_TYPES.PAPERING_AGENT]?.[0].agentName || '',
          responsibleAgent:
            (groupedAgents[AGENT_TYPES.RESPONSIBLE_AGENT] || [])?.map(
              (agent: { agentName: string }) => agent?.agentName
            ) || '',
          bookingAgent: primaryBookingAgent,
          contractAdministrator: groupedAgents[AGENT_TYPES.CONTRACT_ADMINISTRATOR]?.[0] || {
            name: '',
            _id: '',
          },
          additionalBookingAgents: additionalBookingAgents || [],
        };
        setFormData(clientData);
      }
    } else {
      setFormData(CLIENT_EMPTY_FORM);
    }
  }, [deal, contract]);

  const onSubmit = async (values: iOrganismClient) => {
    // Returning null when the field is cleared to remove the document when submitting the update request
    const updatedBusiness = values.artistBusinessName ? await doBusinessUpdate(values.artistBusinessName) : null;

    const touringTeamPayload = await doTouringAgentsUpdate(values.touringTeam);

    const dealToUse = masterDeal || deal;

    const agentsPayload = await getAgentsPayload(values);

    const mutableAgents = agentsPayload.filter(
      (agent) => agent.type !== AGENT_TYPES.RESPONSIBLE_AGENT && agent.type !== AGENT_TYPES.PAPERING_AGENT
    );

    const immutableAgents = deal?.agents.filter(
      (item) => item.type === AGENT_TYPES.RESPONSIBLE_AGENT || item.type === AGENT_TYPES.PAPERING_AGENT
    );

    const agents = [...mutableAgents, ...immutableAgents];

    try {
      setCanSubmit(false);

      const updatedDeal = await updateDeal(dealToUse._id, {
        ...dealToUse,
        agents,
        artistBusiness: updatedBusiness,
        touringTeam: touringTeamPayload,
      });

      if (contract && setContract) {
        const updatedMasterDeal = masterDeal ? { masterDeal: updatedDeal as Deal } : {};
        const commission = contract.commission !== values.commission ? values.commission : values.commission || 0;
        const newContractInfo = {
          commission,
          ...updatedMasterDeal,
        };

        try {
          const updatedContract = await updateContract(contract._id, {
            ...contract,
            ...newContractInfo,
          });

          setContract(updatedContract);
        } catch (e) {
          console.log('ERROR UPDATING CONTRACT COMMISSION', e);
        }
      }
      if (setDeal && !masterDeal) {
        setDeal(updatedDeal);
      }
      setTimeout(() => setCanSubmit(true), 500);
    } catch (e) {
      console.log('ERROR UPDATING CLIENT/AGENT INFO', e);
      setCanSubmit(true);
    }
  };

  const GRID_LAYOUT = 'grid grid-cols-[200px_400px] gap-5 items-center';
  const contentStyles = 'text-sm font-normal';

  return (
    formData && (
      <>
        {masterDeal && contract && (
          <MasterDealWarning crossed={contract.crossed === 'Crossed'} fields={['All Client and Agent Fields']} />
        )}

        <Form
          canSubmit={canSubmit}
          onSubmit={onSubmit}
          defaultValues={formData}
          disabled={!isOpen || locked}
          formName={FORM_NAMES.CLIENT}
        >
          <div className={GRID_LAYOUT}>
            <label htmlFor="client" className="form-label">
              Client
            </label>
            <div data-cy={cypressTags.DEAL_FORM.CLIENT_SECTION.CLIENT_NAME} className={`text-sm px-2`}>
              {deal?.client?.name}
            </div>

            <Label htmlFor="artistBusinessName">Artist Business Name</Label>

            <Dropdown
              placeholder="Please select an artists business name."
              id="artistBusinessName"
              options={businessNames}
              disabled={!isOpen || locked}
              handleChange={(_chosen, _setValueMethod, _index, trigger) => {
                if (trigger) {
                  handleBusinessChange(_chosen);
                }
              }}
            />

            {deal.artistBusiness?.taxID && (
              <>
                <label htmlFor="taxId" className="form-label">
                  Tax ID
                </label>
                <div className={`${contentStyles} px-2`}>
                  {optionData?.data?.taxID ? optionData.data?.taxID : deal.artistBusiness?.taxID}
                </div>
              </>
            )}
            {formData.artistVatNumber && (
              <>
                <label htmlFor="taxId" className="form-label">
                  Artist VAT Number
                </label>
                <div className={`${contentStyles} px-2`}>{formData.artistVatNumber}</div>
              </>
            )}

            <label htmlFor="companyAddress" className="form-label self-start pt-2">
              Company Address
            </label>
            <div className={`${contentStyles} px-2 pt-[10px]`}>{formData.companyAddress}</div>
          </div>
          <div
            className={`grid grid-cols-[100px_200px] md:grid-cols-[200px_800px] gap-5 items-center border-t border-greyMineShaft mt-6 pt-6`}
          >
            <div className="text-xl font-medium col-start-1 col-span-full">Agents</div>
            {!contract?.template.includes('Comedy') && (
              <>
                <Label isRequired={!contract?.template.includes('Comedy')} htmlFor="paperingAgent">
                  {AGENT_TYPES.PAPERING_AGENT}
                </Label>
                <div className="text-sm" data-cy={cypressTags.DEAL_FORM.CLIENT_SECTION.PAPERING_AGENT}>
                  {formData.paperingAgent || '--'}
                </div>
              </>
            )}
            <>
              <Label htmlFor="responsibleAgent">{'Responsible Agent(s)'}</Label>
              <div className="text-sm">
                {formData?.responsibleAgent?.length > 0
                  ? formData?.responsibleAgent?.map((agent, i) => {
                      return (
                        agent && (
                          <span key={i} data-cy={cypressTags.DEAL_FORM.CLIENT_SECTION.REPONSIBLE_AGENT}>
                            {agent}
                            {i !== formData?.responsibleAgent?.length - 1 ? ', ' : ''}
                          </span>
                        )
                      );
                    })
                  : '--'}
              </div>
            </>

            <Label isRequired htmlFor="bookingAgent">
              {AGENT_TYPES.BOOKING_AGENT}
            </Label>
            <DropdownWithSearch
              label={false}
              disabled={!isOpen || locked}
              placeholder="Agent Name."
              id="bookingAgent.agentName"
              handleChange={(chosen, setValueMethod) => handleAgentChange(chosen, setValueMethod, 'bookingAgent')}
              secondaryId="contacts"
              searchType={SEARCH_TYPE.EMPLOYEE}
              rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
              isRequired
              shouldSortOptions
            />

            <Label isRequired htmlFor="contractAdministrator">
              {AGENT_TYPES.CONTRACT_ADMINISTRATOR}
            </Label>
            <DropdownWithSearch
              label={false}
              disabled={!isOpen || locked}
              placeholder="Agent Name."
              id="contractAdministrator.agentName"
              secondaryId="contacts"
              searchType={SEARCH_TYPE.EMPLOYEE}
              handleChange={(chosen, setValueMethod) =>
                handleAgentChange(chosen, setValueMethod, 'contractAdministrator')
              }
              rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
              isRequired
              shouldSortOptions
            />
          </div>

          <div className="border-t border-greyMineShaft mt-6 pt-6">
            <FieldArray
              className="col-span-2"
              gridClassName={GRID_LAYOUT}
              groupName="additionalBookingAgents"
              addButtonLabel="Add Booking Agents"
              emptyRow={CLIENT_AGENT_EMPTY_ITEM}
              disabled={!isOpen || locked}
            >
              <Label htmlFor="agentName">Booking Agent</Label>
              <DropdownWithSearch
                label={false}
                disabled={!isOpen || locked}
                placeholder="Agent Name."
                id="name"
                secondaryId="contacts"
                handleChange={(chosen, setValueMethod, index) =>
                  handleAdditionalBookingAgentChange(chosen, index, setValueMethod, 'additionalBookingAgents')
                }
                searchType={SEARCH_TYPE.EMPLOYEE}
                rules={{ required: ERROR_MESSAGES.REQUIRED_FIELDS }}
                isRequired
                shouldSortOptions
              />
            </FieldArray>
          </div>
        </Form>
      </>
    )
  );
};

export default ClientForm;
