import { Tooltip } from '@kaizen/draft-tooltip';
import { ICellRendererParams } from 'ag-grid-community';
import { isViolation } from 'containers/routing/RoutingTableColumns';
import haversine from 'haversine-distance';
import React, { FC, useState } from 'react';
import { Venue } from 'types';
import { toMiles } from 'utils/helpers';

// default messages
export const NO_DATA = 'No location data available';
export const LOADING = '...';

/**
 * A styled cells that displays a vertical line or a dot representing the presence of a Venue within the row.
 *
 * onHover should display the driving distance between the two closest adjacent rows with valid Venues,
 * accomplished through a nested API call to PreciselyAPI.
 *  1. TOURING -> ONYX API
 *  2. ONX API -> PRECISELY
 *
 *  this is done to keep the Precisely Api keys hidden from users.
 *
 * @param {ICellRendererParams} props - fully-populated parameters that include hooks into overall grid
 * @param {TableRow} props.data - contains the data structure of the entire row
 *  such that it is accessible from any individual cell
 * @constructor
 */
export const TimelineRenderer: FC<ICellRendererParams> = (props) => {
  // const [hoverTop, setHoverTop] = useState(LOADING);
  // const [hoverBottom, setHoverBottom] = useState(LOADING);
  const [hoverFull, setHoverFull] = useState(LOADING);

  /**
   * Get distance between two venues.
   *
   * @param {Venue} a - prev location
   * @param {Venue} b - location
   * @param {Venue} c - next location
   * @param {React.Dispatch<React.SetStateAction<string>>} setHover - stateHook injected into the method;
   *  cells can contain 1 of three onHover hooks
   */
  const getDistanceOnVenue = async (
    a: Venue | undefined,
    b: Venue | undefined,
    c: Venue | undefined,
    setHover: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (a && b && c) {
      const pointA = { lon: a.geoLocation.coordinates[0], lat: a.geoLocation.coordinates[1] };
      const pointB = { lon: b.geoLocation.coordinates[0], lat: b.geoLocation.coordinates[1] };
      const pointC = { lon: c.geoLocation.coordinates[0], lat: c.geoLocation.coordinates[1] };

      if (pointB.lon === -71 && pointB.lat === 25) {
        // there is an issue with the location in the middle event
        setHover(`Could not calculate distance`);
      } else {
        // get prior and after distance in miles
        const priorDistance =
          pointA.lon === -71 && pointA.lat === 25 ? 'NA' : Math.round(toMiles(haversine(pointA, pointB)));

        const afterDistance =
          pointC.lon === -71 && pointC.lat === 25 ? 'NA' : Math.round(toMiles(haversine(pointB, pointC)));

        setHover(`Prior: ${priorDistance} mi | After: ${afterDistance} mi`);
      }
    } else if (a && b && c === undefined) {
      const pointA = { lon: a.geoLocation.coordinates[0], lat: a.geoLocation.coordinates[1] };
      const pointB = { lon: b.geoLocation.coordinates[0], lat: b.geoLocation.coordinates[1] };

      if (pointB.lon === -71 && pointB.lat === 25) {
        // there is an issue with the location in the middle event
        setHover(`Could not calculate distance`);
      } else {
        // get prior distance in miles
        const priorDistance =
          pointA.lon === -71 && pointA.lat === 25 ? 'NA' : Math.round(toMiles(haversine(pointA, pointB)));

        setHover(`Prior: ${priorDistance} mi`);
      }
    } else if (b && c && a === undefined) {
      const pointB = { lon: b.geoLocation.coordinates[0], lat: b.geoLocation.coordinates[1] };
      const pointC = { lon: c.geoLocation.coordinates[0], lat: c.geoLocation.coordinates[1] };

      if (pointB.lon === -71 && pointB.lat === 25) {
        // there is an issue with the location in the middle event
        setHover(`Could not calculate distance`);
      } else {
        // get after distance in miles
        const afterDistance =
          pointC.lon === -71 && pointC.lat === 25 ? 'NA' : Math.round(toMiles(haversine(pointB, pointC)));

        setHover(`After: ${afterDistance} mi`);
      }
    } else {
      setHover(NO_DATA);
    }
  };

  /**
   * Get distance between two venues.
   *
   * @param {Venue} a - driving 'from' location
   * @param {Venue} b - driving 'to' location
   * @param {React.Dispatch<React.SetStateAction<string>>} setHover - stateHook injected into the method;
   *  cells can contain 1 of three onHover hooks
   */
  const getDistanceBetweenVenues = async (
    a: Venue | undefined,
    b: Venue | undefined,
    setHover: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (a && b) {
      const pointA = { lon: a.geoLocation.coordinates[0], lat: a.geoLocation.coordinates[1] };
      const pointB = { lon: b.geoLocation.coordinates[0], lat: b.geoLocation.coordinates[1] };

      // get prior distance in miles
      const distance = Math.round(toMiles(haversine(pointA, pointB)));

      setHover(`${distance} mi between venues`);
    } else {
      setHover(NO_DATA);
    }
  };

  const isError = isViolation(props?.data?.violations);

  return props.data?.monthBreak ? (
    <span />
  ) : (
    <span className="z-40" data-cy={`${props?.data?._id}-${props?.colDef?.field}`}>
      {props.value ? (
        <Tooltip text={hoverFull} classNameOverride="text-sm">
          <div
            className="flex justify-center items-end border-greyQuill cursor-pointer h-10 w-7 relative"
            onMouseEnter={() =>
              getDistanceOnVenue(props.data.prevLocation, props.data.venue, props.data.nextLocation, setHoverFull)
            }
          >
            <div
              className={`dot absolute w-[10px] h-[10px] top-1/2 -mt-[5px] rounded-full border-[2px] ${
                isError ? 'border-red' : 'border-greyCloudy'
              } bg-white`}
            />

            <div className={`flex h-full border-l-2 border-dotted ${isError ? 'border-red' : 'border-greyCloudy'}`} />
          </div>
        </Tooltip>
      ) : (
        <Tooltip text={hoverFull} classNameOverride="text-sm">
          <div
            className="w-7 h-10 flex justify-center items-end cursor-pointer force-z"
            onMouseEnter={() =>
              getDistanceBetweenVenues(props.data.prevLocation, props.data.nextLocation, setHoverFull)
            }
          >
            <div className="flex h-full border-l-2 border-dotted border-greyCloudy" />
          </div>
        </Tooltip>
      )}
    </span>
  );
};
