import { TableRowData } from 'components/Table/types';
import { FC } from 'react';
import Cell from '../Cell';
import { BodyCellFullProps } from '../Cell/Full';

type BodyRowContentProps = {
  row: TableRowData;
  index: number;
  renderCellContent?: FC<BodyCellFullProps<any>>;
} & Omit<BodyCellFullProps, 'column'>;

const Content: FC<BodyRowContentProps> = ({ row, index, renderCellContent, ...props }) => {
  return (
    <>
      {row?.data?.map((column, columnIndex) => (
        <Cell.Full key={columnIndex} column={column} columnIndex={columnIndex} rowIndex={index} {...props}>
          {renderCellContent && renderCellContent({ column, columnIndex, rowIndex: index })}
        </Cell.Full>
      ))}
    </>
  );
};

export default Content;
